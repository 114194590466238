/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SkillSlider from "./SkillSlider/SkillSlider";


function ProfilesList({ title, profiles, comment, candidate, setCandidate }) { // eslint-disable-line

  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const user = ReactSession.get("user");
  // const [englishLevel, englishLevel] = useState(profiles)
  // const [skillsListToPost, setSkillsListToPost] = useState(CandidateForEdit?.skills)
  const [skillUpdated, setSkillUpdated] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)

  const saveChanges = () => {
    setShowSpinner(true)
    const candidateToUpdate = candidate?.user?.id ? candidate?.user?.id : candidate?.id;
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateToUpdate}/teclaValidateEnglish/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify({ englishLevel: candidate.englishLevel })
    })
      .then(res => res.json())
      .then(response => {

        if (response.success) {
          
          if (!candidate.englishLevelValidated) {
            const tempCandidate = {...candidate}
            tempCandidate.englishLevelValidated = true;
            tempCandidate.englishValidator = { user: user }
            setCandidate(tempCandidate)
          }

          setShowSpinner(false)
          setShowDoneIcon(true)
          setSkillUpdated(false)

          setTimeout(() => {
            setShowDoneIcon(false)
          }, 1500)
        }
      })
      .catch(error => console.error('There was an error!', error))
  }
  
  return (
    <Card sx={{ maxHeight: "44vh", overflow: "auto" }}>
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2} maxWidth="95%" >
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {profiles.map(({ name, percent, color }) =>
            <SkillSlider
              name={name}
              percent={percent}
              color={color}
              candidate={candidate}
              setCandidate={setCandidate}
              setSkillUpdated={setSkillUpdated}
            />
          )}
        </SuiBox>

        <SuiBox mt={2} mb={2} width='80%' mx='auto' display="flex">
            {/* eslint-disable-next-line */}
            {showSpinner ?
              <SuiBox mx='auto'><CircularProgress size={20} /></SuiBox>
              :
              showDoneIcon ?
                  <SuiBox mx='auto'><DoneIcon size={20} /></SuiBox>
                  :
                  null
            }
            {!showSpinner && skillUpdated ?
              <SuiButton
                sx={{ mx: 1 }}
                fullWidth
                color="info"
                variant="gradient"
                onClick={() => saveChanges()}
              >
                SAVE CHANGES AND VALIDATE
              </SuiButton>
            : null }

        </SuiBox>
      </SuiBox>
    </Card>
  );
}

ProfilesList.defaultProps = {
  comment: "",
  candidate: {},
  setCandidate: null,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  comment: PropTypes.string,
  candidate: PropTypes.arrayOf(PropTypes.object),
  setCandidate: PropTypes.func,
};

export default ProfilesList;
