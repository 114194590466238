/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import { useParams } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import SuiAlert from "components/SuiAlert";
// MUI components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';

function AddToRecruiterFolderModal({ showAddToRecruiterFolderModal, setshowAddToRecruiterFolderModal }) {

    const recruiterFolders = ReactSession.get("recruiterFolders")
    const clientFolders = ReactSession.get("clientFolders")
    const token = ReactSession.get("token")

    const { userId } = useParams()

    const folderOptions = []
    if (Array.isArray(recruiterFolders)) {
        recruiterFolders.forEach(folder => folderOptions.push({ value: folder.id, label: folder.name, public: folder.public }))
    }
    if (Array.isArray(clientFolders)) {
        clientFolders.forEach(folder => folderOptions.push({ value: `clientCode-${folder.id}`, label: `${folder.name} (Client folder)` }))
    }

    const [desiredFolder, setDesiredFolder] = useState(null)
    const [showSpinner, setShowSpinner] = useState(false)
    const [showDoneIcon, setShowDoneIcon] = useState(false)
    const [showError, setShowError] = useState(false)

    const saveCandidateToFolder = (folder, candidatesArray) => {
        // For recruiter folders
            const requestBody = {
                name: folder.name,
                favorite: folder.favorite,
                candidates: candidatesArray,
            }
            requestBody.candidates.push(userId)
    
            fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${desiredFolder}/updateFolder/`, {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                },
                body: JSON.stringify(requestBody)
            })
                .then(async response => {
                    const data = await response.json()
    
                    if (data.success) {
                        setShowSpinner(false)
                        setShowDoneIcon(true)
    
                        setTimeout(() => {
                            setshowAddToRecruiterFolderModal(false)
                            setShowDoneIcon(false)
                            setDesiredFolder(null)
                        }, 1500)
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error)
                    setShowSpinner(false)
                    setShowError(true)
    
                    setTimeout(() => setShowError(false), 3000)
                })
    }

    const fetchFolderContent = () => {
        setShowSpinner(true)

        // For client folders
        if (String(desiredFolder).includes('clientCode')) {
            const clientFolderCode = desiredFolder.slice(desiredFolder.indexOf('-')+1)
            
            fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${clientFolderCode}/addCandidateToFolderClient/`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                },
                body: JSON.stringify({ candidateId: userId })

            })
                .then(async response => {
                    const data = await response.json()
                    
                    if (data.success) {
                        setShowSpinner(false)
                        setShowDoneIcon(true)
    
                        setTimeout(() => {
                            setshowAddToRecruiterFolderModal(false)
                            setShowDoneIcon(false)
                            setDesiredFolder(null)
                        }, 1500)
                    }
                })
                .catch(error => console.error('There was an error!', error))
        }
        // For recruiter folders
        else {
            fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${desiredFolder}/getFolder/`, {
                method: 'GET',
                headers: { "Authorization": `Token ${token}` }
            })
                .then(async response => {
                    const data = await response.json()
    
                    if (data.success) {
                        const candidatesArray = []
                        // eslint-disable-next-line
                        for (const candidate of data.data.candidates) candidatesArray.push(candidate.user.id)
    
                        saveCandidateToFolder(data.data, candidatesArray)
                    }
                })
                .catch(error => console.error('There was an error!', error))
        }

    }

    const saveCandidate = () => fetchFolderContent()

    return (
        <Modal open={showAddToRecruiterFolderModal} onClose={() => setshowAddToRecruiterFolderModal(false)}>
            <SuiBox
                sx={{
                    width: "650px",
                    height: "auto",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                {showDoneIcon && <SuiAlert color="info">Candidate added to folder!</SuiAlert>}
                {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                <SuiTypography sx={{ my: 2.5 }} variant="h6">Add candidate to folder</SuiTypography>

                <SuiTypography sx={{ my: 2.5 }} variant="button">Please select the folder you&apos;d like to save the candidate to:</SuiTypography>

                <SuiBox my={5}>
                    <SuiSelect
                        label="Folder"
                        name="folder"
                        placeholder="Folder"
                        options={folderOptions}
                        onChange={e => setDesiredFolder(e.value)}
                        getOptionLabel={e => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ marginLeft: 5 }}>{e.label}</span>
                              { e.public === true ? (<PublicIcon sx={{ marginLeft: "5px" }} />) : null}
                              { e.public === false ? (<LockIcon sx={{ marginLeft: "5px" }} />) : null}
                            </div>
                          )}
                    />
                </SuiBox>

                <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                    <SuiButton
                        sx={{ mx: 1 }}
                        fullWidth
                        color="info"
                        variant="outlined"
                        disabled={showSpinner || showDoneIcon}
                        onClick={() => {
                            setDesiredFolder(null)
                            setshowAddToRecruiterFolderModal(false)
                        }}
                    >
                            Cancel
                    </SuiButton>

                    {/* eslint-disable-next-line */}
                    {showSpinner ?
                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                        :
                        showDoneIcon ?
                            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                            :
                            <SuiButton sx={{ mx: 1 }} disabled={!desiredFolder} fullWidth color="info" variant="gradient" onClick={() => saveCandidate()}>Confirm</SuiButton>
                    }
                </SuiBox>
            </SuiBox>
        </Modal>
    )
}

export default AddToRecruiterFolderModal

// typechecking props
AddToRecruiterFolderModal.propTypes = {
    showAddToRecruiterFolderModal: PropTypes.func.isRequired,
    setshowAddToRecruiterFolderModal: PropTypes.func.isRequired
};