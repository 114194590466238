/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import React, { useState } from "react";
// SUI components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
// MUI components
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
// Assets
import mockCompanyAvatar from "assets/images/mockCompanyAvatar.png";


function HelpTab({ learningAdvisor }) {

  const [open, setOpen] = useState(false)

  return (
    <SuiBox
      sx={{
        position: 'absolute',
        bottom: open ? 0 : '-13rem',
        right: '1%',
        width: '18rem',
        height: '16rem',
        transition: '.3s'
      }}
    >
        <SuiBox
          onClick={() => setOpen(prev => !prev)}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pl: 3,
            pr: 1.5,
            alignItems: 'center',
            color: 'white !important',
            background: '#367fa5',
            height: '3rem',
            borderRadius: '0.5rem 0.5rem 0 0',
            cursor: 'pointer'
          }}
        >
            <SuiTypography variant='body2' fontWeight='bold' sx={{ color: 'white !important' }}>Need help?</SuiTypography>
            {!open ? <AddIcon fontSize='small' sx={{ mx: 1, color: 'inherit' }}/>
            : <CloseIcon fontSize='small' sx={{ mx: 1, color: 'inherit' }}/>}
        </SuiBox>

      <SuiBox sx={{ display: 'flex', flexDirection: 'column', background: '#367faf !important', p: 3, pt: 1 }}>

        <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
          <SuiAvatar
            size='lg'
            src={learningAdvisor.photo ? `${process.env.REACT_APP_API_ROUTE}${learningAdvisor.photo}` : mockCompanyAvatar}
            alt="advisor avatar"
          />

          <SuiBox sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', ml: 1 }}>
            <SuiTypography variant='body2' fontWeight='bold' sx={{ color: 'white !important' }}>{learningAdvisor.first_name} {learningAdvisor.last_name}</SuiTypography>
            <SuiTypography variant='body2' sx={{ color: 'white !important' }}>{learningAdvisor.email}</SuiTypography>
          </SuiBox>
        </SuiBox>

        <SuiTypography variant='body2' sx={{ color: 'white !important', my: 3, fontSize: '.9rem' }}>Contact your Program Lead via email for lightning-fast support on any questions about Boost (content, platform, bugs, etc.)</SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

// Typechecking props for the HelpTab
HelpTab.propTypes = {
  learningAdvisor: PropTypes.object.isRequired // eslint-disable-line
};

export default HelpTab