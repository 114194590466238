/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types' // eslint-disable-line
import { useState , useEffect } from 'react'
import { ReactSession } from 'react-client-session'
// @mui material components
import Fade from '@mui/material/Fade'
import useMediaQuery from '@mui/material/useMediaQuery'
// Soft UI Dashboard PRO React components
import DataTable from 'examples/Tables/DataTable'
import SuiBox from 'components/SuiBox'
// Table
import ScreeningTableColumns from 'layouts/dashboards/recruiter/screeningTable/data/screeningTableData'

function ScreeningTable() {
    const isMobile = useMediaQuery('(max-width:750px)')
    
    ReactSession.setStoreType('localStorage')
    const recruiter = ReactSession.get('recruiter')

    const [table, seTtable] = useState(ScreeningTableColumns)

    const formatInterviewStatus = status => {
        if (status === -1) return 'N/A'
        if (status === 0) return 'Pending schedule'
        if (status === 1) return 'Scheduled'
        return 'Completed'
    }

    const formatCVStatus = status => {
        if (status === 0) return 'Pending review'
        if (status === 1) return 'Editing'
        return 'Sourcer approved'
    }

    // Fuction to format date from UTC with zero UTC offset (2023-06-26T15:30:00), to local timezone with this format "MM/DD/YYYY - hh:mm AM/PM"
    const convertToLocalTime = utcDate => {
        const utcDateTime = new Date(`${utcDate  }Z`)
    
        // Get the user's locale-specific options
        const localOptions = {  
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }
    
        // Format the date to local time
        return utcDateTime.toLocaleString([], localOptions)
    }
    
    const buildRows = () => {
        const tempRows = []
        recruiter?.applications?.forEach(application => {
            const row = {
                candidate: [`${application?.candidate?.user?.first_name  } ${  application?.candidate?.user?.last_name}`, application?.candidate?.user?.photo, application?.candidate?.user?.id],
                position: [application?.application?.job, application?.application?.id],
                company: [application?.application?.company, application?.application?.companyId],
                interviewStatus: formatInterviewStatus(application?.application?.screeningInterviewState),
                interviewDate: application?.application?.screeningInterviewDate ? convertToLocalTime(application?.application?.screeningInterviewDate) : '',
                cvStatus: formatCVStatus(application?.application?.screeningCVState)
            }
            tempRows.push(row)
        })

        table.rows = tempRows
        seTtable(table)
    }

    useEffect(() => { buildRows() }, [])
    
    return (
        <Fade in timeout={550} sx={{ }}>
            { table?.rows?.length ? (
                <SuiBox my={3}>
                    {!isMobile ? 
                        <SuiBox sx={{ display: 'flex', maxHeight: '30rem' }}>
                            <DataTable entriesPerPage={{defaultValue: 100}} table={table} />
                        </SuiBox>
                    : 
                        <SuiBox sx={{ display: 'flex', maxHeight: '30rem' }}>
                            <DataTable entriesPerPage={{defaultValue: 100}} table={table} />
                        </SuiBox>
                    }
                </SuiBox>
            ) : <SuiBox my={3}></SuiBox> }
        </Fade>
    )
}

// Typechecking props for the CameraView
ScreeningTable.propTypes = {
    
}

export default ScreeningTable