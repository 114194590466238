/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import PropTypes from "prop-types";
// MUI components
import { Modal } from "@mui/material";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from '@mui/material/CircularProgress'
// import Grid from "@mui/material/Grid";
// SUI components
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import SuiAvatar from "components/SuiAvatar";
import SuiSelect from "components/SuiSelect";


import Vimeo from '@u-wave/react-vimeo'
import mockCompanyAvatar from  "assets/images/mockCompanyAvatar.png"
import Swal from "sweetalert2"

function ShowWelcomeWizardModal({ student, setStudent, learningAdvisor }) {

    ReactSession.setStoreType("localStorage");
    const token = ReactSession.get("token");
    const user = ReactSession.get("user"); // eslint-disable-line

    const [open, setOpen] = useState(true);
    const [alertError, setAlertError] = useState("");
    const [readLevel, setReadLevel] = useState(0);
    const [writtenLevel, setWrittenLevel] = useState(0);
    const [verbalLevel, setVerbalLevel] = useState(0);
    const [interestsData, setInterestsData] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false)
    const studentPhoto = learningAdvisor?.photo ? `${process.env.REACT_APP_API_ROUTE}${learningAdvisor?.photo}` : mockCompanyAvatar;

    const handleClose = () => {
        setOpen(false);
    };

    // Validation
    const [activeStep, setActiveStep] = useState(1);
    const [interests, setInterests] = useState([]);

    const setStudentWelcomeData = async () => {
        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/academy/setStudentWelcomeData/`;

        const postBody = {
            'read': readLevel,
            'written': writtenLevel,
            'verbal': verbalLevel,
            'interests': interestsData,
        };

        const requestMetadata = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(postBody)
        };

        setShowSpinner(true);

        fetch(recipeUrl, requestMetadata)
            .then(res => res.json())
            .then(response => {
                $(".alertErrorBox").hide();
                if (response.success) {
                    handleClose()
                    Swal.fire("<span style='font-weight: 200;'>Thank you for sharing your information with us. To ensure you have the best learning experience, your Program Lead will work closely with you to find the coach for you.</span>", "", "success");
                    const tempStudent = {...student}
                    tempStudent.showWelcomeWizard = false;
                    setStudent(tempStudent)
                    ReactSession.set("student", tempStudent)
                } else {
                    setShowSpinner(false);
                    let errorMsg = "Unexepted error";
                    if (response.data && response.data.error) {
                        errorMsg = response.data.error;
                    }
                    setAlertError(errorMsg)
                    setTimeout(() => {
                        setAlertError("")
                    }, 3000);
                }
            });
    }

    const handleNext = () => {
        let error = ""
        if (activeStep === 3 && (!readLevel || !writtenLevel || !verbalLevel)) {
            error = "Please fill all the fields in this section before continuing."
        }
        if (activeStep === 4 && interestsData.length === 0) {
            error = "Please select one or more options."
        }
        if (!error && activeStep !== 4) { 
            setActiveStep(activeStep + 1)
        } else if (!error && activeStep === 4) { 
            setStudentWelcomeData(true)
        } else {
            setAlertError(error)
            setTimeout(() => {
                setAlertError("")
            }, 3000);
        }
    };

    function updateInterests(interestId) {
        const tempInterest = [...interestsData]
        const index = tempInterest.indexOf(interestId)
        if (index >= 0) {
            tempInterest.splice(index, 1);
        } else {
            tempInterest.push(interestId)
        }
        setInterestsData(tempInterest)
    }

    const levels = [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: 10, label: 10 },
    ];
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/getBoostInterests/`)
          .then(async response => {
            const data = await response.json();
            
            if (data.success) {
                setInterests(data.data)
            }
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
        // setCountries([]);
    }, []);

    return (
        <>
            <Modal
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className="welcomeWizard"
            >
                <SuiBox sx={{
                    display: "flex",
                    position: 'relative',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: "60vw", md: "42rem", lg: "43rem", xl: "55rem" },
                    height: { xs: "100vh", md: "auto" },
                    maxHeight: { md: "101vh" },
                    minHeight: { md: "20rem" },
                    background: "white",
                    border: 'none',
                    boxShadow: 16,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    paddingBottom: "80px"
                }}>
                    <SuiBox>
                        <SuiBox>
                            { alertError ? (
                                <SuiBox className="alertErrorBox">
                                  <SuiAlert color="error" className="alertError">{alertError}</SuiAlert>
                                </SuiBox>
                            ) : null }
                            <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                                <SuiTypography id="child-modal-title" variant="overline" opacity={0.75}></SuiTypography>
                            </SuiBox>
                        </SuiBox>
                        { activeStep === 1 ? (
                            <SuiBox>
                                <Vimeo
                                    video="https://vimeo.com/806913713"
                                    autoplay
                                    responsive 
                                  />
                            </SuiBox>
                        ) : null }
                        { activeStep === 2 ? (
                            <SuiBox>
                                <SuiBox mt={2}>
                                  <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4} container justifyContent="center">
                                      <SuiBox position="relative" height="max-content" mx="auto" mt={3}>
                                        <SuiAvatar src={studentPhoto} alt="profile picture" size="xxl" variant="rounded" sx={{ width: "150px", height: "150px" }} />
                                      </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        { learningAdvisor?.bio ? (
                                            <Grid item xs={12} sm={12}>
                                                <SuiTypography variant="button">
                                                  bio
                                                </SuiTypography>
                                                <SuiTypography variant="button" fontWeight="regular" color="text">
                                                    <div dangerouslySetInnerHTML={{ __html: learningAdvisor.bio }} />
                                                </SuiTypography>
                                                <br/>
                                            </Grid>
                                        ) : null }
                                      <Grid item xs={12} sm={12}>
                                        <SuiTypography variant="button">
                                          how to contact
                                        </SuiTypography>
                                        <br/>
                                        <SuiTypography variant="button" fontWeight="regular" color="text">
                                          {learningAdvisor?.email}
                                        </SuiTypography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </SuiBox>
                            </SuiBox>
                        ) : null }
                        { activeStep === 3 ? (
                            <SuiBox>
                                <SuiBox mt={2}>
                                  <Grid container spacing={3}>
                                    <SuiTypography variant="h4" ml={3}>
                                        What’s your level of English?
                                    </SuiTypography>
                                    <Grid item xs={12} sm={12}>
                                      <Grid item xs={12} sm={12}>
                                        <SuiTypography variant="button">
                                          Read
                                        </SuiTypography>
                                        <SuiSelect
                                            placeholder="Select a Level"
                                            options={levels}
                                            name="readLevel"
                                            onChange={e => setReadLevel(e.value)}
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={12}>
                                        <SuiTypography variant="button">
                                          Written
                                        </SuiTypography>
                                        <SuiSelect
                                            placeholder="Select a Level"
                                            options={levels}
                                            name="writtenLevel"
                                            onChange={e => setWrittenLevel(e.value)}
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={12}>
                                        <SuiTypography variant="button">
                                          Verbal
                                        </SuiTypography>
                                        <SuiSelect
                                            placeholder="Select a Level"
                                            options={levels}
                                            name="verbalLevel"
                                            onChange={e => setVerbalLevel(e.value)}
                                          />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </SuiBox>
                            </SuiBox>
                        ) : null }
                        { activeStep === 4 && interests.length ? (
                            <SuiBox>
                                <SuiBox mt={2}>
                                  <Grid container spacing={3}>
                                    <SuiTypography variant="h4" ml={3}>
                                        What are you interested in doing on Boost?
                                    </SuiTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SuiTypography variant="h5" ml={3}>
                                            Select as many as you want
                                        </SuiTypography>
                                        {interests.map((interest) => (
                                            <SuiBox ml={3}>
                                                <Checkbox className="selectSkill" onChange={() => updateInterests(interest.pk)} />
                                                <SuiTypography
                                                variant="button"
                                                fontWeight="regular"
                                                sx={{ userSelect: "none" }}>
                                                    {interest.name}
                                                </SuiTypography>
                                            </SuiBox>
                                        ))}
                                    </Grid>
                                  </Grid>
                                </SuiBox>
                            </SuiBox>
                        ) : null }

                        <SuiBox className="bottomRight">
                            { activeStep !== 4 ? (
                                <SuiButton size="large" color="info"variant="gradient" onClick={() => handleNext()} circular sx={{ float: "right" }}>
                                    Next
                                </SuiButton>
                            ) : (
                                <SuiButton size="large" color="info"variant="gradient" onClick={() => handleNext()} circular sx={{ float: "right" }}>
                                    { showSpinner ? (<CircularProgress size={15}/>) : "Submit" }
                                </SuiButton>
                            ) }
                        </SuiBox>

                    </SuiBox>
                </SuiBox>
            </Modal>
        </>
    );
}

// typechecking props for UserSignUp
ShowWelcomeWizardModal.propTypes = {
    student: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    setStudent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    learningAdvisor: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ShowWelcomeWizardModal;
