/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
import { useParams } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress'
// sui components
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

function Questions({ formData, setActiveStep, selectedQuestions, setSelectedQuestions, updateApplication, showError, isSubmitting, isRedirecting }) { // eslint-disable-line

  ReactSession.setStoreType("localStorage");
  const { appId } = useParams()
  let ApplicationForEdit = ReactSession.get(`ApplicationForEdit${appId}`)
  if (!ApplicationForEdit) {
    ApplicationForEdit = ReactSession.get("ApplicationForEdit");
    ReactSession.set(`ApplicationForEdit${appId}`, ApplicationForEdit);
  }
  const applicationQuestions = ApplicationForEdit.job.questions
  
  let getQuestions = true;
  let questions = [];

  const updateSelectedQuestions = (questionId, answer) => {  
    const quest = selectedQuestions
    quest.filter(q => q.id === questionId)[0].answer = answer

    setSelectedQuestions(quest)
  }

  function questionTypehtml (question) {
    let restHtml = "";
    if (question.type === "line2") {
      if (question.default) restHtml = <SuiInput placeholder="" defaultValue={question.default} onChange={e => updateSelectedQuestions(question.id, e.target.value)} />
      else restHtml = <SuiInput placeholder="" defaultValue={question.previousAnswer} onChange={e => updateSelectedQuestions(question.id, e.target.value)} />

    } else if (question.type === "choice") {
      if (question.default) restHtml = <SuiSelect options={question.choices} defaultValue={{ value: question.default, label: question.default }} onChange={e => updateSelectedQuestions(question.id, e.value)} />
      else restHtml = <SuiSelect options={question.choices} name={`select${question.id}`} defaultValue={{ value: question.previousAnswer, label: question.previousAnswer }} onChange={e => updateSelectedQuestions(question.id, e.value)} />

    } else {
      // eslint-disable-next-line
      if (question.default)restHtml = <SuiInput placeholder="" multiline rows={3} defaultValue={question.default} onChange={e => updateSelectedQuestions(question.id, e.target.value)} />
      else restHtml = <SuiInput placeholder="" multiline rows={3} defaultValue={question.previousAnswer}  onChange={e => updateSelectedQuestions(question.id, e.target.value)} />
    }

    return restHtml;
  }

  function applicationsAnswer (question) {
    // eslint-disable-next-line
    for (const appQuestion of ApplicationForEdit.questions) {
      if (Number(appQuestion.question.id) === Number(question.id)) {
        return appQuestion.answer;
      }
    }

    return "";
  }

  if (getQuestions) {
    getQuestions = false;
    questions = [];
    // eslint-disable-next-line
    for (const additionalQuestion of applicationQuestions) {
      const questionTitle = additionalQuestion.question ? additionalQuestion.question.title : additionalQuestion.title;
      const questionId = additionalQuestion.question ? additionalQuestion.question.id : additionalQuestion.id;
      const questionType = additionalQuestion.question ? additionalQuestion.question.type : additionalQuestion.type;
      const question = {
        title: questionTitle,
        id: questionId,
        type: questionType,
        previousAnswer: applicationsAnswer(additionalQuestion),
      }
      const questionChoices = [];
      const additionalQuestionChoices = additionalQuestion.question ? additionalQuestion.question.choice : additionalQuestion.choice;
      // eslint-disable-next-line
      for (const choice of additionalQuestionChoices.split("|")) { questionChoices.push({ value: choice, label: choice }) }
      question.choices = questionChoices;

      question.input = questionTypehtml(question);
      questions.push(question);
    }
  }

  let timerid = "";
  if (timerid) clearTimeout(timerid);
  timerid = setTimeout(() =>  { getQuestions = true }, 2000);

  const verifyQuestionsInputs = () => {
    let inputsValidated = true

    selectedQuestions.forEach(quest => { if (quest.answer === "") inputsValidated = false })
  
    if (!inputsValidated) showError()
    if (inputsValidated) updateApplication()
  }

  return (
    <SuiBox>
      <SuiBox width="100%" textAlign="center" mb={4}>
        <SuiTypography variant="h5" fontWeight="regular">
          Please answer the following pre-screening questions for this application:
        </SuiTypography>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container spacing={2} className="webLinks">
          {questions.map(question => (
            <Grid item xs={12} md={12}>
              <SuiBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
                mb={2}
                className="questionSelect"
                data-id={question.id}
                key={question.id}
              >
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography variant="button">
                    {question.title}
                  </SuiTypography>
                </SuiBox>
                {question.input}
              </SuiBox>
            </Grid>
          ))}
        </Grid>
      </SuiBox>
            
      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" onClick={() => setActiveStep(prevState => prevState-1)} isSubmitting className="changeStep">Back</SuiButton>
        {isSubmitting ?
          <SuiBox display="flex" justifyContent="center" alignItems="center" mr={2.5}>
            <CircularProgress size={20} />
          </SuiBox >
        :
          <SuiButton variant="gradient" color="info" onClick={() => verifyQuestionsInputs()} className="changeStep nextStep" disabled={isRedirecting}>Submit</SuiButton>
        }
      </SuiBox>

    </SuiBox>
  )
}

// typechecking props for Questions
Questions.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  selectedQuestions: PropTypes.object.isRequired, // eslint-disable-line
  setSelectedQuestions: PropTypes.func.isRequired,
  updateApplication: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isRedirecting: PropTypes.bool.isRequired,
}

export default Questions
