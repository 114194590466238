/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import $ from 'jquery';
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";
// Images
import mockCandidateAvatar from "assets/images/mockCandidateAvatar.png";

function Basic({ formData, candidate, setCandidate }) {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");

  const { formField, values, errors, touched } = formData;
  const { email, firstName, lastName, title, photo, bio } = formField;
  const {
    email: emailV,
    firstName: firstNameV,
    lastName: lastNameV,
    title: titleV,
    photo: photoV,
    bio: bioV
  } = values;

  const defaultAvatar = candidate?.user?.photo ? `${process.env.REACT_APP_API_ROUTE}${candidate.user.photo}` : mockCandidateAvatar;
  const [profileImage, setProfileImage] = useState(defaultAvatar)

  let timerid = "";

  if (timerid) {
    clearTimeout(timerid);
  }

  // timerid = setTimeout(() => {
  //   if (user.photo) {
  //     $(".MuiAvatar-img").attr("src", `${process.env.REACT_APP_API_ROUTE}${user.photo}`)
  //   }
  // }, 200);

  function editImageClick() {
    $("input[name=file]").click();
  }

  useEffect(() => {
    $("input[name=file]").attr("accept", ".jpg, .jpeg, .png");
  }, []);

  function editImage() {
    if (candidate) {
      // eslint-disable-next-line
      const imageField = $("input[name=file]").prop('files')[0];
      const formPostData = new FormData();

      // eslint-disable-next-line no-useless-concat
      const postToken = `Token ${token}`;

      // eslint-disable-next-line
      formPostData.append('file', imageField);
      formPostData.append('candidate', candidate.user.id);

      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/teclaUploadPhoto/`;

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Authorization': postToken
        },
        body: formPostData
      };


      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            setProfileImage(`${process.env.REACT_APP_API_ROUTE}${response.data.user.photo}`);
            const tempCandidate = {...candidate}
            tempCandidate.user.photo = response.data.user.photo;
            setCandidate(tempCandidate)
            if (timerid) {
              clearTimeout(timerid);
            }

            timerid = setTimeout(() => {
              $(".MuiAvatar-img").attr("src", `${process.env.REACT_APP_API_ROUTE}${response.data.user.photo}`);
            }, 200);
          }
        });
    }
  }

  return (
    <SuiBox>
      <SuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} container justifyContent="center">
            <SuiBox position="relative" height="max-content" mx="auto" mt={3}>
              <SuiAvatar src={profileImage} alt="profile picture" size="xxl" variant="rounded" sx={{ width: "150px", height: "150px" }} />
              <SuiBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                <SuiButton variant="gradient" color="light" size="small" iconOnly className="editImage" onClick={editImageClick}>
                  <Icon>edit</Icon>
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid display="none">
              <FormField
                type={photo.type}
                label={photo.label}
                name="file"
                value={photoV}
                placeholder={photo.placeholder}
                error={errors.image && touched.photo}
                success={photoV.length > 0 && !errors.photo}
                onChange={editImage}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>
                Email
              </SuiTypography>
              <FormField
                type={email.type}
                label={email.label}
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={emailV.length > 0 && !errors.email}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>
                First name
              </SuiTypography>
              <FormField
                type={firstName.type}
                label={firstName.label}
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={firstNameV.length > 0 && !errors.firstName}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>
                Last name
              </SuiTypography>
              <FormField
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                error={errors.lastName && touched.lastName}
                success={lastNameV.length > 0 && !errors.lastName}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>
                Title (40 chars. max)
              </SuiTypography>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={titleV}
                placeholder={title.placeholder}
                error={errors.title && touched.title}
                success={titleV.length > 0 && !errors.title}
                inputProps={{ maxLength: 40 }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>
                Tell us a bit more about you (optional - 500 chars. max)
              </SuiTypography>
              <FormField
                type={bio.type}
                label={bio.label}
                name={bio.name}
                value={bioV}
                placeholder={bio.placeholder}
                error={errors.bio && touched.bio}
                success={bioV.length > 0 && !errors.bio}
                multiline
                rows={3}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox >
  );
}

// typechecking props for UserSignUp
Basic.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  candidate: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setCandidate: PropTypes.func.isRequired,
};

export default Basic;
