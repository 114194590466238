/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';

import $ from 'jquery';

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
// import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

// Images
// import team2 from "assets/images/logos/Tecla_Logo_bg.png";
let loadPdf = true;
function Basic({ formData }) {
  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("user");
  let candidate = { ...ReactSession.get("candidate") };
  if (!candidate) candidate = {};
  const [lastApply, setLastApply] = useState({}); // eslint-disable-line
  const [countries, setCountries] = useState([]);
  const [sessionQuestions, setSessionQuestions] = useState([]);

  const [countryPlaceholder, setCountryPlaceholder] = useState("Select Country");
  // const token = ReactSession.get("token");
  const searchParams = new URLSearchParams(window.location.search);
  const jobId = searchParams.get('id');
  const { formField, values, errors, touched } = formData;
  // ,email
  const { firstName, lastName, email, phone } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    phone: phoneV
  } = values;

  // Extra questions
  // const [extraQuestions, setExtraQuestions] = useState([]);
  function loadExtraQuestions(extraSessionQuestions) {
    const tempQuestions = [];
    // eslint-disable-next-line
    for (const question of extraSessionQuestions) {
      const questionJson = {
        title: question.title,
        id: question.id, 
        answer: null, 
        required: question.required, 
        choices: question.choices, 
        type: question.type, 
        default: question.default
      }
      const questionChoices = [];
      // eslint-disable-next-line
      for (const choice of question.choice.split("|")) {
        questionChoices.push({ value: choice, label: choice });
      }
      questionJson.choices = questionChoices;
      if (lastApply && lastApply?.questions && lastApply?.questions.length) {
        // eslint-disable-next-line
        for (const additional of lastApply?.questions) {
          if (additional.id && Number(additional.id) === Number(question.id)) {
            questionJson.default = additional.answer;
          }
        }
      }
      tempQuestions.push(questionJson);
    }
    setSessionQuestions(tempQuestions);
  }

  const [isFreelanceJob, setIsFreelanceJob] = useState(false)

  useEffect(() => {
    if (jobId) {
      const url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${jobId}/jobStandalone/`;
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      fetch(url, { headers, })
        .then(async response => {
          const res = await response.json();

          if (res?.data?.job?.commitment?.name === "Freelance") setIsFreelanceJob(true)

          validateToken(res) // eslint-disable-line
          if (res.success && res.data && res.data.job && res.data.job.questions) {
            const resQuestions = res.data.job.questions;
            const resIsQuestion = [];
            // eslint-disable-next-line
            for (const resQuestion of resQuestions) {
              if (!resQuestion.isJobQuestion) {
                resIsQuestion.push(resQuestion);
              }
            }
            // console.log(resIsQuestion)
            setSessionQuestions(resIsQuestion);
            loadExtraQuestions(resIsQuestion)
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, []);

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj

    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateCountries = async () => {

    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = [];
    // eslint-disable-next-line
    Object.keys(interests?.data?.countries).forEach(key => {
      newData.push({ value: interests?.data?.countries[key]?.id, label: interests?.data?.countries[key]?.name });

      if (candidate && candidate?.country && interests?.data?.countries[key]?.id === candidate?.country.id) {
        setCountryPlaceholder(interests?.data?.countries[key]?.name);
      }

      if (!candidate && lastApply && lastApply?.country && lastApply?.country.id === interests?.data?.countries[key]?.id) {
        setCountryPlaceholder(interests?.data?.countries[key]?.name);
      }
    });
    setCountries(newData);
  }

  useEffect(() => { populateCountries() }, []);

  function changeCountry(e) {
    $(".countryBox").attr("data-val", e.value);
  }

  if (candidate && candidate?.phone && candidate?.phone.includes("+")) {
    candidate.phone = candidate?.phone.slice(1);
  }


  const socialMediaList = [];
  const socialMediaInputsDefault = [];
  if ((lastApply && lastApply?.linkedin) || (candidate && candidate?.linkedin)) { 
    socialMediaInputsDefault.push({ label: "LinkedIn Profile", name: "linkedin", value: lastApply?.linkedin ? lastApply?.linkedin : candidate?.linkedin })
  } else {
    socialMediaInputsDefault.push({ label: "LinkedIn Profile", name: "linkedin", value: "" })
  }
  if ((lastApply && lastApply?.github) || (candidate && candidate?.github)) { 
    socialMediaInputsDefault.push({ label: "Github", name: "github", value: lastApply?.github ? lastApply?.github : candidate?.github })
  } else {
    socialMediaList.push({ value: "github", label: "Github" })
  }
  if ((lastApply && lastApply?.portfolio) || (candidate && candidate?.portfolio)) { 
    socialMediaInputsDefault.push({ label: "Portfolio", name: "portfolio", value: lastApply?.portfolio ? lastApply?.portfolio : candidate?.portfolio })
  } else {
    socialMediaList.push({ value: "portfolio", label: "Portfolio" })
  }
  if ((lastApply && lastApply?.website) || (candidate && candidate?.website)) { 
    socialMediaInputsDefault.push({ label: "Website", name: "website", value: lastApply?.website ? lastApply?.website : candidate?.website })
  } else {
    socialMediaList.push({ value: "website", label: "Website" })
  }
  if ((lastApply && lastApply?.twitter) || (candidate && candidate?.twitter)) { 
    socialMediaInputsDefault.push({ label: "Twitter", name: "twitter", value: lastApply?.twitter ? lastApply?.twitter : candidate?.twitter })
  } else {
    socialMediaList.push({ value: "twitter", label: "Twitter" })
  }
  const [socialMediaOptions, setSocialMediaOptions] = useState(socialMediaList);
  const [socialMediaInputs, setSocialMediaInputs] = useState(socialMediaInputsDefault);

  function addMediaInput() {
    const media = $("[name=media]").val();
    const tempMedia = [];
    const temsocialMediaInputs = [...socialMediaInputs]
    // eslint-disable-next-line
    for (const socialMedia of [...socialMediaOptions]) {
      if (media !== socialMedia.value) {
        tempMedia.push(socialMedia);
      } else {
        temsocialMediaInputs.push({ label: socialMedia.label, name: socialMedia.value, value: "" })
      }
    }
    setSocialMediaInputs(temsocialMediaInputs);
    setSocialMediaOptions(tempMedia);
  }

  function closeMediaInput(mediaName, mediaLabel) {
    const temsocialMediaInputs = []
    const tempMedia = [...socialMediaOptions];
    // eslint-disable-next-line
    for (const socialMediaInput of [...socialMediaInputs]) {
      if (mediaName !== socialMediaInput.name) {
        temsocialMediaInputs.push(socialMediaInput);
      }
    }
    tempMedia.push({ value: mediaName, label: mediaLabel });

    setSocialMediaInputs(temsocialMediaInputs);
    setSocialMediaOptions(tempMedia);
  }

  // Skills ------------------------------------

  if (!lastApply?.interviewInEnglish && lastApply?.interviewInEnglish !== false && (candidate?.interviewInEnglish || candidate?.interviewInEnglish === false)) {
    lastApply.interviewInEnglish = candidate?.interviewInEnglish;
  }
  if (lastApply?.interviewInEnglish === true) {
    lastApply.interviewInEnglishExtra = "Yes";
  } else if (lastApply?.interviewInEnglish === false) {
    lastApply.interviewInEnglishExtra = "No";
  }

  if (!lastApply?.canWorkRemotely && lastApply?.canWorkRemotely !== false && (candidate?.canWorkRemotely || candidate?.canWorkRemotely === false)) {
    lastApply.canWorkRemotely = candidate?.canWorkRemotely;
  }
  if (lastApply?.canWorkRemotely === true) {
    lastApply.canWorkRemotelyExtra = "Yes";
  } else if (lastApply?.canWorkRemotely === false) {
    lastApply.canWorkRemotelyExtra = "No";
  }

  if (!lastApply?.englishLevel && candidate?.englishLevel && !candidate?.firstApplication) {
    lastApply.englishLevel = String(candidate?.englishLevel);
  }

  let defaultSalary = 0;
  if (lastApply?.salary) {
    defaultSalary = lastApply?.salary;
  }

  // eslint-disable-next-line
  $(document).unbind().on("keypress", "input[name=salary]", function (e) {
    if (e.keyCode === 46) {
        e.preventDefault();
    }
});

  let timerid = "";

  if (timerid) {
    clearTimeout(timerid);
  }

  function resetLoad() {
    let resumeTimerid = "";
    if (resumeTimerid) {
      clearTimeout(resumeTimerid);
    }
    resumeTimerid = setTimeout(() => {
      loadPdf = true;
    }, 500);
  }

  useEffect(() => {
    timerid = setTimeout(() => {
      $("input[name=cv]").attr("accept", ".pdf");
      if (loadPdf && candidate && candidate?.cv) {
        loadPdf = false;
        fetch(`${process.env.REACT_APP_API_ROUTE}${candidate?.cv}`)
          .then((response) => response.blob())
          .then((blob) => {
            let cvName = "resume.pdf";
            if (lastApply && lastApply?.cvName) {
              cvName = lastApply?.cvName;
            }
            const file = new File([blob], cvName);
            const container = new DataTransfer();
            container.items.add(file);
            $("input[name=cv]")[0].files = container.files;
            resetLoad();
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
      }
    }, 100);
  }, []);

  function showError(text) {
    let textError = text;
    if (!textError) {
      textError = "There was an unknown error uploading the file.";
    }
    let timerSuccessid = "";
    $(".alertErrorBox div div").text(textError);
    $(".alertErrorBox").show();
    $('html, body').animate({
      scrollTop: $(".alertErrorBox").offset().top
    }, 100);
    if (timerSuccessid) {
      clearTimeout(timerSuccessid);
    }
    timerSuccessid = setTimeout(() => {
      $(".alertErrorBox").hide();
    }, 5000);
  }

  function uploadResume() {
    const resumeField = $("input[name=cv]").prop('files')[0];
    const formCvPostData = new FormData();
    formCvPostData.append('cv', resumeField);
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/uploadCVApply/`;
    const normalizedName = "cv.pdf"
    const requestMetadata = {
      method: 'PATCH',
      headers: {
        "Content-Disposition": `attachment; filename=${normalizedName}`
      },
      body: formCvPostData
    };

    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          const lastApplySession = { ...ReactSession.get("lastApply") };
          lastApplySession.tempCV = response.data.tempCV;
          ReactSession.set("lastApply", lastApplySession);
        } else if (response.data.error) {
          $("input[name=cv]").val("");

          showError(response.data.error);
        }
      })
      .catch(error => showError(error))
  }

  const interviewInEnglishArray = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
  ];
  const englishLevelArray = [
    { value: "1", label: "1 (Not able to speak English at all)" },
    { value: "2", label: "2 (Not able to speak English at all)" },
    { value: "3", label: "3 (Not able to speak English at all)" },
    { value: "4", label: "4 (Not able to speak English at all)" },
    { value: "5", label: "5 (Currently learning; not able to speak yet)" },
    { value: "6", label: "6 (I can speak but with lots of mistakes)" },
    { value: "7", label: "7 (I can hold a conversation with a few mistakes)" },
    { value: "8", label: "8 (I can hold a conversation easily; minor mistakes)" },
    { value: "9", label: "9 (Excellent English; no issues at all)" },
    { value: "10", label: "10 (English is like my first language)" },
  ];
  const availabilityArray = [
    { value: "1-week", label: "1 Week" },
    { value: "2-weeks", label: "2 Weeks" },
    { value: "3-weeks", label: "3 Weeks" },
    { value: "4-weeks", label: "4 Weeks" },
  ];


  // Additional --------------------------------
  function onChangeQuestion(questionId, questionValue) {
    if (questionId && questionValue) {
      const lastApplySession = { ...ReactSession.get("lastApply") };
      const lastApplyQuestions = lastApplySession.questions ? lastApplySession.questions : [];
      const newQuestions = [];
      const questionsFilter = lastApplyQuestions.filter(x => x.id === questionId);
      // eslint-disable-next-line
      for (const lastApplyQuestion of lastApplyQuestions) {
        // eslint-disable-next-line
        if (lastApplyQuestion.id === questionId) {
          lastApplyQuestion.answer = questionValue;
        }

        newQuestions.push(lastApplyQuestion);
      }
      
      if (!questionsFilter.length) {
        newQuestions.push({ id: questionId, answer: questionValue })
      }
      lastApplySession.questions = newQuestions;
      ReactSession.set("lastApply", lastApplySession)
    }
  }

  let additionalQuestions = ReactSession.get("additionalQuestions");
  const [questions, setQuestions] = useState([]);

  function questionTypehtml(question) {
    let restHtml = "";
    if (question.type === "line") {
      if (question.default) {
        restHtml = <SuiInput onChange={e => onChangeQuestion(question.id, e.value)} placeholder="" defaultValue={question.default} />
      } else {
        restHtml = <SuiInput onChange={e => onChangeQuestion(question.id, e.value)} placeholder="" />
      }
    } else if (question.type === "choice") {
      if (question.default) {
        restHtml = <SuiSelect onChange={e => onChangeQuestion(question.id, e.value)} options={question.choices} isSearchable={false} defaultValue={{ value: question.default, label: question.default }} />
      } else {
        restHtml = <SuiSelect onChange={e => onChangeQuestion(question.id, e.value)} options={question.choices} isSearchable={false} name={`select${question.id}`} />
      }
    } else {
      // eslint-disable-next-line
      if (question.default) {
        restHtml = <SuiInput onChange={e => onChangeQuestion(question.id, e.value)} placeholder="" multiline rows={3} defaultValue={question.default} />
      } else {
        restHtml = <SuiInput onChange={e => onChangeQuestion(question.id, e.value)} placeholder="" multiline rows={3} />
      }
    }

    return restHtml;
  }

  function setQuestionsFunction (additionalQuestionsFunction) {
    const tempQuestions = [];
    // eslint-disable-next-line
    for (const additionalQuestion of additionalQuestionsFunction) {
      const question = {
        title: additionalQuestion.title,
        id: additionalQuestion.id,
        type: additionalQuestion.type
      }
      const questionChoices = [];
      // eslint-disable-next-line
      for (const choice of additionalQuestion.choice.split("|")) {
        questionChoices.push({ value: choice, label: choice });
      }
      question.choices = questionChoices;
      if (lastApply && lastApply?.questions && lastApply?.questions.length) {
        // eslint-disable-next-line
        for (const additional of lastApply?.questions) {
          if (additional.id && Number(additional.id) === Number(additionalQuestion.id)) {
            question.default = additional.answer;
          }
        }
      }
      question.input = questionTypehtml(question);
      tempQuestions.push(question);
    }
    setQuestions(tempQuestions);
  }

  useEffect(() => {
    setTimeout(() => {
      additionalQuestions = ReactSession.get("additionalQuestions");
      if (additionalQuestions) {
        setQuestionsFunction(additionalQuestions)
      } else {
        const url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${jobId}/jobStandalone/`;
        const headers = { "Content-Type": "application/x-www-form-urlencoded" };
        fetch(url, { headers, })
          .then(async response => {
            const res = await response.json();
        validateToken(res) // eslint-disable-line
            if (res.success && res.data && res.data.job && res.data.job.questions) {
              const resQuestions = res.data.job.questions;
              const resIsQuestion = [];
              const resAdditionalQuestion = [];
              // eslint-disable-next-line
              for (const resQuestion of resQuestions) {
                if (resQuestion.isJobQuestion) {
                  resAdditionalQuestion.push(resQuestion);
                } else {
                  resIsQuestion.push(resQuestion);
                }
              }
              ReactSession.set("questions", resIsQuestion);
              ReactSession.set("additionalQuestions", resAdditionalQuestion);
              additionalQuestions = ReactSession.get("additionalQuestions");
              if (resAdditionalQuestion.length) {
                setQuestionsFunction(resAdditionalQuestion)
              }
            }
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
      }
    }, 500);
  }, []);

  // Extra Questions --------------------------
  const updateResponse = (id, value) => {
      const questionsCopy = [...sessionQuestions]
      questionsCopy.filter(item => item.id === id)[0].answer = value
      setSessionQuestions(questionsCopy)
      onChangeQuestion(id, value)
  }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">
          Basic details and contact information
        </SuiTypography>
      </SuiBox>
      <SuiBox>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <SuiTypography variant="button" ml={1} mb={1}>
                  First name
                </SuiTypography>
                {user && user.first_name ? (
                  <FormField
                    type={firstName.type}
                    label={firstName.label}
                    name={firstName.name}
                    disabled="disabled"
                    value={user.first_name}
                  />
                ) :
                  <FormField
                    type={firstName.type}
                    label={firstName.label}
                    name={firstName.name}
                    value={firstNameV}
                    placeholder={firstName.placeholder}
                    error={errors.firstName && touched.firstName}
                    success={firstNameV.length > 0 && !errors.firstName}
                  />
                }
              </Grid>
              <Grid item xs={6}>
                <SuiTypography variant="button" ml={1} mb={1}>
                  Last name
                </SuiTypography>
                {user && user.last_name ? (
                  <FormField
                    type={lastName.type}
                    label={lastName.label}
                    name={lastName.name}
                    disabled="disabled"
                    value={user.last_name}
                  />
                ) :
                  <FormField
                    type={lastName.type}
                    label={lastName.label}
                    name={lastName.name}
                    value={lastNameV}
                    placeholder={lastName.placeholder}
                    error={errors.lastName && touched.lastName}
                    success={lastNameV.length > 0 && !errors.lastName}
                  />
                }
              </Grid>
            </Grid>
            {!user ? (
              <Grid item xs={12}>
                <SuiTypography variant="button" ml={1} mb={1}>
                  Email address
                </SuiTypography>
                <FormField
                  type={email.type}
                  label={email.label}
                  name={email.name}
                  value={emailV}
                  placeholder={email.placeholder}
                  error={errors.email && touched.email}
                  success={emailV.length > 0 && !errors.email}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} className="countryBox">
              <SuiTypography variant="button" ml={1}>
                Location
              </SuiTypography>
              {candidate && candidate?.country ? (
                <FormField
                    type="text"
                    label="country"
                    name="country"
                    disabled="disabled"
                    value={candidate?.country.name}
                  />
              ) :
                <SuiSelect
                  placeholder={countryPlaceholder}
                  options={countries}
                  name="country"
                  onChange={changeCountry}
                />
              }
              {!candidate || !candidate?.country ? (
                <br />
              ) : null }
            </Grid>
            <Grid item xs={12}>
              <SuiTypography variant="button" ml={1} mb={1}>
                Phone number (including code - optional)
              </SuiTypography>
              {candidate && candidate?.phone ? (
                <FormField
                  number
                  type={phone.type}
                  label={phone.label}
                  name={phone.name}
                  disabled="disabled"
                  value={candidate?.phone}
                  icon={{
                    component: "add",
                    direction: "left",
                  }}
                  // event on paste
                  onPaste={e => {
                    e.preventDefault();
                    // remove all characters except numbers
                    const paste = e.clipboardData.getData("text/plain").replace(/\D/g, "");
                    // insert pasted text
                    document.execCommand("insertText", false, paste);
                  }}
                />
              ) :
                <FormField
                  number
                  type={phone.type}
                  label={phone.label}
                  name={phone.name}
                  value={phoneV}
                  placeholder={phone.placeholder}
                  error={phone.email && touched.phone}
                  success={phoneV.length > 0 && !errors.phone}
                  icon={{
                    component: "add",
                    direction: "left",
                  }}
                  // event on paste
                  onPaste={e => {
                    e.preventDefault();
                    // remove all characters except numbers
                    const paste = e.clipboardData.getData("text/plain").replace(/\D/g, "");
                    // insert pasted text
                    document.execCommand("insertText", false, paste);
                  }}
                />
              }
            </Grid>
            <Grid item xs={12} mb={2}>
              <SuiBox ml={1} mb={0.5}>
                <SuiTypography variant="button" ml={1}>
                  Social Media Links
                </SuiTypography>
              </SuiBox>

              {socialMediaInputs.map(socialMediaInput => { // eslint-disable-line
                if (socialMediaInput.name === "linkedin") return (
                  <Grid item xs={12} sx={{ position: "relative" }} mb={1}>
                    <SuiInput
                      type="text"
                      name={socialMediaInput.name}
                      label="Linkedin"
                      style={{ paddingRight: "40px" }}
                      defaultValue={socialMediaInput.value}
                      placeholder={socialMediaInput.label}
                    />
                  </Grid>
                )
              })}

              <Grid container xs={12} md={12} spacing={1}>
                <Grid item xs={9} md={9}>
                  <SuiSelect
                    placeholder="Select social media"
                    options={socialMediaOptions}
                    name="media"
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <SuiButton color="info" fullWidth onClick={() => addMediaInput()} >Add</SuiButton>
                </Grid>
              </Grid>

            </Grid>

            {socialMediaInputs.map(socialMediaInput => { // eslint-disable-line
              if (socialMediaInput.name !== "linkedin") return (
                <Grid item xs={12} sx={{ position: "relative" }} mb={1}>
                  <SuiInput
                    type="text"
                    name={socialMediaInput.name}
                    label="Linkedin"
                    style={{ paddingRight: "40px" }}
                    defaultValue={socialMediaInput.value}
                    placeholder={socialMediaInput.label}
                  />
                  <Icon className="closeMedia" onClick={() => closeMediaInput(socialMediaInput.name, socialMediaInput.label)} >close</Icon>
                </Grid>
              )
            })}

          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox>
        <SuiBox width="100%" textAlign="center" mt={3} mb={3}>
          <SuiTypography variant="h5" fontWeight="regular">
            Application Questions
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={2} mb={4}>
          <SuiBox ml={1}>
            <SuiTypography variant="button">
              Resume / CV
            </SuiTypography>
          </SuiBox>
          <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={uploadResume} />
          <SuiTypography variant="body2" fontWeight="regular" color="text" ml={1}>
            Please upload your CV here in PDF format. If you already have an account you can apply using the information saved in your profile
          </SuiTypography>
        </SuiBox>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          mb={4}
        >
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography variant="button">
              Are you able to hold an interview in English?
            </SuiTypography>
          </SuiBox>
          {lastApply?.interviewInEnglishExtra ? (
            <SuiSelect
              name="interviewInEnglish"
              options={interviewInEnglishArray}
              defaultValue={interviewInEnglishArray.find(({ value }) => value === lastApply?.interviewInEnglishExtra)}
              isSearchable={false}
            />
          ) : (
            <SuiSelect
              name="interviewInEnglish"
              options={interviewInEnglishArray}
              isSearchable={false}
            />
          )}
        </SuiBox>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          mb={4}
        >
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography variant="button">
              On a scale of 1-10: what is your level of spoken English?
            </SuiTypography>
          </SuiBox>
          {lastApply?.englishLevel ? (
            <SuiSelect
              name="englishLevel"
              options={englishLevelArray}
              defaultValue={englishLevelArray.find(({ value }) => value === lastApply?.englishLevel)}
              isSearchable={false}
            />
          ) : (
            <SuiSelect
              name="englishLevel"
              options={englishLevelArray}
              isSearchable={false}
            />
          )}
        </SuiBox>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          mb={4}
        >
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography variant="button">
              Will you be able to work with your own equipment?
            </SuiTypography>
          </SuiBox>
          {lastApply?.canWorkRemotelyExtra ? (
            <SuiSelect
              name="canWorkRemotely"
              options={interviewInEnglishArray}
              defaultValue={interviewInEnglishArray.find(({ value }) => value === lastApply?.canWorkRemotelyExtra)}
              isSearchable={false}
            />
          ) : (
            <SuiSelect
              name="canWorkRemotely"
              options={interviewInEnglishArray}
              isSearchable={false}
            />
          )}
        </SuiBox>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          mb={5}
        >
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography variant="button">
              When are you available to start a new position?
            </SuiTypography>
          </SuiBox>
          {lastApply?.availability ? (
            <SuiSelect
              name="availability"
              options={availabilityArray}
              defaultValue={availabilityArray.find(({ value }) => value === lastApply?.availability)}
              isSearchable={false}
            />
          ) : (
            <SuiSelect
              name="availability"
              options={availabilityArray}
              isSearchable={false}
            />
          )}
        </SuiBox>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          mb={5}
        >
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            {isFreelanceJob ?
              <SuiTypography variant="button">What is your hourly rate expectation in USD?</SuiTypography>
              :
              <SuiTypography variant="button">What is your monthly salary expectation in USD?</SuiTypography>
            }
          </SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuiInput type="number" placeholder="Expected salary" name="salary" defaultValue={defaultSalary} />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>

      { questions && questions.length ? (
        <SuiBox>
          <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
            <SuiTypography variant="h5" fontWeight="regular">
              Additional Information
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={2}>
            {questions.map(question => (
              <SuiBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
                mb={2}
                className="questionSelect"
                data-id={question.id}
              >
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography variant="button">
                    {question.title}
                  </SuiTypography>
                </SuiBox>
                {question.input}
              </SuiBox>
            ))}
          </SuiBox>
        </SuiBox>
      ) : null }

      { sessionQuestions.length ? (
        <SuiBox>
          <br/>
          <SuiBox width="100%" textAlign="center" mb={2}>
            <SuiTypography variant="h5" fontWeight="regular">
              Please answer the following pre-screening questions for this application:
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={2}>
            <Grid container spacing={2} className="webLinks">
              {sessionQuestions.map(question => (
                <Grid item xs={12} md={12}>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                    mb={2}
                    className="questionSelect"
                    data-id={question.id}
                  >
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography variant="button">
                        {question.title}
                      </SuiTypography>
                    </SuiBox>
                    {question.type === "choice" ? (
                      <>
                        {question.type === "default" ? (
                          <SuiSelect onChange={e => onChangeQuestion(question.id, e.value)} options={question.choices} defaultValue={{ value: question.default, label: question.default }} />
                        ) : (
                          <SuiSelect onChange={e => onChangeQuestion(question.id, e.value)} options={question.choices} name={`select${question.id}`} />
                        )}
                      </>
                    ) : (
                      <SuiBox>
                        {question.type === "default" ? (
                          <SuiInput placeholder="" multiline rows={3} defaultValue={question.default} inputProps={{ maxLength: 5000 }} onChange={e => updateResponse(question.id, e.target.value)} />
                        ) : (
                          <SuiInput placeholder="" multiline rows={3} inputProps={{ maxLength: 5000 }} onChange={e => updateResponse(question.id, e.target.value)} />
                        )}
                        <SuiTypography
                            mt={1}
                            variant="subtitle2"
                            color={sessionQuestions?.filter(item => item?.id === question?.id)[0]?.answer?.length > 5000 ? "error" : "auto"}
                            fontSize=".9rem"
                            align="right"
                        >
                            {sessionQuestions?.filter(item => item?.id === question?.id)[0]?.answer?.length || "0"}/5000 chars.
                        </SuiTypography>
                      </SuiBox>
                    )}
                  </SuiBox>
                </Grid>
              ))}
            </Grid>
          </SuiBox>

        </SuiBox>
      ) : null }

    </SuiBox >

  );
}

// typechecking props for UserSignUp
Basic.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Basic;
