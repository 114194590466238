/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiPagination from "components/SuiPagination";
// Soft UI Dashboard PRO React example components
import DataTable from "examples/Tables/DataTable"
import dataTableData from "layouts/pages/searchRecruiter/data/dataTableData";
import dataTableDataSmall from "layouts/pages/searchRecruiter/data/sm/dataTableDataSmall";

function ProductsList({ applicationsData, applicationsDataSmall, pagination, setPage, filters, totalResults, isFetchingData, originalCandidateName }) {

  return (
    <Card style={{ position: "relative" }}>
      {isFetchingData ?
        <SuiBox display="flex" justifyContent="center" alignItems="center" my={30} >
          <CircularProgress size={40} />
        </SuiBox>
      :
        <>
          <SuiBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography variant="h5" fontWeight="medium">Candidates</SuiTypography>
              <SuiTypography variant="button" fontWeight="regular" color="text">
                Here you can see a list of candidates with profiles similar to {originalCandidateName}
              </SuiTypography>
            </SuiBox>
          </SuiBox>

          <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
            <DataTable entriesPerPage={{ defaultValue: 100 }} table={applicationsData} totalResults={totalResults} view="candidates" paginationData={pagination} />
          </SuiBox>

          <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
            <DataTable entriesPerPage={{ defaultValue: 100 }} table={applicationsDataSmall} totalResults={totalResults} view="candidates" paginationData={pagination} />
          </SuiBox>

          <SuiBox mb={9} className="paginationBox" style={{ margin: "20px auto 40px auto" }}>
            <SuiPagination>
              {(pagination.count > 0 && pagination.hasPrev) && (
                <SuiPagination item className="pageReloadPrev" onClick={() => setPage(filters.page - 1)}>
                  <Icon>keyboard_arrow_left</Icon>
                </SuiPagination>
              )}

              {(pagination.count > 0)  && pagination.pagesHtml}

              {(pagination.count > 0 && pagination.hasNext) && (
                <SuiPagination item className="pageReloadNext" onClick={() => setPage(filters.page + 1)}>
                  <Icon>keyboard_arrow_right</Icon>
                </SuiPagination>
              )}
            </SuiPagination>
          </SuiBox>
        </>
      }
    </Card>
  );
}

ProductsList.defaultProps = {
  applicationsData: dataTableData,
  applicationsDataSmall: dataTableDataSmall,
  pagination: { pages: [] },
  totalResults: null,
  originalCandidateName: '',
};

// typechecking props for UserSignUp
ProductsList.propTypes = {
  applicationsData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  applicationsDataSmall: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  setPage: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  filters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  totalResults: PropTypes.number,
  isFetchingData: PropTypes.bool.isRequired,
  originalCandidateName: PropTypes.string
}

export default ProductsList;

