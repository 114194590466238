/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types' // eslint-disable-line
// Soft UI Dashboard PRO React components
import { useState } from 'react'
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
// import SuiAvatar from 'components/SuiAvatar'
// import CircularProgressWithLabel from 'components/circularProgressWithLabel/CircularProgressWithLabel'
// MUI components
// import Card from '@mui/material/Card'
import SidenavCourseCollapse from "examples/Sidenav/SidenavCourseCollapse";
import SuiButton from "components/SuiButton";
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useMediaQuery from '@mui/material/useMediaQuery'
import SuiProgress from "components/SuiProgress";
// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

function FullContent({ courses, setLessons, setModuleSelected, courseSelected, setCourseSelected, setVideoDisplayed }) {

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')
  const [openCollapse, setOpenCollapse] = useState(false);

  function setModuleAndLesson(moduleToSet, courseToSet, lessonToSet) {
    if (lessonToSet) {
      setVideoDisplayed(lessonToSet)
    }
    setLessons(moduleToSet.lessons)
    setModuleSelected(moduleToSet)
    setCourseSelected(courseToSet)

    let defaultUrl = "/t/dashboard/courses"
    if (courseToSet) {
      defaultUrl = `${defaultUrl}/${courseToSet.pk}`
    }
    if (moduleToSet) {
      defaultUrl = `${defaultUrl}/${moduleToSet.pk}`
    }
    if (lessonToSet && lessonToSet.lesson) {
      defaultUrl = `${defaultUrl}/${lessonToSet.pk}`
    } else if (lessonToSet) {
      defaultUrl = `${defaultUrl}/test`
    }

    window.history.pushState({}, "", defaultUrl);
    window.scrollTo(0, 0);
  }

  let sectionsLen = 0;
  let lessonsLen = 0;
  let lessonsCompletedLen = 0;
  let lessonToResume = null;
  let moduleToResume = null;
  const currentLesson = courseSelected.currentLesson >= 1 ? courseSelected.currentLesson : 1;
  if (courseSelected && !sectionsLen && courseSelected.modules.length) {
    for (const tempModule of courseSelected.modules) { // eslint-disable-line
      sectionsLen += 1;
      for (const tempLesson of tempModule.lessons) { // eslint-disable-line
        lessonsLen += 1;
        lessonsCompletedLen += tempLesson.completed ? 1 : 0;
        if (currentLesson === tempLesson.pk && tempLesson?.lesson) {
          lessonToResume = tempLesson;
          moduleToResume = tempModule;
        }
      }
    }
    if (!lessonToResume) {
      lessonToResume = courseSelected.modules[0].lessons[0]; // eslint-disable-line
      moduleToResume = courseSelected.modules[0]; // eslint-disable-line
    }
  }

  function secondsToMinutes(seconds) {
    if (!seconds || seconds <= 0) return ''

    let minutesToPrint = Math.floor(seconds / 60);
    let secondsToPrint = Math.floor(seconds % 60);

    minutesToPrint = minutesToPrint < 10 ? `0${minutesToPrint}` : `${minutesToPrint}`;
    secondsToPrint = secondsToPrint < 10 ? `0${secondsToPrint}` : `${secondsToPrint}`;

    return `${minutesToPrint}:${secondsToPrint}`
  }

  function setCourseCollapse(courseCollapseToSet) {
    setCourseSelected(courseCollapseToSet)
    window.history.pushState({}, "", `/t/dashboard/courses/${courseCollapseToSet.pk}`);
    setOpenCollapse(false)
  }

  return (
      <SuiBox className="customCourse">
        <SuiBox display="flex" flexWrap="wrap" width="100%" alignItems="center" justifyContent={{ sx: "start", xl: "space-between" }} my={2} sx={{ position: "relative" }}>
          <SuiBox lineHeight={1} sx={{ position: "absolute", top: 0 }} >
            <SidenavCourseCollapse
              color="#fff"
              icon={openCollapse ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              key={`module-${courseSelected.pk}`}
              name={courseSelected?.course?.title}
              open={openCollapse}
              onClick={() => (setOpenCollapse(!openCollapse))}
            >
              {courses.map((courseMap) => (
                <SuiTypography 
                  variant='h6' 
                  ml={3} 
                  onClick={() => setCourseCollapse(courseMap)} 
                  className="courseTypography"
                  >
                  {courseMap.progress >= 100 ? (
                    <CheckCircleIcon sx={{ mr: 1, verticalAlign: "middle", color: "#008a2f" }} />
                  ) : (
                    <RadioButtonUncheckedIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                  )}
                  {courseMap?.course?.title}
                </SuiTypography>

              ))}
            </SidenavCourseCollapse>
          </SuiBox>
          <div></div>
          <SuiBox>
            <SuiButton color="info" onClick={() => setModuleAndLesson(moduleToResume, courseSelected, lessonToResume)}> Continue Course </SuiButton>
          </SuiBox>
        </SuiBox>

        <SuiBox display='flex' flexDirection='column' sx={{ marginTop: "30px" }}>
          <SuiTypography variant='h5' fontWeight="bold">Course progress</SuiTypography>
        </SuiBox>
        <SuiBox className="courseProgress" display='flex' flexDirection='column'>
          <SuiTypography variant='h6'>Completed {lessonsCompletedLen} of {lessonsLen} lessons</SuiTypography>
          <SuiProgress variant="gradient" color="info" value={Math.ceil(lessonsCompletedLen / lessonsLen * 100)} sx={{ marginTop: "15px" }} />
        </SuiBox>
        <SuiBox display='flex' flexDirection='column' sx={{ marginBottom: "15px" }}>
          <SuiTypography variant='h5' fontWeight="bold">Course content</SuiTypography>
          <SuiTypography variant='h5' fontWeight="regular">{sectionsLen} Modules - {lessonsLen} lessons</SuiTypography>
        </SuiBox>
        <SuiBox>
          {courseSelected?.modules.map(module => { // eslint-disable-line
            return (
              <Accordion className="customAccordion" key={module.pk} TransitionProps={{ unmountOnExit: false }} defaultExpanded>
                {/* COURSE */}
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    margin: "0 !important"
                  }}
                >
                  <SuiBox
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: isMobile ? 'column' : 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    {/* TITLE */}
                    <SuiBox display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-start' p={isMobile ? 0: 2} sx={{ maxWidth: "calc(100% - 150px)" }}>
                      <SuiBox display='flex' justifyContent='center' alignItems='center'>
                        <SuiBox display='flex' flexDirection='column' ml={1.5}>
                          <SuiTypography variant='h5'>{module.module.title}</SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </SuiBox>
                </AccordionSummary>
                
                <AccordionDetails sx={{ width: '100%', mx: 'auto', padding: "0" }}>
                  {/* MODULE */}
                  {module.lessons.map(lesson => { // eslint-disable-line
                    return (
                      <Accordion key={lesson.pk} TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: 'none' }}>
                        <AccordionSummary
                          sx={{
                            my: 0,
                            cursor: 'pointer',
                            border: '1px solid rgba(0, 0, 0, .125)',
                          }}
                        >
                          <SuiBox
                            sx={{
                              display: 'flex',
                              width: '100%',
                              flexDirection: isMobile ? 'column' : 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              position: 'relative'
                            }}
                          >
                            {/* TITLE */}
                            <SuiBox display='block' flexDirection='column' justifyContent='space-between' alignItems='flex-start' p={isMobile ? 0 : 2} sx={{ width: "100%" }}>
                              <SuiTypography variant='h6' onClick={() => setModuleAndLesson(module, courseSelected, lesson)}>
                                {lesson.completed || lesson?.score >= 100 ? (
                                  <CheckCircleIcon sx={{ mr: 1, verticalAlign: "middle", color: "#008a2f" }} />
                                ) : (
                                  <RadioButtonUncheckedIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                                )}
                                {lesson?.lesson?.title ? lesson?.lesson?.title : lesson?.name}
                                <SuiTypography sx={{ float: "right", fontSize: "14px" }}>{secondsToMinutes(lesson?.lesson?.duration)}</SuiTypography>
                              </SuiTypography>
                            </SuiBox>
                          </SuiBox>
                        </AccordionSummary>
                      </Accordion>
                    )
                  })}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </SuiBox>
      </SuiBox>
  );
}

// Typechecking props for the ProfilesList
FullContent.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  setLessons: PropTypes.func.isRequired,
  setModuleSelected: PropTypes.func.isRequired,
  courseSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCourseSelected: PropTypes.func.isRequired,
  setVideoDisplayed: PropTypes.func.isRequired,
};
  
export default FullContent;