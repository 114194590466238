/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function UserValidate({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { code } = formField;
  const {
    code: codeV,
  } = values;

  return (
    <SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={code.type}
              label={code.label}
              name={code.name}
              value={codeV}
              placeholder={code.placeholder}
              error={errors.code && touched.code}
              success={codeV.length > 0 && !errors.code}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for UserValidate
UserValidate.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserValidate;
