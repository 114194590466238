/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from 'prop-types'
import Vimeo from '@u-wave/react-vimeo'
// @mui material components
import Modal from '@mui/material/Modal'
import useMediaQuery from '@mui/material/useMediaQuery'
import Fade from '@mui/material/Fade'
import Card from '@mui/material/Card'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'

function VideoModal({ openVideoModal, setOpenVideoModal }) {

    const isMobile = useMediaQuery('(max-width:500px)')

    return (
        <Modal open={openVideoModal} onClose={() => setOpenVideoModal(false)}>
            <SuiBox
                sx={{
                    width: isMobile ? '95%' : '650px',
                    height: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Fade in timeout={550}>
                    <Card>
                        <Vimeo
                            className='videoIframe'
                            video='https://vimeo.com/796805146'
                            height={isMobile ? 400 : 650}
                            autoplay
                            style={{ maxWidth: "100%" }}
                        />
                    </Card>
                </Fade>

                <SuiBox my={5} width='80%' mx='auto' display='flex'>
                    <SuiButton
                      sx={{ mx: 1 }}
                      fullWidth
                      color='info'
                      variant='outlined'
                      onClick={() => setOpenVideoModal(false)}
                    >
                      Close
                    </SuiButton>
                </SuiBox>
            </SuiBox>
        </Modal>
    )
}

export default VideoModal

// typechecking props
VideoModal.propTypes = {
    openVideoModal: PropTypes.bool.isRequired,
    setOpenVideoModal: PropTypes.func.isRequired,
};