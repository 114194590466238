/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// react-router-dom components
import { Link } from "react-router-dom";
// formik components
import { Formik, Form } from "formik";
// @mui material components
import Checkbox from "@mui/material/Checkbox";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
// NewUser layout schemas for form and form feilds
import SignUp from "layouts/pages/users/new-user/components/SignUpCompany";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/formCompany";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
// Authentication layout components
import IllustrationLayoutCandidate from "layouts/authentication/components/IllustrationLayoutCandidate";
// Image
import companyAndRecruitersLogin from "assets/images/companies_cover_anim.webp";
import companyAndRecruitersLoginAnim from  "assets/images/companies_cover_anim.webm";


function getContent(formData) {
  return <SignUp formData={formData} />;
}

function Illustration() {
  const domains = [
    "aol.com", "att.net", "comcast.net", "facebook.com", "gmail.com", "gmx.com", "googlemail.com",
    "google.com", "hotmail.com", "hotmail.co.uk", "mac.com", "me.com", "mail.com", "msn.com",
    "live.com", "sbcglobal.net", "verizon.net", "yahoo.com", "yahoo.co.uk",
    "email.com", "fastmail.fm", "games.com", "gmx.net", "hush.com", "hushmail.com", "icloud.com",
    "iname.com", "inbox.com", "lavabit.com", "love.com", "outlook.com", "pobox.com", "protonmail.com",
    "rocketmail.com", "safe-mail.net", "wow.com", "ygm.com",
    "ymail.com", "zoho.com", "yandex.com",
    "bellsouth.net", "charter.net", "cox.net", "earthlink.net", "juno.com",
    "btinternet.com", "virginmedia.com", "blueyonder.co.uk", "freeserve.co.uk", "live.co.uk",
    "ntlworld.com", "o2.co.uk", "orange.net", "sky.com", "talktalk.co.uk", "tiscali.co.uk",
    "virgin.net", "wanadoo.co.uk", "bt.com",
    "sina.com", "sina.cn", "qq.com", "naver.com", "hanmail.net", "daum.net", "nate.com", "yahoo.co.jp", "yahoo.co.kr", "yahoo.co.id", "yahoo.co.in", "yahoo.com.sg", "yahoo.com.ph", "163.com", "126.com", "aliyun.com", "foxmail.com",
    "hotmail.fr", "live.fr", "laposte.net", "yahoo.fr", "wanadoo.fr", "orange.fr", "gmx.fr", "sfr.fr", "neuf.fr", "free.fr",
    "gmx.de", "hotmail.de", "live.de", "online.de", "t-online.de", "web.de", "yahoo.de",
    "libero.it", "virgilio.it", "hotmail.it", "aol.it", "tiscali.it", "alice.it", "live.it", "yahoo.it", "email.it", "tin.it", "poste.it", "teletu.it",
    "mail.ru", "rambler.ru", "yandex.ru", "ya.ru", "list.ru",
    "hotmail.be", "live.be", "skynet.be", "voo.be", "tvcablenet.be", "telenet.be",
    "hotmail.com.ar", "live.com.ar", "yahoo.com.ar", "fibertel.com.ar", "speedy.com.ar", "arnet.com.ar",
    "yahoo.com.mx", "live.com.mx", "hotmail.es", "hotmail.com.mx", "prodigy.net.mx",
    "yahoo.com.br", "hotmail.com.br", "outlook.com.br", "uol.com.br", "bol.com.br", "terra.com.br", "ig.com.br", "itelefonica.com.br", "r7.com", "zipmail.com.br", "globo.com", "globomail.com", "oi.com.br"
  ];
  // const signUpErrorText = "";

  // Validation
  const [activeStep, setActiveStep] = useState(0);
  // const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = false;
  let timerid = "";

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(values, null, 2));

    // actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      // setActiveStep(activeStep + 1);
      actions.setTouched({});
      // actions.setSubmitting(false);
    }
  };

  function validDomain(email) {
    // eslint-disable-next-line
    for (const domain of domains) {
      if (email.includes(domain)) {
        return false
      }
    }

    return true;
  }

  const signUpClick = async () => {
    await sleep(100);

    const emailValue = $("input[name=email]").val();
    const isValidDomain = validDomain(emailValue);
    console.log($(".MuiTypography-caption:visible").text().length === 0, $("input[type='checkbox']").is(':checked'), isValidDomain)
    if ($(".MuiTypography-caption:visible").text().length === 0 && $("input[type='checkbox']").is(':checked') && isValidDomain) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/signupCompany/`;
      const postBody = {
        'email': emailValue,
        'password': $("input[name=password]").val(),
        'password_confirmation': $("input[name=password]").val(),
        'first_name': $("input[name=firstName]").val(),
        'last_name': $("input[name=lastName]").val(),
        'company': $("input[name=company]").val()
      };

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: new URLSearchParams(postBody)
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          $(".suiAlertMsg").remove();
          console.log(response)
          if (response.success) {
            $(".suiAlertMsg").remove();
            const successAlert = $($('.alertSuccessBox .alertSuccess').parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(successAlert);

            if (timerid) {
              clearTimeout(timerid);
            }

            timerid = setTimeout(() => {
              window.location.replace(`/validated?id=${response.data.id}`);
            }, 2000);
          } else {
            $(".suiAlertMsg").remove();

            const errorAlert = $($('.alertSuccessBox .alertError').text(response.data.error).parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(errorAlert);
          }
        });
    } else if (!$("input[type='checkbox']").is(':checked')) {
      $(".suiAlertMsg").remove();
      $(".suiAlertMsg").remove();

      const errorAlert = $($('.alertSuccessBox .alertError').text("Agreement with Terms of Service is required").parent().html()).addClass("suiAlertMsg");
      $(".suiAlertBox").parent().prepend(errorAlert);
    } else if (!isValidDomain) {
      $(".suiAlertMsg").remove();
      $(".suiAlertMsg").remove();

      const errorAlert = $($('.alertSuccessBox .alertError').text("Please use a business email.").parent().html()).addClass("suiAlertMsg");
      $(".suiAlertBox").parent().prepend(errorAlert);
    }
  }


  return (
    <IllustrationLayoutCandidate
      color="primary"
      title="Sign Up"
      illustration={{
        image: companyAndRecruitersLogin,
        video: companyAndRecruitersLoginAnim,
      }}
    >
      <SuiBox className="suiAlertBox">
        <SuiBox display="flex" alignItems="end">
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, issubmitting }) => (
              <Form id={formId} autoComplete="off" type="POST" actions="#">
                <SuiBox>
                  {getContent({
                    values,
                    touched,
                    formField,
                    errors,
                  })}
                  <SuiBox>
                    <SuiBox>
                      <Checkbox />
                      <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        sx={{ userSelect: "none" }}
                        name="terms"
                      >
                        I Agree with Tecla’s <a target="_blank" rel="noreferrer" href="https://www.tecla.io/tos/"><u>Terms of Service</u></a> and <a target="_blank" rel="noreferrer" href="https://www.tecla.io/privacy/"><u>Privacy policy</u></a>.
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                  <SuiBox mt={4} mb={1}>
                    <SuiButton type="submit" variant="contained" color="warning" size="large" issubmitting={issubmitting} onClick={signUpClick} fullWidth sx={{ backgroundColor: "#feec1e" }}>
                      <SuiTypography color="dark" fontWeight="bold">
                        Sign Up
                      </SuiTypography>
                    </SuiButton>
                  </SuiBox>

                  <SuiBox opacity={0.25} my={4}>
                    <hr />
                  </SuiBox>

                  {/* <SuiBox mt={2} mb={1}>
                    <SuiButton variant="outlined" color="primary" size="large" fullWidth>
                      Sign up Using LinkedIn
                    </SuiButton>
                  </SuiBox> */}

                  <SuiBox mt={3} textAlign="center">
                    <SuiTypography variant="button" color="text" fontWeight="regular">
                      Already have an account?&nbsp;
                      <SuiTypography
                        component={Link}
                        to="/c/login"
                        variant="button"
                        sx={{ color: "#097df7", fontSize: "18px" }}
                        fontWeight="bold"
                      >
                        Log in
                      </SuiTypography>
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              </Form>
            )}
          </Formik>
        </SuiBox>
        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertSuccess">Welcome to TECLA!</SuiAlert>
        </SuiBox>
        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertError" color="error">EMAIL IS ALREADY IN USE</SuiAlert>
        </SuiBox>
      </SuiBox>
    </IllustrationLayoutCandidate>
  );
}

export default Illustration;
