/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { ReactSession } from 'react-client-session';
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import Swal from "sweetalert2";
// MUI components
import { Modal } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// icons
import Icon from '@mui/material/Icon';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
// SUI components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function createNotificationModal({ openCreateNotificationModal, setOpenCreateNotificationModal }) {

    ReactSession.setStoreType("localStorage");
    const token = ReactSession.get("token");

    const [showSpinner, setShowSpinner] = useState(true)
    const [rows, setRows] = useState(false);
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
      `;

    useEffect(() => {
      const headers = { "Content-Type": "application/json" };
      const url = `${process.env.REACT_APP_API_ROUTE}/api/users/getNotifications/`;
      headers.Authorization = `Token ${token}`;

      fetch(url, { headers, })
          .then(async response => {
            const data = await response.json();
            setRows(data.data)
            setShowSpinner(false);
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }, [])

    function getNotificationDetail(notificationId) {
      const headers = { "Content-Type": "application/json" };
      const url = `${process.env.REACT_APP_API_ROUTE}/api/users/${notificationId}/getNotificationDetail/`;
      headers.Authorization = `Token ${token}`;

      fetch(url, { headers, })
          .then(async response => {
            const data = await response.json(); // eslint-disable-line
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
    

    function getStringDate(activityDate) {
        const dateObject = new Date(activityDate);
        const yyyy = dateObject.getFullYear();
        const mm = String(dateObject.getMonth() + 1).padStart(2, '0');
        const dd = String(dateObject.getDate()).padStart(2, '0');
        let hour = String(dateObject.getHours());
        let min = String(dateObject.getMinutes());
        if (hour.length === 1) {
            hour = `0${hour}`
        }
        if (min.length === 1) {
            min = `0${min}`
        }

        return `${mm}/${dd}/${yyyy} - ${hour}:${min}`;
    }

    function setRead(notificationId) {
      const tempRows = [...rows]
      let edited = false;
      if (tempRows.length) {
        for (const tempRow of tempRows) { // eslint-disable-line
          if (tempRow.pk === notificationId) { // eslint-disable-line
            if (tempRow.status === "new") { // eslint-disable-line
              getNotificationDetail(tempRow.pk)
              tempRow.status = "read"
              edited = true;
            }
            break;
          }
        }
      }

      if (edited) {
        setRows(tempRows)
      }
    }


    const declineDialog = () => Swal.fire({
          title: "",
          text: "This will clean your notifications registry and leave it blank. Proceed?",
          width: "600",
          height: "auto",
          showCloseButton: true,
          confirmButtonText: 'Clean registry',
          confirmButtonAriaLabel: 'Clean registry',
          focusConfirm: true,
          showCancelButton: true,
          allowEscapeKey: "true",
        }).then((result) => {
        if (result.isConfirmed) {
            setShowSpinner(true);
            const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/cleanNotifications/`;
            const requestMetadata = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': `Token ${token}`
                },
                body: new URLSearchParams({})
            };
            fetch(recipeUrl, requestMetadata)
                .then(res => res.json())
                .then(response => {
                    if (response.success) {
                        setRows([]);
                        setShowSpinner(false);
                    }
                });
        }
    })

    return (
      <Modal
          open={openCreateNotificationModal}
          onClose={() => setOpenCreateNotificationModal(false)}
        >
          <SuiBox
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: "100vw", md: "80vw", xl: "60vw" },
              height: { xs: "101vh", md: "80vh" },
              bgcolor: 'background.paper',
              border: 'none',
              boxShadow: 24,
              borderRadius: { xs: 0, md: 4 },
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              maxHeight: "100%",
            }}
          >
          { showSpinner ? (
              <SuiBox className="moonItem" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={8} mb={8}>
                  <MoonLoader color="#17c1e8" loading={showSpinner} css={override} size={70} />
              </SuiBox>
          ) : (
          <SuiBox sx={{ maxHeight: "100%", position: "relative" }}>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center">
              <SuiBox display="flex" flexWrap="wrap">
                <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Notifications</SuiTypography>

              </SuiBox>
              <Icon onClick={() => setOpenCreateNotificationModal(false)} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
            </SuiBox>
            { rows && rows.length ? (
                <SuiBox id="modal-modal-description" bgColor="#f8f9fa" borderRadius="16px" height="100%" my={2}  sx={{ height: "630px", maxHeight: "85%", overflowY: "hidden", borderRadius: "0" }}>
                  <TableContainer component={Paper} sx={{ boxShadow: "none", height: "100%", "overflow": "hidden", borderRadius: "0" }}>
                    <Table aria-label="simple table" sx={{ maxHeight: "600px !important", "height": "100%" }}>
                      <TableBody sx={{ display: "inline-table", width: "100%" }}>
                        {rows.map((row) => (
                          <TableRow
                            key={row.text}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" }, "width": "100%" }}
                          >
                            <TableCell align="left" size="small">
                              <SuiTypography variant="body2" color="secondary">
                                  {row.type}
                              </SuiTypography>
                            </TableCell>
                            <TableCell align="left" size="small">
                              <SuiTypography variant="body2" color="secondary">
                                  {getStringDate(row.createdAt)}
                              </SuiTypography>
                            </TableCell>
                            <TableCell component="th" scope="row" size="small" sx={{ position: "relative", paddingLeft: "30px" }}>
                              <SuiTypography variant="body2" fontWeight="medium" color="dark">
                                  { row.status === "new" ? (
                                      <div className="notificationsBox modal">
                                          <PriorityHighIcon />
                                      </div>
                                  ) : null }
                                  {row.text}
                              </SuiTypography>
                            </TableCell>
                            <TableCell align="left" size="small">
                              <SuiTypography variant="body2" color="secondary">
                                  <SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                      <SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={row.link} target="_blank" onClick={() => setRead(row.pk)}>
                                          <Tooltip title="View Notes" placement="top">
                                              <PersonIcon />
                                          </Tooltip>
                                      </SuiTypography>
                                  </SuiBox>
                              </SuiTypography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </SuiBox>
              ) : (
                  <SuiBox mt={15} sx={{ background: "transparent" }}>
                      <SuiTypography variant="h2" textTransform="capitalize" fontWeight="bold" sx={{ color: "#000", textAlign: "center", background: "transparent" }}>
                        No notifications to show.
                      </SuiTypography>
                  </SuiBox>
              )}
              { rows && rows.length ? (
                <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                  <SuiBox display="flex" flexWrap="wrap">
                  </SuiBox>
                  <DeleteIcon className="deleteNotifications" onClick={declineDialog} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}/>
                </SuiBox>
              ) : null }
          </SuiBox>
          )}
          </SuiBox>
      </Modal>
    );

}

export default createNotificationModal;
