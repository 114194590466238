/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router components
import { Link } from 'react-router-dom'
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
// @mui material components
import Card from '@mui/material/Card'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'

// Custom styles for the CustomCourseCard
import CustomCourseCardWavesRoot from 'examples/Cards/BlogCards/CustomCourseCard/CustomCourseCardRoot'

function NlTypeCard({ image, title, description, link }) {

  return (
    <Link to={link}>
      <Card>
        <SuiBox component='img' src={image} alt={title} width='100%' maxHeight='20rem' />
        <SuiBox position='relative' height='3.125rem' overflow='hidden' zIndex={2} mt={-6.25}>
          <SuiBox position='absolute' width='100%' top={0} zIndex={1}>
            <SuiBox
              component='svg'
              position='relative'
              width='100%'
              height='3.125rem'
              minHeight='3.125rem'
              maxHeight='3.125rem'
              mb={-0.875}
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              viewBox='0 24 150 40'
              preserveAspectRatio='none'
              shapeRendering='auto'
            >
              <defs>
                <path
                  id='card-wave'
                  d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'
                />
              </defs>
              <CustomCourseCardWavesRoot>
                <use xlinkHref='#card-wave' x='48' y='-1' fill='rgba(255,255,255,0.30' />
                <use xlinkHref='#card-wave' x='48' y='3' fill='rgba(255,255,255,0.35)' />
                <use xlinkHref='#card-wave' x='48' y='5' fill='rgba(255,255,255,0.25)' />
                <use xlinkHref='#card-wave' x='48' y='8' fill='rgba(255,255,255,0.20)' />
                <use xlinkHref='#card-wave' x='48' y='13' fill='rgba(255,255,255,0.15)' />
                <use xlinkHref='#card-wave' x='48' y='16' fill='rgba(255,255,255,0.99)' />
              </CustomCourseCardWavesRoot>
            </SuiBox>
          </SuiBox>
        </SuiBox>
        <SuiBox p={3}>
          <SuiTypography variant='h4' gutterBottom>
            {title}
          </SuiTypography>
          <SuiBox mb={2}>
            <SuiTypography variant='body2' component='p' color='text'>
              {description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </Card>
    </Link>
  )
}

// Typechecking props for the NlTypeCard
NlTypeCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default NlTypeCard
