/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
import { useState } from "react";
// Utils
import { sliceString, formatDate } from 'utils/commonFunctions';
// @mui material components
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAvatar from "components/SuiAvatar";
import companyAvatar from "assets/images/mockCompanyAvatarB.jpg";
import CandidateScorecardModal from 'layouts/pages/profile/components/Header/candidateScorecardModal/CandidateScorecardModal'
import DeleteFormConfirmationModal from "./DeleteFormConfirmationModal";

function GeneralView({ setModalContent, candidate, setFormIdToShow, setIsEditingForm, application, candidateScorecardData }) {

    ReactSession.setStoreType("localStorage")
    const user = ReactSession.get("user")
    const recruiter = ReactSession.get("recruiter")
    const isRecruiter = (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin")

    const isApplicationView = window.location.pathname.includes('/application')

    const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

    /*
        Forms solo se pueden crear/editar desde vista /application. Desde perfil de candidato solo se pueden visualizar.
        Scorecards solo se pueden editar desde vista /application. Desde perfil de candidato solo se pueden visualizar.

        Solo pueden borrar y editar un Form la cuenta recruiter que creó el Form (de cualquier tipo).
        Las demás cuentas no puede borrar Forms creados por otros usuarios.
        Solo pueden editar un Scorecard:
            - La cuenta recruiter que creó el scorecard (de cualquier tipo)
            - Cuenta recruiter Manager (con compañía asignada)
            - Cuenta recruiter Admin
        No pueden borrarse los scorecards
    */

    const canEditAndDeleteForm = formRecruiterId => formRecruiterId === user?.id

    const [showDeleteFormConfirmationModal, setShowDeleteFormConfirmationModal] = useState(false)

    const scorecardIsEmpty = candidateScorecardData && candidateScorecardData[0].value === ''

    const canEditScorecard = () => {
        // [Soy admin] o 
        if (user?.type === "tecla_admin") return true
        // [Soy manager con la compañía asignada] o 
        if (user?.type === "tecla_manager" && recruiter?.companies?.filter(comp => comp?.id === application?.company?.id)?.length) return true
        // [No soy manager o admin pero fui el recruiter que ha creado el Scoreboard] o 
        if (candidateScorecardData && candidateScorecardData.length &&  candidateScorecardData[0]?.recruiter?.user?.id === user?.id) return true
        // [No soy nada de eso y lo ha creado otro pero está vacío también puedo llenarlo]
        return scorecardIsEmpty
    }

    const [showCandidateScorecardModal, setShowCandidateScorecardModal] = useState(false)

    return (
        <>
            <TableContainer component={Paper} sx={{ boxShadow: "none", my: 5, mx: "auto", width: "100%" }}>
                <Table>
                    <TableBody>
                        {candidate?.forms?.length ?
                            candidate?.forms?.map(form => (
                                <TableRow  key={form.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:last-child th': { width: "78%" }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" } }} >
                                   
                                    <TableCell component="th" scope="row" size="small" sx={{ minWidth: {xs: "auto", md: "12rem"} }}>
                                        <SuiTypography variant="body2" fontWeight="medium" color="dark">
                                            {capitalize(form.recruiter?.user?.first_name)} {capitalize(form.recruiter?.user?.last_name)}
                                        </SuiTypography>
                                    </TableCell>

                                    {/* Company avatar */}
                                    <TableCell component="th" scope="row" size="small" sx={{ minWidth: {xs: "auto", md: "12rem"} }}>
                                        <SuiTypography
                                            variant="subtitle2"
                                            sx={{ cursor: 'pointer' }}
                                            component="a"
                                            href={`/r/company/${form?.application?.job?.company?.id}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Tooltip title={form?.application?.job?.company?.name} placement="top" sx={{ verticalAlign: "middle", ml: 1 }}>
                                                <SuiAvatar
                                                    src={form?.application?.job?.company?.photo ? `${process.env.REACT_APP_API_ROUTE}${form?.application?.job?.company?.photo}` : companyAvatar}
                                                    alt="profile-image"
                                                    variant="circular"
                                                    size="md"
                                                    shadow="sm"
                                                />
                                            </Tooltip>
                                        </SuiTypography>
                                    </TableCell>

                                    {/* Job name */}
                                    {isRecruiter && form?.application?.job?.name && (
                                        <TableCell align="left" size="small" sx={{ minWidth: {xs: "auto", md: "25rem"} }}>
                                            <SuiTypography
                                                variant="subtitle2"
                                                sx={{ cursor: 'pointer' }}
                                                component="a"
                                                href={`/job?id=${form?.application?.job?.id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {sliceString(form?.application?.job?.name, 100)}
                                            </SuiTypography>
                                        </TableCell>
                                    )}

                                    <TableCell align="left" size="small" sx={{ display: { xs: "none", md: "table-cell" }, minWidth: {xs: "auto", md: "15rem"} }}>
                                        <SuiTypography variant="body2" color="secondary">
                                            Date added: {formatDate(form.createdAt)}
                                        </SuiTypography>
                                    </TableCell>

                                    {/* View or edit scorecard */}
                                    <TableCell align="right" size="small" sx={{ minWidth: {xs: "auto", md: "12rem"} }}>
                                        <SuiButton
                                            variant="outlined"
                                            color="info"
                                            onClick={() => setShowCandidateScorecardModal(true)}
                                        >
                                            {(isApplicationView && canEditScorecard()) ? 'Edit scorecard' : 'View scorecard'}
                                        </SuiButton>
                                    </TableCell>

                                    {/* View or edit form */}
                                    {(canEditAndDeleteForm(form?.recruiter?.user?.id) && isApplicationView) ?
                                        <>
                                            <TableCell align="right" size="small" sx={{ minWidth: {xs: "auto", md: "10rem"} }}>
                                                <SuiButton
                                                    variant="outlined"
                                                    color="info"
                                                    onClick={() =>{
                                                        setIsEditingForm(true)
                                                        setFormIdToShow(form?.id)
                                                    }}
                                                >
                                                    Edit form
                                                </SuiButton>
                                            </TableCell>

                                            <TableCell align="right" size="small" sx={{ minWidth: {xs: "auto", md: "10rem"} }}>
                                                <SuiButton
                                                    variant="outlined"
                                                    color="info"
                                                    onClick={() => setFormIdToShow(form?.id)}
                                                >
                                                    View form
                                                </SuiButton>
                                            </TableCell>
                                        </>
                                        :
                                        <TableCell align="right" size="small" sx={{ minWidth: {xs: "auto", md: "12rem"} }}>
                                            <SuiButton
                                                variant="outlined"
                                                color="info"
                                                onClick={() => setFormIdToShow(form?.id)}
                                            >
                                                View form
                                            </SuiButton>
                                        </TableCell>
                                    }
                                    
                                    {/* Delete form - Only the user who created the form can delete it */}
                                    {(canEditAndDeleteForm(form?.recruiter?.user?.id) && isApplicationView) && (
                                        <SuiBox sx={{ mt: 2.5, ml: 3, mr: 3, cursor: 'pointer'  }}>
                                            <Tooltip title="Delete form" placement="top">
                                                <DeleteIcon
                                                    fontSize="medium"
                                                    onClick={() => setShowDeleteFormConfirmationModal(true)}
                                                />
                                            </Tooltip>
                                        </SuiBox>
                                    )}

                                    {showDeleteFormConfirmationModal && (
                                        <DeleteFormConfirmationModal
                                            showDeleteFormConfirmationModal={showDeleteFormConfirmationModal}
                                            setShowDeleteFormConfirmationModal={setShowDeleteFormConfirmationModal}
                                            formId={form?.id}
                                        />
                                    )}
                                </TableRow>
                            ))
                            :
                            <SuiBox display="flex" justifyContent="center" alignItems="center">
                                <SuiTypography variant="body2" color="secondary">This candidate doesn&apos;t have any related forms yet.</SuiTypography>
                            </SuiBox>
                    }
                        
                    </TableBody>
                </Table>
            </TableContainer>
            
            {showCandidateScorecardModal && (
                <CandidateScorecardModal
                    showCandidateScorecardModal={showCandidateScorecardModal}
                    setShowCandidateScorecardModal={setShowCandidateScorecardModal}
                    candidateName={`${candidate?.user?.first_name} ${candidate?.user?.last_name}`}
                    candidateId={candidate?.user?.id}
                    cantEditRecords={!isApplicationView || !canEditScorecard()}
                    application={application}
                />
            )}
            
            {isApplicationView && (
                <SuiBox display="inherit" justifyContent="end" mr={2} mt={2}>
                    <SuiButton color="info" variant="gradient" onClick={() => setModalContent('addNewForm')}>Add a new form</SuiButton>
                </SuiBox>
            )}
        </>
    )
}

GeneralView.defaultProps = {
    application: null,
    candidateScorecardData: null
}

// typechecking props
GeneralView.propTypes = {
    setModalContent: PropTypes.func.isRequired,
    candidate: PropTypes.object.isRequired, // eslint-disable-line
    setFormIdToShow: PropTypes.func.isRequired,
    setIsEditingForm: PropTypes.func.isRequired,
    application: PropTypes.object, // eslint-disable-line
    candidateScorecardData: PropTypes.object, // eslint-disable-line
};

export default GeneralView;
