/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from 'prop-types'
import { useState } from 'react'
import { ReactSession } from 'react-client-session'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
// MUI components
import Tooltip from '@mui/material/Tooltip'
import PersonIcon from '@mui/icons-material/Person'
import AssignmentIcon from '@mui/icons-material/Assignment'
import DeleteIcon from '@mui/icons-material/Delete'
import UploadFileIcon from '@mui/icons-material/UploadFile'
// Modals
import DeleteFromFolderModal from './DeleteFromFolderModal'
import ChangeCVModal from './ChangeCVModal'

function ActionCell({ candidate, fetchFolderContent, folderId }) {

	const apiUrl = process.env.REACT_APP_API_ROUTE
	let candidateUrl = ""
	let cvUrl = ""

	if (candidate && candidate?.candidate?.user) {
		candidateUrl = `/t/profile/${candidate.candidate.user.id}`
		cvUrl = candidate.cv ? `${apiUrl}${candidate.cv}` : ""
	}

	const recruiter = ReactSession.get("recruiter")

	const [openDeleteFromFolderModal, setOpenDeleteFromFolderModal] = useState(false)
	const [openChangeCVModal, setOpenChangeCVModal] = useState(false)	

	return (
		<SuiBox display="flex" alignItems="center" >

			{cvUrl ? (
				<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={cvUrl} target="_blank">
						<Tooltip title="View CV" placement="top">
							<AssignmentIcon />
						</Tooltip>
					</SuiTypography>
				</SuiBox>
			) : null}
			
			{/* Only recruiters that can createClientFolders can access see these options */}
			{recruiter?.createClientFolders && (
				<>
					<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
						<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} onClick={() => setOpenChangeCVModal(true)}>
							<Tooltip title="Change CV" placement="top">
								<UploadFileIcon />
							</Tooltip>
						</SuiTypography>
					</SuiBox>

					{/* Delete candidate from folder modal */}
					{openChangeCVModal && (
						<ChangeCVModal
							openChangeCVModal={openChangeCVModal}
							setOpenChangeCVModal={setOpenChangeCVModal}
							candidate={candidate}
							fetchFolderContent={fetchFolderContent}
						/>
					)}

					{(recruiter?.canManageClientFolders || recruiter?.createClientFolders) && (
						<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
							<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={candidateUrl} target="_blank">
								<Tooltip title="View profile" placement="top">
									<PersonIcon />
								</Tooltip>
							</SuiTypography>
						</SuiBox>
					)}

					{(recruiter?.canManageClientFolders || recruiter?.createClientFolders) && (
						<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
							<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} onClick={() => setOpenDeleteFromFolderModal(true)}>
								<Tooltip title="Remove from folder" placement="top">
									<DeleteIcon />
								</Tooltip>
							</SuiTypography>
						</SuiBox>
					)}

					{/* Delete candidate from folder modal */}
					{openDeleteFromFolderModal && (
						<DeleteFromFolderModal
							openDeleteFromFolderModal={openDeleteFromFolderModal}
							setOpenDeleteFromFolderModal={setOpenDeleteFromFolderModal}
							folderId={folderId}
							candidate={candidate}
							fetchFolderContent={fetchFolderContent}
						/>
					)}

				</>
			)}
		</SuiBox>
	)
}

ActionCell.defaultProps = {
	candidate: {}
}

ActionCell.propTypes = {
	candidate: PropTypes.objectOf(PropTypes.any),
	fetchFolderContent: PropTypes.func.isRequired,
	folderId: PropTypes.number.isRequired
}

export default ActionCell