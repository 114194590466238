/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { useParams } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";
import SuiButton from "components/SuiButton";

function Interests({ formData, setActiveStep, setSelectedCommitments, setSelectedIndustries }) {
  ReactSession.setStoreType("localStorage");
  const { candidateId } = useParams()
  let CandidateForEdit = ReactSession.get(`CandidateForEdit${candidateId}`)
  if (!CandidateForEdit) {
    CandidateForEdit = ReactSession.get("CandidateForEdit");
    ReactSession.set(`CandidateForEdit${candidateId}`, CandidateForEdit);
  }

  const { formField, values, errors, touched } = formData; // eslint-disable-line
  const { resume } = formField; // eslint-disable-line
  const {
    resume: resumeV // eslint-disable-line
  } = values;

  let commitmentsString = "";
  let techsString = "";
  let commitmentsSelectedStrings = [];
  let techsSelectedStrings = [];
  let commitmentStrings = [];
  let techStrings = [];
  let tagtext = "";
  let timerid = "";
  let timerid2 = "";

  const [commitments, setCommitments] = useState([]);
  const [commitmentsSelected, setCommitmentsSelected] = useState([]);

  const [techs, settechs] = useState([]);
  const [techsSelected, setTechsSelected] = useState([]);

  function getCommitment(commitmentString) {  // eslint-disable-line
    const commitmentsStr = JSON.parse($(".commitmentsBox").attr("data-commitment"));
    let data = null;
    // eslint-disable-next-line
    $.each(commitmentsStr, function (i, val) {
      if (commitmentString === val.name) {
        data = { "id": val.id, "name": val.name };
      }
    });

    return data;
  }

  function getTech(techString) {  // eslint-disable-line
    const techsStr = JSON.parse($(".techsBox").attr("data-techs"));
    let data = null;
    // eslint-disable-next-line
    $.each(techsStr, function (i, val) {
      if (techString === val.name) {
        data = { "id": val.id, "name": val.name };
      }
    });

    return data;
  }

  function changeCommitPlaceholder(remove, box) {
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      if (remove) {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "");
      } else if (box === "commitmentsBox") {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "Commitments.");
      } else {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "Techs.");
      }
    }, 100);
  }

  function changeTechPlaceholder(remove, box) {
    if (timerid2) {
      clearTimeout(timerid2);
    }
    timerid2 = setTimeout(() => {
      if (remove) {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "");
      } else if (box === "commitmentsBox") {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "Commitments.");
      } else {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "Techs.");
      }
    }, 100);
  }

  useEffect(() => {
    if (CandidateForEdit.commitment) {
      const candidateCommitmets = [];
      // eslint-disable-next-line
      $.each(CandidateForEdit.commitment, function (i, val) {
        candidateCommitmets.push(val.name)
      });

      if (candidateCommitmets.length) {
        setCommitmentsSelected(candidateCommitmets);
        changeCommitPlaceholder(1, "commitmentsBox");
      }
    }

    if (CandidateForEdit.industries) {
      const candidateTechs = [];
      // eslint-disable-next-line
      $.each(CandidateForEdit.industries, function (i, val) {
        candidateTechs.push(val.name)
      });

      if (candidateTechs.length) {
        setTechsSelected(candidateTechs);
        changeTechPlaceholder(1, "techsBox");
      }
    }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      .then(async response => {
        const data = await response.json();

        const newCommitmentData = [];
        // eslint-disable-next-line
        for (let i = 0; i < data.data.commitment.length; i++) {
          newCommitmentData.push({ value: data.data.commitment[i].name, label: data.data.commitment[i].name });
        }

        const newTechData = [];
        // eslint-disable-next-line
        for (let i = 0; i < data.data.industries.length; i++) {
          newTechData.push({ value: data.data.industries[i].name, label: data.data.industries[i].name });
        }

        $(".commitmentsBox").attr("data-commitment", JSON.stringify(data.data.commitment));
        $(".techsBox").attr("data-techs", JSON.stringify(data.data.industries));

        setCommitments(newCommitmentData);
        settechs(newTechData);
        // this.setState({ totalReactPackages: data.total })
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    // setCountries([]);
  }, []);

  function notInArray(selectStr, isTech) {
    let tempArray = commitmentsSelected;
    if (isTech) {
      tempArray = techsSelected;
    }
    // eslint-disable-next-line
    for (const tempItem of tempArray) {
      if(tempItem.includes(selectStr)) {
        return false;
      }
    }

    return true;
  }

  function addCommitment() {
    commitmentsString = $("input[name=commitments]").val();
    if (commitmentsString && notInArray(commitmentsString, false)) {
      commitmentsSelectedStrings = [...commitmentsSelected];
      commitmentsSelectedStrings.push(commitmentsString);
      setCommitmentsSelected(commitmentsSelectedStrings);
      changeCommitPlaceholder(1, "commitmentsBox");

      if ($(".commitmentsBox .react-tag-input__tag__content:visible").length) {
        const commitmentArray = [];
        // eslint-disable-next-line
        $.each($(".commitmentsBox .react-tag-input__tag__content:visible"), function (i, val) {
          const commitment = getCommitment($(val).text());
          if (commitment) {
            commitmentArray.push(commitment);
          }
          if (commitmentArray.length) {
            setSelectedCommitments(commitmentArray)
          }
        });
      }
    }
  }

  function addtech() {
    techsString = $("input[name=techs]").val();
    if (techsString && notInArray(techsString, true)) {
      techsSelectedStrings = [...techsSelected];
      techsSelectedStrings.push(techsString);
      setTechsSelected(techsSelectedStrings);
      changeTechPlaceholder(1, "techsBox");

      if ($(".techsBox .react-tag-input__tag__content:visible").length) {
        const techArray = [];
        // eslint-disable-next-line
        $.each($(".techsBox .react-tag-input__tag__content:visible"), function (i, val) {
          const tech = getTech($(val).text());
          if (tech) {
            techArray.push(tech);
          }
          if (techArray.length) {
            setSelectedIndustries(techArray);
          }
        });
      }
    }
  }

  function removeTag() {
    if ($(".commitmentsBox .react-tag-input__tag__content:visible").length) {
      const commitmentArray = [];
      // eslint-disable-next-line
      $.each($(".commitmentsBox .react-tag-input__tag__content:visible"), function (i, val) {
        const commitment = getCommitment($(val).text());
        if (commitment) {
          commitmentArray.push(commitment);
        }
        setSelectedCommitments(commitmentArray)
      });
    }

    if ($(".techsBox .react-tag-input__tag__content:visible").length) {
      const techArray = [];
      // eslint-disable-next-line
      $.each($(".techsBox .react-tag-input__tag__content:visible"), function (i, val) {
        const tech = getTech($(val).text());
        if (tech) {
          techArray.push(tech);
        }
        setSelectedIndustries(techArray);
      });
    }
  }

  // eslint-disable-next-line
  $(document).on("click", ".commitmentsBox .react-tag-input__tag__remove, .techsBox .react-tag-input__tag__remove", function () {
    tagtext = $(this).prev(".react-tag-input__tag__content");
    if ($(this).parents(".commitmentsBox").length && tagtext) {
      tagtext = tagtext.text();
      commitmentStrings = [...commitmentsSelected];
      if (commitmentStrings.indexOf(tagtext) >= 0) {
        commitmentStrings.splice(commitmentStrings.indexOf(tagtext), 1);
        setCommitmentsSelected(commitmentStrings);
        if (!commitmentStrings.length) {
          changeCommitPlaceholder(0, "commitmentsBox");
        }
      }
    } else if (tagtext) {
      tagtext = tagtext.text();
      techStrings = [...techsSelected];
      if (techStrings.indexOf(tagtext) >= 0) {
        techStrings.splice(techStrings.indexOf(tagtext), 1);
        setTechsSelected(techStrings);
        if (!techStrings.length) {
          changeTechPlaceholder(0, "techsBox");
        }
      }
    }
  });

  const verifyInterestsInputs = () => {
    setActiveStep(prevStep => prevStep+1)
  }

  return (
    <SuiBox>
      <SuiBox width="100%" textAlign="center" mb={4}>
        <SuiTypography variant="h5" fontWeight="regular">Select your levels of commitment (optional)</SuiTypography>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container xs={12} md={12} spacing={1}>
          <Grid item xs={8} mb={2}>
            <SuiSelect
              placeholder="Select Commitment"
              options={commitments}
              name="commitments"
            />
          </Grid>

          <Grid item xs={4} >
            <SuiButton color="info" onClick={addCommitment} fullWidth>Add</SuiButton>
          </Grid>

        </Grid>

        <Grid item xs={12} md={12} className="commitmentsBox">
          <SuiTagInput
            placeholder="Your commitments"
            tags={commitmentsSelected}
            onChange={removeTag}
          />
        </Grid>
      </SuiBox>

      <SuiBox width="100%" textAlign="center" mb={4} mt={4}>
        <SuiTypography variant="h5" fontWeight="regular">Select some tech segments you are interested in (optional)</SuiTypography>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container xs={12} md={12} spacing={1}>
          <Grid item xs={8} mb={2}>
            <SuiSelect
              placeholder="Select tech segment"
              options={techs}
              name="techs"
            />
          </Grid>
          
          <Grid item xs={4}>
            <SuiButton color="info" onClick={addtech} fullWidth>Add</SuiButton>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} className="techsBox">
          <SuiTagInput
            placeholder="Your tech segments"
            tags={techsSelected}
            onChange={removeTag}
          />
        </Grid>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" onClick={() => setActiveStep(prevStep => prevStep-1)} isSubmitting className="changeStep">Back</SuiButton>
        <SuiButton variant="gradient" color="info" onClick={() => verifyInterestsInputs()} className="changeStep nextStep" isSubmitting>Next</SuiButton>
      </SuiBox>

    </SuiBox>
  );
}

Interests.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setSelectedCommitments: PropTypes.func.isRequired,
  setSelectedIndustries: PropTypes.func.isRequired,
};

export default Interests;
