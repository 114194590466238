/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { ReactSession } from 'react-client-session';
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
// MUI components
import { Modal } from "@mui/material";
import Grid from '@mui/material/Grid';
// SUI components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTagInput from 'components/SuiTagInput';
import SuiAlert from 'components/SuiAlert'

function createNotificationModal({ openCreateIndustryModal, setOpenCreateIndustryModal }) {

    ReactSession.setStoreType("localStorage");
    const token = ReactSession.get("token");

    const [showErrorMsg, setShowErrorMsg] = useState("")
    const [showSuccessMsg, setShowSuccessMsg] = useState("")
    const [showSpinner, setShowSpinner] = useState(false) // eslint-disable-line  
    const [industries, setIndustries] = useState([]); // eslint-disable-line  
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
      `;

    function addIndustryfunction() {
      const modalindustryInput = $("input[name=modalindustryInput]").val();
      if (modalindustryInput && modalindustryInput.length) {
        setShowSpinner(true)
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/addIndustry/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify({ industry: modalindustryInput })
        })
          .then(res => res.json())
          .then(response => {

            if (response.success) {
              setShowSuccessMsg(modalindustryInput)
            } else {
              setShowErrorMsg(response.data.error)
            }
            setTimeout(() => {
              setShowSuccessMsg("")
              setShowErrorMsg("")
            }, 3000);
            setShowSpinner(false)
          })
          .catch(error => console.error('There was an error!', error))
      }
    }

    return (
      <Modal
          open={openCreateIndustryModal}
          onClose={() => setOpenCreateIndustryModal(false)}
        >
          <SuiBox
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: "100vw", md: "80vw", xl: "60vw" },
              height: { xs: "32vh", md: "32vh" },
              bgcolor: 'background.paper',
              border: 'none',
              boxShadow: 24,
              borderRadius: { xs: 0, md: 4 },
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              maxHeight: "100%",
            }}
          >
            <SuiBox sx={{ maxHeight: "100%", position: "relative", width: "90%" }}>
              {showErrorMsg && <SuiAlert color='error'>{showErrorMsg}</SuiAlert>}
              {showSuccessMsg && <SuiAlert color='info'>Industry {showSuccessMsg} added successfully!</SuiAlert>}
              <SuiTypography sx={{ my: 2.5 }} variant="h6">Add new Industry</SuiTypography>
              <SuiBox mt={1}>
                <SuiInput
                  type="text"
                  placeholder="Industry"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                  className="searchInput"
                  name="modalindustryInput"
                >
                  
                </SuiInput>
              </SuiBox>
              <SuiBox mt={5} mb={5} width='80%' mx='auto' display="flex">
                { showSpinner ? (
                    <SuiBox className="moonItem" display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ margin: "0 auto" }}>
                        <MoonLoader color="#17c1e8" loading={showSpinner} css={override} size={40} />
                    </SuiBox>
                ) : (
                  <SuiButton
                      sx={{ mx: 1 }}
                      fullWidth
                      color="info"
                      variant="gradient"
                      onClick={() => addIndustryfunction()}
                  >
                      Add
                  </SuiButton>
                )}
              </SuiBox>
              { industries.length ? (
                <Grid item xs={12} md={12} className="industrysBox">
                    <SuiTagInput
                      placeholder="Industries Added"
                      tags={industries}
                    />
                </Grid>
              ) : null }
            </SuiBox>
          </SuiBox>
      </Modal>
    );

}

export default createNotificationModal;
