/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import trustedBy from "assets/images/clients-logos/trusted-by.png";

function Illustration() {
  return (
    <IllustrationLayout
      title="Change your Password"
      // description="Enter the email associated with your account to receive instructions on changing your password."
      illustration={{
        image: trustedBy,
      }}
    >
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiInput type="password" placeholder="Old Password" size="large" />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiInput type="password" placeholder="New Password" size="large" />
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton variant="gradient" color="info" size="large" fullWidth>
            Change
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </IllustrationLayout>
  );
}

export default Illustration;
