/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import SignUpModal from "examples/Modal/SignUpModal";
import SignInModal from "examples/Modal/SignInModal";

function WithAttachedFunction({ type, text, size, fullWidth }) {

  if (type === "1") {
    return (
      <SignUpModal text={text} />
    );
  }

  return (
    <>
      <SignInModal text={text} size={size} fullWidth={fullWidth} />
    </>
  );

}

WithAttachedFunction.defaultProps = {
  type: 0,
  text: "",
  size: "small",
  fullWidth: false
}

WithAttachedFunction.propTypes = {
  type: PropTypes.number,
  text: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,

};

export default WithAttachedFunction;