/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// Soft UI Dashboard PRO React components
import NameAndPictureCell from "layouts/recruiterFolders/folderContent/NameAndPictureCell/nameAndPictureCell"
import ActionCell from "layouts/clientFolders/folderContent/ActionCell/ActionCell"

export default {
  columns: [
    {
      Header: "Candidate",
      accessor: "candidate",
      Cell: ({ value: [name, data] }) => <NameAndPictureCell image={data} name={name} />,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ value: [candidate, fetchFolderContent, folderId] }) => <ActionCell candidate={candidate} fetchFolderContent={fetchFolderContent} folderId={folderId} />,
    },
  ],

  rows: [
    {
      candidate: "",
      action: ""
    },
  ],
};