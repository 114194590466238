/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from "react-client-session"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
// MUI components
import useMediaQuery from "@mui/material/useMediaQuery"
import Card from "@mui/material/Card"
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiBadge from "components/SuiBadge"
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React example components
import CompanyDashboardLayout from "examples/LayoutContainers/CompanyDashboardLayout"
import DataTable from "examples/Tables/DataTable"
import companysSourcingTableData from "layouts/companySourcingContent/data/companySourcingTableData"
import companysSourcingTableDataSmall from "layouts/companySourcingContent/data/companySourcingTableDataSmall"
import ActionCell from "layouts/companySourcingContent/ActionCell/ActionCell"

function CompanySourcingContent() {
    const isMobile = useMediaQuery('(max-width:500px)')

    ReactSession.setStoreType("localStorage")
    const token = ReactSession.get("token")

    const { id } = useParams()

    const [folderContent, setFolderContent] = useState(null)
    const [candidatesData, setCandidatesData] = useState(companysSourcingTableData)
    const [candidatesDataSmall, setCandidatesDataSmall] = useState(companysSourcingTableDataSmall)

    const fetchSourcingContent = () => {
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/sourcings/getSourcings/?job=${id}`, {
            method: 'GET',
            headers: { "Authorization": `Token ${token}` }
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setFolderContent(data.data)

                    const candidatesArray = []
                    // eslint-disable-next-line
                    for (const item of data.data) {

                        const candidateSkills = []

                        // eslint-disable-next-line
                        for (let i = 0; i < 5; i++) {
                            if (item.candidate?.skills[i]?.skill?.name) candidateSkills.push(<SuiBadge badgeContent={item.candidate.skills[i].skill.name} color="info" variant="contained" size="lg" />)
                            else break
                        }

                        const countrName = item.candidate && item.candidate.country && item.candidate.country.name ? item.candidate.country.name : "";

                        candidatesArray.push({
                            candidate: [`${item.candidate.user.first_name} ${item.candidate.user.last_name}`, item.candidate.user.photo, item.candidate.user.id],
                            sourceDate: new Date(item.createdAt).toISOString().split('T')[0],
                            country: countrName,
                            skills: candidateSkills.length > 0 ? candidateSkills : '',
                            action: <ActionCell candidate={item.candidate} sourcing={item} fetchSourcingContent={fetchSourcingContent} folderContent={folderContent} />
                        })
                    }

                    const candidatesDataTemp = { ...candidatesData }
                    candidatesDataTemp.rows = candidatesArray
                    setCandidatesData(candidatesDataTemp)

                    const candidatesDataSmallTemp = { ...candidatesDataSmall }
                    candidatesDataSmallTemp.rows = candidatesArray
                    setCandidatesDataSmall(candidatesDataSmallTemp)

                }
            })
            .catch(error => console.error('There was an error!', error))
    }

    useEffect(() => { fetchSourcingContent() }, [])

    useEffect(() => { 
        if (folderContent && folderContent.length) {
            document.title = `Sourcing - ${folderContent[0]?.job?.company?.name} | TECLA`;
        }
    }, [folderContent])

    return (
        <CompanyDashboardLayout>

            {folderContent ? 
                <>                    
                    <SuiBox
                        py={2}
                        mb={2}
                        display="flex"
                        flexDirection={isMobile ? "column" : 'row'}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {folderContent.length > 0 &&
                            <SuiTypography
                                variant="h3"
                                fontWeight="bold">
                                Sourcing for {folderContent[0]?.job?.company?.name} - {folderContent[0]?.job?.name}
                            </SuiTypography>}
                    </SuiBox>
                    
                    <Card>
                        <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <DataTable table={candidatesData} />
                        </SuiBox>
                        <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
                            <DataTable table={candidatesDataSmall} />
                        </SuiBox>
                    </Card>
                </>
                
                :
                <Card sx={{ marginTop: 10 }}>
                    <SuiBox py={20} display="flex" justifyContent="center" alignItems="center" >
                        <CircularProgress />
                    </SuiBox>
                </Card>
            }

        </CompanyDashboardLayout >
    )
}

export default CompanySourcingContent