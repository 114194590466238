/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Tooltip from "@mui/material/Tooltip";
import AssignmentIcon from '@mui/icons-material/Assignment';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function invoiceCell({ file }) {

	return (
		<SuiBox display="flex" alignItems="center" justifyContent="center" flexDirection="column">
			<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} component="a" mr={1} href={`${process.env.REACT_APP_API_ROUTE}${file}`} target="_blank">
				<Tooltip title="View invoice" placement="top">
					<AssignmentIcon/>
				</Tooltip>
			</SuiTypography>
		</SuiBox>
	);
}

// Typechecking props for the invoiceCell
invoiceCell.propTypes = {
	file: PropTypes.string.isRequired
};

export default invoiceCell;
