/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import SuiSelect from 'components/SuiSelect';
import SuiTagInput from 'components/SuiTagInput';
// MUI components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import placeholder from "assets/images/placeholder.jpg";

function AddSkillModal({
                          showAddSkillModal,
                          setShowAddSkillModal,
                          skillsListToShow,
                          setSkillsListToshow,
                          skillsListToPost,
                          setSkillsListToPost,
                          setSkillUpdated,
                          returAsYears,
                          getSkillValue
                        }) {

  const [selectedSkill, setSelectedSkill] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)

  const [skills, setSkills] = useState([])
  const [skillsDB, setSkillsDB] = useState([])
  const [skillLevels, setSkillLevels] = useState([]);

  const [modalSkillsListToShow, setModalSkillsListToShow] = useState(skillsListToShow);
  const [modalSkillsListToPost, setModalSkillsListToPost] = useState(skillsListToPost);

  const [showSpinner, setShowSpinner] = useState(false)

  const years = [
      { value: 1, label: "1 Year" },
      { value: 2, label: "2 Years" },
      { value: 3, label: "3 Years" },
      { value: 4, label: "4 Years" },
      { value: 5, label: "5 Years" },
      { value: 6, label: "6 Years" },
      { value: 7, label: "7 Years" },
      { value: 8, label: "8 Years" },
      { value: 9, label: "9 Years" },
      { value: 10, label: "10+ Years" },
  ];

  function getSkillAndLevel(skillString) {  // eslint-disable-line  

      const skill = skillString.substring(0, skillString.indexOf(" - "))
      const level = Number(skillString.substring(skillString.indexOf(" - ")+3));

      let data = null;

      // eslint-disable-next-line
      for (let i = 0; i < skillsDB.length; i++) {
        const element = skillsDB[i];
        if (skill === element.name) {
          data = {
            "level": level,
            "minLevel": 1,
            "skill": { "id": element.id, "name": element.name }
          };
          break
        }
      }

      return data;
  }

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
    } catch (err) {
      console.error('fetchInterests error', err)
    }
  }

  const fetchSkills = async () => {
    setShowSpinner(true)

    if (skillsListToShow.length > 0) {
      const candidateSkill = [];
      // eslint-disable-next-line
      skillsListToShow.forEach(skl => {
        candidateSkill.push(`${skl.name} - ${skl.percent.substring(0,2).replaceAll(" ", "")}`)
      })

      if (candidateSkill.length) setSkillLevels(candidateSkill)
    }

    let interests = sessionStorage.getItem('interests')

    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()
  
    const newData = [];
    // eslint-disable-next-line
    for (let i = 0; i < interests?.data?.skills.length; i++) {
      newData.push({ value: interests?.data?.skills[i].name, label: interests?.data?.skills[i].name });
    }

    setSkillsDB(interests?.data?.skills);

    setSkills(newData);
    setShowSpinner(false);
  }

  useEffect(() => fetchSkills(), [])

  /* Mui hook to read screen size - We use it for small screen rendering */
  const isSmallScreen = useMediaQuery('(max-width: 1201px)')

  const updateTags = newTags => {
    setSkillLevels(newTags)

    const skillArray = []
    
    newTags.forEach(tag => {
      const skill = getSkillAndLevel(tag)
      if (skill) skillArray.push(skill)
    });

    if (skillArray.length) {
      setModalSkillsListToPost(skillArray)

      const skillArrayToShow = [];

      skillArray.forEach(skl => {
        skillArrayToShow.push({
          image: placeholder,
          name: skl.skill.name,
          percent: returAsYears(skl.level),
          color: "info",
          value: getSkillValue(skl.level)
        });
      })
      
      setModalSkillsListToShow(skillArrayToShow)
    }
  }

  const addSkill = () => {
    let skillIsInArray = false

    skillLevels.forEach(skill => {
      if (skill.includes(selectedSkill)) skillIsInArray = true
    })
    
    const newSkill = `${selectedSkill} - ${selectedLevel}`

    if (!skillIsInArray && selectedSkill && selectedLevel) {
      skillLevels.push(newSkill)
      updateTags(skillLevels)
    }

    // If skill is in array, replace it with the new one
    if (skillIsInArray && selectedSkill && selectedLevel) {
      const newSkillLevels = skillLevels.map(skill => {
        if (skill.includes(selectedSkill)) return newSkill
        return skill
      })
      updateTags(newSkillLevels)
    }
  }

  useEffect(() => { if (skillLevels.length > 1) updateTags(skillLevels) }, [skillLevels])

  return (
    <Modal open={showAddSkillModal} onClose={() => setShowAddSkillModal(false)}>
      <SuiBox
        sx={{
          width: "850px",
          height: "auto",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: 'none',
          boxShadow: 24,
          borderRadius: { xs: 0, md: 4 },
          p: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {showSpinner ?
          <SuiBox my={15} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={40} /></SuiBox>
        :
          <>
            <SuiTypography sx={{ my: 2.5 }} variant="h6">Add new skills to candidate</SuiTypography>

            <SuiBox mt={2}>
                <SuiBox ml={1} mb={0.5}>
                    <SuiTypography variant="button">Skills and knowledge levels</SuiTypography>
                </SuiBox>

                <Grid container xs={12} md={12} spacing={1}>
                    <Grid item xs={4}>
                        <SuiSelect
                            placeholder="Skill"
                            options={skills}
                            name="skill"
                            smallFont={isSmallScreen}
                            onChange={e => setSelectedSkill(e.value)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <SuiSelect
                            placeholder="Years"
                            options={years}
                            name="level"
                            smallFont={isSmallScreen}
                            onChange={e => setSelectedLevel(e.value)}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SuiButton color="info" onClick={addSkill} fullWidth>
                            Add
                        </SuiButton>
                    </Grid>

                    <Grid item xs={12} md={12} className="skillsBox">
                        <SuiTagInput
                          placeholder=" "
                          tags={skillLevels}
                          onChange={updateTags}
                        />
                    </Grid>
                </Grid>
            </SuiBox>

            <SuiBox mt={5} mb={5} width='80%' mx='auto' display="flex">
                <SuiButton
                  sx={{ mx: 1 }}
                  fullWidth
                  color="info"
                  variant="outlined"
                  disabled={showSpinner}
                  onClick={() => {
                    setModalSkillsListToShow(skillsListToShow)
                    setModalSkillsListToPost(skillsListToPost)
                    setShowAddSkillModal(false)
                  }}
                >
                  Cancel
                </SuiButton>

                <SuiButton
                    sx={{ mx: 1 }}
                    fullWidth
                    color="info"
                    variant="gradient"
                    onClick={() => {
                      setSkillsListToshow(modalSkillsListToShow)
                      setSkillsListToPost(modalSkillsListToPost)
                      setSkillUpdated(true)
                      setShowAddSkillModal(false)
                    }}
                >
                    Confirm
                </SuiButton>

            </SuiBox>
          </>
        }
      </SuiBox>
    </Modal>
  )
}

export default AddSkillModal

// typechecking props
AddSkillModal.propTypes = {
    showAddSkillModal: PropTypes.bool.isRequired,
    setShowAddSkillModal: PropTypes.func.isRequired,
    skillsListToShow: PropTypes.array.isRequired, // eslint-disable-line
    setSkillsListToshow: PropTypes.func.isRequired,
    skillsListToPost: PropTypes.array.isRequired, // eslint-disable-line
    setSkillsListToPost: PropTypes.func.isRequired,
    setSkillUpdated: PropTypes.func.isRequired,
    returAsYears: PropTypes.func.isRequired,
    getSkillValue: PropTypes.func.isRequired,
};