/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";

import { ReactSession } from 'react-client-session';

// Settings page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function ChangeEmail({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { email } = formField;
  const {
    email: emailV,
  } = values;

  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  // const candidate = ReactSession.get("candidate");
  const postToken = `Token ${token}`;

  function showSuccess() {
    let timerSuccessId = "";
    $(".alertSuccessBox div div").text("Email succesfully changed.");
    $(".alertSuccessBox").show();
    $('html, body').animate({
      scrollTop: $(".alertSuccessBox").offset().top
    }, 100);
    if (timerSuccessId) {
      clearTimeout(timerSuccessId);
    }
    timerSuccessId = setTimeout(() => {
      $(".alertSuccessBox").hide();
    }, 5000);
  }

  function showInfo() {
    let timerInfoId = "";
    $(".alertInfoBox").show();
    $('html, body').animate({
      scrollTop: $(".alertInfoBox").offset().top
    }, 100);
    if (timerInfoId) {
      clearTimeout(timerInfoId);
    }
    timerInfoId = setTimeout(() => {
      $(".alertInfoBox").hide();
    }, 5000);
  }

  function showError(text) {
    let timerErrorId = "";
    $(".alertErrorBox div div").text(text);
    $(".alertErrorBox").show();
    $('html, body').animate({
      scrollTop: $(".alertErrorBox").offset().top
    }, 100);
    if (timerErrorId) {
      clearTimeout(timerErrorId);
    }
    timerErrorId = setTimeout(() => {
      $(".alertErrorBox").hide();
    }, 7500);
  }

  function updateEmail() {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/changeEmail/`;
    const newEmail = $("input[name=email]").val();
    const postBody = {
      "newEmail": newEmail,
    };
    if (newEmail && $("form .MuiTypography-caption:visible").text().length === 0) {
      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': postToken
        },
        body: JSON.stringify(postBody)
      };
      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            showInfo();
            $(".codeBox").show();
            $(".emailBox").hide();
          } else if (response.data && response.data.error) {
            showError(response.data.error)
          } else {
            showError("Invalid email");
          }
        });
    }
  }

  function updateEmailConfirm() {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/changeEmailConfirm/`;
    const code = $("input[name=code]").val();
    const postBody = {
      "code": code,
    };
    if (code && $("form .MuiTypography-caption:visible").text().length === 0) {
      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': postToken
        },
        body: JSON.stringify(postBody)
      };
      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            showSuccess();
            $("input[name=code]").val("");
            $(".codeBox").hide();
            $(".emailBox").show();
          } else if (response.data && response.data.error) {
            showError(response.data.error)
          } else {
            showError("Invalid email");
          }
        });
    }
  }

  return (
    <Card id="change-email">
      <SuiBox p={3}>
        <SuiTypography variant="h5">Change Email</SuiTypography>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type="email"
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12} display="none" className="codeBox">
            <SuiInput name="code" type="text" label="Code" placeholder="Code" />
          </Grid>
        </Grid>
        <SuiBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <SuiBox ml="auto" mt={4} className="emailBox">
            <SuiButton variant="outlined" color="info" size="small" onClick={updateEmail}>
              Update email
            </SuiButton>
          </SuiBox>
          <SuiBox ml="auto" mt={4} display="none" className="codeBox">
            <SuiButton variant="outlined" color="info" size="small" onClick={updateEmailConfirm}>
              Send Code
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// typechecking props for UserSignUp
ChangeEmail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChangeEmail;
