/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// react-router-dom components
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";
// MUI components
import Tooltip from "@mui/material/Tooltip";

function SourcerCell({ image, name }) {

  if (!image || !name) return <></>

  const fullImage = `${process.env.REACT_APP_API_ROUTE}${image}`

  return (
      <SuiBox display="flex" alignItems="center" pr={2} minWidth='3rem'>
          <Tooltip title={name} placement="top" sx={{ mb: 1, ml: .5 }}>
            <SuiAvatar src={fullImage} alt={name} variant="rounded" size="sm" />
          </Tooltip>
      </SuiBox>
  );
}

// Typechecking props for the SourcerCell
SourcerCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default SourcerCell;
