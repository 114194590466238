/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { ReactSession } from 'react-client-session'
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// MUI Components
import Modal from '@mui/material/Modal';
// import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiEditor from "components/SuiEditor";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiBadge from "components/SuiBadge";
import SuiAlert from 'components/SuiAlert'

import PieChart from "examples/Charts/PieChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";

function SuccessReportModal({ successReport, setSuccessReport, job, report, reportNotes, setReportNotes, loading, horizontalBarChartData, pieChartData }) {

    ReactSession.setStoreType("localStorage")
    // const user = ReactSession.get("user")
    const token = ReactSession.get("token")
    const searchUrl = decodeURIComponent(window.location.search);
    const searchParams = new URLSearchParams(searchUrl);
    const jobId = searchParams.get('id');
    const [showAlert, setShowAlert] = useState(false);

    const fullname = `${job?.company?.name} ${job?.name}`;
    
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

    const reasons = [
        { value: "", label: "No close reason selected" },
        { value: "client_hired_elsewhere", label: "Client hired elsewhere" },
        { value: "client_closed_search", label: "Client closed the search" },
        { value: "client_unresponsive", label: "Client unresponsive" },
        { value: "client_hired_tecla", label: "Client hired TECLA candidate" },
        { value: "tecla_closed_search", label: "TECLA closed search due to poor fit with client" },
    ];

    const hiredReasons = [
        { value: true, label: "Sucess (Job closed with one or more candidates hired)" },
        { value: false, label: "Unsuccessful (job closed with no candidates hired)" },
    ];

    const formatHiringMethod = hm => {
      if (hm === "dh") return "Direct Hire"
      if (hm === "dhp") return "Direct Hire - Payroll"
      if (hm === "dhf") return "Direct Hire"
      if (hm === "saf") return "Staff Augmentation"
      return "Staff Augmentation" 
    }

    const formatHiringMethodColor = hm => {
      if (hm === "sa") return "primary"
      return "info" 
    }

    const formatStatusColor = status => {
      if (status === "closed") return "error"
      return "success" 
    }

    const updateReport = async () => {
        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${jobId}/updateJobReport/`;

        const postBody = {
            'reportNotes': reportNotes,
        };

        if ($("input[name=contract]").val()) {
          postBody.reportCloseReason = $("input[name=contract]").val();
        }

        if ($("input[name=reportDaysOpen]").val()) {
          postBody.reportDaysOpen = Number($("input[name=reportDaysOpen]").val());
        }

        if ($("input[name=someoneWasHired]").val()) {
          postBody.someoneWasHired = $("input[name=someoneWasHired]").val() === "true" ? 1 : 0;
        }

        const requestMetadata = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': `Token ${token}`
            },
            body: new URLSearchParams(postBody)
        };

        fetch(recipeUrl, requestMetadata)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                  setShowAlert(true)       
                  setTimeout(() => setShowAlert(false), 2000)
                }
            });
    }

    return (
        <Modal
            open={successReport}
            onClose={() => setSuccessReport(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <SuiBox sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: "100vw", md: "40vw", xl: "70vw" },
                height: { xs: "101vh", md: "fit-content" },
                maxHeight: "100%",
                bgcolor: 'background.paper',
                border: 'none',
                boxShadow: 24,
                borderRadius: { xs: 0, md: 4 },
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}>
              <SuiBox sx={{ maxHeight: "100%", overflow: "auto" }}>
                {showAlert && <SuiAlert color="info">Report Updated</SuiAlert>}
                { loading ? (
                <SuiBox className="moonCover" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={0}>
                    <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                </SuiBox>
                ) : null }
                <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                <SuiBox display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                    <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Success report for&nbsp;</SuiTypography>
                    <SuiBox sx={{ mx: { xs: '110', md: '0' } }}>
                    <SuiTypography variant="h6" fontWeight="bold">{fullname}</SuiTypography>
                    </SuiBox>
                </SuiBox>

                <SuiBox>
                  <SuiBadge
                    badgeContent={formatHiringMethod(job?.hiringMethod)}
                    variant="contained"
                    circular="true"
                    size="sm"
                    color={formatHiringMethodColor(job?.hiringMethod)}
                    container 
                    sx={{ height: 30, my: 1, marginRight: "5px" }}
                  />
                  <SuiBadge 
                    color={formatStatusColor(job?.status)}
                    badgeContent={report?.status} 
                    variant="contained"
                    circular="true"
                    size="sm"
                    container
                    sx={{ height: 30, my: 1 }} />
                </SuiBox>

                </SuiBox>
                <SuiBox id="modal-modal-description" borderRadius="16px" height="100%" my={2} p={2} sx={{ overflow: "auto", minHeight: "330px" }}>
                  <Grid container xs={12} md={12} spacing={1}>
                      <Grid item xs={5} mb={2}>
                        <SuiTypography variant="button" ml={1} mb={1}>Reason for closing:</SuiTypography>
                        { report.reportCloseReason ? (
                          <SuiSelect
                            placeholder="Select a reason for closing"
                            options={reasons}
                            defaultValue={reasons.find(({ value }) => value === report.reportCloseReason)}
                            name="contract"
                          />
                        ) : (
                          <SuiSelect
                            placeholder="Select a reason for closing"
                            options={reasons}
                            defaultValue={{ value: "", label: "No close reason selected" }}
                            name="contract"
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <SuiTypography variant="button" ml={1} mb={1}>Days open</SuiTypography>
                        <SuiInput type="days" placeholder="Days open" name="reportDaysOpen" defaultValue={report?.reportDaysOpen} />
                      </Grid>
                      <Grid item xs={5}>
                        <SuiTypography variant="button" ml={1} mb={1}>Result for this job</SuiTypography>
                        { report?.someoneWasHired || report?.someoneWasHired === false ? (
                          <SuiSelect
                            placeholder="Result for this job"
                            options={hiredReasons}
                            defaultValue={hiredReasons.find(({ value }) => value === report.someoneWasHired)}
                            name="someoneWasHired"
                          />
                        ) : (
                          <SuiSelect
                            placeholder="Result for this job"
                            options={hiredReasons}
                            name="someoneWasHired"
                          />
                        )}
                      </Grid>
                  </Grid>
                  <Grid>
                      <SuiEditor
                          maxLength={500}
                          defaultValue={reportNotes}
                          name="notes"
                          onChange={setReportNotes}
                          placeholder="Notes"
                      />
                  </Grid>
                </SuiBox>
                <SuiBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={7}>
                      { horizontalBarChartData && horizontalBarChartData.labels && horizontalBarChartData.labels.length ? (
                        <HorizontalBarChart title="Decline reasons for this job" chart={horizontalBarChartData} />
                      ) : (
                        <SuiBox p={2}>
                          <SuiTypography variant="h6">Decline reasons for this job</SuiTypography>
                          <SuiTypography component="div" variant="button" fontWeight="regular" color="text" mt={1}>No Data</SuiTypography>
                        </SuiBox>
                      ) }
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      { pieChartData && pieChartData.labels && pieChartData.labels.length ? (
                        <PieChart title="Candidates per status" chart={pieChartData} />
                      ) : (
                        <SuiBox p={2}>
                          <SuiTypography variant="h6">Candidates per status</SuiTypography>
                          <SuiTypography component="div" variant="button" fontWeight="regular" color="text" mt={1}>No Data</SuiTypography>
                        </SuiBox>
                      ) }
                    </Grid>
                  </Grid>
                </SuiBox>
                <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0, textAlign: "right", overflow: "hidden" }}>
                  <SuiButton color="info" onClick={updateReport}>Save</SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
        </Modal>
  )
}

SuccessReportModal.propTypes = {
    successReport: PropTypes.bool.isRequired,
    setSuccessReport: PropTypes.func.isRequired,
    reportNotes: PropTypes.string.isRequired,
    setReportNotes: PropTypes.func.isRequired,
    job: PropTypes.object.isRequired, // eslint-disable-line
    report: PropTypes.object.isRequired, // eslint-disable-line
    loading: PropTypes.bool.isRequired,
    horizontalBarChartData: PropTypes.object.isRequired, // eslint-disable-line
    pieChartData: PropTypes.object.isRequired, // eslint-disable-line
}

export default SuccessReportModal