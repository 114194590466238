/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import checkout from "layouts/cvBuilder/formSchemas/form";

ReactSession.setStoreType("localStorage");
/* We use user & candidate session variables to get information about the user */
const user = ReactSession.get("user");
const candidate = ReactSession.get("candidate");

const {
  formField: {
    firstName,
    lastName,
    email,
    phone,
    linkedin,
    bio,
  },
} = checkout;

/* If the user has previously set certain values, we use those values. If not, values start off empty. */
export default {
  [firstName.name]: user && user.first_name ? user.first_name : "",
  [lastName.name]: user && user.last_name ? user.last_name : "",
  [email.name]: user && user.email ? user.email : "",
  [phone.name]: candidate && candidate.phone ? candidate.phone : "",
  [linkedin.name]: candidate && candidate.linkedin ? candidate.linkedin : "",
  [bio.name]: candidate && candidate.bio ? candidate.bio : "",
};
