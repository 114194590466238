/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function ProductCell({ name, jobId }) {

	const jobLink = `/job?id=${jobId}`;

  return (
    <SuiBox display="flex" alignItems="center">
      <SuiTypography variant="button" fontWeight="medium" sx={{ cursor: "pointer", lineHeight: 0 }} component="a" mr={1} href={jobLink} target="_blank">
        {name}
      </SuiTypography>
    </SuiBox>
  );
}

// Typechecking props for the ProductCell
ProductCell.propTypes = {
  name: PropTypes.string.isRequired,
  jobId: PropTypes.number.isRequired,
};

export default ProductCell;
