/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
import { formatDate } from 'utils/commonFunctions'
// @mui material components
import Modal from '@mui/material/Modal'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'

function FullFeedbackModal({ showFullFeedbackModal, setShowFullFeedbackModal, feedback }) {

  const userName = feedback?.candidate ?
    `${feedback?.candidate?.user?.first_name} ${feedback?.candidate?.user?.last_name}`
    :
    'Guest'

  const formatFeedbackReason = reason => {
    if (reason === 'non-competitive-salary') return 'Non competitive salary'
    if (reason === 'missmatching-skills') return 'Mismatching skills'
    if (reason === 'responsibilities-not-challenging') return 'Job responsibilities not challenging enough'
    if (reason === 'company-description-interests') return 'Company description doesn’t match my interests'
    if (reason === 'company-beneficts-compelling') return 'Company benefits are not compelling'
    return 'Other'
  }

  return (
    <Modal
      open={showFullFeedbackModal}
      onClose={() => setShowFullFeedbackModal(false)}
    >
        <SuiBox
          sx={{
            width: { xs: '90%', md: '50%' },
            height: { xs: '90%', md: 'auto' },
            maxHeight: '90vh',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            borderRadius: { xs: 0, md: 4 },
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflowY: 'auto'
          }}
        >
          <SuiTypography sx={{ mt: 2.5 }} variant='h4'>
            {userName}&apos;s feedback for {feedback?.job?.name} - {feedback?.job?.company?.name}
          </SuiTypography>
          <SuiTypography variant='body2'>{formatDate(feedback?.createdAt)}</SuiTypography>

          <SuiTypography variant='h6' mt={3}>Feedback reason</SuiTypography>
          <SuiTypography variant='body2'>{formatFeedbackReason(feedback?.reason)}</SuiTypography>

          {feedback?.text && (
            <>
              <SuiTypography variant='h6' mt={3}>Feedback notes</SuiTypography>
              <SuiTypography variant='body2'>{feedback?.text}</SuiTypography>
            </>
          )}
          
          <SuiBox mt={5} mb={5} width='80%' mx='auto' display='flex'>
            <SuiButton
              sx={{ mx: 1 }}
              fullWidth
              color='info'
              variant='outlined'
              onClick={() => setShowFullFeedbackModal(false)}
            >
              Close
            </SuiButton>
          </SuiBox>

        </SuiBox>
    </Modal>
  )
}

// Typechecking props for the ProfilesList
FullFeedbackModal.propTypes = {
  showFullFeedbackModal: PropTypes.bool.isRequired,
  setShowFullFeedbackModal: PropTypes.func.isRequired,
  feedback: PropTypes.object.isRequired, // eslint-disable-line
}

export default FullFeedbackModal