/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session'
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
import SuiInput from "components/SuiInput";


function DeclineInvoiceModal({ showDeclineInvoiceModal, setShowDeclineInvoiceModal, invoiceId, updateInvoices, setUpdateInvoices }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  const [showError, setShowError] = useState(false)

  const [declineReason, setDeclineReason] = useState(null)

  const declineInvoice = () => {
    setShowSpinner(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${invoiceId}/setInvoiceStatus/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify({
        status: 'declined-by-client',
        reason: declineReason
      })
    })
    .then(async response => {
      const data = await response.json()

      if (data.success) {
        setShowSpinner(false)
        setShowDoneIcon(true)
        setUpdateInvoices(updateInvoices + 1)

        setTimeout(() => {
          setShowDeclineInvoiceModal(false)
          setShowDoneIcon(false)
          // window.location.reload()
        }, 2000)
      }
      else {
        setShowError(true)
        setShowSpinner(false)
        
        setTimeout(() => setShowError(false), 2000)
      }
    })
    .catch(error => {
      setShowError(true)
      console.error('There was an error!', error)
    })
  }

  return (
    <Modal open={showDeclineInvoiceModal} onClose={() => setShowDeclineInvoiceModal(false)}>
        <SuiBox sx={{
            width: "650px",
            height: "auto",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            borderRadius: { xs: 0, md: 4 },
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        }} >

                {showDoneIcon && <SuiAlert color="info">Invoice declined!</SuiAlert>}
                {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                <SuiTypography sx={{ my: 2.5 }} variant="h4">Decline invoice?</SuiTypography>

                <SuiTypography sx={{ my: 2.5 }} variant="body2">Are you sure to decline this invoice? This action can’t be undone. The candidate will receive a notification. If sure, please describe the reason to decline and/or the changes or actions required from the candidate to solve this.</SuiTypography>

                <SuiInput
                  type="text"
                  placeholder="Decline reason (max 100 chars.)"
                  name="reason"
                  inputProps={{ maxLength: 100 }}
                  onChange={e => setDeclineReason(e.target.value)}
                  sx={{ my: 3 }}
                />
               
                <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                    <SuiButton
                      sx={{ mx: 1 }}
                      fullWidth
                      color="info"
                      variant="outlined"
                      disabled={showSpinner || showDoneIcon}
                      onClick={() => setShowDeclineInvoiceModal(false)}
                    >
                      Cancel
                    </SuiButton>

                    {/* eslint-disable-next-line */}
                    {showSpinner ?
                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                        :
                        showDoneIcon ?
                            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                            :
                            <SuiButton
                              sx={{ mx: 1 }}
                              fullWidth
                              disabled={showSpinner || showDoneIcon || !declineReason?.length}
                              color="info"
                              variant="gradient"
                              onClick={() => declineInvoice()}
                            >
                              Decline invoice
                            </SuiButton>
                    }
                </SuiBox>
        </SuiBox>
    </Modal>
  )
}

// Setting default values
DeclineInvoiceModal.defaultProps = {
  updateInvoices: 0,
  setUpdateInvoices: null
};

// Typechecking props for the ProfilesList
DeclineInvoiceModal.propTypes = {
  showDeclineInvoiceModal: PropTypes.bool.isRequired,
  setShowDeclineInvoiceModal: PropTypes.func.isRequired,
  invoiceId: PropTypes.number.isRequired,
  updateInvoices: PropTypes.number,
  setUpdateInvoices: PropTypes.objectOf(PropTypes.any),
};
  

export default DeclineInvoiceModal