/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// Soft UI Dashboard PRO React context
import { useSoftUIController, setOpenJobFinder } from "context";
// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
// Custom styles for the JobFinder
import JobFinderRoot from "examples/JobFinder/JobFinderRoot";

function JobFinder({ href,
                    skills,
                    setSkills,
                    skillsStart,
                    categories,
                    companies,
                    setCompanies,
                    companiesStart,
                    filters,
                    setFilters,
                    companiesString,
                    setCompaniesString,
                    inReviewStateFilter,
                    setInReviewStateFilter,
                    activeStateFilter,
                    setActiveStateFilter,
                    pauseStateFilter,
                    setPauseStateFilter,
                    closedStateFilter,
                    setClosedStateFilter,
                    hotJobsFilter,
                    setHotJobsFilter,
                    setPage,
                    fetchJobsData
                  }) {
  
  const [controller, dispatch] = useSoftUIController();
  const { openJobFinder } = controller;

  const handleCloseJobFinder = () => setOpenJobFinder(dispatch, false);

  const [filtersWereCleared, setFiltersWereCleared] = useState(false)

  function clearFilters() {
    const filtersCopy = { ...filters };
    const filtersTemp = {
      skills: [],
      skillString: "All",
      companies: [],
      categories: [],
      minSalary: 0,
      maxSalary: 0,
      page: 1,
      updateCompany: filtersCopy.updateCompany,
    }
    setFilters(filtersTemp);
    setSkills([]);
    setCompanies([]);

    $("[name=jobName]").val("")
    setInReviewStateFilter(false);
    setActiveStateFilter(false);
    setPauseStateFilter(false);
    setClosedStateFilter(false);
    setHotJobsFilter(false);

    let timerid = "";
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      setSkills(skillsStart);
      setCompanies(companiesStart)
    }, 10);

    setFiltersWereCleared(true)

    handleCloseJobFinder()
  }

  useEffect(() => { 
    if (filtersWereCleared) {
      fetchJobsData() 
      setFiltersWereCleared(false)
    }
  }, [filtersWereCleared])

  function skillChecked(skillId) {
    const filtersCopy = { ...filters };
    const skillArray = filtersCopy.skills.filter(x => x === skillId);
    if (skillArray && skillArray.length) {
      return true;
    }

    return false;
  }

  function companyChecked(companyValue) {
    const filtersCopy = { ...filters };
    const companyArray = filtersCopy.companies.filter(x => x === companyValue);
    if (companyArray && companyArray.length) {
      return true;
    }

    return false;
  }

  function checkCompany(target) {
    const checked = !$(target).parent().hasClass("Mui-checked");
    const filtersCopy = { ...filters };
    let companiesStringCopy = companiesString;
    if ($(target).val()) {
      const checkValue = Number($(target).parent().attr("data-id"));
      if (checked) {
        filtersCopy.companies.push(checkValue);
      } else {
        filtersCopy.companies = filtersCopy.companies.filter(x => x !== checkValue);
      }

      const companyCopy = [...companiesStart];
      const companyArray = companyCopy.filter(x => x.value === checkValue);
      if (companyArray && companyArray.length) {
        const company = companyArray[0];
        if (companiesStringCopy === "All" && checked) {
          companiesStringCopy = "";
        }
        if (checked) {
          companiesStringCopy = `${companiesStringCopy}, ${company.label}`;
        } else {
          companiesStringCopy = companiesStringCopy.replace(`, ${company.label}`, "");
          companiesStringCopy = companiesStringCopy.replace(`${company.label}`, "");
        }
        if (companiesStringCopy === "") {
          companiesStringCopy = "All";
        }
        if (companiesStringCopy.substring(0, 1) === ",") {
          companiesStringCopy = companiesStringCopy.substring(2, companiesStringCopy.length);
        }
      }

      setCompaniesString(companiesStringCopy);
      setFilters(filtersCopy);
    }
  }

  function checkSkill(target) {
    const checked = !$(target).parent().hasClass("Mui-checked");
    const filtersCopy = { ...filters };
    if ($(target).val()) {
      const checkValue = Number($(target).parent().attr("data-id"));
      if (checked) {
        filtersCopy.skills.push(checkValue);
      } else {
        filtersCopy.skills = filtersCopy.skills.filter(x => x !== checkValue);
      }

      const skillCopy = [...skillsStart];
      const skillArray = skillCopy.filter(x => x.id === checkValue);
      if (skillArray && skillArray.length) {
        const skill = skillArray[0];
        if (filtersCopy.skillString === "All" && checked) {
          filtersCopy.skillString = "";
        }
        if (checked) {
          filtersCopy.skillString = `${filtersCopy.skillString}, ${skill.name}`;
        } else {
          filtersCopy.skillString = filtersCopy.skillString.replace(`, ${skill.name}`, "");
          filtersCopy.skillString = filtersCopy.skillString.replace(`${skill.name}`, "");
        }
        if (filtersCopy.skillString === "") {
          filtersCopy.skillString = "All";
        }
        if (filtersCopy.skillString.substring(0, 1) === ",") {
          filtersCopy.skillString = filtersCopy.skillString.substring(2, filtersCopy.skillString.length);
        }
      }

      setFilters(filtersCopy);
    }
  }

  function checkCategory(target) {
    const checked = !$(target).parent().hasClass("Mui-checked");
    const filtersCopy = { ...filters };
    if ($(target).val()) {
      const checkValue = $(target).parent().attr("data-id");
      if (checked) {
        filtersCopy.categories.push(checkValue);
      } else {
        filtersCopy.categories = filtersCopy.categories.filter(x => x !== checkValue);
      }

      setFilters(filtersCopy);
    }
  }

  function setMinSalaryFilter(value) {
    const filtersCopy = { ...filters };
    filtersCopy.minSalary = value;
    setFilters(filtersCopy);
  }

  function setMaxSalaryFilter(value) {
    const filtersCopy = { ...filters };
    filtersCopy.maxSalary = value;
    setFilters(filtersCopy);
  }

  function setCompanyFilter(value) {
    let companiesCopy = [...companiesStart];
    // eslint-disable-next-line
    companiesCopy = companiesCopy.filter(function (el) { return el.label.toLowerCase().includes(value) });

    setCompanies([]);
    let companyTimerid = "";
    if (companyTimerid) {
      clearTimeout(companyTimerid);
    }
    companyTimerid = setTimeout(() => {
      setCompanies(companiesCopy);
    }, 10);
  }

  function setSkillFilter(value) {
    let skillCopy = [...skillsStart];
    // eslint-disable-next-line
    skillCopy = skillCopy.filter(function (el) { return el.name.toLowerCase().includes(value.toLowerCase()) });

    setSkills([]);
    let skillTimerid = "";
    if (skillTimerid) {
      clearTimeout(skillTimerid);
    }
    skillTimerid = setTimeout(() => {
      setSkills(skillCopy);
    }, 10);
  }

  /* Custom hook to allow closing filters when clicking outside of it */
  const wrapperRef = useRef(null)

  // Detect clicks on scrollbar
  const clickedOnScrollBar = e => {
    if (e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight) return true
    return false
  }

  useEffect(() => {
    function handleClickOutside(event) {
      // The filters menu closes only when clicking outside of the component and not in the scrollbar
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !clickedOnScrollBar(event)) handleCloseJobFinder()
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [wrapperRef])

  return (
    <JobFinderRoot variant="permanent" ownerState={{ openJobFinder }}>
      <SuiBox bgColor="white" height="100%" ref={wrapperRef}>
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={3}
          pb={0.8}
          px={2}
          width="100%"
        >
          <SuiBox>
            <SuiTypography variant="h5">Job filters</SuiTypography>
          </SuiBox>

          <Icon
            sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseJobFinder}
          >
            close
          </Icon>
        </SuiBox>

        {/* JOB NAME */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="body1">Job name</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SuiBox mt={-1}>
              <SuiInput
                type="text"
                placeholder="Search by job name"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                className="searchInput"
                name="jobName"
              />
            </SuiBox>
          </AccordionDetails>
        </Accordion>
        
        {/* COMPANY NAME */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="bold">Company</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="skillsSelected" style={{ fontSize: "12px", "background": "#f6f6f6", "marginTop": "-20px", "color": "#868686", "padding": "10px" }}>{companiesString}</div>
            <SuiBox mt={1}>
              <SuiInput
                type="text"
                placeholder="Search by company"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                className="searchInput"
                name="searchInput"
                // value={companyFilter}
                onChange={e => setCompanyFilter(e.target.value)}
              >
                Search
              </SuiInput>
            </SuiBox>
            <SuiBox mt={1} className="skillBox" style={{ "maxHeight": "165px", "overflowY": "auto" }}>
              {companies.map(company => (
                <SuiBox>
                  <Checkbox data-id={company.value} className="selectSkill" onClick={e => checkCompany(e.target)} defaultChecked={companyChecked(company.value)} />
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ userSelect: "none" }}>
                    {company.label}
                  </SuiTypography>
                </SuiBox>
              ))}
            </SuiBox>
          </AccordionDetails>
        </Accordion>
        
        {/* SKILLS */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="bold">Skills</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="skillsSelected" style={{ fontSize: "12px", "background": "#f6f6f6", "marginTop": "-20px", "color": "#868686", "padding": "10px" }}>{filters.skillString}</div>
            <SuiBox mt={1}>
              <SuiInput
                type="text"
                placeholder="Search by skill"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                className="searchInput"
                name="searchInput"
                // value={skillFilter}
                onChange={e => setSkillFilter(e.target.value)}
              >
                Search
              </SuiInput>
            </SuiBox>
            <SuiBox mt={1} className="skillBox" style={{ "maxHeight": "165px", "overflowY": "auto" }}>
              {skills.map(skill => (
                <SuiBox>
                  <Checkbox data-id={skill.id} className="selectSkill" onClick={e => checkSkill(e.target)} defaultChecked={skillChecked(skill.id)} />
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ userSelect: "none" }}>
                    {skill.name}
                  </SuiTypography>
                </SuiBox>
              ))}
            </SuiBox>
          </AccordionDetails>
        </Accordion>
        
        {/* CATEGORY */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <SuiTypography>Category</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SuiBox mt={-2}>
              {categories.map(category => (
                <SuiBox>
                  <Checkbox data-id={category.id} onClick={e => checkCategory(e.target)} className="selectCategory" />
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ userSelect: "none" }}>
                    {category.name}
                  </SuiTypography>
                </SuiBox>
              ))}
            </SuiBox>
          </AccordionDetails>
        </Accordion>
        
        {/* SALARY */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <SuiTypography>Salary</SuiTypography>
          </AccordionSummary>

          <AccordionDetails>
            <SuiBox mb={2}>
              <SuiBox display="flex" mb={3}>
                <SuiTypography variant="button" ml={1}>Salary Range:</SuiTypography>
              </SuiBox>

              <SuiBox display="flex" mb={3}>
                <SuiBox pr={1}>
                  <SuiBox mb={1}>
                    <SuiInput
                      placeholder="Min Salary"
                      name="dateSelect"
                      onChange={e => setMinSalaryFilter(e.target.value)}
                      value={filters.salary}
                    />
                  </SuiBox>

                  <SuiBox mb={1}>
                    <SuiInput
                      placeholder="Max Salary"
                      name="dateSelect"
                      onChange={e => setMaxSalaryFilter(e.target.value)}
                      value={filters.salary}
                    />
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </AccordionDetails>
        </Accordion>
        
        {/* STATE */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="body">Job state</SuiTypography>
          </AccordionSummary>

          <AccordionDetails>
            <SuiBox className="skillBox" style={{ "overflowY": "auto" }}>
              <SuiBox>
                <Checkbox checked={inReviewStateFilter} onChange={() => setInReviewStateFilter(!inReviewStateFilter)}/>
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>In review</SuiTypography>
              </SuiBox>

              <SuiBox>
                <Checkbox checked={activeStateFilter} onChange={() => setActiveStateFilter(!activeStateFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Active</SuiTypography>
              </SuiBox>
              <SuiBox>

                <Checkbox checked={pauseStateFilter} onChange={() => setPauseStateFilter(!pauseStateFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Pause</SuiTypography>
              </SuiBox>

              <SuiBox>
                <Checkbox checked={closedStateFilter} onChange={() => setClosedStateFilter(!closedStateFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Closed</SuiTypography>
              </SuiBox>

              <SuiBox>
                <Checkbox checked={hotJobsFilter} onChange={() => setHotJobsFilter(!hotJobsFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Hot jobs only</SuiTypography>
              </SuiBox>
            </SuiBox>
          </AccordionDetails>
        </Accordion>

        <Divider />

        <SuiBox mt={2} pb={10} display="flex" flexDirection="column">
          <SuiButton sx={{ my: 2 }} fullWidth color="info" variant="gradient" onClick={() => {
            setPage(1)
            fetchJobsData()
            handleCloseJobFinder()
          }}>Search</SuiButton>
          <SuiButton sx={{ my: 2 }} fullWidth color="info" variant="outlined" onClick={() => {
            setPage(1)
            clearFilters()
          }}>Clear filters</SuiButton>
        </SuiBox>

      </SuiBox>
    </JobFinderRoot >
  );
}

// typechecking props
JobFinder.propTypes = {
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  skills: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setSkills: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  skillsStart: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  filters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setFilters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  categories: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  companies: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setCompanies: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  companiesStart: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  companiesString: PropTypes.string.isRequired,
  setCompaniesString: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  inReviewStateFilter: PropTypes.bool.isRequired,
  setInReviewStateFilter: PropTypes.func.isRequired,
  activeStateFilter: PropTypes.bool.isRequired,
  setActiveStateFilter: PropTypes.func.isRequired,
  pauseStateFilter: PropTypes.bool.isRequired,
  setPauseStateFilter: PropTypes.func.isRequired,
  closedStateFilter: PropTypes.bool.isRequired,
  setClosedStateFilter: PropTypes.func.isRequired,
  hotJobsFilter: PropTypes.bool.isRequired,
  setHotJobsFilter: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  fetchJobsData: PropTypes.func.isRequired,
};

export default JobFinder;
