/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
import { useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Modal from '@mui/material/Modal'
import Icon from '@mui/material/Icon'
import Checkbox from '@mui/material/Checkbox'
import Badge from '@mui/material/Badge'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import UserGlobe from 'examples/RecruiterGlobe'


function CompanyProfileManagersCard({ company, managers, editManagers, updateManagers, setUpdateManagers, setManagers }) {

  const [showEditManagersModal, setShowEditManagersModal] = useState(false)

  const setManagerCheck = managerCheckId => {
    const tempManagers = [...managers]
    const newManagers = []
    // eslint-disable-next-line
    for (const manager of tempManagers) {
      if (Number(manager.value) === Number(managerCheckId)) {

        manager.checked = !manager.checked
        if (!manager?.isNewCheck) manager.isNewCheck = true
      }

      newManagers.push(manager)
    }

    setManagers(newManagers)
  }

  const saveManagers = () => {
    setUpdateManagers(updateManagers + 1)

    $('.alertSuccessManagerBox').show()

    setTimeout(() => window.location.reload(), 500)

    setShowEditManagersModal(false)
  }

  return (
    <Card sx={{ height: 'auto' }}>
      <SuiBox p={2}>
        <SuiBox>
          <SuiTypography variant='button' fontWeight='bold'>Manager(s)</SuiTypography>

          <SuiBox display='flex' flexWrap='wrap' my={1}>
            {managers
              ?.filter(manager => manager?.checked && !manager?.isNewCheck)
              ?.map(
                manager => <UserGlobe key={manager?.value} userItem={manager} /> 
              )
            }
          </SuiBox>

          <SuiBox display='flex' justifyContent='flex-end'>
            {editManagers && (
              <>
                <Badge
                  color='secondary'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  sx={{ width: { xs: '100%', sm: 'auto' }}}
                  badgeContent={company?.managersCount}
                  showZero
                  max={999}
                >
                    <SuiButton color='info' py={25} onClick={() => setShowEditManagersModal(true)} sx={{ width: { xs: '100%', sm: 'auto' } }}>
                      EDIT MANAGERS
                    </SuiButton>
                </Badge>

                {showEditManagersModal && (
                  <Modal
                    open={showEditManagersModal}
                    onClose={() => setShowEditManagersModal(false)}
                  >
                    <SuiBox
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '100vw', md: '40vw', xl: '30vw' },
                        height: { xs: '101vh', md: 'fit-content' },
                        maxHeight: '90%',
                        bgcolor: 'background.paper',
                        border: 'none',
                        boxShadow: 24,
                        borderRadius: { xs: 0, md: 4 },
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <SuiBox display='flex' justifyContent='space-between' alignItems='center'>
                        <SuiBox display='flex' flexWrap='wrap' justifyContent='center' alignItems='center'>
                          <SuiTypography variant='h6' ml={1.5} color='dark'>Assign Recruiting Managers for&nbsp;</SuiTypography>
                          <SuiBox sx={{ mx: { xs: '110', md: '0' } }}>
                            <SuiTypography variant='h6' fontWeight='bold'>{company?.name}</SuiTypography>
                          </SuiBox>
                        </SuiBox>

                        <Icon onClick={() => setShowEditManagersModal(false)} sx={{ cursor: 'pointer', borderRadius: 1, backgroundColor: { xs: '#21c0fd', md: '#fff' }, color: { xs: '#fff', md: '#344767' } }}>close</Icon>
                      </SuiBox>

                      <SuiBox borderRadius='16px' height='100%' my={2} p={2} sx={{ overflow: 'auto' }}>
                        {managers?.map(manager => 
                          <SuiBox key={manager?.value} item xs={12} sm={12} style={{ margin: '5px 0', display: 'block', width: '100%' }}>
                            <Checkbox data-id={manager?.value} className='selectSkill' checked={manager?.checked} onChange={() => setManagerCheck(manager?.value)} />
                            <SuiTypography
                              variant='button'
                              fontWeight='regular'
                              sx={{ userSelect: 'none' }}>
                              {manager?.label}
                            </SuiTypography>
                          </SuiBox>
                        )}
                      </SuiBox>

                      <SuiBox ml={{ xs: 0, sm: 'auto' }} mt={{ xs: 2, sm: 0 }}>
                        <SuiButton color='info' onClick={saveManagers}>Save</SuiButton>
                      </SuiBox>
                    </SuiBox>
                  </Modal>
                )}
                
              </>
            )}
          </SuiBox>

        </SuiBox>
      </SuiBox>
    </Card>
  )
}

// Typechecking props for the CompanyProfileManagersCard
CompanyProfileManagersCard.propTypes = {
  company: PropTypes.object.isRequired, // eslint-disable-line
  managers: PropTypes.arrayOf(PropTypes.object),
  editManagers: PropTypes.bool.isRequired,
  updateManagers: PropTypes.number.isRequired,
  setUpdateManagers: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setManagers: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

CompanyProfileManagersCard.defaultProps = {
  managers: []
}

export default CompanyProfileManagersCard
