/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react"
// prop-type is a library for typechecking of props
import PropTypes from "prop-types" // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import { ReactSession } from 'react-client-session'
import PdfViewer from 'layouts/pdfViewer/PdfViewer'

function Download({ cvUrl }) {

  const token = ReactSession.get("token")

  const [cvLinkedToUserProfile, setCvLinkedToUserProfile] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  
  const linkCVtoUserProfile = async () => {
    setShowSpinner(true)

    await fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/setCVCreated/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Token ${token}`
      }
    })
    .then(async response => {
      const data = await response.json()

      if (data.success) {
        setShowSpinner(false)
        setCvLinkedToUserProfile(true)
      } else {
        throw new Error('Error linking CV to profile')
      }
    })
    .catch(error => console.error('There was an error!', error))
  }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">Download your CV</SuiTypography>
      </SuiBox>
      
      <a href={cvUrl} target="_blank" rel="noreferrer">
        <SuiButton color="info" fullWidth sx={{marginTop: 2, marginBottom: 2}}>Download your CV</SuiButton>
      </a>

      {/* linkCVtoUserProfile option only shows to logged in users */}
      {token ?
        <>
          {showSpinner ?  // eslint-disable-line
            <SuiBox mt={3} display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </SuiBox>
          :
            !cvLinkedToUserProfile ?
              <SuiButton
                color="info"
                fullWidth
                sx={{marginTop: 2, marginBottom: 5}}
                onClick={() => linkCVtoUserProfile()}
              >Link CV to your profile</SuiButton>
            :
            <SuiBox mt={3} mb={5} display="flex" justifyContent="center" alignItems="center">
              <DoneIcon fontSize="large"/>
            </SuiBox >
          }
        </>
        :
        null
      }

    {cvUrl &&
      <Grid item xs={15} lg={15}>
        <Card sx={{ overflowY: "auto", backgroundColor: 'white' }}>
            <PdfViewer cvUrl={cvUrl} />
        </Card>
      </Grid>
    }

    </SuiBox >
  )
}

// typechecking props for UserSignUp
Download.propTypes = {
  cvUrl: PropTypes.string.isRequired,
}

export default Download
