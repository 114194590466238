/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";

import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import ClearIcon from '@mui/icons-material/Clear';
import { Icon, Modal } from "@mui/material";

function SignInModal({ text, appError, cvError, appLoading, uploadApplyCv, applicationId, token }) {
    const lastApply = { ...ReactSession.get("lastApply") };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
      `;
    const [errorMsg, setErrorMsg] = useState(""); // eslint-disable-line
    const [open, setOpen] = useState(false);
    
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    let cvText = "";
    if (cvError) {
        cvText = "CV"
    }

    // eslint-disable-next-line
    window.onbeforeunload = function(e) {
        if ($(".loadingBox").length) {
            return "our application is still being submitted. Please don't close this tab until the process has finished.";
        }
    };

    function uploadResume() {
        const resumeField = $("input[name=cv]").prop('files')[0];

        let validCv = true;
        if (resumeField && resumeField.name) {
            const extension = resumeField.name.substring(resumeField.name.length - 4, resumeField.name.length).toLowerCase();
            if (!extension.includes("pdf")) {
                validCv = false;
            }
        }

        if (validCv) {
            const formPostData = new FormData();
            formPostData.append('cv', resumeField);
            lastApply.resume = resumeField;
            lastApply.cvForm = formPostData;
            lastApply.cvName = $("input[name=cv]").val().split('\\').pop();;
            ReactSession.set("lastApply", lastApply);
        }
    }

    // const searchParams = new URLSearchParams(window.location.search);
    // const jobId = searchParams.get('id');
    // let timerid = "";
    // ReactSession.setStoreType("localStorage");

    return (
        <>
            <SuiButton onClick={handleOpen} variant="gradient" color="info">
                {text}
            </SuiButton>
            <Modal
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
            <SuiBox 
                className="loadingBox"
                sx={{
                    display: "flex",
                    position: "relative",
                    flexDirection: "column",
                    justifyContent: "start",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "80vw", md: "40rem", lg: "33rem", xl: "30rem" },
                    // width: "45vw",
                    height: { xs: "80vh", md: "47vh" },
                    background: "white",
                    border: "none",
                    boxShadow: 16,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    overflowY: "auto"
                }}>
                    { appLoading ? (
                        <SuiBox mb={5} mt={1}>
                            <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                                <SuiTypography id="child-modal-title" variant="overline" opacity={0.75} sx={{ lineHeight: "1rem" }} mb={3}>
                                </SuiTypography>
                                <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                            </SuiBox>
                            <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center"  mt={5}>
                                <SuiTypography id="modal-modal-description" variant="h3" fontWeight="bold">
                                    Submitting application...
                                </SuiTypography>
                            </SuiBox>
                            <SuiBox className="moonItem" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={5} mb={5}>
                                <MoonLoader color="#17c1e8" loading={appLoading} css={override} size={70} />
                            </SuiBox>
                            <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <SuiTypography id="modal-modal-description" variant="h5" fontWeight="light">
                                    Your application is being submitted.
                                </SuiTypography>
                                <SuiTypography id="modal-modal-description" variant="h5" fontWeight="light">
                                    Don&apos;t close this tab until the process has finished.
                                </SuiTypography>
                            </SuiBox>
                        </SuiBox>
                    ) : (
                        <SuiBox mb={5} mt={1}>
                            <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                                <SuiTypography id="child-modal-title" variant="overline" opacity={0.75} sx={{ lineHeight: "1rem" }} mb={3}>
                                </SuiTypography>
                                <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                            </SuiBox>
                            <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center"  mt={0}>
                                <SuiTypography id="modal-modal-description" variant="h3" fontWeight="bold">
                                    Error submitting application!
                                </SuiTypography>
                            </SuiBox>
                            <SuiBox className="moonItem" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={2.5} mb={2.5}>
                                <ClearIcon sx={{ width: "100px", height: "100px", border: "5px solid #ea0606", borderRadius: "60px" }} color="error" />
                            </SuiBox>
                            <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ height: "120px", maxHeight: "120px", overflow: "auto" }}>
                                <SuiTypography variant="h5" fontWeight="light">
                                    There was a problem with your application {cvText}:
                                </SuiTypography>
                                { cvError ? (
                                    <SuiTypography variant="h5" fontWeight="light" sx={{ textAlign: "center" }}>
                                        {cvError}
                                    </SuiTypography>
                                ) : null }
                                { appError ? (
                                    <SuiTypography variant="h5" fontWeight="light" sx={{ textAlign: "center" }}>
                                        {appError}
                                    </SuiTypography>
                                ) : null }
                            </SuiBox>
                            { appError || !uploadApplyCv ? (
                                <SuiBox mt={2} display="flex" justifyContent="center" alignItems="center">
                                    <SuiButton color="info" variant="outlined" sx={{ marginRight: "20px" }} onClick={handleClose}>Edit Application</SuiButton>
                                </SuiBox>
                            ) : null }
                            { cvError && uploadApplyCv ? (
                                <SuiBox mt={2} display="flex" justifyContent="center" alignItems="center">
                                    <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={uploadResume} inputProps={{ accept: '.pdf' }} />
                                    <SuiButton color="info" variant="gradient" onClick={() => uploadApplyCv(applicationId, token)}>Retry</SuiButton>
                                </SuiBox>
                            ) : null }
                        </SuiBox>
                    ) }

                </SuiBox>
            </Modal>
        </>
    );

}

SignInModal.defaultProps = {
  text: "",
  appError: "",
  cvError: "",
  token: "",
  appLoading: false,
  applicationId: 0,
}

SignInModal.propTypes = {
  text: PropTypes.string,
  appError: PropTypes.string,
  cvError: PropTypes.string,
  token: PropTypes.string,
  appLoading: PropTypes.bool,
  applicationId: PropTypes.number,
  uploadApplyCv: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired
};

export default SignInModal;
