/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
// import PropTypes from 'prop-types'
import { useState } from 'react'
// import { ReactSession } from 'react-client-session'
// @mui material components
import Icon from "@mui/material/Icon";
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
// import SuiButton from 'components/SuiButton'
import SuiTypography from "components/SuiTypography";

function DensityOptions() {

    // ReactSession.setStoreType('localStorage')
    // const recruiter = ReactSession.get('recruiter')
    const menuItems = []

    // Conditionally populate menu items
    const buildMenu = () => {
        menuItems.push({ label: <b>Search method:</b> })
        menuItems.push({ label: <SuiBox sx={{ width: "100%" }}>
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none", marginRight: "10px" }}>Standard Search</SuiTypography>
                <input name="density" type="radio" value="0" defaultChecked={$("span.density").text() === "0"} className="dropdownRadio" onChange={e => $("span.density").text(e.target.value)} />
            </SuiBox> 
        })
        menuItems.push({ label: <SuiBox sx={{ width: "100%" }}>
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none", marginRight: "10px" }}>Density Algorithm</SuiTypography>
                <input name="density" type="radio" value="1" defaultChecked={$("span.density").text() === "1"} className="dropdownRadio" onChange={e => $("span.density").text(e.target.value)} />
            </SuiBox> 
        })
        menuItems.push({ label: <SuiBox sx={{ width: "100%" }}>
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none", marginRight: "10px" }}>Skill Density</SuiTypography>
                <input name="density" type="radio" value="2" defaultChecked={$("span.density").text() === "2"} className="dropdownRadio" onChange={e => $("span.density").text(e.target.value)} />
            </SuiBox> 
        })
        
        menuItems.push({ label: <b>Desired seniority:</b> })
        menuItems.push({ label: <SuiBox sx={{ width: "100%" }}>
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none", marginRight: "10px" }}>All</SuiTypography>
                <input name="seniority" type="radio" value="0" defaultChecked={$("span.seniority").text() === "0"}  className="dropdownRadio" onChange={e => $("span.seniority").text(e.target.value)} />
            </SuiBox> 
        })
        menuItems.push({ label: <SuiBox sx={{ width: "100%" }}>
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none", marginRight: "10px" }}>Senior Only</SuiTypography>
                <input name="seniority" type="radio" value="1" defaultChecked={$("span.seniority").text() === "1"} className="dropdownRadio" onChange={e => $("span.seniority").text(e.target.value)} />
            </SuiBox> 
        })
        menuItems.push({ label: <SuiBox sx={{ width: "100%" }}>
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none", marginRight: "10px" }}>Non-Senior</SuiTypography>
                <input name="seniority" type="radio" value="2" defaultChecked={$("span.seniority").text() === "2"} className="dropdownRadio" onChange={e => $("span.seniority").text(e.target.value)} />
            </SuiBox> 
        })
    }

    buildMenu()

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const openMenu = (event) => setAnchorEl(event.currentTarget)
    const closeMenu = () => setAnchorEl(null)

    return (
        <SuiBox>
            <Icon fontSize="default" sx={{ cursor: "pointer", marginTop: "8px" }} onClick={openMenu}>more_vert</Icon>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
            >   
                {menuItems.map(item => 
                    <MenuItem
                        key={item.label}
                    >
                        {item.label}
                    </MenuItem>
                )}
            </Menu>
        </SuiBox >
    )
}

DensityOptions.defaultProps = {
}

// typechecking props
DensityOptions.propTypes = {
}

export default DensityOptions