/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { capitalize, validateToken } from "utils/commonFunctions"; // eslint-disable-line
// import { useParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery"
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from 'components/SuiAvatar';
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import JobCard from "examples/Cards/JobCard";
import emptyImg from "assets/images/illustrations/recommended.png";
import MockCandidateAvatar from  "assets/images/mockCandidateAvatar.png"
import ProfileCompletionModal from "layouts/dashboards/default/profileCompletionModal/ProfileCompletionModal"
import LevelUpView from "layouts/dashboards/default/levelUpView/LevelUpView"


function Default() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const candidateDashboardActiveTab = ReactSession.get("candidateDashboardActiveTab");
  const sesionCandidate = { ...ReactSession.get("candidate") };
  sesionCandidate.favoriteJobs = [];
  const [candidate, setSandidate] = useState(sesionCandidate);
  const [markLessonCompleted, setMarkLessonCompleted] = useState(0);
  const [progress, setProgress] = useState(0);
  const [currentLesson, setCurrentLesson] = useState(0);
  const [plan, setPlan] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [courses, setCourses] = useState([]);

  const [videoDisplayed, setVideoDisplayed] = useState(null)
  const [moduleSelected, setModuleSelected] = useState(null)
  const [courseSelected, setCourseSelected] = useState(null)

  const [saveLessons, setSaveLessons] = useState(null);

  // const { courseId, moduleId, lessonId } = useParams();

  useEffect(() => {
    document.title = `Dashboard | TECLA`;
  }, []);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/candidate/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line

        if (res.success) {
          setSandidate(res.data);
          ReactSession.set("candidate", res.data);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });

    const lessonsUrl = `${process.env.REACT_APP_API_ROUTE}/api/academy/getSavedLessons/`;
    fetch(lessonsUrl, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line

        if (res.success) {
          setSaveLessons(res.data)
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  useEffect(() => {
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/getUserPlan/`, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success) {

          ReactSession.set("hasCompletedEnglishAssessment", res?.data?.student?.assesment);
          ReactSession.set("assesmentCalification", res?.data?.student?.assesmentCalification);
          ReactSession.set("spokenAssessmentMailSent", res?.data?.student?.spokenAssessmentMailSent);

          /* SET Program Lead DATA */
          if (res?.data?.student?.learningAdvisor) ReactSession.set("learningAdvisor", res.data.student.learningAdvisor);

          const tempCourses = [...res.data.courses]
          // eslint-disable-next-line
          for (const tempCourse of tempCourses) {
            // eslint-disable-next-line
            for (const tempModule of tempCourse.modules) {
              // eslint-disable-next-line
              if (tempModule?.module?.test) {
                const tempTest = tempModule?.test?.test ? tempModule.test.test : tempModule.module.test;
                const prevAnswers = tempModule?.test;
                tempTest.questions = []
                tempTest.score = prevAnswers ? prevAnswers.score : null
                tempTest.moduleId = tempModule.pk
                let loop = 1
                // eslint-disable-next-line
                while (tempTest[`question${loop}`] && tempTest[`question${loop}`].length) {
                  const answers = []
                  let answerLoop = 0;
                  // eslint-disable-next-line
                  for (const answer of tempTest[`answers${loop}`].split('|')) {
                    // eslint-disable-next-line
                    if (answer) {
                      answers.push({
                        "answer": answer,
                        "index": answerLoop,
                      })
                      answerLoop += 1;
                    }
                  }
                  tempTest.questions.push({
                    "question": tempTest[`question${loop}`],
                    "answers": answers,
                    "name": `question${loop}`,
                    "index": loop - 1,
                    "answered": prevAnswers ? prevAnswers[`answer${loop}`] : null,
                    "isCorrect": prevAnswers ? prevAnswers.isCorrectAnswers[loop - 1] : null,
                  })
                  loop += 1;
                }
                tempModule.lessons.push(tempTest)
              }
            }
          }
          setCourses(tempCourses);
          setProgress(res.data.progress);
          if (!plan) {
            setPlan(res.data.plan);
          }
          setCurrentLesson(res.data.currentLesson)

          const pathname = window.location.pathname; // eslint-disable-line
          let courseId = null;
          let moduleId = null;
          let lessonId = null;
          if (pathname.includes("courses")) {
            const urlIds = pathname.substr(pathname.indexOf("courses") + 8, pathname.length).split("/");
            if (urlIds.length && urlIds[0] && Number(urlIds[0])) {
              courseId = Number(urlIds[0])
            }
            if (urlIds.length && urlIds[1] && Number(urlIds[1])) {
              moduleId = Number(urlIds[1])
            }
            if (urlIds.length && urlIds[2]) {
              lessonId = urlIds[2] // eslint-disable-line
            }
          }

          if (courseId) {
            let lessonToResume = null;
            let courseToResume = null;
            let moduleToResume = null;

            for (const tempCourse of tempCourses) { // eslint-disable-line
              courseToResume = tempCourse.pk === Number(courseId) ? tempCourse : courseToResume;
              for (const tempModule of tempCourse.modules) { // eslint-disable-line
                moduleToResume = moduleId && tempModule.pk === Number(moduleId) ? tempModule : moduleToResume;
                for (const tempLesson of tempModule.lessons) { // eslint-disable-line
                  if (lessonId && lessonId !== "test" && Number(lessonId) === tempLesson.pk) { // eslint-disable-line
                    lessonToResume = tempLesson;
                    break;
                  }
                }

                if (lessonId && lessonId === "test" && moduleId && 
                  Number(moduleId) === tempModule.pk && 
                  tempModule.lessons[tempModule.lessons.length - 1]?.question1) { // eslint-disable-line
                  lessonToResume = tempModule.lessons[tempModule.lessons.length - 1];
                  break;
                }
              }
            }
            if (courseToResume) {
              setCourseSelected(courseToResume)
            }
            if (moduleToResume) {
              setLessons(moduleToResume.lessons)
              setModuleSelected(moduleToResume)
            }
            if (lessonToResume) {
              setVideoDisplayed(lessonToResume)
            }

          }
        } else {
          setPlan({})
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, [markLessonCompleted]);

  window.addEventListener('popstate', function() { // eslint-disable-line
    const pathname = window.location.pathname; // eslint-disable-line
    let courseId = null;
    let moduleId = null;
    let lessonId = null;
    if (pathname.includes("courses")) {
      const urlIds = pathname.substr(pathname.indexOf("courses") + 8, pathname.length).split("/");
      if (urlIds.length && urlIds[0] && Number(urlIds[0])) {
        courseId = Number(urlIds[0])
      }
      if (urlIds.length && urlIds[1] && Number(urlIds[1])) {
        moduleId = Number(urlIds[1])
      }
      if (urlIds.length && urlIds[2]) {
        lessonId = urlIds[2] // eslint-disable-line
      }
    }

    if (courseId) {
      let lessonToResume = null;
      let courseToResume = null;
      let moduleToResume = null;

      for (const tempCourse of courses) { // eslint-disable-line
        courseToResume = tempCourse.pk === Number(courseId) ? tempCourse : courseToResume;
        for (const tempModule of tempCourse.modules) { // eslint-disable-line
          moduleToResume = moduleId && tempModule.pk === Number(moduleId) ? tempModule : moduleToResume;
          for (const tempLesson of tempModule.lessons) { // eslint-disable-line
            if (lessonId && lessonId !== "test" && Number(lessonId) === tempLesson.pk) { // eslint-disable-line
              lessonToResume = tempLesson;
              break;
            }
          }

          if (lessonId && lessonId === "test" && moduleId && 
            Number(moduleId) === tempModule.pk && 
            tempModule.lessons[tempModule.lessons.length - 1]?.question1) { // eslint-disable-line
            lessonToResume = tempModule.lessons[tempModule.lessons.length - 1];
            break;
          }
        }
      }
      if (courseToResume && courseToResume !== courseSelected) {
        setCourseSelected(courseToResume)
      }
      if (moduleToResume && moduleToResume !== moduleSelected) {
        setLessons(moduleToResume.lessons)
        setModuleSelected(moduleToResume)
      }
      if (lessonToResume && lessonToResume !== videoDisplayed) {
        setVideoDisplayed(lessonToResume)
      }

    }
  }, {once: true});

  function getDifferenceInSeconds(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / 1000;
  }

  function timeAgo(date) {
    const currentDate = new Date();
    const seconds = Math.floor(getDifferenceInSeconds(new Date(date), currentDate));
    const mins = Math.floor(seconds / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    let timePassed = "";
    if (months >= 1) {
      timePassed = `${months}M ago`;
    } else if (days >= 1) {
      timePassed = `${days}d ago`;
    } else if (hours >= 1) {
      timePassed = `${hours}h ago`;
    } else if (mins >= 1) {
      timePassed = `${mins}m ago`;
    } else if (seconds >= 1) {
      timePassed = `${seconds}s ago`;
    }

    return timePassed;
  }
  const isMobile = useMediaQuery('(max-width:500px)')

  // If the URL contains 'courses' means the user clicked on that section in the sidenav, so we activate that tab
  // Else we keep track of the last tab the candidate opened in local storage
  // If there's any info there, we open that tab
  // Else we check candidate has any recommended jobs to open tab 0
  // Else we default to tab 1
  // const comesFromSidebarLevelUp = window?.location?.href?.includes('courses')
  const comesFromSidebarLevelUp = false;
  const [JobsTab, setJobsTabs] = useState(
    comesFromSidebarLevelUp ? 0 :
    (candidateDashboardActiveTab || (candidate.recommendedJobs.length ? 0 : 1))
    )
  
  const [openProfileCompletionModal, setOpenProfileCompletionModal] = useState(candidate?.checkList?.completedPercent === 0)

  const completionPercentage = candidate?.checkList?.completedPercent >= 100 ? 100 : parseFloat(candidate?.checkList?.completedPercent).toFixed(2)

  const handleOpenModal = () => {
    if (candidate?.checkList?.completedPercent < 100) setOpenProfileCompletionModal(true)
  }

  const handleChangeTab = tab => {
    setJobsTabs(tab)
    ReactSession.set("candidateDashboardActiveTab", tab)
  }

  return (
    <DashboardLayout>

    <SuiBox display="flex" flexDirection={isMobile ? "column-reverse" : "row"} position="relative" >
      {/* Tabs */}
      { !courseSelected ? ( <>
        <AppBar position="relative" sx={{pt: isMobile ? 8 : "auto"}}>
          <Tabs orientation={isMobile ? "vertical" : "horizontal"} value={JobsTab}>
            <SuiBox height="50px" width={isMobile ? "200px" : "10%"} mx={isMobile ? "auto" : "1%"} ml={isMobile ? "auto" : 0} display="flex" justifyContent="center" alignItems="center" >
              <Tab label="Invitations 💌" onClick={() => handleChangeTab(0)}/>
            </SuiBox>
            
            <SuiBox height="50px" width={isMobile ? "200px" : "10%"} mx={isMobile ? "auto" : "1%"} display="flex" justifyContent="center" alignItems="center" >
              <Tab label="Hot Jobs 🔥" onClick={() => handleChangeTab(1)}/>
            </SuiBox>
            
            <SuiBox height="50px" width={isMobile ? "200px" : "10%"} mx={isMobile ? "auto" : "1%"} display="flex" justifyContent="center" alignItems="center" >
              <Tab label="Saved Jobs 📦" onClick={() => handleChangeTab(2)}/>
            </SuiBox>
          </Tabs>
        </AppBar>

        {/* Profile completion */} 
        <SuiBox
          display="flex"
          sx={{
            minWidth: isMobile ? "100%" : candidate?.checkList?.completedPercent > 100 ? "10rem" : "20rem", // eslint-disable-line
            cursor: candidate?.checkList?.completedPercent > 100 ? "default" : "pointer",
            position: "absolute",
            top: 0,
            right: 5,
            zIndex: 10
          }}
          onClick={() => handleOpenModal()}
        >
          <SuiBox display="flex" flexDirection="column" justifyContent="center" alignItems="space-between" mr={3}>
            { candidate && candidate?.user?.first_name ? (
              <SuiTypography variant="h5" textAlign={isMobile ? "left" : "right"}>Hi {capitalize(candidate?.user?.first_name)}!</SuiTypography>
            ) : null }
            
            {candidate?.checkList?.completedPercent < 100 && (
              <SuiTypography variant="body2" color="secondary" textAlign={isMobile ? "left" : "right"}>
                Your profile is {completionPercentage}% complete
              </SuiTypography>
            )}
          </SuiBox>

          {!isMobile && (
            <SuiAvatar
              src={candidate?.user?.photo ? `${process.env.REACT_APP_API_ROUTE}${candidate?.user?.photo}` : MockCandidateAvatar}
              alt="avatar"
              variant="rounded"
              size="md"
              shadow="sm"
            />
          )}
        </SuiBox>
      </>) : null }

      {openProfileCompletionModal && (
        <ProfileCompletionModal
          openProfileCompletionModal={openProfileCompletionModal}
          setOpenProfileCompletionModal={setOpenProfileCompletionModal}
          candidate={candidate}
        />
      )}
    </SuiBox>


      <SuiBox py={3}>
        {/* DEFAULT EMPTY STATE */}
        {(candidate.hotJobs && !candidate.hotJobs.length) && 
        (candidate.recommendedJobs && !candidate.recommendedJobs.length) && 
        (candidate.favoriteJobs && !candidate.favoriteJobs.length) ? (
            <SuiBox sx={{ minHeight: 400, py: 4 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
              <SuiTypography variant="h4" fontWeight="medium">Plase add skills to your profile so we can display some jobs for you</SuiTypography>
              <img src={emptyImg} alt="Couple of documents" width={isMobile ? "100%" : "35%"} />
              <SuiButton
                component="a"
                href='/t/wizard'
                sx={{ backgroundColor: "#feec1e" }}
                size="large"
                >
                  <SuiTypography color="dark" fontWeight="bold">Add Skills</SuiTypography>
                </SuiButton>

            </SuiBox>
        ) : null}

        {/* INVITATIONS */}
        {JobsTab === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuiTypography variant="h4" fontWeight="bold" ml={1}>INVITATIONS 💌</SuiTypography>
            </Grid>

            {candidate.recommendedJobs && candidate.recommendedJobs.length ?
              candidate.recommendedJobs.map(recomendedJob => (
                <Grid item xs={12} className="dashboardJob">
                  <JobCard
                    by={{ name: recomendedJob.company.name, date: timeAgo(recomendedJob.createdAt) }}
                    badge={{ color: "success", label: "Invitation" }}
                    title={recomendedJob.seniorityName}
                    description={recomendedJob.description}
                    skills={recomendedJob.requiredSkills}
                    value={{ type: "$", from: recomendedJob.minSalary, to: recomendedJob.maxSalary, method: "Month" }}
                    action={{ type: "internal", route: `/job?id=${recomendedJob.id}`, label: "see more" }}
                    category={recomendedJob.category}
                    subtitle={recomendedJob.subtitle}
                    salaryBasedOnExperience={recomendedJob?.salaryBasedOnExperience}
                    job={recomendedJob}
                  />
                </Grid>
              ))
            :
              <Grid item xs={12} className="dashboardJob">
                <SuiTypography variant="h4" fontWeight="bold" ml={1} mt={4} textAlign="center">You have no job invitations yet, stay tuned!</SuiTypography>
              </Grid>
            }
          </Grid>
        )}

        {/* HOT JOBS */}
        {JobsTab === 1 && candidate.hotJobs && candidate.hotJobs.length ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuiTypography variant="h4" fontWeight="bold" ml={1}>HOT JOBS 🔥</SuiTypography>
            </Grid>
            
            {candidate.hotJobs.map(hotJob => (
              <Grid item xs={12} className="dashboardJob">
                <JobCard
                  by={{ name: hotJob.company.name, date: timeAgo(hotJob.createdAt) }}
                  badge={{ color: "error", label: "HOT JOB" }}
                  title={hotJob.seniorityName}
                  description={hotJob.description}
                  skills={hotJob.requiredSkills}
                  value={{ type: "$", from: hotJob.minSalary, to: hotJob.maxSalary , method: "Month" }}
                  action={{ type: "internal", route: `/job?id=${hotJob.id}`, label: "see more" }}
                  category={hotJob.category}
                  subtitle={hotJob.subtitle}
                  salaryBasedOnExperience={hotJob?.salaryBasedOnExperience}
                  job={hotJob}
                />
              </Grid>
            ))}
          </Grid>
        ) : null}
        
        {/* SAVED JOBS */}
        {JobsTab === 2 && candidate.favoriteJobs && candidate.favoriteJobs.length ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuiTypography variant="h4" fontWeight="bold" ml={1}>SAVED JOBS 📦</SuiTypography>
            </Grid>

            {candidate.favoriteJobs.map(favoriteJob => (
              <Grid item xs={12} className="dashboardJob">
                <JobCard
                  by={{ name: favoriteJob.company.name, date: timeAgo(favoriteJob.createdAt) }}
                  badge={{ color: "primary", label: "SAVED JOB" }}
                  title={favoriteJob.seniorityName}
                  description={favoriteJob.description}
                  skills={favoriteJob.requiredSkills}
                  value={{ type: "$", from: favoriteJob.minSalary, to: favoriteJob.maxSalary, method: "Month" }}
                  action={{ type: "internal", route: `/job?id=${favoriteJob.id}`, label: "see more" }}
                  category={favoriteJob.category}
                  subtitle={favoriteJob.subtitle}
                  salaryBasedOnExperience={favoriteJob?.salaryBasedOnExperience}
                  job={favoriteJob}
                />
              </Grid>
            ))}
          </Grid>
        ) : null}

        {/* LEVEL UP */}
        {JobsTab === 3 && (<>
          <SuiTypography variant="h4" sx={{ fontWeight: 300 }} mb={5} ml={1}>Fuel your future; learn relentlessly.</SuiTypography>
          <Grid container spacing={3} className="levelUpBox">
            <LevelUpView
              candidate={sesionCandidate}
              courses={courses}
              markLessonCompleted={markLessonCompleted}
              setMarkLessonCompleted={setMarkLessonCompleted}
              progress={progress}
              setProgress={setProgress}
              currentLesson={currentLesson}
              setCurrentLesson={setCurrentLesson}
              lessons={lessons}
              setLessons={setLessons}
              plan={plan}
              token={token}
              saveLessons={saveLessons}
              setSaveLessons={setSaveLessons}
              videoDisplayed={videoDisplayed}
              setVideoDisplayed={setVideoDisplayed}
              moduleSelected={moduleSelected}
              setModuleSelected={setModuleSelected}
              courseSelected={courseSelected}
              setCourseSelected={setCourseSelected}
            />
          </Grid>
        </>)}

      </SuiBox>
    </DashboardLayout >
  );
}

export default Default;