/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
// Data
import Listas from "layouts/pages/recruiter-applications/components/ApplicationLists";

function ProductsList() {

  useEffect(() => { document.title = `Manage applications | TECLA` }, []);

  return (
    <RecruiterDashboardLayout>
      <SuiBox my={3}>
        <Card>
          <Listas />
        </Card>
      </SuiBox>
    </RecruiterDashboardLayout>
  );
}

export default ProductsList;
