/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from "react-client-session"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
// MUI components
import { Modal } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import useMediaQuery from "@mui/material/useMediaQuery"
import Card from "@mui/material/Card"
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from "@mui/material/Checkbox";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import DataTable from "examples/Tables/DataTable"
import recruitersSourcingTableData from "layouts/aiSourcing/data/tableData"
import recruitersSourcingTableDataSmall from "layouts/aiSourcing/data/tableDataSmall"
import ActionCell from "layouts/aiSourcing/ActionCell/ActionCell"

function RecruiterSourcingContent() {

    const isMobile = useMediaQuery('(max-width:500px)')

    ReactSession.setStoreType("localStorage")
    const token = ReactSession.get("token")
    // Hack => local storage variable to control select all
    const aiSourcingSelectAll = ReactSession.get("aiSourcingSelectAll")

    const { id } = useParams()

    const [showLoading, setShowLoading] = useState(false)
    const [jobName, setJobName] = useState(null)
    const [candidates, setCandidates] = useState([])
    const [candidatesData, setCandidatesData] = useState(recruitersSourcingTableData)
    const [candidatesDataSmall, setCandidatesDataSmall] = useState(recruitersSourcingTableDataSmall)
    const [fetchAgain, setFetchAgain] = useState(0);
    const [open, setOpen] = useState(false);

    const [showStatsLoading, setShowStatsLoading] = useState(false)
    const [statsData, setStatsData] = useState(false);

    const [selectAll, setSelectAll] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    function setState(candidateId, state) {
        let status = "Removed";
        if (state === 1) {
            status = "Pending";
        }

        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${id}/setStatusAutoSourcing/`;
        const postBody = {
            id: Number(candidateId),
            status: status
        };
        const requestMetadata = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': `Token ${token}`
            },
            body: new URLSearchParams(postBody)
        };

        fetch(recipeUrl, requestMetadata)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    setFetchAgain(fetchAgain + 1);
                }
            });
    }

    const fetchSourcingContent = (showRemovedCandidates) => {
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/${id}/getAutoSourcing/`, {
            method: 'GET',
            headers: { "Authorization": `Token ${token}` }
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {

                    const candidatesArray = []
                    // eslint-disable-next-line
                    for (const item of data.data.candidates) {
                        const isRemoved = item.status === "Removed";
                        if (showRemovedCandidates || (!showRemovedCandidates && !isRemoved)) {
                            candidatesArray.push({
                                check: <Checkbox defaultChecked={aiSourcingSelectAll} className="candidateCheck" inputProps={{'data-value': item.id }} />,
                                candidate: [`${item.candidate.user.first_name} ${item.candidate.user.last_name}`, item.candidate.user.photo, item.candidate.user.id],
                                status: item.status,
                                action: <ActionCell candidate={item.candidate} sourceId={item.id} isRemoved={isRemoved} setState={setState} />
                            })
                        }
                    }

                    if (!jobName) {
                        setJobName(data.data.jobName);
                    }
                    setCandidates(data.data.candidates);
                    const candidatesDataTemp = { ...candidatesData }
                    candidatesDataTemp.rows = [];
                    setCandidatesData(candidatesDataTemp)
                    candidatesDataTemp.rows = candidatesArray
                    setCandidatesData(candidatesDataTemp)

                    const candidatesDataSmallTemp = { ...candidatesDataSmall }
                    candidatesDataSmallTemp.rows = [];
                    setCandidatesDataSmall(candidatesDataSmallTemp)
                    candidatesDataSmallTemp.rows = candidatesArray
                    setCandidatesDataSmall(candidatesDataSmallTemp)
                    setShowLoading(false);
                }
            })
            .catch(error => console.error('There was an error!', error))
    }

    useEffect(() => { 
        if ($(".showRemoved.Mui-checked").length) {
            fetchSourcingContent(true)
        } else {
            fetchSourcingContent(false)
        }
    }, [fetchAgain])

    useEffect(() => { 
        if (candidates && candidates.length) {

            const candidatesArray = []
            // eslint-disable-next-line
            for (const item of candidates) {
                const isRemoved = item.status === "Removed";
                const showRemovedCandidates = $(".candidateCheck.Mui-checked").length >= 1;
                if (showRemovedCandidates || (!showRemovedCandidates && !isRemoved)) {
                    candidatesArray.push({
                        check: <Checkbox defaultChecked={aiSourcingSelectAll} className="candidateCheck" inputProps={{'data-value': item.id }} />,
                        candidate: [`${item.candidate.user.first_name} ${item.candidate.user.last_name}`, item.candidate.user.photo, item.candidate.user.id],
                        status: item.status,
                        action: <ActionCell candidate={item.candidate} sourceId={item.id} isRemoved={isRemoved} setState={setState} />
                    })
                }
            }

            const candidatesDataTemp = { ...candidatesData }
            candidatesDataTemp.rows = [];
            setCandidatesData(candidatesDataTemp)
            candidatesDataTemp.rows = candidatesArray
            setCandidatesData(candidatesDataTemp)

            const candidatesDataSmallTemp = { ...candidatesDataSmall }
            candidatesDataSmallTemp.rows = [];
            setCandidatesDataSmall(candidatesDataSmallTemp)
            candidatesDataSmallTemp.rows = candidatesArray
            setCandidatesDataSmall(candidatesDataSmallTemp)
        }
    }, [selectAll])

    // function selectDialog() {
    //     if (status === "active" && $(".candidateCheck.Mui-checked").length) {
    //         sourceStartInviteDialog()
    //     } else {
    //         sourceStartInviteErrorDialog()
    //     }
    // }

    // function showRemoved() {
    //     setShowLoading(true);
    //     if ($(".showRemoved.Mui-checked").length) {
    //         fetchSourcingContent(false)
    //     } else {
    //         fetchSourcingContent(true)
    //     }
    // }

    function loadStats() {
        setShowStatsLoading(true)
        handleOpen()
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/${id}/getAutoSourcingStats/`, {
            method: 'GET',
            headers: { "Authorization": `Token ${token}` }
        })
        .then(async response => {
            const data = await response.json()
            if (data.success) {
                setStatsData(data.data)
                setShowStatsLoading(false)
            }
        })
        .catch(error => console.error('There was an error!', error))
    }


    return (
        <RecruiterDashboardLayout>

            {jobName ? 
                <>                    
                    <SuiBox
                        py={2}
                        mb={2}
                        display="flex"
                        flexDirection={isMobile ? "column" : 'row'}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {jobName ? (
                            <SuiTypography
                                variant="h3"
                                fontWeight="bold">
                                Sourcing for {jobName}
                            </SuiTypography>
                        ) : null }
                        {jobName ? (
                            <SuiBox display="flex">
                                <SuiBox>
                                    <SuiButton size="medium" color="info" variant="outlined" circular sx={{ marginLeft: "0.5rem" }} onClick={() => loadStats()}>STATS</SuiButton>
                                </SuiBox>
                            </SuiBox>
                        ) : null }
                    </SuiBox>
                    
                    <Card>
                        { showLoading ? (
                            <SuiBox className="moonLoaderBox" py={20} display="flex" justifyContent="center" alignItems="center" >
                                <CircularProgress />
                            </SuiBox>
                        ) : null }
                        { candidatesData?.rows && candidatesData?.rows?.length ? (<>
                            <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <DataTable table={candidatesData} entriesPerPage={{ defaultValue: 100 }} setSelectAll={setSelectAll} selectAll={selectAll} />
                            </SuiBox>
                            <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
                                <DataTable table={candidatesDataSmall} entriesPerPage={{ defaultValue: 100 }} setSelectAll={setSelectAll} selectAll={selectAll} />
                            </SuiBox>
                        </>) : (
                            <SuiBox sx={{ padding: "35px" }}>
                                <SuiTypography mb={2} variant="h5">
                                  The system is currently searching for the best candidates for this role. Please wait a moment while the list is being populated. Once the list is complete, you will be able to start inviting them.
                                </SuiTypography>
                            </SuiBox>
                        ) } 
                    </Card>

                    <Modal
                        hideBackdrop
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                    >
                        <SuiBox sx={{
                            display: "flex",
                            position: 'relative',
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: "60vw", md: "42rem", lg: "43rem", xl: "49rem" },
                            height: { xs: "100vh", md: "auto" },
                            maxHeight: { md: "101vh" },
                            minHeight: { md: "30rem" },
                            background: "white",
                            border: 'none',
                            boxShadow: 16,
                            borderRadius: { xs: 0, md: 4 },
                            p: 3,
                        }}>
                            <SuiBox>
                                <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                                    <SuiTypography id="child-modal-title" variant="overline" opacity={0.75}></SuiTypography>
                                    <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                                </SuiBox>
                                { !showStatsLoading && statsData ? (
                                    <SuiBox display="flex">
                                        <SuiTypography id="modal-modal-description" variant="h4" fontWeight="bold">
                                            Stats for {statsData.companyName} - {statsData.jobName} AI sourcing:
                                        </SuiTypography>
                                    </SuiBox>
                                ) : null }
                            </SuiBox>
                            { !showStatsLoading && statsData ? (
                                <TableContainer component={Paper} sx={{ boxShadow: "none" }} >
                                    <Table aria-label="simple table">
                                        <TableBody style={{ display: "table", width: "100%" }}>
                                            <TableRow key="rowKey" sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:last-child th': { width: "4rem" }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" } }}>
                                                <TableCell component="th" scope="row" size="small">
                                                    <SuiTypography variant="body2" color="secondary">
                                                        Soucing accuracy: {statsData.totalSourced}
                                                    </SuiTypography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="rowKey" sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:last-child th': { width: "4rem" }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" } }}>
                                                <TableCell component="th" scope="row" size="small">
                                                    <SuiTypography variant="body2" color="secondary">
                                                        Total invited: {statsData.totalInvited}
                                                    </SuiTypography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="rowKey" sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:last-child th': { width: "4rem" }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" } }}>
                                                <TableCell component="th" scope="row" size="small">
                                                    <SuiTypography variant="body2" color="secondary">
                                                        Total applied: {statsData.totalApplied}
                                                    </SuiTypography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="rowKey" sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:last-child th': { width: "4rem" }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" } }}>
                                                <TableCell component="th" scope="row" size="small">
                                                    <SuiTypography variant="body2" color="secondary">
                                                        Total declined: {statsData.totalDeclined}
                                                    </SuiTypography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key="rowKey" sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:last-child th': { width: "4rem" }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" } }}>
                                                <TableCell component="th" scope="row" size="small">
                                                    <SuiTypography variant="body2" color="secondary">
                                                        Total removed: {statsData.totalRemoved}
                                                    </SuiTypography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <SuiBox py={20} display="flex" justifyContent="center" alignItems="center" >
                                    <CircularProgress />
                                </SuiBox>
                            ) }
                            <SuiBox display="flex">
                                <SuiBox sx={{ width: { xs: "100%", md: "100%" } }} mr={2}>
                                    
                                </SuiBox>
                            </SuiBox>
                        </SuiBox>
                    </Modal>
                </>
                
                :
                <Card sx={{ marginTop: 10 }}>
                    <SuiBox py={20} display="flex" justifyContent="center" alignItems="center" >
                        <CircularProgress />
                    </SuiBox>
                </Card>
            }

        </RecruiterDashboardLayout >
    )
}

export default RecruiterSourcingContent