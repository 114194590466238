/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react"
import { ReactSession } from "react-client-session"
// @mui material components
import Grid from "@mui/material/Grid"
import CircularProgress from "@mui/material/CircularProgress"
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React example components
import BaseLayout from "layouts/pages/account/components/BaseLayout"
import StudentDashboardLayout from "examples/LayoutContainers/StudentDashboardLayout"
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart"


function Charts() {

    const token = ReactSession.get("token")
    const student = ReactSession.get("student")

    const [techTrendsData, setTechTrendsData] = useState(null)

    useEffect(() => {
        document.title = `Tech Trends | TECLA`;
    }, []);

    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')

    const fetchTechTrendsData = async () => {
        await fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/getTechTrends/`, {
            method: "GET",
            headers: {
                "Authorization": `Token ${token}`,
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    const labels = []
                    const points = []

                    data.data.forEach(item => {
                        labels.push(item.name)
                        points.push(item.points)
                    })

                    const datasets = [{
                        label: "Score by demand",
                        color: "dark",
                        data: points,
                    }]

                    setTechTrendsData({ labels, datasets })
                }
            })
            .catch(error => console.error("There was an error!", error))
    }

    useEffect(() => { fetchTechTrendsData() }, [])

    const setLayout = children => {
        if (student) return <StudentDashboardLayout>{children}</StudentDashboardLayout>
        return <BaseLayout>{children}</BaseLayout>
    }

    return (
        <>
            {setLayout(
                <>
                    {!techTrendsData ?
                        <SuiBox mt={3} sx={{ height: "80vh" }} display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress />
                        </SuiBox>
                        :
                        <SuiBox textAlign="center" width={isMobile ? "100%" : "95%"}>
                            <SuiTypography variant="h3" fontWeight="bold" mt={6} mb={1}>Tech Trends</SuiTypography>
                            <SuiTypography variant="h5" fontWeight="regular" color="secondary" mb={5}>These are the most in-demand skills on TECLA:</SuiTypography>

                            <Grid container spacing={3} >
                                <Grid item xs={12} md={12}>
                                    <VerticalBarChart title="Skills score by demand" chart={techTrendsData} height={isMobile ? "50vh" : "60vh"} />
                                </Grid>
                            </Grid>

                            <SuiTypography variant="button" fontWeight="regular" color="text" mt={5}>Values updated in real time.</SuiTypography>
                        </SuiBox>
                    }
                </>
            )}
        </>
    )
}

export default Charts
