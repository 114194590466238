/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
import { ReactSession } from 'react-client-session'
import { useEffect, useState } from 'react'
import { sliceString } from 'utils/commonFunctions'
// @mui material components
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiAlert from 'components/SuiAlert'
import SuiButton from 'components/SuiButton'

function CandidateCVsModal({
                          showCandidateCVsModal,
                          setShowCandidateCVsModal,
                          candidateId,
                        }) {

  ReactSession.setStoreType('localStorage')
  const token = ReactSession.get('token')

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)') // eslint-disable-line

  const [isFetchingData, setIsFetchingData] = useState(false)
  const [showError, setShowError] = useState(false)
  const [CVs, setCVs] = useState([])

  const fetchCandidateCVs = () => {
    setIsFetchingData(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateId}/teclaGetCandidate/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      }
    })
    .then(async response => {
      const data = await response.json()

      if (data?.success) {
        setCVs(data?.data?.cvs)
        setIsFetchingData(false)
      }
      else {
        setIsFetchingData(false)
        setShowError(true)
        setTimeout(() => setShowError(false), 2000)
      }
    })
    .catch(error => {
      setShowError(true)
      setTimeout(() => setShowError(false), 2000)
      console.error('There was an error!', error)
    })
  }

  useEffect(() => fetchCandidateCVs(), [])

  // function to format date from '2023-12-06T01:43:04.818013' to '06/12/2023 - 01:43'
  const formatDate = date => {
    const dateObj = new Date(date)
    const day = dateObj.getDate()
    const month = dateObj.getMonth() + 1
    const year = dateObj.getFullYear()
    const hours = dateObj.getHours()
    const minutes = dateObj.getMinutes()

    return `${day}/${month}/${year} - ${hours}:${minutes}`
  }

  return (
    <Modal open={showCandidateCVsModal} onClose={() => setShowCandidateCVsModal(false)}>
      <SuiBox
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '100vw', md: '45vw' },
          height: { xs: '100vh', md: 'auto' },
          maxHeight: { md: '90vh' },
          bgcolor: 'background.paper',
          border: 'none',
          boxShadow: 24,
          borderRadius: { xs: 0, md: 4 },
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflowY: 'auto'
        }}
      >
        {isFetchingData ?
          <SuiBox my={20} mx='auto'><CircularProgress size={40}/></SuiBox>
        :
          <SuiBox mx='auto'>
            {showError && <SuiAlert color='error'>There was an error, try again later</SuiAlert>}

            <SuiTypography sx={{ mt: 2.5 }} variant='h4'>Candidate CVs</SuiTypography>

            <TableContainer component={Paper} sx={{ boxShadow: 'none', my: 5, mx: 'auto', width: '100%' }}>
              <Table>
                <TableBody>
                  {CVs?.length ?
                    CVs?.map(item => (
                      <TableRow
                        key={item.createdAt}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:nth-of-type(odd)': { backgroundColor: '#f8f9fa' }
                        }}
                      >
                        <TableCell component='th' scope='row' sx={{ minWidth: {xs: 'auto', md: '15rem'} }}>
                          <SuiTypography variant='body2' fontWeight='medium' color='dark'>
                            {formatDate(item.createdAt)}
                          </SuiTypography>
                        </TableCell>

                        {item?.application?.id ?
                          <TableCell sx={{ minWidth: {xs: 'auto', md: '15rem'} }}>
                            <SuiTypography
                              variant='subtitle2'
                              sx={{ cursor: 'pointer' }}
                              component='a'
                              href={`/r/application/${item?.application?.id}`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {sliceString(item?.application?.name, 100)}
                            </SuiTypography>
                          </TableCell>
                          :
                          <TableCell sx={{ minWidth: {xs: 'auto', md: '15rem'} }}>
                            <SuiTypography variant='subtitle2'>
                              N/A
                            </SuiTypography>
                          </TableCell>
                        }

                        <TableCell sx={{ minWidth: {xs: 'auto', md: '15rem'} }}>
                          <SuiButton
                            variant='gradient'
                            color='info'
                            href={`${process.env.REACT_APP_API_ROUTE}${item.cv}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            View CV
                          </SuiButton>
                        </TableCell>                      
                      </TableRow>
                    ))
                    :
                    <SuiBox display='flex' justifyContent='center' alignItems='center'>
                      <SuiTypography variant='body2' color='secondary'>This candidate doesn&apos;t have any CVs yet.</SuiTypography>
                    </SuiBox>
                }
                    
                </TableBody>
              </Table>
            </TableContainer>
          </SuiBox>
        }
      </SuiBox>
    </Modal>
  )
}

// Typechecking props for the CandidateCVsModal
CandidateCVsModal.propTypes = {
  showCandidateCVsModal: PropTypes.bool.isRequired,
  setShowCandidateCVsModal: PropTypes.func.isRequired,
  candidateName: PropTypes.string.isRequired, // eslint-disable-line
  candidateId: PropTypes.number.isRequired,
};
  

export default CandidateCVsModal