/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { Link } from "react-router-dom";
import { ReactSession } from 'react-client-session';
// @mui material components
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import SendIcon from '@mui/icons-material/Send';
import Badge from '@mui/material/Badge';
import useMediaQuery from "@mui/material/useMediaQuery"
import CircularProgress from '@mui/material/CircularProgress';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
// Soft UI Dashboard PRO React example components
import ApplicationState from "layouts/pages/applications-list/components/ApplicationState";
import ActionCell from "layouts/pages/total-applications/components/ActionCell";
import mockCandidateAvatar from "assets/images/mockCandidateAvatar.png";

import Prospects from "layouts/pages/total-applications/data/dataTableData";
import Intro from "layouts/pages/total-applications/data/introTableData";
import Interviewing from "layouts/pages/total-applications/data/interviewingTableData";
import Declined from "layouts/pages/total-applications/data/declinedTableData";
import Hired from "layouts/pages/total-applications/data/hiredTableData";
// import Swal from "sweetalert2";


function Cameras() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [camera, setCamera] = useState(0);

  const [isFetchingData, setIsFetchingData] = useState(false);

  const applicationStatesTables = {
    "prospects": Prospects,
    "intro": Intro,
    "interviewing": Interviewing,
    "declined": Declined,
    "hired": Hired,
  }
  const [applicationStates, setApplicationState] = useState(applicationStatesTables);

  // const formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  //   maximumFractionDigits: 0
  // });

  const formatAvailability = availability => {
    switch (availability) {
      case '1-week':
        return '1 week'
      case '2-weeks':
        return '2 weeks'
      case '3-weeks':
        return '3 weeks'
      case '4-weeks':
        return '4 weeks'  
      default:
        return availability
    }
  }

  function moveTo(appId, phase, oldStatus, newMoveStatus, declinedReason, declinedComment, calendlyLink) {
    // newMoveStatus: intro
    // newMoveStatus: interviewing
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/setStatus/`;
    let apiStatus = "active";
    // eslint-disable-next-line
    if (newMoveStatus === "intro") {
      apiStatus = "intro-requested";
    } else if (newMoveStatus === "interviewing") {
      apiStatus = "interview";
    } else if (newMoveStatus === "declined") {
      apiStatus = "declined";
    } else if (newMoveStatus === "hired") {
      apiStatus = "hired";
    }
    const postBody = {
      "status": apiStatus,
      "declinedReason": declinedReason,
      "declinedComment": declinedComment
    };

    if (calendlyLink) {
      postBody.calendlyLink = calendlyLink;
    }

    const postToken = `Token ${token}`;

    const requestMetadata = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Authorization': postToken
      },
      body: new URLSearchParams(postBody)
    };

    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          const tempApplicationStates = { ...applicationStates };
          let appToMove = null;
          const statusUpdatedArray = [];
          // eslint-disable-next-line
          for (const row of tempApplicationStates[oldStatus].rows) {
            // eslint-disable-next-line
            const application = row.application;
            if (application.id !== appId) {
              statusUpdatedArray.push(row);
            } else {
              appToMove = application;
            }
          }
          let monthlySalary = "";
          let annualSalary = "";

          let row = {};
          let user = {};
          let image = mockCandidateAvatar;
          let actionNext = "Intro Request";

          // eslint-disable-next-line
          if (newMoveStatus === "prospects") {
            appToMove.status = "active";
          } else if (newMoveStatus === "intro") {
            appToMove.status = "intro-requested";
          } else if (newMoveStatus === "interviewing") {
            appToMove.status = "interview";
          } else if (newMoveStatus === "declined") {
            appToMove.status = "declined";
          } else if (newMoveStatus === "hired") {
            appToMove.status = "hired";
          }

          // eslint-disable-next-line
          let status = appToMove.status;

          let newStatus = "prospects";
          if (appToMove.status === "active") {
            status = "prospects";
            newStatus = "intro";
          }
          if (appToMove.status === "intro-requested") {
            actionNext = "Interviewing";
            status = "intro";
            newStatus = "interviewing";
          }
          if (appToMove.status === "interview") {
            actionNext = "Interviewing";
            status = "interviewing";
            newStatus = "declined";
          }
          if (appToMove.status === "declined") {
            actionNext = "Interviewing";
          }
          if (appToMove.status === "hired") {
            actionNext = "Interviewing";
          }
          actionNext = appToMove.status === "intro-requested" ? "Interviewing" : actionNext;
          actionNext = appToMove.status === "interview" ? "Declined" : actionNext;
          actionNext = appToMove.status === "declined" ? "Prospects" : actionNext;
          actionNext = appToMove.status === "hired" ? "Prospects" : actionNext;
          user = appToMove.user;
          image = { image: mockCandidateAvatar };
          if (user && user.photo) {
            image = { image: `${process.env.REACT_APP_API_ROUTE}${user.photo}` };
          }
          let country = "";
          if (user && user.candidate && user.candidate.country) {
            country = user.candidate.country.name;
          }
          const jobName = <Link to={`/job?id=${appToMove.job.id}`} target="_blank" style={{ color: "#67748e" }}>{appToMove.job.seniorityName}</Link>;
          const appLink = <Link to={`/application/${appToMove.id}`} target="_blank" style={{ color: "#67748e" }}><Tooltip title="View application" placement="top"><SendIcon style={{ width: "30px", height: "30px" }} /></Tooltip></Link>;

          /* SALARY */
          const isFreelanceJob =  appToMove?.job?.commitment?.name === "Freelance"
          let salary = ''

          if (isFreelanceJob) {
            salary = <div>Hourly Rate: ${appToMove?.salary}</div> // eslint-disable-line
          }
          else {
            monthlySalary = appToMove.monthly_salary ? appToMove.monthly_salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""; // eslint-disable-line
            annualSalary = appToMove.annual_salary ? appToMove.annual_salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""; // eslint-disable-line
            salary = <div>${monthlySalary} /Month<br/>${annualSalary} /Year</div>
          }

          row = {
            product: [`${user.first_name} ${user.last_name}`, image, appToMove.id, appToMove.isNew],
            country: country,
            availability: formatAvailability(appToMove.availability),
            salary: salary,
            job: jobName,
            app: appLink,
            action: <ActionCell phase={actionNext} status={status} newStatus={newStatus} moveTo={moveTo} appId={appToMove.id} jobStatus={appToMove.job.status} sourcingAsService={appToMove.sourcingAsService} />,
            application: appToMove,
            sourcingAsService: appToMove.sourcingAsService
          };

          tempApplicationStates[oldStatus].rows = statusUpdatedArray;
          tempApplicationStates[newMoveStatus].rows = [row].concat(tempApplicationStates[newMoveStatus].rows);
          setApplicationState(tempApplicationStates);
        }

      });
  }

  function buildRow(applications) {
    const rows = [];
    let row = {};
    let user = {};
    let image = mockCandidateAvatar;
    let monthlySalary = "";
    let annualSalary = "";

    // eslint-disable-next-line
    for (const application of applications) {
      let actionNext = "Intro Request";
      // eslint-disable-next-line
      let status = application.status;
      let newStatus = "prospects";
      if (application.status === "active") {
        status = "prospects";
        newStatus = "intro";
      }
      if (application.status === "intro-requested") {
        actionNext = "Interviewing";
        status = "intro";
        newStatus = "interviewing";
      }
      if (application.status === "interview") {
        actionNext = "Interviewing";
        status = "interviewing";
        newStatus = "declined";
      }
      if (application.status === "declined") {
        actionNext = "Interviewing";
      }
      if (application.status === "hired") {
        actionNext = "Interviewing";
      }
      actionNext = application.status === "intro-requested" ? "Interviewing" : actionNext;
      actionNext = application.status === "interview" ? "Declined" : actionNext;
      actionNext = application.status === "declined" ? "Prospects" : actionNext;
      actionNext = application.status === "hired" ? "Prospects" : actionNext;
      user = application.user;
      image = { image: mockCandidateAvatar };
      if (user && user.photo) {
        image = { image: `${process.env.REACT_APP_API_ROUTE}${user.photo}` };
      }
      let country = "";
      if (user && user.candidate && user.candidate.country) {
        country = user.candidate.country.name;
      }
      const action = <ActionCell phase={actionNext} status={status} newStatus={newStatus} moveTo={moveTo} appId={application.id} jobStatus={application.job.status} sourcingAsService={application.sourcingAsService} />;
      const jobName = <Link to={`/job?id=${application.job.id}`} target="_blank" style={{ color: "#67748e" }}>{application.job.seniorityName}</Link>;
      const appLink = <Link to={`/application/${application.id}`} target="_blank" style={{ color: "#67748e" }}><Tooltip title="View application" placement="top"><SendIcon style={{ width: "30px", height: "30px" }} /></Tooltip></Link>;

      /* SALARY */
      const isFreelanceJob =  application?.job?.commitment?.name === "Freelance"
      let salary = ''

      if (isFreelanceJob) {
        salary = <div>Hourly Rate: ${application?.salary}</div> // eslint-disable-line
      }
      else {
        monthlySalary = application.monthly_salary ? application.monthly_salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""; // eslint-disable-line
        annualSalary = application.annual_salary ? application.annual_salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""; // eslint-disable-line
        salary = <div>${monthlySalary} /Month<br/>${annualSalary} /Year</div>
      }

      row = {
        product: [`${user.first_name} ${user.last_name}`, image, application.id, application.isNew],
        country: country,
        availability: formatAvailability(application.availability),
        salary: salary,
        job: jobName,
        app: appLink,
        sourcingAsService: application.sourcingAsService,
        action,
        application,
      }

      rows.push(row);
    }

    return rows;
  }

  const fetchData = () => {
    setIsFetchingData(true)

    const url = `${process.env.REACT_APP_API_ROUTE}/api/companies/companyAllApplications/`;
    const headers = { "Content-Type": "application/json" };
    headers.Authorization = `Token ${token}`;

    fetch(url, { headers, })
      .then(async response => {
        const data = await response.json();
        // eslint-disable-next-line
        const applications = data.data;

        const tempApplicationStates = { ...applicationStates };
        tempApplicationStates.prospects.rows = buildRow(applications.active);
        tempApplicationStates.intro.rows = buildRow(applications['intro-requested']);
        tempApplicationStates.interviewing.rows = buildRow(applications.interview);
        tempApplicationStates.declined.rows = buildRow(applications.declined);
        tempApplicationStates.hired.rows = buildRow(applications.hired);
        setApplicationState(tempApplicationStates);
        // setJobName(data.data.name);
        setIsFetchingData(false)
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => fetchData(), []);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);

  }, [tabsOrientation]);

  const handleSetCamera = (event, newCamera) => setCamera(newCamera);

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <Card sx={{ minHeight: "65vh" }}>
      {isFetchingData ?
        <SuiBox m="auto" display="flex" justifyContent="center" alignItems="center"><CircularProgress size={40} /></SuiBox>
      :
        <>
          <SuiBox pt={3} px={3}>
            <SuiBox mb={4}>
              <SuiTypography variant="h5">Review All Applications</SuiTypography>
              <SuiTypography variant="body2">Review all applicants across all your job postings.</SuiTypography>
            </SuiBox>

            <SuiBox display="flex" justifyContent="start" alignItems="center" width="100%">
              <SuiBox width="100%">
                <AppBar position="static">
                  <Tabs orientation={tabsOrientation} value={camera} onChange={handleSetCamera}>

                    <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                      <Badge
                        color="secondary"
                        badgeContent={applicationStates?.prospects?.rows?.length}
                        max={999}
                        onClick={() => setCamera(0)}
                      >
                        <Tab label="Applicants" />
                      </Badge>
                    </SuiBox>

                    <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                      <Badge
                        color="secondary"
                        badgeContent={applicationStates?.intro?.rows?.length}
                        max={999}
                        onClick={() => setCamera(1)}
                      >
                        <Tab label="Intro Requested" />
                        </Badge>
                    </SuiBox>

                    <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                      <Badge
                        color="secondary"
                        badgeContent={applicationStates?.interviewing?.rows?.length}
                        max={999}
                        onClick={() => setCamera(2)}
                      >
                        <Tab label="Interviewing" />
                      </Badge>
                    </SuiBox>

                    <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                      <Badge
                        color="secondary"
                        badgeContent={applicationStates?.declined?.rows?.length}
                        max={999}
                        onClick={() => setCamera(3)}
                      >
                        <Tab label="Declined" />
                      </Badge>
                    </SuiBox>

                    <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                      <Badge
                        color="secondary"
                        badgeContent={applicationStates?.hired?.rows?.length}
                        max={999}
                        onClick={() => setCamera(4)}
                      >
                        <Tab label="Hired" />
                      </Badge>
                    </SuiBox>

                  </Tabs>
                </AppBar>
              </SuiBox>
            </SuiBox>
          </SuiBox>

          <SuiBox p={2} mt={1} width="100%" height="100%">
            <ApplicationState table={applicationStates.prospects} value={camera} index={0} />
            <ApplicationState table={applicationStates.intro} value={camera} index={1} />
            <ApplicationState table={applicationStates.interviewing} value={camera} index={2} />
            <ApplicationState table={applicationStates.declined} value={camera} index={3} />
            <ApplicationState table={applicationStates.hired} value={camera} index={4} />
          </SuiBox>
        </>
      }
    </Card >
  );
}

export default Cameras;
