/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { useParams } from 'react-router-dom';
import $ from 'jquery';
// @mui material components
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import Tooltip from "@mui/material/Tooltip";
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import ValidateEnglishLevelModal from "examples/Modal/ValidateEnglishLevel";
import SuiAlert from "components/SuiAlert";

import CheckBoxIcon from '@mui/icons-material/CheckBox';

function Skills({ formData, setActiveStep, setSelectedSkills, setSelectedInterviewInEnglish, setSelectedEnglishLevel, setSelectedCanWorkRemotely, englishLevelValidated, setEnglishLevelValidated }) { // eslint-disable-line
  ReactSession.setStoreType("localStorage");
  const { candidateId } = useParams()
  const user = ReactSession.get("user");
  const token = ReactSession.get("token");
  let CandidateForEdit = ReactSession.get(`CandidateForEdit${candidateId}`)
  if (!CandidateForEdit) {
    CandidateForEdit = ReactSession.get("CandidateForEdit");
    ReactSession.set(`CandidateForEdit${candidateId}`, CandidateForEdit);
  }

  const [skills, setSkills] = useState([]);
  const [skillLevels, setSkillLevels] = useState([]);
  const [interviewPlaceHolder, setInterviewPlaceHolder] = useState("Select...");
  const [canWorkPlaceHolder, setCanWorkPlaceHolder] = useState("Select...");

  const [candidateEnglishLevel, setCandidateEnglishLevel] = useState(CandidateForEdit?.englishLevel);
  const [showEnglishLevelUpdated, setShowEnglishLevelUpdated] = useState(false)
  const [updateWithSameValue, setUpdateWithSameValue] = useState(false);

  let skillLevelStrings = [];
  let skillLevelString = "";
  let skillString = "";
  let levelString = "";
  let tagtext = "";
  let timerid = "";

  const years = [
    { value: 1, label: "1 Year" },
    { value: 2, label: "2 Years" },
    { value: 3, label: "3 Years" },
    { value: 4, label: "4 Years" },
    { value: 5, label: "5 Years" },
    { value: 6, label: "6 Years" },
    { value: 7, label: "7 Years" },
    { value: 8, label: "8 Years" },
    { value: 9, label: "9 Years" },
    { value: 10, label: "10+ Years" },
  ];

  const defaultOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  function changeskillPlaceholder(remove) {
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      if (remove) {
        $(".react-tag-input__input").attr("placeholder", "");
      } else {
        $(".react-tag-input__input").attr("placeholder", "Skills");
      }
    }, 100);
  }

  function getStringBool(value) {
    if (value === true) {
      return "Yes";
    }
    if (value === false) {
      return "No";
    }

    return "Select...";
  }

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateSkills = async () => {
    $("input[name=cv]").attr("accept", ".pdf");
    if (CandidateForEdit.interviewInEnglish === true || CandidateForEdit.interviewInEnglish === false) setInterviewPlaceHolder(getStringBool(CandidateForEdit.interviewInEnglish))
    if (CandidateForEdit.canWorkRemotely === true || CandidateForEdit.canWorkRemotely === false) setCanWorkPlaceHolder(getStringBool(CandidateForEdit.canWorkRemotely))

    if (CandidateForEdit.skills) {
      const candidateSkill = [];
      // eslint-disable-next-line
      $.each(CandidateForEdit.skills, function (i, val) {
        candidateSkill.push(`${val.skill.name} - ${val.level}`)
      });

      if (candidateSkill.length) {
        setSkillLevels(candidateSkill);
        changeskillPlaceholder(1);
      }
    }

    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = [];
    // eslint-disable-next-line
    for (let i = 0; i < interests?.data?.skills?.length; i++) {
      newData.push({ value: interests?.data?.skills[i]?.name, label: interests?.data?.skills[i]?.name });
    }

    $(".skillsBox").attr("data-skills", JSON.stringify(interests?.data?.skills));

    setSkills(newData);
  }

  useEffect(() => { populateSkills() }, []);

  useEffect(() => {
    if (CandidateForEdit && candidateEnglishLevel && (updateWithSameValue || CandidateForEdit.englishLevel !== candidateEnglishLevel)) {
      setEnglishLevelValidated(true)
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${CandidateForEdit.user.id}/teclaValidateEnglish/`;

      const postBody = {
          'englishLevel': candidateEnglishLevel
      };

      const requestMetadata = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              'Authorization': `Token ${token}`
          },
          body: new URLSearchParams(postBody)
      };

      fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
              if (response.success) {
                setShowEnglishLevelUpdated(true);
                setTimeout(() => {
                    setShowEnglishLevelUpdated(false);
                }, "3000")
              }
          });
    }
  }, [candidateEnglishLevel, updateWithSameValue]);

  function notInArray(skillStr) {
    // eslint-disable-next-line
    for (const skillLevel of skillLevels) {
      if (skillLevel.includes(`${skillStr} -`)) {
        return false;
      }
    }

    return true;
  }

  function getSkillObject(skillNameString) {  // eslint-disable-line
    const skillsStr = JSON.parse($(".skillsBox").attr("data-skills"));
    let data = null;
    // eslint-disable-next-line
    $.each(skillsStr, function (i, val) {
      if (skillNameString === val.name) {
        data = val;
      }
    });

    return data;
  }

  function getSkillAndLevel(skillString) {  // eslint-disable-line
    const skillsStr = JSON.parse($(".skillsBox").attr("data-skills"));
    const skill = skillString.substring(skillString.indexOf("-") - 1, 0).trim();
    const level = Number(skillString.substring(skillString.length, skillString.indexOf("-") + 1));
    let data = null;
    // eslint-disable-next-line
    $.each(skillsStr, function (i, val) {
      if (skill === val.name) {
        data = {
          "level": level,
          "minLevel": 1,
          "skill": { "id": val.id, "name": val.name }
        };
      }
    });

    return data;
  }

  function addSkill() {
    skillString = $("input[name=skill]").val();
    levelString = $("input[name=level]").val();
    
    if (skillString && levelString && notInArray(skillString)) {
      skillLevelString = `${skillString} - ${levelString}`;
      skillLevelStrings = [...skillLevels];
      skillLevelStrings.push(skillLevelString);
      setSkillLevels(skillLevelStrings);
      changeskillPlaceholder(1);

      const skillArray = [];
      if ($(".skillsBox .react-tag-input__tag__content:visible").length) {
        // eslint-disable-next-line
        $.each($(".skillsBox .react-tag-input__tag__content:visible"), function (i, val) {
          const skill = getSkillAndLevel($(val).text());
          if (skill) {
            skillArray.push(skill);
          }
        });
      }

      const skillObject = getSkillObject(skillString);
      if (skillObject) {
        skillArray.push({
          "level": Number(levelString),
          "minLevel": 1,
          "skill": { "id": skillObject.id, "name": skillObject.name }
        });
      }

      if (skillArray.length) {
        setSelectedSkills(skillArray)
      }
    }

    // If skill is in array, replace it with the new one
    if (skillString && levelString && !notInArray(skillString)) {

      skillLevelString = `${skillString} - ${levelString}`;
      skillLevelStrings = [...skillLevels];

      // Find skillLevelString in skillLevelStrings that starts with same skillString before slash
      // Replace that skillLevelString with new skillLevelString
      // eslint-disable-next-line
      $.each(skillLevelStrings, function (i, val) {
        if (val.substring(0, val.indexOf("-") - 1).trim() === skillString) {
          skillLevelStrings[i] = skillLevelString;
        }
      });

      setSkillLevels(skillLevelStrings);

      changeskillPlaceholder(1);

      const skillArray = [];
      // eslint-disable-next-line
      $.each($(".skillsBox .react-tag-input__tag__content:visible"), function (i, val) {
        const skill = getSkillAndLevel($(val).text());
        if (skill) {
          skillArray.push(skill);
        }
      });

      const skillObject = getSkillObject(skillString);

      // find skill with same id in skillArray and replace it with skillObject
      // eslint-disable-next-line
      $.each(skillArray, function (i, val) {
        if (val.skill.id === skillObject.id) {
          skillArray[i] = {
            "level": Number(levelString),
            "minLevel": 1,
            "skill": { "id": skillObject.id, "name": skillObject.name }
          };
        }
      });

      if (skillArray.length) {
        setSelectedSkills(skillArray)
      }
    }
  }

  function removeTag() {
    if ($(".skillsBox .react-tag-input__tag__content:visible").length) {
      const skillArray = [];
      // eslint-disable-next-line
      $.each($(".skillsBox .react-tag-input__tag__content:visible"), function (i, val) {
        const skill = getSkillAndLevel($(val).text());
        if (skill) {
          skillArray.push(skill);
        }
        if (skillArray.length) {
          setSelectedSkills(skillArray)
        }
      });
    }
  }

  function uploadResume() {
    if (user) {
      const resumeField = $("input[name=cv]").prop('files')[0];
      const formPostData = new FormData();
      // eslint-disable-next-line no-useless-concat
      const postToken = `Token ${token}`;

      formPostData.append('cv', resumeField);

      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateId}/teclaUploadCVCandidate/`;
      const normalizedName = "cv.pdf"

      const requestMetadata = {
        method: 'PATCH',
        headers: {
          "Content-Disposition": `attachment; filename=${normalizedName}`,
          "Authorization": postToken,
          "content-type": "multipart/form-data;"
        },
        body: formPostData
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {

          if (response.success && response.data && response.data.cv) {
            CandidateForEdit.cv = response.data.cv;
            ReactSession.set("CandidateForEdit", CandidateForEdit);
          }
        })
        .catch(error => console.error('There was an error!', error))
    }
  }

  // eslint-disable-next-line
  $(document).unbind().on("click", ".skillsBox .react-tag-input__tag__remove", function () {
    tagtext = $(this).prev().text();
    skillLevelStrings = [...skillLevels];
    if (skillLevelStrings.length && skillLevelStrings.indexOf(tagtext) >= 0) {
      skillLevelStrings.splice(skillLevelStrings.indexOf(tagtext), 1);
      setSkillLevels(skillLevelStrings);
      if (!skillLevelStrings.length) {
        changeskillPlaceholder(0);
      }
    }
  });

  const verifySkillInputs = () => {
    setActiveStep(prevStep => prevStep+1)
  }

  /* Mui hook to read screen size - We use it for small screen rendering */
  const isSmallScreen = useMediaQuery('(max-width: 1201px)')

  return (
    <SuiBox>
      { showEnglishLevelUpdated ? (<SuiAlert color="info">English Level Updated.</SuiAlert>) : null }
      <SuiBox width="100%" textAlign="center" mb={4}>
        <SuiTypography variant="h5" fontWeight="regular">Tell us a bit more about your skills</SuiTypography>
      </SuiBox>

      <SuiBox mt={2}>
        <SuiBox ml={1} mb={0.5}>
          <SuiTypography variant="button">Upload your Resume / CV (optional)</SuiTypography>
        </SuiBox>
        <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={uploadResume} />
      </SuiBox>

      <SuiBox mt={2}>
        <SuiBox ml={1} mb={0.5}>
          <SuiTypography variant="button">Add your skills and knowledge levels (optional)</SuiTypography>
        </SuiBox>
        <Grid container xs={12} md={12} spacing={1}>

          <Grid item xs={4}>
            <SuiSelect
              placeholder="Skill"
              options={skills}
              name="skill"
              smallFont={isSmallScreen}
            />
          </Grid>

          <Grid item xs={4}>
            <SuiSelect
              placeholder="Years"
              options={years}
              name="level"
              smallFont={isSmallScreen}
            />
          </Grid>

          <Grid item xs={4} ><SuiButton color="info" onClick={addSkill} fullWidth>Add</SuiButton></Grid>

          <Grid item xs={12} md={12} className="skillsBox">
            <SuiTagInput
              placeholder="Skills."
              tags={skillLevels}
              onChange={removeTag}
            />
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox>
        <SuiBox mt={2} className="interviewInEnglishBox">
          <SuiBox ml={1} mb={0.5}>
            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Are you able to hold an interview in English?</SuiTypography>
          </SuiBox>
          <SuiSelect
            placeholder={interviewPlaceHolder}
            options={defaultOptions}
            name="interviewInEnglish"
            onChange={e => (setSelectedInterviewInEnglish(e.value))}
          />
        </SuiBox>
      </SuiBox>

      <SuiBox>
        <SuiBox mt={2} className="englishLevelBox">
          <SuiBox ml={1} mb={0.5}>
            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>
              On a scale of 1 to 10: What is your level of spoken English? 
              { CandidateForEdit?.englishLevelValidated ? (
                <Tooltip title={`Validated by ${CandidateForEdit.englishValidator.user.first_name} ${CandidateForEdit.englishValidator.user.last_name}`} placement="top">
                  <CheckBoxIcon fontSize="small" color="success" sx={{ verticalAlign: "middle" }} />
                </Tooltip>
              ) : null }
            </SuiTypography>
          </SuiBox>
          <Grid container xs={12} md={12}>
            <Grid item xs={7} sm={7}>
              <SuiInput name="englishLevelDisabled" type="text" disabled value={candidateEnglishLevel} />
            </Grid>
            <Grid item xs={5} sm={5} pl={2}>
              <ValidateEnglishLevelModal candidateEnglishLevel={candidateEnglishLevel} setCandidateEnglishLevel={setCandidateEnglishLevel} setUpdateWithSameValue={setUpdateWithSameValue}/>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>

      <SuiBox>
        <SuiBox mt={2} className="canWorkRemotelyBox">
          <SuiBox ml={1} mb={0.5}>
            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>
              Will you be able to work with your own equipment?
            </SuiTypography>
          </SuiBox>
          <SuiSelect
            placeholder={canWorkPlaceHolder}
            options={defaultOptions}
            name="canWorkRemotely"
            onChange={e => (setSelectedCanWorkRemotely(e.value))}
          />
        </SuiBox>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" onClick={() => setActiveStep(prevStep => prevStep-1)} isSubmitting className="changeStep">Back</SuiButton>
        <SuiButton variant="gradient" color="info" onClick={() => verifySkillInputs()} className="changeStep nextStep" isSubmitting>Next</SuiButton>
      </SuiBox>

    </SuiBox>
  );
}

// typechecking props for Skills
Skills.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setSelectedSkills: PropTypes.func.isRequired,
  setSelectedInterviewInEnglish: PropTypes.func.isRequired,
  setSelectedEnglishLevel: PropTypes.func.isRequired,
  setSelectedCanWorkRemotely: PropTypes.func.isRequired,
  englishLevelValidated: PropTypes.bool.isRequired,
  setEnglishLevelValidated: PropTypes.func.isRequired,
};

export default Skills;