/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react"
import { ReactSession } from 'react-client-session'
import Swal from "sweetalert2"
// formik components
import { Formik, Form } from "formik"
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
// import Stepper from "@mui/material/Stepper"
// import Step from "@mui/material/Step"
// import StepLabel from "@mui/material/StepLabel"
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import SuiAlert from "components/SuiAlert"
// Soft UI Dashboard PRO React example components
import BaseLayout from "layouts/pages/account/components/BaseLayout"
// Wizard page components
import OnePage from "layouts/applications/newjob/components/OnePage"
// import Basic from "layouts/applications/newjob/components/Basic"
// import Description from "layouts/applications/newjob/components/Description"
// import Experience from "layouts/applications/newjob/components/Experience"
// import Questions from "layouts/applications/newjob/components/Questions"
// import Salary from "layouts/applications/newjob/components/Salary"
import validations from "layouts/pages/users/new-user/schemas/validations"
import form from "layouts/pages/users/new-user/schemas/form"
import initialValues from "layouts/pages/users/new-user/schemas/initialValues"
import { stripHtml } from "utils/commonFunctions"


function Wizard() {
 // Validation
 ReactSession.setStoreType("localStorage");
 const company = ReactSession.get("company");
 const token = ReactSession.get("token");


 const searchParams = new URLSearchParams(window.location.search);
 const jobId = searchParams.get('id');
 let lastApply = ReactSession.get("lastApply");
 if (!lastApply) {
   lastApply = {};
 }
 let skillArray = [];


 useEffect(() => {
   document.title = `New Job | TECLA`;
   if (jobId) {
     document.title = `Edit Job | TECLA`;
   }
 }, []);




 const [activeStep, setActiveStep] = useState(0);
 const [firstView, setFirstView] = useState(true);
 const [showSpinner, setShowSpinner] = useState(false)
 const [showBeforeUnload, setShowBeforeUnload] = useState(true)


 /* CONTRIES */
 const [selectedCountryTags, setSelectedCountryTags] = useState(["Anywhere in LATAM", "United States"]);
 const [fullCountriesList, setFullCountriesList] = useState([]);


 /* COMMITMENT */
 const [selectedRoleCommitment, setSelectedRoleCommitment] = useState(lastApply?.commitment)
 const [commitmentOptions, setCommitmentOptions] = useState([])

 const [showMaxSalary, setShowMaxSalary] = useState(true)

 const fetchInterests = async () => { // eslint-disable-line
  try {
    const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
    const data = await res.json()
    const interestsObj = { date: new Date(), data: data.data }
    sessionStorage.setItem('interests', JSON.stringify(interestsObj))
    return interestsObj

  } catch (err) { console.error('fetchInterests error', err) }
}

 const getCommitmentOpts = async () => {
  let interests = sessionStorage.getItem('interests')
  if (interests) interests = JSON.parse(interests)
  else interests = await fetchInterests()

  const optionsArr = []
  interests?.data?.commitment?.forEach(opt => optionsArr?.push({ value: opt?.id, label: opt?.name }))
  setCommitmentOptions(optionsArr)
}


 useEffect(() => {
   if (commitmentOptions.length === 0) getCommitmentOpts()
 }, [])


 const getCountriesList = () => {
   fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/jobCountries/`)
     .then(async response => {
       const data = await response.json()
       setFullCountriesList(data?.data?.countries)
     })
     .catch(error => console.error('There was an error!', error))
 }


 useEffect(() => {
   if (fullCountriesList.length === 0) getCountriesList()
 }, [])


 /* ======== */


 // Benefits
 const [selectedRoleBenefitsIds, setSelectedRoleBenefitsIds] = useState([])


 function getStepContent(formData, ifFirstView) {
   return  <OnePage
     formData={formData}
     firstView={ifFirstView}
     setSelectedRoleCommitment={setSelectedRoleCommitment}
     commitmentOptions={commitmentOptions}
     selectedRoleBenefitsIds={selectedRoleBenefitsIds}
     setSelectedRoleBenefitsIds={setSelectedRoleBenefitsIds}
     skillLevels={selectedCountryTags}
     setSkillLevels={setSelectedCountryTags}
     fullCountriesList={fullCountriesList}
     selectedRoleCommitment={selectedRoleCommitment}
     showMaxSalary={showMaxSalary}
     setShowMaxSalary={setShowMaxSalary}
   />;
 }


 // jobApply,
 // isJobQuestion
 // const steps = ["Basic Info", "Description", "Experience", "Questions", "Salary & Locations"];
 const { formId, formField } = form;
 const currentValidation = validations[activeStep];
 let validPost = true;


 // const stepsLength = steps.length - 1;
 const isLastStep = activeStep === 0;
 // let timerid = "";


 const handleNext = () => setActiveStep(activeStep + 1); // eslint-disable-line
 const handleBack = () => {
   setFirstView(false);
   setActiveStep(activeStep - 1);
 };


 function showError(message) {
   let text = message;
   if (!text) {
     text = "Please fill all the fields in this section before continuing";
   }
   let timerErrorid = "";
   $(".alertErrorBox div div").text(text);
   $(".alertErrorBox").show();
   $('html, body').animate({
     scrollTop: $(".alertErrorBox").offset().top
   }, 100);
   if (timerErrorid) {
     clearTimeout(timerErrorid);
   }
   timerErrorid = setTimeout(() => {
     $(".alertErrorBox").hide();
   }, 4000);
 }


 function showSuccess(isUpdate) {
   let swalTitle = "Job created successfully.";
   let swalText = "It will be reviewed by our team shortly (within 24 business hours). We will notify you via e-mail once it is approved.";
   if (isUpdate) {
     swalTitle = "Job updated successfully."
     swalText = "";
   }
   Swal.fire({
     icon: "success",
     title: swalTitle,
     text: swalText,
   });
   let timerSuccessid = "";
   if (timerSuccessid) {
     clearTimeout(timerSuccessid);
   }
   timerSuccessid = setTimeout(() => {
     window.location.replace(`/c/dashboard`);
   }, 5000);
 }


 function getSkillAndLevel(skillString) {
   const skills = JSON.parse($(".requiredBox").attr("data-skills"));
   const skill = skillString.substring(skillString.indexOf("-") - 1, 0).trim();
   const level = Number(skillString.substring(skillString.indexOf(" - ") + 3, skillString.length));
   let data = null;
   // eslint-disable-next-line
   $.each(skills, function (i, val) {
     if (skill === val.name) {
       data = { "level": level, "skill": { "id": val.id, "name": val.name } };
     }
   });


   return data;
 }




 function updateUser() {
   let errorMessage = "";
   if ($("form .MuiTypography-caption:visible").text().length === 0 && validPost) {
     validPost = false;
     let handleNextIsValid = true;
     lastApply = { ...ReactSession.get("lastApply") };
     if (!lastApply) {
       lastApply = {};
     }
     // if (activeStep === 0) {
     lastApply.name = $("input[name=name]").val();
     lastApply.subtitle = $("input[name=subtitle]").val();
     lastApply.hiringMethod = $("input[name=hiringMethod]").val();
     lastApply.location = $("input[name=location]").val();
     lastApply.seniority = $("input[name=seniority]").val();
     lastApply.category = $("input[name=category]").val();
     if (selectedRoleCommitment) lastApply.commitment = {id: selectedRoleCommitment}


     lastApply.benefits = selectedRoleBenefitsIds


     if (!lastApply.name || !lastApply.location || !lastApply.seniority || !lastApply.category || !lastApply.commitment) {
       handleNextIsValid = false;
     }
     // } else if (activeStep === 1) {
     lastApply.companyDescription = $("[data-name=description]").attr("data-bio");
     if (!lastApply.companyDescription && company && company.description) {
       lastApply.companyDescription = company.description;
     }
     lastApply.description = $("[data-name=description]").attr("data-html");
      if (!stripHtml(lastApply.description)) {
        handleNextIsValid = false;
      }
     if (!lastApply.companyDescription || lastApply.description === "<p><br></p><p><br></p><p><br></p>") {
       handleNextIsValid = false;
     }
     if (lastApply.description.length >= 15000) {
       handleNextIsValid = false;
       errorMessage = "Your description cannot have more than 15000 characters";
     }
     if (lastApply.description.length > 5000 || lastApply.companyDescription.length > 5000) {
       handleNextIsValid = false;
       errorMessage = "Max length for descriptions is 5000 characters";
     }
     // } else if (activeStep === 2) {
     if ($(".requiredBox .react-tag-input__tag__content:visible").length) {
       skillArray = [];
       // eslint-disable-next-line
       $.each($(".requiredBox .react-tag-input__tag__content:visible"), function (i, val) {
         const skill = getSkillAndLevel($(val).text());
         if (skill) {
           skillArray.push(skill);
         }
         if (skillArray.length) {
           lastApply.requiredSkills = skillArray;
         }
       });
     }


     if (!lastApply.requiredSkills || !lastApply.requiredSkills.length) {
       handleNextIsValid = false;
     }


     if ($(".optionalBox .react-tag-input__tag__content:visible").length) {
       skillArray = [];
       // eslint-disable-next-line
       $.each($(".optionalBox .react-tag-input__tag__content:visible"), function (i, val) {
         const skill = getSkillAndLevel($(val).text());
         if (skill) {
           skillArray.push(skill);
         }
         if (skillArray.length) {
           lastApply.optionalSkills = skillArray;
         }
       });
      } else {
        lastApply.optionalSkills = [];
      }
     // } else if (activeStep === 3) {
       lastApply.questions = [];
     // eslint-disable-next-line
     $.each($(".questionBox input:visible"), function (i, val) {
       if ($(val).val()) {
         lastApply.questions.push({ "title": $(val).val() });
       }
     });
     // } else if (activeStep === 4) {
      
     /* COUNTRIES */


     // Si se selecciona "Anywhere in Latam" y "United States", se envía array vacío
     // Si se selecciona solo "Anywhere in Latam", se envía lista de países de Latam
     // Si no, se envía solo el registro de los países seleccionados
    
     if (selectedCountryTags.length === 2 && selectedCountryTags.includes('Anywhere in LATAM') && selectedCountryTags.includes('United States')) {
       lastApply.countries = []
     }
     else if (selectedCountryTags.length === 1 && selectedCountryTags.includes('Anywhere in LATAM')) {
       const selectedCountriesArr = []


       fullCountriesList.forEach(country => {
         if (country.name !== 'United States') selectedCountriesArr.push({ name: country.name, id: country.id })
       })


       lastApply.countries = selectedCountriesArr
     }
     else {
       const selectedCountriesArr = []


       fullCountriesList.forEach(country => {
         if (selectedCountryTags.includes(country.name)) selectedCountriesArr.push({ name: country.name, id: country.id })
       })


       lastApply.countries = selectedCountriesArr
     }
    
     if (!selectedCountryTags.includes('Anywhere in LATAM') && (!lastApply.countries || !lastApply.countries.length)) {
       handleNextIsValid = false;
     }
     /* ============= */


     // Stores wether the job has an hourly rate or not. Freelance positions have hourly rate.
     const hasHourlyRate = selectedRoleCommitment === 3


     lastApply.minSalary = $(".salaryRange").attr("data-min");
     lastApply.maxSalary = $(".salaryRange").attr("data-max");
     if (!lastApply.minSalary || lastApply.minSalary === "0") {
       lastApply.minSalary = hasHourlyRate ? 30 : 5000;
     }
     if (!lastApply.maxSalary || lastApply.maxSalary === "20000") {
       lastApply.maxSalary = hasHourlyRate ? 50 : 10000;
     }

    // If not show max salary, send min salary and max salary is top of the range 
    if (!showMaxSalary) lastApply.maxSalary = hasHourlyRate ? 100 : 20000;
    // }

    lastApply.includeMaxSalary = showMaxSalary;

     // ReactSession.set("lastApply", lastApply);

     validPost = true;
     let method = 'POST';
    if (handleNextIsValid) {
       setShowSpinner(true);
       let isUpdate = false;
       let recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/companies/companyCreateJob/`;
       if (jobId) {
         recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/companies/${jobId}/companyUpdateJob/`;
         method = "PATCH";
         isUpdate = true;
       }
       const requestMetadata = {
         method: method,
         headers: {
           'Content-Type': 'application/json',
           'Authorization': `Token ${token}`
         },
         body: JSON.stringify(lastApply)
       };
       fetch(recipeUrl, requestMetadata)
         .then(res => res.json())
         .then(response => {
           validPost = true;
           if (response.success) {
             ReactSession.set("lastApply", {});
             showSuccess(isUpdate);
             setShowBeforeUnload(false);
           }
         });
     } else if (!handleNextIsValid) {
        $.each($(".validateField"), function () { // eslint-disable-line
          if ($(this).find(".ql-editor").length) { // eslint-disable-line
            if (!$(this).find(".ql-editor").text()) { // eslint-disable-line
              $(this).addClass("error");
            }
          } else if ($(this).find("input[type=text]").length) {
            if ((!$(this).find("input[type=hidden]").length || !$(this).find("input[type=hidden]").val()) && 
                (!$(this).find("input[type=text]").val() || !$(this).find("input[type=text]").val().length)) { // eslint-disable-line
              $(this).addClass("error");
            }
          } else if($(this).find(".react-tag-input").length) {
            if (!$(this).find(".react-tag-input > div").length) { // eslint-disable-line
              $(this).find(".react-tag-input").addClass("error");
            }
          }
        })
        showError(errorMessage)
     }
   }
 }


 // eslint-disable-next-line
 window.onbeforeunload = function(e) {
   if (showBeforeUnload && window.location.href.includes("create-a-job")) {
     return "Changes you made may not be saved.";
   }
 };


 return (
   <BaseLayout isCompany>
     <SuiBox pb={8}>
       <Grid container justifyContent="center">
         <Grid item xs={12} lg={8}>
           <SuiBox mt={6} mb={1} textAlign="center">
             <SuiBox mb={1}>
               <SuiTypography variant="h3" fontWeight="bold">
                 Create a New Job
               </SuiTypography>
             </SuiBox>
             <SuiTypography variant="h5" fontWeight="regular" color="secondary">
               Please fill out the following details to continue
             </SuiTypography>
           </SuiBox>

           <SuiBox className="alertErrorBox" display="none">
             <SuiAlert color="error" className="alertError">Please fill all the fields in this section before continuing.</SuiAlert>
           </SuiBox>
           <SuiBox className="alertSuccessBox" display="none">
             <SuiAlert color="info" className="alertSuccess">Application submitted!</SuiAlert>
           </SuiBox>
           <Card>
             <SuiBox p={2}>
               <SuiBox>
                 <Formik
                   initialValues={initialValues}
                   validationSchema={currentValidation}
                 >
                   {({ values, errors, touched, issubmitting }) => (
                     <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">
                       <SuiBox className="moonBox">
                         {getStepContent({
                           values,
                           touched,
                           formField,
                           errors,
                         }, firstView)}
                         <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                           {activeStep === 0 ? (
                             <SuiBox />
                           ) : (
                             <SuiButton variant="outlined" color="info" onClick={handleBack} issubmitting={issubmitting} className="changeStep">
                               back
                             </SuiButton>
                           )}
                           {showSpinner ? (
                             <SuiBox mt={3} display="flex" justifyContent="center" alignItems="center">
                               <CircularProgress />
                             </SuiBox>)
                             : (
                               <SuiButton
                                 variant="gradient"
                                 color="info"
                                 onClick={updateUser}
                                 className="changeStep nextStep"
                               >
                                 {isLastStep ? "submit" : "next"}
                               </SuiButton>
                             )}
                         </SuiBox>
                       </SuiBox>
                     </Form>
                   )}
                 </Formik>
               </SuiBox>
             </SuiBox>
           </Card>
         </Grid>
       </Grid>
     </SuiBox>
     {/* <Footer /> */}
   </BaseLayout>
 );
}


export default Wizard;


