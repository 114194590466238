/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router components
// prop-types is library for typechecking of props
import PropTypes from "prop-types"
import { sliceString } from "utils/commonFunctions"
// @mui material components
import Card from "@mui/material/Card"
import StarIcon from '@mui/icons-material/Star'
import CloseIcon from '@mui/icons-material/Close';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiAvatar from "components/SuiAvatar";
import folderIcon from "layouts/recruiterFolders/folderCard/folderIcon/folderIcon.png"

function FolderCard({ folderName, folderId, isFavorite, folderUrl, closeFile }) {
  const url = `${process.env.REACT_APP_API_ROUTE}${folderUrl}`;

  return (
    <Card position="relative" sx={{ minWidth: "175px !important", cursor: "pointer" }} className="closeFileBox">
      <SuiBox px={1.5} py={2} minHeight="10rem" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" >
        <a href={url} target="_blank">
          <SuiAvatar src={folderIcon} alt="Avatar" variant="rounded" size="xxl" sx={{ margin: "0 auto" }} />

          {isFavorite && <StarIcon fontSize="small" sx={{ position: 'absolute', top: 15, right: 15 }} />}
          <SuiBox mt={1} mb={2} lineHeight={0} sx={{ maxWidth: "100%" }}>
            <SuiTypography variant="button" fontWeight="regular" color="text">{sliceString(folderName, 20)}</SuiTypography>
          </SuiBox>
        </a>
      </SuiBox>
      { closeFile ?
        <CloseIcon size="large" className="closeFile" onClick={() => closeFile(folderId)}/>
      : null }
    </Card >
  )
}


// Typechecking props for the FolderCard
FolderCard.propTypes = {
  folderName: PropTypes.string.isRequired,
  folderId: PropTypes.number.isRequired,
  isFavorite: PropTypes.bool,
  folderUrl: PropTypes.string,
  closeFile: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

FolderCard.defaultProps = {
  isFavorite: false,
  folderUrl: "",
  closeFile: null
}

export default FolderCard
