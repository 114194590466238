/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/// @mui material components
import Grid from "@mui/material/Grid"
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// prop-type is a library for typechecking of props
import PropTypes from "prop-types"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiEditor from "components/SuiEditor"
import SuiButton from "components/SuiButton"
import SuiAlert from "components/SuiAlert";


function Highlights({ formData, setActiveStep, activeStep, highlights, setHighlights, whyWorkHere, setWhyWorkHere, culture, setCulture, perks, setPerks }) {

  /* eslint-disable-next-line */
  const { formField, values, errors, touched } = formData
  const [showErrorField, setShowErrorField] = useState(false)

  const verifyHighlightsInputs = () => {
    if (highlights.length <= 500 && whyWorkHere.length <= 500 && culture.length <= 500 && perks.length <= 500) {
      setActiveStep(activeStep+1)
    } else {
      setShowErrorField(true)
      // eslint-disable-next-line
      $('html, body').animate({ scrollTop: $(".alertUrlErrorBox").offset().top }, 100);
      setTimeout(() => setShowErrorField(false), 3000)
    }
  }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={1} mb={4}>
        <SuiBox mb={1}>
          <SuiTypography className="alertUrlErrorBox" variant="h5" fontWeight="regular">Highlights</SuiTypography>
        </SuiBox>
      </SuiBox>

      {showErrorField && <SuiAlert color="secondary" className="alertError">Error: Input text exceeds 500 characters limit. Please reduce the text length and try again.</SuiAlert>}

      <SuiBox mt={2}>
        <Grid container spacing={3}>

            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography variant="button">Company Highlights</SuiTypography>
              </SuiBox>
              <SuiEditor
                maxLength={500}
                defaultValue={highlights}
                name="highlights"
                onChange={setHighlights}
                placeholder="Share some highlights about your company. I.e. featured in Forbes, etc. (optional - Max 500 characters)"
                error={errors.highlights && touched.highlights}
                success={highlights.length > 0 && highlights.length <= 500 && !errors.highlights}
              />
            </Grid>
            <SuiTypography
                mt={1}
                variant="subtitle2"
                color={highlights.length > 500 ? "error" : "auto"}
                fontSize=".9rem"
                align="right"
                sx={{ display: "block", width: "100%" }}
            >
                {highlights.length || "0"}/500 chars.
            </SuiTypography>


            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography variant="button">Why Work Here</SuiTypography>
              </SuiBox>
              <SuiEditor
                maxLength={500}
                defaultValue={whyWorkHere}
                name="whyWorkHere"
                onChange={setWhyWorkHere}
                placeholder="Tell us, why is your company an awesome place to work at? (optional - Max 500 chars)"
                error={errors.whyWorkHere && touched.whyWorkHere}
                success={whyWorkHere.length > 0 && whyWorkHere.length <= 500 && !errors.whyWorkHere}
              />
            </Grid>
            <SuiTypography
                mt={1}
                variant="subtitle2"
                color={whyWorkHere.length > 500 ? "error" : "auto"}
                fontSize=".9rem"
                align="right"
                sx={{ display: "block", width: "100%" }}
            >
                {whyWorkHere.length || "0"}/500 chars.
            </SuiTypography>

            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography variant="button">Culture</SuiTypography>
              </SuiBox>
              <SuiEditor
                maxLength={500}
                defaultValue={culture}
                name="culture"
                onChange={setCulture}
                placeholder="In a few sentences, please describe the culture of the company (optional - Max 500 chars)"
                error={errors.culture && touched.culture}
                success={culture.length > 0  && culture.length <= 500 && !errors.culture}
              />
            </Grid>
            <SuiTypography
                mt={1}
                variant="subtitle2"
                color={culture.length > 500 ? "error" : "auto"}
                fontSize=".9rem"
                align="right"
                sx={{ display: "block", width: "100%" }}
            >
                {culture.length || "0"}/500 chars.
            </SuiTypography>

            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography variant="button">Perks</SuiTypography>
              </SuiBox>
              <SuiEditor
                maxLength={500}
                defaultValue={perks}
                name="perks"
                onChange={setPerks}
                placeholder="In a few sentences, please describe the perks of the company (optional - Max 500 chars)"
                error={errors.perks && touched.perks}
                success={perks.length > 0 && perks.length <= 500 && !errors.perks}
              />
            </Grid>
            <SuiTypography
                mt={1}
                variant="subtitle2"
                color={perks.length > 500 ? "error" : "auto"}
                fontSize=".9rem"
                align="right"
                sx={{ display: "block", width: "100%" }}
            >
                {perks.length || "0"}/500 chars.
            </SuiTypography>

        </Grid>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" onClick={() => setActiveStep(activeStep-1)} isSubmitting className="changeStep">Back</SuiButton>
        <SuiButton variant="gradient" color="info" onClick={() => verifyHighlightsInputs()} className="changeStep nextStep" isSubmitting>Next</SuiButton>
      </SuiBox>

    </SuiBox>
  )
}

// typechecking props for UserSignUp
Highlights.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.isRequired,
  activeStep: PropTypes.isRequired,
  highlights: PropTypes.isRequired,
  setHighlights: PropTypes.isRequired,
  whyWorkHere: PropTypes.isRequired,
  setWhyWorkHere: PropTypes.isRequired,
  culture: PropTypes.isRequired,
  setCulture: PropTypes.isRequired,
  perks: PropTypes.isRequired,
  setPerks: PropTypes.isRequired
}

export default Highlights
