/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
// @mui material components
import Card from '@mui/material/Card';
// import AppBar from '@mui/material/AppBar';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import breakpoints from 'assets/theme/base/breakpoints';
// Videos page components
import BaseLayout from 'layouts/pages/account/components/BaseLayout';
import StudentDashboardLayout from 'examples/LayoutContainers/StudentDashboardLayout';
import Vimeo from '@u-wave/react-vimeo'
import { CardActionArea } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

function Videos() {

  const student = ReactSession.get("student")

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [videos, setVideos] = useState([]);
  const [videoDisplayed, setVideoDisplayed] = useState(null);

  // const [selectedPlaylist, setSelectedPlaylist] = useState(0);

  useEffect(() => {
    document.title = `Videos | TECLA`;
    if (student) {
      document.title = `Events | Boost`;
    }
  }, []);

  useEffect(() => {
      function handleTabsOrientation() {
        return window.innerWidth < breakpoints.values.md
          ? setTabsOrientation("vertical")
          : setTabsOrientation("horizontal");
      }

      window.addEventListener("resize", handleTabsOrientation);

      handleTabsOrientation();

      return () => window.removeEventListener("resize", handleTabsOrientation);

    }, [tabsOrientation]);

  /* Mui hook to read screen size - We use it for small screen rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_ROUTE}/api/academy/getB2BVideos/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        if (res.success && res.data && res.data.length) {
          setVideos(res.data);
          setVideoDisplayed(res.data[0])
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  // const handleSetSelectedPlaylist = (event, newCamera) => setSelectedPlaylist(newCamera)

  const setLayout = children => {
    if (student) return <StudentDashboardLayout>{children}</StudentDashboardLayout>
    return <BaseLayout>{children}</BaseLayout>
  }

  const formatTitle = str => {
    if (str.length < 45) return str
    return `${str.substring(0, 45)} ...`
  }

  return (
    <>
      {setLayout(
        <>
          <SuiBox my={3}>
            <Card>
                <SuiBox mb={0} ml={3} p={2} display="flex" flexDirection={isMobile ? "column" : "row"} alignItems="center">
                    <SuiTypography
                      variant="h3"
                      fontWeight="bold"
                      mr={isMobile ? 0 : 3}
                    >
                      Videos
                    </SuiTypography>
                </SuiBox>
    
                <Card sx={{ height: "80vh", display: "block" }}>
                    <SuiBox className="vimeos" p={2}>
                      { videoDisplayed ? (
                        <Vimeo
                            video={videoDisplayed.url}
                            autoplay
                            responsive 
                          />
                      ) : null }
                    </SuiBox>
                    <SuiBox
                      display="flex"
                      flexDirection="column"
                      p={2}
                      sx={{
                        maxHeight: isMobile ? 450 : "97%",
                        overflowY: "scroll",
                        width: isMobile ? "100%" : "300px",
                        mx: "auto",
                        mt: isMobile ? 3 : 0,
                        float: "right"
                      }}>
                      {videos.map(vid => (

                        <Card
                          key={vid.pk}
                          sx={{
                            mb: 2,
                            cursor: "pointer",
                            height: isMobile ? 150 : 280,
                            width: "100%",
                            border: videoDisplayed && videoDisplayed?.pk === vid?.pk ? "2px solid #00bcd4" : "2px solid transparent"
                          }}
                          onClick={() => setVideoDisplayed(vid)}
                        >
                          <CardActionArea sx={{
                            borderRadius: "5%",
                            display: "flex",
                            flexDirection: isMobile ? "row" : "column",
                            justifyContent: "center",
                            aliginItems: "center"
                          }}>
                            <CardMedia
                              component="img"
                              image={`${process.env.REACT_APP_API_ROUTE}${vid.image}`}
                              alt="Video thumbnail"
                              sx={{ width: isMobile ? "45%" : { md: "85%", lg: "90%" }, my: isMobile ? "auto" : 1, mx: 0 }}
                            />
                            <CardContent
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                aliginItems: "center",
                                width: isMobile ? "50%" : "auto",
                              }}
                            >
                              <SuiTypography variant={isMobile ? "Caption" : "h6"}>{isMobile ? formatTitle(vid?.title) : vid?.title}</SuiTypography>
                            </CardContent>
                          </CardActionArea>
                        </Card>

                      ))}
                    </SuiBox>
                </Card >
            </Card>
          </SuiBox>
        </ >
      )}
    </>
   
  );
}

export default Videos;
