/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress'
// sui components
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";

function Questions({ formData, setActiveStep, selectedPosition, selectedQuestions, setSelectedQuestions, createApplication, isSubmitting, isRedirecting }) { // eslint-disable-line

  const applicationQuestions = selectedPosition.questions

  const [showEmptyFieldsMsg, setShowEmptyFieldsMsg] = useState(false)
  
  let getQuestions = true;
  let questions = [];

  const updateSelectedQuestions = (questionId, answer) => {  
    const quest = selectedQuestions
    quest.filter(q => q.id === questionId)[0].answer = answer

    setSelectedQuestions(quest)
  }

  function questionTypehtml (question) {
    let restHtml = "";
    if (question.type === "line2") {
      if (question.default) restHtml = <SuiInput onChange={e => updateSelectedQuestions(question.id, e.target.value)} />
      else restHtml = <SuiInput onChange={e => updateSelectedQuestions(question.id, e.target.value)} />

    } else if (question.type === "choice") {
      if (question.default) restHtml = <SuiSelect options={question.choices} onChange={e => updateSelectedQuestions(question.id, e.value)} />
      else restHtml = <SuiSelect options={question.choices} name={`select${question.id}`} onChange={e => updateSelectedQuestions(question.id, e.value)} />

    } else {
      // eslint-disable-next-line
      if (question.default)restHtml = <SuiInput multiline rows={3} onChange={e => updateSelectedQuestions(question.id, e.target.value)} />
      else restHtml = <SuiInput multiline rows={3}  onChange={e => updateSelectedQuestions(question.id, e.target.value)} />
    }

    return restHtml;
  }

  if (getQuestions) {
    getQuestions = false;
    questions = [];
    // eslint-disable-next-line
    for (const additionalQuestion of applicationQuestions) {

      const question = {
        title: additionalQuestion.title,
        id: additionalQuestion.id,
        type: additionalQuestion.type,
        previousAnswer: null,
      }
      const questionChoices = [];
      // eslint-disable-next-line
      for (const choice of additionalQuestion.choice.split("|")) { questionChoices.push({ value: choice, label: choice }) }
      question.choices = questionChoices;

      question.input = questionTypehtml(question);
      questions.push(question);
    }
  }

  let timerid = "";
  if (timerid) clearTimeout(timerid);
  timerid = setTimeout(() =>  { getQuestions = true }, 2000);

  const verifyQuestionsInputs = () => {
    let inputsValidated = true

    selectedQuestions.forEach(quest => { if (!quest.answer) inputsValidated = false })
  
    if (!inputsValidated) {
      setShowEmptyFieldsMsg(true)
      setTimeout(() => setShowEmptyFieldsMsg(false), 3000)
    }
    if (inputsValidated) createApplication()
  }

  return (
    <SuiBox>
      <SuiBox width="100%" textAlign="center" mb={4}>
        <SuiTypography variant="h5" fontWeight="regular">
          Please answer the following pre-screening questions for this application:
        </SuiTypography>
      </SuiBox>

      {showEmptyFieldsMsg && <SuiAlert color="secondary" className="alertError">Please fill all the required fields in this section before continuing.</SuiAlert>}

      <SuiBox mt={2}>
        <Grid container spacing={2} className="webLinks">
          {questions.map(question => (
            <Grid item xs={12} md={12}>
              <SuiBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
                mb={2}
                className="questionSelect"
                data-id={question.id}
                key={question.id}
              >
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography variant="button">
                    {question.title}
                  </SuiTypography>
                </SuiBox>
                {question.input}
              </SuiBox>
            </Grid>
          ))}
        </Grid>
      </SuiBox>
            
      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" onClick={() => setActiveStep(prevState => prevState-1)} isSubmitting className="changeStep">Back</SuiButton>
        {isSubmitting ?
          <SuiBox display="flex" justifyContent="center" alignItems="center" mr={2.5}>
            <CircularProgress size={20} />
          </SuiBox >
        :
          <SuiButton variant="gradient" color="info" onClick={() => verifyQuestionsInputs()} className="changeStep nextStep" disabled={isRedirecting}>Create</SuiButton>
        }
      </SuiBox>

    </SuiBox>
  )
}

// typechecking props for Questions
Questions.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  selectedPosition: PropTypes.object.isRequired, // eslint-disable-line
  selectedQuestions: PropTypes.object.isRequired, // eslint-disable-line
  setSelectedQuestions: PropTypes.func.isRequired,
  createApplication: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isRedirecting: PropTypes.bool.isRequired,
}

export default Questions
