/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// Soft UI Dashboard PRO React components
import SuiBadge from "components/SuiBadge";
// ProductsList page components
import ProductCell from "layouts/pages/profile/applications-list/components/ProductCell";

// Badges
function applicationStatus(value) {
  let color = "secondary";
  if (["Intro Requested", "Interview"].includes(value)) {
    color = "info";
  } else if (["Declined", "Withdrawn"].includes(value)) {
    color = "error";
  } else if (["Hired"].includes(value)) {
    color = "success";
  }
  const statusToReturn = (<SuiBadge variant="contained" color={color} size="xs" badgeContent={value} container />);

  return statusToReturn;
}

export default {
  columns: [
    {
      Header: "Position",
      accessor: "position",
      width: "40%",
      Cell: ({ value: [name, jobId] }) => (
        <ProductCell name={name} jobId={jobId} />
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (applicationStatus(value)),
    },
    { Header: "Action", accessor: "action" },
  ],

  rows: [],
};
