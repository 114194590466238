/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAvatar from "components/SuiAvatar";
// MUI components
import Modal from '@mui/material/Modal';
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Icons
import leverIcon from "assets/images/LeverIcon.png"

function ActionCell({ candidate, fetchSourcingContent, sourcing }) {

	ReactSession.setStoreType("localStorage")
	const user = ReactSession.get("user")
	const token = ReactSession.get("token")
	const recruiter = ReactSession.get("recruiter")

	const apiUrl = process.env.REACT_APP_API_ROUTE;
	let candidateUrl = "";
	let cvUrl = "";
	let applicationUrl = "";
	let leverUrl = "";

	if (candidate && candidate.user) {
		candidateUrl = `/r/profile/${candidate.user.id}`;
		cvUrl = candidate.cv ? `${apiUrl}${candidate.cv}` : "";
		applicationUrl = `/r/applications/${candidate.user.id}`;
	}

	if (candidate && candidate.leverUrl) leverUrl = candidate.leverUrl;

	const [openModal, setOpenModal] = useState(false)
	const [showSpinner, setShowSpinner] = useState(false)
	const [showDoneIcon, setShowDoneIcon] = useState(false)

	const deleteCandidateFromFolder = () => {
		setShowSpinner(true)

		fetch(`${process.env.REACT_APP_API_ROUTE}/api/sourcings/${sourcing.id}/removeSourcing/`, {
			method: 'POST',
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": `Token ${token}`
			}
		})
			.then(async response => {
				const data = await response.json()

				if (data.success) {
					setShowSpinner(false)
					setShowDoneIcon(true)
					fetchSourcingContent()

					setTimeout(() => {
						setOpenModal(false)
						setShowDoneIcon(false)
					}, 2000)
				}
			})
			.catch(error => console.error('There was an error!', error))
	}

	const canDelete = () => {
		// Admins can delete everyone
		if (user.type === 'tecla_admin') return true
		// Managers can only delete if they're assigned to the company
		if (user.type === 'tecla_manager') {
			const managerCompaniesIds = []
			recruiter.companies.forEach(comp => managerCompaniesIds.push(comp.id))

			if (managerCompaniesIds.indexOf(sourcing.job.company.id) !== -1) return true
			return false
		}
		// Recruiters can delete only candidates they've added
		if (user.type === 'tecla_recruiter' && sourcing.recruiter.user.id === user.id) return true
		return false
	}

	return (
		<SuiBox display="flex" alignItems="center" >

			{cvUrl && (
				<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={cvUrl} target="_blank">
						<Tooltip title="View CV" placement="top">
							<AssignmentIcon />
						</Tooltip>
					</SuiTypography>
				</SuiBox>
			)}

			<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={candidateUrl} target="_blank">
					<Tooltip title="View profile" placement="top">
						<PersonIcon />
					</Tooltip>
				</SuiTypography>
			</SuiBox>

			<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={applicationUrl} target="_blank">
					<Tooltip title="Applications" placement="top">
						<SendIcon />
					</Tooltip>
				</SuiTypography>
			</SuiBox>

			{canDelete() && (
				<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} onClick={() => setOpenModal(true)}>
						<Tooltip title="Remove" placement="top">
							<DeleteIcon />
						</Tooltip>
					</SuiTypography>
				</SuiBox>
			)}

			{leverUrl && (
				<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={leverUrl} target="_blank">
						<Tooltip title="Lever Profile" placement="top">
							<SuiAvatar src={leverIcon} alt="Lever icon" size="xs" />
						</Tooltip>
					</SuiTypography>
				</SuiBox>
			)}

			<Modal open={openModal} onClose={() => setOpenModal(false)}>
				<SuiBox sx={{
					width: { xs: "100vw", md: "650px" },
					height: { xs: "101vh", md: "auto" },
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					bgcolor: 'background.paper',
					border: 'none',
					boxShadow: 24,
					borderRadius: { xs: 0, md: 4 },
					p: 3,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}} >

					<SuiTypography variant="h5" textAlign="center">
						Do you want to remove the candidate from this sourcing?
					</SuiTypography>

					<SuiBox width="80%" display="inherit" justifyContent="center" mx="auto" mt={2}>
						<SuiButton
							sx={{ width: "100%", marginRight: "1rem" }}
							color="info"
							variant="outlined"
							disabled={showSpinner || showDoneIcon}
							onClick={() => {
								setOpenModal(false)
							}}>
							Cancel
						</SuiButton>

						{/* eslint-disable-next-line */}
						{showSpinner ?
							<SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
							:
							showDoneIcon ?
								<SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
								:
								<SuiButton
									sx={{ width: "100%" }}
									color="info"
									variant="gradient"
									onClick={() => deleteCandidateFromFolder()}
								>
									Confirm
								</SuiButton>
						}
					</SuiBox>

				</SuiBox>
			</Modal>

		</SuiBox>
	)
}

ActionCell.defaultProps = {
	candidate: {}
}

ActionCell.propTypes = {
	candidate: PropTypes.objectOf(PropTypes.any),
	fetchSourcingContent: PropTypes.func.isRequired,
	sourcing: PropTypes.object.isRequired // eslint-disable-line
}

export default ActionCell
