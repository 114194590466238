/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { ReactSession } from 'react-client-session'
import { useState } from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
// MUI Components
import Modal from '@mui/material/Modal';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
// import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiTagInput from "components/SuiTagInput";
import SuiAlert from "components/SuiAlert";


function InvoiceModal({ openSkills, setOpenSkills, skills, setCandidate }) {
    
    ReactSession.setStoreType("localStorage")
    // const candidate = { ...ReactSession.get("candidate") };
    const token = ReactSession.get("token")

    const [loading, setLoading] = useState(false) // eslint-disable-line
    const [skillLevels, setSkillLevels] = useState([]) // eslint-disable-line
    const [skillValue, setSkillValue] = useState("") // eslint-disable-line
    const [minYear, setMinYear] = useState(0) // eslint-disable-line
    const [maxYear, setMaxYear] = useState(0) // eslint-disable-line
    const [showAlert, setShowAlert] = useState(false) // eslint-disable-line

    const yearsMin = [
      { value: 1, label: "1 Year" },
      { value: 2, label: "2 Years" },
      { value: 3, label: "3 Years" },
      { value: 4, label: "4 Years" },
      { value: 5, label: "5 Years" },
      { value: 6, label: "6 Years" },
      { value: 7, label: "7 Years" },
      { value: 8, label: "8 Years" },
      { value: 9, label: "9 Years" },
      { value: 10, label: "10 Years" },
    ];

    const yearsMax = [
      { value: 1, label: "1 Year" },
      { value: 2, label: "2 Years" },
      { value: 3, label: "3 Years" },
      { value: 4, label: "4 Years" },
      { value: 5, label: "5 Years" },
      { value: 6, label: "6 Years" },
      { value: 7, label: "7 Years" },
      { value: 8, label: "8 Years" },
      { value: 9, label: "9 Years" },
      { value: 10, label: "10+ Years" },
    ];

    function removeTag(e) {
        console.log(e);
    }

    function notInArray(skillStr) {
        // eslint-disable-next-line
        for (const skillLevel of skillLevels) {
          if (skillLevel.includes(`${skillStr} -`)) {
            return false;
          }
        }

        return true;
    }

    function addOptionalSkill() {
        if (skillValue && minYear && maxYear && maxYear >= minYear && notInArray(skillValue) && skillLevels.length < 3) {
            const skillLevelString = `${skillValue} - ${minYear} to ${maxYear}`;
            const skillLevelStrings = [...skillLevels];
            skillLevelStrings.push(skillLevelString);
            setSkillLevels(skillLevelStrings);
        } else {
            setShowAlert(true)
            setTimeout(() => {
              setShowAlert(false)
            }, "3000")
        }
    }

    function getSkillAndLevel(skillString) {
        // const skills = JSON.parse($(".requiredBox").attr("data-skills"));
        const skill = skillString.substring(skillString.lastIndexOf("-") - 1, 0).trim();
        const yearMin = Number(skillString.substring(skillString.lastIndexOf(" - ") + 3, skillString.lastIndexOf(" to ")));
        const yearMax = Number(skillString.substring(skillString.lastIndexOf(" to ") + 4, skillString.length));
        let data = null;
        // eslint-disable-next-line
        $.each(skills, function (i, val) {
            if (skill === val.label) {
                console.log(val)
                data = { "yearMin": yearMin, "yearMax": yearMax, "skill": { "id": val.id, "name": val.label } };
            }
        });

        return data;
    }

    function setAlert() {
        if (skillLevels.length) {
            const newSkill = { "skills": [] };
            // eslint-disable-next-line
            for (const skillLevelString of skillLevels) {
                const skillAndLevel = getSkillAndLevel(skillLevelString);
                console.log(skillAndLevel)
                newSkill.skills.push({
                    "skillId": Number(skillAndLevel.skill.id),
                    "minLevel": skillAndLevel.yearMin,
                    "level": skillAndLevel.yearMax,
                })
            }

            const requestMetadata = {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
              },
              body: JSON.stringify(newSkill)
            };
            fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/setJobAlert/`, requestMetadata)
              .then(res => res.json())
              .then(response => {
                if (response.success) {
                    setOpenSkills(false)
                    const sessionCandidate = { ...ReactSession.get("candidate") }
                    sessionCandidate.alerts = response.data;
                    setCandidate(sessionCandidate);
                    ReactSession.set("candidate", sessionCandidate);
                    setSkillLevels([])
                }
              });
        }
    }
    
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    `;

    // eslint-disable-next-line
    $(document).unbind().on("click", ".react-tag-input__tag__remove", function () {
        const tagtext = $(this).prev().text();
        const skillLevelStrings = [...skillLevels];

        if (skillLevelStrings.length && skillLevelStrings.indexOf(tagtext) >= 0) {
          skillLevelStrings.splice(skillLevelStrings.indexOf(tagtext), 1);
          setSkillLevels(skillLevelStrings);
        }
    });

    /* Mui hook to read screen size - We use it for small screen rendering */
    const isSmallScreen = useMediaQuery('(max-width: 1201px)')

    return (
        <Modal
            open={openSkills}
            onClose={() => setOpenSkills(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <SuiBox sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: "100vw", md: "40vw", xl: "70vw" },
                height: { xs: "101vh", md: "fit-content" },
                maxHeight: "90%",
                bgcolor: 'background.paper',
                border: 'none',
                boxShadow: 24,
                borderRadius: { xs: 0, md: 4 },
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}>
                { loading ? (
                    <SuiBox className="moonCover" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={0}>
                        <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                    </SuiBox>
                ) : null }
                { showAlert ? (
                    <SuiAlert color="error" className="alertError">Please select at least one skill and a range of years of experience before setting the alert.</SuiAlert>
                ) : null }
                <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <SuiBox display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                        <SuiTypography id="modal-modal-title" variant="h4" ml={1.5} color="dark" sx={{ fontWeigth: "bold" }}>Set new alert</SuiTypography>
                    </SuiBox>
                    <Icon onClick={() => setOpenSkills(false)} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                </SuiBox>
                <SuiBox display="flex" flexWrap="wrap">
                    <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">To set a new alert, first select a skill and a range of years of experience. You can add up to 3 skill per alert.</SuiTypography>
                </SuiBox>
                <SuiBox id="modal-modal-description" borderRadius="16px" height="100%" my={2} p={2}>
                    <Grid container xs={12} md={12} spacing={1} className="optionalBox">
                      <Grid item xs={4}>
                        <SuiSelect
                          placeholder={skills.length < 1 ? "Loading..." : "Skill"}
                          disabled={skills.length < 1}
                          options={skills}
                          smallFont={isSmallScreen}
                          onChange={e => setSkillValue(e.label)}
                          name="skill"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SuiSelect
                          placeholder="Years (Min)"
                          options={yearsMin}
                          smallFont={isSmallScreen}
                          name="yearsMin"
                          onChange={e => setMinYear(e.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SuiSelect
                          placeholder="Years (Max)"
                          options={yearsMax}
                          smallFont={isSmallScreen}
                          name="yearsMax"
                          onChange={e => setMaxYear(e.value)}
                        />
                      </Grid>
                      <Grid item xs={2} >
                        <SuiButton color="info" fullWidth onClick={addOptionalSkill}>ADD SKILL</SuiButton>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} md={12} spacing={1} mt={1}>
                        <Grid item xs={10}>
                            <SuiTagInput
                              placeholder="Skills."
                              tags={skillLevels}
                              onChange={e => removeTag(e)}
                            />
                        </Grid>
                    </Grid>
                </SuiBox>
                <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
                <SuiButton color="info" onClick={setAlert}>SET ALERT</SuiButton>
                </SuiBox>
            </SuiBox>
        </Modal>
  )
}

InvoiceModal.defaultProps = {
  skills: []
}

InvoiceModal.propTypes = {
    openSkills: PropTypes.bool.isRequired,
    setOpenSkills: PropTypes.func.isRequired,
    skills: PropTypes.objectOf(PropTypes.any),
    setCandidate: PropTypes.func.isRequired,
}

export default InvoiceModal