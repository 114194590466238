/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState, useEffect } from "react"
import { ReactSession } from 'react-client-session'
// @mui material components
import Modal from '@mui/material/Modal'
import Card from "@mui/material/Card"
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import { useMediaQuery, CircularProgress } from "@mui/material"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiAlert from "components/SuiAlert"
// Soft UI Dashboard PRO React example components
import DataTable from "examples/Tables/DataTable"
// Data
import dataTableData from "layouts/pages/profile/candidate-applications/data/dataTableData"
import dataTableDataSmall from "layouts/pages/profile/candidate-applications/data/dataTableDataSmall"
import ActionCell from "layouts/pages/profile/candidate-applications/components/ActionCell"


function ApplicationsModal({ openApplicationsModal, setOpenApplicationsModal, candidateId, candidateName }) {

    ReactSession.setStoreType("localStorage");
    const token = ReactSession.get("token")
        
    const [showSpinner, setShowSpinner] = useState(false)
    const [showError, setShowError] = useState(false)

    const [applicationsData, setApplicationsData] = useState(dataTableData);
    const [applicationsDataSmall, setApplicationsDataSmall] = useState(dataTableDataSmall);

    const fetchApplications = () => {
        setShowSpinner(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/applications/${candidateId}/teclaCandidateApplications/`, {
            headers: { "Authorization": `Token ${token}` }
        })
        .then(async response => {
            const res = await response.json()

            if (res.success && res.data) {
                const applicationsDataTemp = { ...applicationsData }
                const applicationsDataSmalltemp = { ...applicationsDataSmall }
                const restApplications = res.data.applications

                const applicationsArray = []

                // eslint-disable-next-line
                for (const application of restApplications) {
                    let hiringMethod = "TECLA"
                    if (application.job.hiringMethod === "dh") hiringMethod = "Direct Hire"
                    if (application.job.hiringMethod === "dhp") hiringMethod = "Direct Hire - Payroll"

                    const date = new Date(application.createdAt)
                    const dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split("T")[0]

                    applicationsArray.push({
                        appId: application.id,
                        jobId: application.job.id,
                        position: [application.job.seniorityName, application.job.id],
                        method: hiringMethod,
                        category: application.job.category,
                        date: dateString,
                        salary: application.salary,
                        status: application.status,
                        feedbackSent: application.feedbackSent ? <LibraryAddCheckIcon sx={{ color: "#69bc6d", ml: 2 }} fontSize="medium" /> : null,
                        action: <ActionCell jobId={application.job.id} appId={application.id} status={application.status} application={application} />,
                    })
                }
                applicationsDataTemp.rows = applicationsArray
                applicationsDataSmalltemp.rows = applicationsArray
                setApplicationsData(applicationsDataTemp)
                setApplicationsDataSmall(applicationsDataSmalltemp)

                setShowSpinner(false)
            }

            else {
                setShowSpinner(false)
                setShowError(true)
            }
        })
        .catch(error => {
            setShowSpinner(false)
            setShowError(true)
            console.error('There was an error!', error);
        });
    }

    useEffect(() => fetchApplications(), []);

    const isMobile = useMediaQuery('(max-width:500px)')

    return (
        <Modal open={openApplicationsModal} onClose={() => setOpenApplicationsModal(false)}>
            <SuiBox sx={{
                width: isMobile ? "90%" : "80%",
                height: isMobile ? "90%" : "50%",
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: 'none',
                boxShadow: 24,
                borderRadius: { xs: 0, md: 4 },
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }} >
                {/* eslint-disable-next-line */}
                {showSpinner ?
                    <SuiBox my={15} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={40} /></SuiBox>
                    :
                    showError ? <SuiAlert color="error">There was an error, try again later</SuiAlert>
                    :
                    <>
                        <SuiBox my={3}>
                            <SuiBox mb={2}>
                                <SuiTypography variant="h3" fontWeight="bold">{candidateName}&apos;s Applications</SuiTypography>
                            </SuiBox>

                            <Card>
                                <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <DataTable
                                        table={applicationsData}
                                        view="applications"
                                        entriesPerPage={{ defaultValue: 50 }}
                                    />
                                </SuiBox>
                                <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
                                    <DataTable
                                        table={applicationsDataSmall}
                                        view="applications"
                                        entriesPerPage={{ defaultValue: 50 }}
                                    />
                                </SuiBox>
                            </Card>
                        </SuiBox>
                    </>
                }                
            </SuiBox>
        </Modal>
    )
}

export default ApplicationsModal

// typechecking props
ApplicationsModal.propTypes = {
    openApplicationsModal: PropTypes.func.isRequired,
    setOpenApplicationsModal: PropTypes.func.isRequired,
    candidateId: PropTypes.number.isRequired,
    candidateName: PropTypes.string.isRequired,
};