/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

import PropTypes from "prop-types";


function ReferralCode({ shareRef }) {
  const { secondary } = colors;
  const { borderWidth } = borders;

  function showSuccess() {
    let timerSuccessid = "";
    $(".alertSuccessBox").show();
    if (timerSuccessid) {
      clearTimeout(timerSuccessid);
    }
    timerSuccessid = setTimeout(() => {
      $(".alertSuccessBox").hide();
    }, 2000);
  }

  function copyToClipboard(text) {
    const sampleTextarea = document.createElement("textarea");
    document.body.appendChild(sampleTextarea);
    sampleTextarea.value = text;
    sampleTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(sampleTextarea);
    showSuccess();
  }

  function copyTextClick(e) {
    e.preventDefault();
    const copyText = shareRef;
    copyToClipboard(copyText);
  }

  return (
    <SuiBox
      borderRadius="xl"
      border={`${borderWidth[1]} dashed ${secondary.main}`}
      pt={2}
      pb={1.5}
      px={2}
      mt={0}
    >
      <SuiBox mb={1} lineHeight={2}>
        <SuiTypography variant="h6" fontWeight="medium" >
          Your referral code
        </SuiTypography>
        <SuiTypography variant="caption" color="text" fontWeight="bold">
          Copy the link below and share it with your friends:
        </SuiTypography>
      </SuiBox>
      <SuiBox display="flex" alignItems="center" >
        <SuiBox width="80%" mr={1}>
          <SuiInput
            size="small"
            defaultValue={shareRef}
            icon={{ component: "lock", direction: "right" }}
            disabled
          />
        </SuiBox>
        <Tooltip title="Click here to copy your referral link" placement="top">
          <SuiButton
            variant="outlined"
            color="success"
            size="small"
            sx={{ padding: "0.5rem 1rem" }}
            onClick={copyTextClick}
          >
            copy
          </SuiButton>
        </Tooltip>
      </SuiBox>
      <SuiBox mt={2} mb={0.5} lineHeight={1.2}>
        <SuiTypography variant="caption" color="text">
          <SuiTypography
            component={Link}
            variant="caption"
            href="mailto:info@tecla.io"
            className="color-background"
          >
            Contact us
          </SuiTypography>{" "}
          if you need help
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

ReferralCode.defaultProps = {
  shareRef: "",
}

ReferralCode.propTypes = {
  shareRef: PropTypes.string,
};

export default ReferralCode;
