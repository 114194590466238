/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Fade from "@mui/material/Fade";
// Example componentes
import DataTable from "examples/Tables/DataTable";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function CameraView({ value, index, title, table }) {
  return (
    value === index && (
      <Fade in timeout={550}>
        <SuiBox my={3}>
          <SuiTypography variant="h4" ml={1}>
            {title}
          </SuiTypography>
          <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
            <DataTable
              table={table}
              view="applicants"
            />
          </SuiBox>
          <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
            <DataTable
              table={table}
              view="applicants"
            />
          </SuiBox>
        </SuiBox>
      </Fade>
    )
  );
}

// Typechecking props for the CameraView
CameraView.propTypes = {
  title: PropTypes.string.isRequired,
  table: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CameraView;
