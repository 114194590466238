/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React example components
import CompanyDashboardLayout from "examples/LayoutContainers/CompanyDashboardLayout"

import EventCard from "examples/Cards/EventCard";
// avatares
import Payoneer from "assets/images/discount-logos/payonneer.png";
import Deel from "assets/images/discount-logos/deel.png";
// banners
import PayoneerBanner from "assets/images/discount-banners/payoneer.png";
import DeelBanner from "assets/images/discount-banners/deel.png";


function CompanyDiscounts() {
  ReactSession.setStoreType("localStorage");

  useEffect(() => {document.title = `Discounts | TECLA`}, []);

  return (
    <CompanyDashboardLayout>
      <SuiTypography variant="h3" fontWeight="bold" ml={1} mb={3}>Discounts</SuiTypography>
      <SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12}>
            <SuiBox mb={3} display="flex" justifyContent="center">
              <Grid container spacing={2} xs={12}>
                <Grid item xs={12} xl={6} className="job-box">
                  <EventCard
                    id="https://deel.grsm.io/tecla"
                    image={Deel}
                    banner={DeelBanner}
                    size="70%"
                    title="Deel"
                    description="10% OFF on Deel Platform"
                    description2="Register on Deel and get 10% OFF through the following link https://deel.grsm.io/tecla"
                    action={{ type: "external", route: "https://deel.grsm.io/tecla", color: "success", label: "Redeem" }}
                  />
                </Grid>
                
                <Grid item xs={12} xl={6} className="job-box">
                  <EventCard
                    id="http://tracking.payoneer.com/SH3QH"
                    image={Payoneer}
                    banner={PayoneerBanner}
                    size="100%"
                    title="Payoneer"
                    description="Payoneer Premium (No Fees)"
                    description2="Payoneer Premium for TECLA's community through the following link https://register.payoneer.com/en/solution/tecla-talents/"
                    action={{ type: "external", route: "http://tracking.payoneer.com/SH3QH", color: "success", label: "Redeem" }}
                  />
                </Grid>
              </Grid>
            </SuiBox>

          </Grid>
        </Grid>
      </SuiBox>
    </CompanyDashboardLayout>
  );
}

export default CompanyDiscounts;
