/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types"
// MUI components
import { Icon, Modal } from "@mui/material"
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
// SUI components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"

function CandidateActivityModal({ fullname, activities, openActivityModal, setOpenActivityModal }) {

    function getStringDate(activityDate) {
        const dateObject = new Date(activityDate);
        const yyyy = dateObject.getFullYear();
        const mm = String(dateObject.getMonth() + 1).padStart(2, '0');
        const dd = String(dateObject.getDate()).padStart(2, '0');
        let hour = String(dateObject.getHours());
        let min = String(dateObject.getMinutes());
        if (hour.length === 1) {
            hour = `0${hour}`
        }
        if (min.length === 1) {
            min = `0${min}`
        }

        return `${mm}/${dd}/${yyyy} - ${hour}:${min}`;
    }

    return (
        <Modal
            hideBackdrop
            open={openActivityModal}
            onClose={() => setOpenActivityModal(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <SuiBox
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: "100vw", md: "80vw", xl: "60vw" },
                    height: { xs: "100vh", md: "auto" },
                    maxHeight: "80%",
                    overflowY: "scroll",
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                <SuiBox display="flex" flexWrap="wrap">
                    <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Activity for&nbsp;</SuiTypography>
                    <SuiTypography variant="h6" fontWeight="bold">{fullname}</SuiTypography>
                </SuiBox>

                    <Icon onClick={() => setOpenActivityModal(false)} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                </SuiBox>

                <SuiBox id="modal-modal-description" bgColor="#f8f9fa" borderRadius="16px" height="100%" my={2} sx={{ display: "block", maxHeight: "500px", overflow: "auto" }}>
                    <TableContainer component={Paper} sx={{ boxShadow: "none" }} >
                        <Table aria-label="simple table">
                            <TableBody style={{ display: "table", width: "100%" }}>
                                {activities.map((row) => (
                                    <TableRow
                                        key={row.text}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:last-child th': { width: "4rem" }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" } }}
                                        >
                                        <TableCell component="th" scope="row" size="small">
                                            <SuiTypography variant="body2" color="secondary" sx={{ width: "140px" }}>
                                                {getStringDate(row.createdAt)}
                                            </SuiTypography>
                                        </TableCell>
                                        <TableCell align="left" size="small">
                                            <SuiTypography variant="body2" color="secondary">
                                                {row.text}
                                            </SuiTypography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </SuiBox>

            </SuiBox>
        </Modal>
    )
}

CandidateActivityModal.defaultProps = {
  fullname: "",
  activities: [],
}

CandidateActivityModal.propTypes = {
  fullname: PropTypes.string,
  activities: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  openActivityModal: PropTypes.bool.isRequired,
  setOpenActivityModal: PropTypes.func.isRequired,
}

export default CandidateActivityModal