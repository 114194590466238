/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Switch from '@mui/material/Switch';
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
// Custom styles for the TalentFinder
import TalentFinderRoot from "examples/TalentFinder/TalentFinderRoot";
// Soft UI Dashboard PRO React context
import { useSoftUIController, setOpenTalentFinder } from "context";

function TalentFinder({ href, skills, countries, setCountries, filters, setFilters, search, setSearch, setPage }) {
  const [controller, dispatch] = useSoftUIController();
  const { openTalentFinder } = controller;

  const [skillLevels, setSkillLevels] = useState([]);

  const handleCloseTalentFinder = () => setOpenTalentFinder(dispatch, false);

  let tagtext = "";

  function setJobNameFilter(value) {
    const filtersCopy = { ...filters };
    filtersCopy.firstName = value;
    setFilters(filtersCopy);
  }

  function setEmailFilter(value) {
    const filtersCopy = { ...filters };
    filtersCopy.email = value;
    setFilters(filtersCopy);
  }

  function setsearchWithCV(value) {
    const filtersCopy = { ...filters };
    filtersCopy.withCV = value;
    setFilters(filtersCopy);
  }

  function setsearchWithVideo(value) {
    const filtersCopy = { ...filters };
    filtersCopy.withVideo = value;
    setFilters(filtersCopy);
  }

  function setsearchActive(value) {
    const filtersCopy = { ...filters };
    filtersCopy.active = value;
    setFilters(filtersCopy);
  }

  function clearFilters() {
    // const filtersCopy = { ...filters };
    const filtersTemp = {
      firstName: "",
      email: "",
      skills: [],
      skillsInCV: [],
      countries: [],
      withCV: false,
      withVideo: false,
      active: false,
      excludes: "",
      page: 1,
      searchKeyWords: "",
      keywordsSearch: false
    }
    setFilters(filtersTemp);
    setSkillLevels([]);
    const tempCountries = JSON.parse($(".countryBox").attr("data-countries"));
    setCountries([]);
    let timerid = "";
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      setCountries(tempCountries);
    }, 10);
  }

  function checkCountry(target) {
    const checked = !$(target).parent().hasClass("Mui-checked");
    const filtersCopy = { ...filters };
    if ($(target).val()) {
      const checkValue = Number($(target).parent().attr("data-id"));
      if (checked) {
        filtersCopy.countries.push(checkValue);
      } else {
        filtersCopy.countries = filtersCopy.countries.filter(x => x !== checkValue);
      }

      setFilters(filtersCopy);
    }
  }

  function getSkillById(skillId) {
    const tempSkills = [...skills];
    // eslint-disable-next-line
    for (const skill of tempSkills) {
      if (skillId === skill.value) {
        return skill;
      }
    }

    return null;
  }

  function getSkillByLabel(skillLabel) {
    const tempSkills = [...skills];
    // eslint-disable-next-line
    for (const skill of tempSkills) {
      if (skillLabel === skill.label) {
        return skill;
      }
    }

    return null;
  }

  function addSkillLevel() {
    const filtersCopy = { ...filters };
    const tempSkillLevels = [...skillLevels];
    const skillVal = $("input[name=skill]").val();
    const minVal = $("input[name=levelMin]").val();
    let maxVal = $("input[name=levelMax]").val();
    let isValid = false;
    if ($(".minMaxYears:visible").length && skillVal && minVal && maxVal) {
      isValid = true;
      filtersCopy.skills.push(`${skillVal},${minVal},${maxVal}`);
    } else if (!$(".minMaxYears:visible").length && skillVal) {
      filtersCopy.skillsInCV.push(`${skillVal}`);
      isValid = true;
    }
    const skill = getSkillById(Number(skillVal));
    if (isValid) {
      // eslint-disable-next-line
      if (maxVal) {
        maxVal = `${maxVal}+`;
      }
      let validPush = true;
      // eslint-disable-next-line
      for (const tempSkillLevel of tempSkillLevels) {
        if (tempSkillLevel.includes(`${skill.label} -`)) {
          validPush = false;
        }
      }
      if (validPush) {
        // eslint-disable-next-line
        if ($(".minMaxYears:visible").length) {
          tempSkillLevels.push(`${skill.label} - ${minVal} to ${maxVal} Years`);
        } else {
          tempSkillLevels.push(`${skill.label}`);
        }
        setSkillLevels(tempSkillLevels);
        setFilters(filtersCopy);
      }
    }
  }

  // eslint-disable-next-line
  $(document).unbind().on("click", ".react-tag-input__tag__remove", function () {
    let skllLabel = "";
    let minValue = "";
    let maxValue = "";
    let filtersText = "";
    tagtext = $(this).prev(".react-tag-input__tag__content");
    tagtext = tagtext.text();
    if ($(".minMaxYears:visible").length) {
      skllLabel = tagtext.substring(0, tagtext.indexOf("-") - 1);
      minValue = tagtext.substring(tagtext.indexOf("-") + 2, tagtext.indexOf("to") - 1);
      maxValue = tagtext.substring(tagtext.indexOf("to") + 3, tagtext.indexOf("Years") - 2);
    } else {
      skllLabel = tagtext.trim();
    }
    const skill = getSkillByLabel(skllLabel);
    const tempSkillLevels = [...skillLevels];
    const filtersCopy = { ...filters };
    if (tempSkillLevels.indexOf(tagtext) >= 0) {
      tempSkillLevels.splice(tempSkillLevels.indexOf(tagtext), 1);
      setSkillLevels(tempSkillLevels);
      if ($(".minMaxYears:visible").length) {
        filtersText = `${skill.value},${minValue},${maxValue}`;
        if (filtersCopy.skills.indexOf(filtersText) >= 0) {
          filtersCopy.skills.splice(filtersCopy.skills.indexOf(filtersText), 1);
          setFilters(filtersCopy);
        }
      } else {
        filtersText = `${skill.value}`;
        if (filtersCopy.skillsInCV.indexOf(filtersText) >= 0) {
          filtersCopy.skillsInCV.splice(filtersCopy.skillsInCV.indexOf(filtersText), 1);
          setFilters(filtersCopy);
        }
      }
    }
  });

  const levels = [
    { value: 1, label: "1 Years" },
    { value: 2, label: "2 Years" },
    { value: 3, label: "3 Years" },
    { value: 4, label: "4 Years" },
    { value: 5, label: "5 Years" },
    { value: 6, label: "6 Years" },
    { value: 7, label: "7 Years" },
    { value: 8, label: "8 Years" },
    { value: 9, label: "9 Years" },
    { value: 10, label: "10 Years +" },
  ];

  /* Custom hook to allow closing filters when clicking outside of it */
  const wrapperRef = useRef(null)

  // Detect clicks on scrollbar
  const clickedOnScrollBar = e => {
    if (e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight) return true
    return false
  }

  useEffect(() => {
    function handleClickOutside(event) {
      // The filters menu closes only when clicking outside of the component and not in the scrollbar
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !clickedOnScrollBar(event)) handleCloseTalentFinder()
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [wrapperRef])

  return (
    <TalentFinderRoot variant="permanent" ownerState={{ openTalentFinder }}>
      <SuiBox bgColor="white" height="100%" ref={wrapperRef}>
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={3}
          pb={0.8}
          px={3}
          width="100%"
        >
          <SuiBox>
            <SuiTypography variant="h5">Talent Finder</SuiTypography>
            <SuiTypography variant="body2" color="text">Find the right candidate</SuiTypography>
          </SuiBox>

          <Icon
            sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseTalentFinder}
          >
            close
          </Icon>
        </SuiBox>

        {/* CANDIDATE NAME */}
        {<>
          <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }} defaultExpanded>
            <AccordionSummary

              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              href={href}
            >
              <SuiTypography variant="body1">Full name</SuiTypography>
            </AccordionSummary>
            <AccordionDetails>
              <SuiBox mb={2}>
                <SuiInput
                  type="text"
                  placeholder="Full name"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                  className="searchname"
                  name="searchName"
                  onChange={e => setJobNameFilter(e.target.value)}
                  value={filters.firstName}
                >
                  Search
                </SuiInput>
              </SuiBox>
            </AccordionDetails>
          </Accordion>

          {/* EMAIL */}
          <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              href={href}
            >
              <SuiTypography variant="body1">Email</SuiTypography>
            </AccordionSummary>
            <AccordionDetails>
              <SuiBox mt={-1}>
                <SuiInput
                  type="text"
                  placeholder="Search by candidate email"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                  className="searchInput"
                  name="searchEmail"
                  onChange={e => setEmailFilter(e.target.value)}
                  value={filters.email}
                >
                  Search
                </SuiInput>
              </SuiBox>
            </AccordionDetails>
          </Accordion>
        </>}

        {/* SKILLS for DB */}
        {/* eslint-disable-next-line */}
        {<Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="body1">Skills</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container xs={12} md={12} spacing={1}>
              <Grid item xs={12}>
                <SuiSelect
                  placeholder="Skill"
                  options={skills}
                  name="skill"
                />
              </Grid>
              
              <Grid container xs={12} md={12} className="minMaxYears">
                <Grid item xs={5.5} spacing={1} style={{ marginLeft: "8px", marginTop: "8px" }}>
                  <SuiSelect
                    placeholder="Min Years"
                    options={levels}
                    name="levelMin"
                  />
                </Grid>
                <Grid item xs={5.5} spacing={1} style={{ marginLeft: "8px", marginTop: "8px" }}>
                  <SuiSelect
                    placeholder="Max Years"
                    options={levels}
                    name="levelMax"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} >
                <SuiButton className="addSkillLevel" color="info" onClick={addSkillLevel} fullWidth>Add</SuiButton>
              </Grid>
              <Grid item xs={12} md={12} className="skillsBox">
                <SuiTagInput
                  disabled
                  placeholder="Skills selected..."
                  tags={skillLevels}
                  onChange={() => { }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>}

        {/* COUNTRY */}
        {<>
          <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              href={href}
            >
              <SuiTypography variant="body1">Country</SuiTypography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <SuiBox mt={1}>
                <SuiInput
                  type="text"
                  placeholder="Search..."
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                  className="searchInput"
                  name="searchCountry"
                  onChange={e => setCountryFilter(e.target.value)}
                >
                  Search
                </SuiInput>
              </SuiBox> */}
              <SuiBox mt={1} className="countryBox" style={{ "maxHeight": "165px", "overflowY": "auto" }}>
                {countries.map(country => (
                  <SuiBox>
                    <Checkbox data-id={country.value} className="selectSkill" onClick={e => checkCountry(e.target)} defaultChecked={false} />
                    <SuiTypography
                      variant="button"
                      fontWeight="regular"
                      sx={{ userSelect: "none" }}>
                      {country.label}
                    </SuiTypography>
                  </SuiBox>
                ))}
              </SuiBox>
            </AccordionDetails>
          </Accordion>

          <Divider />

          <SuiBox ml={2}  >
            <SuiBox display="flex" height="auto" alignItems="center">
              <Switch sx={{ "& .Mui-checked": { top: "50% !important" }}} checked={filters.withCV} onChange={() => setsearchWithCV(!filters.withCV)} />
              <SuiTypography variant="body2" ml={2}>
                With CV only
              </SuiTypography>
            </SuiBox>

            <SuiBox display="flex" height="auto" alignItems="center">
              <Switch sx={{ "& .Mui-checked": { top: "50% !important" }}} checked={filters.withVideo} onChange={() => setsearchWithVideo(!filters.withVideo)} />
              <SuiTypography variant="body2" ml={2}>
                With video only
              </SuiTypography>
            </SuiBox>

            <SuiBox display="flex" height="auto" alignItems="center">
              <Switch sx={{ "& .Mui-checked": { top: "50% !important" }}} checked={filters.active} onChange={() => setsearchActive(!filters.active)} />
              <SuiTypography variant="body2" ml={2}>
                Active users only
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </>}

        <Divider />

        <SuiBox mt={2} pb={10} display="flex" flexDirection="column">
          <SuiButton sx={{ my: 2 }} fullWidth color="info" variant="gradient" onClick={() => {
            setPage(1)
            setSearch(search + 1)}
          }>Search</SuiButton>

          <SuiButton sx={{ my: 2 }} fullWidth color="info" variant="outlined" onClick={() => {
            setPage(1)
            clearFilters()
          }}>Clear filters</SuiButton>
        </SuiBox>

      </SuiBox>
    </TalentFinderRoot >
  );
}

// typechecking props
TalentFinder.propTypes = {
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  skills: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  countries: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setCountries: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  filters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setFilters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  search: PropTypes.number.isRequired,
  setSearch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setPage: PropTypes.func.isRequired
};

export default TalentFinder;
