/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { ReactSession } from 'react-client-session'
// @mui material components
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiSelect from "components/SuiSelect"
import SuiButton from "components/SuiButton"
// Settings page components
import FormField from "layouts/pages/users/new-user/components/FormField"
import TableCell from "layouts/pages/account/settings/components/TableCell"
import UserTableRecord from "./UserTableRecord"

function ManageUsers({ formData }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const { formField, values, errors, touched } = formData
  const { email } = formField
  const { email: emailV } = values

  const [newUserRole, setNewUserRole] = useState("")
  /* companyUsers stores all other company users. This renders so the admin user can change their roles. */
  const [companyUsers, setcompanyUsers] = useState([])

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  const [showEmailAlreadyUsedError, setShowEmailAlreadyUsedError] = useState(false)

  const fetchCompanyUsers = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/companyUsers/`, {
      method: 'GET',
      headers: { 'Authorization': `Token ${token}` }
    })
      .then(res => res.json())
      .then(response => {

        if (response.success) {
          const fetchedUsers = []

          response.data.forEach(user => {
            fetchedUsers.push({
              name: `${user.first_name} ${user.last_name}`,
              email: user.email,
              status: user.status ? `${user.status[0].toUpperCase()}${user.status.substring(1, user.status.length)}` : user.status,
              role: user.type,
              id: user.id
            })
          })

          setcompanyUsers(fetchedUsers)
        }
      })
      .catch(error => console.error(error))
  }

  const addNewUser = () => {
    setShowSpinner(true)
    const body = { email: emailV, type: newUserRole }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/companyAddUser/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(response => {

        /* Showing error when using duplicate email */
        if (!response.success && response.data.error.indexOf("duplicate key value violates unique constraint") !== -1) {
          setShowSpinner(false)
          setShowEmailAlreadyUsedError(true)
          setTimeout(() => setShowEmailAlreadyUsedError(false), 2000)
        }

        else if (response.success) {
          fetchCompanyUsers()

          setShowSpinner(false)
          setShowDoneIcon(true)
          setTimeout(() => setShowDoneIcon(false), 2000)
        }
      })
      .catch(error => console.error(error))
  }

  const roleOptions = [
    { value: 'company_owner', label: 'Admin' },
    { value: 'recruiter', label: 'Recruiter' },
    { value: 'limited_recruiter', label: "Limited Recruiter" }
  ]

  useEffect(() => { fetchCompanyUsers() }, [])

  return (
    <Card id="manageUsers">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5">Manage Users</SuiTypography>
        </SuiBox>
        <SuiTypography variant="button" color="text" fontWeight="regular">Add users and set their permission levels.</SuiTypography>
      </SuiBox>

      <SuiBox px={3} lineHeight={1} mb={1}>
        <SuiTypography variant="h6">Company Roles and Permission Levels</SuiTypography>

        <SuiBox mb={1} display="flex" flexDirection="column">
          <SuiTypography variant="button" color="text" fontWeight="regular"><SuiTypography variant="button" fontWeight="bold">- Admin:</SuiTypography> Can review candidates, create jobs, view salary information and manage users.</SuiTypography>
          <SuiTypography variant="button" color="text" fontWeight="regular"><SuiTypography variant="button" fontWeight="bold">- Recruiter:</SuiTypography> Can review candidates, create jobs and view salary information.</SuiTypography>
          <SuiTypography variant="button" color="text" fontWeight="regular"><SuiTypography variant="button" fontWeight="bold">- Limited Recruiter:</SuiTypography> Can review candidates, but can’t create jobs or view salary information.</SuiTypography>
        </SuiBox>
      </SuiBox>

      <SuiBox mb={1}>
        <SuiTypography variant="h6" ml={3} my={1.5}>Add New User</SuiTypography>

        <Grid container spacing={3} alignItems="center" justifyContent="center" mb={2}>

          <Grid item xs={10} md={4} >
            <FormField
              type="email"
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder="Work email address"
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>

          <Grid item xs={10} md={5} >
            <SuiSelect
              placeholder="User role (required)"
              label="Role"
              options={roleOptions}
              name="UserRole"
              success={newUserRole}
              onChange={e => setNewUserRole(e.value)}
            />
          </Grid>

          <Grid item xs={12} md={2} alignSelf="center">
            <SuiBox display="flex" justifyContent="center" alignItems="center">
              {showSpinner ?  // eslint-disable-line
                <CircularProgress size={20} />
                :
                showDoneIcon ? // eslint-disable-line
                  <DoneIcon fontSize="medium" />
                  :
                  showEmailAlreadyUsedError ?
                    <SuiButton
                      color="error"
                      variant="outlined"
                      style={isMobile ? { minWidth: '80%' } : null}
                    >Email address already used</SuiButton>
                    :
                    <SuiButton
                      variant="outlined"
                      color="info"
                      size="small"
                      disabled={errors.email || !emailV || !newUserRole}
                      onClick={() => addNewUser()}
                      style={isMobile ? { minWidth: '80%' } : null}
                    >Add user</SuiButton>
              }
            </SuiBox >
          </Grid>

        </Grid>

      </SuiBox>

      <SuiBox mb={1} >
        <SuiTypography variant="h6" ml={3} mb={.5}>Change Roles</SuiTypography>

        <Table sx={isMobile ? { width: "95%", mx: 1, mt: 3, mb: 5 } : { width: "95%", mx: 4, mt: 3, mb: 5 }} >
          <SuiBox component="thead">
            <TableRow>
              <TableCell align="center">{isMobile ? "User / Current role" : "User"}</TableCell>
              {!isMobile && <TableCell align="center">Email</TableCell>}
              {!isMobile && <TableCell align="center">Status</TableCell>}
              {!isMobile && <TableCell align="center">Current Role</TableCell>}
              <TableCell align="center">New Role</TableCell>
              <TableCell align="center">Confirm</TableCell>
            </TableRow>
          </SuiBox>

          <TableBody>
            {companyUsers.map(companyUser =>
              <UserTableRecord
                key={companyUser.id}
                user={companyUser}
                roleOptions={roleOptions}
                fetchCompanyUsers={fetchCompanyUsers}
              />
            )}
          </TableBody>
        </Table>
      </SuiBox>

    </Card>
  )
}

// typechecking props for UserSignUp
ManageUsers.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
}

export default ManageUsers