/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { ReactSession } from 'react-client-session'
import { useState } from "react"
// MUI Components
import Modal from '@mui/material/Modal';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
// import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import FolderImage from "layouts/recruiterFolders/folderImage/folderImage";


function InvoiceModal({ openInvoice, setOpenInvoice, loadInvoices, setLoadInvoices }) {
    
    ReactSession.setStoreType("localStorage")
    const user = ReactSession.get("user")
    const token = ReactSession.get("token")

    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState([]) // eslint-disable-line
    
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

    function getFileName(fileUrl) {
        if (fileUrl) {
        return fileUrl.substring(fileUrl.lastIndexOf("/") + 1, fileUrl.length - 4);
        }

        return "";
    }

    function closeFile() {
        setFiles([]);
    }

    function validateCV() {
        const resumeField = $("input[name=cv]").prop('files')[0];

        let validCv = true;
        if (resumeField && resumeField.name) {
            const extension = resumeField.name.substring(resumeField.name.length - 4, resumeField.name.length).toLowerCase();
            if (!extension.includes("pdf")) {
                validCv = false;
            }
        }

        if (!validCv) {
          $("input[name=cv]").val("");
        } else {
            setFiles([resumeField.name])
        }
    }
  
    function uploadResume() {
        if (user && $("input[name=description]").val()) {
            setLoading(true);
            const resumeField = $("input[name=cv]").prop('files')[0];
            const formPostData = new FormData();

            // eslint-disable-next-line no-useless-concat
            const postToken = `Token ${token}`;

            formPostData.append('file', resumeField);
            formPostData.append('description', $("input[name=description]").val());

            const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${user.id}/createInvoice/`;
            const normalizedName = resumeField.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            // const postBody = {
            //   'file': $("input[name=password]").val(),
            // };

            const requestMetadata = {
                method: 'POST',
                headers: {
                    "Content-Disposition": `attachment; filename=${normalizedName}`,
                    "Authorization": postToken,
                },
                body: formPostData
            };

            fetch(recipeUrl, requestMetadata)
                .then(res => res.json())
                .then(response => {
                    if (response.success) {
                        setLoadInvoices(loadInvoices + 1); // eslint-disable-line
                        setOpenInvoice(false)
                    }
                    setLoading(false);
                });
        }
    }

    return (
        <Modal
            open={openInvoice}
            onClose={() => setOpenInvoice(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <SuiBox sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: "100vw", md: "40vw", xl: "70vw" },
                height: { xs: "101vh", md: "fit-content" },
                maxHeight: "90%",
                bgcolor: 'background.paper',
                border: 'none',
                boxShadow: 24,
                borderRadius: { xs: 0, md: 4 },
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}>
                { loading ? (
                    <SuiBox className="moonCover" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={0}>
                        <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                    </SuiBox>
                ) : null }
                <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <SuiBox display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                        <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Send a new invoice</SuiTypography>
                    </SuiBox>
                    <Icon onClick={() => setOpenInvoice(false)} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                </SuiBox>
                <SuiBox id="modal-modal-description" borderRadius="16px" height="100%" my={2} p={2} sx={{ overflow: "auto" }}>
                    <Grid container xs={12} md={12} spacing={1}>
                        <Grid item xs={6}>
                            <SuiTypography variant="button" ml={1} mb={1}>Description</SuiTypography>
                            <SuiInput name="description" type="text" label="Description" placeholder="Description" />
                        </Grid>
                        <Grid item xs={6}>
                            <SuiTypography variant="button" ml={1} mb={1}>Add your invoice file in PDF</SuiTypography>
                            <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={validateCV} inputProps={{ accept: '.pdf' }} />
                        </Grid>
                    </Grid>
                    {files && files.length ? (
                        <SuiBox my={3} display="flex" justifyContent="start" style={{ overflow: "auto", "maxWidth": "100%", background: "#f6f7f8" }}>
                        {files.map(file => (
                            <Grid item xs={6} lg={2} className="folder-box" m={1} p={1} sx={{ maxWidth: "175px !important" }}>
                                <FolderImage folderName={getFileName(file)} closeFile={closeFile} />
                            </Grid>
                        ))}
                        </SuiBox>
                    ) : null }
                </SuiBox>
                <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
                <SuiButton color="info" onClick={uploadResume}>Save</SuiButton>
                </SuiBox>
            </SuiBox>
        </Modal>
  )
}

InvoiceModal.defaultProps = {
  loadInvoices: 0,
  setLoadInvoices: null
}

InvoiceModal.propTypes = {
    openInvoice: PropTypes.bool.isRequired,
    setOpenInvoice: PropTypes.func.isRequired,
    loadInvoices: PropTypes.number,
    setLoadInvoices: PropTypes.func,
}

export default InvoiceModal