/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types' // eslint-disable-line
import { ReactSession } from 'react-client-session'
import { useEffect, useState } from 'react'
// @mui material components
import useMediaQuery from '@mui/material/useMediaQuery'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import ListItem from '@mui/material/ListItem'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiAlert from 'components/SuiAlert'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
// Assets
import BoostLogo from 'assets/images/Boost_Trans_Blanco_1.png'
// Modal
import VideoModal from './videoModal/VideoModal'

function PreviewContent() {

    ReactSession.setStoreType('localStorage')
    const token = ReactSession.get('token')

    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')

    const [showSpinner, setShowSpinner] = useState(false)
    const [showError, setShowError] = useState(false)

    const [products, setProducts] = useState([])

    const [discountCodes, setDiscountCodes] = useState({})

    const updateDiscountCodes = (code, productId) => {
        const discountCodesObj = discountCodes
        discountCodesObj[productId] = code
        setDiscountCodes(discountCodesObj)
    }
  
    const getProducts = () => {
        setShowSpinner(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/getStripeProducts/`, {
            headers: {
                'content-type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(async response => {
            const data = await response.json()
  
            if (data.success) {
                setProducts(data.data)
                setShowSpinner(false)
            }
            else {
                setShowError(true)
                setShowSpinner(false)
                setTimeout(() => setShowError(false), 2000)
            }
        })
        .catch(error => {
            setShowError(true)
            console.error('There was an error!', error)
        })
    }

    useEffect(() => getProducts(), [])

    const buyProduct = (selectedProduct) => {
        setShowSpinner(true)

        const requestBody = { id: selectedProduct }
        if (discountCodes[selectedProduct]) requestBody.coupon = discountCodes[selectedProduct]

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/buyProduct/`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(requestBody)
        })
        .then(async response => {
            const data = await response.json()

            if (data.success) {
                ReactSession.set('coursePaymentData', data?.data)
                window.location.href = data?.data?.url
            }
            else {
                setShowError(true)
                setShowSpinner(false)
                setTimeout(() => setShowError(false), 2000)
            }
        })
        .catch(error => {
            setShowError(true)
            console.error('There was an error!', error)
        })
    }

    const [openVideoModal, setOpenVideoModal] = useState(true)
   
    return (
        <SuiBox mt={5} ml={isMobile ? 2 : 4}>
            {showSpinner ?
                <SuiBox my={20} display='flex' justifyContent='center' alignItems='center'>
                    <CircularProgress size={40}/>
                </SuiBox>
            :
                <>
                    {showError && <SuiAlert color='error'>There was an error, try again later</SuiAlert>}

                    <SuiBox sx={{ width: '100%', background: '#006bda', p: 2, borderRadius: '.5rem', mb: 2 }}>
                        <SuiBox component='img' src={BoostLogo} alt='BoostLogo' width='auto' height='100%'/>
                    </SuiBox>

                    <SuiTypography variant='body1' sx={{ ml: 1 }}>Our certifications are tailored to your specific goals.</SuiTypography>
                    <SuiTypography variant='body1' sx={{ ml: 1 }}>Choose what best fits your career path.</SuiTypography>

                    <SuiBox width='100%' display='flex' flexDirection={isMobile ? 'column' : 'row'} mt={3} >
                        {products?.filter(product => product?.plus === false).map((product, i) => { // eslint-disable-line
                            return (
                                <Card
                                    key={product?.id}
                                    sx={{
                                        width: isMobile ? '100%' : '30%',
                                        m: isMobile ? 0 : 3,
                                        my: isMobile ? 2 : 0,
                                        ml: i === 0 ? 0 : 'auto',
                                        overflowX: 'hidden'
                                    }}
                                >
                                    <CardMedia
                                        sx={{ m: 0, ml: -10, p: 0, height: '1rem', width: '150%', background: '#006bda' }}
                                    />
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <SuiTypography sx={{ mt: 1.5 }} variant='h4'>{product?.name}</SuiTypography>
                                        <SuiTypography variant='subtitle1' fontWeight='bold'>$ {product?.price} USD</SuiTypography>
                                        <SuiTypography variant='button'>*One time payment + Lifetaime access</SuiTypography>

                                        <ListItem divider sx={{ my: 2 }} />

                                        <SuiTypography variant='overline' my={1} dangerouslySetInnerHTML={{ __html: product?.description }} />

                                        <SuiTypography variant='button' sx={{ mt: 2 }}>Discount code:</SuiTypography>
                                        <SuiInput
                                            type='text'
                                            placeholder='Discount code'
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                            textTransform='capitalize'
                                            onChange={e => updateDiscountCodes(e?.target?.value, product?.id)}
                                        />
                                    </CardContent>

                                    <CardActions
                                        sx={{ m: 0, p: 0, height: '5rem', width: '100%', background: '#006bda', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        onClick={() => buyProduct(product?.id)}
                                    >
                                        <SuiTypography variant='body1' color='white'>Get this plan!</SuiTypography>
                                    </CardActions>
                                </Card>
                            )
                        })}
                    </SuiBox>
                    
                    {openVideoModal && (
                        <VideoModal openVideoModal={openVideoModal} setOpenVideoModal={setOpenVideoModal} />
                    )}
                </>
            }
        </SuiBox>
    );
}

// Typechecking props for the ProfilesList
PreviewContent.propTypes = {
};
  
export default PreviewContent;