/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// ProductsList page components
import NameAndPictureCell from "layouts/clientFolders/folderContent/NameAndPictureCell/nameAndPictureCell"
import ActionCell from "layouts/clientFolders/folderContent/ActionCell/ActionCell"

export default {
  columns: [
    {
      Header: "Candidate",
      accessor: "candidate",
      Cell: ({ value: [name, data, url] }) => <NameAndPictureCell image={data} name={name} url={url} />,
    },
    { Header: "Country", accessor: "country" },
    { Header: "Skills", accessor: "skills" },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ value: [candidate, fetchFolderContent, folderId] }) => <ActionCell candidate={candidate} fetchFolderContent={fetchFolderContent} folderId={folderId} />,
    },
  ],

  rows: [
    {
      candidate: "",
      country: "",
      skills: "",
      action: ""
    },
  ],
}
