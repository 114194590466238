/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";

function actionCell({ recruiterId, invites }) {

	return (
		<SuiBox display="flex" alignItems="center" justifyContent="center" flexDirection="column">
			<SuiInput
				name="recruiterMonthInvitesInput"
				type="number"
				defaultValue={invites}
				id={`${recruiterId}`}
				data-recruiterid={recruiterId}
            />
		</SuiBox>
	);
}

// Typechecking props for the actionCell
actionCell.propTypes = {
	recruiterId: PropTypes.number.isRequired,
	invites: PropTypes.number.isRequired,
};

export default actionCell;
