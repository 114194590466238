/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useRef, useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { useLocation } from 'react-router-dom';
import PropTypes from "prop-types";
// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";
// From Filters
import Checkbox from "@mui/material/Checkbox";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Configurator({
                        href,
                        candidateCountryFilter,
                        setCandidateCountryFilter,
                        hiredStateFilter,
                        setHiredStateFilter,
                        inReviewStateFilter,
                        setInReviewStateFilter,
                        activeStateFilter,
                        setActiveStateFilter,
                        introStateFilter,
                        setIntroStateFilter,
                        interviewStateFilter,
                        setInterviewStateFilter,
                        withdrewStateFilter,
                        setWithdrewStateFilter,
                        rejectedStateFilter,
                        setRejectedStateFilter,
                        engineeringFilter,
                        setEngineeringFilter,
                        qaFilter,
                        setQaFilter,
                        designFilter,
                        setDesignFilter,
                        devopsFilter,
                        setDevopsFilter,
                        dataFilter,
                        setDataFilter,
                        productFilter,
                        setProductFilter,
                        managementFilter,
                        setManagementFilter,
                        marketingFilter,
                        setMarketingFilter,
                        salesFilter,
                        setSalesFilter,
                        betterForAnotherRoleFilter,
                        setBetterForAnotherRoleFilter,
                        compensationFilter,
                        setCompensationFilter,
                        cultureFitFilter,
                        setCultureFitFilter,
                        employmentDurationFilter,
                        setEmploymentDurationFilter,
                        englishSkillsFilter,
                        setEnglishSkillsFilter,
                        futureOpportunitiesFilter,
                        setFutureOpportunitiesFilter,
                        locationFilter,
                        setLocationFilter,
                        motivationsFilter,
                        setMotivationsFilter,
                        offerDeclinedFilter,
                        setOfferDeclinedFilter,
                        overqualifiedFilter,
                        setOverqualifiedFilter,
                        positionClosedFilter,
                        setPositionClosedFilter,
                        timingFilter,
                        setTimingFilter,
                        underqualifiedFilter,
                        setUnderqualifiedFilter,
                        unresponsiveFilter,
                        setUnresponsiveFilter,
                        withdrewFilter,
                        setWithdrewFilter,
                        fetchApplications,
                      }) {

  const [controller, dispatch] = useSoftUIController(); // eslint-disable-line
  const { openConfigurator } = controller;
  
  // const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const [filtersWereCleared, setFiltersWereCleared] = useState(false)

  const selectAllRejectReasons = () => {
    setBetterForAnotherRoleFilter(true)
    setCompensationFilter(true)
    setCultureFitFilter(true)
    setEmploymentDurationFilter(true)
    setEnglishSkillsFilter(true)
    setFutureOpportunitiesFilter(true)
    setLocationFilter(true)
    setMotivationsFilter(true)
    setOfferDeclinedFilter(true)
    setOverqualifiedFilter(true)
    setPositionClosedFilter(true)
    setTimingFilter(true)
    setUnderqualifiedFilter(true)
    setUnresponsiveFilter(true)
    setWithdrewFilter(true)
  }

  const unselectAllRejectReasons = () => {
    setBetterForAnotherRoleFilter(false)
    setCompensationFilter(false)
    setCultureFitFilter(false)
    setEmploymentDurationFilter(false)
    setEnglishSkillsFilter(false)
    setFutureOpportunitiesFilter(false)
    setLocationFilter(false)
    setMotivationsFilter(false)
    setOfferDeclinedFilter(false)
    setOverqualifiedFilter(false)
    setPositionClosedFilter(false)
    setTimingFilter(false)
    setUnderqualifiedFilter(false)
    setUnresponsiveFilter(false)
    setWithdrewFilter(false)
  }
  
  const clearFiltersAndFetchResults = () => {
    $("[name=jobName]").val("")
    $("[name=candidateName]").val("")
    $("[name=candidateEmail]").val("")
    setCandidateCountryFilter(null)
    $("[name=companyName]").val("")
    setInReviewStateFilter(false)
    setActiveStateFilter(false)
    setHiredStateFilter(false)
    setIntroStateFilter(false)
    setInterviewStateFilter(false)
    setWithdrewStateFilter(false)
    setRejectedStateFilter(false)
    setEngineeringFilter(false)
    setQaFilter(false)
    setDesignFilter(false)
    setDevopsFilter(false)
    setDataFilter(false)
    setProductFilter(false)
    setManagementFilter(false)
    setMarketingFilter(false)
    setSalesFilter(false)
    unselectAllRejectReasons()

    setFiltersWereCleared(true)
  }

  const [countriesOptions, setCountriesOptions] = useState([])

  const location = useLocation()

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
    } catch (err) {
      console.error('fetchInterests error', err)
    }
  }

  const populateCountries = async () => {
    if (location?.pathname !== '/t/signup' && !location?.pathname?.includes('/job/apply') && !location?.pathname?.includes('validated')) {

      let interests = sessionStorage.getItem('interests')

      if (interests) interests = JSON.parse(interests)
      else interests = await fetchInterests()

      const newData = []
      interests?.data?.countries.forEach(country => newData.push({ value: country.id, label: country.name }))
      setCountriesOptions(newData)
    }
  }

  useEffect(() => { populateCountries() }, [])

  useEffect(() => { 
    if(fetchApplications && filtersWereCleared) { 
      fetchApplications()
      setFiltersWereCleared(false)
    } 
  }, [filtersWereCleared])

  /* Custom hook to allow closing filters when clicking outside of it */
  const wrapperRef = useRef(null)

  // Detect clicks on scrollbar
  const clickedOnScrollBar = e => {
    if (e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight) return true
    return false
  }
  
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     // The filters menu closes only when clicking outside of the component and not in the scrollbar
  //     if (wrapperRef.current && !$(event.target).parents(".wrapperBox").length && !clickedOnScrollBar(event)) handleCloseConfigurator()
  //   }
  //   // Bind the event listener
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }
  // }, [wrapperRef])

  function setCloseConfigurator() {
    $(".wrapperBox").parent().css("right", "-360px").css("width", "0");
  }

  // eslint-disable-next-line
  $(document).unbind().on("click", function (e) {
    if ($(".wrapperBox").parent().css("right") === "0px" && 
      !$(e.target).hasClass("wrapperBox") && 
      !$(e.target).parents(".wrapperBox").length && 
      !clickedOnScrollBar(e)) {
      setCloseConfigurator();
    }
  });

  function handleCloseConfigurator() {
    setCloseConfigurator();
  }

  
  
  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <SuiBox className="wrapperBox" bgColor="white" height="100%" ref={wrapperRef} onKeyPress={(e) => {
          if (e.key === "Enter") {
          fetchApplications()
          handleCloseConfigurator()
        }}}>
        <SuiBox display="flex" justifyContent="space-between" alignItems="baseline" pt={3} pb={0.8} px={3} width="100%" >
          <SuiBox>
            <SuiTypography variant="h5">Applications filters</SuiTypography>
            <SuiTypography variant="body2" color="text">Find the right application</SuiTypography>
          </SuiBox>

          <Icon
            sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseConfigurator}
          >
            close
          </Icon>
        </SuiBox>

        {/* JOB NAME */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="body1">Job name</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SuiBox mt={-1}>
              <SuiInput
                type="text"
                placeholder="Search by job name"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                className="searchInput"
                name="jobName"
              />
            </SuiBox>
          </AccordionDetails>
        </Accordion>

        {/* CANDIDATE NAME */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="body1">Candidate name</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SuiBox mb={2}>
              <SuiInput
                type="text"
                placeholder="Candidate name"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                className="searchInput"
                name="candidateName"
              />
            </SuiBox>
          </AccordionDetails>
        </Accordion>

        {/* CANDIDATE COUNTRY */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="body1">Candidate country</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SuiBox mb={2}>
              <SuiSelect
                placeholder="Candidate country"
                options={countriesOptions}
                name="country"
                onChange={e => (setCandidateCountryFilter(e.value))}
                value={countriesOptions.filter(country => country.value === candidateCountryFilter)}
              />
            </SuiBox>
          </AccordionDetails>
        </Accordion>

        {/* CANDIDATE EMAIL */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="body1">Candidate email</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SuiBox mb={2}>
              <SuiInput
                type="text"
                placeholder="Candidate email"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                className="searchInput"
                name="candidateEmail"
              />
            </SuiBox>
          </AccordionDetails>
        </Accordion>

        {/* COMPANY NAME */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="body1">Company name</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SuiBox>
              <SuiInput
                type="text"
                placeholder="Search by company name"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                className="searchInput"
                name="companyName"
              />
            </SuiBox>
          </AccordionDetails>
        </Accordion>

        {/* APPLICATION STATE */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="body">Application state</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SuiBox className="skillBox" style={{ "overflowY": "auto" }}>
              <SuiBox>
                <Checkbox checked={inReviewStateFilter} onChange={() => setInReviewStateFilter(!inReviewStateFilter)}/>
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Applicants</SuiTypography>
              </SuiBox>

              <SuiBox>
                <Checkbox checked={activeStateFilter} onChange={() => setActiveStateFilter(!activeStateFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Submitted</SuiTypography>
              </SuiBox>

              <SuiBox>
                <Checkbox checked={hiredStateFilter} onChange={() => setHiredStateFilter(!hiredStateFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Hired</SuiTypography>
              </SuiBox>

              <SuiBox>
                <Checkbox checked={introStateFilter} onChange={() => setIntroStateFilter(!introStateFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Intro Requested</SuiTypography>
              </SuiBox>

              <SuiBox>
                <Checkbox checked={interviewStateFilter} onChange={() => setInterviewStateFilter(!interviewStateFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Interview</SuiTypography>
              </SuiBox>

              <SuiBox>
                <Checkbox checked={withdrewStateFilter} onChange={() => setWithdrewStateFilter(!withdrewStateFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Withdrew</SuiTypography>
              </SuiBox>

              <SuiBox>
                <Checkbox checked={rejectedStateFilter} onChange={() => {
                  if (!rejectedStateFilter) {
                    setRejectedStateFilter(true)
                    selectAllRejectReasons()
                  } else {
                    setRejectedStateFilter(false)
                    unselectAllRejectReasons()
                  }
                }} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Rejected</SuiTypography>
              </SuiBox>

              {rejectedStateFilter && (
                <SuiBox ml={2}>
                  <SuiBox>
                    <Checkbox checked={betterForAnotherRoleFilter} onChange={() => setBetterForAnotherRoleFilter(!betterForAnotherRoleFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Better for another role</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={compensationFilter} onChange={() => setCompensationFilter(!compensationFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Compensation</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={cultureFitFilter} onChange={() => setCultureFitFilter(!cultureFitFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Culture fit</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={employmentDurationFilter} onChange={() => setEmploymentDurationFilter(!employmentDurationFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Employment duration</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={englishSkillsFilter} onChange={() => setEnglishSkillsFilter(!englishSkillsFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>English skills</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={futureOpportunitiesFilter} onChange={() => setFutureOpportunitiesFilter(!futureOpportunitiesFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Keep for future opportunities</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={locationFilter} onChange={() => setLocationFilter(!locationFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Location</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={motivationsFilter} onChange={() => setMotivationsFilter(!motivationsFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Motivations</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={offerDeclinedFilter} onChange={() => setOfferDeclinedFilter(!offerDeclinedFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Offer declined</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={overqualifiedFilter} onChange={() => setOverqualifiedFilter(!overqualifiedFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Overqualified</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={positionClosedFilter} onChange={() => setPositionClosedFilter(!positionClosedFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Position closed</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={timingFilter} onChange={() => setTimingFilter(!timingFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Timing</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={underqualifiedFilter} onChange={() => setUnderqualifiedFilter(!underqualifiedFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Underqualified</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={unresponsiveFilter} onChange={() => setUnresponsiveFilter(!unresponsiveFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Unresponsive</SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Checkbox checked={withdrewFilter} onChange={() => setWithdrewFilter(!withdrewFilter)} />
                    <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Withdrew</SuiTypography>
                  </SuiBox>     
                </SuiBox>
              )}

            </SuiBox>
          </AccordionDetails>
        </Accordion>

        {/* CATEGORIES */}
        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none", backgroundColor: "inherit" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <SuiTypography variant="body1">Category</SuiTypography>
          </AccordionSummary>

          <AccordionDetails>
            <SuiBox className="skillBox" style={{ "overflowY": "auto" }}>
              <SuiBox>
                <Checkbox checked={engineeringFilter} onChange={() => setEngineeringFilter(!engineeringFilter)}/>
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Engineering</SuiTypography>
              </SuiBox>
              <SuiBox>
                <Checkbox checked={qaFilter} onChange={() => setQaFilter(!qaFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>QA</SuiTypography>
              </SuiBox>
              <SuiBox>
                <Checkbox checked={designFilter} onChange={() => setDesignFilter(!designFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Design</SuiTypography>
              </SuiBox>
              <SuiBox>
                <Checkbox checked={devopsFilter} onChange={() => setDevopsFilter(!devopsFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Devops</SuiTypography>
              </SuiBox>
              <SuiBox>
                <Checkbox checked={dataFilter} onChange={() => setDataFilter(!dataFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Data</SuiTypography>
              </SuiBox>
              <SuiBox>
                <Checkbox checked={productFilter} onChange={() => setProductFilter(!productFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Product</SuiTypography>
              </SuiBox>
              <SuiBox>
                <Checkbox checked={managementFilter} onChange={() => setManagementFilter(!managementFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Management</SuiTypography>
              </SuiBox>
              <SuiBox>
                <Checkbox checked={marketingFilter} onChange={() => setMarketingFilter(!marketingFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Marketing</SuiTypography>
              </SuiBox>
              <SuiBox>
                <Checkbox checked={salesFilter} onChange={() => setSalesFilter(!salesFilter)} />
                <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Sales</SuiTypography>
              </SuiBox>
            </SuiBox>
          </AccordionDetails>
        </Accordion>

        <Divider />

        <SuiBox mt={2} pb={10} display="flex" flexDirection="column">
          <SuiButton sx={{ my: 2 }} fullWidth color="info" variant="gradient" onClick={() => {
            fetchApplications()
            handleCloseConfigurator()
          }}>Search</SuiButton>
          <SuiButton sx={{ my: 2 }} fullWidth color="info" variant="outlined" onClick={() => {
            clearFiltersAndFetchResults()
            handleCloseConfigurator()
          }}>Clear filters</SuiButton>
        </SuiBox>
      </SuiBox>
    </ConfiguratorRoot >
  );
}

// Setting props default values
Configurator.defaultProps = {
  href: null,
  candidateCountryFilter: null,
  setCandidateCountryFilter: null,
  inReviewStateFilter: false,
  setInReviewStateFilter: null,
  activeStateFilter: false,
  setActiveStateFilter: null,
  hiredStateFilter: false,
  setHiredStateFilter: null,
  introStateFilter: false,
  setIntroStateFilter: null,
  interviewStateFilter: false,
  setInterviewStateFilter: null,
  withdrewStateFilter: false,
  setWithdrewStateFilter: null,
  rejectedStateFilter: false,
  setRejectedStateFilter: null,
  engineeringFilter: false,
  setEngineeringFilter: null,
  qaFilter: false,
  setQaFilter: null,
  designFilter: false,
  setDesignFilter: null,
  devopsFilter: false,
  setDevopsFilter: null,
  dataFilter: false,
  setDataFilter: null,
  productFilter: false,
  setProductFilter: null,
  managementFilter: false,
  setManagementFilter: null,
  marketingFilter: false,
  setMarketingFilter: null,
  salesFilter: false,
  setSalesFilter: null,
  betterForAnotherRoleFilter: false,
  setBetterForAnotherRoleFilter: null,
  compensationFilter: false,
  setCompensationFilter: null,
  cultureFitFilter: false,
  setCultureFitFilter: null,
  employmentDurationFilter: false,
  setEmploymentDurationFilter: null,
  englishSkillsFilter: false,
  setEnglishSkillsFilter: null,
  futureOpportunitiesFilter: false,
  setFutureOpportunitiesFilter: null,
  locationFilter: false,
  setLocationFilter: null,
  motivationsFilter: false,
  setMotivationsFilter: null,
  offerDeclinedFilter: false,
  setOfferDeclinedFilter: null,
  overqualifiedFilter: false,
  setOverqualifiedFilter: null,
  positionClosedFilter: false,
  setPositionClosedFilter: null,
  timingFilter: false,
  setTimingFilter: null,
  underqualifiedFilter: false,
  setUnderqualifiedFilter: null,
  unresponsiveFilter: false,
  setUnresponsiveFilter: null,
  withdrewFilter: false,
  setWithdrewFilter: null,
  fetchApplications: null,
};

// typechecking props
Configurator.propTypes = {
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  candidateCountryFilter: PropTypes.number,
  setCandidateCountryFilter: PropTypes.func,
  hiredStateFilter: PropTypes.bool,
  setHiredStateFilter: PropTypes.func,
  inReviewStateFilter: PropTypes.bool,
  setInReviewStateFilter: PropTypes.func,
  activeStateFilter: PropTypes.bool,
  setActiveStateFilter: PropTypes.func,
  introStateFilter: PropTypes.bool,
  setIntroStateFilter: PropTypes.func,
  interviewStateFilter: PropTypes.bool,
  setInterviewStateFilter: PropTypes.func,
  withdrewStateFilter: PropTypes.bool,
  setWithdrewStateFilter: PropTypes.func,
  rejectedStateFilter: PropTypes.bool,
  setRejectedStateFilter: PropTypes.func,
  engineeringFilter: PropTypes.bool,
  setEngineeringFilter: PropTypes.func,
  qaFilter: PropTypes.bool,
  setQaFilter: PropTypes.func,
  designFilter: PropTypes.bool,
  setDesignFilter: PropTypes.func,
  devopsFilter: PropTypes.bool,
  setDevopsFilter: PropTypes.func,
  dataFilter: PropTypes.bool,
  setDataFilter: PropTypes.func,
  productFilter: PropTypes.bool,
  setProductFilter: PropTypes.func,
  managementFilter: PropTypes.bool,
  setManagementFilter: PropTypes.func,
  marketingFilter: PropTypes.bool,
  setMarketingFilter: PropTypes.func,
  salesFilter: PropTypes.bool,
  setSalesFilter: PropTypes.func,
  betterForAnotherRoleFilter: PropTypes.bool,
  setBetterForAnotherRoleFilter: PropTypes.func,
  compensationFilter: PropTypes.bool,
  setCompensationFilter: PropTypes.func,
  cultureFitFilter: PropTypes.bool,
  setCultureFitFilter: PropTypes.func,
  employmentDurationFilter: PropTypes.bool,
  setEmploymentDurationFilter: PropTypes.func,
  englishSkillsFilter: PropTypes.bool,
  setEnglishSkillsFilter: PropTypes.func,
  futureOpportunitiesFilter: PropTypes.bool,
  setFutureOpportunitiesFilter: PropTypes.func,
  locationFilter: PropTypes.bool,
  setLocationFilter: PropTypes.func,
  motivationsFilter: PropTypes.bool,
  setMotivationsFilter: PropTypes.func,
  offerDeclinedFilter: PropTypes.bool,
  setOfferDeclinedFilter: PropTypes.func,
  overqualifiedFilter: PropTypes.bool,
  setOverqualifiedFilter: PropTypes.func,
  positionClosedFilter: PropTypes.bool,
  setPositionClosedFilter: PropTypes.func,
  timingFilter: PropTypes.bool,
  setTimingFilter: PropTypes.func,
  underqualifiedFilter: PropTypes.bool,
  setUnderqualifiedFilter: PropTypes.func,
  unresponsiveFilter: PropTypes.bool,
  setUnresponsiveFilter: PropTypes.func,
  withdrewFilter: PropTypes.bool,
  setWithdrewFilter: PropTypes.func,
  fetchApplications: PropTypes.func,
};

export default Configurator;

