/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// ProductsList page components
import NameAndPictureCell from "layouts/pages/applicationsRecruiter/components/NameAndPictureCell/nameAndPictureCell";
import ActionCell from "layouts/pages/applicationsRecruiter/components/actionCell/actionCell";
import SuiBox from "components/SuiBox";


export default {
  columns: [
    {
      Header: "Candidate",
      accessor: "candidate",
      Cell: ({ value: [name, data, appId] }) => <NameAndPictureCell image={data} name={name} appId={appId} />,
    },
    { Header: "Position", accessor: "position" },
    {
      Header: "Action", accessor: "action",
      Cell: ({ value: data }) => (
        <SuiBox display="flex">
          <ActionCell cvUrl={data.cvUrl} />
          <ActionCell jobId={data.jobId} />
          <ActionCell companyId={data.companyId} />
          <ActionCell candidateId={data.candidateId} />
          <ActionCell applicationId={data.appId} />
          <ActionCell deleteApplication={true} applicationCompanyId={data.companyId} applicationDeleteId={data.appId} />
        </SuiBox>
      ),
    },
  ],

  rows: [],
};
