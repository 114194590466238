/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// import { Link } from "react-router-dom";
// import { capitalize } from 'utils/commonFunctions';
// @mui material components
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery"
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from 'components/SuiAvatar';
// Soft UI Dashboard PRO React example components
import StudentDashboardLayout from "examples/LayoutContainers/StudentDashboardLayout";
// import JobCard from "examples/Cards/JobCard";
// import emptyImg from "assets/images/illustrations/recommended.png";
import mockCompanyAvatar from  "assets/images/mockCompanyAvatar.png"
// import ProfileCompletionModal from "layouts/dashboards/student/profileCompletionModal/ProfileCompletionModal"
import LevelUpView from "layouts/dashboards/student/levelUpView/LevelUpView"
import ShowWelcomeWizard from 'examples/Modal/ShowWelcomeWizard';


function Default() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const sesionStudent = { ...ReactSession.get("student") };
  sesionStudent.favoriteJobs = [];
  const [student, setStudent] = useState(sesionStudent); // eslint-disable-line
  const [showWelcomeWizard, setShowWelcomeWizard] = useState(false);
  const [markLessonCompleted, setMarkLessonCompleted] = useState(0);
  const [progress, setProgress] = useState(0);
  const [currentLesson, setCurrentLesson] = useState(0);
  const [plan, setPlan] = useState(null);
  const [learningAdvisor, setLearningAdvisor] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [courses, setCourses] = useState([]);

  const [videoDisplayed, setVideoDisplayed] = useState(null)
  const [moduleSelected, setModuleSelected] = useState(null)
  const [courseSelected, setCourseSelected] = useState(null)

  const [saveLessons, setSaveLessons] = useState(null);

  useEffect(() => {
    document.title = `Dashboard | Boost`;
  }, []);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_ROUTE}/api/users/getStudent/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line

        if (res.success) {
          setStudent(res.data);
          ReactSession.set("student", res.data);
          ReactSession.set("coaches", res?.data?.coachs);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });

    const lessonsUrl = `${process.env.REACT_APP_API_ROUTE}/api/academy/getSavedLessons/`;
    fetch(lessonsUrl, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line

        if (res.success) {
          setSaveLessons(res.data)
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  useEffect(() => {
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/getUserPlan/`, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success) {

          ReactSession.set("hasCompletedEnglishAssessment", res?.data?.student?.assesment);
          ReactSession.set("assesmentCalification", res?.data?.student?.assesmentCalification);
          ReactSession.set("spokenAssessmentMailSent", res?.data?.student?.spokenAssessmentMailSent);

          /* SET Program Lead DATA */
          if (res?.data?.student?.learningAdvisor) ReactSession.set("learningAdvisor", res.data.student.learningAdvisor);

          const tempCourses = [...res.data.courses]
          // eslint-disable-next-line
          for (const tempCourse of tempCourses) {
            // eslint-disable-next-line
            for (const tempModule of tempCourse.modules) {
              // eslint-disable-next-line
              if (tempModule?.module?.test) {
                const tempTest = tempModule?.test?.test ? tempModule.test.test : tempModule.module.test;
                const prevAnswers = tempModule?.test;
                tempTest.questions = []
                tempTest.score = prevAnswers ? prevAnswers.score : null
                tempTest.moduleId = tempModule.pk
                let loop = 1
                // eslint-disable-next-line
                while (tempTest[`question${loop}`] && tempTest[`question${loop}`].length) {
                  const answers = []
                  let answerLoop = 0;
                  // eslint-disable-next-line
                  for (const answer of tempTest[`answers${loop}`].split('|')) {
                    // eslint-disable-next-line
                    if (answer) {
                      answers.push({
                        "answer": answer,
                        "index": answerLoop,
                      })
                      answerLoop += 1;
                    }
                  }
                  tempTest.questions.push({
                    "question": tempTest[`question${loop}`],
                    "answers": answers,
                    "name": `question${loop}`,
                    "index": loop - 1,
                    "answered": prevAnswers ? prevAnswers[`answer${loop}`] : null,
                    "isCorrect": prevAnswers ? prevAnswers.isCorrectAnswers[loop - 1] : null,
                  })
                  loop += 1;
                }
                tempModule.lessons.push(tempTest)
              }
            }
          }
          setCourses(tempCourses);
          setProgress(res.data.progress);
          if (!plan) {
            setPlan(res.data.plan);
            if (res.data?.student?.learningAdvisor) {
              setLearningAdvisor(res.data.student.learningAdvisor)
            }
            if (res.data?.student?.showWelcomeWizard) {
              setShowWelcomeWizard(true)
            }
          }
          setCurrentLesson(res.data.currentLesson)

          const pathname = window.location.pathname; // eslint-disable-line
          let courseId = null;
          let moduleId = null;
          let lessonId = null;
          if (pathname.includes("courses")) {
            const urlIds = pathname.substr(pathname.indexOf("courses") + 8, pathname.length).split("/");
            if (urlIds.length && urlIds[0] && Number(urlIds[0])) {
              courseId = Number(urlIds[0])
            }
            if (urlIds.length && urlIds[1] && Number(urlIds[1])) {
              moduleId = Number(urlIds[1])
            }
            if (urlIds.length && urlIds[2]) {
              lessonId = urlIds[2] // eslint-disable-line
            }
          }

          if (courseId) {
            let lessonToResume = null;
            let courseToResume = null;
            let moduleToResume = null;

            for (const tempCourse of tempCourses) { // eslint-disable-line
              courseToResume = tempCourse.pk === Number(courseId) ? tempCourse : courseToResume;
              for (const tempModule of tempCourse.modules) { // eslint-disable-line
                moduleToResume = moduleId && tempModule.pk === Number(moduleId) ? tempModule : moduleToResume;
                for (const tempLesson of tempModule.lessons) { // eslint-disable-line
                  if (lessonId && lessonId !== "test" && Number(lessonId) === tempLesson.pk) { // eslint-disable-line
                    lessonToResume = tempLesson;
                    break;
                  }
                }

                if (lessonId && lessonId === "test" && moduleId && 
                  Number(moduleId) === tempModule.pk && 
                  tempModule.lessons[tempModule.lessons.length - 1]?.question1) { // eslint-disable-line
                  lessonToResume = tempModule.lessons[tempModule.lessons.length - 1];
                  break;
                }
              }
            }
            if (courseToResume) {
              setCourseSelected(courseToResume)
            }
            if (moduleToResume) {
              setLessons(moduleToResume.lessons)
              setModuleSelected(moduleToResume)
            }
            if (lessonToResume) {
              setVideoDisplayed(lessonToResume)
            }

          }
        } else {
          setPlan({})
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, [markLessonCompleted]);

  window.addEventListener('popstate', function() { // eslint-disable-line
    const pathname = window.location.pathname; // eslint-disable-line
    let courseId = null;
    let moduleId = null;
    let lessonId = null;
    if (pathname.includes("courses")) {
      const urlIds = pathname.substr(pathname.indexOf("courses") + 8, pathname.length).split("/");
      if (urlIds.length && urlIds[0] && Number(urlIds[0])) {
        courseId = Number(urlIds[0])
      }
      if (urlIds.length && urlIds[1] && Number(urlIds[1])) {
        moduleId = Number(urlIds[1])
      }
      if (urlIds.length && urlIds[2]) {
        lessonId = urlIds[2] // eslint-disable-line
      }
    }

    if (courseId) {
      let lessonToResume = null;
      let courseToResume = null;
      let moduleToResume = null;

      for (const tempCourse of courses) { // eslint-disable-line
        courseToResume = tempCourse.pk === Number(courseId) ? tempCourse : courseToResume;
        for (const tempModule of tempCourse.modules) { // eslint-disable-line
          moduleToResume = moduleId && tempModule.pk === Number(moduleId) ? tempModule : moduleToResume;
          for (const tempLesson of tempModule.lessons) { // eslint-disable-line
            if (lessonId && lessonId !== "test" && Number(lessonId) === tempLesson.pk) { // eslint-disable-line
              lessonToResume = tempLesson;
              break;
            }
          }

          if (lessonId && lessonId === "test" && moduleId && 
            Number(moduleId) === tempModule.pk && 
            tempModule.lessons[tempModule.lessons.length - 1]?.question1) { // eslint-disable-line
            lessonToResume = tempModule.lessons[tempModule.lessons.length - 1];
            break;
          }
        }
      }
      if (courseToResume && courseToResume !== courseSelected) {
        setCourseSelected(courseToResume)
      }
      if (moduleToResume && moduleToResume !== moduleSelected) {
        setLessons(moduleToResume.lessons)
        setModuleSelected(moduleToResume)
      }
      if (lessonToResume && lessonToResume !== videoDisplayed) {
        setVideoDisplayed(lessonToResume)
      }

    }
  }, {once: true});

  const isMobile = useMediaQuery('(max-width:500px)')

  // If the URL contains 'levelup' means the user clicked on that section in the sidenav, so we activate that tab
  // Else we keep track of the last tab the candidate opened in local storage
  // If there's any info there, we open that tab
  // Else we check candidate has any recommended jobs to open tab 0
  // Else we default to tab 1
  const [JobsTab, setJobsTabs] = useState(3)

  const handleChangeTab = tab => {
    setJobsTabs(tab)
    ReactSession.set("candidateDashboardActiveTab", tab)
  }

  const studentPhoto = sesionStudent?.studentCompany?.photo ? `${process.env.REACT_APP_API_ROUTE}${sesionStudent.studentCompany.photo}` : mockCompanyAvatar;

  return (
    <StudentDashboardLayout>

      <SuiBox display="flex" flexDirection={isMobile ? "column-reverse" : "row"} position="relative" >
        {/* Tabs */}
        { JobsTab === 1 ? (
          <AppBar position="relative" sx={{pt: isMobile ? 8 : "auto"}}>
            <Tabs orientation={isMobile ? "vertical" : "horizontal"} value={JobsTab}>
              <SuiBox height="50px" width={isMobile ? "200px" : "10%"} mx={isMobile ? "auto" : "1%"} display="flex" justifyContent="center" alignItems="center" >
                <Tab label="Level Up ✨" onClick={() => handleChangeTab(3)}/>
              </SuiBox>
            </Tabs>
          </AppBar>
        ) : null }
      </SuiBox>
      { showWelcomeWizard ? (
        <ShowWelcomeWizard student={student} setStudent={setStudent} learningAdvisor={learningAdvisor} />
      ) : null }
      <SuiBox py={3}>
        {/* LEVEL UP */}
        {JobsTab === 3 && (
          <SuiBox>
            <SuiBox
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: .5,
                mb: 5
              }}
            >
              <SuiTypography variant="h4"  sx={{ fontWeight: 300 }} ml={1}>Fuel your future; learn relentlessly.</SuiTypography>

              <SuiBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 5
                }}
              >
                <SuiBox height="90%" mt={0.5} mr={2} lineHeight={1}>
                  <SuiTypography variant="h5" fontWeight="medium">
                    Hi {sesionStudent?.first_name},
                  </SuiTypography>
                  <SuiTypography variant="button" color="text" fontWeight="medium">
                    Your courses are {progress}% complete
                  </SuiTypography>
                </SuiBox>

                <SuiAvatar
                  src={studentPhoto}
                  alt="avatar"
                  variant="rounded"
                  size="md"
                  shadow="sm"
                />
              </SuiBox>
            </SuiBox>

            <Grid container spacing={3}>
              <LevelUpView 
                student={sesionStudent} 
                courses={courses} 
                markLessonCompleted={markLessonCompleted} 
                setMarkLessonCompleted={setMarkLessonCompleted} 
                progress={progress} 
                setProgress={setProgress} 
                currentLesson={currentLesson}
                setCurrentLesson={setCurrentLesson}
                lessons={lessons} 
                setLessons={setLessons} 
                plan={plan} 
                token={token} 
                saveLessons={saveLessons}
                setSaveLessons={setSaveLessons}
                videoDisplayed={videoDisplayed}
                setVideoDisplayed={setVideoDisplayed}
                moduleSelected={moduleSelected}
                setModuleSelected={setModuleSelected}
                courseSelected={courseSelected}
                setCourseSelected={setCourseSelected}
              />
            </Grid>
          </SuiBox>
        )}

      </SuiBox>
    </StudentDashboardLayout >
  );
}

export default Default;
