/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @material-ui core components
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import { navbarMobileMenu } from "examples/Navbars/FilterNavbar/styles";
import SuiInput from "components/SuiInput";
// import Checkbox from "@mui/material/Checkbox";
// Soft UI Dashboard PRO React context
import { useSoftUIController, setOpenTalentFinder } from "context";
import CreateCandidate from "examples/Modal/CreateCandidate";
import Density from "examples/Navbars/FilterNavbar/density";

function DashboardNavbar({ isTalent, title, filters, setFilters, search, setSearch, method, setMethod, seniority, setSeniority }) {

  const [controller, dispatch] = useSoftUIController();

  // const { openConfigurator } = controller;
  const { openTalentFinder } = controller;

  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleTalentFinderOpen = () => setOpenTalentFinder(dispatch, !openTalentFinder);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  // const handleCloseMenu = () => setOpenMenu(false);

  // function setKeywordFilter(value) {
  //   const filtersCopy = { ...filters };
  //   filtersCopy.searchKeyWords = value;
  //   setFilters(filtersCopy);
  // }

  function funtionSearchKeywords() {
    const filtersCopy = { ...filters };
    if ($("input[name=searchInCv]").val().length) {
      filtersCopy.page = 1;
      filtersCopy.keywordsSearch = true;
      setFilters(filtersCopy);
      setSearch(search + 1);
    }
  }

  function openConfiguratorFunction() {
    $(".wrapperBox").parent().css("right", 0).css("width", "360px");
  }


  return (
    <SuiBox container xs={12} md={12} display="flex" flexWrap="wrap" width="100%" alignItems="right" justifyContent={{ sx: "start", xl: "space-between" }} my={2}>
      <SuiTypography variant="h3" fontWeight="bold" mr={5}>
        {title}
      </SuiTypography>
      {isTalent ? (
        <SuiBox container sx={{ width: "40%" }} className="talentRight talentInput">
          <SuiInput
            type="text"
            placeholder="General CV search. Add keywords separated by comma"
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
            className="searchInCv"
            name="searchInCv"
            sx={{ cursor: "pointer", top: { xs: "1rem", lg: "initial" }, display: { lg: "none" }, navbarMobileMenu }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                funtionSearchKeywords();
              }
            }}
          >
            Search
          </SuiInput>
          <SuiBox sx={{ float: "right", marginRight: "10px" }}>
            <Density method={method} setMethod={setMethod} seniority={seniority} setSeniority={setSeniority} />
            <span className="density" style={{ display: "none" }}>0</span>
            <span className="seniority" style={{ display: "none" }}>0</span>
          </SuiBox>
        </SuiBox>
      ) : null }
      {isTalent ? (
        <SuiBox className="talentRight">
          <SuiButton
            size="medium"
            color="info"
            variant="gradient"
            onClick={funtionSearchKeywords}
            sx={{
              navbarMobileMenu,
            }}
            circular
            style={{ marginRight: "5px" }}
          >
            Search
          </SuiButton>
          <SuiButton
            size="medium"
            color="info"
            variant="gradient"
            sx={{
              navbarMobileMenu,
            }}
            onClick={handleTalentFinderOpen}
            circular
          >
            <Icon>menu</Icon>
            Filters
          </SuiButton>
          <CreateCandidate text="test" />
        </SuiBox>
      ) : (
        <SuiButton
          size="medium"
          color="info"
          variant="gradient"
          sx={{
            navbarMobileMenu,
          }}
          onClick={openConfiguratorFunction}
          circular
        >
          <Icon>
            menu
          </Icon>
          Filters
        </SuiButton>
      )}
    </SuiBox >
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  isTalent: false,
  filters: {},
  setFilters: null,
  search: 0,
  setSearch: null,
  method: 1,
  setMethod: null,
  seniority: 1,
  setSeniority: null,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  isTalent: PropTypes.bool,
  title: PropTypes.string.isRequired,
  filters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  setFilters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  search: PropTypes.number,
  setSearch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  method: PropTypes.number,
  setMethod: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  seniority: PropTypes.number, 
  setSeniority: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default DashboardNavbar;
