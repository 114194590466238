/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types"
import { useState, useEffect } from "react"
import { ReactSession } from 'react-client-session'
// @mui material components
import Grid from "@mui/material/Grid"
// sui components
import SuiSelect from "components/SuiSelect"
import SuiTagInput from "components/SuiTagInput"
import SuiButton from "components/SuiButton"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"

function Interests({ formData, setActiveStep, activeStep, updateUser }) {
  ReactSession.setStoreType("localStorage")
  const { values } = formData

  let techsString = ""
  let techsSelectedStrings = []
  let timerid2 = ""

  const [techs, settechs] = useState([])
  const [techsSelected, setTechsSelected] = useState([])

  function changeTechPlaceholder(remove, box) {
    if (timerid2) {
      clearTimeout(timerid2);
    }
    timerid2 = setTimeout(() => {
      if (remove) {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "");
      } else {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "Techs.");
      }
    }, 100);
  }

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateIndustries = async () => {
    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newTechData = [];
    // eslint-disable-next-line
    for (let i = 0; i < interests?.data?.industries?.length; i++) {
      newTechData.push({ value: interests?.data?.industries[i]?.name, label: interests?.data?.industries[i]?.name });
    }

    $(".techsBox").attr("data-techs", JSON.stringify(interests?.data?.industries));

    settechs(newTechData);
  }

  useEffect(() => { populateIndustries() }, []);

  function notInArray(selectStr, isTech) {
    const commitmentsSelected = [];
    let tempArray = commitmentsSelected;
    if (isTech) {
      tempArray = techsSelected;
    }
    // eslint-disable-next-line
    for (const tempItem of tempArray) {
      if (tempItem.includes(selectStr)) {
        return false;
      }
    }

    return true;
  }

  function addtech() {
    techsString = $("input[name=techs]").val();
    if (techsString && notInArray(techsString, true)) {
      techsSelectedStrings = [...techsSelected];
      techsSelectedStrings.push(techsString);
      setTechsSelected(techsSelectedStrings);
      changeTechPlaceholder(1, "techsBox");
    }
  }

  function removeTag(e) {
    setTechsSelected(e)
  }

  return (
    <SuiBox>
      <SuiBox width="100%" textAlign="center" mb={4} mt={4}>
        <SuiTypography variant="h5" fontWeight="regular">Select some industries that apply for the company</SuiTypography>
      </SuiBox>
      <SuiBox mt={2}>
        <Grid container xs={12} md={12} spacing={1}>
          <Grid item xs={8} mb={2}>
            <SuiSelect
              placeholder="Select industry"
              options={techs}
              name="techs"
            />
          </Grid>
          <Grid item xs={4}>
            <SuiButton color="info" onClick={addtech} fullWidth>Add</SuiButton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} className="techsBox">
          <SuiTagInput
            placeholder="Your industries"
            tags={techsSelected}
            onChange={e => removeTag(e)}
          />
        </Grid>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" onClick={() => setActiveStep(activeStep-1)} isSubmitting className="changeStep">Back</SuiButton>
        <SuiButton variant="gradient" color="info" onClick={() => updateUser(values)} className="changeStep nextStep" isSubmitting>Create</SuiButton>
      </SuiBox>

    </SuiBox>
  )
}

Interests.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.isRequired,
  activeStep: PropTypes.isRequired,
  updateUser: PropTypes.func.isRequired
}

export default Interests
