/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router components
import { Link } from "react-router-dom"
// prop-types is library for typechecking of props
import PropTypes from "prop-types"
// @mui material components
import Card from "@mui/material/Card"
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import SuiAvatar from "components/SuiAvatar"
import SuiBadge from "components/SuiBadge"
// Placeholder avatars
import cat0Eng from "examples/Cards/JobCard/placeholderAvatars/cat_0.png"
import cat1QA from "examples/Cards/JobCard/placeholderAvatars/cat_1.png"
import cat2Des from "examples/Cards/JobCard/placeholderAvatars/cat_2.png"
import cat3Devo from "examples/Cards/JobCard/placeholderAvatars/cat_3.png"
import cat4Data from "examples/Cards/JobCard/placeholderAvatars/cat_4.png"
import cat5Prod from "examples/Cards/JobCard/placeholderAvatars/cat_5.png"
import cat6Man from "examples/Cards/JobCard/placeholderAvatars/cat_6.png"
import cat7Mark from "examples/Cards/JobCard/placeholderAvatars/cat_7.png"
import { sliceString } from "utils/commonFunctions"

function AnnouncementCard({ by, badge, title, description, skills, value, action, category, companyPhoto, subtitle, salaryBasedOnExperience, job }) {

  console.log('job', job)

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  function numberFormat(number) {
    return number.toLocaleString('en');
  }

  // eslint-disable-next-line
  const placeHolderAvatar = () => {
    if (!companyPhoto) {
      if (category === "Engineering" || category === "eng") return cat0Eng
      if (category === "qa" || category === "QA") return cat1QA
      if (category === "des" || category === "Design") return cat2Des
      if (category === "dev" || category === "DevOps") return cat3Devo
      if (category === "dat" || category === "Data") return cat4Data
      if (category === "prod" || category === "Product") return cat5Prod
      if (category === "man" || category === "Management") return cat6Man
      return cat7Mark
    }
  }

  const formatSubtitle = sub => {
    if (sub.length < 90) return sub
    return `${sub.substring(0, 90)} ...`
  }

  const returnSalary = () => {
    if (job && job?.commitment?.name === 'Hourly') return "Hourly Rate"
    if (value.method === "Month") return "Monthly Salary"
    return `/${value.method}`
  }
  
  return (
    <Card>
      <SuiBox display="flex" justifyContent="space-between" alignItems="flex-start" p={2}>
        {by.image || by.name || by.date ? (
          <SuiBox display="flex" mr={2}>
            <SuiAvatar src={companyPhoto ? process.env.REACT_APP_API_ROUTE + companyPhoto : placeHolderAvatar()} alt="company avatar" />
            <SuiBox mx={1.5} display="flex" flexDirection="column" justifyContent="center">
              <SuiTypography component="a" href={action.route} variant="h5" fontWeight="regular">{title}</SuiTypography>
              {subtitle && <SuiTypography variant="caption" color="text">{formatSubtitle(subtitle)}</SuiTypography>}
            </SuiBox>
          </SuiBox>
        ) : null}

        {badge.color && badge.label ? (
          <SuiBadge color={badge.color} badgeContent={badge.label} size="sm" container />
        ) : null}
      </SuiBox>

      <SuiBox px={1.5} py={1} minHeight="10rem" display="flex" flexDirection="column" justifyContent="space-between">
        <SuiBox px={3} mt={1} mb={2} lineHeight={0} >
          <SuiTypography variant="body2" fontWeight="regular" color="text">
            <div dangerouslySetInnerHTML={{ __html: sliceString(description, 430) }} />
          </SuiTypography>
        </SuiBox>
        
        <SuiBox>
          <SuiBox>
            {skills.skill1 ? (
              <SuiBadge badgeContent={skills.skill1} color="secondary" variant="contained" circular="true" size="lg" />
            ) : null}
            {skills.skill2 ? (
              <SuiBadge badgeContent={skills.skill2} color="secondary" variant="contained" circular="true" size="lg" />
            ) : null}
            {skills.skill3 ? (
              <SuiBadge badgeContent={skills.skill3} color="secondary" variant="contained" circular="true" size="lg" />
            ) : null}
          </SuiBox>

          {/* =========== SALARY ========== */}
          <SuiBox
            bgColor="grey-100"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="lg"
            px={1}
            py={1.25}
            mt={2}
          >
            {salaryBasedOnExperience ?
              <SuiTypography
                display="flex"
                variant="h4"
                justifyContent="space-between"
                alignItems="center"
                height="auto"
                ml={1.5}
              >
                <SuiTypography
                  variant="button"
                  color="secondary"
                  fontWeight="medium"
                  verticalAlign="text-bottom"
                  style={{ fontSize: "16px", display: "block" }}
                >
                    Salary based on experience
                </SuiTypography>
              </SuiTypography>
            :
              <>
                {value.from ? (
                  <SuiBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={isMobile ? "column" : "row"}
                  >
                    {value.method && (
                        <SuiTypography
                          variant="button"
                          color="secondary"
                          fontWeight="medium"
                          style={{ fontSize: "16px" }}
                          mx={isMobile ? .5 : 1.5}
                        >
                          {returnSalary()}
                        </SuiTypography>
                    )}

                    <SuiTypography style={{ fontSize: "16px" }} fontWeight="bold">
                      {!job?.includeMaxSalary ?
                        <>{value.type} {numberFormat(value.from)}+</>
                        :
                        <>{value.type} {numberFormat(value.from)} - {numberFormat(value.to)}</>
                      }
                    </SuiTypography>
                  </SuiBox>
                ) : (
                  <SuiBox />
                )}
              </>
            }
            
            {action.type === "internal" ? (
              <SuiButton
                component={Link}
                to={action.route}
                color="info"
                variant="outlined"
              >
                {action.label}
              </SuiButton>
            ) : (
              <SuiButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                color="dark"
              >
                {action.label}
              </SuiButton>
            )}
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card >
  );
}

// Setting default values for the props of AnnouncementCard
AnnouncementCard.defaultProps = {
  by: {},
  badge: {},
  skills: {},
  value: {},
  companyPhoto: "",
  subtitle: "",
  salaryBasedOnExperience: false,
  job: {},
};

// Typechecking props for the AnnouncementCard
AnnouncementCard.propTypes = {
  by: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
  }),
  badge: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    label: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  skills: PropTypes.shape({
    skill1: PropTypes.string,
    skill2: PropTypes.string,
    skill3: PropTypes.string,
  }),
  value: PropTypes.shape({
    type: PropTypes.string,
    from: PropTypes.number,
    to: PropTypes.number,
    method: PropTypes.string,
  }),
  action: PropTypes.shape({
    type: PropTypes.oneOf(["enternal", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  category: PropTypes.string.isRequired,
  companyPhoto: PropTypes.string,
  subtitle: PropTypes.string,
  salaryBasedOnExperience: PropTypes.bool,
  job: PropTypes.object // eslint-disable-line
};

export default AnnouncementCard;
