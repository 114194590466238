/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types' // eslint-disable-line
import { ReactSession } from 'react-client-session'
import { useEffect, useState } from 'react'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiAlert from 'components/SuiAlert'
// MUI components
import CircularProgress from '@mui/material/CircularProgress'

function StripeSuccess() {

    ReactSession.setStoreType('localStorage')
    const token = ReactSession.get('token')
    const coursePaymentData = ReactSession.get('coursePaymentData')

    const [showSpinner, setShowSpinner] = useState(true)
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    const confirmBuy = () => {
        if (!coursePaymentData?.id) {
            setShowError(true)
            setShowSpinner(false)
            setTimeout(() => setShowError(false), 2000)
            setTimeout(() => { window.location.href = '/t/dashboard/levelUp/levelUp' }, 2000)
        }

        else {
            fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/confirmBuy/`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({ id: coursePaymentData?.id })
            })
            .then(async response => {
                const data = await response.json()
                ReactSession.set('coursePaymentData', null)
                
                if (data.success) {
                    setShowSpinner(false)
                    setShowSuccess(true)
                    setTimeout(() => { window.location.href = '/t/dashboard/levelUp' }, 2000)
                }
                else {
                    setShowError(true)
                    setShowSpinner(false)
                    setTimeout(() => setShowError(false), 2000)
                    setTimeout(() => { window.location.href = '/t/dashboard/levelUp' }, 2000)
                }
            })
            .catch(error => {
                setShowError(true)
                setShowSpinner(false)
                console.error('There was an error!', error)
                setTimeout(() => setShowError(false), 2000)
                setTimeout(() => { window.location.href = '/t/dashboard/levelUp' }, 2000)
            })
        }
    }

    useEffect(() => confirmBuy(), [])

    return (
        <SuiBox mt={5} display='flex' justifyContent='center' alignItems='center'>
            {showError && <SuiAlert color='error'>There was an error, please try again later.</SuiAlert>}

            {showSuccess && <SuiAlert color='success'>Your order has been processed successfully, thank you for choosing TECLA.</SuiAlert>}

            {showSpinner && <SuiBox my='20%' mx='auto'><CircularProgress size={40}/></SuiBox>}
        </SuiBox>
    )
}

// Typechecking props for the StripeSuccess
StripeSuccess.propTypes = {
}
  
export default StripeSuccess