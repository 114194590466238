/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-pdf library docs: https://github.com/wojtekmaj/react-pdf
import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import SuiBox from 'components/SuiBox'
import useMediaQuery from '@mui/material/useMediaQuery'

// eslint-disable-next-line
export default function PdfViewer({ cvUrl }) {
    
    // PDFjs worker from an external cdn  
    pdfjs.GlobalWorkerOptions.workerSrc =  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    
    const [pagesArr, setPagesArr] = useState([])

    function onDocumentLoadSuccess({ numPages }) {
        const arr = []
        for (let i = 1; i <= numPages; i++) arr.push(i)  // eslint-disable-line
        setPagesArr(arr)
    }

    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')
    const isLaptop = useMediaQuery('(max-width:1900px)')

    const returnPageContainerWidth = () => {
        if (isMobile) return window.innerWidth/1.2
        if (isLaptop) return window.innerWidth/2.5
        return window.innerWidth/2
    }
    
    return (
        <SuiBox
            pt={0}
            px={.5}
            mb={2}
            height='100vh'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            style={{ backgroundColor: '#525758' }}
        >
            <div className='main' style={{ maxHeight: '90vh' }}>
                <Document
                    file={cvUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    error=''
                    loading={<p style={{ color: 'white' }}>Loading...</p>}
                >
                        {pagesArr.length && pagesArr.map(pageNum => 
                            <SuiBox sx={{ mb: 2 }}>
                                <Page
                                    key={pageNum}
                                    pageNumber={pageNum}
                                    width={returnPageContainerWidth()}
                                />
                            </SuiBox>
                        )}
                </Document>
            </div>
        </SuiBox>
    )
}