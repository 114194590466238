/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
// @mui material components
import Slider from '@mui/material/Slider';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import Tooltip from "@mui/material/Tooltip";

import CheckBoxIcon from '@mui/icons-material/CheckBox';


function SkillSlider({ name, percent, color, candidate, setCandidate, setSkillUpdated }) {
    ReactSession.setStoreType("localStorage");
    const user = ReactSession.get("user");
    const isRecruiter = (user && (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin"));

    const handleChange = newVal => {
        if (candidate && candidate.user) {
            const tempCandidate = {...candidate}
            tempCandidate.englishLevel = newVal;
            setSkillUpdated(true)
            setCandidate(tempCandidate)
        }
    }

    return (
        <SuiBox key={name} component="li" display="flex" alignItems="center">
            <SuiBox
                component="ul"
                display="flex"
                flexDirection="column"
                width="100%"
                sx={{ listStyle: "none" }}
            >
                <SuiBox component="li" w="100%" py={0.25}>
                    <SuiBox display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
                        <SuiBox>
                            <SuiBadge badgeContent={name} color={color} variant="contained" circular size="sm" container />
                            { candidate.englishLevelValidated && isRecruiter ? (
                                <Tooltip title={`Validated by ${candidate.englishValidator.user.first_name} ${candidate.englishValidator.user.last_name}`} placement="top">
                                  <CheckBoxIcon fontSize="small" color="success" sx={{ verticalAlign: "middle", marginLeft: "5px" }} />
                                </Tooltip>
                            ) : null }
                            { candidate.englishLevelValidated && !isRecruiter ? (
                                <Tooltip title="Validated by our team. To change this please request an interview" placement="top">
                                  <CheckBoxIcon fontSize="small" color="success" sx={{ verticalAlign: "middle", marginLeft: "5px" }} />
                                </Tooltip>
                            ) : null }
                        </SuiBox>

                        <SuiTypography
                            variant="button"
                            fontWeight="medium"
                            color="text"
                            textTransform="capitalize"
                        >
                            {percent}
                        </SuiTypography>
                    </SuiBox>

                    <Slider
                        defaultValue={candidate?.englishLevel ? candidate?.englishLevel : 1}
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={10}
                        onChange={e => handleChange(e.target.value)}
                    />
                </SuiBox>
            </SuiBox>
        </SuiBox>
    );
}

SkillSlider.defaultProps = {
  candidate: {},
  setCandidate: null,
  setSkillUpdated: null,
};

// Typechecking props for the SkillSlider
SkillSlider.propTypes = {
    name: PropTypes.string.isRequired,
    percent: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    candidate: PropTypes.arrayOf(PropTypes.object),
    setCandidate: PropTypes.func,
    setSkillUpdated: PropTypes.func,
};

export default SkillSlider;
