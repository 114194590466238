/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { sliceString } from "utils/commonFunctions"
// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
// Soft UI Dashboard PRO React example components
import breakpoints from 'assets/theme/base/breakpoints'
import RecruiterDashboardLayout from 'examples/LayoutContainers/RecruiterDashboardLayout'
import DashboardNavbar from 'examples/Navbars/FilterNavbar'
import DataTable from "examples/Tables/DataTable"
// Data
import JobFeedbackTableData from 'layouts/pages/jobFeedback/data/jobFeedbackTableData'
import JobFeedbackTableDataSmall from 'layouts/pages/jobFeedback/data/jobFeedbackTableDataSmall'

function JobFeedback() {
  ReactSession.setStoreType('localStorage')
  const token = ReactSession.get('token')

  const { jobId } = useParams()

  const [isFetchingData, setIsFetchingData] = useState(false)

  const jobFeedbackStatesTables = { 'jobFeedback': JobFeedbackTableData }
  const [jobFeedbackStates, setJobFeedbackStates] = useState(jobFeedbackStatesTables)

  const smallJobFeedbackStatesTables = { 'jobFeedback': JobFeedbackTableDataSmall }
  const [smallJobFeedbackStates, setSmallJobFeedbackStates] = useState(smallJobFeedbackStatesTables)

  const formatFeedbackReason = reason => {
    if (reason === 'non-competitive-salary') return 'Non competitive salary'
    if (reason === 'missmatching-skills') return 'Mismatching skills'
    if (reason === 'responsibilities-not-challenging') return 'Job responsibilities not challenging enough'
    if (reason === 'company-description-interests') return 'Company description doesn’t match my interests'
    if (reason === 'company-beneficts-compelling') return 'Company benefits are not compelling'
    return 'Other'
  }

  const fetchApplications = () => {
    setIsFetchingData(true)
    
    const buildRows = feedbackArr => {

      const rows = []

      if (feedbackArr.length > 0) {
        feedbackArr.forEach(feedback => {

          const candidateName = feedback?.candidate?.user?.first_name && feedback?.candidate?.user?.last_name ?
            `${feedback?.candidate?.user?.first_name} ${feedback?.candidate?.user?.last_name}`
            :
            "Guest"

          const feedbackObj = {
            feedbackId: feedback?.id,
            candidate: [candidateName, feedback?.candidate?.user?.photo, feedback.id],
            position: `${feedback?.job?.name} - ${feedback?.job?.company?.name}`,
            company: [feedback?.job?.company?.name, feedback?.job?.company?.photo, feedback?.job?.company?.id],
            date: feedback?.createdAt,
            feedback: [formatFeedbackReason(feedback?.reason), feedback],
            notes: [sliceString(feedback?.text, 100), feedback]
          }

          rows.push(feedbackObj)
        })
      }

      return rows
    }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/${jobId}/getJobFeedback/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
      .then(async response => {
        const res = await response.json()

        if (res.success) {
          /* FEEDBACK RECORDS */
          const tempJobFeedbackStates = {...jobFeedbackStates}
          tempJobFeedbackStates.jobFeedback.rows = buildRows(res.data)          
          setJobFeedbackStates(tempJobFeedbackStates)

          const tempSmallJobFeedbackStates  = {...smallJobFeedbackStates}
          tempSmallJobFeedbackStates.jobFeedback.rows = buildRows(res.data)
          setSmallJobFeedbackStates(tempSmallJobFeedbackStates)

          setIsFetchingData(false)
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => { fetchApplications() }, [])

  const [tabsOrientation, setTabsOrientation] = useState("horizontal")

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal")
    }

    window.addEventListener("resize", handleTabsOrientation)

    handleTabsOrientation()

    return () => window.removeEventListener("resize", handleTabsOrientation)

  }, [tabsOrientation])

  return (
    <RecruiterDashboardLayout>
      <DashboardNavbar title="Job feedback" />
      <SuiTypography variant="button" fontWeight="regular" color="text">
        Here you can see all the feedback left by candidates for this position
      </SuiTypography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ minHeight: "65vh" }}>
            {isFetchingData ?
                <SuiBox display="flex" justifyContent="center" alignItems="center" my={30} >
                  <CircularProgress size={40} />
                </SuiBox>
              :
              <>                
                <SuiBox p={2} mt={1} width="100%" height="100%" minHeight="50vh">
                  <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <DataTable entriesPerPage={{ defaultValue: 100 }} table={jobFeedbackStatesTables.jobFeedback} view="feedbacks" />
                  </SuiBox>

                  <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
                    <DataTable entriesPerPage={{ defaultValue: 100 }} table={smallJobFeedbackStatesTables.jobFeedback} view="feedbacks" />
                  </SuiBox>
                </SuiBox>
              </>
            }
          </Card >

        </Grid>
      </Grid>
    </RecruiterDashboardLayout>
  );
}

export default JobFeedback;
