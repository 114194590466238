/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react'
import { ReactSession } from 'react-client-session'
// @mui material components
import useMediaQuery from '@mui/material/useMediaQuery'
// import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
// import Divider from '@mui/material/Divider'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
// import SuiAvatar from 'components/SuiAvatar'
// import CircularProgressWithLabel from 'components/circularProgressWithLabel/CircularProgressWithLabel'
// Soft UI Dashboard PRO React example components
import BaseLayout from 'layouts/pages/account/components/BaseLayout'
import StudentDashboardLayout from 'examples/LayoutContainers/StudentDashboardLayout'

function Tests() {

  const token = ReactSession.get('token')
  const student = ReactSession.get('student')

  const [isFetchingData, setIsFetchingData] = useState(true)
  const [candidateTests, setCandidateTests] = useState([])

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const fetchTests = async () => {
      try {
          const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/getAllModuleTests/`, {
              headers: { 'Authorization': `Token ${token}` }
          })
          const data = await res.json()
          
          setIsFetchingData(false)
          if (data.success) setCandidateTests(data.data)
          
      } catch (error) {
          console.error('There was an error!', error)
      }
  }
  
  useEffect(() => { fetchTests() }, [])

  const redirectToLesson = (course, lesson) => {
    if (student) {
        window.location.replace(`/dashboard/courses/${course.courseId}/${lesson.moduleId}/test`);
    } else {
        window.location.replace(`/t/dashboard/courses/${course.courseId}/${lesson.moduleId}/test`);
    } 
  }

  const setLayout = children => {
    if (student) return <StudentDashboardLayout>{children}</StudentDashboardLayout>
    return <BaseLayout>{children}</BaseLayout>
  }

  return (
    <>
      {setLayout(
        <>
            <SuiTypography variant='h3' fontWeight='bold' mb={5} mt={0}>Quizzes</SuiTypography>

            {/* LESSON */}
            {isFetchingData ?
                <SuiBox mt={3} sx={{ height: '80vh' }} display='flex' justifyContent='center' alignItems='center'>
                    <CircularProgress />
                </SuiBox>
                :
                <>
                    {candidateTests.length === 0 ?
                        <SuiTypography variant='h4' fontWeight='regular' color='secondary' mt={30} textAlign='center'>
                            You don&apos;t have any tests yet.
                        </SuiTypography>
                    :
                      <Accordion className="customAccordion" key="quizzes" TransitionProps={{ unmountOnExit: false }} defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          sx={{
                            margin: "0 !important"
                          }}
                        >
                          <SuiBox
                            sx={{
                              display: 'flex',
                              width: '100%',
                              flexDirection: isMobile ? 'column' : 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            {/* TITLE */}
                            <SuiBox display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-start' p={isMobile ? 0: 2} sx={{ maxWidth: "calc(100% - 150px)" }}>
                              <SuiBox display='flex' justifyContent='center' alignItems='center'>
                                <SuiBox display='flex' flexDirection='column' ml={1.5}>
                                  <SuiTypography variant='h5'>Quizzes</SuiTypography>
                                </SuiBox>
                              </SuiBox>
                            </SuiBox>
                          </SuiBox>
                        </AccordionSummary>
                        
                        <AccordionDetails sx={{ width: '100%', mx: 'auto', padding: "0" }}>
                          {candidateTests.map(course => { // eslint-disable-line
                            return (<>
                            {course.tests.map(lesson => { // eslint-disable-line
                              return (
                                <Accordion key={lesson.pk} TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: 'none' }}>
                                  <AccordionSummary
                                    sx={{
                                      my: 0,
                                      cursor: 'pointer',
                                      border: '1px solid rgba(0, 0, 0, .125)',
                                    }}
                                  >
                                    <SuiBox
                                      sx={{
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: isMobile ? 'column' : 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        position: 'relative'
                                      }}
                                    >
                                      {/* TITLE */}
                                      <SuiBox display='block' flexDirection='column' justifyContent='space-between' alignItems='flex-start' p={isMobile ? 0 : 2} sx={{ width: "100%" }}  onClick={() => redirectToLesson(course, lesson)}>
                                        <SuiTypography variant='h6'>
                                          {lesson?.score >= 1 ? (
                                            <CheckCircleIcon sx={{ mr: 1, verticalAlign: "middle", color: "#008a2f" }} />
                                          ) : (
                                            <RadioButtonUncheckedIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                                          )}
                                          <SuiTypography sx={{ display: "inline-block !important", verticalAlign: "middle", fontSize: "1rem", fontWeight: "500" }}>
                                            {lesson?.name}
                                          </SuiTypography>
                                          {lesson?.score ?
                                            <SuiTypography sx={{ float: "right", fontSize: "14px", marginTop: "3px", fontWeight: "500" }}>Score: {lesson?.score}</SuiTypography>
                                          : null }


                                        </SuiTypography>
                                      </SuiBox>
                                    </SuiBox>
                                  </AccordionSummary>
                                </Accordion>
                              )
                            })}
                            </>)
                          })}


                        </AccordionDetails>
                      </Accordion>

                    }
                </>
            }
        </>
      )}
    </>
  )
}

export default Tests
