/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session'
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";


function MarkHiredModal({ showMarkHiredModal,
                          setShowMarkHiredModal,
                          candidateId,
                          isHired,
                          candidateName,
                          // We use following props to avoid calling API everytime the checkbox is clicked
                          hiredMarkHasBeenChanged,
                          setHiredMarkHasBeenChanged,
                          frontHiredMark,
                          setFrontHiredMark
                        }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  const [showError, setShowError] = useState(false)

  const prevFrontHiredMark = frontHiredMark

  const toggleHiredMark = () => {
    setShowSpinner(true)

    let requestBody
    // If we're working with backend data, use isHired prop
    if (hiredMarkHasBeenChanged === undefined) requestBody = { hired: isHired ? 0 : 1 }
    // If we're working with frontend data, use frontHiredMark prop
    else requestBody = { hired: frontHiredMark ? 0 : 1 }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateId}/setHired/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(requestBody)
    })
    .then(async response => {
      const data = await response.json()

      if (data.success) {
        setShowSpinner(false)
        setShowDoneIcon(true)

        setTimeout(() => {
          setShowMarkHiredModal(false)
          setShowDoneIcon(false)
          if (hiredMarkHasBeenChanged === undefined) window.location.reload()
          else {
            setHiredMarkHasBeenChanged(true)
            setFrontHiredMark(!frontHiredMark)
          }
        }, 2000)
      }
      else {
        setShowError(true)
        setShowSpinner(false)
        
        setTimeout(() => setShowError(false), 2000)
      }
    })
    .catch(error => {
      setShowError(true)
      console.error('There was an error!', error)
    })
  }

  return (
    <Modal
      open={showMarkHiredModal}
      onClose={() => setShowMarkHiredModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <SuiBox sx={{
        width: "650px",
        height: "auto",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        borderRadius: { xs: 0, md: 4 },
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }} >

        {showDoneIcon && hiredMarkHasBeenChanged && (
          !prevFrontHiredMark ? <SuiAlert color="info">Marked as hired!</SuiAlert> : <SuiAlert color="info">Hired mark removed!</SuiAlert>
        )}

        {showDoneIcon && !hiredMarkHasBeenChanged && (
          !isHired ? <SuiAlert color="info">Marked as hired!</SuiAlert> : <SuiAlert color="info">Hired mark removed!</SuiAlert>
        )}

        {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

        {hiredMarkHasBeenChanged ? ( // eslint-disable-line
          !prevFrontHiredMark ? (
            <>
              <SuiTypography sx={{ my: 2.5 }} variant="h4">Mark {candidateName} as hired?</SuiTypography>
              <SuiTypography sx={{ my: 2.5 }} variant="body2">Are you sure to mark this candidate as hired?</SuiTypography>
            </>
          ) : (
            <>
              <SuiTypography sx={{ my: 2.5 }} variant="h4">Remove hired mark from {candidateName}?</SuiTypography>
              <SuiTypography sx={{ my: 2.5 }} variant="body2">Are you sure to remove the hired mark from this candidate?</SuiTypography>
            </>
          )
        ) : (
          !isHired ? (
            <>
              <SuiTypography sx={{ my: 2.5 }} variant="h4">Mark {candidateName} as hired?</SuiTypography>
              <SuiTypography sx={{ my: 2.5 }} variant="body2">Are you sure to mark this candidate as hired?</SuiTypography>
            </>
          ) : (
            <>
              <SuiTypography sx={{ my: 2.5 }} variant="h4">Remove hired mark from {candidateName}?</SuiTypography>
              <SuiTypography sx={{ my: 2.5 }} variant="body2">Are you sure to remove the hired mark from this candidate?</SuiTypography>
            </>
          )
        )}
        
        <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
            <SuiButton
              sx={{ mx: 1 }}
              fullWidth
              color="info"
              variant="outlined"
              disabled={showSpinner || showDoneIcon}
              onClick={() => setShowMarkHiredModal(false)}
            >
              Cancel
            </SuiButton>

            {/* eslint-disable-next-line */}
            {showSpinner ?
                <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                :
                showDoneIcon ?
                    <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                    :
                    <SuiButton
                      sx={{ mx: 1 }}
                      fullWidth
                      disabled={showSpinner || showDoneIcon}
                      color="info"
                      variant="gradient"
                      onClick={() => toggleHiredMark()}
                    >
                      Confirm
                    </SuiButton>
            }
        </SuiBox>
      </SuiBox>
    </Modal>
  )
}

MarkHiredModal.defaultProps = {
  hiredMarkHasBeenChanged: undefined,
  setHiredMarkHasBeenChanged: undefined,
  frontHiredMark: undefined,
  setFrontHiredMark: undefined
}

// Typechecking props for the MarkHiredModal
MarkHiredModal.propTypes = {
  showMarkHiredModal: PropTypes.bool.isRequired,
  setShowMarkHiredModal: PropTypes.func.isRequired,
  candidateId: PropTypes.number.isRequired,
  isHired: PropTypes.bool.isRequired,
  candidateName: PropTypes.string.isRequired,
  hiredMarkHasBeenChanged: PropTypes.bool,
  setHiredMarkHasBeenChanged: PropTypes.bool,
  frontHiredMark: PropTypes.bool,
  setFrontHiredMark: PropTypes.bool,
};
  

export default MarkHiredModal