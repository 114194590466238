/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from "react-client-session"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { capitalize, formatDate } from "utils/commonFunctions"
// MUI components
import useMediaQuery from "@mui/material/useMediaQuery"
import Card from "@mui/material/Card"
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiBadge from "components/SuiBadge"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import DataTable from "examples/Tables/DataTable"
import recruitersSourcingTableData from "layouts/recruiterSourcingContent/data/recruitersSourcingTableData"
import recruitersSourcingTableDataSmall from "layouts/recruiterSourcingContent/data/recruitersSourcingTableDataSmall"
import ActionCell from "layouts/recruiterSourcingContent/ActionCell/ActionCell"

function RecruiterSourcingContent() {

    const isMobile = useMediaQuery('(max-width:500px)')

    ReactSession.setStoreType("localStorage")
    const token = ReactSession.get("token")

    const { id } = useParams()

    const [folderContent, setFolderContent] = useState(null)
    const [candidatesData, setCandidatesData] = useState(recruitersSourcingTableData)
    const [candidatesDataSmall, setCandidatesDataSmall] = useState(recruitersSourcingTableDataSmall)

    const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 500,
        },
      });

    const fetchSourcingContent = () => {
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/sourcings/getSourcings/?job=${id}`, {
            method: 'GET',
            headers: { "Authorization": `Token ${token}` }
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setFolderContent(data.data)
                    if (data.data && data.data.length && data.data[0].job) {
                        ReactSession.set("sourcingJob", data.data[0].job)
                    }

                    const candidatesArray = []
                    // eslint-disable-next-line
                    for (const item of data.data) {

                        const candidateSkills = []
                        let allSkills = "";
                        let skill = {};

                        // eslint-disable-next-line
                        for (let i = 0; i < item.candidate.skills.length; i++) {
                            skill = item.candidate.skills[i];
                            allSkills = `${allSkills} ${skill.skill.name}(${skill.level ? skill.level : 1}),`;
                        }
                        allSkills = allSkills.length ? allSkills.substr(0, allSkills.length - 1) : allSkills;

                        // eslint-disable-next-line
                        for (let i = 0; i < 5; i++) {
                            if (item.candidate?.skills[i]?.skill?.name) candidateSkills.push(<span><CustomWidthTooltip title={<div style={{ textAlign: "left" }}><b>Skills: </b>{allSkills}</div>}><SuiBadge badgeContent={item.candidate.skills[i].skill.name} color="info" variant="contained" size="lg" /></CustomWidthTooltip></span>)
                            else break
                        }

                        const countrName = item.candidate && item.candidate.country && item.candidate.country.name ? item.candidate.country.name : "";

                        candidatesArray.push({
                            candidate: [`${item.candidate.user.first_name} ${item.candidate.user.last_name}`, item.candidate.user.photo, item.candidate.user.id, item.candidate.title],
                            recruiter: `${capitalize(item.recruiter.user.first_name)} ${capitalize(item.recruiter.user.last_name)}`,
                            sourceDate: formatDate(item.createdAt),
                            country: countrName,
                            skills: candidateSkills.length > 0 ? candidateSkills : '',
                            status: item.candidate && item.candidate.leverStatus ? item.candidate.leverStatus : "",
                            action: <ActionCell candidate={item.candidate} sourcing={item} fetchSourcingContent={fetchSourcingContent} folderContent={folderContent} />
                        })
                    }

                    const candidatesDataTemp = { ...candidatesData }
                    candidatesDataTemp.rows = candidatesArray
                    setCandidatesData(candidatesDataTemp)

                    const candidatesDataSmallTemp = { ...candidatesDataSmall }
                    candidatesDataSmallTemp.rows = candidatesArray
                    setCandidatesDataSmall(candidatesDataSmallTemp)

                }
            })
            .catch(error => console.error('There was an error!', error))
    }

    useEffect(() => { fetchSourcingContent() }, [])

    useEffect(() => { 
        if (folderContent && folderContent.length) {
            document.title = `Sourcing - ${folderContent[0]?.job?.company?.name} ${folderContent[0]?.job?.name} | TECLA`;
        }
    }, [folderContent])

    return (
        <RecruiterDashboardLayout>

            {folderContent ? 
                <>                    
                    <SuiBox
                        py={2}
                        mb={2}
                        display="flex"
                        flexDirection={isMobile ? "column" : 'row'}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {folderContent.length > 0 &&
                            <SuiTypography
                                variant="h3"
                                fontWeight="bold">
                                Sourcing for {folderContent[0]?.job?.company?.name} - {folderContent[0]?.job?.name}
                            </SuiTypography>}
                            { !folderContent[0] ? (<div></div>) : null }
                            <SuiButton size="medium" color="info" variant="gradient" circular component="a" href={`/r/suggested/${id}`}>SUGGESTED CANDIDATES</SuiButton>

                    </SuiBox>
                    
                    <Card>
                        <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <DataTable table={candidatesData} />
                        </SuiBox>
                        <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
                            <DataTable table={candidatesDataSmall} />
                        </SuiBox>
                    </Card>
                </>
                
                :
                <Card sx={{ marginTop: 10 }}>
                    <SuiBox py={20} display="flex" justifyContent="center" alignItems="center" >
                        <CircularProgress />
                    </SuiBox>
                </Card>
            }

        </RecruiterDashboardLayout >
    )
}

export default RecruiterSourcingContent