/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { Link } from "react-router-dom";
import { ReactSession } from 'react-client-session';
// @mui material components
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress'
import Badge from '@mui/material/Badge';
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import mockCandidateAvatar from "assets/images/mockCandidateAvatar.png";
// Soft UI Dashboard PRO React example components
import ApplicationState from "layouts/pages/applications-list/components/ApplicationState";
import ActionCell from "layouts/pages/applications-list/components/ActionCell";

import Prospects from "layouts/pages/applications-list/data/dataTableData";
import Intro from "layouts/pages/applications-list/data/introTableData";
import Interviewing from "layouts/pages/applications-list/data/interviewingTableData";
import Declined from "layouts/pages/applications-list/data/declinedTableData";
import Hired from "layouts/pages/applications-list/data/hiredTableData";

function Cameras() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");

  const searchParams = new URLSearchParams(window.location.search);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const [camera, setCamera] = useState(0);
  const applicationStatesTables = {
    "prospects": Prospects,
    "intro": Intro,
    "interviewing": Interviewing,
    "declined": Declined,
    "hired": Hired,
  }
  const [applicationStates, setApplicationState] = useState(applicationStatesTables);
  const [jobName, setJobName] = useState("");

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [reloadTable, setReloadTable] = useState(0);

  function moveTo(appId, phase, oldStatus, newMoveStatus, declinedReason, declinedComment, calendlyLink) {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/setStatus/`;
    let apiStatus = "active";
    // eslint-disable-next-line
    if (newMoveStatus === "intro") {
      apiStatus = "intro-requested";
    } else if (newMoveStatus === "interviewing") {
      apiStatus = "interview";
    } else if (newMoveStatus === "declined") {
      apiStatus = "declined";
    } else if (newMoveStatus === "hired") {
      apiStatus = "hired";
    }
    const postBody = {
      "status": apiStatus,
      "declinedReason": String(declinedReason),
      "declinedComment": declinedComment
    };

    if (calendlyLink) {
      postBody.calendlyLink = calendlyLink;
    }

    const postToken = `Token ${token}`;

    const requestMetadata = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Authorization': postToken
      },
      body: new URLSearchParams(postBody)
    };

    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {

        if (response.success) {
          setReloadTable(reloadTable + 1);
        }

      });
  }

  function buildRow(applications) {
    const rows = [];
    let row = {};
    let user = {};
    let image = mockCandidateAvatar;
    let monthlySalary = "";
    let annualSalary = "";

    // eslint-disable-next-line
    for (const application of applications) {
      if (application.user) {
        let actionNext = "Intro Request";
        // eslint-disable-next-line
        let status = application.status;
        let newStatus = "prospects";
        if (application.status === "active") {
          status = "prospects";
          newStatus = "intro";
        }
        if (application.status === "intro-requested") {
          actionNext = "Interviewing";
          status = "intro";
          newStatus = "interviewing";
        }
        if (application.status === "interview") {
          actionNext = "Interviewing";
          status = "interviewing";
          newStatus = "declined";
        }
        if (application.status === "declined") {
          actionNext = "Interviewing";
        }
        if (application.status === "hired") {
          actionNext = "Interviewing";
        }
        actionNext = application.status === "intro-requested" ? "Interviewing" : actionNext;
        actionNext = application.status === "interview" ? "Declined" : actionNext;
        actionNext = application.status === "declined" ? "Prospects" : actionNext;
        actionNext = application.status === "hired" ? "Prospects" : actionNext;
        user = application.user;
        image = { image: mockCandidateAvatar };
        if (user && user.photo) {
          image = { image: `${process.env.REACT_APP_API_ROUTE}${user.photo}` };
        }
        let country = "";
        if (user && user.candidate && user.candidate.country) {
          country = user.candidate.country.name;
        }
        const action = <ActionCell phase={actionNext} status={status} newStatus={newStatus} moveTo={moveTo} appId={application.id} jobStatus={application.job.status} sourcingAsService={application.sourcingAsService} />;
        const appLink = <Link to={`/application/${application.id}`} target="_blank" style={{ color: "#67748e" }}><Tooltip title="View Application" placement="top"><SendIcon style={{ width: "30px", height: "30px" }} /></Tooltip></Link>;
        
        /* SALARY */
        const isFreelanceJob =  application?.job?.commitment?.name === "Freelance"
        let salary = ''

        if (isFreelanceJob) {
          salary = <div>Hourly Rate: ${application?.salary}</div> // eslint-disable-line
        }
        else {
          monthlySalary = application.monthly_salary ? application.monthly_salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""; // eslint-disable-line
          annualSalary = application.annual_salary ? application.annual_salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""; // eslint-disable-line
          salary = <div>${monthlySalary} /Month<br/>${annualSalary} /Year</div>
        }
                
        let appAvailability = application.availability;
            if (appAvailability) {
              appAvailability = appAvailability.replace("-", " ");
            }
        row = {
          product: [`${user.first_name} ${user.last_name}`, image, `/application/${application.id}`, application.isNew],
          country: country,
          availability: appAvailability,
          salary: salary,
          action,
          application: appLink,
          app: application
        }

        rows.push(row);
      }
    }

    return rows;
  }

  useEffect(() => {
    const appId = searchParams.get('id');
    if (appId) {
      setIsLoadingData(true)

      const url = `${process.env.REACT_APP_API_ROUTE}/api/companies/${appId}/companyJobApplications/`;
      const headers = { "Content-Type": "application/json" };
      headers.Authorization = `Token ${token}`;
      fetch(url, { headers, })
        .then(async response => {
          const data = await response.json();
          // eslint-disable-next-line
          const applications = data.data.applications;

          const tempApplicationStates = { ...applicationStates };
          tempApplicationStates.prospects.rows = buildRow(applications.active);
          tempApplicationStates.intro.rows = buildRow(applications['intro-requested']);
          tempApplicationStates.interviewing.rows = buildRow(applications.interview);
          tempApplicationStates.declined.rows = buildRow(applications.declined);
          tempApplicationStates.hired.rows = buildRow(applications.hired);
          setApplicationState(tempApplicationStates);
          setJobName(data.data.seniorityName);

          setIsLoadingData(false)
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }

  }, [reloadTable]);

  useEffect(() => {
    if (jobName) {
      document.title = `Applicants - ${jobName} | TECLA`;
    }
  }, [jobName]);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);

  }, [tabsOrientation]);

  const handleSetCamera = (event, newCamera) => setCamera(newCamera);

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <>
      {isLoadingData && reloadTable === 0 ?
        <SuiBox display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress size={40} />
        </SuiBox >
      :
        <>
          <SuiBox mb={2} p={2}>
            <SuiTypography variant="h3" fontWeight="bold">
              {jobName}
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              Manage your active candidates here.
            </SuiTypography>
          </SuiBox>
          <Card sx={{ minHeight: "65vh" }}>

            <SuiBox pt={3} px={3} >
              <SuiBox display="flex" justifyContent="start" alignItems="center" width="100%">
                <SuiBox width="100%">
                  <AppBar position="static">
                    <Tabs orientation={tabsOrientation} value={camera} onChange={handleSetCamera}>

                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.prospects?.rows?.length}
                          max={999}
                          onClick={() => setCamera(0)}
                        >
                          <Tab label="Applicants" />
                        </Badge>
                      </SuiBox>
                      
                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.intro?.rows?.length}
                          max={999}
                          onClick={() => setCamera(1)}
                        >
                          <Tab label="Intro Requested" />
                          </Badge>
                      </SuiBox>

                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.interviewing?.rows?.length}
                          max={999}
                          onClick={() => setCamera(2)}
                        >
                          <Tab label="Interviewing" />
                        </Badge>
                      </SuiBox>

                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.declined?.rows?.length}
                          max={999}
                          onClick={() => setCamera(3)}
                        >
                          <Tab label="Declined" />
                        </Badge>
                      </SuiBox>
                      

                      <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                        <Badge
                          color="secondary"
                          badgeContent={applicationStates?.hired?.rows?.length}
                          max={999}
                          onClick={() => setCamera(4)}
                        >
                          <Tab label="Hired" />
                        </Badge>
                      </SuiBox>

                    </Tabs>
                  </AppBar>
                </SuiBox>
              </SuiBox>
            </SuiBox>


            {isLoadingData && reloadTable > 0 ?
              <SuiBox display="flex" justifyContent="center" alignItems="center" height="40vh">
                <CircularProgress size={40} />
              </SuiBox >
            : (
              <SuiBox p={2} mt={1} width="100%" height="100%">
                <ApplicationState title="Applicants" value={camera} index={0} table={applicationStates.prospects} />
                <ApplicationState title="Intro Requested" value={camera} index={1} table={applicationStates.intro} />
                <ApplicationState title="Interviewing" value={camera} index={2} table={applicationStates.interviewing} />
                <ApplicationState title="Declined" value={camera} index={3} table={applicationStates.declined} />
                <ApplicationState title="Hired" value={camera} index={4} table={applicationStates.hired} />
              </SuiBox>
            )}
          </Card >
        </>
      }
    </>
  );
}

export default Cameras;
