/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "company-profile-form",
  formField: {
    companyName: {
      name: "companyName",
      type: "text",
      placeholder: "Company name (required)",
      errorMsg: "Company name is required.",
      tooShort: "Your company name should be more than 1 character.",
    },
    companyFirstName: {
      name: "companyFirstName",
      type: "text",
      placeholder: "First name of the company Admin or Manager",
      errorMsg: "Company first name is required.",
    },
    companyLastName: {
      name: "companyLastName",
      type: "text",
      placeholder: "Last name of the company Admin or Manager",
      errorMsg: "Company last name is required.",
    },
    companyMail: {
      name: "companyMail",
      type: "email",
      placeholder: "Work mail of the company Admin or Manager",
      errorMsg: "Company email is required.",
    },
    companyPassword: {
      name: "companyPassword",
      type: "password",
      placeholder: "A simple / temporary password for the client to log in the first time",
      errorMsg: "Company password is required.",
    },
    website: {
      name: "website",
      type: "text",
      placeholder: "Website (optional)",
      errorMsg: "Website error",
    },
    youtube: {
      name: "youtube",
      type: "text",
      placeholder: "Youtube Channel (optional)",
      errorMsg: "Youtube error",
    },
    linkedin: {
      name: "linkedin",
      type: "text",
      placeholder: "LinkedIn (optional)",
      errorMsg: "linkedin error",
    },
    twitter: {
      name: "twitter",
      type: "text",
      placeholder: "Twitter profile (optional)",
      errorMsg: "Twitter error",
    },
    bio: {
      name: "bio",
      label: "bio",
      type: "textarea",
      placeholder: "Say a few words about who you are or what you're working on (optional).",
      tooSmall: "Please complete the company bio",
      tooBig: "Your bio cannot have more than 500 characters.",
    },
    photo: {
      name: "photo",
      type: "file",
      label: "photo",
      placeholder: "image"
    },
  },
};
