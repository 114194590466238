/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import MarkPaidInvoiceModal from "layouts/pages/managersAddToTeam/modals/markPaidInvoiceModal";
import DeclineInvoiceModal from "layouts/pages/managersAddToTeam/modals/declineInvoiceModal";
import ApproveInvoiceModal from "layouts/pages/managersAddToTeam/modals/approveInvoiceModal";


function actionCell({ invoiceId, status }) {

	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleClick = event => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)

	const [showMarkPaidInvoiceModal, setShowMarkPaidInvoiceModal] = useState(false)
	const [showDeclineInvoiceModal, setShowDeclineInvoiceModal] = useState(false)
	const [showApproveInvoiceModal, setShowApproveInvoiceModal] = useState(false)

	const getMenuActions = () => {
		const actions = []

		if (status !== "paid") actions.push(
			<MenuItem className="menuActive" onClick={() => { setShowMarkPaidInvoiceModal(true); handleClose() }}>Mark as &quot;paid&quot;</MenuItem>
		)
		if (status === "pending-approval") {
			actions.push(
				<MenuItem className="menuActive" onClick={() => { setShowDeclineInvoiceModal(true); handleClose() }}>Decline</MenuItem>
			)
			actions.push(
				<MenuItem className="menuActive" onClick={() => { setShowApproveInvoiceModal(true); handleClose() }}>Send for client review</MenuItem>
			)
		}
		return actions.length ? actions.map(act => act) : null
	}

	return (
		<SuiBox display="flex" alignItems="center" justifyContent="center" flexDirection="column">
			{getMenuActions() && (
				<>
					<IconButton
						aria-label="more"
						id="long-button"
						aria-controls={open ? 'long-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-haspopup="true"
						onClick={handleClick}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-menu"
						MenuListProps={{ 'aria-labelledby': 'long-button' }}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
					>
						{getMenuActions()}
					</Menu>
				</>
			)}

			{/* Modals */}
			<>
				{showMarkPaidInvoiceModal && (
					<MarkPaidInvoiceModal showMarkPaidInvoiceModal={showMarkPaidInvoiceModal} setShowMarkPaidInvoiceModal={setShowMarkPaidInvoiceModal} invoiceId={invoiceId} />
				)}

				{showDeclineInvoiceModal && (
					<DeclineInvoiceModal showDeclineInvoiceModal={showDeclineInvoiceModal} setShowDeclineInvoiceModal={setShowDeclineInvoiceModal} invoiceId={invoiceId} />
				)}

				{showApproveInvoiceModal && (
					<ApproveInvoiceModal showApproveInvoiceModal={showApproveInvoiceModal} setShowApproveInvoiceModal={setShowApproveInvoiceModal} invoiceId={invoiceId} />
				)}
			</>
		</SuiBox>
	);
}

// Typechecking props for the actionCell
actionCell.propTypes = {
	invoiceId: PropTypes.number.isRequired,
	status: PropTypes.string.isRequired,
};

export default actionCell;
