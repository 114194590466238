/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { ReactSession } from 'react-client-session'
// MUI Components
import Modal from '@mui/material/Modal';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiEditor from "components/SuiEditor";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import FolderCard from "layouts/recruiterFolders/folderFile/folderFile";
import { useState } from "react";
import SuiAlert from "components/SuiAlert";


function SalesModal({ openSales, setOpenSales, company, companySalesData, companyUpdated, setCompanyUpdated, salesNotes, setSalesNotes, saveSales, loading, setLoading }) {
    
    ReactSession.setStoreType("localStorage")
    const user = ReactSession.get("user")
    const token = ReactSession.get("token")

    const fullname = company.name;
    
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

    function getFileName(fileUrl) {
        if (fileUrl) {
        return fileUrl.substring(fileUrl.lastIndexOf("/") + 1, fileUrl.length - 4);
        }

        return "";
    }

    const contractTypes = [
        { value: "direct_hire", label: "Direct Hire" },
        { value: "sourcing_service", label: "Sourcing as a Service" },
        { value: "staff_augmentation", label: "Staff Augmentation" },
        { value: "staff_augmentation-conversion", label: "Staff Augmentation (Conversion)" }
    ];

    function closeFile(fileId) {
        setLoading(true);
        const formPostData = new FormData();

        const requestMetadata = {
        method: 'POST',
        headers: {
            "Content-Disposition": `attachment; filename=file.pdf`,
            'Authorization': `Token ${token}`
        },
        body: formPostData
        };

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/${fileId}/deleteCompanySaleFile/`, requestMetadata)
        .then(res => res.json())
        .then(response => {
            if (response.success) {
            setCompanyUpdated(companyUpdated + 1);
            }
        })

    }
  
    function uploadResume() {
        if (user) {
        setLoading(true);
        const resumeField = $("input[name=cv]").prop('files')[0];
        const formPostData = new FormData();

        // eslint-disable-next-line no-useless-concat
        const postToken = `Token ${token}`;

        formPostData.append('file', resumeField);

        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/companies/${company.id}/updateCompanySale/`;
        const normalizedName = resumeField.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        // const postBody = {
        //   'file': $("input[name=password]").val(),
        // };

        const requestMetadata = {
            method: 'POST',
            headers: {
            "Content-Disposition": `attachment; filename=${normalizedName}`,
            "Authorization": postToken,
            },
            body: formPostData
        };

        fetch(recipeUrl, requestMetadata)
            .then(res => res.json())
            .then(response => {
            if (response.success) {
                setCompanyUpdated(companyUpdated + 1);
            }
            });
        }
    }

    const [selectedContractType, setselectedContractType] = useState(companySalesData.contractType)
    const [showContractTypeAlert, setShowContractTypeAlert] = useState(false)

    const contractTypeAlert = () => {
        setShowContractTypeAlert(true)
        setTimeout(() => {
            setShowContractTypeAlert(false)
        }, 3000);
    }

    return (
        <Modal
            open={openSales}
            onClose={() => setOpenSales(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <SuiBox
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: "100vw", md: "40vw", xl: "70vw" },
                    height: { xs: "101vh", md: "fit-content" },
                    maxHeight: "90%",
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >

                {showContractTypeAlert && (
                    <SuiAlert color='error'>Please select a contract type</SuiAlert>
                )}

                { loading ? (
                <SuiBox className="moonCover" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={0}>
                    <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                </SuiBox>
                ) : null }
                <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                <SuiBox display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                    <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Sales notes for &nbsp;</SuiTypography>
                    <SuiBox sx={{ mx: { xs: '110', md: '0' } }}>
                    <SuiTypography variant="h6" fontWeight="bold">{fullname}</SuiTypography>
                    </SuiBox>
                </SuiBox>
                <Icon onClick={() => setOpenSales(false)} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                </SuiBox>
                <SuiBox id="modal-modal-description" borderRadius="16px" height="100%" my={2} p={2} sx={{ overflow: "auto" }}>
                <Grid container xs={12} md={12} spacing={1}>
                    <Grid item xs={6} mb={2}>
                    <SuiTypography variant="button" ml={1} mb={1}>Contract type:</SuiTypography>

                    { companySalesData && companySalesData.contractType ? (
                        <SuiSelect
                            placeholder="Select a contract type"
                            options={contractTypes}
                            defaultValue={contractTypes.find(({ value }) => value === companySalesData.contractType)}
                            name="contract"
                            onChange={e => setselectedContractType(e.value)}
                        />
                    ) : (
                        <SuiSelect
                            placeholder="Select a contract type"
                            options={contractTypes}
                            name="contract"
                            onChange={e => setselectedContractType(e.value)}
                        />
                    ) }
                    </Grid>
                    <Grid item xs={6}>
                    <SuiTypography variant="button" ml={1} mb={1}>Add file (PDF)</SuiTypography>
                    <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={uploadResume} accept=".pdf" />
                    </Grid>
                </Grid>
                <Grid>
                    <SuiEditor
                        maxLength={2000}
                        defaultValue={salesNotes}
                        name="salesNotes"
                        onChange={setSalesNotes}
                        placeholder="Sales Notes"
                    />
                </Grid>
                {companySalesData && companySalesData.files && companySalesData.files.length ? (
                    <SuiBox my={3} display="flex" justifyContent="start" style={{ overflow: "auto", "maxWidth": "100%", background: "#f6f7f8" }}>
                    {companySalesData.files.map(companySalesFile => (
                        <Grid item xs={6} lg={2} className="folder-box" m={1} p={1} sx={{ maxWidth: "175px !important" }}>
                        <FolderCard folderName={getFileName(companySalesFile.file)} folderId={companySalesFile.id} folderUrl={companySalesFile.file} closeFile={closeFile} />
                        </Grid>
                    ))}
                    </SuiBox>
                ) : null }
                </SuiBox>
                <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
                <SuiButton
                    color="info"
                    onClick={() => {
                        if (!selectedContractType) contractTypeAlert();
                        else saveSales()
                    }}
                >
                    Save
                </SuiButton>
                </SuiBox>
            </SuiBox>
        </Modal>
  )
}

SalesModal.propTypes = {
    openSales: PropTypes.bool.isRequired,
    setOpenSales: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired, // eslint-disable-line
    companySalesData: PropTypes.object.isRequired, // eslint-disable-line
    companyUpdated: PropTypes.number.isRequired,
    setCompanyUpdated: PropTypes.func.isRequired,
    salesNotes: PropTypes.string.isRequired,
    setSalesNotes:  PropTypes.func.isRequired,
    saveSales:  PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
}

export default SalesModal