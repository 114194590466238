/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types"
// @mui material components
import Card from "@mui/material/Card"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React base styles
// import typography from "assets/theme/base/typography"

function RecruiterProfileInfoCard({ recruiter }) {

  return (
    <Card sx={{ height: "auto" }}>
      <SuiBox p={2}>
        <SuiBox>

          <SuiBox display="flex" flexDirection="column" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize" mb={2}>Profile</SuiTypography>

            {recruiter?.user ? ( /* eslint-disable-line */
              <SuiBox display="flex" py={1} pr={2}>
                <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">Full Name: &nbsp;</SuiTypography>
                <SuiTypography variant="button" fontWeight="regular">{`${recruiter?.user?.first_name} ${recruiter?.user?.last_name}`}</SuiTypography>
              </SuiBox>
            ) : null }

            {recruiter?.country ? ( /* eslint-disable-line */
              <SuiBox display="flex" py={1} pr={2}>
                <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">Location: &nbsp;</SuiTypography>
                <SuiTypography variant="button" fontWeight="regular">{recruiter.country}</SuiTypography>
              </SuiBox>
            ) : null }

            {recruiter?.dateOfBirth ? ( /* eslint-disable-line */
              <SuiBox display="flex" py={1} pr={2}>
                <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">Birthday: &nbsp;</SuiTypography>
                <SuiTypography variant="button" fontWeight="regular">{recruiter.dateOfBirth}</SuiTypography>
              </SuiBox>
            ) : null }

          </SuiBox>

        </SuiBox>
      </SuiBox>
    </Card>
  )
}

// Typechecking props for the RecruiterProfileInfoCard
RecruiterProfileInfoCard.propTypes = {
  recruiter: PropTypes.arrayOf(PropTypes.object),
}

RecruiterProfileInfoCard.defaultProps = {
  recruiter: {}
}

export default RecruiterProfileInfoCard
