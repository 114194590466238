/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
// import useMediaQuery from "@mui/material/useMediaQuery"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SuiDatePicker from "components/SuiDatePicker";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React icons
import Cube from "examples/Icons/Cube";

function Sidenav({ utms, utmFilterText, utmArrayFilters, filterUtms, updateJobs }) {

  /* Mui hook to read screen size - We use it for small screen rendering */
  // const isSmallScreen = useMediaQuery('(max-width: 1201px)')

  const sidenavItems = [
    { icon: <Cube />, href: "profile" },
  ];

  function utmChecked(utmId) {
    // eslint-disable-next-line
    for (const utmArrayFilter of utmArrayFilters) {
      if (String(utmArrayFilter.id) === String(utmId)) {
        return true;
      }
    }

    return false;
  }

  const renderSidenavItems = sidenavItems.map(({ href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <SuiBox p={2} key={itemKey} component="li" pt={key === 0 ? 0 : 1} bgColor="transparent">
        <Accordion TransitionProps={{ unmountOnExit: false }} defaultExpanded sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography variant="bold">UTMs</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="utmsSelected" style={{ fontSize: "12px", "background": "#f6f6f6", "marginTop": "-20px", "color": "#868686", "padding": "10px" }}>{utmFilterText}</div>
            <SuiBox mt={1}>
              <SuiInput
                type="text"
                placeholder="Search..."
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                className="searchInput"
                name="searchInput"
                onChange={e => filterUtms(e.target.value)}
              >
                Search
              </SuiInput>
            </SuiBox>
            <SuiBox mt={1} className="utmBox" style={{ "maxHeight": "165px", "overflowY": "auto" }}>
              {utms.map(utm => (
                <SuiBox>
                  <Checkbox data-name={utm.name} className="selectUtm" defaultChecked={utmChecked(utm.id)} />
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ userSelect: "none" }}>
                    {utm.name}
                  </SuiTypography>
                </SuiBox>
              ))}
            </SuiBox>
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <SuiTypography>Date Range</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SuiBox>
              <SuiTypography variant="button" ml={1}>
                Start Date:
              </SuiTypography>
              <SuiDatePicker input={{ placeholder: "Start Date", name: "startDate" }} name="startDate" />
            </SuiBox>
            <SuiBox mt={2}>
              <SuiTypography variant="button" ml={1}>
                End Date:
              </SuiTypography>
              <SuiDatePicker input={{ placeholder: "End Date", name: "endDate" }} name="endDate" />
            </SuiBox>
          </AccordionDetails>
        </Accordion>

        <SuiButton sx={{ my: 2 }} fullWidth color="info" variant="gradient" onClick={() => updateJobs(true)} >Search</SuiButton>
      </SuiBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
        marginBottom: "1rem",
      }}
    >
      <SuiBox
        bgColor="transparent"
        component="ul"
        display="flex"
        flexDirection="column"
        p={0}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </SuiBox>
    </Card>
  );
}

// typechecking props
Sidenav.propTypes = {
  utms: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]).isRequired,
  utmFilterText: PropTypes.string.isRequired,
  utmArrayFilters: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]).isRequired,
  filterUtms: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]).isRequired,
  updateJobs: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]).isRequired,
};

export default Sidenav;
