/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import Swal from "sweetalert2";
// @mui material components
import Tooltip from "@mui/material/Tooltip";
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SendIcon from '@mui/icons-material/Send';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Modal
import DeleteApplicationModal from "layouts/pages/applicationsRecruiter/components/actionCell/DeleteApplicationModal/DeleteApplicationModal"

function ActionCell({ jobId, companyId, candidateId, applicationId, cvUrl, deleteApplication, applicationCompanyId, applicationDeleteId, withdrawReason }) {

	ReactSession.setStoreType("localStorage")
	const recruiter = ReactSession.get("recruiter")
	const user = ReactSession.get("user")

	let link = `/job?id=${jobId}`
	let title = "View job detail"

	if (companyId) {
		link = `company/${companyId}`
		title = "View company detail"
	}
	if (candidateId) {
		link = `profile/${candidateId}`
		title = "View candidate detail"
	}
	if (applicationId) {
		link = `application/${applicationId}`
		title = "View application"
	}
	if (cvUrl) {
		link = cvUrl
		title = "View CV"
	}

	const shouldRender = companyId || candidateId || applicationId || cvUrl || jobId

	const renderIcon = () => {
		if (companyId) return <ApartmentIcon/>
		if (candidateId) return <PersonIcon/>
		if (applicationId) return <SendIcon/>
		if (cvUrl) return <AssignmentIcon/>
		if (jobId) return <WorkIcon/>
		return null
	}

	const canDeleteApplication = appCompanyId => {
		/* Admins can edit everything */
		if (user.type === "tecla_admin") return true
		/* Managers can only edit companies they're assigned to */
		if (user.type === "tecla_manager" && recruiter.companies.filter(comp => comp?.id === appCompanyId).length) return true
		/* No one else can edit anything */
		return false
	}

	const [showDeleteApplicationModal, setShowDeleteApplicationModal] = useState(false)

	const showWithdrawReason = () => Swal.fire({
        title: "Withdraw reason",
		text: withdrawReason,
        confirmButtonText: 'CLOSE',
        confirmButtonAriaLabel: 'CLOSE',
        showCancelButton: false,
        allowEscapeKey: "true"
    })

	return (
		<SuiBox display="flex" alignItems="center" justifyContent="center" flexDirection="column">
			{shouldRender && (
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} component="a" mr={1} href={link} target="_blank">
					<Tooltip title={title} placement="top">
						{renderIcon()}
					</Tooltip>
				</SuiTypography>
			)}
			
			{deleteApplication && applicationCompanyId !== null && canDeleteApplication(applicationCompanyId) && applicationDeleteId !== null && (
				<>
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} onClick={() => setShowDeleteApplicationModal(true)}>
						<Tooltip title="Delete application" placement="top">
							<DeleteIcon/>
						</Tooltip>
					</SuiTypography>

					{showDeleteApplicationModal && <DeleteApplicationModal showDeleteApplicationModal={showDeleteApplicationModal}  setShowDeleteApplicationModal={setShowDeleteApplicationModal} appId={applicationDeleteId} />}
				</>
			)}

			{withdrawReason && (
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} onClick={() => showWithdrawReason()}>
					<Tooltip title="View withdraw reason" placement="top">
						<VisibilityIcon/>
					</Tooltip>
				</SuiTypography>
			)}
		</SuiBox>
	);
}

// Setting default value for the props of ActionCell
ActionCell.defaultProps = {
	jobId: null,
	companyId: null,
	candidateId: null,
	applicationId: null,
	cvUrl: null,
	deleteApplication: false,
	applicationCompanyId: null,
	applicationDeleteId: null,
	withdrawReason: null
};

// Typechecking props for the ActionCell
ActionCell.propTypes = {
	jobId: PropTypes.number,
	companyId: PropTypes.number,
	candidateId: PropTypes.number,
	applicationId: PropTypes.number,
	cvUrl: PropTypes.string,
	deleteApplication: PropTypes.bool,
	applicationCompanyId: PropTypes.number,
	applicationDeleteId: PropTypes.number,
	withdrawReason: PropTypes.string,
};

export default ActionCell;
