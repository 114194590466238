/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/pages/users/new-user/schemas/form";
import { ReactSession } from 'react-client-session'

ReactSession.setStoreType("localStorage")
const user = ReactSession.get("user")
const candidate = ReactSession.get("candidate")
const searchParams = new URLSearchParams(window.location.search);
const urlCode = searchParams.get('code');

const {
  formField: {
    firstName,
    lastName,
    title,
    company,
    email,
    password,
    repeatPassword,
    passwordConfirm,
    address1,
    address2,
    city,
    country,
    phone,
    dateOfBirth,
    resume,
    zip,
    twitter,
    facebook,
    instagram,
    bio,
    linkedin,
    youtube,
    github,
    gitlab,
    stackoverflow,
    portfolio,
    website,
    photo,
    code,
  },
} = checkout;

export default {
  [firstName.name]: user && user.first_name ? user.first_name : "",
  [lastName.name]: user && user.last_name ? user.last_name : "",
  [title.name]: candidate && candidate.title ? candidate.title : "",
  [bio.name]: candidate && candidate.bio ? candidate.bio : "",
  [company.name]: "",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [passwordConfirm.name]: "",
  [address1.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [country.name]: "",
  [phone.name]: "",
  [dateOfBirth.name]: "",
  [resume.name]: "",
  [zip.name]: "",
  [photo.name]: "",
  [youtube.name]: candidate && candidate.youtube ? candidate.youtube : "",
  [github.name]: candidate && candidate.github ? candidate.github : "",
  [gitlab.name]: candidate && candidate.gitlab ? candidate.gitlab : "",
  [linkedin.name]: candidate && candidate.linkedin ? candidate.linkedin : "",
  [stackoverflow.name]: candidate && candidate.stackOverflow ? candidate.stackOverflow : "",
  [portfolio.name]: candidate && candidate.portfolio ? candidate.portfolio : "",
  [website.name]: candidate && candidate.website ? candidate.website : "",
  [twitter.name]: candidate && candidate.twitter ? candidate.twitter : "",
  [facebook.name]: candidate && candidate.facebook ? candidate.facebook : "",
  [instagram.name]: candidate && candidate.instagram ? candidate.instagram : "",
  [code.name]: urlCode ? urlCode : "", // eslint-disable-line 
};


