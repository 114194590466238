/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// MUI components
import Tooltip from "@mui/material/Tooltip";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// Modal
import SourcingsModal from "./SourcingsModal/sourcingsModal";

function SourcedCell({ isSourced, sourcingsCount, candidateId, candidateName }) {

	const [openSourcingsModal, setOpenSourcingsModal] = useState(false)

	return (
		<SuiBox display="flex" alignItems="center" >
			{isSourced && (
				<Tooltip title="Sourced for active job" placement="top" ml={1}>
					<SuiBox mx={.5} p={1} display="flex" alignItems="center" justifyContent="center" sx={{ cursor: "pointer" }} onClick={() => setOpenSourcingsModal(true)}>
						<CheckBoxIcon fontSize="medium" color="success" />
						<SuiTypography variant="button" color="secondary" mr={1}>{sourcingsCount} Sourcings</SuiTypography>
					</SuiBox>
				</Tooltip>
			)}

			{/* Sourcings modal */}
			{openSourcingsModal && (
				<SourcingsModal
					openSourcingsModal={openSourcingsModal}
					setOpenSourcingsModal={setOpenSourcingsModal}
					candidateId={candidateId}
					candidateName={candidateName}
				/>
			)}
		</SuiBox>
	)
}

SourcedCell.propTypes = {
	isSourced: PropTypes.bool.isRequired,
	sourcingsCount: PropTypes.number.isRequired,
	candidateId: PropTypes.number.isRequired,
	candidateName: PropTypes.string.isRequired,
}

export default SourcedCell
