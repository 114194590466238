/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useEffect, useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import SuiBadge from "components/SuiBadge";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexBackgroundCard from "examples/Cards/BackgroundCards/ComplexBackgroundCard";
import DataTable from "examples/Tables/DataTable";
import AnimatedStatisticsCard from "examples/Cards/StatisticsCards/AnimatedStatisticsCard";
// Referral page components
import ReferralCode from "layouts/pages/referral/components/ReferralCode";
// Data
import dataTableData from "layouts/pages/referral/data/dataTableData";
import ProductCell from "layouts/ecommerce/overview/components/ProductCell";
import DefaultCell from "layouts/ecommerce/overview/components/DefaultCell";
// Images
import officeDark from "assets/images/work-space.jpeg";
import mockCandidateAvatar from "assets/images/mockCandidateAvatar.png";


function Referral() {
  ReactSession.setStoreType("localStorage");
  const candidate = ReactSession.get("candidate");
  const token = ReactSession.get("token");
  const [referals, setReferals] = useState(dataTableData);
  const apiUrl = process.env.REACT_APP_API_ROUTE;

  const shareRef = `${process.env.REACT_APP_PROD_ROUTE}t/signup?code=${candidate.code}`;

  useEffect(() => {
    document.title = `Refer | TECLA`;
  }, []);

  useEffect(() => {
    if (candidate) {
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      const url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/referrals/`;
      if (token) {
        headers.Authorization = `Token ${token}`;
      }
      fetch(url, { headers, })
        .then(async response => {
          const res = await response.json();
        validateToken(res) // eslint-disable-line
          if (res.success && res.data) {
            const referalRows = [];
            const referalsCopy = { ...referals };
            // eslint-disable-next-line
            for (const referalUser of res.data) {
              let referalImg = mockCandidateAvatar
              if (referalUser.user.photo) {
                referalImg = `${apiUrl}${referalUser.user.photo}`;
              }
              const joinedDate = new Date(referalUser.user.date_joined);
              const day = joinedDate.getDate();
              const month = joinedDate.getMonth() + 1;
              const year = joinedDate.getFullYear();
              let date = `${month}/${day}/${year}`;
              if (month < 10) {
                date = `0${month}/${day}/${year}`;
              }
              let referredStatus = "Registered";
              let referredColor = "secondary";
              if (referalUser.referredStatus === 1) {
                referredStatus = "Not Yet Eligible";
              } else if (referalUser.referredStatus === 2) {
                referredStatus = "Hired";
                referredColor = "info";
              } else if (referalUser.referredStatus === 3) {
                referredStatus = "Paid";
                referredColor = "success";
              }
              const fullName = `${referalUser.user.first_name} ${referalUser.user.last_name}`;
              referalRows.push({
                "user": <ProductCell image={referalImg} name={fullName} />,
                "date": <DefaultCell>{date}</DefaultCell>,
                "status": (
                  <SuiBadge badgeContent={referredStatus} container color={referredColor} variant="contained" />
                ),
              });
            }
            referalsCopy.rows = referalRows;
            setReferals(referalsCopy);
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox my={3}>
        <SuiBox mb={2}>
          <Card>
            <SuiBox p={2}>
              <SuiBox mb={1}>
                <SuiTypography variant="h4" fontWeight="medium">
                  Refer your friends and earn rewards 💸
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="body2" fontWeight="regular" color="text">
                Share the best remote job opportunities with your network. Refer your friends and earn up up to $500 if they get hired!
              </SuiTypography>
              <SuiTypography variant="body2" fontWeight="regular" color="text">
                <a href='//n.tecla.io/l/referral' target="_blank" rel="noreferrer">Learn more</a>
              </SuiTypography>
            </SuiBox>
            <SuiBox p={2}>
              <SuiBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3} >
                      <Grid item xs={12} md={4}>
                        <AnimatedStatisticsCard
                          title="Step 1"
                          count="Become a member of the TECLA talent network"
                          action={{
                            type: "internal",
                            route: "/pages/projects/general",
                            label: "view more",
                          }}
                        />
                        {/* <TransparentInfoCard
                          color="info"
                          icon="share"
                          value="1"
                          description="Become a member of the TECLA talent network"
                        /> */}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <AnimatedStatisticsCard
                          title="Step 2"
                          count="Refer others by sharing your unique referral link with them"
                          action={{
                            type: "internal",
                            route: "/pages/projects/general",
                            label: "view more",
                          }}
                        />
                        {/* <TransparentInfoCard
                          color="info"
                          icon="mail"
                          value="2"
                          description="Reffer tech proffesionals by sharing your affiliate link"
                        /> */}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <AnimatedStatisticsCard
                          title="Step 3"
                          count="Earn up to $500 for every one of your friends that gets hired!"
                          // count="When one of your refferals gets hired for a valid job, we give you up to $500"
                          action={{
                            type: "internal",
                            route: "/pages/projects/general",
                            label: "view more",
                          }}
                        />
                        {/* <TransparentInfoCard
                          color="info"
                          icon="paid"
                          value="3"
                          description="When one of your refferals gets hired for a valid job, we give you up to $500"
                        /> */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <SuiBox className="alertSuccessBox" display="none">
                      <SuiAlert color="info" className="alertSuccess">Link copied to your clipboard!</SuiAlert>
                    </SuiBox>
                    <ReferralCode shareRef={shareRef} />
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <ComplexBackgroundCard
                      image={officeDark}
                      description="The best tech opportunities for your network. If they get hired, you get paid!"
                      action={{
                        type: "external",
                        route: "https://n.tecla.io/l/referral",
                        href: "https://n.tecla.io/l/referral",
                        label: "Learn more",
                      }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
            </SuiBox>
          </Card>
        </SuiBox>
        <Card>
          <SuiBox pt={3} px={3}>
            <SuiTypography variant="h6" fontWeight="medium">
              Friends You&apos;ve Referred
            </SuiTypography>
          </SuiBox>
          <DataTable
            table={referals}
            entriesPerPage={false}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
          />
        </Card>
      </SuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Referral;
