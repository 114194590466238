/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken, formatDate } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from "@mui/material/Checkbox";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from '@mui/material/Badge';
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiPagination from "components/SuiPagination";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React example components
import breakpoints from "assets/theme/base/breakpoints";
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import DashboardNavbar from "examples/Navbars/FilterNavbar";
import Configurator from "examples/Configurator";
import ApplicationStateTable from "layouts/pages/applicationsRecruiter/components/ApplicationStateTable";
// Data
import inReviewTableData from "layouts/pages/applicationsRecruiter/data/inReviewTableData";
import prospectsTableData from "layouts/pages/applicationsRecruiter/data/prospectsTableData";
import introTableData from "layouts/pages/applicationsRecruiter/data/introTableData";
import interviewingTableData from "layouts/pages/applicationsRecruiter/data/interviewingTableData";
import hiredTableData from "layouts/pages/applicationsRecruiter/data/hiredTableData";
import declinedTableData from "layouts/pages/applicationsRecruiter/data/declinedTableData";
import withdrewTableData from "layouts/pages/applicationsRecruiter/data/withdrewTableData";
// In Review - Active - Intro requested - Interview - Hired - Declined - withdrew
import inReviewTableDataSmall from "layouts/pages/applicationsRecruiter/data/inReviewTableDataSmall";
import prospectsTableDataSmall from "layouts/pages/applicationsRecruiter/data/prospectsTableDataSmall";
import introTableDataSmall from "layouts/pages/applicationsRecruiter/data/introTableDataSmall";
import interviewingTableDataSmall from "layouts/pages/applicationsRecruiter/data/interviewingTableDataSmall";
import hiredTableDataSmall from "layouts/pages/applicationsRecruiter/data/hiredTableDataSmall";
import declinedTableDataSmall from "layouts/pages/applicationsRecruiter/data/declinedTableDataSmall";
import withdrewTableDataSmall from "layouts/pages/applicationsRecruiter/data/withdrewTableDataSmall";

function ApplicationsRecruiter() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const user = ReactSession.get("user");
  const recruiter = ReactSession.get("recruiter");

  const [pagination, setPagination] = useState({ pages: [] })
  const [totalResults, setTotalResults] = useState(null)

  const [isFetchingData, setIsFetchingData] = useState(false)

  useEffect(() => {
    document.title = `Job Applications | TECLA`;
  }, []);

  /* State to store the filter choices */
  const [jobNameFilter, setJobNameFilter] = useState("")
  const [candidateFirstNameFilter, setCandidateFirstNameFilter] = useState("")
  const [candidateLastNameFilter, setCandidateLastNameFilter] = useState("")
  const [candidateEmailFilter, setCandidateEmailFilter] = useState("")
  const [candidateCountryFilter, setCandidateCountryFilter] = useState(null)
  const [companyNameFilter, setCompanyNameFilter] = useState("")
  const [inReviewStateFilter, setInReviewStateFilter] = useState(false)
  const [activeStateFilter, setActiveStateFilter] = useState(false)
  const [hiredStateFilter, setHiredStateFilter] = useState(false)
  const [introStateFilter, setIntroStateFilter] = useState(false)
  const [interviewStateFilter, setInterviewStateFilter] = useState(false)
  const [withdrewStateFilter, setWithdrewStateFilter] = useState(false)
  const [rejectedStateFilter, setRejectedStateFilter] = useState(false)
  const [engineeringFilter, setEngineeringFilter] = useState(false)
  const [qaFilter, setQaFilter] = useState(false)
  const [designFilter, setDesignFilter] = useState(false)
  const [devopsFilter, setDevopsFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState(false)
  const [productFilter, setProductFilter] = useState(false)
  const [managementFilter, setManagementFilter] = useState(false)
  const [marketingFilter, setMarketingFilter] = useState(false)
  const [salesFilter, setSalesFilter] = useState(false)

  const [betterForAnotherRoleFilter, setBetterForAnotherRoleFilter] = useState(false)
  const [compensationFilter, setCompensationFilter] = useState(false)
  const [cultureFitFilter, setCultureFitFilter] = useState(false)
  const [employmentDurationFilter, setEmploymentDurationFilter] = useState(false)
  const [englishSkillsFilter, setEnglishSkillsFilter] = useState(false)
  const [futureOpportunitiesFilter, setFutureOpportunitiesFilter] = useState(false)
  const [locationFilter, setLocationFilter] = useState(false)
  const [motivationsFilter, setMotivationsFilter] = useState(false)
  const [offerDeclinedFilter, setOfferDeclinedFilter] = useState(false)
  const [overqualifiedFilter, setOverqualifiedFilter] = useState(false)
  const [positionClosedFilter, setPositionClosedFilter] = useState(false)
  const [timingFilter, setTimingFilter] = useState(false)
  const [underqualifiedFilter, setUnderqualifiedFilter] = useState(false)
  const [unresponsiveFilter, setUnresponsiveFilter] = useState(false)
  const [withdrewFilter, setWithdrewFilter] = useState(false)

// In Review - Active - Intro requested - Interview - Hired - Declined - withdrew

  const applicationStatesTables = {
    "inReview": inReviewTableData,
    "prospects": prospectsTableData,
    "intro": introTableData,
    "interviewing": interviewingTableData,
    "hired": hiredTableData,
    "declined": declinedTableData,
    "withdrew": withdrewTableData,
  }

  const [applicationStates, setApplicationStates] = useState(applicationStatesTables)

  const smallApplicationStatesTables = {
    "inReview": inReviewTableDataSmall,
    "prospects": prospectsTableDataSmall,
    "intro": introTableDataSmall,
    "interviewing": interviewingTableDataSmall,
    "hired": hiredTableDataSmall,
    "declined": declinedTableDataSmall,
    "withdrew": withdrewTableDataSmall,
  }

  const [smallApplicationStates, setSmallApplicationStates] = useState(smallApplicationStatesTables)

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const fetchApplications = (page = 1) => {
    setIsFetchingData(true)

    let url = `${process.env.REACT_APP_API_ROUTE}/api/applications/teclaApplicationsFind/?page=${page}`
    /* Adding search params */
    const searchParamsObj = {}
    if ($("[name=jobName]:last").val()) searchParamsObj.jobName = $("[name=jobName]:last").val()
    if ($("[name=candidateName]:last").val()) searchParamsObj.name = $("[name=candidateName]:last").val()
    // if (candidateLastNameFilter) searchParamsObj.lastName = candidateLastNameFilter
    if ($("[name=candidateEmail]:last").val()) searchParamsObj.email = $("[name=candidateEmail]:last").val()
    if (candidateCountryFilter) searchParamsObj.country = candidateCountryFilter
    if ($("[name=companyName]:last").val()) searchParamsObj.companyName = $("[name=companyName]:last").val()

    if (inReviewStateFilter) url = `${url}&status=in-review`
    if (activeStateFilter) url = `${url}&status=active`
    if (hiredStateFilter) url = `${url}&status=hired`
    if (introStateFilter) url = `${url}&status=intro-requested`
    if (interviewStateFilter) url = `${url}&status=interview`
    if (withdrewStateFilter) url = `${url}&status=withdrew`
    if (rejectedStateFilter) url = `${url}&status=declined`
    if (engineeringFilter) url = `${url}&categories=eng`
    if (qaFilter) url = `${url}&categories=qa`
    if (designFilter) url = `${url}&categories=dg`
    if (devopsFilter) url = `${url}&categories=do`
    if (dataFilter) url = `${url}&categories=da`
    if (productFilter) url = `${url}&categories=pro`
    if (managementFilter) url = `${url}&categories=mg`
    if (marketingFilter) url = `${url}&categories=mk`
    if (salesFilter) url = `${url}&categories=sl`

    if (!(betterForAnotherRoleFilter && compensationFilter && cultureFitFilter && employmentDurationFilter && englishSkillsFilter && futureOpportunitiesFilter && locationFilter && motivationsFilter && offerDeclinedFilter && overqualifiedFilter && positionClosedFilter && timingFilter && underqualifiedFilter && unresponsiveFilter && withdrewFilter)) {
      if (betterForAnotherRoleFilter) url = `${url}&declinedReasons=11`
      if (compensationFilter) url = `${url}&declinedReasons=0`
      if (cultureFitFilter) url = `${url}&declinedReasons=12`
      if (employmentDurationFilter) url = `${url}&declinedReasons=1`
      if (englishSkillsFilter) url = `${url}&declinedReasons=2`
      if (futureOpportunitiesFilter) url = `${url}&declinedReasons=13`
      if (locationFilter) url = `${url}&declinedReasons=3`
      if (motivationsFilter) url = `${url}&declinedReasons=6`
      if (offerDeclinedFilter) url = `${url}&declinedReasons=4`
      if (overqualifiedFilter) url = `${url}&declinedReasons=5`
      if (positionClosedFilter) url = `${url}&declinedReasons=10`
      if (timingFilter) url = `${url}&declinedReasons=14`
      if (underqualifiedFilter) url = `${url}&declinedReasons=7`
      if (unresponsiveFilter) url = `${url}&declinedReasons=8`
      if (withdrewFilter) url = `${url}&declinedReasons=9`
    }    
    
    const searchParams = new URLSearchParams(searchParamsObj)

    const formatCategories = cat => {
      switch (cat) {
        case 'eng':
          return 'Engineering'
        case 'qa':
          return 'QA'
        case 'dg':
          return 'Design'
        case 'do':
          return 'DevOps'
        case 'da':
          return 'Data'
        case 'pro':
          return 'Product'
        case 'mg':
          return 'Management'
        default:
          return 'Marketing'
      }
    }

    const formatStatus = stat => {
      switch (stat) {
        case 'in-review':
          return 'In Review'
        case 'active':
          return 'Active'
        case 'intro-requested':
          return 'Intro Requested'
        case 'interview':
          return 'Interview'
        case 'declined':
          return 'Declined'
        case 'withdrew':
          return 'Withdrew'
        default:
          return 'Hired'
      }
    }

    function checkShowApp(target, appShowId) {
      const showToClient = $(target).parent(".Mui-checked").length ? 0 : 1;
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appShowId}/setShowToClient/`;
      const postBody = {
          'showToClient': showToClient,
      };
      const requestMetadata = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              'Authorization': `Token ${token}`,
          },
          body: new URLSearchParams(postBody)
      };

      fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
              console.log(response);
          });
    }

    function buildPagination(paginationData) {
      const funcPagination = {...paginationData};
      funcPagination.hasPrev = true;
      funcPagination.hasNext = true;
      if (funcPagination.current === 1 || funcPagination.countPages === 1) {
        funcPagination.hasPrev = false;
      }
  
      if (funcPagination.current === funcPagination.countPages) {
        funcPagination.hasNext = false;
      }
  
      if (funcPagination.countPages >= 5) {
        if (funcPagination.current === 1 || funcPagination.current === 2) {
          funcPagination.pages = [1, 2, 3, 4, 5];
        } else {
          funcPagination.pages = [funcPagination.current - 2, funcPagination.current - 1, funcPagination.current];
          if (funcPagination.countPages >= funcPagination.current + 1) {
            funcPagination.pages.push(funcPagination.current + 1);
          }
          if (funcPagination.countPages >= funcPagination.current + 2) {
            funcPagination.pages.push(funcPagination.current + 2);
          }
        }
      } else {
        funcPagination.pages = [];
        // eslint-disable-next-line
        for (let i = 0; i < funcPagination.countPages; i++) {
          funcPagination.pages.push(i + 1);
        }
      }
  
      funcPagination.pagesHtml = [];
      if (funcPagination.pages.length > 1) {
        // eslint-disable-next-line
        for (let i = 0; i < funcPagination.pages.length; i++) {
          if (funcPagination.pages[i] === funcPagination.current) {
            funcPagination.pagesHtml.push(<SuiPagination className="pageReload" item active> {funcPagination.pages[i]} </SuiPagination>)
          } else {
            funcPagination.pagesHtml.push(<SuiPagination className="pageReload" item onClick={() => fetchApplications(funcPagination.pages[i])}> {funcPagination.pages[i]} </SuiPagination>)
          }
        }
      }
  
      return funcPagination;
    }

    const buildRows = applications => {

      const rows = []

      if (applications.length > 0) {
        applications.forEach(application => {

          let hiringMethod = "";
          if (application.job.hiringMethod === "dh" || application.job.hiringMethod === "dhf") {
            hiringMethod = <Tooltip title="Direct Hire" placement="top"><div>DH</div></Tooltip>
          } else if (application.job.hiringMethod === "sa" || application.job.hiringMethod === "saf") {
            hiringMethod = <Tooltip title="Staff Augmentation" placement="top"><div>SA</div></Tooltip>
          } else if (application.job.hiringMethod === "dhp") {
            hiringMethod = <Tooltip title="Direct Hire - Payroll" placement="top"><div>DHP</div></Tooltip>
          }
          // else if (application.job.hiringMethod === "dhf") {
          //   hiringMethod = <Tooltip title="Direct Hire - Flexible" placement="top"><div>DHF</div></Tooltip>
          // } else if (application.job.hiringMethod === "saf") {
          //   hiringMethod = <Tooltip title="Staff Augmentation - Flexible" placement="top"><div>SAF</div></Tooltip>
          // }
          
          let isAdmin = false;
          if (user.type === "tecla_admin" || (application.job && application.job.company && user.type === "tecla_manager" && recruiter.companies && recruiter.companies.filter(comp => Number(comp.id) === Number(application.job.company.id)).length)) {
            isAdmin = true;
          }
          let show = <div style={{ width: "80px", textAlign: "center" }}></div>
          if (isAdmin) {
            show = <div style={{ width: "80px", textAlign: "center" }}><Checkbox onClick={e => checkShowApp(e.target, application.id)} defaultChecked={application.showToClient} /></div>
          }

          const applicationObj = {
            appId: application?.id,
            jobId: application?.job?.id,
            position: application?.job?.seniorityName,
            company: [application?.job?.company?.name, application?.job?.company?.photo, application?.job?.company?.id],
            candidate: [`${application?.user?.first_name} ${application?.user?.last_name}`, application?.user?.photo, application.id],
            sourcer: [`${application?.recruiter?.user?.first_name} ${application?.recruiter?.user?.last_name}`, application?.recruiter?.user?.photo],
            method: hiringMethod,
            category: formatCategories(application?.job?.category),
            date: formatDate(application.createdAt),
            lastStatus: application.lastStatusChangeDate ? formatDate(application.lastStatusChangeDate) : "N/A",
            show: show,
            salary: application?.monthly_salary?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }),
            status: formatStatus(application?.status),
            action: {
              jobId: application?.job?.id,
              candidateId: application?.user?.id,
              companyId: application?.job?.company?.id,
              appId: application?.id,
              cvUrl: application?.cv ? `${process.env.REACT_APP_API_ROUTE}${application?.cv}` : null,
              withdrawReason: application?.withdrawReason || null
            },
          }

          rows.push(applicationObj)
        })
      }

      return rows
    }

    fetch(`${url}&${searchParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
      .then(async response => {
        const res = await response.json()

        if (res.success) {
          
          /* PAGINATION */
          const resPagination = buildPagination({ ...res.data.pagination });
          setPagination(resPagination);

          /* APPLICATION RECORDS */
          const tempApplicationStates = {...applicationStates}

          tempApplicationStates.inReview.rows = buildRows(res.data.applications['in-review'])
          tempApplicationStates.prospects.rows = buildRows(res.data.applications.active)
          tempApplicationStates.intro.rows = buildRows(res.data.applications['intro-requested'])
          tempApplicationStates.interviewing.rows = buildRows(res.data.applications.interview)
          tempApplicationStates.hired.rows = buildRows(res.data.applications.hired)
          tempApplicationStates.declined.rows = buildRows(res.data.applications.declined)
          tempApplicationStates.withdrew.rows = buildRows(res.data.applications.withdrew)
          
          setApplicationStates(tempApplicationStates)

          const tempSmallApplicationStates  = {...smallApplicationStates}

          tempSmallApplicationStates.inReview.rows = buildRows(res.data.applications['in-review'])
          tempSmallApplicationStates.prospects.rows = buildRows(res.data.applications.active)
          tempSmallApplicationStates.intro.rows = buildRows(res.data.applications['intro-requested'])
          tempSmallApplicationStates.interviewing.rows = buildRows(res.data.applications.interview)
          tempSmallApplicationStates.hired.rows = buildRows(res.data.applications.hired)
          tempSmallApplicationStates.declined.rows = buildRows(res.data.applications.declined)
          tempSmallApplicationStates.withdrew.rows = buildRows(res.data.applications.withdrew)

          setSmallApplicationStates(tempSmallApplicationStates)

          if (res.data.pagination.count !== totalResults) setTotalResults(res.data.pagination.count)

          setIsFetchingData(false)
        }
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }

  useEffect(() => { fetchApplications() }, [])

  const [tabsOrientation, setTabsOrientation] = useState("horizontal")
  const [applicationsTab, setApplicationTabs] = useState(0)

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal")
    }

    window.addEventListener("resize", handleTabsOrientation)

    handleTabsOrientation()

    return () => window.removeEventListener("resize", handleTabsOrientation)

  }, [tabsOrientation])

  const handleSetApplicationsTabs = (event, newTab) => setApplicationTabs(newTab)

  return (
    <RecruiterDashboardLayout>
      <DashboardNavbar title="Job applications" />
      <SuiTypography variant="button" fontWeight="regular" color="text">
        View and filter through all job applications currently in our system.
      </SuiTypography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Configurator
            jobNameFilter={jobNameFilter}
            setJobNameFilter={setJobNameFilter}
            candidateFirstNameFilter={candidateFirstNameFilter}
            setCandidateFirstNameFilter={setCandidateFirstNameFilter}
            candidateLastNameFilter={candidateLastNameFilter}
            setCandidateLastNameFilter={setCandidateLastNameFilter}
            candidateEmailFilter={candidateEmailFilter}
            setCandidateEmailFilter={setCandidateEmailFilter}
            candidateCountryFilter={candidateCountryFilter}
            setCandidateCountryFilter={setCandidateCountryFilter}
            companyNameFilter={companyNameFilter}
            setCompanyNameFilter={setCompanyNameFilter}
            inReviewStateFilter={inReviewStateFilter}
            setInReviewStateFilter={setInReviewStateFilter}
            activeStateFilter={activeStateFilter}
            setActiveStateFilter={setActiveStateFilter}
            hiredStateFilter={hiredStateFilter}
            setHiredStateFilter={setHiredStateFilter}
            introStateFilter={introStateFilter}
            setIntroStateFilter={setIntroStateFilter}
            interviewStateFilter={interviewStateFilter}
            setInterviewStateFilter={setInterviewStateFilter}
            withdrewStateFilter={withdrewStateFilter}
            setWithdrewStateFilter={setWithdrewStateFilter}
            rejectedStateFilter={rejectedStateFilter}
            setRejectedStateFilter={setRejectedStateFilter}
            engineeringFilter={engineeringFilter}
            setEngineeringFilter={setEngineeringFilter}
            qaFilter={qaFilter}
            setQaFilter={setQaFilter}
            designFilter={designFilter}
            setDesignFilter={setDesignFilter}
            devopsFilter={devopsFilter}
            setDevopsFilter={setDevopsFilter}
            dataFilter={dataFilter}
            setDataFilter={setDataFilter}
            productFilter={productFilter}
            setProductFilter={setProductFilter}
            managementFilter={managementFilter}
            setManagementFilter={setManagementFilter}
            marketingFilter={marketingFilter}
            setMarketingFilter={setMarketingFilter}
            salesFilter={salesFilter}
            setSalesFilter={setSalesFilter}
            betterForAnotherRoleFilter={betterForAnotherRoleFilter}
            setBetterForAnotherRoleFilter={setBetterForAnotherRoleFilter}
            compensationFilter={compensationFilter}
            setCompensationFilter={setCompensationFilter}
            cultureFitFilter={cultureFitFilter}
            setCultureFitFilter={setCultureFitFilter}
            employmentDurationFilter={employmentDurationFilter}
            setEmploymentDurationFilter={setEmploymentDurationFilter}
            englishSkillsFilter={englishSkillsFilter}
            setEnglishSkillsFilter={setEnglishSkillsFilter}
            futureOpportunitiesFilter={futureOpportunitiesFilter}
            setFutureOpportunitiesFilter={setFutureOpportunitiesFilter}
            locationFilter={locationFilter}
            setLocationFilter={setLocationFilter}
            motivationsFilter={motivationsFilter}
            setMotivationsFilter={setMotivationsFilter}
            offerDeclinedFilter={offerDeclinedFilter}
            setOfferDeclinedFilter={setOfferDeclinedFilter}
            overqualifiedFilter={overqualifiedFilter}
            setOverqualifiedFilter={setOverqualifiedFilter}
            positionClosedFilter={positionClosedFilter}
            setPositionClosedFilter={setPositionClosedFilter}
            timingFilter={timingFilter}
            setTimingFilter={setTimingFilter}
            underqualifiedFilter={underqualifiedFilter}
            setUnderqualifiedFilter={setUnderqualifiedFilter}
            unresponsiveFilter={unresponsiveFilter}
            setUnresponsiveFilter={setUnresponsiveFilter}
            withdrewFilter={withdrewFilter}
            setWithdrewFilter={setWithdrewFilter}
            fetchApplications={fetchApplications}
          />
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ minHeight: "65vh" }}>
            {isFetchingData ?
                <SuiBox display="flex" justifyContent="center" alignItems="center" my={30} >
                  <CircularProgress size={40} />
                </SuiBox>
              :
              <>
                <SuiBox pt={3} px={3}>
                  <SuiBox display="flex" justifyContent="start" alignItems="center" width="100%">
                    <SuiBox width="100%">
                      <AppBar position="static">
                        <Tabs orientation={tabsOrientation} value={applicationsTab} onChange={handleSetApplicationsTabs}>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={applicationStates?.inReview?.rows?.length}
                              max={999}
                              onClick={() => setApplicationTabs(0)}
                            >
                              <Tab label="Applicants" />
                            </Badge>
                          </SuiBox>

                          
                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={applicationStates?.prospects?.rows?.length}
                              max={999}
                              onClick={() => setApplicationTabs(1)}
                            >
                              <Tab label="Submitted" />
                            </Badge>
                          </SuiBox>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={applicationStates?.intro?.rows?.length}
                              max={999}
                              onClick={() => setApplicationTabs(2)}
                            >
                              <Tab label="Intro Requested" />
                            </Badge>
                          </SuiBox>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={applicationStates?.interviewing?.rows?.length}
                              max={999}
                              onClick={() => setApplicationTabs(3)}
                            >
                              <Tab label="Interviewing" />
                            </Badge>
                          </SuiBox>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={applicationStates?.declined?.rows?.length}
                              max={999}
                              onClick={() => setApplicationTabs(4)}
                            >
                              <Tab label="Declined" />
                            </Badge>
                          </SuiBox>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={applicationStates?.hired?.rows?.length}
                              max={999}
                              onClick={() => setApplicationTabs(5)}
                            >
                              <Tab label="Hired" />
                            </Badge>
                          </SuiBox>                          

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={applicationStates?.withdrew?.rows?.length}
                              max={999}
                              onClick={() => setApplicationTabs(6)}
                            >
                              <Tab label="Withdrew" />
                            </Badge>
                          </SuiBox>

                        </Tabs>
                      </AppBar>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
                
                <SuiBox p={2} mt={1} width="100%" height="100%" minHeight="50vh">
                  <ApplicationStateTable title="In Review" value={applicationsTab} index={0} table={applicationStates.inReview} smallTable={smallApplicationStates.inReview} />
                  <ApplicationStateTable title="Applicants" value={applicationsTab} index={1} table={applicationStates.prospects} smallTable={smallApplicationStates.prospects} />
                  <ApplicationStateTable title="Intro Requested" value={applicationsTab} index={2} table={applicationStates.intro} smallTable={smallApplicationStates.intro} />
                  <ApplicationStateTable title="Interviewing" value={applicationsTab} index={3} table={applicationStates.interviewing} smallTable={smallApplicationStates.interviewing} />
                  <ApplicationStateTable title="Declined" value={applicationsTab} index={4} table={applicationStates.declined} smallTable={smallApplicationStates.declined} />
                  <ApplicationStateTable title="Hired" value={applicationsTab} index={5} table={applicationStates.hired} smallTable={smallApplicationStates.hired} />
                  <ApplicationStateTable title="Withdrew" value={applicationsTab} index={6} table={applicationStates.withdrew} smallTable={smallApplicationStates.withdrew} />
                </SuiBox>

                <SuiBox sx={{ mt: { xs: 'auto ', md: -8 } }} mb={5} mr={5} className="paginationBox" style={{ margin: "20px auto 40px auto" }}>
                  <SuiPagination>
                    {(pagination.count > 0 && pagination.hasPrev) && (
                      <SuiPagination item className="pageReloadPrev" onClick={() => fetchApplications(pagination.prev)}>
                        <Icon>keyboard_arrow_left</Icon>
                      </SuiPagination>
                    )}

                    {(pagination.count > 0) && pagination.pagesHtml}

                    {(pagination.count > 0 && pagination.hasNext) && (
                      <SuiPagination item className="pageReloadNext" onClick={() => fetchApplications(pagination.next)}>
                        <Icon>keyboard_arrow_right</Icon>
                      </SuiPagination>
                    )}
                  </SuiPagination>
                </SuiBox>
              </>
            }
          </Card >

        </Grid>
      </Grid>
    </RecruiterDashboardLayout>
  );
}

export default ApplicationsRecruiter;
