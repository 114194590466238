/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from "react-router-dom";
import { useEffect } from "react"

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

import SuiAlert from "components/SuiAlert";

// Soft UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Soft UI Dashboard PRO React base styles
// import typography from "assets/theme/base/typography";

// Authentication layout components
// import Footer from "layouts/authentication/components/Footer";

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

// Images
// import error404 from "assets/images/illustrations/error-404.png";
function Activate() {
  const searchParams = new URLSearchParams(window.location.search);
  const urlId = searchParams.get('id');
  const urlToken = searchParams.get('token');
  const urlAll = searchParams.get('all');

  function resendActivateAccount() {
    const postBody = {
      'id': urlId,
      'token': urlToken,
      'all': urlAll
    };
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/jobs/declineAutoSourcing/`;
    const requestMetadata = {
      method: 'POST',
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      },
      body: new URLSearchParams(postBody)
    };

    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          console.log("send")
        }
      });
  }

  useEffect(() => { 
    resendActivateAccount();
  }, [])


  return (
    <PageLayout white>
      <DefaultNavbar
        routes={pageRoutes}
        transparent
        action={{
          type: "external",
          route: "/",
          label: "home",
          color: "info",
        }}
      />
      <SuiBox my={24} height="calc(100vh - 24rem)">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={8} ml={2}>
              <SuiBox className="alertSuccessBox" display="none">
                <SuiAlert color="info" className="alertSuccess">Mail sent.</SuiAlert>
              </SuiBox>
              <SuiBox className="alertErrorBox" display="none">
                <SuiAlert color="secondary" className="alertError">Please wait at least one minute before requesting another link.</SuiAlert>
              </SuiBox>
              <SuiBox
                fontSize="3.5rem"
                // fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }}
                lineHeight={1.2}
              >
                <SuiTypography variant="inherit" color="info" textGradient fontWeight="bold">
                  Confirmation email sent!
                </SuiTypography>
              </SuiBox>
              <SuiBox mt={1} mb={2}>
                <SuiTypography variant="body1" color="text">
                  A confirmation link has been sent to your email address. Please follow the link to validate your account.
                </SuiTypography>
              </SuiBox>
              <SuiBox mt={4} mb={2}>
                <SuiButton variant="gradient" color="info" onClick={resendActivateAccount}>
                  Resend confirmation link
                </SuiButton>
              </SuiBox>
            </Grid>
            {/* <Grid item xs={12} lg={6}>
              <SuiBox component="img" src={Activate} alt="error-404" width="100%" />
            </Grid> */}
          </Grid>
        </Grid>
      </SuiBox>
    </PageLayout>
  );
}

export default Activate;
