/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import { useParams } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAvatar from "components/SuiAvatar";
// MUI components
import Modal from '@mui/material/Modal';
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
// Icons
import leverIcon from "assets/images/LeverIcon.png"

function ActionCell({ candidate, fetchFolderContent, isOwner }) {

	// Folder id
	const { id } = useParams()

	const apiUrl = process.env.REACT_APP_API_ROUTE;
	let candidateUrl = "";
	let cvUrl = "";
	let applicationUrl = "";
	let leverUrl = "";
	if (candidate && candidate.user) {
		candidateUrl = `/t/profile/${candidate.user.id}`;
		cvUrl = candidate.cv ? `${apiUrl}${candidate.cv}` : "";
		applicationUrl = `/r/applications/${candidate.user.id}`;
	}


	if (candidate && candidate.leverUrl) {
		leverUrl = candidate.leverUrl;
	}

	const recruiterFolders = ReactSession.get("recruiterFolders")
	const token = ReactSession.get("token")

	const folderOptions = []
	if (Array.isArray(recruiterFolders)) {
		recruiterFolders.forEach(folder => folderOptions.push({ value: folder.id, label: folder.name, public: folder.public }))
	}

	const [openModal, setOpenModal] = useState(false)
	const [showSpinner, setShowSpinner] = useState(false)
	const [showDoneIcon, setShowDoneIcon] = useState(false)

	const deleteCandidateFromFolder = (folder, candidatesArray) => {
		const requestBody = {
			name: folder.name,
			favorite: folder.favorite,
			candidates: candidatesArray.filter(candId => candId !== candidate.user.id),
		}

		fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${id}/updateFolder/`, {
			method: 'PATCH',
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Token ${token}`
			},
			body: JSON.stringify(requestBody)
		})
			.then(async response => {
				const data = await response.json()

				if (data.success) {
					setShowSpinner(false)
					setShowDoneIcon(true)
					fetchFolderContent()

					setTimeout(() => {
						setOpenModal(false)
						setShowDoneIcon(false)
					}, 1500)
				}
			})
			.catch(error => console.error('There was an error!', error))
	}

	const fetchFolderData = () => {
		setShowSpinner(true)

		fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${id}/getFolder/`, {
			method: 'GET',
			headers: { "Authorization": `Token ${token}` }
		})
			.then(async response => {
				const data = await response.json()

				if (data.success) {
					const candidatesArray = []
					// eslint-disable-next-line
					for (const candidate of data.data.candidates) candidatesArray.push(candidate.user.id)

					deleteCandidateFromFolder(data.data, candidatesArray)
				}
			})
			.catch(error => console.error('There was an error!', error))
	}

	const deleteCandidate = () => fetchFolderData()

	return (
		<SuiBox display="flex" alignItems="center" >

			{ cvUrl ? (
				<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={cvUrl} target="_blank">
						<Tooltip title="View CV" placement="top">
							<AssignmentIcon />
						</Tooltip>
					</SuiTypography>
				</SuiBox>
			) : null }

			<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={candidateUrl} target="_blank">
					<Tooltip title="View profile" placement="top">
						<PersonIcon />
					</Tooltip>
				</SuiTypography>
			</SuiBox>

			<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={applicationUrl} target="_blank">
					<Tooltip title="Applications" placement="top">
						<SendIcon />
					</Tooltip>
				</SuiTypography>
			</SuiBox>

			{ isOwner ? (
				<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} onClick={() => setOpenModal(true)}>
						<Tooltip title="Remove from folder" placement="top">
							<DeleteIcon />
						</Tooltip>
					</SuiTypography>
				</SuiBox>
			) : null}

			{ leverUrl && (
				<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={leverUrl} target="_blank">
						<Tooltip title="Lever Profile" placement="top">
							<SuiAvatar src={leverIcon} alt="Lever icon" size="xs" />
						</Tooltip>
					</SuiTypography>
				</SuiBox>
			)}

			<Modal open={openModal} onClose={() => setOpenModal(false)}>
				<SuiBox sx={{
					width: { xs: "100vw", md: "650px" },
					height: { xs: "101vh", md: "auto" },
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					bgcolor: 'background.paper',
					border: 'none',
					boxShadow: 24,
					borderRadius: { xs: 0, md: 4 },
					p: 3,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}} >

					<SuiTypography variant="h5" textAlign="center">
						Do you want to remove the candidate from this folder?
					</SuiTypography>

					<SuiBox width="80%" display="inherit" justifyContent="center" mx="auto" mt={2}>
						<SuiButton
							sx={{ width: "100%", marginRight: "1rem" }}
							color="info"
							variant="outlined"
							disabled={showSpinner || showDoneIcon}
							onClick={() => {
								setOpenModal(false)
							}}>
							Cancel
						</SuiButton>
						<SuiButton
							sx={{ width: "100%" }}
							color="info"
							variant="gradient"
							onClick={() => deleteCandidate()}
						>
							Confirm
						</SuiButton>
					</SuiBox>

				</SuiBox>
			</Modal>

		</SuiBox>
	)
}

ActionCell.defaultProps = {
	candidate: {},
	isOwner: false
}

ActionCell.propTypes = {
	candidate: PropTypes.objectOf(PropTypes.any),
	fetchFolderContent: PropTypes.func.isRequired,
	isOwner: PropTypes.bool
}

export default ActionCell
