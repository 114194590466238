/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
// import SuiAvatar from "components/SuiAvatar";
// import SuiProgress from "components/SuiProgress";
// import SuiButton from "components/SuiButton";

function ProfilesList({ title, profiles }) {
  const renderProfiles = profiles.map(({ name }) => (
    <SuiBox key={name} display="flex" alignItems="center" py={1} mb={1} mr={1}>
      {/* <SuiBox mr={2}>
        <SuiAvatar src={image} alt="interest logo" variant="rounded" shadow="md" />
      </SuiBox> */}
      {/* <SuiTypdgiography
          variant="button"
          fontWeight="medium"
          color="text"
          textTransform="capitalize"
        >
          {name}
        </SuiTypdgiography> */}
      <SuiBadge badgeContent={name} color="secondary" variant="contained" circular size="sm" container />

      {/* <SuiBox ml="auto">
        {action.type === "internal" ? (
          <SuiButton component={Link} to={action.route} variant="text" color="info">
            {action.label}
          </SuiButton>
        ) : (
          <SuiButton
            component="a"
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant="text"
            color={action.color}
          >
            {action.label}
          </SuiButton>
        )}
      </SuiBox> */}
    </SuiBox>
  ));

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox display="flex" flexDirection="row" flexWrap="wrap" p={0} m={0}>
          {renderProfiles}
        </SuiBox>
      </SuiBox>
    </Card >
  );
}

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProfilesList;
