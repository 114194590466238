/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// ProductsList page components
import NameAndPictureCell from "layouts/recruiterSourcingContent/NameAndPictureCell/nameAndPictureCell"
// MUI components
// import Checkbox from "@mui/material/Checkbox";
// import { ReactSession } from "react-client-session"

// ReactSession.setStoreType("localStorage")
// const aiSourcingSelectAll = ReactSession.get("aiSourcingSelectAll")

export default {
  columns: [
    {
      Header: "Candidate",
      accessor: "candidate",
      Cell: ({ value: [name, image, candidateId] }) => <NameAndPictureCell name={name} image={image} candidateId={candidateId} />,
    },
    { Header: "Status", accessor: "status" },
    { Header: "Action", accessor: "action" },
  ],

  rows: [],
}
