/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useEffect } from 'react'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
// MUI componentes
import useMediaQuery from '@mui/material/useMediaQuery'
// Newsletters page components
import StudentDashboardLayout from 'examples/LayoutContainers/StudentDashboardLayout'
import BaseLayout from 'layouts/pages/account/components/BaseLayout'
import NlTypeCard from './nlTypeCard'

function Newsletters() {
  
  const student = ReactSession.get("student")

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  useEffect(() => { document.title = student ? 'Newsletters | Boost' : 'Newsletters | TECLA' }, [])

  const setLayout = children => {
    if (student) return <StudentDashboardLayout>{children}</StudentDashboardLayout>
    return <BaseLayout>{children}</BaseLayout>
  }

  return (
    <>
      {setLayout(
        <>
          <SuiTypography variant='h3' fontWeight='bold' ml={1} mb={3}>
            Newsletters
          </SuiTypography>

          <SuiBox display='flex' alignItems='center' flexWrap='wrap'>

            <SuiBox
              sx={{
                width: isMobile ? '100%' : '31%',
                margin: isMobile ? '3%' : '1%',
                cursor: 'pointer'
              }}
            >
              <NlTypeCard
                title='English Tips & Tricks'
                description='Wednesdays'
                link={student ? '/newsletters/1' : '/t/newsletters/1'}
                image={`${process.env.REACT_APP_API_ROUTE}/media/users/Newsletter_English.png`}
              />
            </SuiBox>
            
            <SuiBox
              sx={{
                width: isMobile ? '100%' : '31%',
                margin: isMobile ? '3%' : '1%',
                cursor: 'pointer'
              }}
            >
              <NlTypeCard
                title='Lessons on Employability'
                description='Mondays, Tuesdays and Fridays'
                link={student ? '/newsletters/2' : '/t/newsletters/2'}
                image={`${process.env.REACT_APP_API_ROUTE}/media/users/Newsletter_Levelup.png`}
              />
            </SuiBox>
          </SuiBox >
        </>
      )}
    </ >
  )
}

export default Newsletters