/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
// Soft UI Dashboard PRO React context
import CreateCandidate from 'examples/Modal/CreateCandidate';

function DashboardNavbar({ title }) {

  return (
    <SuiBox container xs={12} md={12} display='flex' flexWrap='wrap' width='100%' alignItems='right' justifyContent={{ sx: 'start', xl: 'space-between' }} my={2}>
      <SuiTypography variant='h3' fontWeight='bold' mr={5}>
        {title}
      </SuiTypography>

      <SuiBox className='talentRight'>
        <CreateCandidate text='test' />
      </SuiBox>
    </SuiBox >
  );
}

// Setting default values for the props of DashboardNavbar

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DashboardNavbar;
