/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
// Soft UI Dashboard PRO React example components
// DefaultNavbar dropdown menus
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";
import PagesMenu from "examples/Navbars/DefaultNavbar/Menus/PagesMenu";
import AuthenticationMenu from "examples/Navbars/DefaultNavbar/Menus/AuthenticationMenu";
import EcommerceMenu from "examples/Navbars/DefaultNavbar/Menus/EcommerceMenu";
import ApplicationsMenu from "examples/Navbars/DefaultNavbar/Menus/ApplicationsMenu";
import DocsMenu from "examples/Navbars/DefaultNavbar/Menus/DocsMenu";
// Tecla logo
// import teclaLight from "assets/images/logos/Tecla_Logo_Light.png";

function DefaultNavbar({ routes, transparent, light }) {
  const [pagesMenu, setPagesMenu] = useState(false);
  const [authenticationMenu, setAuthenticationMenu] = useState(false);
  const [ecommerceMenu, setEcommerceMenu] = useState(false);
  const [applicationsMenu, setApplicationsMenu] = useState(false);
  const [docsMenu, setDocsMenu] = useState(false);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const closePagesMenu = () => setPagesMenu(false);
  const closeAuthenticationMenu = () => setAuthenticationMenu(false);
  const closeEcommerceMenu = () => setEcommerceMenu(false);
  const closeApplicationsMenu = () => setApplicationsMenu(false);
  const closeDocsMenu = () => setDocsMenu(false);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Container>
      <SuiBox
        py={{ xs: 0, lg: 1 }}
        px={{ xs: transparent ? 4 : 5, sm: transparent ? 2 : 5, lg: transparent ? 0 : 5 }}
        my={2}
        mx={{ xs: 0, lg: 2 }}
        width={{ xs: '100%', sm: "100%", md: "100%", lg: "width=calc(100%-48px)" }}
        borderRadius="section"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent={{ xs: "center", sm: "center", md: "center", lg: "left" }}
        alignItems="center"
        position="absolute"
        left={{ xs: 0, sm: 0, md: 0, lg: -60 }}
        zIndex={3}
        sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >

        {/* <SuiBox
          component={Link}
          to="/"
          py={transparent ? 1.5 : 0.75}
          lineHeight={1}
          display={isSmallScreen ? 'none' : 'flex'}
          justifyContent="center"
          alignItems="center"
          width="50%"
          m={2}
          sx={{ border: "black solid 1px" }}
        >
          <img width="150vw" src={teclaLight} alt="Tecla logo with dark font" />
        </SuiBox> */}
      </SuiBox>

      <PagesMenu routes={routes} open={pagesMenu} close={closePagesMenu} />

      <AuthenticationMenu
        routes={routes}
        open={authenticationMenu}
        close={closeAuthenticationMenu}
      />

      <EcommerceMenu routes={routes} open={ecommerceMenu} close={closeEcommerceMenu} />

      <ApplicationsMenu routes={routes} open={applicationsMenu} close={closeApplicationsMenu} />

      <DocsMenu routes={routes} open={docsMenu} close={closeDocsMenu} />

      {mobileView &&<DefaultNavbarMobile routes={routes} open={mobileNavbar} close={closeMobileNavbar} />}
    </Container >
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
};

export default DefaultNavbar;
