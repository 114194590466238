/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// react-router-dom components
import { useLocation } from "react-router-dom";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// Soft UI Dashboard PRO React context
import { useSoftUIController, setLayout } from "context";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboardCompany");
  }, [pathname]);

  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");

  const getInvoicesNotificationsCount = async () => {
    if (token) {
      const headers = { "Content-Type": "application/json" };
      headers.Authorization = `Token ${token}`;
      fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/getInvoicesNotification/`, { headers, })
        .then(async response => {
          const data = await response.json();
          if (data.detail && data.detail.includes("Invalid token")) {
            ReactSession.set("user", null);
            ReactSession.set("candidate", null);
            ReactSession.set("company", null);
            ReactSession.set("recruiter", null);
            window.location.replace("/t/login");
          }
          if (data.success) ReactSession.set("invoicesNotificationsCount", data.data);
        })
        .catch(error => console.error('There was an error!', error));
    }
  }

  useEffect(() => { getInvoicesNotificationsCount() }, [])

  return (
    <SuiBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </SuiBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
