/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import Swal from "sweetalert2";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from '@mui/material/Modal';
// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiAlert from 'components/SuiAlert'


function WithAttachedFunction({jobId, withdrawApplication}) {

  const showAlert = () => { // eslint-disable-line
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-error removeApplication",
        cancelButton: "button button-success",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Withdraw application",
        text: "Are you sure to withdraw your application from this Job?",
        showCancelButton: true,
        confirmButtonText: `<span data-id=${jobId}>Withdraw</span>`,
        cancelButtonText: "Cancel",
        reverseButtons: false,
      })
    .then((result) => {
      if (result.value) {
        withdrawApplication(jobId);
      }
    });
  };

  const [openModal, setOpenModal] = useState(false)
  const [withdrawReason, setWithdrawReason] = useState("")
  const [reasonError, setReasonError] = useState("")
  

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleWithdraw = () => {
    if (withdrawReason && withdrawReason.length >= 10 && withdrawReason.length <= 500) {
      withdrawApplication(jobId, withdrawReason)
    } else if(!withdrawReason || withdrawReason.length < 10) {
      setReasonError("Please add a comment with at least 10 characters")
      setTimeout(() => setReasonError(""), 3000)
    } else if(!withdrawReason || withdrawReason.length > 500) {
      setReasonError("Please add a shorter comment with less than 500 characters")
      setTimeout(() => setReasonError(""), 3000)
    }
  }

  return (
    <>
      <SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => setOpenModal(true)}>
        <Tooltip title="Withdraw application" placement="left">
          <Icon>delete</Icon>
        </Tooltip>
      </SuiTypography>

      {openModal && (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SuiBox sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: "100vw", md: "80vw", xl: "60vw" },
            height: { xs: "101vh", md: "auto" },
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            borderRadius: { xs: 0, md: 4 },
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}>
            {reasonError ? (
              <SuiBox sx={{ display: "block", width: "100%" }}>
                <SuiAlert color="error">{reasonError}</SuiAlert>
              </SuiBox>
             ) : null }
            <SuiBox display="flex">
              <SuiBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%">
                <SuiTypography variant="h3" color="dark">Withdraw application</SuiTypography>
                <SuiTypography variant="body" color="dark">Are you sure to withdraw your application from this Job?</SuiTypography>
              </SuiBox>

              <Icon
                onClick={handleClose}
                sx={{
                  cursor: "pointer",
                  borderRadius: 1,
                  backgroundColor: { xs: "#21c0fd", md: "#fff" },
                  color: { xs: "#fff", md: "#344767" },
                  position: "absolute",
                  top: 15,
                  right: 15
                }}
              >
                close
              </Icon>
            </SuiBox>

            <SuiBox my={6}>
              <SuiInput type="text" multiline rows={5} placeholder="Type here..." onChange={e => setWithdrawReason(e.target.value)} />
              <SuiTypography
                  mt={1}
                  variant="subtitle2"
                  color={withdrawReason.length > 500 ? "error" : "auto"}
                  fontSize=".9rem"
                  align="right"
              >
                  {withdrawReason.length || "0"}/500 chars.
              </SuiTypography>
              <SuiTypography variant="body2" color="dark" textAlign="center" mt={2}>To withdraw, please add some comments explaining the reason. (10 to 500 characters)</SuiTypography>
            </SuiBox>
            
            <SuiBox width="80%" display="inherit" justifyContent="center" mx="auto" mt={2}>
              <SuiButton
                sx={{ width: "100%", marginRight: "1rem" }}
                color="info"
                variant="outlined"
                onClick={handleClose}
                className="button button-error removeApplication"
              >
                Cancel
              </SuiButton>

              <SuiButton
                sx={{ width: "100%" }}
                color="info"
                variant="gradient"
                disabled={withdrawReason.length < 1}
                onClick={() => handleWithdraw()}
                className="button button-success"
              >
                Confirm
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </Modal>
      )}
    </>
  );
}

WithAttachedFunction.defaultProps = {
  jobId: 0,
  withdrawApplication: null
}

WithAttachedFunction.propTypes = {
  jobId: PropTypes.number,
  withdrawApplication: PropTypes.func,
};

export default WithAttachedFunction;