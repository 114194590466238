/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { sliceString } from "utils/commonFunctions";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Badge from '@mui/material/Badge';
import useMediaQuery from "@mui/material/useMediaQuery"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
import ProgressDoughnutChart from "examples/Charts/DoughnutCharts/ProgressDoughnutChart";
// Placeholder avatar
import empty from "assets/images/empty.png";
import mockCompanyAvatar from "assets/images/mockCompanyAvatar.png";
import UserGlobe from "examples/RecruiterGlobe";

function RecruiterJobCard({
                            by,
                            job,
                            dropdown,
                            title,
                            subtitle,
                            description,
                            skills,
                            value,
                            action,
                            sourcing,
                            manage,
                            badge,
                            applicationsCounter,
                            reopened,
                            recruiter,
                            publicJob,
                            companyId,
                            JobHeight, // eslint-disable-line
                            recruiters,
                            managers,
                            setJobIdOpen,
                            setJobManagersOpen,
                            tags
                          }) { 
 
  ReactSession.setStoreType("localStorage")
  const user = ReactSession.get("user");
  const recruiterSesion = ReactSession.get("recruiter");

  let isCompany = false;
  if (user && user.type === "company_owner") {
    isCompany = true;
  }
  let filterCompany = [];
  if (recruiterSesion && recruiterSesion.companies) {
    filterCompany = recruiterSesion.companies.filter(x => x.id === companyId);
  }
  let isRecruiter = false;
  let isAdmin = false;
  if (user) {
    isRecruiter = (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin") // eslint-disable-line
    if ((user.type === "tecla_admin") || (filterCompany && filterCompany.length)) {
      isAdmin = true;
    }
  }

  let badgeColor = badge.color;
  if (badge.label === "in_review") {
    badgeColor = "secondary";
  } else if (badge.label === "pause") {
    badgeColor = "primary";
  } else if (badge.label === "closed") {
    badgeColor = "error";
  } else if (badge.label === "active") {
    badgeColor = "success";
  }

  function numberFormat(number) {
    return number.toLocaleString('en');
  }

  let progress = {
    datasets: {
      label: "Total prospects",
      backgroundColors: [],
      data: [],
    },
    labels: [],
  }

  let apSum = 0;
  if (applicationsCounter) {
    const ap = applicationsCounter;
    const data = [];
    const labels = [];
    const backgroundColors = [];

    if (isRecruiter && ap["in-review"]) {
      data.push(ap["in-review"]);
      labels.push(`Applicants (${ap["in-review"]})`);
      backgroundColors.push("dark")
    }

    if (ap.active) {
      data.push(ap.active);
      if (isRecruiter) labels.push(`Submitted (${ap.active})`)
      else labels.push(`Applicants (${ap.active})`)
      backgroundColors.push("info")
    }

    if (ap["intro-requested"]) {
      data.push(ap["intro-requested"]);
      labels.push(`Intro Requested (${ap["intro-requested"]})`);
      backgroundColors.push("dark")
    }

    if (ap.interview) {
      data.push(ap.interview);
      labels.push(`Interviewing (${ap.interview})`);
      backgroundColors.push("primary")
    }

    if (ap.declined) {
      data.push(ap.declined);
      labels.push(`Declined (${ap.declined})`);
      backgroundColors.push("error")
    }

    if (ap.hired) {
      data.push(ap.hired);
      labels.push(`Hired (${ap.hired})`);
      backgroundColors.push("success")
    }

    if (isRecruiter) apSum = ap.active + ap["in-review"] + ap["intro-requested"] + ap.interview + ap.declined + ap.hired;
    else apSum = ap.active + ap["intro-requested"] + ap.interview + ap.declined + ap.hired;
    

    progress = {
      datasets: {
        label: "Total prospects",
        backgroundColors: backgroundColors,
        data: data,
      },
      labels: labels,
    }
  }

  let jobLabel = badge.label;
  if (badge.label === "in_review") {
    jobLabel = "in review";
  }

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const formatHiringMethod = hm => {
    if (hm === "dh") return "DH"
    if (hm === "dhp") return "DHP"
    if (hm === "dhf") return "DH"
    if (hm === "saf") return "SA"
    return "SA" 
  }

  const formatHiringMethodColor = hm => {
    if (hm === "sa") return "pink"
    return "info" 
  }

  let recruitersArray = recruiters;
  if (!recruitersArray) {
    recruitersArray = [];
  }

  let managersArray = managers;
  if (!managersArray) {
    managersArray = [];
  }

  const formatSubtitle = sub => {
    if (sub.length < 90) return sub
    return `${sub.substring(0, 90)} ...`
  }

  const avatarBorderColor = () => {
    if (job.status === "active") return "#9aeb48"
    if (job.status === "closed") return "red"
    return "gray"
  }

  const avatarIcon = () => {
    if (job.status === "active") return <PlayArrowIcon sx={{ color: avatarBorderColor() }} />
    if (job.status === "closed") return <StopIcon sx={{ color: avatarBorderColor() }} />
    return <PauseIcon sx={{ color: avatarBorderColor() }} />
  }

  function setOpenValues(jobId, jobManagers) {
    setJobIdOpen(job.id)
    if (setJobManagersOpen && jobManagers) {
      setJobManagersOpen(jobManagers)
    }
  }

  return (
    <Card sx={{ height: { xs: "auto", md: 700 } }}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="flex-start" p={1}>
        {by.date ? (
          <SuiBox display="flex" mr={2}>
            {isRecruiter && (

              <SuiBox
                  sx={{
                    position: "relative",
                    width: "3.1rem",
                    height: "3.1rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                <SuiAvatar
                  src={by.logo ? `${process.env.REACT_APP_API_ROUTE}${by.logo}` : mockCompanyAvatar}
                  alt="company avatar"
                  sx={{
                    border: `${avatarBorderColor()} solid 3px`
                  }}
                />

                <SuiBox
                  sx={{
                    position: "absolute",
                    bottom: -5,
                    left: 0,
                    width: "25px",
                    height: "25px",
                    background: "white",
                    borderRadius: "50%",
                    border: `${avatarBorderColor()} solid 2px`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {avatarIcon()}
                </SuiBox>
              </SuiBox>
            )}
            
            <SuiBox mx={1.5} display="flex" flexDirection="column" justifyContent="center">
              
              <SuiBox display="flex" flexWrap="wrap" maxWidth={isMobile ? "80px" : "100%"}>
                <SuiBox display="flex" flexDirection="column" mb={.5}>
                  {/* TITLE */}
                  <SuiTypography variant={isMobile ? "h6" : "h5"} mr={isMobile ? 0 : 1} mt={1} mb={.5}>{sliceString(title, 30)}</SuiTypography>
                  {/* SUBTITLE */}
                  {!isRecruiter && subtitle && <SuiTypography variant="caption" color="text">{formatSubtitle(subtitle)}</SuiTypography>}
                </SuiBox>

                {/* AI sourcing badge */}
                {isRecruiter && job.autoSourcing && (
                  <SuiBadge
                    badgeContent="AI Sourcing"
                    variant="contained"
                    circular="true"
                    size="sm"
                    color="warning"
                    container sx={{ height: 30, my: 1, mx: .5 }}
                  />
                )}

                {/* Hotjob badge */}
                {isRecruiter && job.isHot && (
                  <SuiBadge
                    badgeContent="Hot"
                    variant="contained"
                    circular="true"
                    size="sm"
                    color="error"
                    container sx={{ height: 30, my: 1, mx: .5 }}
                  />
                )}
              </SuiBox>

              {by.date ? (
                <SuiTypography variant="caption" color="text" mt={isMobile ? 1 : -1}>
                  {recruiter ? (
                    <SuiTypography variant="caption" color="inherit" component={Link} to={`/r/company/${companyId}`} target="_blank" >
                      {by.name}&nbsp;-&nbsp;
                    </SuiTypography>
                  ) : null}
                  {by.date}
                </SuiTypography>
              ) : null}

              {isRecruiter && subtitle && <SuiTypography variant="caption" color="text">{formatSubtitle(subtitle)}</SuiTypography>}

            </SuiBox>
          </SuiBox>
        ) : null}

        {dropdown && setJobIdOpen ? (
          <SuiBox display="flex" justifyContent="center" alignItems="center" onClick={() => setOpenValues(job.id, job.managers)}>
            <SuiBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-end"
              sx={{ maxWidth: { xs: "3rem", md: "40rem" } }}
            >
              {/* STATUS BADGE */}
              {/* <SuiBadge
                color={badgeColor}
                badgeContent={reopened ? 're-opened' : jobLabel}
                size="lg"
                variant="contained"
                container
              /> */}
              
              {/* Tags badges */}
              {isRecruiter && tags.length > 0 && (
                <SuiBox
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="flex-end"
                >
                  {
                    tags.map(tag =>
                      <SuiBadge
                        badgeContent={tag.name}
                        variant="contained"
                        circular="true"
                        color={tag.name === "HIGH PRIORITY" ? "purple" : "secondary"}
                        size="sm"
                        container
                        sx={{ height: 30, my: 1, mx: .5, display: "flex", justifyContent: "center", alignItems: "center"  }}
                      />  
                    )
                  }
                </SuiBox>
              )}
            </SuiBox>

            {/* DROPDOWN */}
            {!reopened && (isAdmin || !isRecruiter) && (user.type === "tecla_admin" || user.type === "tecla_manager") && (
              <SuiBox>
                <SuiTypography
                  color="secondary"
                  onClick={dropdown.action}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ml: "1rem",
                    alignSelf: "flex-start",
                    px: .25,
                    py: isMobile ? .25 : 1.25,
                  }}
                >
                  <Icon fontSize="default" sx={{ cursor: "pointer" }}>more_vert</Icon>
                </SuiTypography>
              </SuiBox>
          )}
          </SuiBox>
        ) : (
          <SuiBox display="flex" justifyContent="center" alignItems="center">

            {/* STATUS BADGE */}
            <SuiBadge
              color={badgeColor}
              badgeContent={reopened ? 're-opened' : jobLabel}
              size="lg"
              variant="contained"
              container 
            />
            
            {!reopened && (isAdmin || !isRecruiter)  && (user.type === "tecla_admin" || user.type === "tecla_manager") && (
              <SuiBox>
                <SuiTypography
                  color="secondary"
                  onClick={dropdown.action}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ml: "1rem",
                    alignSelf: "flex-start",
                    px: .25,
                    py: isMobile ? .25 : 1.25,
                  }}
                >
                  <Icon fontSize="default" sx={{ cursor: "pointer" }}>more_vert</Icon>
                </SuiTypography>
              </SuiBox>
            )}

          </SuiBox>
        )}
        {dropdown.menu}
      </SuiBox>

      <Divider />

      {apSum === 0 && (
        <SuiBox display="flex" alignItems="center" justifyContent="center" height="100%">
          <SuiBox>
            <SuiTypography variant="body1" fontWeight="bold">No applicants, yet!</SuiTypography>
            <SuiTypography variant="body2" color="dark">Invites are being sent out.</SuiTypography>
          </SuiBox>
          <SuiBox
            component="img"
            src={empty}
            width="auto"
            height="100px"
          />
        </SuiBox>
      )}

      {Object.keys(applicationsCounter).length && apSum ? (
        <SuiBox display="flex" alignItems="center" justifyContent="center" height="100%">
            <SuiBox width="100%" mx={1} height="auto">
              <ProgressDoughnutChart
                icon="workspace"
                title="Total Prospects"
                count={apSum}
                chart={progress}
              />
            </SuiBox>
        </SuiBox>
      ) : null }

      <Divider />

      <SuiBox pb={2} px={2}>
        <SuiBox mt={0} mb={2} lineHeight={0}>
          <SuiTypography variant="button" fontWeight="regular" color="text">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </SuiTypography>
        </SuiBox>

        {/* FOOTER BADGES */}
        {recruiter || publicJob ? (
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <SuiBox display="flex" justifyContent="flex-start" alignItems="center">
              {/* HIRING METHOD BADGE */}
              {isRecruiter && !job.autoSourcing && (
                <>
                  <SuiBadge
                    badgeContent={formatHiringMethod(job?.hiringMethod)}
                    variant="contained"
                    circular="true"
                    color={formatHiringMethodColor(job?.hiringMethod)}
                    size="sm"
                    container
                    sx={{ height: 30, my: 1, mx: .5 }}
                  />
                  <SuiTypography sx={{ color: "#dbdbdb", ml: 1 }}>|</SuiTypography>
                </>
              )}

              {/* SKILLS BADGES */}
              <SuiBox>
                {skills.skill1 ? (
                  <SuiBadge badgeContent={skills.skill1} color="secondary" variant="contained" circular="true" size="sm" sx={{ height: 30, my: 1, mx: .5 }} />
                ) : null}
                {skills.skill2 ? (
                  <SuiBadge badgeContent={skills.skill2} color="secondary" variant="contained" circular="true" size="sm" sx={{ height: 30, my: 1, mx: .5 }} />
                ) : null}
                {skills.skill3 ? (
                  <SuiBadge badgeContent={skills.skill3} color="secondary" variant="contained" circular="true" size="sm" sx={{ height: 30, my: 1, mx: .5 }} />
                ) : null}
                {skills.skill4 ? (
                  <SuiBadge badgeContent={skills.skill4} color="secondary" variant="contained" circular="true" size="sm" sx={{ height: 30, my: 1, mx: .5 }} />
                ) : null}
                {skills.skill5 ? (
                  <SuiBadge badgeContent={skills.skill5} color="secondary" variant="contained" circular="true" size="sm" sx={{ height: 30, my: 1, mx: .5 }} />
                ) : null}
                {!skills.skill1 && !skills.skill2 && !skills.skill3 && !skills.skill4 && !skills.skill5 ? (
                  <br/>
                ) : null}
              </SuiBox>
            </SuiBox>

            {/* USER GLOBES */}
            <SuiBox>
              {recruitersArray.map(jobRecruiter => (
                <UserGlobe userItem={jobRecruiter} isRecruiter={true} />
              ))}
              {managersArray.map(jobManager => (
                <UserGlobe userItem={jobManager} />
              ))}
            </SuiBox>
          </SuiBox>
        ) : null}

        {/* ============ SALARY ============ */}
        <SuiBox
          bgColor="grey-100"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderRadius="lg"
          p={2}
          mb={1}
          width="100%"
          flexWrap="wrap"
        >
          {job?.salaryBasedOnExperience ?
            <SuiTypography
              display="flex"
              variant="h4"
              justifyContent="space-between"
              alignItems="center"
              height="auto"
              ml={1.5}
            >
              <SuiTypography
                variant="button"
                color="secondary"
                fontWeight="medium"
                verticalAlign="text-bottom"
                style={{ fontSize: "16px", display: "block" }}
              >
                  Salary based on experience
              </SuiTypography>
            </SuiTypography>
          :
            <>
              {value.from && (!isCompany || (job && job.hiringMethod !== 'sa')) ? (
                <SuiTypography
                  display="flex"
                  variant="h4"
                  justifyContent="space-between"
                  alignItems="center"
                  height="auto"
                >
                  <SuiTypography
                    variant="button"
                    xs={{ fontSize: "16px", display: "block", paddingTop: "5px" }}
                    fontWeight="bold"
                  >
                    {!job?.includeMaxSalary ?
                      <>{value.type} {numberFormat(value.from)}+</>
                      :
                      <>{value.type} {numberFormat(value.from)} - {numberFormat(value.to)}</>
                    }

                    {value.method ? (
                      <SuiBox component="span" ml={0.5}>
                        <SuiTypography
                          variant="button"
                          color="secondary"
                          fontWeight="medium"
                          verticalAlign="text-bottom"
                          style={{ fontSize: "16px", display: "block" }}
                        >
                          {/* eslint-disable-next-line */}
                          {job && job?.commitment?.name === 'Hourly' ? "Hourly Rate" :
                            value.method === "Month" ? "Monthly Salary" : `/${value.method}`
                          }
                        </SuiTypography>
                      </SuiBox>
                    ) : null}
                  </SuiTypography>
                </SuiTypography>
              ) : (
                <SuiBox />
              )}
            </>
          }

                        

          <SuiBox display="flex" flexWrap="wrap" justifyContent="flex-end" sx={{ marginTop: { xs: 2, sm: 0 }, width: { xs: "100%", sm: "auto" } }}>
            
            {/* If job autoSourcing, show autoSourcing btn. Else show sourcing btn. */}
            {isRecruiter && recruiter && job && (
              <Badge
                color="secondary"
                badgeContent={job?.candidatesInSourcing}
                max={999}
                sx={isMobile ? { m: .5 , px: .75 } : { my: .5, mx: 1 }}
              >
                <SuiButton size={isMobile ? "small" : "medium"} component={Link} to={`/r/sourcing/${job?.id}`} color="info" variant="outlined">
                  Sourcing
                </SuiButton>
              </Badge>
            )}

            {sourcing.valid && job && (
              <Badge
                color="secondary"
                badgeContent={job?.candidatesInSourcing}
                max={999}
                sx={isMobile ? { m: .5 , px: .75 } : { my: .5, mx: 1 }}
              >
                <SuiButton size={isMobile ? "small" : "medium"} component={Link} to={sourcing.route} color="info" variant="outlined">
                  Sourcing
                </SuiButton>
              </Badge>
            )}
            {job.autoSourcing && isRecruiter ? (
              <Badge
                color="secondary"
                sx={isMobile ? { m: .5 , px: .75 } : { my: .5, mx: 1 }}
              >
                <SuiButton size={isMobile ? "small" : "medium"} component={Link} to={`/r/ai-sourcing/${job?.id}`} color="info" variant="outlined">
                  AI Sourcing
                </SuiButton>
              </Badge>
            ) : null }
            
            <SuiButton size={isMobile ? "small" : "medium"} component={Link} to={action.route} color="info" sx={isMobile ? { m: .5 , px: .75 } : { my: .5, mx: 1 }} variant="outlined">
              {action.label}
            </SuiButton>

            {manage.label && <SuiButton size={isMobile ? "small" : "medium"} component={Link} to={manage.route} sx={isMobile ? { m: .5 , px: .75 } : { my: .5, mx: 1 }} color="info" >
              {manage.label}
            </SuiButton>}
          </SuiBox>

        </SuiBox>
      </SuiBox>
    </Card >
  );
}

// Setting default values for the props of RecruiterJobCard
RecruiterJobCard.defaultProps = {
  by: {},
  badge: {},
  skills: {},
  value: {},
  dropdown: false,
  applicationsCounter: {},
  reopened: false,
  recruiter: false,
  publicJob: false,
  companyId: 0,
  job: undefined,
  manage: {},
  sourcing: {},
  recruiters: [],
  managers: [],
  JobHeight: "650px",
  setJobIdOpen: null,
  setJobManagersOpen: null,
  tags: []
};

// Typechecking props for the RecruiterJobCard
RecruiterJobCard.propTypes = {
  by: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    logo: PropTypes.string,
  }),
  badge: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    label: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  skills: PropTypes.shape({
    skill1: PropTypes.string,
    skill2: PropTypes.string,
    skill3: PropTypes.string,
    skill4: PropTypes.string,
    skill5: PropTypes.string,
  }),
  value: PropTypes.shape({
    type: PropTypes.string,
    from: PropTypes.number,
    to: PropTypes.number,
    method: PropTypes.string,
  }),
  action: PropTypes.shape({
    type: PropTypes.oneOf(["enternal", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  sourcing: PropTypes.shape({
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    valid: PropTypes.string.isRequired,
  }),
  manage: PropTypes.shape({
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
  applicationsCounter: PropTypes.objectOf(PropTypes.number),
  reopened: PropTypes.bool,
  recruiter: PropTypes.bool,
  publicJob: PropTypes.bool,
  companyId: PropTypes.number,
  JobHeight: PropTypes.string,
  recruiters: PropTypes.arrayOf(PropTypes.object),
  managers: PropTypes.arrayOf(PropTypes.object),
  job: PropTypes.object, // eslint-disable-line
  setJobIdOpen: PropTypes.func,
  setJobManagersOpen: PropTypes.func,
  tags: PropTypes.array // eslint-disable-line
};

export default RecruiterJobCard;
