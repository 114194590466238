/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react'
import { ReactSession } from 'react-client-session'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import MoonLoader from 'react-spinners/MoonLoader'
import { css } from '@emotion/react'
// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import EditIcon from '@mui/icons-material/Edit'
import Tooltip from '@mui/material/Tooltip'
// @mui icons
import useMediaQuery from '@mui/material/useMediaQuery'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import SuiInput from 'components/SuiInput'
import SuiEditor from 'components/SuiEditor'
import SuiTypography from 'components/SuiTypography'
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from 'examples/LayoutContainers/RecruiterDashboardLayout'
import RecruiterProfileInfoCard from 'layouts/pages/profile/profile-recruiter/RecruiterProfileInfoCards/recruiterProfileInfoCard'
import ConversionRateCard from 'layouts/pages/profile/profile-recruiter/RecruiterProfileInfoCards/conversionRateCard'
import FolderFile from 'layouts/recruiterFolders/folderFile/folderFile'
// Overview page components
import RecruiterHeader from 'layouts/pages/profile/components/RecruiterHeader'
import placeholder from 'assets/images/illustrations/recommended.png'
import DefaultLineChart from 'examples/Charts/LineCharts/DefaultLineChart'
import MockCandidateAvatar from  'assets/images/mockCandidateAvatar.png'


function Overview() {

  ReactSession.setStoreType("localStorage")
  const user = ReactSession.get("user")
  const token = ReactSession.get("token")

  const isAdmin = user && user.type === "tecla_admin";

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const [recruiter, setRecruiter] = useState({})
  const [salesNotes, setSalesNotes] = useState("<br/><br/><br/><br/><br/><br/><br/><br/>");
  const [files, setFiles] = useState([]) // eslint-disable-line 
  const [notesUpdate, setNotesUpdate] = useState(0)
  const [loading, setLoading] = useState(true)
  const [year, setYear] = useState(new Date().getFullYear())
  const [imgProfile, setImgProfile] = useState(recruiter && recruiter.user && recruiter.user.photo ? `${process.env.REACT_APP_API_ROUTE}${recruiter.user.photo}` : MockCandidateAvatar)

  useEffect(() => {
    const headers = { "Content-Type": "application/json" }
    if (token) headers.Authorization = `Token ${token}`

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/globalNotes/getGlobalNotes/`, { headers })
      .then(async response => {
        const data = await response.json()
        if (data.success) {
          setFiles(data.data.files)
          if (data.data.notes && data.data.notes.length) {
            $(".ql-editor").html(data.data.notes)
            setSalesNotes(data.data.notes)
          }
        }
        setLoading(false)
      })
  }, [notesUpdate, year])

  useEffect(() => {
    if (!recruiter.user) {
      setRecruiter(ReactSession.get("recruiter"));
    }
    const headers = { "Content-Type": "application/json" }
    if (token) headers.Authorization = `Token ${token}`

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiterDetail/`, { headers })
      .then(async response => {
        const data = await response.json()
        if (data.success) {
          const responseRecruiter = {...data.data}
          ReactSession.set("recruiter", responseRecruiter)
          setRecruiter(responseRecruiter)
          if (responseRecruiter.user.photo) {
            setImgProfile(`${process.env.REACT_APP_API_ROUTE}${responseRecruiter.user.photo}`)
          }
        }
        setLoading(false)
      })
  }, [])

  function uploadResume() {
    if (user) {
      setLoading(true);
      const resumeField = $("input[name=cv]").prop('files')[0];
      const formPostData = new FormData();

      // eslint-disable-next-line no-useless-concat
      const postToken = `Token ${token}`;

      formPostData.append('file', resumeField);
      formPostData.append('notes', salesNotes);

      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/globalNotes/updateGlobalNotes/`;
      const normalizedName = resumeField.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      const requestMetadata = {
          method: 'POST',
          headers: {
          "Content-Disposition": `attachment; filename=${normalizedName}`,
            "Authorization": postToken,
          },
          body: formPostData
      };

      fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
            if (response.success) {
                setNotesUpdate(notesUpdate + 1);
            }
          });
    }
  }

  function saveSales() {
    if (user) {
      setLoading(true);
      // const resumeField = $("input[name=cv]").prop('files')[0];
      const formPostData = new FormData();

      // eslint-disable-next-line no-useless-concat
      const postToken = `Token ${token}`;

      // formPostData.append('file', resumeField);
      formPostData.append('notes', salesNotes);

      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/globalNotes/updateGlobalNotes/`;
      // const normalizedName = resumeField.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      const requestMetadata = {
          method: 'POST',
          headers: {
            "Content-Disposition": `attachment;`,
            "Authorization": postToken,
          },
          body: formPostData
      };

      fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
            if (response.success) {
                setNotesUpdate(notesUpdate + 1);
            }
          });
    }
  }

  const closeFileDialog = (fileId) => Swal.fire({
    title: 'Are you sure to delete this file?',
    text: '',
    width: "500",
    height: "auto",
    showCloseButton: true,
    confirmButtonText: 'DELETE',
    confirmButtonAriaLabel: 'DELETE',
    showCancelButton: true,
    allowEscapeKey: "true"
  }).then((result) => {
    if (result.isConfirmed) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/globalNotes/${fileId}/deleteGlobalNoteFile/`;
      const requestMetadata = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              'Authorization': `Token ${token}`
          },
      };

      fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
              if (response.success) {
                  setFiles(response.data.files)
              }
          });
    }
  })

  function closeFile(fileId) {
   closeFileDialog(fileId) 
  }

  function getFileName(fileUrl) {
      if (fileUrl) {
        return fileUrl.substring(fileUrl.lastIndexOf("/") + 1, fileUrl.length - 4);
      }

      return "";
  }

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')


  // TABLES
  const [isFetchingTablesData, setIsFetchingTablesData] = useState(false)

  const conversionRatesTableDescription = (
    <SuiBox display="flex">
      <SuiBox display="flex" alignItems="center">
        <SuiBox sx={{ width: 10, height: 10, mr: 1, borderRadius: "50%", background: "#2ec6e5" }} />
        <SuiTypography variant="button" color="secondary">My conversion rate</SuiTypography>
      </SuiBox>

      <SuiBox display="flex" alignItems="center" ml={2}>
        <SuiBox sx={{ width: 10, height: 10, mr: 1, borderRadius: "50%", backgroundColor: "#344866" }} />
        <SuiTypography variant="button" color="secondary">Team conversion rate</SuiTypography>
      </SuiBox>
    </SuiBox>
  )

  const invitesAndApplicationsTableDescription = (
    <SuiBox display="flex">
      <SuiBox display="flex" alignItems="center">
        <SuiBox sx={{ width: 10, height: 10, mr: 1, borderRadius: "50%", background: "#2ec6e5" }} />
        <SuiTypography variant="button" color="secondary">My invites</SuiTypography>
      </SuiBox>

      <SuiBox display="flex" alignItems="center" ml={2}>
        <SuiBox sx={{ width: 10, height: 10, mr: 1, borderRadius: "50%", backgroundColor: "#445d66" }} />
        <SuiTypography variant="button" color="secondary">My applications</SuiTypography>
      </SuiBox>

      <SuiBox display="flex" alignItems="center" ml={2}>
        <SuiBox sx={{ width: 10, height: 10, mr: 1, borderRadius: "50%", background: "#040e4c" }} />
        <SuiTypography variant="button" color="secondary">Team invites (average)</SuiTypography>
      </SuiBox>

      <SuiBox display="flex" alignItems="center" ml={2}>
        <SuiBox sx={{ width: 10, height: 10, mr: 1, borderRadius: "50%", backgroundColor: "#fbcd49" }} />
        <SuiTypography variant="button" color="secondary">Team applications (average)</SuiTypography>
      </SuiBox>

      <SuiBox display="flex" alignItems="center" ml={2}>
        <SuiBox sx={{ width: 10, height: 10, mr: 1, borderRadius: "50%", backgroundColor: "#89d346" }} />
        <SuiTypography variant="button" color="secondary">Monthly goal (applications)</SuiTypography>
      </SuiBox>
    </SuiBox>
  )
  
  const [conversionRatesDataset, setConversionRatesDataset] = useState([
    {
      label: "My conversion rate",
      color: "info",
      data: []
    },
    {
      label: "Team conversion rate",
      color: "dark",
      data: []
    }
  ])

  const [invitesAndApplicationsDataset, setInvitesAndApplicationsDataset] = useState([
    {
      label: "My invites",
      color: "info",
      data: []
    },
    {
      label: "My applications",
      color: "dark",
      data: []
    },
    {
      label: "Team invites (average)",
      color: "primary",
      data: []
    },
    {
      label: "Team applications (average)",
      color: "warning",
      data: []
    },
    {
      label: "Monthly goal (applications)",
      color: "success",
      data: []
    }
  ])

  const fetchConversionTablesData = () => {
    setIsFetchingTablesData(true)

    let url = `${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiterConversionData/`
    if (year !== new Date().getFullYear()) url = `${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiterConversionData/?year=${year}`

    fetch(url, {
      method: 'GET',
      headers: { 'Authorization': `Token ${token}` },
    })
      .then(async response => {
        const res = await response.json()

        if (res.success) {
          // Conversion rates table
          // Records are initialized in 0 for each month. We only update the data for the months API returns, the rest are left at 0.
          const recruiterConversionRateData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          const teamConversionRateData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

          // Invites and applications table
          // Records are initialized in 0 for each month. We only update the data for the months API returns, the rest are left at 0.
          const recruiterInvitesData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          const recruiterApplicationsData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          const teamInvitesData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          const teamApplicationsData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          // Same rule applies for monthGoalData but with 25 set as default.
          const monthGoalData = [25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25]

          res.data.recruiterRecords.forEach(month => {
            // Conversion rates table - recruiter data
            if(month?.conversionRate) recruiterConversionRateData[month.month-1] = month?.conversionRate
            // Invites and applicatitions table - recruiter data
            if(month?.invites) recruiterInvitesData[month.month-1] = month?.invites
            if(month?.applications) recruiterApplicationsData[month.month-1] = month?.applications
          })

          res.data.totalRecords.forEach(month => {
            // Conversion rates table - team data
            if(month?.conversionRate) teamConversionRateData[month.month-1] = month?.conversionRate
            // Invites and applicatitions table - team data
            if(month?.invites) teamInvitesData[month.month-1] = month?.invites
            if(month?.applications) teamApplicationsData[month.month-1] = month?.applications
            if(month?.monthGoal) monthGoalData[month.month-1] = month?.monthGoal
          })

          // Conversion rates table data
          const conversionRatesDataArr = [
            {
              label: "My conversion rate",
              color: "info",
              data: recruiterConversionRateData
            },
            {
              label: "Team conversion rate",
              color: "dark",
              data: teamConversionRateData
            }
          ]
          
          setConversionRatesDataset(conversionRatesDataArr)

          // Invites and applications table data
          const invitesAndApplicationsDataArr = [
            {
              label: "My invites",
              color: "info",
              data: recruiterInvitesData
            },
            {
              label: "My applications",
              color: "dark",
              data: recruiterApplicationsData
            },
            {
              label: "Team invites (average)",
              color: "primary",
              data: teamInvitesData
            },
            {
              label: "Team applications (average)",
              color: "warning",
              data: teamApplicationsData
            },
            {
              label: "Monthly goal (applications)",
              color: "success",
              data: monthGoalData
            }
          ]
          
          setInvitesAndApplicationsDataset(invitesAndApplicationsDataArr)

          setIsFetchingTablesData(false)
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => fetchConversionTablesData(), [])
  useEffect(() => {if(year !== new Date().getFullYear())fetchConversionTablesData()}, [year])

  return (
    <>
      {/* eslint-disable-next-line */}
      {(user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin") ?
        /* Company profile for recruiter users */
        <RecruiterDashboardLayout>
          <RecruiterHeader
            user={user}
            recruiter={recruiter}
            imgProfile={imgProfile}
          />

          <SuiBox display="flex" mt={5} mb={3}>
            {!recruiter ? (
              <>
                <Grid container xs={12}>
                  <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
                    <SuiBox component="img" src={placeholder} alt="Couple of documents" height="auto" width={isMobile ? "100%" : "35%"} />
                    <SuiTypography mb={1} variant="h5" textAlign="center" color="dark">
                      Company has nothing to display here yet.
                    </SuiTypography>
                    <SuiTypography mb={4} variant="body2" textAlign="center" color="dark">
                      This company will be personalizing their profile soon!
                    </SuiTypography>
                  </SuiBox>
                </Grid>
              </>
            ) : (
              <Grid container spacing={3}>
                {/* LEFT */}
                <Grid item xs={12} md={4}>
                  <SuiBox mb={3}>
                    <RecruiterProfileInfoCard recruiter={recruiter} />
                  </SuiBox>

                  <SuiBox mb={3}>
                    <ConversionRateCard setYear={setYear} />
                  </SuiBox>

                  <SuiBox mb={3}>
                    <Card>
                      <SuiBox p={5}>
                        <SuiButton component={Link} to="/r/reports/" fullWidth color="info" variant="outlined">
                          View reports
                        </SuiButton>
                      </SuiBox>
                    </Card>
                  </SuiBox>

                </Grid>

                {/* RIGHT */}
                <Grid item xs={12} lg={8}>

                  <Card sx={{ height: "auto", minHeight: 350, mb: 5, position: "relative" }}>
                      {isFetchingTablesData ?
                        <SuiBox sx={{ margin: "auto" }}>
                          <CircularProgress/>
                        </SuiBox>
                      :
                        <>
                          {user.type === "tecla_admin" && (
                            <SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer" }} component="a" href="/r/conversion-data/" >
                              <Tooltip title="Edit conversion data" placement="top">
                                <EditIcon sx={{ position: "absolute", top: 15, right: 25, zIndex: 100 }} />
                              </Tooltip>
                            </SuiTypography>
                          )}

                          <DefaultLineChart
                            title="Conversion rates"
                            description={conversionRatesTableDescription}
                            chart={{
                              labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                              datasets: conversionRatesDataset
                            }}
                          />
                        </>
                      }
                    </Card>

                    <Card sx={{ height: "auto", minHeight: 350, mb: 5, position: "relative" }}>
                      {isFetchingTablesData ?
                        <SuiBox sx={{ margin: "auto" }}>
                          <CircularProgress/>
                        </SuiBox>
                      :
                        <>
                          {user.type === "tecla_admin" && (
                            <SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer" }} component="a" href="/r/conversion-data/" >
                              <Tooltip title="Edit conversion data" placement="top">
                                <EditIcon sx={{ position: "absolute", top: 15, right: 25, zIndex: 100 }} />
                              </Tooltip>
                            </SuiTypography>
                          )}

                          <DefaultLineChart
                            title="Invites and applications"
                            description={invitesAndApplicationsTableDescription}
                            chart={{
                              labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                              datasets: invitesAndApplicationsDataset
                            }}
                          />
                        </>
                      }
                    </Card>

                  <Card sx={{ height: "auto" }}>
                    <SuiBox p={2}>
                      <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                        <SuiBox display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                            <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Tecla notes and files</SuiTypography>
                            <SuiBox sx={{ mx: { xs: '110', md: '0' } }}></SuiBox>
                        </SuiBox>
                      </SuiBox>
                      <SuiBox id="modal-modal-description" borderRadius="16px" height="100%" my={2} p={2} sx={{ overflow: "auto" }}>
                          { isAdmin ? (
                            <Grid container xs={12} md={12} spacing={1} mb={1}>
                                <Grid item xs={6}>
                                  <SuiTypography variant="button" ml={1} mb={1}>Add file (PDF)</SuiTypography>
                                  <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={uploadResume} inputProps={{ accept: '.pdf' }} />
                                </Grid>
                            </Grid>
                          ) : null }
                          <SuiBox>
                            { loading ? (
                              <SuiBox className="moonCover" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={0}>
                                <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                              </SuiBox>
                            ) : null }
                            <Grid sx={{ maxHeight: "400px", overflow: "auto" }}>
                                <SuiEditor
                                    maxLength={2000}
                                    defaultValue={salesNotes}
                                    name="salesNotes"
                                    onChange={setSalesNotes}
                                    placeholder="Sales Notes"
                                />
                            </Grid>
                            {files && files.length ? (
                                <SuiBox my={3} display="flex" justifyContent="start" style={{ overflow: "auto", "maxWidth": "100%", background: "#f6f7f8" }}>
                                {files.map(recruiterFile => (
                                    <Grid item xs={6} lg={2} className="folder-box" m={1} p={1} sx={{ maxWidth: "175px !important" }}>
                                      { isAdmin ? (
                                        <FolderFile folderName={getFileName(recruiterFile.file)} folderId={recruiterFile.id} folderUrl={recruiterFile.file} closeFile={closeFile} />
                                      ) : 
                                        <FolderFile folderName={getFileName(recruiterFile.file)} folderId={recruiterFile.id} folderUrl={recruiterFile.file} />
                                      }
                                    </Grid>
                                ))}
                                </SuiBox>
                            ) : null }
                          </SuiBox>
                      </SuiBox>
                      { isAdmin ? (
                        <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }} sx={{ textAlign: "right" }}>
                            <SuiButton color="info" onClick={saveSales}>Save</SuiButton>
                        </SuiBox>
                      ) : null }
                    </SuiBox>
                  </Card>
                  
                </Grid>
              </Grid>
            )}
          </SuiBox>
        </RecruiterDashboardLayout>
      : null }
    </>
  )
}

export default Overview