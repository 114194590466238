/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { ReactSession } from 'react-client-session';
// formik components
import { Formik, Form } from "formik";
// utils
import { validateToken, capitalize } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// Soft UI Dashboard PRO React example components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
// Wizard page components
import OnePageView from "layouts/applications/job-apply/components/OnePage";
import Basic from "layouts/applications/job-apply/components/Basic";
import Skills from "layouts/applications/job-apply/components/Skills";
import Additional from "layouts/applications/job-apply/components/Additional";
import Questions from "layouts/applications/job-apply/components/Questions";

import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import WithAttachedFunction from "layouts/pages/account/detailjob/components/Job/components/WithAttachedFunction";
import LoadApplication from "examples/Modal/LoadApplication";

function getRequiredFields() {
  return ["interviewInEnglish", "canWorkRemotely", "availability", "salary"];
}

function getFields() {
  return ["country", "phone", "interviewInEnglish", "canWorkRemotely", "englishLevel", "availability", "salary", "linkedin", "github", "portfolio", "website", "twitter"];
}


function getContent(formData) {
  return <OnePageView formData={formData} />;
}

let formCvPostData = new FormData();
let cvUploaded = false;
function Wizard() {
  // Validation
  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("user");
  const candidate = ReactSession.get("candidate");
  const company = ReactSession.get("company");
  const recruiter = ReactSession.get("recruiter");
  let lastApply = {};

  const searchParams = new URLSearchParams(window.location.search);
  const jobId = searchParams.get('id');
  
  let code = ReactSession.get("jobCode");
  if (!code) {
    code = searchParams.get('code');
  }
  const [activeStep, setActiveStep] = useState(0);
  const [jobApply, setJobApply] = useState({});
  const [showSpinner, setShowSpinner] = useState(false)

  const getOnePage = true; // eslint-disable-line
  const getRecordId = ReactSession.get(`getRecordId-${jobId}`) ? ReactSession.get(`getRecordId-${jobId}`) : 0;
  const [recordId, setRecordId] = useState(getRecordId)
  const [isOnePage, setIsOnePage] = useState(getOnePage); // eslint-disable-line
  const [appLoading, setAppLoading] = useState(false)
  const [appError, setAppError] = useState("")
  const [cvError, setCvError] = useState("")
  const [steps, setSteps] = useState(["Basic", "Skills", "Additional"]);
  let sessionToken = ReactSession.get("token");
  if (!sessionToken) {
    sessionToken = "";
  }
  const [token, setToken] = useState(sessionToken)
  const [isFreelanceJob, setIsFreelanceJob] = useState(false)

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <Basic formData={formData} />;
      case 1:
        return <Skills formData={formData} isFreelanceJob={isFreelanceJob} />;
      case 2:
        return <Additional formData={formData} />;
      case 3:
        return <Questions formData={formData} />;
      default:
        return null;
    }
  }

  useEffect(() => {
    if (!ReactSession.get(`isOnePage-${jobId}`) && ReactSession.get(`isOnePage-${jobId}`) !== false) {
      ReactSession.set(`isOnePage-${jobId}`, getOnePage);
      if (!recordId) {
        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/createApplicationRecord/`;
        const postBody = {
            'jobId': jobId,
            'type': getOnePage ? 1 : 0,
        };
        const requestMetadata = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body: new URLSearchParams(postBody)
        };
        fetch(recipeUrl, requestMetadata)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                  setRecordId(response.data)
                  ReactSession.set(`getRecordId-${jobId}`, response.data)
                }
            });
      }
    }
  }, []);


  const { formId, formField } = form;
  const currentValidation = validations[0];
  let validPost = true;
  const stepsLength = steps.length - 1;
  const isLastStep = activeStep === stepsLength;

  function errorRedirect(textError) {
    let timerRedirectid = "";
    $(".alertErrorBox div div").text(textError);
    $(".alertErrorBox").show();
    if (timerRedirectid) {
      clearTimeout(timerRedirectid);
    }
    timerRedirectid = setTimeout(() => {
      window.location.replace(`/job?id=${jobId}`);
    }, 2000);
  }

  useEffect(() => {
    if (company || recruiter) {
      errorRedirect("Sorry this position is not currently active.");
    }
    if (jobId) {
      lastApply = {};
      ReactSession.set("lastApply", {});
      const url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${jobId}/jobStandalone/`;
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      fetch(url, { headers, })
        .then(async response => {
          const res = await response.json();

          if (res?.data?.job?.commitment?.name === "Freelance") setIsFreelanceJob(true)

        validateToken(res) // eslint-disable-line
          if (res.success && res.data && res.data.job && res.data.job.questions) {
            const resQuestions = res.data.job.questions;
            const resIsQuestion = [];
            const resAdditionalQuestion = [];
            // eslint-disable-next-line
            for (const resQuestion of resQuestions) {
              if (resQuestion.isJobQuestion) {
                resAdditionalQuestion.push(resQuestion);
              } else {
                resIsQuestion.push(resQuestion);
              }
            }
            ReactSession.set("questions", resIsQuestion);
            ReactSession.set("additionalQuestions", resAdditionalQuestion);
            setJobApply(res.data.job);
            document.title = `${res.data.job.seniorityName} Application | TECLA`;
            if (resIsQuestion.length) {
              setSteps(["Basic", "Skills", "Additional", "Questions"]);
            }
          } else {
            errorRedirect("Sorry this position is not currently active.");
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, []);

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  function showErrorModal() {
    setAppLoading(false);
    setShowSpinner(false);
  }

  function showError(text) {
    let textError = text;
    if (!textError) {
      textError = "Please fill all the required fields in this section before continuing.";
    }
    let timerSuccessid = "";
    $(".alertErrorBox div div").text(textError);
    $(".alertErrorBox").show();
    $('html, body').animate({
      scrollTop: $(".alertErrorBox").offset().top
    }, 80);
    if (timerSuccessid) {
      clearTimeout(timerSuccessid);
    }
    timerSuccessid = setTimeout(() => {
      $(".alertErrorBox").hide();
    }, 5000);
  }

  function showUrlError(urlName) {
    let timerSuccessid = "";
    $(".alertUrlErrorBox div div").text(`Invalid ${urlName} url`)
    $(".alertUrlErrorBox").show();
    $('html, body').animate({
      scrollTop: $(".alertUrlErrorBox").offset().top
    }, 100);
    if (timerSuccessid) {
      clearTimeout(timerSuccessid);
    }
    timerSuccessid = setTimeout(() => {
      $(".alertUrlErrorBox").hide();
    }, 5000);
  }

  // function showFinalError(text) {
  //   setShowSpinner(false);
  //   let errorText = "There was a problem with your application, please try again later.";
  //   if (text && text.length) {
  //     errorText = text;
  //   }
  //   let timerFinalid = "";
  //   $(".alertFinalErrorBox div div").text(errorText)
  //   $(".alertFinalErrorBox").show();
  //   if (timerFinalid) {
  //     clearTimeout(timerFinalid);
  //   }
  //   timerFinalid = setTimeout(() => {
  //     $(".alertFinalErrorBox").hide();
  //   }, 5000);
  // }

  function showSuccess() {
    setShowSpinner(false);
    $(".alertSuccessBox").show();

    if (!candidate) {
      
      $('html, body').animate({
        scrollTop: $(".alertSuccessBox").offset().top
      }, 80);
      
      setTimeout(() => {
        $(".signUpBox button").click();
      }, 2000);
    }
    
    else {
      // eslint-disable-next-line
      if (candidate.firstApplication) {
        const sessionCandidate = { ...ReactSession.get("candidate") };
        sessionCandidate.firstApplication = false;
        ReactSession.set("candidate", sessionCandidate)
      }
      let timerSuccessid = "";
      $('html, body').animate({
        scrollTop: $(".alertSuccessBox").offset().top
      }, 80);
      if (timerSuccessid) {
        clearTimeout(timerSuccessid);
      }
      timerSuccessid = setTimeout(() => {
        window.location.replace(`/job?id=${jobId}`);
      }, 1500);
    }
  }

  // eslint-disable-next-line
  $(document).unbind().on('change', 'input[name=cv]', function () {
    const resumeField = $("input[name=cv]").prop('files')[0];
    let validCv = true;
    if (resumeField && resumeField.name) {
      const extension = resumeField.name.substring(resumeField.name.length - 4, resumeField.name.length).toLowerCase();
      if (!extension.includes("pdf")) {
        validCv = false;
      }
    }

    if (validCv) {
      formCvPostData = new FormData();
      formCvPostData.append('cv', resumeField);
      cvUploaded = true;
    } else {
      $("input[name=cv]").val("");
    }

  });

  function uploadApplyCv(appId, appCvtoken) {
    // const resumeField = formCvPostData.get('cv');
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/uploadCVApplication/`;
    const normalizedName = "cv.pdf"
    const requestMetadata = {
      method: 'PATCH',
      headers: {
        "Content-Disposition": `attachment; filename=${normalizedName}`,
        "Authorization": `Token ${appCvtoken}`,
      },
      body: formCvPostData
    };

    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          $('.loadingBox span:visible:contains("close")').click()
          showSuccess();
        } else if (response.data && response.data.error) {
          setCvError(response.data.error);
          showErrorModal();
        } else {
          setCvError("Unexpected problem uploading your Application CV")
          showErrorModal();
        }
      });
  }

  function isURL(str) {
     const urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
     const url = new RegExp(urlRegex, 'i');
     return str.length < 2083 && url.test(str);
  }

  function updateUser() { // eslint-disable-line
    
    if ($("form .MuiTypography-caption:visible").text().length === 0 && validPost) {
      validPost = false;
      let handleNextIsValid = true;
      lastApply = { ...ReactSession.get("lastApply") };
      if (isOnePage) {
        // const pattern = new RegExp('^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?$', 'i');
        const mediaInputs = ["linkedin", "github", "portfolio", "website", "twitter"];
        // eslint-disable-next-line
        for (const mediaInput of mediaInputs) {
          delete lastApply[mediaInput]
          // eslint-disable-next-line
          if ($(`[name=${mediaInput}]`).length && $(`[name=${mediaInput}]`).val()) {
            // eslint-disable-next-line
            if (!isURL($(`[name=${mediaInput}]`).val())) {
              handleNextIsValid = false;
              showUrlError(mediaInput);
              break;
            } else {
              lastApply[mediaInput] = $(`[name=${mediaInput}]`).val();
            }
          }
        }

        if (!user) {
          const firstName = $("input[name=firstName]").val();
          if (!firstName || !firstName.length) {
            validPost = true;
            return showError('Complete your first name before continuing.');
          }
          lastApply.first_name = firstName;

          const lastName = $("input[name=lastName]").val();
          if (!lastName || !lastName.length) {
            validPost = true;
            return showError('Complete your last name before continuing.');
          }
          lastApply.last_name = lastName;

          const email = $("input[name=email]").val();
          if (!email || !email.length) {
            validPost = true;
            return showError('Complete your email before continuing.');
          }
          lastApply.email = email;


          if (!lastApply.country) {
            lastApply.country = "";
          }
          lastApply.country = $("input[name=country]").val() ? $("input[name=country]").val() : lastApply.country;
          if (!lastApply.country) {
            // eslint-disable-next-line
            if ($(".countryBox").attr("data-val")) {
              lastApply.country = { "id": Number($(".countryBox").attr("data-val")) };
            } else {
              handleNextIsValid = false;
              validPost = true;
              return showError('Please select a location before continuing.');
            }
          } else if ($("input[name=country]").val()) {
            lastApply.country = { "id": Number($("input[name=country]").val()) };
          }

          lastApply.phone = $("input[name=phone]").val();

        } else {
          lastApply.userCV = true;
          lastApply.first_name = $("input[name=firstName]").val();
          lastApply.last_name = $("input[name=lastName]").val();
          lastApply.country = candidate.country ? candidate.country.id : $("input[name=country]").val();
          if (!lastApply.country) {
            // eslint-disable-next-line
            if ($(".countryBox").attr("data-val")) {
              lastApply.country = $(".countryBox").attr("data-val");
            } else {
              handleNextIsValid = false;
              showError("Please select a location before continuing.");
            }
          }
          lastApply.country = { "id": Number(lastApply.country) };
          lastApply.phone = candidate.phone ? candidate.phone : $("input[name=phone]").val();
        }

        if ((candidate && !candidate.cv && !cvUploaded) || (!candidate && !cvUploaded) || (!candidate && cvUploaded && !lastApply.tempCV)) {
          handleNextIsValid = false;
          validPost = true;
          return showError("Please upload a CV before continuing");
        }

        if (!lastApply.questions) {
          lastApply.questions = [];
        }
        let answerNeeded = ReactSession.get("additionalQuestions").length;
        if (ReactSession.get("questions").length) {
          answerNeeded += ReactSession.get("questions").length;
        }
        if (lastApply.questions.length < answerNeeded) {
          handleNextIsValid = false;
          validPost = true;
          showError("Please answer the required questions before continuing.");
        }

        const inputNames = getFields();
        // eslint-disable-next-line
        for (const inputName of inputNames) {
          if ($(`input[name=${inputName}]`).length && $(`input[name=${inputName}]`).val()) {
            lastApply[inputName] = $(`input[name=${inputName}]`).val();
          }
        }

        // eslint-disable-next-line
        for (const inputRequiredName of getRequiredFields()) {
          if ($(`input[name=${inputRequiredName}]`).length) {
            if (!lastApply[inputRequiredName] || !lastApply[inputRequiredName].length) {

              handleNextIsValid = false;
              if (inputRequiredName === "linkedin") {
                validPost = true;
                return showError("Please add a LinkedIn profile link before continuing.")
              }
              validPost = true;
              return showError(`Please complete the ${capitalize(inputRequiredName)} field before continuing.`);
            }
          }
        }

        if (lastApply.interviewInEnglish && lastApply.interviewInEnglish === "Yes") {
          lastApply.interviewInEnglish = true;
        } else if (lastApply.interviewInEnglish && lastApply.interviewInEnglish === "No") {
          lastApply.interviewInEnglish = false;
        }

        if (lastApply.canWorkRemotely && lastApply.canWorkRemotely === "Yes") {
          lastApply.canWorkRemotely = true;
        } else if (lastApply.canWorkRemotely && lastApply.canWorkRemotely === "No") {
          lastApply.canWorkRemotely = false;
        }

        if (activeStep === 1 && (!lastApply.salary || lastApply.salary === "")) {
          handleNextIsValid = false;
          showError("Please enter your salary expectations");
        } else if (String(lastApply.salary).includes(".")) {
          handleNextIsValid = false;
          showError("The salary field must contain only numbers");
        } else if (activeStep === 1 && Number(lastApply.salary) <= 0) {
          handleNextIsValid = false;
          showError("Expected salary can't be 0.");
        }
      } else {
        if (!lastApply) {
          lastApply = {};
        }
        if (activeStep === 0) {
          const mediaInputs = ["linkedin", "github", "portfolio", "website", "twitter"];
          // eslint-disable-next-line
          for (const mediaInput of mediaInputs) {
            delete lastApply[mediaInput]
            // eslint-disable-next-line
            if ($(`[name=${mediaInput}]`).length && $(`[name=${mediaInput}]`).val()) {
              // eslint-disable-next-line
              if (!isURL($(`[name=${mediaInput}]`).val())) {
                handleNextIsValid = false;
                showUrlError(mediaInput);
                break;
              } else {
                lastApply[mediaInput] = $(`[name=${mediaInput}]`).val();
              }
            }
          }
        }
        if (activeStep === 0 && !user) {
          lastApply.first_name = $("input[name=firstName]").val();
          lastApply.last_name = $("input[name=lastName]").val();
          lastApply.email = $("input[name=email]").val();
          if (!lastApply.country) {
            lastApply.country = "";
          }
          lastApply.country = $("input[name=country]").val() ? $("input[name=country]").val() : lastApply.country;
          if (!lastApply.country) {
            // eslint-disable-next-line
            if ($(".countryBox").attr("data-val")) {
              lastApply.country = { "id": Number($(".countryBox").attr("data-val")) };
            } else {
              handleNextIsValid = false;
              showError("Please select a location before continuing");
            }
          } else if ($("input[name=country]").val()) {
            lastApply.country = { "id": Number($("input[name=country]").val()) };
          }
          lastApply.phone = $("input[name=phone]").val();
        } else if (activeStep === 0 && user) {
          lastApply.userCV = true;
          lastApply.first_name = $("input[name=firstName]").val();
          lastApply.last_name = $("input[name=lastName]").val();
          lastApply.country = candidate.country ? candidate.country.id : $("input[name=country]").val();
          if (!lastApply.country) {
            // eslint-disable-next-line
            if ($(".countryBox").attr("data-val")) {
              lastApply.country = $(".countryBox").attr("data-val");
            } else {
              handleNextIsValid = false;
              showError("Please select a location before continuing.");
            }
          }
          lastApply.country = { "id": Number(lastApply.country) };
          lastApply.phone = candidate.phone ? candidate.phone : $("input[name=phone]").val();
        } else if (activeStep === 2 || activeStep === 3) {
          if (!lastApply.questions) {
            lastApply.questions = [];
          }
          let answerNeeded = ReactSession.get("additionalQuestions").length;
          if (activeStep === 3) {
            answerNeeded += ReactSession.get("questions").length;
          }
          if (lastApply.questions.length < answerNeeded) {
            handleNextIsValid = false;
            showError("");
          }
        } else if (activeStep > 0) {
          const inputNames = getFields();
          // eslint-disable-next-line
          for (const inputName of inputNames) {
            if ($(`input[name=${inputName}]`).length && $(`input[name=${inputName}]`).val()) {
              lastApply[inputName] = $(`input[name=${inputName}]`).val();
            }
          }

          // eslint-disable-next-line
          for (const inputRequiredName of getRequiredFields()) {
            if ($(`input[name=${inputRequiredName}]`).length) {
              if (!lastApply[inputRequiredName] || !lastApply[inputRequiredName].length) {

                handleNextIsValid = false;
                if (inputRequiredName === "linkedin")  showError("Please add a LinkedIn profile link before continuing.")
                else showError(`Please complete the ${capitalize(inputRequiredName)} field before continuing.`);
                break;
              }
            }
          }

          if ((candidate && !candidate.cv && !cvUploaded) || (!candidate && !cvUploaded) || (!candidate && cvUploaded && !lastApply.tempCV)) {
            handleNextIsValid = false;
            showError("Please upload a CV before continuing");
          }

          if (lastApply.interviewInEnglish && lastApply.interviewInEnglish === "Yes") {
            lastApply.interviewInEnglish = true;
          } else if (lastApply.interviewInEnglish && lastApply.interviewInEnglish === "No") {
            lastApply.interviewInEnglish = false;
          }

          if (lastApply.canWorkRemotely && lastApply.canWorkRemotely === "Yes") {
            lastApply.canWorkRemotely = true;
          } else if (lastApply.canWorkRemotely && lastApply.canWorkRemotely === "No") {
            lastApply.canWorkRemotely = false;
          }

          if (activeStep === 1 && (!lastApply.salary || lastApply.salary === "")) {
            handleNextIsValid = false;
            showError("Please enter your salary expectations");
          } else if (String(lastApply.salary).includes(".")) {
            handleNextIsValid = false;
            showError("The salary field must contain only numbers");
          } else if (activeStep === 1 && Number(lastApply.salary) <= 0) {
            handleNextIsValid = false;
            showError("Expected salary can't be 0.");
          }
        }
        ReactSession.set("lastApply", lastApply);
      }
      validPost = true;
      if (handleNextIsValid && activeStep !== stepsLength && !isOnePage) {
        handleNext();
      } else if (handleNextIsValid && (activeStep === stepsLength || isOnePage)) {
        $(".loadApplicationBox button").click();
        setShowSpinner(true);
        setAppLoading(true);
        lastApply.userCV = true;
        if (lastApply.tempCV) {
          lastApply.userCV = false;
        }
        if (code && !user) {
          lastApply.code = code;
        }
        if (lastApply.phone && !lastApply.phone.includes("+")) {
          lastApply.phone = `+${lastApply.phone}`;
        }
        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${jobId}/apply/`;
        let requestMetadata = {};

        const utm = ReactSession.get("utm");
        if (utm) {
          lastApply.utm = utm;
        }

        const urlRecruiter = ReactSession.get("urlRecruiter");
        if (urlRecruiter) {
          lastApply.applyTag = urlRecruiter;
        }

        if (recordId) {
          lastApply.applicationRecord = recordId;
        }

        if (user) {
          requestMetadata = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            },
            body: JSON.stringify(lastApply)
          };
        } else {
          requestMetadata = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(lastApply)
          };
        }

        fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
            validPost = true;
            if (response.success) {
              if (!token) {
                setToken(response.token);
              }
              $('.loadingBox span:visible:contains("close")').click()
              ReactSession.set(`isOnePage-${jobId}`, null);
              ReactSession.set(`getRecordId-${jobId}`, null)
              showSuccess();
            } else if (response.data && response.data.error) {
              showErrorModal();
              setAppError(response.data.error)
            } else {
              showErrorModal();
              setAppError("Unexpected problem uploading your Application CV")
            }
          });
      }
    }
  }

  return (
    <BaseLayout>
      <SuiBox pb={8}>
        <SuiBox className="signUpBox" display="none">
          <WithAttachedFunction jobId={jobId} text="Apply" type="1" />
        </SuiBox>

        <SuiBox className="loadApplicationBox" display="none">
          <LoadApplication text="Load Application" appError={appError} cvError={cvError} appLoading={appLoading} uploadApplyCv={uploadApplyCv} token={token}/>
        </SuiBox>

        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SuiBox mt={6} mb={1} textAlign="center">
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Submit your application to {jobApply.seniorityName}
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="regular" color="secondary">
                Fill the required details to apply for this position
              </SuiTypography>
            </SuiBox>

            { !isOnePage ? (
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              ) : null}

            <SuiBox className="alertErrorBox" display="none">
              <SuiAlert color="error" className="alertError">Please fill all the fields in this section before continuing.</SuiAlert>
            </SuiBox>
            <SuiBox className="alertUrlErrorBox" display="none">
              <SuiAlert color="error" className="alertUrlError">Invalid Url.</SuiAlert>
            </SuiBox>
            <SuiBox className="alertFinalErrorBox" display="none">
              <SuiAlert color="error" className="alertUrlError">There was a problem with your application, plsease try again later.</SuiAlert>
            </SuiBox>
            <SuiBox className="alertSuccessBox" display="none">
              <SuiAlert color="info" className="alertSuccess">Application submitted!</SuiAlert>
            </SuiBox>
            <Card>
              <SuiBox p={2}>
                <SuiBox>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={currentValidation}
                  >
                    {({ values, errors, touched, issubmitting }) => (
                      <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">
                        { isOnePage ? (
                          <SuiBox>
                            {getContent({
                              values,
                              touched,
                              formField,
                              errors,
                            })}
                            <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                              <div></div>
                              {showSpinner ? (
                                <SuiBox mt={3} display="flex" justifyContent="center" alignItems="center">
                                  <CircularProgress />
                                </SuiBox>)
                                : (
                                <SuiButton
                                  variant="gradient"
                                  color="info"
                                  onClick={updateUser}
                                  className="changeStep nextStep"
                                >
                                  submit
                                </SuiButton>
                              )}
                            </SuiBox>
                          </SuiBox>
                        ) : 
                        (
                          <SuiBox>
                            {getStepContent(activeStep, {
                              values,
                              touched,
                              formField,
                              errors,
                            })}
                            <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                              {activeStep === 0 ? (
                                <SuiBox />
                              ) : (
                                <SuiButton variant="outlined" color="info" onClick={handleBack} issubmitting={issubmitting} className="changeStep">
                                  back
                                </SuiButton>
                              )}
                              {showSpinner ? (
                                <SuiBox mt={3} display="flex" justifyContent="center" alignItems="center">
                                  <CircularProgress />
                                </SuiBox>)
                                : (
                                  <SuiButton
                                    variant="gradient"
                                    color="info"
                                    onClick={updateUser}
                                    className="changeStep nextStep"
                                  >
                                    {isLastStep ? "submit" : "next"}
                                  </SuiButton>
                                )}
                            </SuiBox>
                          </SuiBox>
                        )}
                      </Form>
                    )}
                  </Formik>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      {/* <Footer /> */}
    </BaseLayout>
  );
}

export default Wizard;
