/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect } from 'react'
import { ReactSession } from 'react-client-session'
import CompanyDashboardLayout from "examples/LayoutContainers/CompanyDashboardLayout"

/*  Empty page that executes the logout function and redirects to login page.
    Recruiter users are directed here when clicking logout button on navbar. 
*/
function CompanyLogout() {
    const token = ReactSession.get("token")
    const postToken = `Token ${token}`

    function userLogout() {
        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/logout/`
        const postBody = {}
        const requestMetadata = {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': postToken
            },
            body: JSON.stringify(postBody)
        }
        fetch(recipeUrl, requestMetadata)
            .then(res => res.json())
            .then(response => {
                ReactSession.set("user", null)
                ReactSession.set("company", null)
                ReactSession.set("token", null)

                window.location.replace("/c/login")
                console.log(response)
            })
    }

    useEffect(() => { userLogout() }, [])
    
    return (
        <CompanyDashboardLayout/>
    )
}

export default CompanyLogout
  