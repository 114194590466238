/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useRef }from "react"
// @mui material components
import Grid from "@mui/material/Grid"
import useMediaQuery from '@mui/material/useMediaQuery'
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiSelect from "components/SuiSelect"
import SuiInput from "components/SuiInput"
import SuiButton from "components/SuiButton"
// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField"
import Separator from "../../../CustomSeparator/index"

// eslint-disable-next-line
function Experience({ experienceId, experience, candidateJobExperiences, setCandidateJobExperiences, countries, monthOptions, yearOptions }) {

    const [currentTask, setCurrentTask] = useState(null)
    const [currentAchievement, setCurrentAchievement] = useState(null)

    const [currentStartDateMonth, setCurrentStartDateMonth] = useState(null)
    const [currentStartDateYear, setCurrentStartDateYear] = useState(null)
    const [currentEndDateMonth, setCurrentEndDateMonth] = useState(null)
    const [currentEndDateYear, setCurrentEndDateYear] = useState(null)

    const currentEndDateYearInput = useRef()
    const currentEndDateMonthInput = useRef()

    const [workHereNow, setWorkHereNow] = useState(false)

    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')
    /* Mui hook to read screen size - We use it for small screen rendering */
    const isSmallScreen = useMediaQuery('(max-width: 1201px)')

    // Option rules for date picker
    const startDateYearOptions = currentEndDateYear ? yearOptions.slice(yearOptions.findIndex(opt => opt.value === currentEndDateYear)) : yearOptions // eslint-disable-line
    const startDateMonthOptions = currentEndDateYear && currentEndDateMonth && currentStartDateYear && currentStartDateYear === currentEndDateYear ?
        monthOptions.slice(0, monthOptions.findIndex(opt => opt.value === currentEndDateMonth)) // eslint-disable-line
        :
        monthOptions

    const endDateYearOptions = currentStartDateYear ? yearOptions.slice(0, yearOptions.findIndex(opt => opt.value === currentStartDateYear)+1) : yearOptions // eslint-disable-line
    const endDateMonthOptions = currentEndDateYear && currentStartDateMonth && currentStartDateYear && currentStartDateYear === currentEndDateYear ?
        monthOptions.slice(monthOptions.findIndex(opt => opt.value === currentStartDateMonth)+1) // eslint-disable-line
        :
        monthOptions

    /* Function that handles input changes and updates the candidateJobExperiences state.
    As parameters it takes the name of the field to change and the value to be assigned to the field. */
    const handleInputChange = (inputName, value) => {
        // Copy the current state
        const prevExperienceRecords = candidateJobExperiences.slice() // eslint-disable-line
        // Copy the record the user is editing
        const experienceRecord = prevExperienceRecords.filter(exp => exp.id === experienceId)[0]
        // Assign the new  value to the corresponding field
        if (inputName === "tasks" || inputName === "achievements") {
            experienceRecord[inputName].push(value)
            $(`input[name=${inputName}]`).val("")
            
            if (inputName === "tasks") setCurrentTask(null)
            else setCurrentAchievement(null)
        }
        else experienceRecord[inputName] = value
        // Replace the state by including the record with the new value
        setCandidateJobExperiences([
            ...prevExperienceRecords.slice(0, experienceId),
            experienceRecord,
            ...prevExperienceRecords.slice(experienceId+1)
        ])
    }

    /* Function for removing tasks and achievements from the experience state.
    As parameters it takes the name of the field (tasks or achievements) and the item you want to remove. */
    const handleInputRemove = (inputName, value) => {
        // Copy the current state
        const prevExperienceRecords = candidateJobExperiences.slice() // eslint-disable-line
        // Copy the record the user is editing
        const experienceRecord = prevExperienceRecords.filter(exp => exp.id === experienceId)[0]
        // Filter the tasks or achievements record array
        experienceRecord[inputName] = experienceRecord[inputName].filter(item => item !== value)
        // Replace the state by including the record with the new value
        setCandidateJobExperiences([
            ...prevExperienceRecords.slice(0, experienceId),
            experienceRecord,
            ...prevExperienceRecords.slice(experienceId+1)
        ])
    }

    const removeExperience = () => {
        // Copy the current state
        const prevExperienceRecords = candidateJobExperiences.slice() // eslint-disable-line
        // Filter out the current experience
        const experienceRecords = prevExperienceRecords.filter(exp => exp.id !== experienceId)
        // Replace the state by the filtered array
        setCandidateJobExperiences(experienceRecords)
    }

    const handleWorkHereChange = () => {
        if (!workHereNow) {
            setWorkHereNow(true)
            handleInputChange("endDate", "Present")

            if (currentEndDateMonth) {
                setCurrentEndDateMonth(null)
                currentEndDateMonthInput.current.state.value = ""
            }
            if (currentEndDateYear) {
                setCurrentEndDateYear(null)
                currentEndDateYearInput.current.state.value = ""
            }
        }

        else setWorkHereNow(false)
    }

    const handleDateChange = (endOrStart, monthOrYear, value) => {
        if (endOrStart === "start") {
            if (monthOrYear === "month" && currentStartDateYear) handleInputChange("startDate", `${value} ${currentStartDateYear}`)
            if (monthOrYear === "year" && currentStartDateMonth) handleInputChange("startDate", `${currentStartDateMonth} ${value}`)
        }
        else if (endOrStart === "end") {
            if (monthOrYear === "month" && currentEndDateYear) handleInputChange("endDate", `${value} ${currentEndDateYear}`)
            if (monthOrYear === "year" && currentEndDateMonth) handleInputChange("endDate", `${currentEndDateMonth} ${value}`)
        }
    }

    const tasksPlaceholder = () => {
        if (experience.tasks.length === 0) return "Task... (required)" // eslint-disable-line
        if (experience.tasks.length === 5) return "Reached max ammount of tasks" // eslint-disable-line
        return "Task... (optional)" 
    }

    return (
        <SuiBox>

            {experienceId !== 0 && <Separator/>}
            
            {candidateJobExperiences.length > 1 && // eslint-disable-line
                <SuiBox mt={3} width="100%" display="flex" justifyContent="flex-end">
                    <SuiButton color="error" variant="outlined" onClick={() => removeExperience()}>Remove experience</SuiButton>
                </SuiBox>
            }

            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Company name</SuiTypography>
                            <FormField
                                type="text"
                                label="Company name"
                                name="Company name"
                                placeholder="Company name (required)"
                                onChange={e => handleInputChange("company", e.target.value)}
                                success={experience.company} // eslint-disable-line
                            />
                        </Grid>

                        <SuiBox ml={2} xs={12} width="100%" display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent={isMobile ? "auto" : "space-between"} alignItems={isMobile ? "auto" : "center"} >
                            <Grid item xs={100} md={9.5}>
                                <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Position name</SuiTypography>
                                <FormField
                                    type="text"
                                    label="Position name"
                                    name="Position name"
                                    placeholder="Position name (required)"
                                    onChange={e => handleInputChange("position", e.target.value)}
                                    success={experience.position} // eslint-disable-line
                                />
                            </Grid>
                            
                            <SuiBox width={isMobile ? "100%" : "15%"} display={isMobile && "flex"} flexDirection={isMobile && "row"} justifyContent={isMobile && "center"} alignItems={isMobile && "center"} >
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox onChange={() => handleWorkHereChange()} />} label="I work here now" />
                                </FormGroup>
                            </SuiBox>

                        </SuiBox>

                        <SuiBox ml={2} xs={12} width="100%" display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between" alignItems={isMobile ? "auto" : "center"} >
                            <Grid item xs={12} md={5.9}>
                                <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Start date</SuiTypography>
                                <SuiBox xs={12} md={6} width="100%" display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between">
                                    <Grid item xs={12} md={7} mb={isMobile && 2}>
                                        <SuiSelect
                                            placeholder="Month (required)"
                                            label="Month"
                                            smallFont={isSmallScreen}
                                            size={isSmallScreen ? "small" : "medium"}
                                            options={startDateMonthOptions}
                                            name="Start date month (required)"
                                            success={currentStartDateMonth} // eslint-disable-line
                                            onChange={e => {
                                                setCurrentStartDateMonth(e.value)
                                                handleDateChange('start', 'month', e.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4.5} mb={isMobile && 2}>
                                        <SuiSelect
                                            placeholder="Year (required)"
                                            label="Year"
                                            smallFont={isSmallScreen}
                                            size={isSmallScreen ? "small" : "medium"}
                                            options={startDateYearOptions}
                                            name="Start date year (required)"
                                            success={currentStartDateYear} // eslint-disable-line
                                            onChange={e => {
                                                setCurrentStartDateYear(e.value)
                                                handleDateChange('start', 'year', e.value)
                                            }}
                                        />
                                    </Grid>
                                </SuiBox>
                            </Grid>

                            <Grid item xs={12} md={5.9} display={workHereNow ? "none" : "block"}>
                                <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>End date</SuiTypography>
                                <SuiBox xs={12} md={6} width="100%" display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between">
                                    <Grid item xs={12} md={7} mb={isMobile && 2}>
                                        <SuiSelect
                                            placeholder="Month (required)"
                                            label="Month"
                                            smallFont={isSmallScreen}
                                            size={isSmallScreen ? "small" : "medium"}
                                            options={endDateMonthOptions}
                                            name="EndDateMonth"
                                            isDisabled={workHereNow}
                                            ref={currentEndDateMonthInput}
                                            success={currentEndDateMonth} // eslint-disable-line
                                            onChange={e => {
                                                setCurrentEndDateMonth(e.value)
                                                handleDateChange('end', 'month', e.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4.5} mb={isMobile && 2}>
                                        <SuiSelect
                                            placeholder="Year (required)"
                                            label="Year"
                                            smallFont={isSmallScreen}
                                            size={isSmallScreen ? "small" : "medium"}
                                            options={endDateYearOptions}
                                            name="EndDateYear"
                                            isDisabled={workHereNow}
                                            ref={currentEndDateYearInput}
                                            success={currentEndDateYear} // eslint-disable-line
                                            onChange={e => {
                                                setCurrentEndDateYear(e.value)
                                                handleDateChange('end', 'year', e.value)
                                            }}
                                        />
                                    </Grid>
                                </SuiBox>
                            </Grid>
                        </SuiBox>

                        <Grid item xs={12} md={6}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Company country</SuiTypography>
                            <SuiSelect
                                placeholder="Country (required)"
                                label="Country"
                                options={countries}
                                name="Company country (required)"
                                success={experience.country} // eslint-disable-line
                                onChange={e => handleInputChange("country", e.value)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Company state</SuiTypography>
                            <FormField 
                                placeholder="State (optional)"
                                label="State"
                                type="text"
                                name="Company state (required)"
                                success={experience.state} // eslint-disable-line
                                onChange={e => handleInputChange("state", e.target.value)}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Main tasks</SuiTypography>

                            <Grid container xs={12} spacing={1} mb={1}>
                                <Grid item xs={9.5} md={10}>
                                    <SuiInput
                                        label="Task"
                                        name="tasks"
                                        placeholder={tasksPlaceholder()}
                                        success={experience.tasks.length} // eslint-disable-line
                                        onChange={e => setCurrentTask(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2} >
                                    <SuiButton
                                        color="info"
                                        disabled={!currentTask || experience.tasks.length === 5} // eslint-disable-line
                                        onClick={() => handleInputChange("tasks", currentTask)}
                                        fullWidth>
                                            Add
                                    </SuiButton>
                                </Grid>
                            </Grid>

                            {experience.tasks.map(task => ( // eslint-disable-line
                                <Grid container xs={12} spacing={1} mb={1} key={task}>
                                    <Grid item xs={9.5} md={10}><SuiInput defaultValue={task} disabled /></Grid>
                                    <Grid item xs={2} >
                                        <SuiButton color="error" variant="outlined" onClick={() => handleInputRemove("tasks", task)} fullWidth>Remove</SuiButton>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        
                        <Grid item xs={12} sx={{ marginBottom: 5 }}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Achievements obtained</SuiTypography>

                            <Grid container xs={12} spacing={1} mb={1}>
                                <Grid item xs={9.5} md={10}>
                                    <SuiInput
                                        label="Achievement"
                                        name="achievements"
                                        placeholder={experience.achievements.length === 5 ? "Reached max ammount of achievements" : "Achievement... (optional)"} // eslint-disable-line
                                        onChange={e => setCurrentAchievement(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2} >
                                    <SuiButton
                                        color="info"
                                        disabled={!currentAchievement || experience.achievements.length === 5} // eslint-disable-line
                                        onClick={() => handleInputChange("achievements", currentAchievement)}
                                        fullWidth>
                                            Add
                                    </SuiButton>
                                </Grid>
                            </Grid>

                            {experience.achievements.map(achievement => ( // eslint-disable-line
                                <Grid container xs={12} spacing={1} mb={1} key={achievement}>
                                    <Grid item xs={9.5} md={10}><SuiInput defaultValue={achievement} disabled /></Grid>
                                    <Grid item xs={2} >
                                        <SuiButton color="error" variant="outlined" onClick={() => handleInputRemove("achievements", achievement)} fullWidth>Remove</SuiButton>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </SuiBox>
  )
}

export default Experience