/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Link } from "react-router-dom";
import { useRef } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";
// Images
import teclaLight from "assets/images/logos/Tecla logo S.png";

function IllustrationLayout({ color, header, title, description, illustration, children }) { // eslint-disable-line

  const teclaLogo = useRef(null)

  ReactSession.setStoreType("localStorage");
  const logoUrl = ReactSession.get("lastCompanyLogin") ? "/c/login" : "/";

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:1000px)')

  return (
    <PageLayout background="white">

      <DefaultNavbar routes={pageRoutes} transparent />

      {/*
        The following conditional is used to detect small screens in which the Tecla logo is left out:
        (teclaLogo.current && teclaLogo.current.getBoundingClientRect().top < 7)
      */}
      <Grid container py={(teclaLogo.current && teclaLogo.current.getBoundingClientRect().top < 7) ? 12 : "auto"}>
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }} >
          <SuiBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">

            <SuiBox
              component={Link}
              to={logoUrl}
              // display={teclaLogo.current && teclaLogo.current.getBoundingClientRect().top < 0 ? 'none' : 'flex'}
              display='flex'
              justifyContent="center"
              alignItems="center"
              mb={3}
              mx='auto'
            >
              <img width="220vw" src={teclaLight} alt="Tecla logo with dark font" ref={teclaLogo} />
          </SuiBox>

            <SuiBox>
              {!header ? (
                <>
                  <SuiBox>
                    <SuiTypography variant="h1" fontWeight="bold" color="dark" fontSize="2rem" mb={2} textAlign="center">
                      {title}
                    </SuiTypography>
                  </SuiBox>

                  <SuiTypography variant="body2" fontWeight="regular" color="text" width={isMobile ? "auto" : "400px"} textAlign="center">
                    {description}
                  </SuiTypography>
                </>
              ) : (
                header
              )}
            </SuiBox>
            <SuiBox py={1}>{children}</SuiBox>
          </SuiBox>
        </Grid>
        
        <Grid item xs={12} lg={6}>
          <SuiBox
            display={{ xs: "none", lg: "flex" }}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="calc(100% - 2rem)"
            height={(teclaLogo.current && teclaLogo.current.getBoundingClientRect().top < 0) ? "100vh" : "calc(100vh - 2rem)"}
            position="relative"
            borderRadius="lg"
            textAlign="center"
            m={2}
            px={13}
            sx={{ overflow: "hidden" }}
          >
            {illustration.title && (
              <SuiBox mb={6}>
                <SuiTypography variant="h4" color="white" fontWeight="bold">
                  {illustration.title}
                </SuiTypography>
              </SuiBox>
            )}

            {illustration.image && (
              <SuiBox
                component="img"
                src={illustration.image}
                alt="chat-illustration"
                width="60rem"
                height="100%"
              />
            )}

            {illustration.description && (
              <SuiBox mb={1}>
                <SuiTypography variant="body2" color="white">
                  {illustration.description}
                </SuiTypography>
              </SuiBox>
            )}
          </SuiBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  color: "info",
  header: "",
  title: "",
  description: "",
  illustration: {},
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default IllustrationLayout;
