/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
import { useEffect, useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// import Swal from "sweetalert2";
// @mui material components
import Modal from '@mui/material/Modal';
import Icon from '@mui/material/Icon';
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import CircularProgress from '@mui/material/CircularProgress';
// import SuiButton from "components/SuiButton";
import GeneralView from "layouts/pages/profile/components/Header/recruiterFormsModal/generalView/GeneralView";
import AddNewForm from "layouts/pages/profile/components/Header/recruiterFormsModal/addNewForm/AddNewForm";
import ViewForm from "layouts/pages/profile/components/Header/recruiterFormsModal/viewForm/ViewForm";
import CloseConfirmationModal from "./closeConfirmationModal/CloseConfirmationModal";
import EditForm from "./editForm/EditForm";

function RecruiterFormsModal({ open, onClose, candidate, fullname, reFetchCandidateForms, isFetchingForms, application }) {

    ReactSession.setStoreType("localStorage")
    const token = ReactSession.get("token")
    // const user = ReactSession.get("user")

    const [modalContent, setModalContent] = useState('generalView')
    const [formIdToShow, setFormIdToShow] = useState(null)

    const [isEditingForm, setIsEditingForm] = useState(false)

    useEffect(() => {
        if (formIdToShow && isEditingForm) setModalContent('editForm')
        else if (formIdToShow) setModalContent('viewForm')
    }, [formIdToShow])

    const [newFormWasCreated, setNewFormWasCreated] = useState(false)
    const [skillsHaveBeenEdited, setSkillsHaveBeenEdited] = useState(false)

    const [isFetchingData, setIsFetchingData] = useState(false)
    const [candidateScorecardData, setCandidateScorecardData] = useState(null)

    const fetchCandidateScorecard = () => {    
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidate?.user?.id}/getScoreboard/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(async response => {
            const data = await response.json()

            if (data.success) {
                setCandidateScorecardData(data?.data)
                setIsFetchingData(false)
            }
        })
        .catch(error => console.error('There was an error!', error))
    }

    useEffect(() => { if (!candidateScorecardData) fetchCandidateScorecard() }, [])

    const showModalContent = () => {
        switch (modalContent) {
            case 'generalView':
                return <GeneralView
                    setModalContent={setModalContent}
                    candidate={candidate}
                    setFormIdToShow={setFormIdToShow}
                    setIsEditingForm={setIsEditingForm}
                    application={application}
                    candidateScorecardData={candidateScorecardData}
                />
            case 'addNewForm':
                return <AddNewForm
                    setModalContent={setModalContent}
                    candidate={candidate}
                    reFetchCandidateForms={reFetchCandidateForms}
                    setNewFormWasCreated={setNewFormWasCreated}
                    skillsHaveBeenEdited={skillsHaveBeenEdited}
                    setSkillsHaveBeenEdited={setSkillsHaveBeenEdited}
                    application={application}
                    candidateScorecardData={candidateScorecardData}
                />
            case 'viewForm':
                return <ViewForm
                    setModalContent={setModalContent}
                    candidate={candidate}
                    formIdToShow={formIdToShow}
                    setFormIdToShow={setFormIdToShow}
                />
            case 'editForm':
                return <EditForm
                    setModalContent={setModalContent}
                    candidate={candidate}
                    reFetchCandidateForms={reFetchCandidateForms}
                    formIdToShow={formIdToShow}
                    setFormIdToShow={setFormIdToShow}
                    setIsEditingForm={setIsEditingForm}
                    skillsHaveBeenEdited={skillsHaveBeenEdited}
                    setSkillsHaveBeenEdited={setSkillsHaveBeenEdited}
                />
            default:
                return <GeneralView setModalContent={setModalContent} candidate={candidate} setFormIdToShow={setFormIdToShow} />
        }
    }

    useEffect(() => { setModalContent('generalView') }, [isFetchingForms])

    const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false)

    return (
        <>
            <Modal
                open={open}
                onClose={() => {
                    if (modalContent === 'addNewForm') setShowCloseConfirmationModal(true)
                    else {
                        onClose()
                        if (skillsHaveBeenEdited) window.location.reload()
                    }
                }}
            >
                <SuiBox
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: "100vw", md: "80vw" },
                        height: { xs: "100vh", md: "auto" },
                        maxHeight: { md: "90vh" },
                        bgcolor: 'background.paper',
                        border: 'none',
                        boxShadow: 24,
                        borderRadius: { xs: 0, md: 4 },
                        p: 3,
                        display: "flex",
                        flexDirection: "column",
                        overflowY:'auto'
                    }}
                >
                    <SuiBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <SuiBox display="inherit" flexWrap="wrap">
                            <SuiTypography id="modal-modal-title" variant="h4" color="dark">Recruiter Form about&nbsp;</SuiTypography>
                            <SuiTypography variant="h4">{fullname}</SuiTypography>
                        </SuiBox>

                        <SuiBox display="inherit" alignItems="inherit">
                            <Icon
                                onClick={() => { 
                                    if (modalContent === 'addNewForm') setShowCloseConfirmationModal(true)
                                    else {
                                        onClose()
                                        if (skillsHaveBeenEdited) window.location.reload()
                                    }
                                }}
                                sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" }, marginLeft: "1rem" }}
                            >
                                close
                            </Icon>
                        </SuiBox>
                    </SuiBox>

                    {(isFetchingForms || isFetchingData) ?
                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center" sx={{ height: "90vh" }}>
                            <CircularProgress size={40} />
                        </SuiBox>
                    :   
                        showModalContent()
                    }
                </SuiBox>
            </Modal>

            {showCloseConfirmationModal && (
                <CloseConfirmationModal 
                    showCloseConfirmationModal={showCloseConfirmationModal}
                    setShowCloseConfirmationModal={setShowCloseConfirmationModal}
                    onClose={onClose}
                    newFormWasCreated={newFormWasCreated}
                />
            )}
        </>
    )
}

RecruiterFormsModal.defaultProps = {
    application: null
}

// typechecking props
RecruiterFormsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    candidate: PropTypes.object.isRequired, // eslint-disable-line
    fullname: PropTypes.string.isRequired,
    reFetchCandidateForms: PropTypes.func.isRequired,
    isFetchingForms: PropTypes.bool.isRequired,
    application: PropTypes.object, // eslint-disable-line
};
  
  export default RecruiterFormsModal;
