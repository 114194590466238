/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
// @mui material components
import Grid from "@mui/material/Grid";
import SuiPagination from "components/SuiPagination";
import Icon from "@mui/material/Icon";
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import JobCard from "examples/Cards/JobCard";
import placeholder from "assets/images/illustrations/recommended.png";

function Settings() {

  useEffect(() => {
    document.title = `Jobs | TECLA`;
  }, []);

  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const [loading, setLoading] = useState(true);

  let skillId = "";
  let skillName = "";

  const [jobs, setJobs] = useState([]);
  const [skillArrayFilters, setSkillArrayFilters] = useState([]);
  const [skillFilterText, setSkillFilterText] = useState("All");
  const [skills, setSkills] = useState([]);
  const [skillsStart, setSkillsStart] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pagination, setPagination] = useState({ pages: [] });

  function updateJobs(filters) {
    let url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/getPublicJobs/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (filters && filters.length) {
      url = `${url}?${filters}`;
    }

    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success) {
          const resJobs = [...res.data.jobs];
          const resPagination = { ...res.data.pagination };

          resPagination.hasPrev = true;
          resPagination.hasNext = true;
          if (resPagination.current === 1 || resPagination.countPages === 1) {
            resPagination.hasPrev = false;
          }

          if (resPagination.current === resPagination.countPages) {
            resPagination.hasNext = false;
          }

          if (resPagination.countPages >= 5) {
            if (resPagination.current === 1 || resPagination.current === 2) {
              resPagination.pages = [1, 2, 3, 4, 5];
            } else {
              resPagination.pages = [resPagination.current - 2, resPagination.current - 1, resPagination.current];
              if (resPagination.countPages >= resPagination.current + 1) {
                resPagination.pages.push(resPagination.current + 1);
              }
              if (resPagination.countPages >= resPagination.current + 2) {
                resPagination.pages.push(resPagination.current + 2);
              }
            }
          } else {
            resPagination.pages = [];
            // eslint-disable-next-line
            for (let i = 0; i < resPagination.countPages; i++) {
              resPagination.pages.push(i + 1);
            }
          }

          resPagination.pagesHtml = [];
          if (resPagination.pages.length > 1) {
            // eslint-disable-next-line
            for (let i = 0; i < resPagination.pages.length; i++) {
              if (resPagination.pages[i] === resPagination.current) {
                resPagination.pagesHtml.push(<SuiPagination className="pageReload" item active> {resPagination.pages[i]} </SuiPagination>)
              } else {
                resPagination.pagesHtml.push(<SuiPagination className="pageReload" item> {resPagination.pages[i]} </SuiPagination>)
              }
            }
          }

          setPagination(resPagination);

          // const jobsData = [];
          let jobSkills = {};
          let count = 0;
          // eslint-disable-next-line
          for (const job of resJobs) {
            jobSkills = {};
            count = 0;
            // eslint-disable-next-line
            for (const requiredSkill of job.requiredSkills) {
              count += 1;
              jobSkills[`skill${count}`] = requiredSkill.skill.name;
            }
            job.requiredSkills = jobSkills;
          }
          setJobs(resJobs);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function getDifferenceInSeconds(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / 1000;
  }

  function timeAgo(date) {
    const currentDate = new Date();
    const seconds = Math.floor(getDifferenceInSeconds(new Date(date), currentDate));
    const mins = Math.floor(seconds / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    let timePassed = "";
    if (months >= 1) {
      timePassed = `${months}M ago`;
    } else if (days >= 1) {
      timePassed = `${days}d ago`;
    } else if (hours >= 1) {
      timePassed = `${hours}h ago`;
    } else if (mins >= 1) {
      timePassed = `${mins}m ago`;
    } else if (seconds >= 1) {
      timePassed = `${seconds}s ago`;
    }

    return timePassed;
  }

  function hotLabel(isHot) {
    if (isHot) {
      return { color: "error", label: "HOT JOB" };
    }

    return {};
  }

  function updateFilters() {
    const url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/filters/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };

    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success) {
          setSkills(res.data.skills);
          setSkillsStart(res.data.skills);
          setCategories(res.data.categories);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => {
    updateJobs("");
    updateFilters();
    // restartFlag();
  }, []);

  function reloadFilters(loadSkillArrayFilters, fromSkill) {
    let skillFilter = "";
    let categoryId = "";
    let skillCount = 0;
    let skillString = "";
    let extra = 0;
    skillId = "";
    skillName = "";
    if (!fromSkill) {
      // eslint-disable-next-line
      loadSkillArrayFilters = skillArrayFilters;
    }
    // eslint-disable-next-line
    $.each(loadSkillArrayFilters, function (index, skillArrayItem) {
      skillCount += 1;
      skillId = skillArrayItem.id;
      skillName = skillArrayItem.name;
      if (skillCount <= 3) {
        skillString = `${skillString} ${skillName},`;
      }

      skillFilter = `${skillFilter}skills=${skillId}&`;
    });

    if (skillString.length) {
      skillString = skillString.slice(0, -1);
      if (skillCount >= 4) {
        extra = skillCount - 3;
        skillString = `${skillString}, +${extra} more`;
      }

      setSkillFilterText(skillString);
    } else {
      setSkillFilterText("All");
    }

    // eslint-disable-next-line
    $.each($(".selectCategory.Mui-checked"), function (index, input) {
      categoryId = $(input).attr("data-id");
      skillFilter = `${skillFilter}categories=${categoryId}&`;
    });

    if ($("input[name=salarySelect]").val()) {
      const salarySelected = $("input[name=salarySelect]").val();
      skillFilter = `${skillFilter}salary=${salarySelected}&`;
    }

    if ($("input[name=dateSelect]").val()) {
      const dateSelected = $("input[name=dateSelect]").val();
      skillFilter = `${skillFilter}id=${dateSelected}&`;
    }

    let dataCurrentPage = $(".paginationBox").attr("data-page");
    if (!dataCurrentPage) {
      dataCurrentPage = 1;
    }
    skillFilter = `${skillFilter}page=${dataCurrentPage}&`;

    // restartFlag();
    updateJobs(skillFilter);
  }

  // eslint-disable-next-line
  $(document).unbind().on("click", ".selectSkill, .selectCategory, .loadFilters", function () {
    let skillArrayFiltersCopy = [...skillArrayFilters];
    let fromSkill = false;
    $(".paginationBox").attr("data-page", 1);
    if ($(this).hasClass("selectSkill")) {
      fromSkill = true;
      skillId = $(this).attr("data-id");
      skillName = $(this).next().text();

      if ($(this).hasClass("Mui-checked")) {
        skillArrayFiltersCopy.push({
          id: skillId,
          name: skillName
        });
      } else {
        skillArrayFiltersCopy = skillArrayFiltersCopy.filter(val => val.name !== skillName)
      }
      setSkillArrayFilters(skillArrayFiltersCopy);
    }
    reloadFilters(skillArrayFiltersCopy, fromSkill);
  });

  // eslint-disable-next-line
  $(document).on("click", ".pageReload, .pageReloadPrev, .pageReloadNext", function () {
    if ($(this).hasClass("pageReload")) {
      $(".paginationBox").attr("data-page", $(this).text().trim());
    } else if ($(this).hasClass("pageReloadPrev")) {
      $(".paginationBox").attr("data-page", pagination.current - 1);
    } else if ($(this).hasClass("pageReloadNext")) {
      $(".paginationBox").attr("data-page", pagination.current + 1);
    }

    reloadFilters("", false);
  });

  // eslint-disable-next-line
  $(document).on("keyup", "input[name=searchInput]", function () {
    let keyupSkills = [...skillsStart];
    const inputText = $(this).val().toLowerCase();
    keyupSkills = keyupSkills.filter(val => val.name.toLowerCase().includes(inputText));
    setSkills([]);
    setSkills(keyupSkills);
  })

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <BaseLayout>
      <SuiTypography variant="h3" fontWeight="bold" ml={1} mb={3}>Find your next opportunity ✨</SuiTypography>
      
      <SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Sidenav skills={skills} categories={categories} skillFilterText={skillFilterText} skillArrayFilters={skillArrayFilters} />
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={9}>
            {/* ROW */}
            {jobs.length ? (
              <>
                <Grid container columnSpacing={{ xs: '0', md: '20' }} rowSpacing={{ xs: '20', md: '20' }}>
                  {jobs.map(job => (
                    <Grid item xs={12} className="job-box">
                      <JobCard
                        by={{ name: job.company.name, date: timeAgo(job.createdAt) }}
                        badge={hotLabel(job.isHot)}
                        title={job.seniorityName}
                        description={job.description}
                        skills={job.requiredSkills}
                        value={{ type: "$", from: job.minSalary, to: job.maxSalary, method: "Month" }}
                        action={{ type: "internal", route: `/job?id=${job.id}`, label: "see more" }}
                        category={job.category}
                        subtitle={job.subtitle}
                        salaryBasedOnExperience={job.salaryBasedOnExperience}
                        job={job}
                      />
                    </Grid>
                  ))}
                </Grid>
                <br />
                <SuiBox mb={9} className="paginationBox" style={{ margin: "20px auto 40px auto" }}>
                  <SuiPagination>
                    {pagination.hasPrev ? (
                      <SuiPagination item className="pageReloadPrev">
                        <Icon>keyboard_arrow_left</Icon>
                      </SuiPagination>
                    ) : null}
                    {pagination.pagesHtml}
                    {pagination.hasNext ? (
                      <SuiPagination item className="pageReloadNext">
                        <Icon>keyboard_arrow_right</Icon>
                      </SuiPagination>
                    ) : null}
                  </SuiPagination>
                </SuiBox>
              </>
            ) :
              <Grid item>
                { loading ? (
                  <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={20}>
                    <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                  </SuiBox> 
                ) : (
                <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={10} width="100%" height="100%">
                  <img src={placeholder} alt="Couple of documents" width={isMobile ? "100%" : "35%"} />

                  <SuiTypography mb={4} variant="h5" textAlign="center" color="dark">No Jobs were found.</SuiTypography>

                  <SuiButton component="a" href="/t/jobs" sx={{ backgroundColor: "#feec1e" }} size="large">
                    <SuiTypography color="dark" fontWeight="bold">REFRESH PAGE</SuiTypography>
                  </SuiButton>
                </SuiBox>
                )}
              </Grid>
            }
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout >
  );
}

export default Settings;
