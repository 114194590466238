/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
// Soft UI Dashboard PRO React layouts
// import { ReactSession } from 'react-client-session';
import { ReactSession } from 'react-client-session';

import StudentDashboard from "layouts/dashboards/student";

import SettingsStudent from "layouts/pages/account/settings-student";
import AiTutor from "layouts/pages/account/aiTutor";
import StudentLogout from "layouts/pages/studentLogout/StudentLogout";

// import Discounts from "layouts/pages/discounts";
// import Newsletters from "layouts/pages/newsletters";
import Videos from "layouts/pages/videos/Videos";
// import TechTrends from "layouts/techTrends";
import SavedLessons from "layouts/pages/savedLessons";
import Quizzes from "layouts/pages/tests";

// Soft UI Dashboard PRO React icons
import SettingsIcon from "examples/Icons/Settings";
// import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AssessmentIcon from '@mui/icons-material/Assessment';
// import DiscountIcon from '@mui/icons-material/Discount';

// import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ComputerIcon from '@mui/icons-material/Computer';
import PeopleIcon from '@mui/icons-material/People';
// Icons
import LogoutIcon from '@mui/icons-material/Logout';
// import FeedIcon from '@mui/icons-material/Feed';
import MyCoaches from 'layouts/pages/coach/myCoaches';
import Assessment from 'layouts/pages/account/assessment';
// import RequestCoach from 'layouts/pages/coach/request';

ReactSession.setStoreType("localStorage");
const student = ReactSession.get("student");

const routes = [
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  { type: "divider", key: "divider" },
  {
    type: "collapse",
    name: "Dashboard",
    key: "/dashboard",
    route: "/dashboard",
    href: "/dashboard",
    component: StudentDashboard,
    icon: <SpaceShip size="14px" />,
  },
  { type: "divider", key: "divider" },
  {
    type: "collapse",
    name: "Courses",
    key: "courses",
    icon: <SpaceShip size="14px" />,
    noCollapse: false,
    collapse: [
      {
        name: "Overview",
        key: "/dashboard/courses",
        route: "/dashboard/courses",
        component: StudentDashboard,
      },
      {
        name: "Saved",
        key: "/savedLessons",
        route: "/savedLessons",
        component: SavedLessons,
      },
      {
        name: "Quizzes",
        key: "/quizzes",
        route: "/quizzes",
        component: Quizzes,
      }
    ]
  },
  {
    type: "collapse",
    name: "My coaches",
    key: "/my-coaches",
    route: "/my-coaches",
    href: "/my-coaches",
    component: MyCoaches,
    icon: <PeopleIcon fontSize="small" />,
  },
  {
    type: "collapse",
    name: "Events",
    key: "/events",
    route: "/events",
    href: "/events",
    component: Videos,
    icon: <OndemandVideoIcon fontSize="small" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "AI Tutor",
    key: "/ai-tutor",
    route: "/ai-tutor",
    href: "/ai-tutor",
    component: AiTutor,
    icon: <ComputerIcon fontSize="small" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Assessment",
    key: "/assessment",
    route: "/assessment",
    href: "/assessment",
    component: Assessment,
    icon: <AssessmentIcon fontSize="small" />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Perks",
  //   key: "/perks",
  //   route: "/perks",
  //   href: "/perks",
  //   component: Discounts,
  //   icon: <LocalOfferIcon fontSize="small" />,
  // },
  // {
  //   type: "collapse",
  //   name: "Coaching",
  //   key: "coaching",
  //   component: null,
  //   icon: <PeopleIcon fontSize="small" />,
  //   noCollapse: false,
  //   collapse: [
  //     {
  //       name: "My coaches",
  //       key: "/myCoaches",
  //       route: "/myCoaches",
  //       component: MyCoaches,
  //     },
  //     {
  //       name: "Request Coach",
  //       key: "/request-coach",
  //       route: "/request-coach",
  //       component: RequestCoach,
  //     }
  //   ]
  // },
  // {
  //   type: "collapse",
  //   name: "Newsletters",
  //   key: "/newsletters",
  //   route: "/newsletters",
  //   href: "/newsletters",
  //   component: Newsletters,
  //   icon: <FeedIcon fontSize="small" />,
  // },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Settings",
    key: "/settings",
    route: "/settings",
    href: "/settings",
    component: SettingsStudent,
    icon: <SettingsIcon size="14px" />,
  },
  {
    type: "collapse",
    name: "Log out",
    title: "test title",
    key: "/logout",
    route: "/logout",
    href: "/logout",
    component: StudentLogout,
    icon: <LogoutIcon fontSize="small" />,
  },
  { type: "divider", key: "divider-2" },
  {
    type: "recruiterAccountDetail",
    name: "InstructionHours",
    title: (student?.englishHours > 0) ? `- Coaching hours left: ${student?.englishHours}` : '',
    key: "InstructionHours",
  },
];

export default routes;
