/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
// @mui material components
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import CustomProgress from 'components/CustomProgress'

function CompanyCandidateScorecardModal({
                          showCompanyCandidateScorecardModal,
                          setShowCompanyCandidateScorecardModal,
                          candidateName,
                          scorecardData
                        }) {

  // Classify questions by category and build options array for each
  const [questionsByCategory, setQuestionsByCategory] = useState([])

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const formatScorecard = () => {
    const questionsByCat = {}

    scorecardData?.forEach(question => {
      if (question?.value) { // only show questions that have an answer
        const options = []
        question?.item?.values?.forEach(val => options.push({ value: val, label: val }))
        question.options = options // eslint-disable-line
  
        if (questionsByCat[question?.item?.category]) questionsByCat[question?.item?.category].push(question)
        else questionsByCat[question?.item?.category] = [question]
      }
    })
  
    setQuestionsByCategory(questionsByCat)
  }

  useEffect(() => { formatScorecard() }, [])
  
  return (
    <Modal open={showCompanyCandidateScorecardModal} onClose={() => setShowCompanyCandidateScorecardModal(false)} >
      <SuiBox
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '100vw', md: '60vw' },
          height: { xs: '100vh', md: 'auto' },
          maxHeight: { md: '90vh' },
          bgcolor: 'background.paper',
          border: 'none',
          boxShadow: 24,
          borderRadius: { xs: 0, md: 4 },
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          overflowY:'auto'
        }}
      >
        {!questionsByCategory ?
          <SuiBox my={20} mx="auto"><CircularProgress size={40}/></SuiBox>
        :
        <>
          <SuiTypography sx={{ mt: isMobile ? 7 : 2.5 }} variant="h4">Scorecard for {candidateName}</SuiTypography>
          <SuiTypography sx={{ mt: .5, mb: 2.5 }} variant="body2">View here {candidateName}&apos;s screening interview results for: General Fit, Communication and Work Style.</SuiTypography>

          {Object?.entries(questionsByCategory)?.map(cat => { // eslint-disable-line
            return (
              <SuiBox display="flex" flexDirection="column" alignItems="flex-start" key={cat[0]}>
                <SuiTypography sx={{ mt: 2, mb: 1.5, fontSize: "1rem" }} variant="button">{cat[0]}:</SuiTypography>

                <Grid container p={1} spacing={2}>
                  {cat[1]?.map(question => { // eslint-disable-line
                    return (
                      <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        {Number.isNaN(parseInt(question?.value, 10)) ?

                          <SuiBox display="flex" justifyContent="space-between" alignItems="center" width="90%">
                            <SuiTypography variant="body2" fontSize=".9rem" fontWeight="bold" color="text">{question?.item?.name}:</SuiTypography>
                            <SuiTypography variant="body2" fontSize=".9rem" fontWeight="bold" color="text">{question?.value}</SuiTypography>
                          </SuiBox>
                          :
                          <>
                            <SuiBox display="flex" justifyContent="space-between" alignItems="center" width="90%">
                              <SuiTypography variant="body2" fontSize=".9rem" fontWeight="bold" color="text">{question?.item?.name}:</SuiTypography>
                              <SuiTypography variant="body2" fontSize=".9rem" fontWeight="bold" color="text">{question?.value}/5</SuiTypography>
                            </SuiBox>

                            <SuiBox
                              component="ul"
                              display="flex"
                              flexDirection="column"
                              width="90%"
                              py={1.5}
                              m={0}
                              sx={{ listStyle: "none" }}
                            >
                              <SuiBox component="li" w="100%" py={0.25} position="relative">
                                <CustomProgress
                                  variant="gradient"
                                  color="info"
                                  value={((parseInt(question?.value, 10) - 1) * 100) / (5 - 1)}
                                />
                              </SuiBox>
                            </SuiBox>
                          </>
                        }
                      </Grid>
                    )
                  })}
                </Grid>
              </SuiBox>
            )
          })}

          <SuiBox
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="flex-end"
            alignItems="center"
            width={isMobile ? "100%" : "95%"}
            mt={2}
          >
            <SuiTypography variant="body2" fontSize=".85rem" fontWeight="bold" color="text" mr={1}>Legend:</SuiTypography>
            <SuiTypography variant="body2" fontSize=".85rem" color="text">
              1 = Poor - 2 = Fair - 3 = Average - 4 = Good - 5 = Excellent
            </SuiTypography>
          </SuiBox>

          <SuiBox mt={3} mb={5} width='80%' mx='auto' display="flex">
              <SuiButton
                sx={{ mx: 1 }}
                fullWidth
                color="info"
                variant="outlined"
                onClick={() => setShowCompanyCandidateScorecardModal(false)}
              >
                Close
              </SuiButton>
          </SuiBox>
        </>
        }
      </SuiBox>
    </Modal>
  )
}

// Typechecking props for the CompanyCandidateScorecardModal
CompanyCandidateScorecardModal.propTypes = {
  showCompanyCandidateScorecardModal: PropTypes.bool.isRequired,
  setShowCompanyCandidateScorecardModal: PropTypes.func.isRequired,
  candidateName: PropTypes.string.isRequired,
  scorecardData: PropTypes.array.isRequired // eslint-disable-line
};
  

export default CompanyCandidateScorecardModal