/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// sui components
import SuiSelect from "components/SuiSelect";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiTypography from 'components/SuiTypography';

function Additional() {
  ReactSession.setStoreType("localStorage");
  const additionalQuestions = ReactSession.get("additionalQuestions");
  const lastApply = { ...ReactSession.get("lastApply") };
  const [questions, setQuestions] = useState([]);

  function onChangeQuestion(questionId, questionValue) {
    if (questionId && questionValue) {
      const lastApplySession = { ...ReactSession.get("lastApply") };
      const lastApplyQuestions = lastApplySession.questions ? lastApplySession.questions : [];
      const newQuestions = [];
      const questionsFilter = lastApplyQuestions.filter(x => x.id === questionId);
      // eslint-disable-next-line
      for (const lastApplyQuestion of lastApplyQuestions) {
        // eslint-disable-next-line
        if (lastApplyQuestion.id === questionId) {
          lastApplyQuestion.answer = questionValue;
        }

        newQuestions.push(lastApplyQuestion);
      }

      if (!questionsFilter.length) {
        newQuestions.push({ id: questionId, answer: questionValue })
      }
      lastApplySession.questions = newQuestions;
      ReactSession.set("lastApply", lastApplySession)
    }
  }

  function questionTypehtml(question) {
    let restHtml = "";
    if (question.type === "line") {
      if (question.default) {
        restHtml = <SuiInput onChange={e => onChangeQuestion(question.id, e.value)} placeholder="" defaultValue={question.default} />
      } else {
        restHtml = <SuiInput onChange={e => onChangeQuestion(question.id, e.value)} placeholder="" />
      }
    } else if (question.type === "choice") {
      if (question.default) {
        restHtml = <SuiSelect onChange={e => onChangeQuestion(question.id, e.value)} options={question.choices} isSearchable={false} on defaultValue={{ value: question.default, label: question.default }} />
      } else {
        restHtml = <SuiSelect onChange={e => onChangeQuestion(question.id, e.value)} options={question.choices} isSearchable={false} name={`select${question.id}`} />
      }
    } else {
      // eslint-disable-next-line
      if (question.default) {
        restHtml = <SuiInput onChange={e => onChangeQuestion(question.id, e.value)} placeholder="" multiline rows={3} defaultValue={question.default} />
      } else {
        restHtml = <SuiInput onChange={e => onChangeQuestion(question.id, e.value)} placeholder="" multiline rows={3} />
      }
    }

    return restHtml;
  }

  useEffect(() => {
    const tempQuestions = [];
    if (additionalQuestions) {
      // eslint-disable-next-line
      for (const additionalQuestion of additionalQuestions) {
        const question = {
          title: additionalQuestion.title,
          id: additionalQuestion.id,
          type: additionalQuestion.type
        }
        const questionChoices = [];
        // eslint-disable-next-line
        for (const choice of additionalQuestion.choice.split("|")) {
          questionChoices.push({ value: choice, label: choice });
        }
        question.choices = questionChoices;
        if (lastApply && lastApply.questions && lastApply.questions.length) {
          // eslint-disable-next-line
          for (const additional of lastApply.questions) {
            if (additional.id && Number(additional.id) === Number(additionalQuestion.id)) {
              question.default = additional.answer;
            }
          }
        }
        question.input = questionTypehtml(question);
        tempQuestions.push(question);
        setQuestions(tempQuestions);
      }
    }
  }, []);


  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">
          Additional Information
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={2}>
        {questions.map(question => (
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
            mb={2}
            className="questionSelect"
            data-id={question.id}
          >
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography variant="button">
                {question.title}
              </SuiTypography>
            </SuiBox>
            {question.input}
          </SuiBox>
        ))}
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for UserSignUp
// Additional.propTypes = {
//   formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default Additional;
