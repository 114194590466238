/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactSession } from 'react-client-session';

// @mui material components
import Grid from "@mui/material/Grid";

// sui components
import SuiSelect from "components/SuiSelect";
// import SuiTagInput from "components/SuiTagInput";
// import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";

import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Wizard application components
// import FormField from "layouts/applications/wizard/components/FormField";

// prop-type is a library for typechecking of props
// import PropTypes from "prop-types";

function Questions() {
  ReactSession.setStoreType("localStorage");
  const sessionQuestions = ReactSession.get("questions");
  const lastApply = { ...ReactSession.get("lastApply") };
  const [questions, setQuestions] = useState([]);

  function onChangeQuestion(questionId, questionValue) {
    if (questionId && questionValue) {
      const lastApplySession = { ...ReactSession.get("lastApply") };
      const lastApplyQuestions = lastApplySession.questions ? lastApplySession.questions : [];
      const newQuestions = [];
      const questionsFilter = lastApplyQuestions.filter(x => x.id === questionId);
      // eslint-disable-next-line
      for (const lastApplyQuestion of lastApplyQuestions) {
        // eslint-disable-next-line
        if (lastApplyQuestion.id === questionId) {
          lastApplyQuestion.answer = questionValue;
        }

        newQuestions.push(lastApplyQuestion);
      }
      
      if (!questionsFilter.length) {
        newQuestions.push({ id: questionId, answer: questionValue })
      }
      lastApplySession.questions = newQuestions;
      ReactSession.set("lastApply", lastApplySession)
      console.log(lastApplySession.questions)
    }
  }

  useEffect(() => {
    const tempQuestions = [];
    // eslint-disable-next-line
    for (const question of sessionQuestions) {
      const questionJson = {
        title: question.title,
        id: question.id, 
        answer: null, 
        required: question.required, 
        choices: question.choices, 
        type: question.type, 
        default: question.default
      }
      const questionChoices = [];
      // eslint-disable-next-line
      for (const choice of question.choice.split("|")) {
        questionChoices.push({ value: choice, label: choice });
      }
      questionJson.choices = questionChoices;
      if (lastApply && lastApply.questions && lastApply.questions.length) {
        // eslint-disable-next-line
        for (const additional of lastApply.questions) {
          if (additional.id && Number(additional.id) === Number(question.id)) {
            questionJson.default = additional.answer;
          }
        }
      }
      tempQuestions.push(questionJson);
    }
    setQuestions(tempQuestions);
  }, []);

  const updateResponse = (id, value) => {
      const questionsCopy = [...questions]
      questionsCopy.filter(item => item.id === id)[0].answer = value
      setQuestions(questionsCopy)
      onChangeQuestion(id, value)
  }

  return (
    <SuiBox>
      <SuiBox width="100%" textAlign="center" mb={4}>
        <SuiTypography variant="h5" fontWeight="regular">
          Please answer the following pre-screening questions for this application:
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={2}>
        <Grid container spacing={2} className="webLinks">
          {questions.map(question => (
            <Grid item xs={12} md={12}>
              <SuiBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
                mb={2}
                className="questionSelect"
                data-id={question.id}
              >
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography variant="button">
                    {question.title}
                  </SuiTypography>
                </SuiBox>
                {question.type === "choice" ? (
                  <>
                    {question.type === "default" ? (
                      <SuiSelect onChange={e => onChangeQuestion(question.id, e.value)} options={question.choices} defaultValue={{ value: question.default, label: question.default }} />
                    ) : (
                      <SuiSelect onChange={e => onChangeQuestion(question.id, e.value)} options={question.choices} name={`select${question.id}`} />
                    )}
                  </>
                ) : (
                  <SuiBox>
                    {question.type === "default" ? (
                      <SuiInput placeholder="" multiline rows={3} defaultValue={question.default} inputProps={{ maxLength: 5000 }} onChange={e => updateResponse(question.id, e.target.value)} />
                    ) : (
                      <SuiInput placeholder="" multiline rows={3} inputProps={{ maxLength: 5000 }} onChange={e => updateResponse(question.id, e.target.value)} />
                    )}
                    <SuiTypography
                        mt={1}
                        variant="subtitle2"
                        color={questions?.filter(item => item?.id === question?.id)[0]?.answer?.length > 5000 ? "error" : "auto"}
                        fontSize=".9rem"
                        align="right"
                    >
                        {questions?.filter(item => item?.id === question?.id)[0]?.answer?.length || "0"}/5000 chars.
                    </SuiTypography>
                  </SuiBox>
                )}
              </SuiBox>
            </Grid>
          ))}
        </Grid>
      </SuiBox>

    </SuiBox>
  );
}

export default Questions;
