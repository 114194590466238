/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types"
// @mui material components
import Card from "@mui/material/Card"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiSelect from "components/SuiSelect";
import Grid from "@mui/material/Grid";
import SuiButton from 'components/SuiButton'
// Soft UI Dashboard PRO React base styles
// import typography from "assets/theme/base/typography"

function ConversionRateCard({ setYear }) {

  const years = [{ value: 2022, label: 2022 }];
  const currentYear = new Date().getFullYear();

  for(let i = 2023; i <= currentYear; i += 1) {
     years.push({ value: i, label: i })
  }

  function searchByYear() {
    const year = Number($("input[name=year]").val());
    setYear(year)
  }

  return (
    <Card sx={{ height: "auto" }}>
      <SuiBox p={3}>
        <SuiBox>
          <SuiBox display="flex" flexDirection="column" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize" mb={2}>Search conversion rate data</SuiTypography>
            <SuiBox>
              <SuiBox ml={1} mb={0.5}>
                <SuiTypography variant="button">
                  Year
                </SuiTypography>
              </SuiBox>
              <Grid container xs={12} md={12} spacing={1} className="requiredBox">
                <Grid item xs={12} sm={12}>
                  <SuiSelect
                    placeholder="Year"
                    options={years}
                    name="year"
                    defaultValue={{ value: 2022, label: 2022 }}
                  />
                </Grid>
              </Grid>
            </SuiBox>
            <SuiBox mt={2} mb={5}>
              <SuiButton fullWidth color="info" variant="outlined" onClick={searchByYear}> Search </SuiButton>
            </SuiBox>
          </SuiBox>

        </SuiBox>
      </SuiBox>
    </Card>
  )
}

// Typechecking props for the RecruiterProfileInfoCard
ConversionRateCard.propTypes = {
  setYear: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
}

export default ConversionRateCard
