/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo, useEffect, useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiPagination from "components/SuiPagination";
// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

function DataTable({
  entriesPerPage,
  canSearch,
  activeJob,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  totalResults,
  customPagination,
  view,
  selectAll,
  setSelectAll,
  paginationData // eslint-disable-line
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  // const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
  const columns = useMemo(() => table.columns, [table, table.columns]);
  const data = useMemo(() => table.rows, [table, table.rows]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  // const setEntriesPerPage = ({ value }) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SuiPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SuiPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = column => {
    if (column.disableSortBy) return ""

    let sortedValue

    if (isSorted && column.isSorted) sortedValue = column.isSortedDesc ? "desc" : "asce"
    else if (isSorted) sortedValue = "none"
    else sortedValue = false
    
    return sortedValue
  }

  // Setting the entries starting point
  let entriesStart
  if (totalResults === 0) entriesStart = 0
  else if (paginationData) {
    if (paginationData.count === 0) entriesStart = 0
    else {
      entriesStart = (paginationData.current-1 === 0) ? paginationData.current : (((paginationData.current-1) * pageSize) + 1)
    }
  }
  else {
    entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1
  }

  // Setting the entries ending point
  let entriesEnd;

  if (totalResults === 0) entriesEnd = 0
  else if (paginationData) {
    if (paginationData.count === 0) entriesEnd = 0
    else if (paginationData.current === 1) {entriesEnd = pageSize}
    else if (paginationData.current === pageOptions.length - 1) entriesEnd = rows.length
    else entriesEnd = pageSize * paginationData.current
  }
  else {
    if (pageIndex === 0) entriesEnd = pageSize // eslint-disable-line
    else if (pageIndex === pageOptions.length - 1) entriesEnd = rows.length
    else entriesEnd = pageSize * (pageIndex + 1)
  }

  if (totalResults && entriesEnd > totalResults) {
    entriesEnd = totalResults;
  }

  let customShowing = "";
  if (customPagination) {
    const customStart = ((customPagination.current - 1) * defaultValue) + 1;
    const customEnd = customPagination.count >= (defaultValue * customPagination.current) ? (defaultValue * customPagination.current) : customPagination.count;
    customShowing = `Showing ${customStart} to ${customEnd} of ${customPagination.count}`;
  }

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {/* entriesPerPage || */}
      {canSearch ? (
        <SuiBox display="flex" justifyContent="end" alignItems="center" p={3}>
          {/* {entriesPerPage && (
            <SuiBox display="flex" alignItems="center">
              <SuiSelect
                defaultValue={{ value: defaultValue, label: defaultValue }}
                options={entries.map((entry) => ({ value: entry, label: entry }))}
                onChange={setEntriesPerPage}
                size="small"
              />
              <SuiTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </SuiTypography>
            </SuiBox>
          )} */}
          <SuiBox display="inherit" alignItems="inherit">
            {activeJob && (
              <SuiBox display="inherit" alignItems="inherit" mx={2}>
                < Checkbox checked />
                <SuiTypography variant="overline">
                  With jobs only
                </SuiTypography>
              </SuiBox>
            )}
            {canSearch && (
              <SuiBox width="12rem" ml="auto">
                <SuiInput
                  placeholder="Search..."
                  value={search}
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </SuiBox>
            )}
          </SuiBox>
        </SuiBox>
      ) : null
      }
      <Table {...getTableProps()} >
        <SuiBox component="thead" >
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                { ...column.render("Header") === "check" ? (
                  <DataTableHeadCell
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                  >
                    <Checkbox checked={selectAll} onChange={() => setSelectAll(!selectAll)}/>
                  </DataTableHeadCell>
                ) : (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                )}
              ))}
            </TableRow>
          ))}
        </SuiBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {/* PAGINATIONS BELOW */}
      {/* PAGINATIONS BELOW */}
      {/* PAGINATIONS BELOW */}
      <SuiBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <SuiBox mb={{ xs: 3, sm: 0 }}>
            { customShowing ? (
              <SuiTypography variant="button" color="secondary" fontWeight="regular">
                {customShowing}
              </SuiTypography>
            ) : (
              <SuiTypography variant="button" color="secondary" fontWeight="regular">
                Showing {entriesStart} to {rows.length < entriesPerPage.defaultValue ? rows.length : entriesEnd} of {totalResults || rows.length} {view || "candidates"}
              </SuiTypography>
            )}
          </SuiBox>
        )}
        {pageOptions.length > 1 && (
          <SuiPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <SuiPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </SuiPagination>
            )}
            {renderPagination.length > 6 ? (
              <SuiBox width="5rem" mx={1}>
                <SuiInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </SuiBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <SuiPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </SuiPagination>
            )}
          </SuiPagination>
        )}
      </SuiBox>
    </TableContainer >
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  activeJob: false,
  totalResults: undefined,
  view: undefined,
  paginationData: undefined,
  setSelectAll: null,
  selectAll: false,
  customPagination: undefined
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  activeJob: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  totalResults: PropTypes.number,
  selectAll: PropTypes.bool,
  setSelectAll: PropTypes.objectOf(PropTypes.any),
  view: PropTypes.string,
  paginationData: PropTypes.object, // eslint-disable-line
  customPagination: PropTypes.objectOf(PropTypes.any),
};

export default DataTable;
