/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// MUI
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React components
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
// ProductsList page components
import ProductCell from "layouts/pages/profile/applications-list/components/ProductCell";
import ActionCell from "layouts/pages/profile/applications-list/components/ActionCell";

const applicationStatusTooltip = status => {
  switch (status) {
    case "Interview":
      return "INTERVIEW: Good luck with your interviews! Feel free to contact us if you have any questions"
    case "Intro Requested":
      return "INTRO REQUESTED: Our client wants to meet you! Please check your email to schedule your first interview"
    case "Withdrawn":
      return "WITHDRAWN: As you requested, your application for this position has been withdrawn"
    case "Declined":
      return "DECLINED: Our client decided not to move forward. Please check our job board for more opportunities"
    case "Submitted":
      return "SUBMITTED: The hiring manager will review your application shortly"
    default:
      return ""
  }
}

function applicationStatus(value) {
  let color = "secondary"
  
  if (["Intro Requested", "Interview"].includes(value)) color = "info"
  else if (["Declined", "Withdrawn"].includes(value)) color = "error"
  else if (["Hired"].includes(value)) color = "success"

  return (
    <SuiBox display="flex" justifyContent="center" alignItems="center">
      <SuiBadge variant="contained" color={color} size="xs" badgeContent={value} container />
      <Tooltip title={applicationStatusTooltip(value)} placement="top">
        <HelpIcon sx={{ marginLeft: 2 }} fontSize="small" />
      </Tooltip>
    </SuiBox>
  )
}

export default {
  columns: [
    {
      Header: "Position",
      accessor: "position",
      width: "40%",
      Cell: ({ value: [name, jobId] }) => (
        <ProductCell name={name} jobId={jobId} />
      ),
    },
    { Header: "Method", accessor: "method" },
    { Header: "Category", accessor: "category" },
    { Header: "Date", accessor: "date" },
    { Header: "Salary", accessor: "salary" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (applicationStatus(value)),
    },
    { Header: "Feedback", accessor: "feedbackSent" },
    { Header: "Action", accessor: "action" },
  ],

  rows: [
    {
      position: "",
      method: "",
      category: "",
      date: "",
      salary: "",
      status: "",
      feedbackSent: "",
      action: <ActionCell />
    },
  ],
};
