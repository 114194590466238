/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
import { useState, useEffect } from "react"
// Utils
import { capitalize } from 'utils/commonFunctions'
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiSelect from "components/SuiSelect"
import SuiInput from "components/SuiInput"
import SuiButton from "components/SuiButton"
import SuiAlert from "components/SuiAlert"
// Soft UI Dashboard PRO React example components
import breakpoints from "assets/theme/base/breakpoints"
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
// Data
import ConversionDataTable from "layouts/pages/conversionData/table/conversionDataTable"
import ConversionDataTableData from "layouts/pages/conversionData/data/ConversionDataTableData"

function ConversionData() {
  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")
  const user = ReactSession.get("user")

  // Only admins can access this page
  useEffect(() => {if(user.type !== "tecla_admin") window.location.replace("/r/dashboard")}, [])

  const [isFetchingData, setIsFetchingData] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const [selectedMonthGoal, setSelectedMonthGoal] = useState(null)
  const [selectedMonthGoalToShow, setSelectedMonthGoalToShow] = useState(null)
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()+1)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const conversionDataTables = { "data": ConversionDataTableData }
  const [conversionDataStates, setConversionDataStates] = useState(conversionDataTables)
  const smallConversionDataTables = { "data": ConversionDataTableData }
  const [smallConversionDataStates, setSmallConversionDataStates] = useState(smallConversionDataTables)

  const fetchConversionData = () => {
    setIsFetchingData(true)

    const buildRows = recruiters => {
      const rows = []

      if (recruiters.length > 0) {
        recruiters.forEach(recruiter => {
          const recruiterObj = {
            recruiter: [
              `${capitalize(recruiter?.recruiter?.user?.first_name)} ${capitalize(recruiter?.recruiter?.user?.last_name)}`,
              recruiter?.recruiter?.user?.photo ? `${process.env.REACT_APP_API_ROUTE}${recruiter?.recruiter?.user?.photo}` : null
            ],
            action: {
              recruiterId: recruiter?.id,
              invites: recruiter?.invites
            },
            applicationsInDb: {
              recruiterId: recruiter?.id,
              applications: recruiter?.applications
            },
          }

          rows.push(recruiterObj)
        })
      }

      return rows
    }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/getConversionData/?month=${selectedMonth}&year=${selectedYear}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
      .then(async response => {
        const res = await response.json()

        if (res.success) {
          setSelectedMonthGoal(res.data.monthGoal)
          setSelectedMonthGoalToShow(res.data.monthGoal.value)
          
          const tempConversionDataStates = {...conversionDataStates}

          tempConversionDataStates.data.rows = buildRows(res.data.recruiters.sort((a,b) => a.id - b.id))
          setConversionDataStates(tempConversionDataStates)
          setSmallConversionDataStates(tempConversionDataStates)

          setIsFetchingData(false)
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => { fetchConversionData() }, [])

  useEffect(() => { fetchConversionData() }, [selectedMonth, selectedYear])

  const [tabsOrientation, setTabsOrientation] = useState("horizontal")

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal")
    }

    window.addEventListener("resize", handleTabsOrientation)

    handleTabsOrientation()

    return () => window.removeEventListener("resize", handleTabsOrientation)

  }, [tabsOrientation])

  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ]

  let yearOptions = []

  const populateYearOptions = () => {
    const yearsArr = []

    const currentYear = new Date().getFullYear()
    let startYear = 2022
    while ( startYear <= currentYear ) {
      yearsArr.push({ value: startYear, label: startYear })
      startYear+=1
    }   

    yearOptions = yearsArr.reverse()
  }
  populateYearOptions()

  const setConversionData = () => {
    setIsFetchingData(true)
    
    // Get each recruiters invites input
    const recruitersData = []
    const invitesInputs = $("input[type=number][name=recruiterMonthInvitesInput]")

    Object.values(invitesInputs).forEach(invitesInput => {
      if (invitesInput.id) {
        recruitersData.push({
          invites: invitesInput.valueAsNumber || 0,
          id: parseInt(invitesInput.id, 10)
        })
      }
    })

    // Get each recruiters applications input
    const applicationsInputs = $("input[type=number][name=recruiterApplicationsInDBInput]")

    Object.values(applicationsInputs).forEach(applicationsInput => {
      if (applicationsInput.id) {
        const recruitersDataObj = recruitersData.filter(item => item.id === parseInt(applicationsInput.id, 10))[0]
        recruitersDataObj.applications = applicationsInput.valueAsNumber || 0
      }
    })

    const conversionData = {
      monthGoal: selectedMonthGoal,
      recruiters: recruitersData
    }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/setConversionData/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(conversionData)

    })
      .then(async response => {
        const res = await response.json()

        if (res.success) {

          // Assign new values to each recruiters invites and applications inputs
          const newConversionDataStates = conversionDataStates
          newConversionDataStates.data.rows.forEach(recruiter => {

            const newRecruiterData = recruitersData.filter(rec => rec.id === recruiter.action.recruiterId)[0]
            recruiter.action.invites = newRecruiterData.invites // eslint-disable-line
            recruiter.applicationsInDb.applications = newRecruiterData.applications // eslint-disable-line
          })

          setConversionDataStates(newConversionDataStates)
          setSmallConversionDataStates(newConversionDataStates)

          setIsFetchingData(false)
          setShowSuccess(true)
          setTimeout(() => setShowSuccess(false), 2000)
        }
        else {
          setShowError(true)
          setTimeout(() => setShowError(false), 2000)
        }
      })
      .catch(error => {
        setShowError(true)
        setTimeout(() => setShowError(false), 2000)
        console.error('There was an error!', error)
      })
  }

  return (
    <RecruiterDashboardLayout>

      <SuiBox display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" >
        <SuiTypography variant="h3" fontWeight="bold">
          Conversion Data for {monthOptions.filter(opt => opt.value === selectedMonth)[0].label} {selectedYear}
        </SuiTypography>

        <SuiBox display="flex" flexWrap="wrap" maxWidth="50%">
          <SuiBox sx={{ mx: 1, maxWidth: "15rem" }}>
            <SuiTypography variant="button">Monthly goal (applications)</SuiTypography>
            <SuiInput
              name="monthGoal"
              type="number"
              value={selectedMonthGoalToShow}
              disabled={isFetchingData}
              onChange={e => {
                setSelectedMonthGoalToShow(parseInt(e.target.value, 10))
                const newMonthGoal = selectedMonthGoal
                newMonthGoal.value = parseInt(e.target.value, 10)
                setSelectedMonthGoal(newMonthGoal)
              }}
            />
          </SuiBox>

          <SuiBox sx={{ mx: 1, minWidth: "12rem" }}>
            <SuiTypography variant="button">Month</SuiTypography>
            <SuiSelect
              options={monthOptions}
              name="month"
              isSearchable
              defaultValue={monthOptions.filter(option => option.value === selectedMonth)}
              isDisabled={isFetchingData}
              onChange={e => setSelectedMonth(e.value)}
            />
          </SuiBox>

          <SuiBox sx={{ mx: 1, minWidth: "12rem" }}>
            <SuiTypography variant="button">Year</SuiTypography>
            <SuiSelect
              options={yearOptions}
              name="year"
              isSearchable
              defaultValue={yearOptions.filter(option => option.value === selectedYear)}
              isDisabled={isFetchingData}
              onChange={e => setSelectedYear(e.value)}
            />
          </SuiBox>
        </SuiBox>

      </SuiBox>
      
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12}>

          {showSuccess && <SuiAlert color="success">Information sent!</SuiAlert>}
          {showError && <SuiAlert color="error">An error ocurred. Please try again later!</SuiAlert>}

          <Card sx={{ minHeight: "65vh" }}>
            {isFetchingData ?
                <SuiBox display="flex" justifyContent="center" alignItems="center" my={30} >
                  <CircularProgress size={40} />
                </SuiBox>
              :
              <>              
                <SuiBox p={2} mt={1} width="100%" height="100%" minHeight="50vh">
                  <ConversionDataTable title="" value={0} index={0} table={conversionDataStates.data} smallTable={smallConversionDataStates.data} />
                </SuiBox>
              </>
            }

          {!isFetchingData && (
            <SuiBox display="flex" justifyContent="flex-end" mt={-12.5} mr={5} mb={5}>
              <SuiButton
                variant="gradient"
                color="info"
                disabled={isFetchingData}
                onClick={() => setConversionData()}
                className="changeStep"
                sx={{ width: "10rem" }}
              >
                Save
              </SuiButton>
            </SuiBox>
          )}

          </Card >

        </Grid>
      </Grid>
    </RecruiterDashboardLayout>
  );
}

export default ConversionData;
