/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from "react-client-session";
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress"
// Soft UI Dashboard PRO React components
import SuiDatePicker from "components/SuiDatePicker";
import SuiSelect from "components/SuiSelect";
import SuiBox from "components/SuiBox";
import SuiAlert from "components/SuiAlert";
import SuiTypography from "components/SuiTypography";
// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

function Advanced({ formData, showRequiredError }) {
  ReactSession.setStoreType("localStorage");
  const candidate = ReactSession.get("candidate");

  const [countries, setCountries] = useState([]);
  const [countryPlaceholder, setCountryPlaceholder] = useState("Select Country");

  const { formField, values, errors, touched } = formData;
  const { phone } = formField;

  const {
    phone: phoneV
  } = values;

  if (candidate.phone && candidate.phone.includes("+")) {
    candidate.phone = candidate.phone.slice(1);
  }

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateCountries = async () => {

    $("input[name=phone]").attr("placeholder", candidate.phone ? candidate.phone : "+1 555 555");
    $("input[name=dateOfBirth]").attr("placeholder", candidate.dateOfBirth ? candidate.dateOfBirth : "YYYY-DD-MM");

    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = [];
    // eslint-disable-next-line
    Object.keys(interests?.data?.countries).forEach(function (key) {
      newData.push({ value: interests?.data?.countries[key]?.id, label: interests?.data?.countries[key]?.name });
      
      if (candidate && candidate.country && interests?.data?.countries[key]?.id === candidate.country.id) {
        setCountryPlaceholder(interests?.data?.countries[key]?.name);
      }
    });
    setCountries(newData);

  }


  useEffect(() => { populateCountries() }, []);

  function changeCountry(e) {
    $(".countryBox").attr("data-val", e.value);
  }

  // Formatting birthday like MM-DD
  const formatBirthday = () => {
    let value = $("input[name=dateOfBirth]:visible")[0].value // eslint-disable-line
    value = value.substring(5)
    $("input[name=dateOfBirth]").val(value)
  }

  return (
    <SuiBox>
      <SuiBox mt={2}>
        {showRequiredError && <SuiAlert color="secondary" className="alertError">Please add a Country to continue.</SuiAlert>}
        {countries.length ?
          <Grid container spacing={3} textAlign="left">
            <Grid item xs={12} sm={12}>
              <Grid item xs={12} sm={12} className="countryBox">
                <SuiTypography variant="button" ml={1}>
                  Where are you located? (Required)
                </SuiTypography>

                <SuiSelect
                  placeholder={countryPlaceholder}
                  options={countries}
                  name="country"
                  onChange={changeCountry}
                />
                <br />
              </Grid>

              <Grid item xs={12} sm={12}>
                <SuiTypography variant="button" ml={1}>
                  Phone number (including country code)
                </SuiTypography>
                <FormField
                  type={phone.type}
                  label={phone.label}
                  name={phone.name}
                  value={phoneV}
                  placeholder={phone.placeholder}
                  error={errors.phone && touched.phone}
                  success={phoneV.length > 0 && !errors.phone}
                  icon={{
                    component: "add",
                    direction: "left",
                  }}
                  // event on paste taste
                  onPaste={e => {
                    e.preventDefault();
                    // remove all characters except numbers
                    const paste = e.clipboardData.getData("text/plain").replace(/\D/g, "");
                    // insert pasted text
                    document.execCommand("insertText", false, paste);
                  }}
                />
              </Grid>
              
              <Grid item xs={12} sm={12}>
                <SuiTypography variant="button" ml={1}>
                  Date of birth
                </SuiTypography>
                <SuiDatePicker
                  input={{ placeholder: "Date of Birth", name: "dateOfBirth" }}
                  name="dateOfBirth"
                  onOpen={() => {
                    // hide year field
                    const instances = document.getElementsByClassName("cur-year");
                    for(let i= 0; i< instances.length; i+=1) instances[i].style.display="none"
                  }}
                  onChange={() => formatBirthday()}
                />
              </Grid>

            </Grid>
          </Grid>
        :
          <SuiBox display="flex" justifyContent="center" alignItems="center" py={20}>
            <CircularProgress size={40} />
          </SuiBox >
        }
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for UserSignUp
Advanced.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  showRequiredError: PropTypes.bool.isRequired,
};

export default Advanced;
