/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react"
import { ReactSession } from "react-client-session"
// formik components and data
import { Formik, Form } from "formik"
import validations from "layouts/applications/company-wizard/formSchemas/validations"
import form from "layouts/applications/company-wizard/formSchemas/form"
import initialValues from "layouts/applications/company-wizard/formSchemas/initialValues"
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React example components
import CompanyDashboardLayout from "examples/LayoutContainers/CompanyDashboardLayout"
// Wizard page components
import Basic from "layouts/applications/company-wizard/components/Basic"
import Interests from "layouts/applications/company-wizard/components/Interests"
import HighLights from "layouts/applications/company-wizard/components/Highlights/highlights"

const getSteps = () => ["General", "Highlights", "Categories"]

let setStep = true
function Wizard() {
  // Validation
  ReactSession.setStoreType("localStorage")
  const user = ReactSession.get("user")
  const company = {...ReactSession.get("company")}
  const token = ReactSession.get("token")
  const postToken = `Token ${token}`
  const searchParams = new URLSearchParams(window.location.search)
  let urlStep = searchParams.get('step')

  useEffect(() => {
    document.title = `Wizard | TECLA`;
  }, []);

  /* State to store highlights wizard step information */
  const [selectedCountry, setSelectedCountry] = useState(company?.country)
  const [selectedCompanySize, setSelectedCompanySize] = useState(company?.size)
  const [selectedCountryText, setSelectedCountryText] = useState(null)
  const [highlights, setHighlights] = useState(company?.highlights)
  const [whyWorkHere, setWhyWorkHere] = useState(company?.whyWorkHere)
  const [culture, setCulture] = useState(company?.culture)
  const [perks, setPerks] = useState(company?.perks)
  const biovalue = company.description ? company.description : "<br/><br/><br/><br/>";
  const [bioValue, setBioValue] = useState(biovalue);

  function getCompanyData() {
    const headers = { "Content-Type": "application/json" }
    if (token) headers.Authorization = `Token ${token}`

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/company/`, { headers })
      .then(async response => {
        const data = await response.json()
        ReactSession.set("company", data.data)

        if (data.data.highlights) setHighlights(data.data.highlights)
        if (data.data.whyWorkHere) setWhyWorkHere(data.data.whyWorkHere)
        if (data.data.culture) setCulture(data.data.culture)
        if (data.data.perks) setPerks(data.data.perks)
      })
      .catch(error => console.error('There was an error!', error))
  }

  const [activeStep, setActiveStep] = useState(0)
  
  if (urlStep && setStep) {
    setStep = false
    urlStep = Number(urlStep)
    setActiveStep(urlStep)
  }

  const steps = getSteps()
  const { formId, formField } = form
  const currentValidation = validations[activeStep]
  let validPost = true

  const isLastStep = activeStep === steps.length - 1

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      console.log("submit")
      // submitForm(values, actions);
    } else {
      // setActiveStep(activeStep + 1);
      actions.setTouched({});
      // actions.setSubmitting(false);
    }
  };

  const handleNext = () => setActiveStep(activeStep + 1)

  function getTech(techString) {
    const techs = JSON.parse($(".techsBox").attr("data-techs"));
    let data = null;
    // eslint-disable-next-line
    $.each(techs, function (i, val) {
      if (techString === val.name) {
        data = { "id": val.id, "name": val.name };
      }
    });

    return data;
  }

  // eslint-disable-next-line
  function updateUser(formValues) {
    if ($(".MuiTypography-caption:visible").text().length === 0 && user && validPost) {
      validPost = false;
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/companies/updateCompany/`;
      const postBody = {};

      if ($("input[name=firstName]:visible").val() && $("input[name=firstName]:visible").val().length) {
        postBody.name = $("input[name=firstName]").val();
      }

      if (selectedCountry && selectedCountry !== company.country) postBody.country = selectedCountry
      if (selectedCompanySize && selectedCompanySize !== company.size) postBody.size = selectedCompanySize

      if ($("textarea[name=bio]:visible").val() && $("textarea[name=bio]:visible").val().length) {
        postBody.description = bioValue;
      }

      if ($(".techsBox .react-tag-input__tag__content:visible").length) {
        const techArray = [];
        // eslint-disable-next-line
        $.each($(".techsBox .react-tag-input__tag__content:visible"), function (i, val) {
          const tech = getTech($(val).text());
          if (tech) {
            techArray.push(tech);
          }
          if (techArray.length) {
            postBody.industries = techArray;
          }
        });
      }
    
      postBody.description = bioValue
      postBody.website = formValues.website
      postBody.youtube = formValues.youtube
      postBody.linkedin = formValues.linkedin
      postBody.twitter = formValues.twitter
      postBody.highlights = highlights
      postBody.whyWorkHere = whyWorkHere
      postBody.culture = culture
      postBody.perks = perks

      if (Object.keys(postBody).length > 0) {
        const requestMetadata = {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': postToken
          },
          body: JSON.stringify(postBody)
        };
        

        fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {

            validPost = true
            if (response.success) {
              // eslint-disable-next-line
              Object.keys(postBody).forEach(function (key) {
                if (key !== "user") {
                  company[key] = postBody[key];
                }
              })
              if (selectedCountryText) {
                company.country = selectedCountryText;
              }
              ReactSession.set("company", company)

              

              if (activeStep === 0) {
                handleNext()
              } else {
                window.location.replace("/c/profile")
              }
            }
          })
      } else if (activeStep === 0) {
        handleNext()
      } else {
        window.location.replace("/c/profile")
      }

    }
  }

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <Basic
          formData={formData}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          bioValue={bioValue}
          setBioValue={setBioValue}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          setSelectedCountryText={setSelectedCountryText}
          selectedCompanySize={selectedCompanySize}
          setSelectedCompanySize={setSelectedCompanySize}
        />
      case 1:
        return <HighLights
            formData={formData}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            highlights={highlights}
            setHighlights={setHighlights}
            whyWorkHere={whyWorkHere}
            setWhyWorkHere={setWhyWorkHere}
            culture={culture}
            setCulture={setCulture}
            perks={perks}
            setPerks={setPerks}
          />
      case 2:
        return <Interests formData={formData} setActiveStep={setActiveStep} activeStep={activeStep} updateUser={updateUser} />
      default:
        return null
    }
  }

  useEffect(() => { getCompanyData() }, [])

  return (
    <CompanyDashboardLayout>
      <SuiBox pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SuiBox mt={6} mb={1} textAlign="center">
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">Build Your Profile</SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="regular" color="secondary">Please share as many details as possible for candidates</SuiTypography>
            </SuiBox>

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Card>
              <SuiBox p={2}>
                <SuiBox>
                  <Formik initialValues={initialValues} validationSchema={currentValidation} onSubmit={handleSubmit} >
                    {({ values, errors, touched }) => (
                      <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">
                        <SuiBox>
                          {getStepContent(activeStep, { values, touched, formField, errors})}
                        </SuiBox>
                      </Form>
                    )}
                  </Formik>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>

    </CompanyDashboardLayout>
  );
}

export default Wizard
