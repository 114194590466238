/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';

import $ from 'jquery';

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
// import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

// Images
// import team2 from "assets/images/logos/Tecla_Logo_bg.png";
function Basic({ formData }) {
  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("user");
  const candidate = ReactSession.get("candidate");
  // const lastApply = ReactSession.get("lastApply");
  const [countries, setCountries] = useState([]);
  const [countryPlaceholder, setCountryPlaceholder] = useState("Select Country");
  // const token = ReactSession.get("token");
  const { formField, values, errors, touched } = formData;
  // ,email
  const { firstName, lastName, email, phone } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    phone: phoneV
  } = values;

  const lastApply = ReactSession.get("lastApply");
  
  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj

    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateCountries = async () => {

    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = [];
    // eslint-disable-next-line
    Object.keys(interests?.data?.countries).forEach(function (key) {
      newData.push({ value: interests?.data?.countries[key]?.id, label: interests?.data?.countries[key]?.name });

      if (candidate && candidate?.country && interests?.data?.countries[key]?.id === candidate?.country.id) {
        setCountryPlaceholder(interests?.data?.countries[key]?.name);
      }

      if (!candidate && lastApply && lastApply?.country && lastApply?.country.id === interests?.data?.countries[key]?.id) {
        setCountryPlaceholder(interests?.data?.countries[key]?.name);
      }
    });

    setCountries(newData);
  }

  useEffect(() => { populateCountries() }, []);

  function changeCountry(e) {
    $(".countryBox").attr("data-val", e.value);
  }

  if (candidate && candidate?.phone && candidate?.phone.includes("+")) {
    candidate.phone = candidate?.phone.slice(1);
  }


  const socialMediaList = [];
  const socialMediaInputsDefault = [];
  if ((lastApply && lastApply?.linkedin) || (candidate && candidate?.linkedin)) { 
    socialMediaInputsDefault.push({ label: "LinkedIn Profile", name: "linkedin", value: lastApply?.linkedin ? lastApply?.linkedin : candidate?.linkedin })
  } else {
    socialMediaInputsDefault.push({ label: "LinkedIn Profile", name: "linkedin", value: "" })
  }
  if ((lastApply && lastApply?.github) || (candidate && candidate?.github)) { 
    socialMediaInputsDefault.push({ label: "Github", name: "github", value: lastApply?.github ? lastApply?.github : candidate?.github })
  } else {
    socialMediaList.push({ value: "github", label: "Github" })
  }
  if ((lastApply && lastApply?.portfolio) || (candidate && candidate?.portfolio)) { 
    socialMediaInputsDefault.push({ label: "Portfolio", name: "portfolio", value: lastApply?.portfolio ? lastApply?.portfolio : candidate?.portfolio })
  } else {
    socialMediaList.push({ value: "portfolio", label: "Portfolio" })
  }
  if ((lastApply && lastApply?.website) || (candidate && candidate?.website)) { 
    socialMediaInputsDefault.push({ label: "Website", name: "website", value: lastApply?.website ? lastApply?.website : candidate?.website })
  } else {
    socialMediaList.push({ value: "website", label: "Website" })
  }
  if ((lastApply && lastApply?.twitter) || (candidate && candidate?.twitter)) { 
    socialMediaInputsDefault.push({ label: "Twitter", name: "twitter", value: lastApply?.twitter ? lastApply?.twitter : candidate?.twitter })
  } else {
    socialMediaList.push({ value: "twitter", label: "Twitter" })
  }
  const [socialMediaOptions, setSocialMediaOptions] = useState(socialMediaList);
  const [socialMediaInputs, setSocialMediaInputs] = useState(socialMediaInputsDefault);

  function addMediaInput() {
    const media = $("[name=media]").val();
    const tempMedia = [];
    const temsocialMediaInputs = [...socialMediaInputs]
    // eslint-disable-next-line
    for (const socialMedia of [...socialMediaOptions]) {
      if (media !== socialMedia.value) {
        tempMedia.push(socialMedia);
      } else {
        temsocialMediaInputs.push({ label: socialMedia.label, name: socialMedia.value, value: "" })
      }
    }
    setSocialMediaInputs(temsocialMediaInputs);
    setSocialMediaOptions(tempMedia);
  }

  function closeMediaInput(mediaName, mediaLabel) {
    const temsocialMediaInputs = []
    const tempMedia = [...socialMediaOptions];
    // eslint-disable-next-line
    for (const socialMediaInput of [...socialMediaInputs]) {
      if (mediaName !== socialMediaInput.name) {
        temsocialMediaInputs.push(socialMediaInput);
      }
    }
    tempMedia.push({ value: mediaName, label: mediaLabel });

    setSocialMediaInputs(temsocialMediaInputs);
    setSocialMediaOptions(tempMedia);
  }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">
          Basic details and contact information
        </SuiTypography>
      </SuiBox>
      <SuiBox>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <SuiTypography variant="button" ml={1} mb={1}>
                  First name
                </SuiTypography>
                {user && user.first_name ? (
                  <FormField
                    type={firstName.type}
                    label={firstName.label}
                    name={firstName.name}
                    disabled="disabled"
                    value={user.first_name}
                  />
                ) :
                  <FormField
                    type={firstName.type}
                    label={firstName.label}
                    name={firstName.name}
                    value={firstNameV}
                    placeholder={firstName.placeholder}
                    error={errors.firstName && touched.firstName}
                    success={firstNameV.length > 0 && !errors.firstName}
                  />
                }
              </Grid>
              <Grid item xs={6}>
                <SuiTypography variant="button" ml={1} mb={1}>
                  Last name
                </SuiTypography>
                {user && user.last_name ? (
                  <FormField
                    type={lastName.type}
                    label={lastName.label}
                    name={lastName.name}
                    disabled="disabled"
                    value={user.last_name}
                  />
                ) :
                  <FormField
                    type={lastName.type}
                    label={lastName.label}
                    name={lastName.name}
                    value={lastNameV}
                    placeholder={lastName.placeholder}
                    error={errors.lastName && touched.lastName}
                    success={lastNameV.length > 0 && !errors.lastName}
                  />
                }
              </Grid>
            </Grid>
            {!user ? (
              <Grid item xs={12}>
                <SuiTypography variant="button" ml={1} mb={1}>
                  Email address
                </SuiTypography>
                <FormField
                  type={email.type}
                  label={email.label}
                  name={email.name}
                  value={emailV}
                  placeholder={email.placeholder}
                  error={errors.email && touched.email}
                  success={emailV.length > 0 && !errors.email}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} className="countryBox">
              <SuiTypography variant="button" ml={1}>
                Location
              </SuiTypography>
              {candidate && candidate?.country ? (
                <FormField
                    type="text"
                    label="country"
                    name="country"
                    disabled="disabled"
                    value={candidate?.country.name}
                  />
              ) :
                <SuiSelect
                  placeholder={countryPlaceholder}
                  options={countries}
                  name="country"
                  onChange={changeCountry}
                />
              }
              {!candidate || !candidate?.country ? (
                <br />
              ) : null }
            </Grid>
            <Grid item xs={12}>
              <SuiTypography variant="button" ml={1} mb={1}>
                Phone number (including code - optional)
              </SuiTypography>
              {candidate && candidate?.phone ? (
                <FormField
                  number
                  type={phone.type}
                  label={phone.label}
                  name={phone.name}
                  disabled="disabled"
                  value={candidate?.phone}
                  icon={{
                    component: "add",
                    direction: "left",
                  }}
                  // event on paste taste
                  onPaste={e => {
                    e.preventDefault();
                    // remove all characters except numbers
                    const paste = e.clipboardData.getData("text/plain").replace(/\D/g, "");
                    // insert pasted text
                    document.execCommand("insertText", false, paste);
                  }}
                />
              ) :
                <FormField
                  number
                  type={phone.type}
                  label={phone.label}
                  name={phone.name}
                  value={phoneV}
                  placeholder={phone.placeholder}
                  error={phone.email && touched.phone}
                  success={phoneV.length > 0 && !errors.phone}
                  icon={{
                    component: "add",
                    direction: "left",
                  }}
                />
              }
            </Grid>

            <Grid item xs={12} mb={2}>
              <SuiBox ml={1} mb={0.5}>
                <SuiTypography variant="button" ml={1}>
                  Social Media Links
                </SuiTypography>
              </SuiBox>

              {socialMediaInputs.map(socialMediaInput => { // eslint-disable-line
                if (socialMediaInput.name === "linkedin") return (
                  <Grid item xs={12} sx={{ position: "relative" }} mb={1}>
                    <SuiInput
                      type="text"
                      name={socialMediaInput.name}
                      label="Linkedin"
                      style={{ paddingRight: "40px" }}
                      defaultValue={socialMediaInput.value}
                      placeholder={socialMediaInput.label}
                    />
                  </Grid>
                )
              })}
              
              <Grid container xs={12} md={12} spacing={1}>
                <Grid item xs={9} md={9}>
                  <SuiSelect
                    placeholder="Select social media"
                    options={socialMediaOptions}
                    name="media"
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <SuiButton color="info" fullWidth onClick={() => addMediaInput()} >Add</SuiButton>
                </Grid>
              </Grid>
            </Grid>

            {socialMediaInputs.map(socialMediaInput => { // eslint-disable-line
              if (socialMediaInput.name !== "linkedin") return (
                <Grid item xs={12} sx={{ position: "relative" }} mb={1}>
                  <SuiInput
                    type="text"
                    name={socialMediaInput.name}
                    label="Linkedin"
                    style={{ paddingRight: "40px" }}
                    defaultValue={socialMediaInput.value}
                    placeholder={socialMediaInput.label}
                  />
                  <Icon className="closeMedia" onClick={() => closeMediaInput(socialMediaInput.name, socialMediaInput.label)} >close</Icon>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox >
  );
}

// typechecking props for UserSignUp
Basic.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Basic;
