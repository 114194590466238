/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router-dom components
import { Link } from "react-router-dom"
// @mui material components
import Grid from "@mui/material/Grid"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
// Soft UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar"
import PageLayout from "examples/LayoutContainers/PageLayout"
// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes"


function RecruiterActivate() {

  return (
    <PageLayout white>
      <DefaultNavbar
        routes={pageRoutes}
        transparent
        action={{
          type: "external",
          route: "/",
          label: "home",
          color: "info",
        }}
      />
      <SuiBox my={24} height="calc(100vh - 24rem)">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={8} ml={2}>

              <SuiBox fontSize="3.5rem" lineHeight={1.2} >
                <SuiTypography variant="inherit" color="info" textGradient fontWeight="bold">Account created</SuiTypography>
              </SuiBox>
              <SuiBox mt={1} mb={2}>
                <SuiTypography variant="body1" color="text">Please wait for an admin to activate your account.</SuiTypography>
              </SuiBox>
              <SuiBox mt={4} mb={2}>
                <SuiButton
                  variant="gradient"
                  color="info"
                  component={Link}
                  to="/r/login"
                >
                  Log in
                </SuiButton>
              </SuiBox>
            </Grid>

          </Grid>
        </Grid>
      </SuiBox>
    </PageLayout>
  )
}

export default RecruiterActivate
