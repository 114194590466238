/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// import InvoiceCell from "layouts/pages/managerInvoices/components/invoiceCell";
// import ActionCell from "layouts/pages/managerInvoices/components/actionCell";

export default {
  columns: [
    { Header: "Created on", accessor: "date" },
    { Header: "Paid on", accessor: "paidOn" },
  ],

  rows: [],
};
