/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { formatDate } from 'utils/commonFunctions';

function ProfilesList({ title, apps }) {

  ReactSession.setStoreType("localStorage")
  const user = ReactSession.get("user");

  const appsStatuss = [
    {'in-review': 'In Review'},
    {'active': 'Active'},
    {'intro-requested': 'Intro Requested'},
    {'interview': 'Interview'},
    {'declined': 'Declined'},
    {'withdrew': 'Withdrawn'},
    {'hired': 'Hired'},
  ]

  function getStatusText(status) {
    // eslint-disable-next-line no-restricted-syntax
    for (const appStatus of appsStatuss) {
      if (appStatus[status]) {
        return appStatus[status];
      }
    }

    return status;
  }

  const isRecruiter = (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin")

  const formatDeclineReason = code => {
		switch (code) {
			case "0":
				return "Compensation misalignment"
			case "1":
				return "Employment duration too short"
			case "2":
				return "English skills not up to par"
			case "3":
				return "Location"
			case "4":
				return "Offer declined"
			case "5":
				return "Overqualified"
			case "6":
				return "Motivations misalignment"
			case "7":
				return "Underqualified"
			case "8":
				return "Unresponsive"
			case "9":
				return "Withdrew"
			case "10":
				return "Position closed"
			case "11":
				return "Better for another role"
			case "12":
				return "Culture Fit"
			case "13":
				return "Keep for future opportunities"
			case "14":
				return "Timing"
			default:
				return ""
		}
	}

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox display="flex" flexDirection="row" flexWrap="wrap" p={0} mb={3}>
          <TableContainer sx={{ boxShadow: "none", overflowX: "scroll" }}>
            <Table aria-label="simple table">
              <TableBody>
                {apps.map((app) => (
                  <TableRow
                    key={app.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" } }}
                  >
                    <TableCell component="th" scope="row" size="small">
                      <SuiTypography variant="body2"  color="dark" maxWidth="30rem" overflowX="scroll">
                        <a href={`/r/application/${app.appId}`} style={{ color: "#8392ab" }} target="_blank" >{app.name}</a>
                      </SuiTypography>
                    </TableCell>

                    <TableCell align="center" size="small">
                      <SuiTypography variant="body2" fontWeight="medium" color="secondary" minWidth="6rem">
                        {formatDate(app.date)}
                      </SuiTypography>
                    </TableCell>

                    <TableCell align="center" size="small">
                      <SuiTypography variant="body2" fontWeight="medium" color="secondary" minWidth="6rem">
                        {getStatusText(app.status)}
                      </SuiTypography>
                    </TableCell>

                    {isRecruiter && (
                      <TableCell align="center" size="small">
                        {app.status === "declined" && app.declinedReason && (
                          <SuiBox display="flex" flexDirection="column" minWidth="6rem">
                            <SuiTypography variant="caption" fontWeight="medium" color="secondary"> 
                              Decline reason:
                            </SuiTypography>
                            <SuiTypography variant="caption" fontWeight="medium" color="secondary">
                              {formatDeclineReason(app.declinedReason)}
                            </SuiTypography>
                          </SuiBox>
                        )}
                      </TableCell>
                    )}

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </SuiBox>
      </SuiBox>
    </Card >
  );
}

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  apps: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProfilesList;
