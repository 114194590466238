/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// formik components
import { Formik, Form } from "formik";
import validations from "layouts/applications/recruiter-application-create/formComponents/validations";
import form from "layouts/applications/recruiter-application-create/formComponents/form";
import initialValues from "layouts/applications/recruiter-application-create/formComponents/initialValues";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
// Wizard page components
import Basic from "layouts/applications/recruiter-application-create/components/Basic";
import Skills from "layouts/applications/recruiter-application-create/components/Skills";
import Questions from "layouts/applications/recruiter-application-create/components/Questions";
// import Links from "layouts/applications/recruiter-application-create/components/Links";


function RecruiterCreateApplicationWizard() {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")
  const CandidateForEdit = ReactSession.get("CandidateForEdit")

  useEffect(() => {
    document.title = `Create Application | TECLA`;
  }, []);

  const [activeStep, setActiveStep] = useState(0)
  const steps = ["Basic", "Skills", "Questions"]

  const { formId, formField } = form
  const currentValidation = validations[activeStep]

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [showQueryError, setShowQueryError] = useState(false)

  // Stores all the available jobs with full detail
  const [allJobsAvailable, setAllJobsAvailable] = useState([])
  // Stores the position dropdown options
  const [positionOptions, setPositionOptions] = useState([])

  const fetchPositionOptions = () => {
    let candidateId = 0;
    if (CandidateForEdit && CandidateForEdit.user) {
      candidateId = CandidateForEdit.user.id;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/teclaGetAllJobs/?candidate=${candidateId}`, {
      headers: { "Authorization": `Token ${token}` }
    })
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          setAllJobsAvailable(response.data)

          const positionOpts = []
          response.data.forEach(job => positionOpts.push({ value: job.id, label: `${job.companyName} - ${job.seniorityName}` }))
          setPositionOptions(positionOpts)
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => { fetchPositionOptions() }, [])

  /* State to store form inputs */
  // Basic
  const [selectedPosition, setSelectedPosition] = useState(null) // eslint-disable-line
  // Skills
  const [CVwasUploaded, setCVwasUploaded] = useState(false)
  const [selectedCV, setSelectedCV] = useState(CandidateForEdit?.cv)
  const [selectedInterviewInEnglish, setSelectedInterviewInEnglish] = useState(CandidateForEdit?.interviewInEnglish ? true : false) // eslint-disable-line
  const [selectedCanWorkRemotely, setSelectedCanWorkRemotely] = useState(CandidateForEdit?.canWorkRemotely ? true : false) // eslint-disable-line
  const [selectedAvailability, setSelectedAvailability] = useState(CandidateForEdit?.availability)
  const [selectedSalary, setSelectedSalary] = useState(CandidateForEdit?.salary)
  const [selectedSalaryToShow, setSelectedSalaryToShow] = useState(CandidateForEdit?.salaryToShow)
  // Questions
  const [selectedQuestions, setSelectedQuestions] = useState(null)

  const populateQuestions = () => {
    const selectedQuest = []
    selectedPosition.questions.forEach(question => selectedQuest.push({ id: question.id, answer: null }))
    setSelectedQuestions(selectedQuest)
  }

  useEffect(() => { if (selectedPosition) populateQuestions() }, [selectedPosition])

  // eslint-disable-next-line
  function showSuccess(appId) {
    let timerSuccessid = "";
    $(".alertSuccessBox").show();
    $('html, body').animate({ scrollTop: $(".alertSuccessBox").offset().top }, 100);

    if (timerSuccessid) clearTimeout(timerSuccessid)

    timerSuccessid = setTimeout(() => {
      setIsRedirecting(false)
      window.location.replace(`/r/application/${appId}`)
    }, 3000);
  }

  const uploadCV = async (appId) => {
    const resumeField = selectedCV.get('cv');
    const normalizedName = resumeField.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    setIsSubmitting(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/uploadCVApplication/`, {
      method: 'PATCH',
      headers: {
        "Content-Disposition": `attachment; filename=${normalizedName}`,
        "Authorization": `Token ${token}`,
        "content-type": "multipart/form-data;"
      },
      body: selectedCV
    })
      .then(res => res.json())
      .then(response => {

        if (response.success) {
          showSuccess(response.data.id)
          setIsRedirecting(true)
          setIsSubmitting(false)
        } else {
          console.error('There was an error!', response)
          setShowQueryError(true)
          setTimeout(() => setShowQueryError(false), 5000)
        }
      })
      .catch(error => {
        console.error('There was an error!', error)
        setShowQueryError(true)
        setTimeout(() => setShowQueryError(false), 5000)
      })
  }

  const teclaCreateApplication = async () => {
    setIsSubmitting(true)

    const requestBody = {
      userId: CandidateForEdit?.user?.id,
      questions: selectedQuestions,
      userCV: !CVwasUploaded,
      country: { id: CandidateForEdit?.country?.id },
      linkedin: CandidateForEdit?.linkedin,
      availability: selectedAvailability,
      interviewInEnglish: selectedInterviewInEnglish,
      salary: selectedSalary,
      salaryToShow: selectedSalaryToShow,
      email: CandidateForEdit?.user?.email,
      first_name: CandidateForEdit?.user?.first_name,
      last_name: CandidateForEdit?.user?.last_name
    }

    if (CandidateForEdit.phone) {
      requestBody.phone = CandidateForEdit.phone;
    }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/${selectedPosition?.id}/teclaApply/`, {
      method: 'POST',
      headers: {
        "Authorization": `Token ${token}`,
        "content-type": "application/json"
      },
      body: JSON.stringify(requestBody)
    })
      .then(res => res.json())
      .then(response => {

        if (response.success) {
          if (CVwasUploaded) uploadCV(response.data.id)
          else {
            showSuccess(response.data.id)
            setIsRedirecting(true)
            setIsSubmitting(false)
          }
        } else {
          console.error('There was an error!', response)
          setShowQueryError(true)
          setTimeout(() => setShowQueryError(false), 5000)
        }
      })
      .catch(error => {
        console.error('There was an error!', error)
        setShowQueryError(true)
        setTimeout(() => setShowQueryError(false), 5000)
      })
  }

  const createApplication = async formValues => teclaCreateApplication(formValues)

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <Basic
          setActiveStep={setActiveStep}
          allJobsAvailable={allJobsAvailable}
          positionOptions={positionOptions}
          selectedPosition={selectedPosition}
          setSelectedPosition={setSelectedPosition}
        />
      case 1:
        return <Skills
          formData={formData}
          setActiveStep={setActiveStep}
          setCVwasUploaded={setCVwasUploaded}
          setSelectedCV={setSelectedCV}
          selectedInterviewInEnglish={selectedInterviewInEnglish}
          setSelectedInterviewInEnglish={setSelectedInterviewInEnglish}
          selectedCanWorkRemotely={selectedCanWorkRemotely}
          setSelectedCanWorkRemotely={setSelectedCanWorkRemotely}
          selectedAvailability={selectedAvailability}
          setSelectedAvailability={setSelectedAvailability}
          selectedSalary={selectedSalary}
          setSelectedSalary={setSelectedSalary}
          selectedSalaryToShow={selectedSalaryToShow}
          setSelectedSalaryToShow={setSelectedSalaryToShow}
        />
      case 2:
        return <Questions
          formData={formData}
          setActiveStep={setActiveStep}
          selectedPosition={selectedPosition}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          createApplication={createApplication}
          isSubmitting={isSubmitting}
          isRedirecting={isRedirecting}
        />
      default:
        return <Basic setActiveStep={setActiveStep} />
    }
  }

  return (
    <RecruiterDashboardLayout>
      <SuiBox pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SuiBox mt={6} mb={1} textAlign="center">
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">Create application for {CandidateForEdit?.user?.first_name} {CandidateForEdit?.user?.last_name}</SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="regular" color="secondary">Fill the required details to create the application for this candidate</SuiTypography>
            </SuiBox>

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>


            {showQueryError && <SuiBox className="alertErrorBox">
              <SuiAlert color="error" className="alertError">There was a problem creating the application</SuiAlert>
            </SuiBox>}

            <SuiBox className="alertSuccessBox" display="none">
              <SuiAlert color="info" className="alertSuccess">Application created succesfully</SuiAlert>
            </SuiBox>

            <Card>
              <SuiBox p={2}>
                <SuiBox>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={currentValidation}
                  >
                    {({ values, errors, touched }) => (
                      <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">
                        <SuiBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                          })}

                        </SuiBox>
                      </Form>
                    )}
                  </Formik>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </RecruiterDashboardLayout>
  );
}

export default RecruiterCreateApplicationWizard;
