/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router components
import { Link } from "react-router-dom";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiSnackbar from "components/SuiSnackbar";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAvatar from "components/SuiAvatar";
import SuiInput from "components/SuiInput";

import pattern from "assets/images/shapes/pattern-lines.svg";

function EventCard({ id, image, title, dateTime, description, description2, action, banner, size }) {

  function showSuccess() {
    let timerSuccessid = "";
    $(".alertSuccessBox").show();
    if (timerSuccessid) {
      clearTimeout(timerSuccessid);
    }
    timerSuccessid = setTimeout(() => {
      $(".alertSuccessBox").hide();
    }, 2000);
  }
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  function copyToClipboard(text) {
    const sampleTextarea = document.createElement("textarea");
    document.body.appendChild(sampleTextarea);
    sampleTextarea.value = text;
    sampleTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(sampleTextarea);
    setShow(!show);
    showSuccess();
  }

  function copyTextClick(e) {
    e.preventDefault();
    const copyText = id;
    copyToClipboard(copyText);
  }

  return (
    <Card sx={{ minHeight: "100%", display: "flex", justifyContent: "space-between" }}>
      <SuiBox >
        <Grid container p={2} spacing={2}>
          <Grid item md={6}>
            {/* Contenedor */}
            <SuiBox
              height="100%"
              width="100%"
              bgColor="dark"
              flexDirection="column"
              justifyContent="center"
              position="relative"
              variant="gradient"
              borderRadius="lg"
              sx={{ overflow: "hidden", maxHeight: "200px" }}
            >
              {/* Contenedor de imagen */}
              <SuiBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
                width="inherit"
              >
                {/* Patrón */}
                <SuiBox
                  component="img"
                  src={pattern}
                  alt="pattern lines"
                  position="absolute"
                  opacity={0.4}
                />
                {/* Logo */}
                <SuiBox sx={{ maxWidth: size || 180, m: 5 }} component="img" src={banner} alt="Host banner" />
              </SuiBox>
            </SuiBox>
          </Grid>
          
          <Grid item md={6}>
            <SuiBox>
              <SuiBox display="flex" alignItems="center" >
                <SuiAvatar src={image} alt={title} size="lg" variant="rounded" sx={{ maxWidth: 45, maxHeight: 45 }} />
                <SuiBox ml={1} lineHeight={0}>
                  <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {title}
                  </SuiTypography>
                  {dateTime ? (
                    <SuiTypography
                      variant="caption"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize"
                    >
                      {dateTime}
                    </SuiTypography>
                  ) : null}
                </SuiBox>
              </SuiBox>
              <SuiBox my={2}>
                <SuiBox>
                  <SuiTypography variant="body1" color="black" fontWeight="medium">
                    {description}
                  </SuiTypography>
                </SuiBox>
                <SuiBox sx={{ maxWidth: { xs: "85%", md: "100%" } }}>
                  <SuiTypography variant="body2" color="text">
                    {description2}
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox p={2}>
        {
          id ? (
            <Grid container item spacing={2} >
              <Grid item xs={12} lg={6}>
                <SuiBox width="100%">
                  <SuiInput
                    size="small"
                    defaultValue={id}
                    disabled
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Tooltip title="Click to copy your discount code or link" placement="top">
                  <SuiButton
                    variant="outlined"
                    color="success"
                    size="small"
                    // sx={{ padding: "0.5rem 1rem" }}
                    onClick={copyTextClick}
                    fullWidth
                  >
                    copy
                  </SuiButton>
                </Tooltip>
                <SuiSnackbar
                  color="info"
                  icon="notifications"
                  title="Success!"
                  content="Copied to clipboard."
                  // dateTime="11 mins ago"
                  open={show}
                  close={toggleSnackbar}
                />
              </Grid>

              {action && (
                <>
                  {action?.type === "internal" ? (
                    <Grid item xs={12} md={6} lg={3}>
                      <SuiButton
                        component={Link}
                        to={action?.route}
                        variant="gradient"
                        color={action?.color}
                        target="_blank"
                        size="small"
                        fullWidth
                      >
                        {action?.label}
                      </SuiButton>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6} lg={3}>
                      <SuiButton
                        component="a"
                        href={action?.route}
                        variant="gradient"
                        color={action?.color}
                        size="small"
                        target="_blank"
                        fullWidth
                      >
                        {action?.label}
                      </SuiButton>
                    </Grid>
                  )}
                </>
              )}
              
            </Grid>
          ) : null
        }
      </SuiBox>
    </Card >
  );
}

// Setting default values for the props of EventCard
EventCard.defaultProps = {
  id: "",
  dateTime: "",
  description2: "",
  action: null,
  size: null
};

// Typechecking props for the EventCard
EventCard.propTypes = {
  image: PropTypes.string.isRequired,
  banner: PropTypes.string.isRequired,
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  size: PropTypes.number,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  description2: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["enternal", "internal"]),
    route: PropTypes.string,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string,
  }),
};

export default EventCard;
