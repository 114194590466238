/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import Switch from "@mui/material/Switch"
import { ReactSession } from 'react-client-session'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// import PropTypes from "prop-types"
// Setting pages components
import TableCell from "layouts/pages/account/settings/components/TableCell"

function Notifications() {
  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")
  const postToken = `Token ${token}`
  const company = ReactSession.get("company")

  function updateCandidate(e) {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/companies/companyEditNotification/`
    const targetName = e.target.name
    const targetValue = e.target.checked
    const postBody = {
      value: targetValue ? 1 : 0
    }

    const requestMetadata = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': postToken
      },
      body: JSON.stringify(postBody)
    }
    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          company[targetName] = targetValue
          ReactSession.set("company", company)
        }
      })
  }

  return (
    <Card id="notifications">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5">Email Notifications</SuiTypography>
        </SuiBox>
        <SuiTypography variant="button" color="text" fontWeight="regular">
          Choose which notifications you&apos;d like to receive.
        </SuiTypography>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        <SuiBox minWidth="auto" sx={{ overflow: "none" }}>
          <Table sx={{ minWidth: "100%" }}>
            <SuiBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>Activity</TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>Off/On</TableCell>
              </TableRow>
            </SuiBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SuiBox lineHeight={1.4}>
                    <SuiTypography display="block" variant="button" fontWeight="regular">Notify me when one of my jobs is approved</SuiTypography>
                  </SuiBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  {company.noSendJobRecommendations ? (
                    <Switch sx={{ "& .Mui-checked": { top: "50% !important" } }} name="noSendJobRecommendations" onChange={updateCandidate} defaultChecked />
                  ) : (
                    <Switch sx={{ "& .Mui-checked": { top: "50% !important" } }} name="noSendJobRecommendations" onChange={updateCandidate} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </SuiBox>
      </SuiBox>
    </Card>
  )
}

export default Notifications