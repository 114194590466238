/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";
import Swal from 'sweetalert2'
// @mui material components
import Icon from "@mui/material/Icon";
import SendIcon from '@mui/icons-material/Send';
import Tooltip from "@mui/material/Tooltip";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";


function ActionCell({ jobId, appId, status, application }) {
	const jobLink = `/job?id=${jobId}`;
	const appLink = `/application/${appId}`;

	const formatDeclineReason = code => {
		switch (code) {
			case "0":
				return "Compensation misalignment"
			case "1":
				return "Employment duration too short"
			case "2":
				return "English skills not up to par"
			case "3":
				return "Location"
			case "4":
				return "Offer declined"
			case "5":
				return "Overqualified"
			case "6":
				return "Motivations misalignment"
			case "7":
				return "Underqualified"
			case "8":
				return "Unresponsive"
			case "9":
				return "Withdrew"
			case "10":
				return "Position closed"
			case "11":
				return "Better for another role"
			case "12":
				return "Culture Fit"
			case "13":
				return "Keep for future opportunities"
			case "14":
				return "Timing"
			default:
				return ""
		}
	}

	const showDeclineReasonModal = () => {
		Swal.fire({
			icon: "info",
			html: `<h2>Decline reason</h2><br>
			${formatDeclineReason(application?.declinedReason)}<br><br>
			<h2>Decline comments</h2><br>
			${application?.declinedComment || ""}<br><br>`,
		})
	}

	return (
		<SuiBox display="flex" alignItems="center" >
			<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} component="a" mr={1} href={jobLink} target="_blank">
				<Tooltip title="View job detail" placement="top">
					<Icon>visibility</Icon>
				</Tooltip>
		    </SuiTypography>

		    <SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} component="a" mr={1} href={appLink} target="_blank">
		      	<Tooltip title="View application" placement="top">
		        	<SendIcon/>
		      	</Tooltip>
		    </SuiTypography>
			
		    {status === "declined" && (
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => showDeclineReasonModal()} >
					<Tooltip title="Decline reason" placement="top">
						<QuestionAnswerIcon/>
					</Tooltip>
				</SuiTypography>
			)}
		</SuiBox>
	);
}

ActionCell.defaultProps = {
  jobId: 0,
  appId: 0,
  status: "",
  application: {}
}

ActionCell.propTypes = {
  jobId: PropTypes.number,
  appId: PropTypes.number,
  status: PropTypes.string,
  application: PropTypes.object, // eslint-disable-line
};

export default ActionCell;
