/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// MUI components
import { Icon, Modal } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress'
// SUI components
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
// Formik
import { Formik, Form } from "formik";
// NewUser layout schemas for form and form feilds
import SignUp from "layouts/pages/users/new-user/components/CreateCompany";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "examples/Modal/CreateCompany/schemas/form";
import initialValues from "examples/Modal/CreateCompany/schemas/initialValues";

function getContent(formData) {
    return <SignUp formData={formData} />;
}

function CreateCompanyModal() {

    ReactSession.setStoreType("localStorage");
    const token = ReactSession.get("token");
    const user = ReactSession.get("user");

    const [open, setOpen] = useState(false);
    const [companyId, setCompanyId] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false)

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setCompanyId(0);
    };


    // Validation
    const [activeStep, setActiveStep] = useState(0);
    // const steps = getSteps();
    const { formId, formField } = form;
    const currentValidation = validations[activeStep];
    const isLastStep = false;
    let timerid = "";

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const submitForm = async (values, actions) => {
        await sleep(1000);

        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values, null, 2));

        // actions.setSubmitting(false);
        actions.resetForm();

        setActiveStep(0);
    };

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            submitForm(values, actions);
        } else {
            // setActiveStep(activeStep + 1);
            actions.setTouched({});
            // actions.setSubmitting(false);
        }
    };

    const redirectProfile = () => {
        if (companyId) {
            window.open(`/r/companyWizard`, '_blank');
            // window.location.replace(`/r/editCompanyWizard/${companyId}`);
        }
    };

    function getCompany(responseCompanyId) {
        const headers = { "Content-Type": "application/json" };
        const url = `${process.env.REACT_APP_API_ROUTE}/api/companies/${responseCompanyId}/teclaCompanyDetail/`;
        headers.Authorization = `Token ${token}`;

        fetch(url, { headers, })
            .then(async response => {
              const data = await response.json();
              const newCompany = { ...data.data };

              ReactSession.set("companyForRecruiterEdit", newCompany);
              setCompanyId(responseCompanyId);
              setShowSpinner(false);

            })
            .catch(error => {
              console.error('There was an error!', error);
            });
    }

    const signUpClick = async () => {
        await sleep(100);
        if ($("#new-company-form .MuiTypography-caption:visible").text().length === 0) {
            const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/teclaCreateCompany/`;

            const postBody = {
                'email': $("input[name=email]").val(),
                'first_name': $("input[name=firstName]").val(),
                'last_name': $("input[name=lastName]").val(),
                'company': $("input[name=company]").val(),
                'password': $("input[name=password]").val(),
                'password_confirmation': $("input[name=password]").val(),
            };

            const requestMetadata = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': `Token ${token}`
                },
                body: new URLSearchParams(postBody)
            };

            setShowSpinner(true);

            fetch(recipeUrl, requestMetadata)
                .then(res => res.json())
                .then(response => {
                    $(".alertErrorBox").hide();
                    if (response.success) {
                        $(".alertSuccesModalBox div div").text("Company account created");
                        $(".alertSuccesModalBox").show();

                        if (timerid) {
                            clearTimeout(timerid);
                        }
                        timerid = setTimeout(() => {
                            $(".alertSuccesModalBox").hide();
                        }, 3000);
                        getCompany(response.data.company.id);
                    } else {
                        setShowSpinner(false);
                        let errorMsg = "Error creating account";
                        if (response.data && response.data.error) {
                            errorMsg = response.data.error;
                        }
                        // alertSuccesModalBox
                        $(".alertErrorBox div div").text(errorMsg);
                        $(".alertErrorBox").show();
                        if (timerid) {
                            clearTimeout(timerid);
                        }
                        timerid = setTimeout(() => {
                            $(".alertErrorBox").hide();
                        }, 3000);
                    }
                });
        }
    }

    return (
        <>
            { user && (user.type === "tecla_admin" || user.type === "tecla_recruiter" || user.type === "tecla_manager") ? (
                <SuiButton
                    onClick={handleOpen}
                    size="medium"
                    color="info"
                    variant="outlined"
                    circular
                    sx={{ marginLeft: "0.5rem" }}
                    >
                    <Icon>
                    add
                    </Icon>
                    &nbsp;Create company
                </SuiButton>
            ) : null }
            <Modal
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <SuiBox sx={{
                    display: "flex",
                    position: 'relative',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: "60vw", md: "42rem", lg: "43rem", xl: "49rem" },
                    height: { xs: "100vh", md: "auto" },
                    maxHeight: { md: "101vh" },
                    minHeight: { md: "30rem" },
                    background: "white",
                    border: 'none',
                    boxShadow: 16,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                }}>
                    <SuiBox>
                        <SuiBox className="alertErrorBox" display="none">
                          <SuiAlert color="error" className="alertError">Please fill all the fields in this section before continuing.</SuiAlert>
                        </SuiBox>
                        <SuiBox className="alertSuccesModalBox" display="none">
                          <SuiAlert color="info" className="alertSuccesModal">Please fill all the fields in this section before continuing.</SuiAlert>
                        </SuiBox>
                        <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                            <SuiTypography id="child-modal-title" variant="overline" opacity={0.75}></SuiTypography>
                            <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                        </SuiBox>
                        <SuiBox display="flex">
                            <SuiTypography id="modal-modal-description" variant="h2" fontWeight="bold">
                                Create Company
                            </SuiTypography>
                        </SuiBox>
                    </SuiBox>

                    <SuiBox display="flex">
                        <SuiBox sx={{ width: { xs: "100%", md: "100%" } }} mr={2}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={currentValidation}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, issubmitting }) => (
                                    <Form id={formId} autoComplete="off" type="POST" actions="#">
                                        <SuiBox>
                                            {getContent({
                                                values,
                                                touched,
                                                formField,
                                                errors,
                                            })}

                                            <SuiBox mt={4}>
                                                { companyId ? (
                                                    <SuiBox display="flex" justifyContent="space-evenly" alignItems="center" flexWrap="wrap" mt={2} mb={1} className="editProfile">
                                                        <SuiButton size="large" onClick={redirectProfile} mr={2} color="warning" sx={{ color: "#000", width: { xs: "100%", sm: "15rem", md: "initial" }, height: { xs: "44px", sm: "initial" }, marginBottom: 1, backgroundColor: "#feec1e" }}>
                                                            Edit company profile
                                                        </SuiButton>
                                                    </SuiBox>
                                                ) : (
                                                    <SuiBox sx={{ textAlign: "right" }} justifyContent="space-evenly" alignItems="right" flexWrap="wrap" mt={2} mb={1}>
                                                        <SuiButton size="large" color="info" onClick={handleClose} variant="gradient" circular sx={{ marginRight: "10px" }}>
                                                            Cancel
                                                        </SuiButton>
                                                        {showSpinner ? (
                                                          <SuiButton sx={{ background: "#fff !important", boxShadow: "none !important" }} size="large" color="white" variant="gradient" circular>
                                                            <CircularProgress />
                                                          </SuiButton>)
                                                        : (
                                                            <SuiButton size="large" type="submit" color="info" issubmitting={issubmitting} variant="gradient" onClick={signUpClick} circular>
                                                                Create
                                                            </SuiButton>
                                                        ) }
                                                    </SuiBox>
                                                ) }
                                            </SuiBox>

                                        </SuiBox>
                                    </Form>
                                )}
                            </Formik>
                        </SuiBox>
                    </SuiBox>
                </SuiBox>
            </Modal>
        </>
    );

}

export default CreateCompanyModal;
