/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
import SourcedCell from "layouts/pages/searchRecruiter/components/SourcedCell/sourcedCell";
import HiredMarkCell from "layouts/pages/searchRecruiter/components/HiredMarkCell/HiredMarkCell";

export default {
  columns: [
    { 
      Header: "Hired",
      accessor: "hired",
      disableSortBy: true,
      Cell: ({ value: [candidateId, isHired, candidateName] }) => <HiredMarkCell candidateId={candidateId} isHired={isHired} candidateName={candidateName} />,
    },
    { Header: "Candidate", accessor: "candidate" },
    { Header: "Country", accessor: "country" },
    { Header: "Skills", accessor: "skills", disableSortBy: true },
    { Header: "Action", accessor: "action", disableSortBy: true },
    {
      Header: "Sourced",
      accessor: "sourced",
      Cell: ({ value: [isSourced, sourcingsCount, candidateId, candidateName] }) => <SourcedCell companyAvatar isSourced={isSourced} sourcingsCount={sourcingsCount} candidateId={candidateId} candidateName={candidateName} />,
      disableSortBy: true,
      // Sort by most ammount of sourcings
      sortMethod: (a, b) => {
        const [sourcingsCountA] = a.value
        const [sourcingsCountB] = b.value

        if (sourcingsCountA > sourcingsCountB) return -1
        if (sourcingsCountA < sourcingsCountB) return 1
        return 0
      }
    },
  ],

  rows: [],
};
