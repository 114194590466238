/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import SuiBox from "components/SuiBox";
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
/* eslint-disable react/prop-types */

export default {
  columns: [
    { Header: "Candidate", accessor: "candidate" },
    { Header: "Country", accessor: "country" },
    { Header: "Skills", accessor: "skills", disableSortBy: true },
    { 
      Header: "Rate the Suggestion",
      accessor: "rate",
      disableSortBy: true,
      Cell: ({ value: data }) => (
        <SuiBox data-cid={data.candidateId} className={`rateIconsBox ${data.rated} rateBox${data.candidateId}`}>
          <SuiBox className="upRate" onClick={() => data.setRated(data.candidateId, true)}>
            <ThumbUpAltIcon style={{ width: "30px", height: "30px", marginRight: "5px" }}/>        
          </SuiBox>
          <SuiBox className="downRate" onClick={() => data.setRated(data.candidateId, false)}>
            <ThumbDownAltIcon style={{ width: "30px", height: "30px" }}/>
          </SuiBox>
        </SuiBox>
      ),
    },
    { Header: "Action", accessor: "action", disableSortBy: true },
    { Header: "Sourced", accessor: "sourced", disableSortBy: true },
  ],

  rows: [],
};
