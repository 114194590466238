/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React icons
import Cube from "examples/Icons/Cube";

function Sidenav({ skills, categories, skillFilterText, skillArrayFilters }) {

  /* Mui hook to read screen size - We use it for small screen rendering */
  const isSmallScreen = useMediaQuery('(max-width: 1201px)')

  let timerid = "";

  const sidenavItems = [
    { icon: <Cube />, href: "profile" },
  ];

  function selectReloadClick() {
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      $(".loadFilters").click();
    }, 100);
  }

  function skillChecked(skillId) {
    // eslint-disable-next-line
    for (const skillArrayFilter of skillArrayFilters) {
      if (String(skillArrayFilter.id) === String(skillId)) {
        return true;
      }
    }

    return false;
  }

  const renderSidenavItems = sidenavItems.map(({ href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <SuiBox p={2} key={itemKey} component="li" pt={key === 0 ? 0 : 1} bgColor="transparent">
        <Accordion TransitionProps={{ unmountOnExit: false }} defaultExpanded sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            href={href}
          >
            <SuiTypography>Skills</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="skillsSelected" style={{ fontSize: "12px", "background": "#f6f6f6", "marginTop": "-20px", "color": "#868686", "padding": "10px" }}>{skillFilterText}</div>
            <SuiBox mt={1}>
              <SuiInput
                type="text"
                placeholder="Search..."
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                className="searchInput"
                name="searchInput"
              >
                Search
              </SuiInput>
            </SuiBox>
            <SuiBox mt={1} className="skillBox" style={{ "maxHeight": "165px", "overflowY": "auto" }}>
              {skills.map(skill => (
                <SuiBox>
                  <Checkbox data-id={skill.id} className="selectSkill" defaultChecked={skillChecked(skill.id)} />
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ userSelect: "none" }}>
                    {skill.name}
                  </SuiTypography>
                </SuiBox>
              ))}
            </SuiBox>
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <SuiTypography>Category</SuiTypography>
          </AccordionSummary>

          <AccordionDetails>
            <SuiBox mt={-2}>
              {categories.map(category => (
                <SuiBox>
                  <Checkbox data-id={category.id} className="selectCategory" />
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ userSelect: "none" }}>
                    {category.name}
                  </SuiTypography>
                </SuiBox>
              ))}
            </SuiBox>
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <SuiTypography>Sort By</SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SuiBox mb={2}>
              <SuiTypography variant="button" ml={1} className="loadFilters">
                Salary:
              </SuiTypography>
              <SuiSelect
                placeholder="Descending"
                name="salarySelect"
                onChange={selectReloadClick}
                smallFont={isSmallScreen}
                options={[
                  { value: "-1", label: "Descending" },
                  { value: "1", label: "Ascending" },]}
              />
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="button" ml={1}>
                Date:
              </SuiTypography>
              <SuiSelect
                placeholder="Most Recent First"
                name="dateSelect"
                onChange={selectReloadClick}
                smallFont={isSmallScreen}
                options={[
                  { value: "-1", label: "Most Recent First" },
                  { value: "1", label: "Oldest First" },]}
              />
            </SuiBox>
          </AccordionDetails>
        </Accordion>
      </SuiBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
        marginBottom: "1rem",
      }}
    >
      <SuiBox
        bgColor="transparent"
        component="ul"
        display="flex"
        flexDirection="column"
        p={0}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </SuiBox>
    </Card>
  );
}

// typechecking props
Sidenav.propTypes = {
  skills: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]).isRequired,
  categories: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]).isRequired,
  skillFilterText: PropTypes.string.isRequired,
  skillArrayFilters: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]).isRequired,
};

export default Sidenav;
