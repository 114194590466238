/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import Withdraw from "layouts/pages/profile/applications-list/components/Withdraw";
import Remove from "layouts/pages/profile/applications-list/components/Remove";

function ActionCell({ jobId, status, removeApplication, withdrawApplication }) {

	return (
		<SuiBox display="flex" alignItems="center" >			
			{status !== "withdrew" && status !== "declined" && status !== "hired" && (
				<Withdraw jobId={jobId} withdrawApplication={withdrawApplication} />
			)}

			{status === "withdrew" && (
				<Remove jobId={jobId} removeApplication={removeApplication} />
			)}
		</SuiBox>
	);
}

ActionCell.defaultProps = {
  jobId: 0,
  status: "",
  removeApplication: null,
  withdrawApplication: null,
}

ActionCell.propTypes = {
  jobId: PropTypes.number,
  status: PropTypes.string,
  removeApplication: PropTypes.func,
  withdrawApplication: PropTypes.func,
};

export default ActionCell;
