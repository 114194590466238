/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// react-router-dom components
import { Link } from "react-router-dom";
// formik components
import { Formik, Form } from "formik";
// @mui material components
import Switch from "@mui/material/Switch";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// NewUser layout schemas for form and form feilds
import SignIn from "layouts/pages/users/new-user/components/SignInCompany";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
// Authentication layout components
import IllustrationLayoutCandidate from "layouts/authentication/components/IllustrationLayoutCandidate";
// Image
import companyAndRecruitersLogin from "assets/images/corp-bg.webp";
// import companyAndRecruitersLoginAnim from  "assets/images/companies_cover_anim.webm";


function getContent(formData) {
  return <SignIn formData={formData} />;
}

function Illustration() {

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const searchParams = new URLSearchParams(window.location.search);
  const jobId = searchParams.get('jobId');

  // Validation
  const [activeStep, setActiveStep] = useState(0);
  // const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = false;
  // let timerid = "";
  ReactSession.setStoreType("localStorage");
  ReactSession.set("lastCompanyLogin", true);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(values, null, 2));

    // actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      // setActiveStep(activeStep + 1);
      actions.setTouched({});
      // actions.setSubmitting(false);
    }
  };

  function getNotificationCount(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/users/getNotificationsCount/`, { headers, })
      .then(async response => {
        const data = await response.json();
        // console.log(data.data.countries)
        if (data.success) {
          ReactSession.set("notificationsCount", data.data);
        }
        window.location.replace("/r/dashboard");
      })
      .catch(error => {
        console.error('There was an error!', error);
        window.location.replace("/r/dashboard");
      });
  }

  const getInvoicesNotificationsCount = async (token) => {
    if (token) {
      const headers = { "Content-Type": "application/json" };
      headers.Authorization = `Token ${token}`;
      fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/getInvoicesNotification/`, { headers, })
        .then(async response => {
          const data = await response.json();
          if (data.success) ReactSession.set("invoicesNotificationsCount", data.data);
        })
        .catch(error => console.error('There was an error!', error));
    }
  }

  function getRecruiter(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiter/`, { headers, })
      .then(async response => {
        const data = await response.json();
        // console.log(data.data.countries)
        if (data.success) {
          ReactSession.set("candidate", null);
          ReactSession.set("company", null);
          ReactSession.set("recruiter", data.data);
          getNotificationCount(token);
          getInvoicesNotificationsCount(token)
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function getCompany(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/company/`, { headers, })
      .then(async response => {
        const data = await response.json();
        // console.log(data.data.countries)

        ReactSession.set("company", data.data);
        ReactSession.set("candidate", null);
        ReactSession.set("recruiter", null);
        if (jobId) {
          window.location.replace(`/job/apply?id=${jobId}`);
        } else {
          window.location.replace("/c/dashboard");
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function getCandidate(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/candidate/`, { headers, })
      .then(async response => {
        const data = await response.json();
        // console.log(data.data.countries)

        ReactSession.set("candidate", data.data);
        ReactSession.set("company", null);
        ReactSession.set("recruiter", null);
        if (jobId) {
          window.location.replace(`/job/apply?id=${jobId}`);
        } else if (!data.data.cv) {
          window.location.replace("/t/wizard");
        } else {
          window.location.replace("/t/dashboard");
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  const signInClick = async () => {
    await sleep(100);
    if ($(".MuiTypography-caption:visible").text().length === 0) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/login/`;
      const postBody = {
        'email': $("input[name=email]").val(),
        'password': $("input[name=password]").val()
      };

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: new URLSearchParams(postBody)
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          $(".suiAlertMsg").remove();
          if (response.success) {
            ReactSession.set("user", response.data.user);
            ReactSession.set("token", response.data.access_token);
            // window.location.replace("/t/dashboard");
            if (response.data && response.data.user && response.data.user.type === "candidate") {
              getCandidate(response.data.access_token);
            } else if (response.data && response.data.user && (response.data.user.type === "company_owner" || response.data.user.type === "recruiter" || response.data.user.type === "limited_recruiter")) {
              getCompany(response.data.access_token);
            } else {
              getRecruiter(response.data.access_token);
            }
          } else {
            $(".suiAlertMsg").remove();
            let erroMsg = response.data.error;
            if (erroMsg.includes("matching query does not")) {
              erroMsg = "Invalid email address or password";
            }

            const errorAlert = $($('.alertSuccessBox .alertError').text(erroMsg).parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(errorAlert);

            if (response.data.id) {
              let timerid = "";
              if (timerid) {
                clearTimeout(timerid);
              }
              timerid = setTimeout(() => {
                window.location.replace(`/activate?id=${response.data.id}`);
              }, 3000);
            }
          }
        });
    }
  }

  return (
    <IllustrationLayoutCandidate
      color="primary"
      illustration={{
        image: companyAndRecruitersLogin,
        // video: companyAndRecruitersLoginAnim,
        description: "If you are interested in creating an account, please contact our sales team.",
        linkText: "GET IN TOUCH >",
        linkUrl:"https://www.tecla.io/onboarding",
      }}
    >
      <SuiBox className="suiAlertBox">
        <SuiBox display="flex" alignItems="end">
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, issubmitting }) => (
              <Form id={formId} autoComplete="off" type="POST" actions="#">
                <SuiBox>
                  {getContent({
                    values,
                    touched,
                    formField,
                    errors,
                  })}
                  <SuiBox my={2}>
                    <SuiButton type="submit" variant="contained" color="warning" size="large" issubmitting={issubmitting} onClick={signInClick} fullWidth sx={{ backgroundColor: "#feec1e" }}>
                      <SuiTypography fontWeight="bold">
                        Sign In
                      </SuiTypography>
                    </SuiButton>
                  </SuiBox>
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" letterSpacing="0.2px">
                    <SuiBox>
                      <Switch checked={rememberMe} onChange={handleSetRememberMe} size="small" />
                      <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={handleSetRememberMe}
                        sx={{ cursor: "pointer", userSelect: "none", fontSize: "0.85rem", marginLeft: "0.5rem", marginRight: "0.5rem" }}
                      >
                        Remember me
                      </SuiTypography>
                    </SuiBox>
                    {/* separador de bajo costo */}
                    <SuiBox>
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        // color="info"
                        sx={{ color: "#097df7", fontSize: "0.85rem", textAlign: "right" }}
                        component={Link}
                        to="/reset-password"
                      >
                        Lost password?
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>

                  <SuiBox opacity={0.25} my={3}>
                    <hr />
                  </SuiBox>


                  {/* <SuiBox mt={2} mb={1}>
                    <SuiButton variant="outlined" color="primary" size="large" fullWidth>
                      Sign in Using LinkedIn
                    </SuiButton>
                  </SuiBox> */}

                  {/* <SuiBox mt={3} textAlign="center">
                    <SuiTypography variant="button" color="text" fontWeight="regular" >
                      Don&apos;t have an account? Join TECLA as a&nbsp;
                      <SuiTypography
                        component={Link}
                        to="/c/signup"
                        variant="button"
                        // color="info"
                        fontWeight="bold"
                        sx={{ color: "#097df7" }}
                      >
                        Company
                      </SuiTypography>
                      {/* &nbsp;or&nbsp;
                      <SuiTypography
                        component={Link}
                        to="/c/signup"
                        variant="button"
                        color="dark"
                        fontWeight="bold"
                      >
                        Company
                      </SuiTypography> 
                  </SuiBox> */}
                </SuiBox>
              </Form>
            )}
          </Formik>
        </SuiBox>
        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertError" color="error">EMAIL IS ALREADY IN USE</SuiAlert>
        </SuiBox>
      </SuiBox>
    </IllustrationLayoutCandidate >
  );
}

export default Illustration;
