/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
// import SuiDatePicker from "components/SuiDatePicker";
// import SuiEditor from "components/SuiEditor";
// import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

function NewProject() {
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  // const [editorValue, setEditorValue] = useState(
  // "<p>Hello World!</p><p>Some initial <strong>bold</strong> text</p><br><br>"
  // );

  // const handleSetStartDate = (newDate) => setStartDate(newDate);
  // const handleSetEndDate = (newDate) => setEndDate(newDate);

  function uploadResume() {
    ReactSession.setStoreType("localStorage");
    const user = ReactSession.get("user");
    const token = ReactSession.get("token");
    const candidate = { ...ReactSession.get("candidate") };

    if (user) {
      const resumeField = $("input[name=cv]").prop('files')[0];
      const formPostData = new FormData();
      // eslint-disable-next-line no-useless-concat
      const postToken = `Token ${token}`;

      formPostData.append('cv', resumeField);

      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/uploadCVCandidate/`;
      const normalizedName = "cv.pdf"
      // const postBody = {
      //   'file': $("input[name=password]").val(),
      // };

      const requestMetadata = {
        method: 'PATCH',
        headers: {
          "Content-Disposition": `attachment; filename=${normalizedName}`,
          "Authorization": postToken,
          "content-type": "multipart/form-data;"
        },
        body: formPostData
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success && response.data && response.data.cv) {
            candidate.cv = response.data.cv;
            ReactSession.set("candidate", candidate);
          }
        });
    }
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <SuiBox mt={3} mb={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <Card sx={{ overflow: "visible" }}>
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Submit your application
                </SuiTypography>
                {/*  */}
                {/*  */}
                <Divider />
                <SuiBox mb={2}>
                  <SuiTypography variant="h6" fontWeight="medium">
                    Personal information
                  </SuiTypography>
                  <SuiTypography variant="button" fontWeight="regular" color="text">
                    dummy text yeah okey
                  </SuiTypography>
                </SuiBox>
                {/*  */}
                {/*  */}
                {/* START -- DROP CV FILES */}
                <SuiBox mb={2}>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Resume/CV:
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={uploadResume} />
                  </SuiBox>
                </SuiBox>
                {/* END -- DROP CV FILES */}
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      First name
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput placeholder="First name" />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Last Name
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput placeholder="Last name" />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Email address
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput placeholder="Email address" />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Location
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    // defaultValue={[
                    //   { value: "choice 1", label: "Choice 1" },
                    //   { value: "label two", label: "label two" },
                    // ]}
                    options={[
                      { value: "choice 1", label: "Choice 1" },
                      { value: "choice 2", label: "Choice 2" },
                      { value: "choice 3", label: "Choice 3" },
                      { value: "choice 4", label: "Choice 4" },
                      { value: "label one", label: "Label One", isDisabled: true },
                      { value: "label two", label: "Tabel Two" },
                      { value: "label three", label: "Label Three" },
                    ]}
                  // isMulti
                  />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={4}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Phone number
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    placeholder="Phone number"
                    // event on paste taste
                    onPaste={e => {
                      e.preventDefault();
                      // remove all characters except numbers
                      const paste = e.clipboardData.getData("text/plain").replace(/\D/g, "");
                      // insert pasted text
                      document.execCommand("insertText", false, paste);
                    }}  
                  />
                </SuiBox>
                {/*  */}
                {/*  */}
                <Divider />
                <SuiBox mb={2}>
                  <SuiTypography variant="h6" fontWeight="medium">
                    Other info
                  </SuiTypography>
                  <SuiTypography variant="button" fontWeight="regular" color="text">
                    dummy text yeah okey
                  </SuiTypography>
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Are you able to hold an interview in English?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    // defaultValue={[
                    //   { value: "choice 1", label: "Choice 1" },
                    //   { value: "label two", label: "label two" },
                    // ]}
                    options={[
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                    ]}
                  // isMulti
                  />
                </SuiBox>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      On a scale of 1-10: what is your level of spoken English?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    // defaultValue={[
                    //   { value: "choice 1", label: "Choice 1" },
                    //   { value: "label two", label: "label two" },
                    // ]}
                    options={[
                      { value: "1", label: "1 - 4 (Not able to speak English at all)" },
                      { value: "2", label: "5 (Currently learning; not able to speak yet)" },
                      { value: "3", label: "6 (I can speak but with lots of mistakes)" },
                      { value: "4", label: "7 (I can hold a conversation with a few mistakes)" },
                      { value: "5", label: "8 (I can hold a conversation easily; minor mistakes)" },
                      { value: "6", label: "9 (Excellent English; no issues at all)" },
                      { value: "7", label: "10 (English is like my first language)" },
                    ]}
                  // isMulti
                  />
                </SuiBox>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      When are you available to start a new position?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    // defaultValue={[
                    //   { value: "choice 1", label: "Choice 1" },
                    //   { value: "label two", label: "label two" },
                    // ]}
                    options={[
                      { value: "1", label: "1 Week" },
                      { value: "2", label: "2 Weeks" },
                      { value: "3", label: "3 Weeks" },
                      { value: "4", label: "4 Weeks" },
                    ]}
                  // isMulti
                  />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={4}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      What are your monthly salary expectations in USD?
                    </SuiTypography>
                  </SuiBox>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <SuiInput placeholder="Min." />
                    </Grid>
                    <Grid item xs={6}>
                      <SuiInput placeholder="Max. " />
                    </Grid>
                  </Grid>
                </SuiBox>
                {/*  */}
                {/*  */}
                <Divider />
                <SuiBox mb={2}>
                  <SuiTypography variant="h6" fontWeight="medium">
                    Aditional info
                  </SuiTypography>
                  <SuiTypography variant="button" fontWeight="regular" color="text">
                    dummy text yeah okey
                  </SuiTypography>
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      How many years of your career have you worked remotely?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    // defaultValue={[
                    //   { value: "choice 1", label: "Choice 1" },
                    //   { value: "label two", label: "label two" },
                    // ]}
                    options={[
                      { value: "1", label: "I've never worked remotely" },
                      { value: "2", label: "Less than 1 year" },
                      { value: "3", label: "More than 1 year" },
                      { value: "4", label: "More than 3 years" },
                      { value: "5", label: "More than 5 years" },
                    ]}
                  // isMulti
                  />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={4}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      How many years have you worked professionally in software?
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput placeholder="Years working in IT" />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Are you currently participating in any other interview process?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    // defaultValue={[
                    //   { value: "choice 1", label: "Choice 1" },
                    //   { value: "label two", label: "label two" },
                    // ]}
                    options={[
                      { value: "choice 1", label: "Yes" },
                      { value: "choice 2", label: "No" },
                    ]}
                  // isMulti
                  />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Are you in the final stages of any other interview process?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    // defaultValue={[
                    //   { value: "choice 1", label: "Choice 1" },
                    //   { value: "label two", label: "label two" },
                    // ]}
                    options={[
                      { value: "choice 1", label: "Yes" },
                      { value: "choice 2", label: "No" },
                    ]}
                  // isMulti
                  />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      How did you hear about this opportunity?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    // defaultValue={[
                    //   { value: "choice 1", label: "Choice 1" },
                    //   { value: "label two", label: "label two" },
                    // ]}
                    options={[
                      { value: "choice 1", label: "Social Media" },
                      { value: "choice 2", label: "Friends recommendation" },
                      { value: "choice 2", label: "third wheel" },
                    ]}
                  // isMulti
                  />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      This is a fully remote opportunity. Will you be able to work with your own equipment?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    // defaultValue={[
                    //   { value: "choice 1", label: "Choice 1" },
                    //   { value: "label two", label: "label two" },
                    // ]}
                    options={[
                      { value: "choice 1", label: "Yes" },
                      { value: "choice 2", label: "No" },
                      { value: "choice 3", label: "Maybe" },
                    ]}
                  // isMulti
                  />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Do you currently work for a U.S company?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    // defaultValue={[
                    //   { value: "choice 1", label: "Choice 1" },
                    //   { value: "label two", label: "label two" },
                    // ]}
                    options={[
                      { value: "choice 1", label: "Yes" },
                      { value: "choice 2", label: "No" },
                      { value: "choice 3", label: "Maybe" },
                    ]}
                  // isMulti
                  />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Have you worked for a U.S company in the past?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    // defaultValue={[
                    //   { value: "choice 1", label: "Choice 1" },
                    //   { value: "label two", label: "label two" },
                    // ]}
                    options={[
                      { value: "choice 1", label: "Yes" },
                      { value: "choice 2", label: "No" },
                      { value: "choice 3", label: "Maybe" },
                    ]}
                  // isMulti
                  />
                </SuiBox>
                {/*  */}
                {/*  */}
                <Divider />
                <SuiBox mb={2}>
                  <SuiTypography variant="h6" fontWeight="medium">
                    Social media
                  </SuiTypography>
                  <SuiTypography variant="button" fontWeight="regular" color="text">
                    dummy text yeah okey
                  </SuiTypography>
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      LinkedIn profile URL
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput placeholder="https://www..." />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Github Profile URL
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput placeholder="https://www..." />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Portfolio URL
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput placeholder="https://www..." />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Website URL
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput placeholder="https://www..." />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                  mb={2}
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Twitter URL
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput placeholder="https://www..." />
                </SuiBox>
                {/*  */}
                {/*  */}
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton color="light">cancel</SuiButton>
                  </SuiBox>
                  <SuiButton variant="gradient" color="info">
                    Send application
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default NewProject;
