/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types"
import { ReactSession } from 'react-client-session'
// @mui material components
import Card from "@mui/material/Card"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography"

function CompanyProfileInfoCard({ links }) {

  ReactSession.setStoreType("localStorage")
  const company = ReactSession.get("company")
  const companyForRecruiterEdit = ReactSession.get("companyForRecruiterEdit")

  const { size } = typography

  // Render the card external link icons
  const renderLinks = links.map(item => (
    <SuiBox
      key={item.color}
      component="a"
      href={item.link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.xl}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {item.icon}
    </SuiBox>
  ))

  return (
    <Card sx={{ height: "auto" }}>
      <SuiBox p={2}>
        <SuiBox>

          <SuiBox display="flex" flexDirection="column" py={1} pr={2}>

            {company?.country ? ( /* eslint-disable-line */
              <SuiBox display="flex" py={1} pr={2}>
                <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">Location: &nbsp;</SuiTypography>
                <SuiTypography variant="button" fontWeight="regular">{company.country}</SuiTypography>
              </SuiBox>
            )
            : companyForRecruiterEdit?.country ? (
              <SuiBox display="flex" py={1} pr={2}>
                <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">Location: &nbsp;</SuiTypography>
                <SuiTypography variant="button" fontWeight="regular">{companyForRecruiterEdit.country}</SuiTypography>
              </SuiBox>
            )
            :
              null
            }

            <SuiBox display="flex" py={1} pr={2}>
              <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">Links: &nbsp;</SuiTypography>
              {renderLinks}
            </SuiBox>

          </SuiBox>

        </SuiBox>
      </SuiBox>
    </Card>
  )
}

// Typechecking props for the CompanyProfileInfoCard
CompanyProfileInfoCard.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
}

CompanyProfileInfoCard.defaultProps = {
  links: []
}

export default CompanyProfileInfoCard
