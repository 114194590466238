/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types"
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import $ from 'jquery';
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";

// eslint-disable-next-line
function Skills({ formData,
                  setActiveStep,
                  setCVwasUploaded,
                  setSelectedCV,
                  selectedInterviewInEnglish,
                  setSelectedInterviewInEnglish,
                  selectedCanWorkRemotely,
                  setSelectedCanWorkRemotely,
                  selectedAvailability,
                  setSelectedAvailability,
                  selectedSalary,
                  setSelectedSalary,
                  selectedSalaryToShow,
                  setSelectedSalaryToShow,
                 }) {
  
  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("user");
  const CandidateForEdit = ReactSession.get("CandidateForEdit")

  const [showEmptyFieldsMsg, setShowEmptyFieldsMsg] = useState(false)
  const [showInvalidSalary, setShowInvalidSalary] = useState(false)
  const [showInvalidCvMsg, setShowInvalidCvMsg] = useState(false)
  
  let loadPdf = true;

  function resetLoad() {
    let resumeTimerid = "";
    if (resumeTimerid) {
      clearTimeout(resumeTimerid);
    }
    resumeTimerid = setTimeout(() => {
      loadPdf = true;
    }, 500);
  }

  useEffect(() => {
    $("input[name=cv]").attr("accept", ".pdf");
    if (loadPdf && CandidateForEdit && CandidateForEdit.cv) {
      loadPdf = false;
      fetch(`${process.env.REACT_APP_API_ROUTE}${CandidateForEdit.cv}`)
        .then((response) => response.blob())
        .then((blob) => {        
          const file = new File([blob], "resume.pdf");
          const container = new DataTransfer();
          container.items.add(file);
          $("input[name=cv]")[0].files = container.files;
          resetLoad();
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, []);


  function uploadResume() {
    if (user) {
      const resumeField = $("input[name=cv]").prop('files')[0];
      let validCv = true;
      if (resumeField && resumeField.name) {
        const extension = resumeField.name.substring(resumeField.name.length - 4, resumeField.name.length).toLowerCase();
        if (!extension.includes("pdf")) {
          validCv = false;
        }
      }
      if (validCv) {
        const formPostData = new FormData();
        formPostData.append('cv', resumeField);
        setSelectedCV(formPostData)
        setCVwasUploaded(true)
      } else {
        setShowInvalidCvMsg(true);
        $("input[name=cv]").val("");
        setTimeout(() => setShowInvalidCvMsg(false), 3000)
      }
    }

  }

  const interviewInEnglishArray = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
  ];
  const englishLevelArray = [
    { value: "1", label: "1 (Not able to speak English at all)" },
    { value: "2", label: "2 (Not able to speak English at all)" },
    { value: "3", label: "3 (Not able to speak English at all)" },
    { value: "4", label: "4 (Not able to speak English at all)" },
    { value: "5", label: "5 (Currently learning; not able to speak yet)" },
    { value: "6", label: "6 (I can speak but with lots of mistakes)" },
    { value: "7", label: "7 (I can hold a conversation with a few mistakes)" },
    { value: "8", label: "8 (I can hold a conversation easily; minor mistakes)" },
    { value: "9", label: "9 (Excellent English; no issues at all)" },
    { value: "10", label: "10 (English is like my first language)" },
  ];
  const availabilityArray = [
    { value: "1-week", label: "1 Week" },
    { value: "2-weeks", label: "2 Weeks" },
    { value: "3-weeks", label: "3 Weeks" },
    { value: "4-weeks", label: "4 Weeks" },
  ];

  const verifySkillInputs = () => {
    const numberSelectedSalary = Number(selectedSalary)
    const numberSelectedSalaryToShow = Number(selectedSalaryToShow)
    if ((selectedInterviewInEnglish === null || selectedInterviewInEnglish === undefined) ||
        (selectedCanWorkRemotely === null || selectedCanWorkRemotely === undefined) ||
        !selectedAvailability || !selectedSalary || !selectedSalaryToShow || !$("input[name=cv]").val().length) {
      setShowEmptyFieldsMsg(true)
      setTimeout(() => setShowEmptyFieldsMsg(false), 3000)
    } else if (numberSelectedSalary <= 0 || numberSelectedSalaryToShow <= 0) {
      setShowInvalidSalary(true)
      setTimeout(() => setShowInvalidSalary(false), 3000)
    }
    else setActiveStep(prevState => prevState+1)
  }

  return (
    <SuiBox>
      <SuiBox width="100%" textAlign="center" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">Submit more details about your skills.</SuiTypography>
      </SuiBox>

      {showEmptyFieldsMsg && <SuiAlert color="secondary" className="alertError">Please fill all the required fields in this section before continuing.</SuiAlert>}
      {showInvalidSalary && <SuiAlert color="secondary" className="alertError">Expected salary can&apos;t be 0.</SuiAlert>}
      {showInvalidCvMsg && <SuiAlert color="secondary" className="alertError">Only PDF files are allowed for CVs.</SuiAlert>}

      <SuiBox mt={2} mb={4}>
        <SuiBox ml={1}>
          <SuiTypography variant="button">Resume / CV</SuiTypography>
        </SuiBox>
        <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={uploadResume} />
        <SuiTypography variant="body2" fontWeight="regular" color="text" ml={1}>
          By default your profile CV will be used. If you want to use a different one for this application upload it here in PDF format.
        </SuiTypography>
      </SuiBox>

      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={4}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">Are you able to hold an interview in English?</SuiTypography>
        </SuiBox>
        <SuiSelect
          name="interviewInEnglish"
          options={interviewInEnglishArray}
          defaultValue={selectedInterviewInEnglish ? { value: true, label: "Yes" } : { value: true, label: "No" }}
          onChange={e => setSelectedInterviewInEnglish(e.value)}
        />
      </SuiBox>

      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={4}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button"> On a scale of 1-10: what is your level of spoken English?</SuiTypography>
        </SuiBox>
        <SuiInput
          type="text"
          name="englishLevel"
          disabled
          defaultValue={CandidateForEdit?.englishLevel ?
            englishLevelArray.filter(item => item.value === CandidateForEdit?.englishLevel.toString())[0].label
            :
            null
          }
        />
      </SuiBox>

      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={4}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">
            Will you be able to work with your own equipment?
          </SuiTypography>
        </SuiBox>
        <SuiSelect
          name="canWorkRemotely"
          options={interviewInEnglishArray}
          defaultValue={selectedCanWorkRemotely ? { value: true, label: "Yes" } : { value: false, label: "No" }}
          onChange={e => setSelectedCanWorkRemotely(e.value)}
        />
      </SuiBox>

      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={5}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">When are you available to start a new position? (required)</SuiTypography>
        </SuiBox>
        <SuiSelect
          name="availability"
          options={availabilityArray}
          defaultValue={selectedAvailability ?
            {
              value: availabilityArray.filter(item => item.value === selectedAvailability)[0].value,
              label: availabilityArray.filter(item => item.value === selectedAvailability)[0].label
            }
            :
            null
          }
          onChange={e => setSelectedAvailability(e.value)}          
        />
      </SuiBox>
      
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={5}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography variant="button">Expected salary (required)</SuiTypography>
            </SuiBox>
            <SuiInput
              type="number"
              placeholder="Expected
              salary"
              name="salary"
              defaultValue={selectedSalary || CandidateForEdit?.salary}
              onChange={e => setSelectedSalary(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography variant="button">Salary shown to client (required)</SuiTypography>
            </SuiBox>
            <SuiInput
              type="number"
              placeholder="Salary shown to client"
              name="salaryToShow"
              defaultValue={selectedSalaryToShow || CandidateForEdit?.salaryToShow}
              onChange={e => setSelectedSalaryToShow(e.target.value)}
            />
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" onClick={() => setActiveStep(prevState => prevState-1)} isSubmitting className="changeStep">Back</SuiButton>
        <SuiButton variant="gradient" color="info" onClick={() => verifySkillInputs()} className="changeStep nextStep" isSubmitting>Next</SuiButton>
      </SuiBox>

    </SuiBox>
  );
}

// typechecking props for Skills
Skills.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setCVwasUploaded: PropTypes.func.isRequired,
  setSelectedCV: PropTypes.func.isRequired,
  selectedInterviewInEnglish: PropTypes.bool.isRequired,
  setSelectedInterviewInEnglish: PropTypes.func.isRequired,
  selectedCanWorkRemotely: PropTypes.bool.isRequired,
  setSelectedCanWorkRemotely: PropTypes.func.isRequired,
  selectedAvailability: PropTypes.string.isRequired,
  setSelectedAvailability: PropTypes.func.isRequired,
  selectedSalary: PropTypes.number.isRequired,
  setSelectedSalary: PropTypes.func.isRequired,
  selectedSalaryToShow: PropTypes.number.isRequired,
  setSelectedSalaryToShow: PropTypes.func.isRequired,
};

export default Skills;