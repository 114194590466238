/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from 'prop-types'
import { ReactSession } from 'react-client-session'
import { useEffect, useState } from 'react'
// @mui material components
import Modal from '@mui/material/Modal'
import useMediaQuery from '@mui/material/useMediaQuery'
import CircularProgress from '@mui/material/CircularProgress'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import SuiAlert from 'components/SuiAlert'
import SuiTypography from 'components/SuiTypography'

function UpgradePlanModal({ openUpgradePlanModal, setOpenUpgradePlanModal }) {

    ReactSession.setStoreType('localStorage')
    const token = ReactSession.get('token')

    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')

    const [showSpinner, setShowSpinner] = useState(false)
    const [showError, setShowError] = useState(false)

    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)   
  
    const getProducts = () => {
        setShowSpinner(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/getStripeProducts/`, {
            headers: {
                'content-type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(async response => {
            const data = await response.json()
  
            if (data.success) {
                setProducts(data.data)
                setShowSpinner(false)
            }
            else {
                setShowError(true)
                setShowSpinner(false)
                setTimeout(() => setShowError(false), 2000)
            }
        })
        .catch(error => {
            setShowError(true)
            console.error('There was an error!', error)
        })
    }

    useEffect(() => getProducts(), [])

    const buyProduct = () => {
        setShowSpinner(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/buyProduct/`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ id: selectedProduct })
        })
        .then(async response => {
            const data = await response.json()
  
            if (data.success) {
                ReactSession.set('coursePaymentData', data?.data)
                window.location.href = data?.data?.url
            }
            else {
                setShowError(true)
                setShowSpinner(false)
                setTimeout(() => setShowError(false), 2000)
            }
        })
        .catch(error => {
            setShowError(true)
            console.error('There was an error!', error)
        })
    }

    return (
        <Modal open={openUpgradePlanModal} onClose={() => setOpenUpgradePlanModal(false)}>
            <SuiBox
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '100vw', md: '60vw' },
                    height: { xs: '100vh', md: 'auto' },
                    maxHeight: { md: '90vh' },
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY:'auto'
                }}
            >   
                {showSpinner && <SuiBox my={20} mx='auto'><CircularProgress size={40}/></SuiBox>}

                {!showSpinner && (
                    <>
                        {showError && <SuiAlert color='error'>There was an error, try again later</SuiAlert>}

                        <SuiTypography sx={{ my: 2.5 }} variant='h4'>Upgrade your plan</SuiTypography>

                        <Grid container p={1} spacing={1}>
                            {products?.filter(product => product?.plus === true).map(product => { // eslint-disable-line
                                return (
                                    <>
                                        <Grid item xs={11}>
                                            <Accordion key={product?.id} TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: 'none' }}>
                                                {/* COURSE */}
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    sx={{
                                                        my: 1,
                                                        p: .5,
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <SuiBox
                                                        sx={{
                                                            display: 'flex',
                                                            width: '100%',
                                                            flexDirection: isMobile ? 'column' : 'row',
                                                            justifyContent: isMobile ? 'center' : 'space-between',
                                                            alignItems: isMobile ? 'flex-start' : 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        {/* TITLE */}
                                                        <SuiBox display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-start' p={isMobile ? 0 : 2} sx={{ maxWidth: isMobile ? 'auto' : 'calc(100% - 150px)' }}>
                                                            <SuiBox display='flex' flexDirection='column' ml={1.5}>
                                                                <SuiTypography variant='subtitle1'>{product?.name}</SuiTypography>
                                                                <SuiTypography variant='subtitle2'>{product?.checkoutSubtitle}</SuiTypography>
                                                            </SuiBox>
                                                        </SuiBox>
                                                        
                                                        <SuiBox
                                                            ml={isMobile ? 1.5 : 0}
                                                            display={isMobile ? 'flex' : 'auto'}
                                                            justifyContent={isMobile ? 'space-between' : 'auto'}
                                                            width={isMobile ? '90%' : 'auto'}
                                                        >
                                                            {/* PRICE */}
                                                            <SuiBox
                                                                mr={isMobile ? 0 : 5}
                                                                display='flex'
                                                                justifyContent='center'
                                                                alignItems='center'
                                                                sx={{
                                                                    position: isMobile ? 'relative' : 'absolute',
                                                                    mt: isMobile ? 2 : 0,
                                                                    mx: isMobile ? 'auto' : 0,
                                                                    right: isMobile ? 'auto' : 100,
                                                                    top: isMobile ? 'auto' : '40%'
                                                                }}
                                                            >
                                                                <SuiTypography variant='subtitle2' mx='auto'>$ {product?.price}</SuiTypography>
                                                            </SuiBox>

                                                            {/* CHECKBOX */}
                                                            <SuiBox
                                                                mr={isMobile ? 0 : 5}
                                                                display='flex'
                                                                flexDirection='column'
                                                                justifyContent='center'
                                                                alignItems='center'
                                                                sx={{
                                                                    position: isMobile ? 'relative' : 'absolute',
                                                                    mt: isMobile ? 2 : 0,
                                                                    mx: isMobile ? 'auto' : 0,
                                                                    right: isMobile ? 'auto' : 20,
                                                                    top: isMobile ? 'auto' : '30%'
                                                                }}
                                                            >
                                                                <SuiTypography variant='caption'>Select</SuiTypography>
                                                                <Checkbox
                                                                    style={{ marginLeft: 3 }}
                                                                    checked={product?.id === selectedProduct}
                                                                    onClick={e => e.stopPropagation()}
                                                                    onChange={() => {
                                                                        if (product?.id === selectedProduct) setSelectedProduct(null)
                                                                        else setSelectedProduct(product?.id)
                                                                    }}
                                                                />
                                                            </SuiBox>
                                                        </SuiBox>
                                                    </SuiBox>
                                                </AccordionSummary>
                                                
                                                <AccordionDetails sx={{ width: '95%', mx: 'auto' }}>
                                                    <SuiTypography variant='overline' mr={1} dangerouslySetInnerHTML={{ __html: product?.description }} />
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>

                                        {/* <Grid item xs={1} mt={6.25}>
                                            <Checkbox
                                                checked={product?.id === selectedProduct}
                                                onChange={() => {
                                                    if (product?.id === selectedProduct) setSelectedProduct(null)
                                                    else setSelectedProduct(product?.id)
                                                }}
                                            />
                                        </Grid> */}
                                    </>
                                )
                            })}
                        </Grid>


                        <SuiBox mt={2} mb={5} width='80%' mx='auto' display='flex'>
                            <SuiButton
                                sx={{ mx: 1 }}
                                fullWidth
                                color='info'
                                variant='outlined'
                                disabled={showSpinner}
                                onClick={() => setOpenUpgradePlanModal(false)}
                            >
                                Cancel
                            </SuiButton>

                            <SuiButton
                                sx={{ mx: 1 }}
                                fullWidth
                                disabled={!selectedProduct || showSpinner}
                                color='info'
                                variant='gradient'
                                onClick={() => buyProduct()}
                            >
                                Checkout
                            </SuiButton>
                        </SuiBox>
                    </>
                )}
                
            </SuiBox>
        </Modal>
    )
}

export default UpgradePlanModal

// typechecking props
UpgradePlanModal.propTypes = {
    openUpgradePlanModal: PropTypes.bool.isRequired,
    setOpenUpgradePlanModal: PropTypes.func.isRequired
};
