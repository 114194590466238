/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types' // eslint-disable-line
import { useState, useEffect } from 'react'
import Vimeo from '@u-wave/react-vimeo'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiAvatar from "components/SuiAvatar";
import SuiAlert from "components/SuiAlert";
// Soft UI Dashboard PRO React example components
import FullContent from 'layouts/dashboards/default/levelUpView/fullContent/FullContent'
import PreviewContent from 'layouts/dashboards/default/levelUpView/previewContent/PreviewContent'
import PdfViewer from 'layouts/pdfViewer/PdfViewer'
import UpgradePlanModal from 'layouts/dashboards/default/levelUpView/upgradePlanModal/UpgradePlanModal'
import CoursesCardsContainer from 'layouts/dashboards/default/levelUpView/coursesCardsContainer/CoursesCardsContainer'
// import CircularProgressWithLabel from 'components/circularProgressWithLabel/CircularProgressWithLabel'
import ScoreModal from 'examples/Modal/ScoreModal'
import SidenavLessonCollapse from "examples/Sidenav/SidenavLessonCollapse";
// import SidenavItem from "examples/Sidenav/SidenavItem";
// Radio
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
// Icons
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Card from '@mui/material/Card'
import useMediaQuery from '@mui/material/useMediaQuery'
import Fade from '@mui/material/Fade'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
// import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import playIcon from "assets/images/icons/Play.png";

// import spellingBg from "assets/images/spelling-bg.png";

function LevelUpView({ candidate, courses, markLessonCompleted, setMarkLessonCompleted, progress, setCurrentLesson, lessons, setLessons, plan, token, saveLessons, setSaveLessons, videoDisplayed, setVideoDisplayed, moduleSelected, setModuleSelected, courseSelected, setCourseSelected }) {

  const [openCollapse, setOpenCollapse] = useState([]);
  const [videoAlreadyDisplayed, setVideoAlreadyDisplayed] = useState(false);
  const [userHasPayed, setUserHasPayed] = useState(false)

  const [showPrev, setShowPrev] = useState(true) // eslint-disable-line
  const [showNext, setShowNext] = useState(true) // eslint-disable-line

  const [testAnswers, setTestAnswers] = useState([]) // eslint-disable-line

  const [moduleIndex, setModuleIndex] = useState(0)
  const [showPrevModule, setShowPrevModule] = useState(false)
  const [showNextModule, setShowNextModule] = useState(false)
  const isMobile = useMediaQuery('(max-width:500px)')

  const [openScoreModal, setOpenScoreModal] = useState(false)
  const [score, setScore] = useState(0)

  $(document).on("click", "a[href='/t/dashboard']", function() { // eslint-disable-line
    location.reload() // eslint-disable-line
  })

  window.onpopstate = e => { // eslint-disable-line
    $(".lessonBox, .customCourse").parent().hide() // eslint-disable-line
    location.reload() // eslint-disable-line
  }

  useEffect(() => {
    if (plan?.pk && !userHasPayed) {
      setUserHasPayed(true);
    }
  }, [plan]);

  useEffect(() => {
    if (courseSelected && courseSelected && courseSelected?.modules?.length > 1) {
      const newOpenCollapse = [];
      for (const courseModule of courseSelected.modules) { // eslint-disable-line
        newOpenCollapse.push(`module-${courseModule.pk}`)
      }

      setOpenCollapse(newOpenCollapse)
    }
  }, [courseSelected]);

  useEffect(() => {
    if (videoDisplayed) {
      setVideoAlreadyDisplayed(true)
    }
  }, [videoDisplayed]);

  useEffect(() => {
    if (moduleSelected && courseSelected && courseSelected?.modules?.length > 1) {
      let loop = 0;
      for (const courseModule of courseSelected.modules) { // eslint-disable-line
        loop += 1;
        if (courseModule.pk === moduleSelected.pk) {
          // eslint-disable-next-line
          if (loop > 1) {
            setShowPrevModule(true)
          }
          if (loop < courseSelected.modules.length) {
            setShowNextModule(true)
          }
          setModuleIndex(loop - 1);
          break;
        }
      }
    } else {
      // eslint-disable-next-line
      if (showPrevModule) {
        setShowPrevModule(false)
      }
      if (showNextModule) {
        setShowNextModule(false)
      }
    }
  }, [moduleSelected]);

  function changeModule(newIndex, isPrev) {
    // setShowPrev(true)
    // setShowNext(true)
    setShowPrevModule(false)
    setShowNextModule(false)
    setVideoDisplayed(null)
    let defaultUrl = `/t/dashboard/courses/${courseSelected.pk}`
    if (courseSelected.modules.length > newIndex && newIndex >= 0) {
      const tempNewModule = {...courseSelected.modules[newIndex]};
      setModuleSelected(tempNewModule);
      setLessons(tempNewModule.lessons)
      defaultUrl = `/t/dashboard/courses/${courseSelected.pk}/${tempNewModule.pk}/${tempNewModule.lessons[0].pk}`
      setTimeout(() => {
        setVideoDisplayed(tempNewModule.lessons[0])
      }, "100")
    } else {
      let nextCourse = null
      let courseMath = false
      // eslint-disable-next-line
      for (const course of courses) {
        courseMath = course.pk === courseSelected.pk ? true : courseMath;
        if (isPrev && !courseMath) {
          nextCourse = course;
        }
        if (!isPrev && courseMath && course.pk !== courseSelected.pk) {
          nextCourse = course;
          break;
        }
      }
      if (nextCourse) {
        setCourseSelected(nextCourse)
        setModuleSelected(nextCourse.modules[0])
        setLessons(nextCourse.modules[0].lessons)
        defaultUrl = `/t/dashboard/courses/${nextCourse.pk}/${nextCourse.modules[0].pk}/${nextCourse.modules[0].lessons[0].pk}`
        setTimeout(() => {
          setVideoDisplayed(nextCourse.modules[0].lessons[0])
        }, "100")
      } else {
        setModuleSelected(null)
        setLessons(null)
        setVideoAlreadyDisplayed(false)
      }
      
    }
    window.history.pushState({}, "", defaultUrl);
  }

  useEffect(() => {
    if (videoDisplayed) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/academy/setCurrentLesson/`;
      const postBody = {
          'currentLesson': videoDisplayed.pk
      };

      const requestMetadata = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              'Authorization': `Token ${token}`
          },
          body: new URLSearchParams(postBody)
      };
      fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
              if (response.success) {
                setCurrentLesson(videoDisplayed.pk)
              }
          });
    }
  }, [videoDisplayed]);

  function markLessonCompletedFunc(lessonCompleted) {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/academy/finishLesson/`;
    const postBody = {
        'lessonId': lessonCompleted.pk
    };

    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': `Token ${token}`
        },
        body: new URLSearchParams(postBody)
    };
    fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
            if (response.success) {
              console.log("Lesson Completed")
            }
        });
  }

  function nextVideo(markCompleted, isPrev) {
    const newLessons = [];
    let previousLeasson = {};
    let newLesson = {};
    const tempLesson = {...videoDisplayed};
    if (markCompleted && !tempLesson.completed && tempLesson.lesson) {
      markLessonCompletedFunc(tempLesson);
    }
    let selectNextLesson = false;
    // eslint-disable-next-line
    for (const lesson of lessons) {
      if (selectNextLesson && !isPrev) {
        newLesson = lesson;
        selectNextLesson = false;
      }
      // eslint-disable-next-line
      if (lesson.pk === tempLesson.pk) {
        tempLesson.completed = true;
        tempLesson.progress = 100;
        newLessons.push(tempLesson);
        selectNextLesson = true;
        if (isPrev && previousLeasson) {
          newLesson = previousLeasson;
          break;
        }
      } else {
        newLessons.push(lesson);
      }
      previousLeasson = {...lesson};
    }
    if (markCompleted) {
      setLessons(newLessons);
      if (!videoDisplayed.completed) {
        setMarkLessonCompleted(markLessonCompleted + 1)
        let lessonsCompleted = 0;
        const tempModuleSelected = {...moduleSelected}
        const tempModuleSelectedLessons = [];
        for (const tempLesson of tempModuleSelected.lessons) { // eslint-disable-line
          if (tempLesson.pk === videoDisplayed.pk) tempLesson.completed = true;
          
          lessonsCompleted += tempLesson.completed ? 1 : 0;
          tempModuleSelectedLessons.push(tempLesson);
        }
        tempModuleSelected.lessons = tempModuleSelectedLessons;
        tempModuleSelected.progress = Math.ceil( lessonsCompleted * 100 / tempModuleSelected.lessons.length)
        setModuleSelected(tempModuleSelected)
      }
    }

    if (newLesson && newLesson.pk) {
      let defaultUrl = `/t/dashboard/courses/${courseSelected.pk}/${moduleSelected.pk}`
      defaultUrl = newLesson.lesson ? `${defaultUrl}/${newLesson.pk}` : `${defaultUrl}/test`
      window.history.pushState({}, "", defaultUrl);
      setVideoDisplayed(null)
      setTimeout(() => {
        setVideoDisplayed(newLesson)
      }, "100")
    } else if (isPrev) {
      changeModule(moduleIndex - 1, true)
    } else {
      changeModule(moduleIndex + 1, false)
    }
    window.scrollTo(0, 0);
  }

  // eslint-disable-next-line
  $(".audioIframe").bind('ended', function(){
      nextVideo(true, false);
  });

  function selectLesson(lessonSelected, moduleToSelect) {
    setVideoDisplayed(null)
    let defaultUrl = `/t/dashboard/courses/${courseSelected.pk}/${moduleToSelect.pk}`
    defaultUrl = lessonSelected.lesson ? `${defaultUrl}/${lessonSelected.pk}` : `${defaultUrl}/test`
    window.history.pushState({}, "", defaultUrl);
    setTimeout(() => {
      if (moduleSelected !== moduleToSelect) {
        setModuleSelected(moduleToSelect)
        setLessons(moduleToSelect.lessons);
      }
      setVideoDisplayed(lessonSelected)
    }, "100")
    window.scrollTo(0, 0);
    // $(".videoIframe iframe").attr("src", `${lessonSelected.lesson.url}&speed=0&autoplay=1&quality=undefined&app_id=122963&texttrack=undefined`)
  }

  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false)

  function selectAnswer(value, index) {
    const tempAnswers = [...testAnswers]
    if (!tempAnswers.length) {
      for (let i = 0; i < videoDisplayed.questions.length; i++) { // eslint-disable-line
        tempAnswers.push("")
      }
    }
    tempAnswers[index] = Number(value);
    setTestAnswers(tempAnswers)
  }

  function answerTest() {
    let validAnswers = true
    for (const testAnswer of testAnswers) { // eslint-disable-line
      // eslint-disable-net-line
      if (!testAnswer && testAnswer !== 0) {
        validAnswers = false
      }
    }
    if (validAnswers && testAnswers.length) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/academy/answerTest/`;
      const postBody = {
          'moduleId': moduleSelected.pk,
          'answers': [...testAnswers]
      };

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(postBody)
      };
      fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
              if (response.success) {
                setScore(response.data.score)
                setOpenScoreModal(true)
                const tempTest = response.data.test;
                const prevAnswers = response.data;
                tempTest.questions = []
                let loop = 1
                // eslint-disable-next-line
                while (tempTest[`question${loop}`] && tempTest[`question${loop}`].length) {
                  const answers = []
                  let answerLoop = 0;
                  // eslint-disable-next-line
                  for (const answer of tempTest[`answers${loop}`].split('|')) {
                    // eslint-disable-next-line
                    if (answer) {
                      answers.push({
                        "answer": answer,
                        "index": answerLoop,
                      })
                      answerLoop += 1;
                    }
                  }
                  tempTest.questions.push({
                    "question": tempTest[`question${loop}`],
                    "answers": answers,
                    "name": `question${loop}`,
                    "index": loop - 1,
                    "answered": prevAnswers ? prevAnswers[`answer${loop}`] : null,
                    "isCorrect": prevAnswers ? prevAnswers.isCorrectAnswers[loop - 1] : null,
                  })
                  loop += 1;
                }
                tempTest.retry = false;
                tempTest.score = response.data.score;
                setVideoDisplayed(null)
                setTimeout(() => {
                  setVideoDisplayed(tempTest)
                  setMarkLessonCompleted(markLessonCompleted + 1)
                }, "100")
              }
          });
    }
  }

  function retryTest() {
    const tempVideoDisplayed = {...videoDisplayed}
    const tempQuestions = [];
    for (const question of tempVideoDisplayed?.questions) { // eslint-disable-line
      question.answered = null
      question.isCorrect = null
      tempQuestions.push(question)
    }
    tempVideoDisplayed.questions = tempQuestions;
    tempVideoDisplayed.retry = true;
    setVideoDisplayed(null)
    setTimeout(() => {
      setVideoDisplayed(tempVideoDisplayed)
    }, "100")
  }

  const [showSavedLessonMsg, setShowSavedLessonMsg] = useState(false)
  const [showRemovedLessonMsg, setShowRemovedLessonMsg] = useState(false)

  function saveLesson(isRemove) {
    let recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/academy/saveLesson/`;
    if (isRemove) {
      recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/academy/removeSavedLesson/`;
    }
    const postBody = {
        'lessonId': videoDisplayed.pk
    };

    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': `Token ${token}`
        },
        body: new URLSearchParams(postBody)
    };
    fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
            if (response.success && isRemove) {
              const tempSaveLessons = [];
              for (const tempSaveLesson of [...saveLessons] ) { // eslint-disable-line
                 // eslint-disable-next-line
                if (tempSaveLesson.pk !== videoDisplayed.pk) {
                  tempSaveLessons.push(tempSaveLesson)
                }
              }
              setSaveLessons(tempSaveLessons)

              setShowRemovedLessonMsg(true)
              setTimeout(() => { setShowRemovedLessonMsg(false) }, 2000)

            } else if (response.success) {
              const tempSaveLessons = saveLessons ? [...saveLessons] : [];
              tempSaveLessons.push(videoDisplayed)
              setSaveLessons(tempSaveLessons)

              setShowSavedLessonMsg(true)
              setTimeout(() => { setShowSavedLessonMsg(false) }, 2000)
            }
        });
  }

  function addCollapsed(moduleToCollapse) {
    const tempOpenCollapses = [...openCollapse]
    if (tempOpenCollapses.includes(moduleToCollapse)) {
      const newOpenCollapses = [];
      // eslint-disable-next-line
      for (const tempOpenCollapse of tempOpenCollapses) {
        if (tempOpenCollapse !== moduleToCollapse) newOpenCollapses.push(tempOpenCollapse)
      }

      setOpenCollapse(newOpenCollapses)
    } else {
      tempOpenCollapses.push(moduleToCollapse);
      setOpenCollapse(tempOpenCollapses)
    }
  }

  let currentIndex = 0;
  let currentIndexLoop = 0;
  if (courseSelected && courseSelected?.modules?.length && videoDisplayed) {
    for (const tempModuleIndex of courseSelected.modules) { // eslint-disable-line
      for (const tempLessonIndex of tempModuleIndex.lessons) { // eslint-disable-line
        currentIndexLoop += 1;
        if (videoDisplayed.moduleId) {
          if (videoDisplayed.moduleId === tempModuleIndex.pk) { // eslint-disable-line
            currentIndex = currentIndexLoop;
          }
        } else if (tempLessonIndex.pk === videoDisplayed.pk && !tempLessonIndex.moduleId) {
          currentIndex = currentIndexLoop;
        }
      }
    }
  }

  function playAudio(audioId) {
    if (audioId && $(`.audio${audioId}`).length) {
      $(`.audio${audioId}`)[0].play();
    }
  }

  $(".dangerouslyHTML a").attr("target", "_blank") // eslint-disable-line
  $(document).on("click", ".dangerouslyHTML a", function() { // eslint-disable-line
    $(this).removeClass("active").addClass("active");
  })

  return (
    <SuiBox width="100%">

      { !courseSelected ? (
        <CoursesCardsContainer courses={courses} setCourseSelected={setCourseSelected}/>
      ) : null }

      {openUpgradePlanModal && (
        <UpgradePlanModal openUpgradePlanModal={openUpgradePlanModal} setOpenUpgradePlanModal={setOpenUpgradePlanModal} />
      )}

      {userHasPayed && courseSelected && !videoDisplayed && !videoAlreadyDisplayed ? <FullContent candidate={candidate} courses={courses} progress={progress} lessons={lessons} setLessons={setLessons} setModuleSelected={setModuleSelected} courseSelected={courseSelected} setCourseSelected={setCourseSelected} setVideoDisplayed={setVideoDisplayed} /> : null }
      {!userHasPayed && plan ? <PreviewContent /> : null }

      {plan && userHasPayed && lessons?.length ? (
        <SuiBox width={isMobile ? '100vw' : 'auto'}>
          {showSavedLessonMsg && <SuiAlert>This lesson has been saved to your Saved Lessons</SuiAlert>}
          {showRemovedLessonMsg && <SuiAlert>This lesson has been removed from your Saved Lessons</SuiAlert>}

          <SuiBox
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
              marginTop: "8px"
            }}
          >
            <SuiBox>
              <SuiTypography variant="h4" fontWeight="bold" ml={1}>{videoDisplayed?.lesson?.title ? videoDisplayed?.lesson?.title : videoDisplayed?.name}</SuiTypography>
              <SuiTypography variant="h6" fontWeight="400" ml={1}>Lesson {currentIndex} of {currentIndexLoop}</SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiBox
              mt={2}
              ml={isMobile ? 2 : "auto"}
              display="flex"
              justifyContent={isMobile ? "center" : "space-between"}
              alignItems="center"
              flexDirection={isMobile ? "column" : "row"}
              className="lessonBox"
          >
              <Fade in timeout={550}>
                <Card sx={{ width: isMobile ? "93%" : "73%", height: isMobile ? 470 : 730 }} className={videoDisplayed && videoDisplayed?.lesson?.type === "spelling" ? "spellingBg" : ""}>
                  
                  { videoDisplayed && videoDisplayed?.lesson?.type === "video" ? (
                    <SuiBox
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        px: '10px',
                        background: videoDisplayed && videoDisplayed?.lesson?.type === "video" ? 'black' : 'auto'
                      }}
                    >
                      <SuiBox
                        sx={{
                          width: '100%',
                          height: isMobile ? '400px' : '650px',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <Vimeo
                          className={`videoIframe ${videoDisplayed?.lesson?.type === "audio" ? "hiddenField" : ""}`}
                          video={videoDisplayed.lesson.url}
                          height={isMobile ? 400 : 650}
                          autoplay
                          style={{ maxWidth: "100%" }}
                          onEnd={() => nextVideo(true, false)}
                        />
                      </SuiBox>
                    </SuiBox>
                  ) : null }

                  <SuiBox
                    sx={{
                      width: '100%',
                      height: isMobile ? '400px' : '650px'
                    }}
                  >
                    { videoDisplayed && videoDisplayed?.lesson?.type === "audio" ? (
                      <audio controlsList="nodownload" controls autoPlay src={videoDisplayed.lesson.url} className={`audioIframe ${videoDisplayed?.lesson?.type === "video" ? "hiddenField" : ""}`}>
                          Your browser does not support the <code>audio</code> element.
                      </audio>
                    ) : null }
                    { videoDisplayed && videoDisplayed?.lesson?.type === "audio" && videoDisplayed?.lesson?.image ? (
                      <SuiBox className="audioImage">
                        <img src={`${process.env.REACT_APP_API_ROUTE}${videoDisplayed.lesson.image}`} alt={videoDisplayed?.lesson?.title}/>
                        <div className="audioImageHelper"></div>
                      </SuiBox>
                    ) : null }
                    { videoDisplayed && videoDisplayed?.lesson?.type === "pdf" ? (
                      <>
                        { videoDisplayed.lesson?.url2 ? (
                          <audio controlsList="nodownload" controls autoPlay src={videoDisplayed.lesson.url2} className={`audioPdfIframe ${videoDisplayed?.lesson?.type === "video" ? "hiddenField" : ""}`}>
                              Your browser does not support the <code>audio</code> element.
                          </audio>
                        ) : null }
                        { videoDisplayed.lesson?.url2 ? (
                          <SuiBox sx={{ height: isMobile ? "320px" : "570px", overflow: "auto", background: "rgb(82, 87, 88)" }}>
                              <PdfViewer cvUrl={videoDisplayed.lesson.url} />
                          </SuiBox>
                        ) : (
                          <SuiBox sx={{ height: isMobile ? "400px" : "650px", overflow: "auto", background: "rgb(82, 87, 88)" }}>
                              <PdfViewer cvUrl={videoDisplayed.lesson.url} />
                          </SuiBox>
                        ) }
                      </>
                    ) : null }

                    { videoDisplayed && videoDisplayed?.lesson?.type === "spelling" ? (
                      <SuiBox sx={{ height: isMobile ? "400px" : "650px", overflow: "auto", margin: "10px" }}>
                        <div style={{ fontSize: "16px", marginTop: "10px" }} dangerouslySetInnerHTML={{ __html: videoDisplayed?.lesson?.text }} />
                        <br/>
                        <SuiBox sx={{ margin: "5px auto 15px auto", textAlign: "center" }}>
                          <SuiTypography variant="h6" fontWeight="bold" className="inlineMiddle">Click</SuiTypography>
                          <SuiAvatar src={playIcon} alt="Play" size="xs" className="inlineMiddle" sx={{ margin: "0 3px", width: "20px", "height": "20px" }} />
                          <SuiTypography variant="h6" fontWeight="bold" className="inlineMiddle">to listen</SuiTypography>
                        </SuiBox>
                        <SuiBox>
                          {videoDisplayed.lesson.words.map(word => (
                            <SuiBox sx={{ marginBottom: "3px" }}>
                              <SuiAvatar src={playIcon} alt="Play" size="xs" className="inlineMiddle" onClick={() => playAudio(word.id)} sx={{ cursor: "pointer", width: "20px", "height": "20px", marginBottom: "2px" }}/>
                              <SuiTypography variant="h6" fontWeight="bold" ml={1} className="inlineMiddle">{word.word}</SuiTypography>
                              <SuiTypography variant="h6" fontWeight="400" ml={1} className="inlineMiddle" sx={{ fontStyle: "italic" }}>{word.syllables}</SuiTypography>
                              <audio controlsList="nodownload" controls src={`${process.env.REACT_APP_API_ROUTE}${word.audio}`} className={`hiddenField audio${word.id}`}>
                                Your browser does not support the <code>audio</code> element.
                              </audio>
                            </SuiBox>
                            ))}
                        </SuiBox>
                      </SuiBox>
                    ) : null }

                    { videoDisplayed && !videoDisplayed.lesson ? (
                      <SuiBox sx={{ height: isMobile ? "400px" : "650px", overflow: "auto" }}>
                        {videoDisplayed.questions.map(question => (<>
                          <SuiBox>
                            <SuiTypography variant="h6" fontWeight="bold" ml={1} mt={1}>{question?.question}</SuiTypography>
                          </SuiBox>
                          <FormControl sx={{ marginBottom: "10px" }}>
                            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              sx={{ marginLeft: "20px" }}
                              onChange={e => selectAnswer(e.target.value, question.index)}
                              defaultValue={question?.answered || question?.answered === 0 ? String(question.answered) : -1}
                            >
                              {question.answers.map(answer => (
                                <FormControlLabel value={answer.index} control={<Radio disabled={moduleSelected?.test && !videoDisplayed.retry} />} label={<SuiTypography sx={{ fontSize: 14, color: question?.answered >= 0 && !question?.isCorrect && answer.index === question?.answered ? "#d32f2f" : "#000" }}> {answer.answer} </SuiTypography>} sx={{ color: "red" }} />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </>))}
                        <ScoreModal score={score} openScoreModal={openScoreModal} setOpenScoreModal={setOpenScoreModal} />
                      </SuiBox>
                    ) : null }
                  </SuiBox>

                  <SuiBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    ml={1} 
                    mb={videoDisplayed && videoDisplayed?.lesson?.type === 'video' ? 3 : 'auto'}
                    className="videoButtons"
                  >
                    <SuiBox>
                      { videoDisplayed && videoDisplayed.lesson && saveLessons ? (
                        <>
                          { saveLessons.filter(x => x.pk === videoDisplayed.pk).length ? (
                              <SuiButton
                                color="info"
                                variant="outlined"
                                onClick={() => saveLesson(true)}
                              >
                                REMOVE LESSON
                              </SuiButton>
                            ) : (
                              <SuiButton
                                color="info"
                                variant="outlined"
                                onClick={() => saveLesson(false)}
                              >
                                SAVE LESSON
                              </SuiButton>
                            )
                          }
                        </>
                      ) : null }
                    </SuiBox>
                    <SuiBox sx={{ mr: 2 }}>
                      {showPrev ? (
                        <SuiButton onClick={() => nextVideo(false, true)}><ArrowBackIosIcon /></SuiButton>
                      ) : null }
                      {showNext ? (
                        <SuiButton onClick={() => nextVideo(true, false)}><ArrowForwardIosIcon /></SuiButton>
                      ) : null }
                      { videoDisplayed && !videoDisplayed.lesson ? (
                        <>
                          { moduleSelected?.test && !videoDisplayed.retry ? (
                              <SuiButton color="info" onClick={() => retryTest()}>RETRY TEST</SuiButton>
                            ) : (
                              <SuiButton color="info" onClick={() => answerTest()}>SUBMIT TEST</SuiButton>
                            )
                          }
                        </>
                      ) : null }
                    </SuiBox>
                  </SuiBox>
                </Card >
              </Fade>
              
              <SuiBox
                display="flex"
                flexDirection="column"
                sx={{
                    width: isMobile ? "100%" : "25%",
                    height: isMobile ? 450 : 750,
                    maxHeight: isMobile ? 450 : 750,
                    px: 1,
                    mx: "auto",
                    marginTop: isMobile ? 0 : "-20px",
                    overflow: "auto"
                }}
              >
                <SuiBox 
                  display='flex' 
                  justifyContent='space-between' 
                  alignItems='center' 
                  ml={1}
                  sx={{
                    border: "2px solid #edeff2",
                    margin: "15px 0 0 0",
                    padding: "10px 15px",
                    background: "#fff",
                    borderRadius: "10px 10px 0 0",
                  }}
                  >
                  Lessons
                </SuiBox>
                <SuiBox
                    display="flex"
                    flexDirection="column"
                    sx={{
                        width: "100%",
                        height: isMobile ? 450 : 750,
                        maxHeight: isMobile ? 450 : 750,
                        px: 1,
                        mx: "auto",
                        marginTop: "-2px",
                        background: "white",
                        overflow: "auto",
                        borderRadius: "0 0 10px 10px",
                        border: "2px solid #edeff2",
                    }}
                >


                { courseSelected && courseSelected?.modules?.length ? (
                  <SuiBox>
                    {courseSelected.modules.map((module) => (
                      <SidenavLessonCollapse
                        color="#fff"
                        icon={openCollapse.includes(`module-${module.pk}`) ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
                        key={`module-${module.pk}`}
                        name={module?.module?.title}
                        open={openCollapse.includes(`module-${module.pk}`)}
                        onClick={() => (addCollapsed(`module-${module.pk}`))}
                      >
                        {module.lessons.map((lesson) => (

                          <SuiTypography 
                            variant='h6' 
                            ml={3} 
                            onClick={() => selectLesson(lesson, module)} 
                            className={`videoList ${((videoDisplayed?.pk === lesson.pk && videoDisplayed && !videoDisplayed.moduleId && !lesson?.name) || (lesson?.name && videoDisplayed && videoDisplayed.moduleId === module.pk)) ? "videoActive" : ""}`}
                            data-src={videoDisplayed?.lesson?.pk}
                            sx={{ fontWeight: "normal", marginBottom: "5px", cursor: "pointer" }}
                            >
                            {lesson.completed || lesson?.score >= 1 ? (
                              <CheckCircleIcon sx={{ mr: 1, verticalAlign: "middle", color: "#008a2f" }} />
                            ) : (
                              <RadioButtonUncheckedIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                            )}
                            {lesson?.lesson?.title ? lesson?.lesson?.title : lesson?.name}
                          </SuiTypography>

                        ))}
                      </SidenavLessonCollapse>
                    ))}
                  </SuiBox>
                ) : null }




                </SuiBox>
              </SuiBox>

          </SuiBox>

          { videoDisplayed && (videoDisplayed?.lesson?.description || videoDisplayed?.description ) ? (
            <Card
                display="flex"
                flexDirection="column"
                sx={{
                    width: "100%",
                    height: "auto",
                    px: 1,
                    mx: "auto",
                    mt: 3,
                    padding: "10px",
                    fontSize: "16px"
                }}
            >
              
                <div className="dangerouslyHTML" dangerouslySetInnerHTML={{ __html: videoDisplayed?.lesson?.description ? videoDisplayed?.lesson?.description : videoDisplayed?.description }} />
            </Card>
          ) : null }
        </SuiBox>
      ) : null }

    </SuiBox>
  );
}

// Typechecking props for the ProfilesList
LevelUpView.propTypes = {
  candidate: PropTypes.objectOf(PropTypes.any).isRequired,
  plan: PropTypes.arrayOf(PropTypes.object).isRequired,
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  markLessonCompleted: PropTypes.number.isRequired,
  setMarkLessonCompleted: PropTypes.func.isRequired,
  lessons: PropTypes.arrayOf(PropTypes.object).isRequired,
  setLessons: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  setCurrentLesson: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  saveLessons: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSaveLessons: PropTypes.func.isRequired,
  videoDisplayed: PropTypes.arrayOf(PropTypes.object).isRequired,
  setVideoDisplayed: PropTypes.func.isRequired,
  moduleSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  setModuleSelected: PropTypes.func.isRequired,
  courseSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCourseSelected: PropTypes.func.isRequired,
};
  
export default LevelUpView;