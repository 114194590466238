/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
// import typography from "assets/theme/base/typography";
// import colors from "assets/theme/base/colors";
// import boxShadows from "assets/theme/base/boxShadows";

// // Soft UI Dashboard PRO React helper functions
// import linearGradient from "assets/theme/functions/linearGradient";
// import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;
// const { fontWeightMedium, fontWeightBold, size } = typography;
// const { gradients, transparent, white } = colors;
// const { buttonBoxShadow } = boxShadows;

export default {
  ".customMention": {
    width: "100%",
    height: "85px",

    "& .customMention__control": {
      width: "100%",
      border: "0",

      "& textarea": {
        borderRadius: `${borderRadius.sm} !important`,
        padding: "7px 15px !important",
        height: "75px !important",
        overflow: "visible !important",
      },

      "& .customMention__highlighter": {
        border: "0 !important",
        padding: "6px 16px !important",
        lineHeight: "26px",
        height: "75px !important",
      },
    },

    "& .customMention__suggestions__item": {
      padding: "5px 8px",
      "&:hover": {
        background: "#175a91",
        color: "#fff"
      }
    },

    "& .customMention__suggestions": {
      maxHeight: "200px !important",
      overflow: "auto !important",
      margin: "7px 0px 0 20px !important",
      "& ul": {
        maxHeight: "100% !important",
        overflow: "auto !important"
      }
    }
  }

};
