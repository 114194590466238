/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
// @mui material components
import Grid from "@mui/material/Grid";
import SuiPagination from "components/SuiPagination";
import Icon from "@mui/material/Icon";
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
// Settings page components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import Sidenav from "layouts/pages/utm/components/Sidenav";
import placeholder from "assets/images/illustrations/recommended.png";

import DataTable from "examples/Tables/DataTable"
// Data
import dataTableData from "layouts/pages/utm/data/dataTableData";
import dataTableDataSmall from "layouts/pages/utm/data/dataTableDataSmall";
import Swal from "sweetalert2";


function Settings() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const [loading, setLoading] = useState(true);

  const [utmArrayFilters, setUtmArrayFilters] = useState([]);  // eslint-disable-line
  const [utmFilterText, setUtmFilterText] = useState("All");  // eslint-disable-line
  const [utms, setUtms] = useState([]);  // eslint-disable-line
  const [utmsStart, setUtmsStart] = useState([]);  // eslint-disable-line

  const [candidatesData, setCandidatesData] = useState(dataTableData);  // eslint-disable-line
  const [candidatesDataSmall, setCandidatesDataSmall] = useState(dataTableDataSmall);  // eslint-disable-line

  const [pagination, setPagination] = useState({ pages: [] });
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(null)
  const [customPagination, setCustomPagination] = useState(null)

  function getStringDate(activityDate) {
      const dateObject = new Date(activityDate);
      const yyyy = dateObject.getFullYear();
      const mm = String(dateObject.getMonth() + 1).padStart(2, '0');
      const dd = String(dateObject.getDate()).padStart(2, '0');
      let hour = String(dateObject.getHours());
      let min = String(dateObject.getMinutes());
      if (hour.length === 1) {
          hour = `0${hour}`
      }
      if (min.length === 1) {
          min = `0${min}`
      }

      return `${mm}/${dd}/${yyyy} - ${hour}:${min}`;
  }

  function filterUtms(value) {
    const filterUtmsObjs = utmsStart.filter(x => x.name.includes(value));
    setUtms([]);
    setUtms(filterUtmsObjs);
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }

  function updateJobs(filters) {
    setLoading(true);
    let url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/getUTMRecords/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    if (filters) {
      url = `${url}?page=${page}&`;

      // eslint-disable-next-line
      $.each($(".selectUtm.Mui-checked"), function() {
        url = `${url}utm=${$(this).attr("data-name")}&`;
      });

      const startDate = $("input[name=startDate]").val();
      const endDate = $("input[name=endDate]").val();
      if (startDate && endDate) {
        const startDateFormat = new Date(`${startDate} 12:00:00`);
        let endDateFormat = new Date(`${endDate} 12:00:00`);
        endDateFormat = new Date(endDateFormat.setDate(endDateFormat.getDate() + 1));
        if (startDate && endDate && endDateFormat > startDateFormat) {
          url = `${url}startDate=${startDate}&endDate=${formatDate(endDateFormat)}`;
        }
      }
    }


    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success) {
          const candidatesDataTemp = { ...candidatesData };
          const candidatesDataSmalltemp = { ...candidatesDataSmall };
          const candidatesArray = [];

          // eslint-disable-next-line
          for (const candidateObj of res.data.records) {
            const candidate = candidateObj.candidate;  // eslint-disable-line
            const candidateUrl = `/r/profile/${candidate.user.id}`;
            candidatesArray.push({
              candidate: [`${candidate.user.first_name} ${candidate.user.last_name}`, candidate.user.photo, candidateUrl],
              mail: candidate.user.email,
              utm: candidateObj.utm,
              date: getStringDate(candidateObj.createdAt),
            })
          }
          candidatesDataTemp.rows = candidatesArray;
          candidatesDataSmalltemp.rows = candidatesArray;

          setCandidatesData(candidatesDataTemp);
          setCandidatesDataSmall(candidatesDataSmalltemp);

          // pagination
          const resPagination = { ...res.data.pagination };

          resPagination.hasPrev = true;
          resPagination.hasNext = true;
          if (resPagination.current === 1 || resPagination.countPages === 1) {
            resPagination.hasPrev = false;
          }

          if (resPagination.current === resPagination.countPages) {
            resPagination.hasNext = false;
          }

          if (resPagination.countPages >= 5) {
            if (resPagination.current === 1 || resPagination.current === 2) {
              resPagination.pages = [1, 2, 3, 4, 5];
            } else {
              resPagination.pages = [resPagination.current - 2, resPagination.current - 1, resPagination.current];
              if (resPagination.countPages >= resPagination.current + 1) {
                resPagination.pages.push(resPagination.current + 1);
              }
              if (resPagination.countPages >= resPagination.current + 2) {
                resPagination.pages.push(resPagination.current + 2);
              }
            }
          } else {
            resPagination.pages = [];
            // eslint-disable-next-line
            for (let i = 0; i < resPagination.countPages; i++) {
              resPagination.pages.push(i + 1);
            }
          }

          resPagination.pagesHtml = [];
          if (resPagination.pages.length > 1) {
            // eslint-disable-next-line
            for (let i = 0; i < resPagination.pages.length; i++) {
              if (resPagination.pages[i] === resPagination.current) {
                resPagination.pagesHtml.push(<SuiPagination className="pageReload" item active> {resPagination.pages[i]} </SuiPagination>)
              } else {
                resPagination.pagesHtml.push(<SuiPagination className="pageReload" item onClick={() => setPage(resPagination.pages[i])}> {resPagination.pages[i]} </SuiPagination>)
              }
            }
          }

          if (res.data.pagination.count !== totalResults) setTotalResults(res.data.pagination.count)
          setPagination(resPagination);
          setCustomPagination(res.data.pagination);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function updateFilters() {
    const url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/getUTMKeys/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };

    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success) {
          setUtms(res.data);
          setUtmsStart(res.data);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => {
    updateFilters();
  }, []);

  useEffect(() => {
    updateJobs(true);
  }, [page]);

  // eslint-disable-next-line
  $(document).unbind().on("click", ".selectUtm", function () {
    let defaultUtmsText = "";
    let count = 0;
    // eslint-disable-next-line
    $.each($(".selectUtm.Mui-checked"), function() {
      count += 1;
      if (count < 4) {
        defaultUtmsText = `${defaultUtmsText} ${$(this).attr("data-name")},`;
      }
    });

    if (defaultUtmsText.length) {
      defaultUtmsText = defaultUtmsText.slice(0, -1);
    } else {
      defaultUtmsText = "All";
    }

    if ($(".selectUtm.Mui-checked").length > 3) {
      defaultUtmsText = `${defaultUtmsText}...`;
    }

    if (utmFilterText !== defaultUtmsText) {
      setUtmFilterText(defaultUtmsText);
    }
  });

  const sourceAgainDialog = () => Swal.fire({
      title: 'Download CVS file?',
      text: "This will download a CSV file including the results on the current filters.",
      width: "400",
      height: "auto",
      showCloseButton: false,
      confirmButtonText: 'DOWNLOAD',
      confirmButtonAriaLabel: 'DOWNLOAD',
      allowEscapeKey: "true"
  }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: 'GET',
          headers: { 
            'Authorization': `Token ${token}`
          }
        };

        let url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/exportUTMRecords/?`;

        // eslint-disable-next-line
        $.each($(".selectUtm.Mui-checked"), function() {
          url = `${url}utm=${$(this).attr("data-name")}&`;
        });

        const startDate = $("input[name=startDate]").val();
        const endDate = $("input[name=endDate]").val();
        const startDateFormat = new Date(`${startDate} 12:00:00`);
        let endDateFormat = new Date(`${endDate} 12:00:00`);
        endDateFormat = new Date(endDateFormat.setDate(endDateFormat.getDate() + 1));
        if (startDate && endDate && endDateFormat > startDateFormat) {
          url = `${url}startDate=${startDate}&endDate=${formatDate(endDateFormat)}`;
        }

        fetch(`${url}`, requestOptions)
          .then( res => res.blob() )
          .then( blob => {
            const file = window.URL.createObjectURL(blob);
            window.location.assign(file);
          });
      }
  })

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <RecruiterDashboardLayout>
      <SuiBox display="flex" flexWrap="wrap" width="100%" alignItems="center" justifyContent={{ sx: "start", xl: "space-between" }} my={2}>
        <SuiTypography variant="h3" fontWeight="bold" ml={1} mb={3}>UTMs tracking tool</SuiTypography>
        <SuiButton sx={{ my: 2 }} color="info" variant="gradient" onClick={() => sourceAgainDialog()}>Download CSV</SuiButton>
      </SuiBox>
      
      <SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Sidenav utms={utms} utmFilterText={utmFilterText} utmArrayFilters={utmArrayFilters} filterUtms={filterUtms} updateJobs={updateJobs} />
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={9}>
            {/* ROW */}
            { candidatesData.rows.length ? (
              <>
                <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <DataTable table={candidatesData} customPagination={customPagination} totalResults={totalResults} view="Candidates" entriesPerPage={{ defaultValue: 100 }} />
                </SuiBox>

                <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
                  <DataTable table={candidatesDataSmall} customPagination={customPagination} totalResults={totalResults} view="Candidates" entriesPerPage={{ defaultValue: 100 }} />
                </SuiBox>

                <SuiBox mt={6} mb={9} className="paginationBox" sx={{ "& > div": { margin: "20px auto 40px auto", display: "table" }, }} >
                  <SuiPagination>
                  {(pagination.count > 0 && pagination.hasPrev) && (
                      <SuiPagination item className="pageReloadPrev" onClick={() => setPage(page - 1)}>
                        <Icon>keyboard_arrow_left</Icon>
                      </SuiPagination>
                    )}

                    {(pagination.count > 0) && pagination.pagesHtml}

                    {(pagination.count > 0 && pagination.hasNext) && (
                      <SuiPagination item className="pageReloadNext" onClick={() => setPage(page + 1)}>
                        <Icon>keyboard_arrow_right</Icon>
                      </SuiPagination>
                    )}
                  </SuiPagination>
                </SuiBox>

                { loading ? (
                  <SuiBox className="moonCover" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={20}>
                    <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                  </SuiBox> 
                ) : null }

              </>
            ) :
              <Grid item>
                { loading ? (
                  <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={20}>
                    <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                  </SuiBox> 
                ) : (
                <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={10} width="100%" height="100%">
                  <img src={placeholder} alt="Couple of documents" width={isMobile ? "100%" : "35%"} />

                  <SuiTypography mb={4} variant="h5" textAlign="center" color="dark">No Candidates were found.</SuiTypography>
                </SuiBox>
                )}
              </Grid>
            }
          </Grid>
        </Grid>
      </SuiBox>
    </RecruiterDashboardLayout>
  );
}

export default Settings;
