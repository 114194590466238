/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
import { useState, useEffect } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
// MUI components
import { Card } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ComputerIcon from '@mui/icons-material/Computer'
import Modal from '@mui/material/Modal'
import DoneIcon from '@mui/icons-material/Done'
// Soft UI Dashboard PRO React components
import SuiAvatar from 'components/SuiAvatar'
import SuiBox from 'components/SuiBox'
import SuiAlert from 'components/SuiAlert'
import SuiButton from 'components/SuiButton'
import SuiInput from 'components/SuiInput'
import SuiTypography from 'components/SuiTypography'
// Assessment page components
import StudentDashboardLayout from 'examples/LayoutContainers/StudentDashboardLayout'
import mockCompanyAvatar from  'assets/images/mockCompanyAvatar.png'

function Assessment() {

  ReactSession.setStoreType('localStorage')
  const token = ReactSession.get('token')
  const sesionStudent = ReactSession.get('student')

  const hasCompletedEnglishAssessment = ReactSession.get('hasCompletedEnglishAssessment')
  const assesmentCalification = ReactSession.get('assesmentCalification')
  const spokenAssessmentMailSent = ReactSession.get('spokenAssessmentMailSent')

  const studentPhoto = sesionStudent?.studentCompany?.photo ? `${process.env.REACT_APP_API_ROUTE}${sesionStudent.studentCompany.photo}` : mockCompanyAvatar
  
  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const [showErrorMsg, setShowErrorMsg] = useState(false)
  const [showShortAnswerMsg, setShowShortAnswerMsg] = useState(false)

  // If user has already completed assessment, only show modal with result 
  const [showCompleteModal, setShowCompleteModal] = useState(hasCompletedEnglishAssessment || false) 
  const [proficiencyLevel, setProficiencyLevel] = useState(assesmentCalification || null)

  const [assessmentStep, setAssessmentStep] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState(null)
  
  const [rows, setRows] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const [inputDisabled, setInputDisabled] = useState(false)
  const [isLastQuestion, setIsLastQuestion] = useState(true)
  
  const [showBtnSpinner, setShowBtnSpinner] = useState(false)
  const [showBtnSuccess, setShowBtnSuccess] = useState(false)
  const [showBtnErrorMsg, setShowBtnErrorMsg] = useState(false)
  const [disableSendMailBtn, setDisableSendMailBtn] = useState(spokenAssessmentMailSent)

  const movedialog = () => {
    setTimeout(() => { 
      $('.aiDialog').scrollTop($('.aiDialog')[0].scrollHeight) 
    }, '100')
  }

  useEffect(() => { document.title = 'Assessment | Boost' }, [])

  const createIAMessage = (data, rowsParam) => {
    const tempRows = rowsParam ? [...rowsParam] : [...rows]

    tempRows.push({
      isBot: true,
      message: data?.question?.question,
      options: data?.question?.options,
      audio: data?.question?.audio?.audio,
      id: rows.length + 1,
      pk: data?.question?.pk
    })
    
    setRows(tempRows)
    movedialog()
  }

  const sendResponse = async (selectedOption) => {
    
    let requestBody = {}
    const tempRows = [...rows]
    setCurrentQuestion(null)
    movedialog()

    /* Case for multiple choice questions */
    if (selectedOption) {
      const inputIndex = currentQuestion?.question?.options?.indexOf(selectedOption)
      requestBody = { questionId: currentQuestion?.question?.pk, answerIndex: inputIndex }
      setShowSpinner(true)
    }
    /* Case for free text questions */
    else {
      const answer = $('textarea[name=message]').val()
  
      if (answer.length < 2) {
        setShowShortAnswerMsg(true)
        setTimeout(() => { setShowShortAnswerMsg(false) }, 3000)
        return
      }
      
      setInputDisabled(true)
  
      tempRows.push({
        isBot: false,
        message: answer,
        id: rows.length + 1
      })
  
      $('textarea[name=message]').blur()
      setRows(tempRows)
      $('textarea[name=message]').val('')
      $('textarea[name=message]').focus()
  
      setTimeout(() => { movedialog() }, 1000)
  
      setShowSpinner(true)

      // check if input exists in options array, independantly if lowercase or uppercase.
      const input = $('textarea[name=message]')?.val()
  
      requestBody = { questionId: currentQuestion?.question?.pk, answerText: input }
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/answerAssesmentQuestion/`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`
        },
        body: JSON.stringify(requestBody)
      })

      const data = await res.json()
      
      setShowSpinner(false)
      setInputDisabled(false)

      if (data?.success) {

        if (data?.data?.finish) {
          setProficiencyLevel(data?.data?.score)
          setShowCompleteModal(true)
          
          ReactSession.set('assesmentCalification', data?.data?.score)
          ReactSession.set('hasCompletedEnglishAssessment', true)
        }

        else {
          if (data?.data?.currentQuestionNumber === data?.data?.totalQuestions) setIsLastQuestion(true)
          setCurrentQuestion(data?.data)
          createIAMessage(data?.data, tempRows)
        }
      }

      else {
        setShowErrorMsg(true)
        setTimeout(() => { setShowErrorMsg(false) }, 3000)
      }

    } catch (error) {
        console.error('There was an error!', error)
        setShowErrorMsg(true)

        setTimeout(() => {
          setShowErrorMsg(false)
        }, 3000)
    }
  }

  const generateAssessment = async () => {
    setAssessmentStep(1)

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/startAssesment/`, {
        method: 'POST',
        headers: { Authorization: `Token ${token}` }
      })
      const data = await res.json()

      if (data.success) {
        setCurrentQuestion(data.data)
        createIAMessage(data.data)
        setAssessmentStep(2)
      }

      else {
        setShowErrorMsg(true)

        setTimeout(() => {
          setShowErrorMsg(false)
          setAssessmentStep(1)
        }, 3000)
      }

    } catch (error) {
        console.error('There was an error!', error)
        setShowErrorMsg(true)

        setTimeout(() => {
          setShowErrorMsg(false)
          setAssessmentStep(1)
        }, 3000)
    }
  }

  const sendEmail = async () => {
    setShowBtnSpinner(true)

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/startSpokenAssessment/`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`
        }
      })

      const data = await res.json()
      
      setShowBtnSpinner(false)

      if (data?.success) {
        setDisableSendMailBtn(true)
        ReactSession.set('hasCompletedEnglishAssessment', true)
        setShowBtnSuccess(true)
        setTimeout(() => { setShowBtnSuccess(false) }, 3000)
      }

      else {
        setShowBtnErrorMsg(true)
        setTimeout(() => { setShowBtnErrorMsg(false) }, 3000)
      }

    } catch (error) {
        console.error('There was an error!', error)
        setShowBtnErrorMsg(true)

        setTimeout(() => {
          setShowBtnErrorMsg(false)
        }, 3000)
    }
  }

  return (
    <StudentDashboardLayout>

      {showErrorMsg && <SuiAlert color='error' sx={{ zIndex: 1 }}>An error has ocurred, please try again later.</SuiAlert>}
      {showShortAnswerMsg && (
        <SuiAlert color='error' sx={{ zIndex: 1 }}>
          Please provide a complete answer to each question instead of a single letter response. For example, use &quot;to-be&quot; instead of just &quot;A&quot;.
        </SuiAlert>
      )}
      
      {!showCompleteModal && (
        <>
          {assessmentStep === 0 && (
            <SuiBox display='flex' flexDirection='column' justifyContent='center'>
              <SuiTypography variant='h5' my={5}>
                This feature will generate an English proficiency assessment to determine your level of English based on the CEFR (Common European Framework of Reference for Languages). Please note that the score obtained is an estimation and may vary from your actual level.
              </SuiTypography>

              <SuiTypography variant='h5' my={5}>
                Click the &quot;Generate Assessment&quot; button to start generating your assessment.
              </SuiTypography>

              <SuiButton
                variant='gradient'
                color='info'
                sx={{
                  mt: 5,
                  mx: 'auto',
                  maxWidth: isMobile ? 'auto' : '15rem'
                }}
                onClick={() => generateAssessment()}
              >
                Generate Assessment
              </SuiButton>
            </SuiBox >
          )}

          {assessmentStep === 1 && (
            <SuiBox mt='5%' display='flex' flexDirection='column' justifyContent='center'>
              <SuiBox my={10} display='flex' justifyContent='center' alignItems='center'><CircularProgress size={40} /></SuiBox>
            </SuiBox >
          )}

          {assessmentStep === 2 && (
            <Card >
              <Grid container spacing={3} className='aiContainer'>
                <Grid item xs={12} lg={12} className='aiBox'>
                  <SuiBox className='aiDialog'>
                    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                      <Table aria-label='simple table'>
                        <TableBody>
                          {rows.map(row => (
                            <TableRow className={row.isBot ? 'aiRow bot' : 'aiRow'}>
                              <TableCell align='left' size='small' className='aiCell'>

                                {row.audio && (
                                  <SuiBox display='flex'>
                                    {/* Avatar */}
                                    {!row.isBot ?
                                      <SuiAvatar
                                        src={studentPhoto}
                                        alt='avatar'
                                        variant='rounded'
                                        size='xs'
                                        shadow='sm'
                                        sx={{ display: 'inline-block' }}
                                      />
                                    : 
                                      <ComputerIcon fontSize='small' sx={{ display: 'inline-block', marginTop: '2px' }}/>
                                    }

                                    <SuiBox>
                                      {/* Audio explanation */}
                                      <SuiBox>
                                        <SuiTypography variant='body2' fontWeight='bold' color='secondary' sx={{ whiteSpace: 'pre-line' }}>
                                          Please listen to the audio attentively and answer the following question based on what you hear:
                                        </SuiTypography>
                                      </SuiBox>

                                      {/* Message */}
                                      <SuiBox>
                                        <SuiTypography variant='body2' color='secondary' sx={{ whiteSpace: 'pre-line', my: 2 }}>
                                          {row.message}
                                        </SuiTypography>
                                      </SuiBox>
                                    </SuiBox>
                                  </SuiBox>
                                )}

                                {!row.audio && (
                                  <SuiBox display='flex'>
                                    {/* Avatar */}
                                    {!row.isBot ?
                                      <SuiAvatar
                                        src={studentPhoto}
                                        alt='avatar'
                                        variant='rounded'
                                        size='xs'
                                        shadow='sm'
                                        sx={{ display: 'inline-block' }}
                                      />
                                    : 
                                      <ComputerIcon fontSize='small' sx={{ display: 'inline-block', marginTop: '2px' }}/>
                                    }

                                    {/* Message */}
                                    <SuiTypography variant='body2' color='secondary' sx={{ whiteSpace: 'pre-line' }}>
                                      {row.message}
                                    </SuiTypography>
                                  </SuiBox>
                                )}
                                
                                {row?.options && row?.options[0] && (
                                  <SuiBox sx={{ ml: 2.5 }}>
                                    <SuiTypography variant='body2' fontWeight='bold' color='secondary' sx={{ whiteSpace: 'pre-line' }}>
                                      Options:
                                    </SuiTypography>

                                    <SuiBox display='flex' flexDirection='column'>
                                      {row?.options.map(option => (
                                        <SuiButton
                                          key={option}
                                          variant='outlined'
                                          size='small'
                                          color={currentQuestion?.question?.pk === row?.pk ? 'info' : 'secondary'}
                                          sx={{ my: 1 }}
                                          disabled={currentQuestion?.question?.pk !== row?.pk}
                                          onClick={() => sendResponse(option)}
                                        >
                                          {option}
                                        </SuiButton>
                                      ))}
                                    </SuiBox>
                                  </SuiBox>
                                )}

                                {/* Audio */}
                                {row.isBot && row.audio && (
                                  <SuiBox sx={{ margin: '15px 0 0 25px', textAlign: 'center' }}>
                                      <audio  controlsList='nodownload' controls src={`${process.env.REACT_APP_API_ROUTE}${row.audio}`}>
                                        Your browser does not support the <code>audio</code> element.
                                      </audio>
                                  </SuiBox >
                                )}

                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                    </TableContainer>

                    {showSpinner && ( 
                      <SuiBox sx={{ padding: '15px 45px 20px 45px', background: '#fff' }}>
                        <ComputerIcon fontSize='small' sx={{ display: 'inline-block', verticalAlign: 'middle', margin: '0 12px 0 0' }}/>
                        <span className='botThinking'>
                          {isLastQuestion ? 'BoostAI is calculating your score, please wait' : 'BoostAI is thinking, please wait'}
                        </span>
                        <BeatLoader color='#36d7b7' size={5} /> 
                      </SuiBox >
                    )}
                  </SuiBox >

                  <Grid item xs={12} lg={12} className='aiInput' mb={2}>
                    <SuiInput 
                      type='text' 
                      placeholder='Enter your answer...' 
                      name='message'
                      multiline 
                      rows={2} 
                      disabled={inputDisabled || currentQuestion?.question?.type !== 2} /* Disabled if question type doesn't require writting */
                      onKeyDown={e => {
                        if (e.code === 'Enter' && e.target.value.length > 0) {
                          e.preventDefault()
                          sendResponse()
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid >
            </Card>
          )}
        </>
      )}

      {showCompleteModal && (
        <Modal open={showCompleteModal} onClose={() => window.location.replace('/dashboard')}>
          <SuiBox
            sx={{
              width: isMobile ? '100%' : '850px',
              maxHeight: '90%',
              height: 'auto',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              border: 'none',
              boxShadow: 24,
              borderRadius: { xs: 0, md: 4 },
              p: 3,
              overflowY: 'auto'
            }}
          >
            {showBtnErrorMsg && <SuiAlert color='error' sx={{ zIndex: 1 }}>An error has ocurred, please try again later.</SuiAlert>}

            <SuiTypography variant='body1' my={5}>
              Congratulations on completing your English assessment! Based on the test, your English proficiency level on the CEFR scale is:
            </SuiTypography>

            <SuiTypography variant='h5' textAlign='center'>
              {proficiencyLevel}
            </SuiTypography>

            <SuiTypography variant='body2' mt={5}>
              It&apos;s important to keep in mind that while the assessment provides a valuable approximation of your language skills, it may not capture the entirety of your abilities. Language is a complex and nuanced domain, and a single test can only evaluate certain aspects. Your score is a reflection of your performance within the parameters of the assessment, but it doesn&apos;t define your complete language proficiency.
            </SuiTypography>

            <SuiTypography variant='body2' my={2}>
              To ensure the completion of your assessment and to obtain a more accurate evaluation of your English proficiency, we will send you an email containing a set of questions. Please answer to the questions so that our team can assess and adjust your score accordingly.
              </SuiTypography>
            
            <SuiTypography variant='body2' my={2}>
              Click the &quot;Send mail&quot; button below to proceed with sending the email.
            </SuiTypography>

            {/* eslint-disable-next-line */}
            {showBtnSpinner ?
                <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                :
                showBtnSuccess ?
                    <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center">
                      <DoneIcon size={20} />
                      <SuiTypography variant='button' my={2}>
                        Email sent correctly!
                      </SuiTypography>
                    </SuiBox>
                    :
                    <SuiButton
                      variant='gradient'
                      color='info'
                      fullWidth
                      sx={{ my: 2 }}
                      onClick={() => sendEmail()}
                      disabled={disableSendMailBtn}
                    >
                      {disableSendMailBtn ? 'Mail sent' : 'Send mail'}
                    </SuiButton>
            }
          </SuiBox>
        </Modal>
      )}

    </StudentDashboardLayout >
  )
}

export default Assessment
