/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// MUI components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';

function DeleteApplicationModal({ showDeleteApplicationModal, setShowDeleteApplicationModal, appId }) {

    const token = ReactSession.get("token")

    const [showSpinner, setShowSpinner] = useState(false)
    const [showDoneIcon, setShowDoneIcon] = useState(false)
    const [showError, setShowError] = useState(false)

    const deleteApplication = () => {
        setShowSpinner(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/removeApplication/`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${token}`
            }
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setShowSpinner(false)
                    setShowDoneIcon(true)

                    setTimeout(() => {
                        setShowDeleteApplicationModal(false)
                        setShowDoneIcon(false)
                        window.location.reload()
                    }, 1500)
                }
            })
            .catch(error => {
                console.error('There was an error!', error)
                setShowSpinner(false)
                setShowError(true)

                setTimeout(() => setShowError(false), 3000)
            })
    }

    return (
        <Modal open={showDeleteApplicationModal} onClose={() => setShowDeleteApplicationModal(false)}>
            <SuiBox sx={{
                width: "650px",
                height: "auto",
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: 'none',
                boxShadow: 24,
                borderRadius: { xs: 0, md: 4 },
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }} >

                {showDoneIcon && <SuiAlert color="info">Application deleted</SuiAlert>}
                {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                <SuiTypography sx={{ my: 2.5 }} variant="h6">Delete application?</SuiTypography>

                <SuiTypography sx={{ my: 2.5 }} variant="button">This action will completly remove the application from the database and we will keep no records of it. Delete?</SuiTypography>

                <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                    <SuiButton
                        sx={{ mx: 1 }}
                        fullWidth
                        color="info"
                        variant="outlined"
                        disabled={showSpinner || showDoneIcon}
                        onClick={() => setShowDeleteApplicationModal(false)}>Cancel</SuiButton>

                    {/* eslint-disable-next-line */}
                    {showSpinner ?
                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                        :
                        showDoneIcon ?
                            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                            :
                            <SuiButton sx={{ mx: 1 }} fullWidth color="info" variant="gradient" onClick={() => deleteApplication()}>Delete</SuiButton>
                    }
                </SuiBox>
            </SuiBox>
        </Modal>
    )
}

export default DeleteApplicationModal

// typechecking props
DeleteApplicationModal.propTypes = {
    showDeleteApplicationModal: PropTypes.func.isRequired,
    setShowDeleteApplicationModal: PropTypes.func.isRequired,
    appId: PropTypes.number.isRequired
};