/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
import { ReactSession } from 'react-client-session'
import { useState } from 'react'
// @mui material components
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiAlert from 'components/SuiAlert'
import SuiEditor from 'components/SuiEditor'

function ApplicationNotesModal({ showApplicationNotesModal, setShowApplicationNotesModal, appId, note, fullname }) {

    ReactSession.setStoreType("localStorage")
    const token = ReactSession.get("token")

    const [showSpinner, setShowSpinner] = useState(false)
    const [showDoneIcon, setShowDoneIcon] = useState(false)
    const [showError, setShowError] = useState(false)

    const [noteHtml, setNoteHtml] = useState(note || '')
  
    const saveNote = () => {
      setShowSpinner(true)
  
      fetch(`${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/setNotesApplication/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({ notes: noteHtml })
      })
      .then(async response => {
        const data = await response.json()
  
        if (data.success) {
          setShowSpinner(false)
          setShowDoneIcon(true)
  
          setTimeout(() => {
            setShowApplicationNotesModal(false)
            setShowDoneIcon(false)
            window.location.reload()
          }, 2000)
        }
        else {
          setShowError(true)
          setShowSpinner(false)
          
          setTimeout(() => setShowError(false), 2000)
        }
      })
      .catch(error => {
        setShowError(true)
        console.error('There was an error!', error)
      })
    }

    return (
        <Modal open={showApplicationNotesModal} onClose={() => setShowApplicationNotesModal(false)} >
            <SuiBox
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: "100vw", md: "80vw", xl: "60vw" },
                    height: "auto",
                    maxHeight: "80%",
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 2, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                {showDoneIcon && <SuiAlert color="info">Note saved!</SuiAlert>}
                {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                <SuiTypography variant="h6" ml={1.5} color="dark">Note for {fullname}&apos;s application</SuiTypography>

                <SuiBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <SuiBox
                        bgColor="#f8f9fa"
                        borderRadius="1%"
                        height="auto"
                        my={2}
                        sx={{ width: "950px", maxWidth: "calc(100% - 110px)", overflowY: 'auto' }}
                    >
                        <SuiEditor
                            height="100%"
                            name="noteHtml"
                            defaultValue={noteHtml}
                            onChange={setNoteHtml}
                            maxLength={1800}
                        />
                    </SuiBox>

                    <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                        <SuiButton
                            sx={{ mx: 1 }}
                            fullWidth
                            color="info"
                            variant="outlined"
                            disabled={showSpinner || showDoneIcon}
                            onClick={() => setShowApplicationNotesModal(false)}
                        >
                            Cancel
                        </SuiButton>

                        {/* eslint-disable-next-line */}
                        {showSpinner ?
                            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                            :
                            showDoneIcon ?
                                <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                                :
                                <SuiButton
                                    sx={{ mx: 1 }}
                                    fullWidth
                                    disabled={showSpinner || showDoneIcon}
                                    color="info"
                                    variant="gradient"
                                    onClick={() => saveNote()}
                                >
                                    Save note
                                </SuiButton>
                        }
                    </SuiBox>
                </SuiBox>

            </SuiBox>
        </Modal>
    )
}

ApplicationNotesModal.defaultProps = {
    note: ''
}

// Typechecking props for the ApplicationNotesModal
ApplicationNotesModal.propTypes = {
    showApplicationNotesModal: PropTypes.bool.isRequired,
    setShowApplicationNotesModal: PropTypes.func.isRequired,
    appId: PropTypes.number.isRequired,
    note: PropTypes.string,
    fullname: PropTypes.string.isRequired
}

export default ApplicationNotesModal