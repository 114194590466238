/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types"
// @mui material components
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"

function CompanyHighlightsCards({ companyHighlights, companyWhyWorkHere, companyCulture, companyPerks }) {

  const stripHtml = text => {
    const stripContent = text.replace(/<\/?[^>]+(>|$)/g, "")
    return stripContent
  }

  return (
    <>
      {companyHighlights && (
      <Grid item xs={12}>
        <SuiBox mb={3}>
          <Card sx={{ height: "auto", minHeight: "13vh" }}>
            <SuiBox p={2}>
              <SuiTypography variant="h6" mb={2}>Highlights</SuiTypography>
              <SuiTypography variant="body2" p={3} dangerouslySetInnerHTML={{ __html: companyHighlights }} />
            </SuiBox>
          </Card>
        </SuiBox>
      </Grid>
      )}

      {companyWhyWorkHere && (
      <Grid item xs={12}>
        <SuiBox mb={3}>
          <Card sx={{ height: "auto", minHeight: "13vh" }}>
            <SuiBox p={2}>
              <SuiTypography variant="h6" mb={2}>Why work here</SuiTypography>
              <SuiTypography variant="body2" p={3}  dangerouslySetInnerHTML={{ __html: companyWhyWorkHere }} />
            </SuiBox>
          </Card>
        </SuiBox>
      </Grid>
      )}
      
      {companyCulture && stripHtml(companyCulture) && (
        <Grid item xs={12}>
          <SuiBox mb={3}>
            <Card sx={{ height: "auto", minHeight: "13vh" }}>
              <SuiBox p={2}>
                <SuiTypography variant="h6" mb={2}>Culture</SuiTypography>
                <SuiTypography variant="body2" p={3} dangerouslySetInnerHTML={{ __html: companyCulture }} />
              </SuiBox>
            </Card>
          </SuiBox>
        </Grid>
      )}

      {companyPerks && (
        <Grid item xs={12}>
          <SuiBox mb={3}>
            <Card sx={{ height: "auto", minHeight: "13vh" }}>
              <SuiBox p={2}>
                <SuiTypography variant="h6" mb={2}>Perks</SuiTypography>
                <SuiTypography variant="body2" p={3}  dangerouslySetInnerHTML={{ __html: companyPerks }} />
              </SuiBox>
            </Card>
          </SuiBox>
        </Grid>
      )}

    </>
  )
}

// Typechecking props for the CompanyHighlightsCards
CompanyHighlightsCards.propTypes = {
  companyHighlights: PropTypes.string,
  companyWhyWorkHere: PropTypes.string,
  companyCulture: PropTypes.string,
  companyPerks: PropTypes.string
}

CompanyHighlightsCards.defaultProps = {
  companyHighlights: '',
  companyWhyWorkHere: '',
  companyCulture: '',
  companyPerks: ''
}

export default CompanyHighlightsCards