/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import AddSkillModal from "./AddSkillModal/AddSkillModal";
import SkillSlider from "./SkillSlider/SkillSlider";


function ProfilesList({ title, profiles, comment, candidateId }) { // eslint-disable-line

  ReactSession.setStoreType("localStorage");
  const CandidateForEdit = ReactSession.get("CandidateForEdit");
  const token = ReactSession.get("token");

  const [skillsListToShow, setSkillsListToshow] = useState(profiles)
  const [skillsListToPost, setSkillsListToPost] = useState(CandidateForEdit?.skills)
  const [skillUpdated, setSkillUpdated] = useState(false)

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  
  const [showAddSkillModal, setShowAddSkillModal] = useState(false)

  const returAsYears = years => {
    if (years >= 10) return `${years}+ Years`
    if (years >= 2) return `${years} Years`
    if (years === 0) return ""
    return `${years} Year`
  }

  const getSkillValue = skillValue => {
    if (skillValue >= 5) return 100
    return skillValue * 20
  }

  const saveChanges = () => {
    setShowSpinner(true)
    const candidateToUpdate = CandidateForEdit?.user?.id ? CandidateForEdit?.user?.id : candidateId;
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateToUpdate}/teclaUpdateCandidate/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify({ skills: skillsListToPost })
    })
      .then(res => res.json())
      .then(response => {

        if (response.success) {
          ReactSession.set("CandidateForEdit", response.data)
          setShowSpinner(false)
          setShowDoneIcon(true)
          setSkillUpdated(false)

          setTimeout(() => {
            setShowDoneIcon(false)
            window.location.reload()
          }, 1500)
        }
      })
      .catch(error => console.error('There was an error!', error))
  }
  
  return (
    <Card sx={{ maxHeight: "44vh", overflow: "auto" }}>
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2} maxWidth="95%" >
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {skillsListToShow.map(({ name, percent, color }) =>
            <SkillSlider
              name={name}
              percent={percent}
              color={color}
              skillsListToShow={skillsListToShow}
              setSkillsListToshow={setSkillsListToshow}
              skillsListToPost={skillsListToPost}
              setSkillsListToPost={setSkillsListToPost}
              returAsYears={returAsYears}
              setSkillUpdated={setSkillUpdated}
            />
          )}
        </SuiBox>

        <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
            <SuiButton
              sx={{ mx: 1 }}
              fullWidth
              color="info"
              variant="outlined"
              onClick={() => setShowAddSkillModal(true)}
            >
              Add skill
            </SuiButton>

            {/* eslint-disable-next-line */}
            {showSpinner ?
              <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
              :
              showDoneIcon ?
                  <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                  :
                  null
            }
            {!showSpinner && skillUpdated ?
              <SuiButton
                sx={{ mx: 1 }}
                fullWidth
                color="info"
                variant="gradient"
                onClick={() => saveChanges()}
              >
                Save changes
              </SuiButton>
            : null }

        </SuiBox>
        
        {showAddSkillModal && (
          <AddSkillModal 
            showAddSkillModal={showAddSkillModal} 
            setShowAddSkillModal={setShowAddSkillModal} 
            skillsListToShow={skillsListToShow}
            setSkillsListToshow={setSkillsListToshow}
            skillsListToPost={skillsListToPost}
            setSkillsListToPost={setSkillsListToPost}
            returAsYears={returAsYears}
            getSkillValue={getSkillValue}
            setSkillUpdated={setSkillUpdated}
          />
        )}
      </SuiBox>
    </Card>
  );
}

ProfilesList.defaultProps = {
  comment: "",
  candidateId: 0,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  comment: PropTypes.string,
  candidateId: PropTypes.number
};

export default ProfilesList;
