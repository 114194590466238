/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import $ from 'jquery';
// sui components
import SuiDatePicker from "components/SuiDatePicker";
import SuiSelect from "components/SuiSelect";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";
// Images
import MockCandidateAvatar from  "assets/images/mockCandidateAvatar.png"

function Basic({ formData, updateUser, selectedCountry, setSelectedCountry, setSelectedDateOfBirth }) {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const recruiter = ReactSession.get("recruiter");
  const { formField, values, errors, touched } = formData;
  const { firstName, lastName, rol, photo } = formField;

  const {
    firstName: firstNameV,
    lastName: lastNameV,
    rol: rolV,
    photo: photoV,
  } = values;


  const [countryPlaceholder, setCountryPlaceholder] = useState("Select Country");
  const [profileImage, setProfileImage] = useState("");
  const [countries, setCountries] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null); // eslint-disable-line

  let timerid = "";
  if (timerid)clearTimeout(timerid)

  useEffect(() => {
    if (recruiter?.user?.photo) {
      setProfileImage(`${process.env.REACT_APP_API_ROUTE}${recruiter?.user?.photo}`)
    } else {
      setProfileImage(MockCandidateAvatar)
    }
    $("input[name=phone]").attr("placeholder", recruiter.phone ? recruiter.phone : "+1 555 555");
    $("input[name=dateOfBirth]").attr("placeholder", recruiter.dateOfBirth ? recruiter.dateOfBirth : "YYYY-DD-MM");
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/getAllCountries/`)
      .then(async response => {
        const data = await response.json();
        const newData = [];
        // eslint-disable-next-line
        Object.keys(data.data).forEach(function (key) {
          newData.push({ value: key, label: data.data[key] });
          if (data.data[key] === selectedCountry) {
            setDefaultSelectValue({ value: key, label: data.data[key] })
            setCountryPlaceholder(data.data[key]);
          }
        });
        setCountries(newData);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    // setCountries([]);
  }, []);

  function changeCountry(e) {
    $(".countryBox").attr("data-val", e.value);
    setSelectedCountry(e.value)
  }

  function editImageClick() { $("input[name=file]").click() }

  function editImage() {

    const imageField = $("input[name=file]").prop('files')[0];
    const formPostData = new FormData();
    formPostData.append('file', imageField);
    // formPostData.append('candidate', candidateId);

    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/recruiters/uploadPhoto/`;

    const requestMetadata = {
      method: 'POST',
      headers: {
        'Authorization': `Token ${token}`
      },
      body: formPostData
    };


    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {

        if (response.success) {
          setProfileImage(`${process.env.REACT_APP_API_ROUTE}${response.data.user.photo}`);
          const sessionRecruiter = {...ReactSession.get("recruiter")};
          sessionRecruiter.user.photo = response.data.user.photo;
          ReactSession.set("recruiter", sessionRecruiter)

          if (timerid) {
            clearTimeout(timerid);
          }

          timerid = setTimeout(() => {
            $(".MuiAvatar-img").attr("src", `${process.env.REACT_APP_API_ROUTE}${response.data.user.photo}`);
          }, 200);
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  const [showEmptyFieldsMsg, setShowEmptyFieldsMsg] = useState(false)

  const verifyBasicInputs = () => {
    if (firstNameV.length > 0 && !errors.firstName && lastNameV.length > 0 && !errors.lastName) updateUser(values)
    else {
      setShowEmptyFieldsMsg(true)
      setTimeout(() => setShowEmptyFieldsMsg(false), 3000)
    }
  }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={1} mb={4}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5" fontWeight="regular">basic information</SuiTypography>
        </SuiBox>
      </SuiBox>

      <SuiBox mt={2}>
        {showEmptyFieldsMsg && <SuiAlert color="secondary" className="alertError">Please fill all the required fields in this section before continuing.</SuiAlert>}

        <Grid container spacing={3}>

          <Grid item xs={12} sm={4} container justifyContent="center">
            <SuiBox position="relative" height="max-content" mx="auto">
              <SuiAvatar src={profileImage} alt="profile picture" size="xxl" variant="rounded" />
              <SuiBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                <SuiButton variant="gradient" color="light" size="small" iconOnly className="editImage" onClick={editImageClick}>
                  <Icon>edit</Icon>
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Grid display="none">
              <FormField
                type={photo.type}
                label={photo.label}
                name="file"
                value={photoV}
                placeholder={photo.placeholder}
                error={errors.image && touched.photo}
                success={photoV.length > 0 && !errors.photo}
                onChange={editImage}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>First name</SuiTypography>
              <FormField
                type={firstName.type}
                label={firstName.label}
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={firstNameV.length > 0 && !errors.firstName}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Last name</SuiTypography>
              <FormField
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                error={errors.lastName && touched.lastName}
                success={lastNameV.length > 0 && !errors.lastName}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Role</SuiTypography>
              <FormField
                type={rol.type}
                label={rol.label}
                name={rol.name}
                value={rolV}
                placeholder={rol.placeholder}
                error={errors.rol && touched.rol}
                success={rolV.length > 0 && !errors.rol}
              />
            </Grid>

            <Grid item xs={12} sm={12} className="countryBox">
              <SuiTypography variant="button" ml={1}>
                Where are you located?
              </SuiTypography>
              <SuiSelect
                placeholder={countryPlaceholder}
                options={countries}
                defaultValue={defaultSelectValue}
                name="country"
                onChange={changeCountry}
                className="test1"
              />
              <br />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1}>
                Date of birth
              </SuiTypography>
              <SuiDatePicker
                input={{ placeholder: "Date of Birth (optional)", name: "dateOfBirth" }}
                name="dateOfBirth"
                defaultValue={recruiter?.dateOfBirth}
                onChange={e => {
                  setSelectedDateOfBirth(new Date(e).toISOString().split('T')[0])
                }}  
              />
            </Grid>

          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="flex-end">
        <SuiButton variant="gradient" color="info" onClick={() => verifyBasicInputs()} className="changeStep nextStep" isSubmitting>Submit</SuiButton>
      </SuiBox>

    </SuiBox>
  );
}

// typechecking props for Basic
Basic.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  updateUser: PropTypes.func.isRequired,
  selectedCountry: PropTypes.string.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedDateOfBirth: PropTypes.func.isRequired,
};

export default Basic;
