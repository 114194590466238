/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react'
import { ReactSession } from 'react-client-session'
import Swal from 'sweetalert2'
// @mui material components
import useMediaQuery from '@mui/material/useMediaQuery'
// import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import CloseIcon from '@mui/icons-material/Close'
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
// Soft UI Dashboard PRO React example components
import BaseLayout from 'layouts/pages/account/components/BaseLayout'
import StudentDashboardLayout from 'examples/LayoutContainers/StudentDashboardLayout'

function SavedLessons() {

    const token = ReactSession.get('token')
    const student = ReactSession.get('student')

    const [isFetchingData, setIsFetchingData] = useState(true)
    const [candidateSavedLessons, setCandidateSavedLessons] = useState([])

    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')

    const fetchSavedLessons = async () => {
        await fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/getSavedLessons/`, {
            headers: { 'Authorization': `Token ${token}` }
        })
            .then(async response => {
                const data = await response.json()
                setIsFetchingData(false)
                if (data.success) setCandidateSavedLessons(data.data)
            })
            .catch(error => console.error('There was an error!', error))
    }

    useEffect(() => { fetchSavedLessons() }, [])

    const redirectToLesson = lesson => {
        if (student) {
            window.location.replace(`/dashboard/courses/${lesson.courseId}/${lesson.moduleId}/${lesson.pk}`);
        } else {
            window.location.replace(`/t/dashboard/courses/${lesson.courseId}/${lesson.moduleId}/${lesson.pk}`);
        } 
    }

    /* SAVE LESSON */
    const [lessonBeingRemoved, setLessonBeingRemoved] = useState(null)

    const showRemoveLessonSuccess = () => {
        Swal.fire({
        icon: 'success',
        title: 'Lesson removed correctly!',
        text: ''
        })
    }
    
    const showRemoveLessonError = () => {
        Swal.fire({
        icon: 'error',
        title: 'An error happened, please try again later.',
        text: ''
        })
    }

    const removeSavedLesson = async (lessonId) => {
        setLessonBeingRemoved(lessonId)

        await fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/removeSavedLesson/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ lessonId })
        })
            .then(async response => {
                const data = await response.json()
                setLessonBeingRemoved(null)
      
                if (data.success) {
                    showRemoveLessonSuccess()
                    setCandidateSavedLessons(prev => prev.filter(item => item.pk !== lessonId))
                }
                else showRemoveLessonError()
            })

            .catch(error => {console.
              error('There was an error!', error)
              showRemoveLessonError()
            })
    }

    function secondsToMinutes(seconds) {
        if (!seconds || seconds <= 0) return ''

        let minutesToPrint = Math.floor(seconds / 60);
        let secondsToPrint = Math.floor(seconds % 60);

        minutesToPrint = minutesToPrint < 10 ? `0${minutesToPrint}` : `${minutesToPrint}`;
        secondsToPrint = secondsToPrint < 10 ? `0${secondsToPrint}` : `${secondsToPrint}`;

        return `${minutesToPrint}:${secondsToPrint}`
    }

    const setLayout = children => {
        if (student) return <StudentDashboardLayout>{children}</StudentDashboardLayout>
        return <BaseLayout>{children}</BaseLayout>
    }

    return (
        <>
            {setLayout(
                <>
                    <SuiTypography variant='h3' fontWeight='bold' mt={0} mb={1}>Saved lessons</SuiTypography>
                    <SuiTypography variant='h5' fontWeight='regular' color='secondary' mb={5}>
                        Here you can find all the lessons you have saved for easy access
                    </SuiTypography>

                    {/* LESSON */}
                    {isFetchingData ?
                        <SuiBox mt={3} sx={{ height: '80vh' }} display='flex' justifyContent='center' alignItems='center'>
                            <CircularProgress />
                        </SuiBox>
                        :
                        <>
                            {candidateSavedLessons.length === 0 ?
                                <SuiTypography variant='h4' fontWeight='regular' color='secondary' mt={30} textAlign='center'>
                                    You don&apos;t have any saved lessons yet.
                                </SuiTypography>
                            :
                                <Accordion className="customAccordion" key="lessons" TransitionProps={{ unmountOnExit: false }} defaultExpanded>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      sx={{
                                        margin: "0 !important"
                                      }}
                                    >
                                      <SuiBox
                                        sx={{
                                          display: 'flex',
                                          width: '100%',
                                          flexDirection: isMobile ? 'column' : 'row',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          position: 'relative',
                                        }}
                                      >
                                        {/* TITLE */}
                                        <SuiBox display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-start' p={isMobile ? 0: 2} sx={{ maxWidth: "calc(100% - 150px)" }}>
                                          <SuiBox display='flex' justifyContent='center' alignItems='center'>
                                            <SuiBox display='flex' flexDirection='column' ml={1.5}>
                                              <SuiTypography variant='h5'>Lessons</SuiTypography>
                                            </SuiBox>
                                          </SuiBox>
                                        </SuiBox>
                                      </SuiBox>
                                    </AccordionSummary>
                                    
                                    <AccordionDetails sx={{ width: '100%', mx: 'auto', padding: "0" }}>
                                      {/* MODULE */}
                                      {candidateSavedLessons.map(lesson => { // eslint-disable-line
                                        return (
                                          <Accordion key={lesson.pk} TransitionProps={{ unmountOnExit: false }} sx={{ boxShadow: 'none' }}>
                                            <AccordionSummary
                                              sx={{
                                                my: 0,
                                                cursor: 'pointer',
                                                border: '1px solid rgba(0, 0, 0, .125)',
                                              }}
                                            >
                                              <SuiBox
                                                sx={{
                                                  display: 'flex',
                                                  width: '100%',
                                                  flexDirection: isMobile ? 'column' : 'row',
                                                  justifyContent: 'space-between',
                                                  alignItems: 'center',
                                                  position: 'relative'
                                                }}
                                              >
                                                {/* TITLE */}
                                                <SuiBox display='block' flexDirection='column' justifyContent='space-between' alignItems='flex-start' p={isMobile ? 0 : 2} sx={{ width: "100%", paddingRight: "30px", position: "relative" }}>
                                                  <SuiTypography variant='h6' onClick={() => redirectToLesson(lesson)}>
                                                    {lesson?.lesson?.completed ? (
                                                      <CheckCircleIcon sx={{ mr: 1, verticalAlign: "middle", color: "#008a2f" }} />
                                                    ) : (
                                                      <RadioButtonUncheckedIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                                                    )}
                                                    <SuiTypography sx={{ display: "inline-block !important", verticalAlign: "middle", fontSize: "1rem", fontWeight: "500" }}>
                                                        {lesson?.lesson?.lesson?.title}
                                                    </SuiTypography>
                                                    <SuiTypography sx={{ float: "right", fontSize: "14px", marginRight: "50px", marginTop: "3px" }}>{secondsToMinutes(lesson?.lesson?.lesson?.duration)}</SuiTypography>
                                                  </SuiTypography>
                                                {lessonBeingRemoved === lesson.pk ?
                                                    <SuiBox display='flex' justifyContent='center' alignItems='center' sx={{ position: "absolute", right: "5px", top: "20px" }} ><CircularProgress size={20} /></SuiBox>
                                                    :
                                                    <Tooltip title='Remove lesson' placement='top' onClick={() => removeSavedLesson(lesson.lesson.pk)}>
                                                        <CloseIcon sx={{ position: "absolute", right: "7px", top: "20px" }} />
                                                    </Tooltip>
                                                }
                                                </SuiBox>
                                              </SuiBox>
                                            </AccordionSummary>
                                          </Accordion>
                                        )
                                      })}
                                    </AccordionDetails>
                                  </Accordion>
                            }
                        </>
                    }
                </>
            )}
        </>
    )
}

export default SavedLessons
