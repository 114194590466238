/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
// formik components
import { ErrorMessage, Field } from "formik";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

function FormField({ name, ...rest }) {
  return (
    <SuiBox>
      <Field {...rest} name={name} as={SuiInput} />
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </SuiTypography>
      </SuiBox>
    </SuiBox >
  );
}

// typechecking props for FormField
FormField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormField;
