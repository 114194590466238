/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import NameAndPictureCell from "layouts/pages/searchCompany/components/NameAndPictureCell/nameAndPictureCell";
import ActionCell from "layouts/pages/searchCompany/components/actionCell/actionCell";
import ManagersCell from "layouts/pages/searchCompany/components/ManagersCell/managersCell";
import SuiBox from "components/SuiBox";

export default {
  columns: [
    {
      Header: "Company",
      accessor: "company",
      width: "50%",
      Cell: ({ value: [name, data, url] }) => <NameAndPictureCell image={data} name={name} url={url} />,
    },
    {
      Header: "Manager(s)",
      accessor: "managers",
      disableSortBy: true,
      Cell: ({ value: data }) => <ManagersCell managers={data} />,
    },
    { Header: "Open jobs", accessor: "openjobs" },
    { Header: "Paused jobs", accessor: "pausedjobs" },
    { Header: "Closed jobs", accessor: "closedjobs" },
    { Header: "Contract type", accessor: "contractType" },
    {
      Header: "Action", accessor: "action",
      Cell: ({ value: data }) => (
        <SuiBox display="flex">
          <ActionCell companyId={data.companyId}/>
          <ActionCell managerId={data.companyId} isManaged={data.isManaged} openManagerModal={data.openManagerModal} />
          <ActionCell companyJobs={data.companyId}/>
          <ActionCell company={data.company} setCompanyForSalesModal={data.setCompanyForSalesModal} />
        </SuiBox>
      ),
    },
  ],

  rows: [],
};
