/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

// Settings page components
import StudentDashboardLayout from "examples/LayoutContainers/StudentDashboardLayout";
// import Sidenav from "layouts/pages/account/settings/components/Sidenav";
// import Header from "layouts/pages/account/settings/components/Header";
// import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings-student/components/ChangePassword";
// import Authentication from "layouts/pages/account/settings/components/Authentication";
// import Accounts from "layouts/pages/account/settings/components/Accounts";
// import Sessions from "layouts/pages/account/settings/components/Sessions";
// import DeleteAccount from "layouts/pages/account/settings-candidate/components/DeleteAccount";

import SuiAlert from "components/SuiAlert";

import { ReactSession } from 'react-client-session';

// validation
import { Formik, Form } from "formik";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";



function Settings() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const postToken = `Token ${token}`;
  const currentValidation = validations[3];
  const { formId, formField } = form;

  useEffect(() => {
    document.title = `Settings | Boost`;
  }, []);

  const handleSubmit = (values, actions) => {
    actions.setTouched({});
  };

  function userLogout() {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/logout/`;
    const postBody = {};
    const requestMetadata = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': postToken
      },
      body: JSON.stringify(postBody)
    };
    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        ReactSession.set("user", null);
        ReactSession.set("candidate", null);
        ReactSession.set("token", null);
        ReactSession.set("student", null);
        ReactSession.set("candidateDashboardActiveTab", null)
        window.location.replace("/login");
        console.log(response)
      });
  }

  return (
    <StudentDashboardLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={10}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SuiBox className="alertErrorBox" display="none">
                    <SuiAlert color="secondary" className="alertError">error.</SuiAlert>
                  </SuiBox>
                  <SuiBox className="alertSuccessBox" display="none">
                    <SuiAlert color="success" className="alertSuccess">Password Changed.</SuiAlert>
                  </SuiBox>
                  <SuiBox className="alertInfoBox" display="none">
                    <SuiAlert color="info" className="alertSuccess">Please enter the confirmation code sent to your new email.</SuiAlert>
                  </SuiBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                      >
                        {({ values, errors, touched, issubmitting }) => (
                          <Form id={formId} autoComplete="off" type="POST" actions="#" issubmitting={issubmitting}>
                            <ChangePassword formData={{ values, touched, formField, errors }} />
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Card >
                    <SuiBox
                      p={3}
                      display="flex"
                      justifyContent="end"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <SuiBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <SuiTypography variant="button" color="text" fontWeight="regular" mr={2}>
                          Close your session
                        </SuiTypography>
                        <SuiButton variant="outlined" color="error" sx={{ height: "100%" }} onClick={userLogout} >
                          Log out
                        </SuiButton>
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
        </Grid >
      </SuiBox >
    </StudentDashboardLayout >
  );
}

export default Settings;
