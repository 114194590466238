/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "recruiter-edit-application-form",
  formField: {
    firstName: {
      name: "firstName",
      type: "text",
      placeholder: "First Name",
      errorMsg: "First name is required.",
      tooShort: "Your first name should be more than 1 character.",
    },
    lastName: {
      name: "lastName",
      type: "text",
      placeholder: "Last Name",
      errorMsg: "Last name is required.",
      tooShort: "Your last name should be more than 1 character.",
    },
    email: {
      name: "email",
      type: "email",
      placeholder: "Email Address",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid.",
      invalidDomainMsg: "Use a valid business email domain.",
    },
    country: {
      name: "country",
      label: "country",
      type: "text",
      placeholder: "eg. Japan",
      errorMsg: "Country is required.",
    },
    phone: {
      name: "phone",
      label: "Phone Number",
      type: "tel",
      placeholder: "Phone Number",
      errorMsg: "Phone Number is required.",
      invalidMsg: "Phone number is not valid.",
    },
    resume: {
      name: "resume",
      label: "Resume or CV",
      type: "file",
      placeholder: "Resume or CV",
      errorMsg: "Resume or CV is required.",
    },
    linkedin: {
      name: "linkedin",
      type: "text",
      label: "Linkedin profile",
      placeholder: "Linkedin profile"
    },
    github: {
      name: "github",
      type: "text",
      label: "Github profile",
      placeholder: "Github profile",
    },
    portfolio: {
      name: "portfolio",
      type: "text",
      label: "Porfolio url",
      placeholder: "Porfolio url",
    },
    website: {
      name: "website",
      type: "text",
      label: "Website url",
      placeholder: "Website url",
    },
    twitter: {
      name: "twitter",
      type: "text",
      label: "Twitter profile",
      placeholder: "Twitter profile",
    },
  },
};
