/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "layouts/pages/users/new-user/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    email,
    company,
    phone,
    password,
    repeatPassword,
    passwordConfirm,
    address1,
    city,
    zip,
    bio,
    linkedin,
    twitter,
    facebook,
    instagram,
    youtube,
    github,
    gitlab,
    stackoverflow,
    portfolio,
    website,
    code
  },
} = checkout;

const validEmailDomains = ["aol.com", "att.net", "comcast.net", "facebook.com", "gmail.com", "gmx.com", "googlemail.com", "google.com", "hotmail.com", "hotmail.co.uk", "mac.com", "me.com", "mail.com", "msn.com", "live.com", "sbcglobal.net", "verizon.net", "yahoo.com", "yahoo.co.uk", "email.com", "fastmail.fm", "games.com", "gmx.net", "hush.com", "hushmail.com", "icloud.com", "iname.com", "inbox.com", "lavabit.com", "love.com", "outlook.com", "pobox.com", "protonmail.com", "rocketmail.com", "safe-mail.net", "wow.com", "ygm.com", "ymail.com", "zoho.com", "yandex.com", "bellsouth.net", "charter.net", "cox.net", "earthlink.net", "juno.com", "btinternet.com", "virginmedia.com", "blueyonder.co.uk", "freeserve.co.uk", "live.co.uk", "ntlworld.com", "o2.co.uk", "orange.net", "sky.com", "talktalk.co.uk", "tiscali.co.uk", "virgin.net", "wanadoo.co.uk", "bt.com", "sina.com", "sina.cn", "qq.com", "naver.com", "hanmail.net", "daum.net", "nate.com", "yahoo.co.jp", "yahoo.co.kr", "yahoo.co.id", "yahoo.co.in", "yahoo.com.sg", "yahoo.com.ph", "163.com", "126.com", "aliyun.com", "foxmail.com", "hotmail.fr", "live.fr", "laposte.net", "yahoo.fr", "wanadoo.fr", "orange.fr", "gmx.fr", "sfr.fr", "neuf.fr", "free.fr", "gmx.de", "hotmail.de", "live.de", "online.de", "t-online.de", "web.de", "yahoo.de", "libero.it", "virgilio.it", "hotmail.it", "aol.it", "mail.ru", "rambler.ru", "yandex.ru", "ya.ru", "list.ru", "hotmail.be", "live.be", "skynet.be", "voo.be", "tvcablenet.be", "telenet.be", "hotmail.com.ar", "live.com.ar", "yahoo.com.ar", "fibertel.com.ar", "speedy.com.ar", "arnet.com.ar", "yahoo.com.mx", "live.com.mx", "hotmail.es", "hotmail.com.mx", "prodigy.net.mx", "yahoo.com.br", "hotmail.com.br", "outlook.com.br", "uol.com.br", "bol.com.br", "terra.com.br", "ig.com.br", "itelefonica.com.br", "r7.com", "zipmail.com.br", "globo.com", "globomail.com", "oi.com.br"]


export default [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg).matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Only alphabets are allowed for this field ").min(1, firstName.tooShort),
    [lastName.name]: Yup.string().required(lastName.errorMsg).matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Only alphabets are allowed for this field ").min(1, lastName.tooShort),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [company.name]: Yup.string().required(company.errorMsg),
    [phone.name]: Yup.string().min(8, phone.invalidMsg).max(15, phone.invalidMsg).matches(/^[0-9]*$/g, "Only numbers without spaces are allowed for this field."),
    [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg),
    [bio.name]: Yup.string().max(500, bio.tooBig),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
    [passwordConfirm.name]: Yup.string()
      .required(passwordConfirm.errorMsg)
      .oneOf([Yup.ref("password"), null], passwordConfirm.invalidMsg),
    [linkedin.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.linkedin.com/"'), // eslint-disable-line
    [twitter.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.twitter.com/"'), // eslint-disable-line
    [youtube.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.youtube.com/"'), // eslint-disable-line
    [github.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.github.com/"'), // eslint-disable-line
    [gitlab.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.gitlab.com/"'), // eslint-disable-line
    [stackoverflow.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.stackoverflow.com/"'), // eslint-disable-line
    [portfolio.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.portfolio.com/"'), // eslint-disable-line
    [website.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.website.com/"'), // eslint-disable-line
    [facebook.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.facebook.com/"'), // eslint-disable-line
    [instagram.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.instagram.com/"'), // eslint-disable-line 
    [code.name]: Yup.string().required(code.errorMsg).max(6, "Invalid code"), // eslint-disable-line 
  }),
  Yup.object().shape({
    [address1.name]: Yup.string().required(address1.errorMsg),
    [city.name]: Yup.string().required(city.errorMsg),
    [zip.name]: Yup.string().required(zip.errorMsg).min(6, zip.invalidMsg),
    [bio.name]: Yup.string().max(2000, bio.tooBig),
  }),
  Yup.object().shape({
    [twitter.name]: Yup.string().required(twitter.errorMsg),
    [linkedin.name]: Yup.string().required(linkedin.errorMsg),
    [bio.name]: Yup.string().max(2000, bio.tooBig),
  }),
  Yup.object().shape({
    [password.name]: Yup.string().required(password.errorMsg),
    [repeatPassword.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
  }),
  Yup.object().shape({
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg).test('validBusinessDomain', email.invalidDomainMsg,
      value => validEmailDomains.indexOf(value?.substr(value.indexOf("@") + 1)) === -1)
  }),
];
