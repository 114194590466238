import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { ReactSession } from 'react-client-session'
// @mui material components
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import SuiSelect from 'components/SuiSelect'
import SuiAlert from 'components/SuiAlert'

import SkillsPicker from '../addNewForm/SkillsPicker'
import EnglishLevelEditor from '../addNewForm/EnglishLevelEditor'


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    "&.MuiAccordion-root::before": {
        display: "none",
    },
    borderRadius: "0.5rem",
    marginBottom: "0.5rem"
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    borderRadius: "0.5rem",
    backgroundColor: '#f8f9fa',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
}));

export default function EditForm({
                                    setModalContent,
                                    candidate,
                                    formIdToShow,
                                    setFormIdToShow,
                                    setIsEditingForm,
                                    reFetchCandidateForms,
                                    skillsHaveBeenEdited,
                                    setSkillsHaveBeenEdited,
                                }) {

    const formToShow = candidate.forms.filter(item => item.id === formIdToShow)[0]
    
    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')
    
    ReactSession.setStoreType("localStorage")
    const token = ReactSession.get("token")
    const user = ReactSession.get("user");
    const isRecruiter = (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin")

    const [expanded, setExpanded] = useState([])
    const [rerenderHack, setRerenderHack] = useState(0) // eslint-disable-line

    const handleExpand = itemId => {
        const newExpandedArr = expanded
        if (expanded.includes(itemId)) setExpanded(newExpandedArr.filter(item => item !== itemId))
        else {
            newExpandedArr.push(itemId)
            setExpanded(newExpandedArr)
        }
        setRerenderHack(prev => prev+1)
    };

    // const [formFields, setFormFields] = useState(null)
    const [formResponses, setFormResponses] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showError, setShowError] = useState(false)

    const [showRequiredQuestionsError, setShowRequiredQuestionsError] = useState(false)

    /* =========== SKILLS ===========  */
    const [selectedSkills, setSelectedSkills] = useState([])
    /* =========== ENGLISH LEVEL ===========  */
    const [selectedEnglishLevel, setSelectedEnglishLevel] = useState(null)

    
    useEffect(() => {
        if (formToShow?.questions) {
            const questionsFormat = []
            const questionsIdsArr = []

            formToShow?.questions.forEach(item => {
                questionsFormat.push({ id: item?.question?.id, answer: item?.answer, required: item?.question?.required })
                questionsIdsArr.push(item?.question?.id)
            })

            setFormResponses({
                formInstanceId: formToShow?.id,
                questions: questionsFormat
            })

            /* All question inputs start expanded by default */
            setExpanded(questionsIdsArr)
        }
    }, [])
    
    // Hack to force re-render when text is entered
    // Necessary to show characters count update
    const [updatesCount, setUpdatesCount] = useState(0)

    const updateResponse = (id, value) => {
        const updatedResponses = formResponses
        updatedResponses.questions.filter(item => item?.id === id)[0].answer = value
        setFormResponses(updatedResponses)
        setUpdatesCount(updatesCount+1)
    }

    const renderAnswerType = question => {
        const diffOptions = question?.question?.choice?.split('|')
        const optionsArr = []        
        diffOptions.forEach(item => optionsArr.push({ value: item, label: item }))

        switch (question?.question?.type) {
            case "choice":
                return (
                    <SuiSelect
                        placeholder={question?.question?.required ? "Select one option (required)" : "Select one option"}
                        options={optionsArr}
                        defaultValue={{ value: question?.answer, label: question?.answer }}
                        onChange={e => updateResponse(question?.question?.id, e.value)}
                    />
                )
            case "multiline":
                return (
                    <SuiBox>
                        <SuiInput
                            placeholder={question?.question?.required ? "Type here... (required)" : "Type here..."}
                            multiline
                            rows={5}
                            inputProps={{ maxLength: 2000 }}
                            defaultValue={question?.answer}
                            onChange={e => updateResponse(question?.question?.id, e.target.value)}
                        />
                        <SuiTypography
                            mt={1}
                            variant="subtitle2"
                            color={formResponses?.questions?.filter(item => item?.id === question?.question?.id)[0]?.answer?.length === 2000 ? "error" : "auto"}
                            fontSize=".9rem"
                            align="right"
                        >
                            {formResponses?.questions?.filter(item => item?.id === question?.question?.id)[0]?.answer?.length || "0"}/2000 chars.
                        </SuiTypography>
                    </SuiBox>
                )
            case "line":
                return (
                    <SuiBox>
                        <SuiInput
                            placeholder={question?.question?.required ? "Type here... (required)" : "Type here..."}
                            inputProps={{ maxLength: 2000 }}
                            defaultValue={question?.answer}
                            onChange={e => updateResponse(question?.question?.id, e.target.value)}
                        />
                        <SuiTypography
                            mt={1}
                            variant="subtitle2"
                            color={formResponses?.questions?.filter(item => item?.id === question?.question?.id)[0]?.answer?.length === 2000 ? "error" : "auto"}
                            fontSize=".9rem"
                            align="right"
                        >
                            {formResponses?.questions?.filter(item => item?.id === question?.question?.id)[0]?.answer?.length || "0"}/2000 chars.
                        </SuiTypography>
                    </SuiBox>
                )
            default:
                return null
        }
    }

    const requiredQuestionsAreCompleted = () => {
        // If the question is required and doesnt have an answer, return false
        if (formResponses.questions.filter(item => item?.required && !item?.answer).length) return false
        return true
    }

    const updateForm = () => {
        setIsSubmitting(true)

        if (!requiredQuestionsAreCompleted()) {
            setIsSubmitting(false)
            setShowRequiredQuestionsError(true)
            setTimeout(() => setShowRequiredQuestionsError(false), 3000)
        }

        else {
            // If the question wasn't answered we send (answer: "")
            const postBody = formResponses
            const answeredQuestionsArr = []
            formResponses.questions.forEach(item => answeredQuestionsArr.push({ id: item?.id, answer: item?.answer || "" }))
            postBody.questions = answeredQuestionsArr

            // Skills
            if (skillsHaveBeenEdited) postBody.skills = selectedSkills
            // English level
            if (selectedEnglishLevel) postBody.englishLevel = selectedEnglishLevel
            // Application id

            fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/editForm/`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`,
                },
                body: JSON.stringify(postBody)
                })
                .then(res => res.json())
                .then(response => {
                    if (response.success) {
                        setIsSubmitting(false)
                        reFetchCandidateForms()
                        setFormIdToShow(null)
                        setIsEditingForm(false)
                    }
                    else {
                        setShowError(true)
                        setIsSubmitting(false)
                        setTimeout(() => setShowError(false), 2000)
                    }
                })
                .catch(error => {
                    setShowError(true)
                    setIsSubmitting(false)
                    setTimeout(() => setShowError(false), 2000)
                    console.error('There was an error!', error)
                })
        }
    }

    return (
        <>
            {isRecruiter && (
                <SuiBox display="flex" sx={{ marginBottom: 1.5 }}>
                    <SuiTypography
                        variant="subtitle2"
                        sx={{ cursor: 'pointer', marginRight: .5 }}
                        component="a"
                        href={`/job?id=${formToShow?.application?.job?.id}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {formToShow?.application?.job?.name}
                    </SuiTypography>
                    
                    <SuiTypography
                        variant="subtitle2"
                        sx={{ cursor: 'pointer' }}
                        component="a"
                        href={`/r/company/${formToShow?.application?.job?.company?.id}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        - {formToShow?.application?.job?.company?.name}
                    </SuiTypography>
                </SuiBox>
            )}

            {(!formToShow || isSubmitting )?
                <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center" sx={{ height: "90vh" }}>
                    <CircularProgress size={40} />
                </SuiBox>
                :
                <>  
                    {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                    {/* SKILLS */}
                    <SuiBox mb={1}>
                        <SkillsPicker selectedSkills={selectedSkills} setSelectedSkills={setSelectedSkills} setSkillsHaveBeenEdited={setSkillsHaveBeenEdited}/>
                    </SuiBox>

                    {/* QUESTIONS */}
                    {formToShow?.questions.map(item => (
                        <Accordion
                            key={item?.question?.id}
                            expanded={expanded.includes(item?.question?.id)}
                            onChange={() => handleExpand(item?.question?.id)}
                            sx={{ backgroundColor: "#f8f9fa" }}
                        >
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <SuiTypography variant="subtitle2">{item?.question?.title}</SuiTypography>
                                {item?.question?.required && <SuiTypography variant="overline" ml={2}> *required</SuiTypography>}
                            </AccordionSummary>
                            
                            <AccordionDetails>
                                <SuiBox display="flex" flexWrap="wrap" width="100%" mb={2} flexDirection="column">
                                    <SuiBox bgColor="#f8f9fa" borderRadius="16px" width="100%" p={1} mr={2} >
                                        <SuiTypography variant="body2">{item?.question?.content}</SuiTypography>
                                    </SuiBox>

                                    <SuiBox display="inherit" flexWrap="inherit" width="100%">
                                        <SuiBox width="100%" mt={1} mr={1}>
                                            {renderAnswerType(item)}
                                        </SuiBox>
                                    </SuiBox>
                                </SuiBox>
                            </AccordionDetails>
                        </Accordion>
                    ))}

                    {/* ENGLISH */}
                    <SuiBox mt={1}>
                        <EnglishLevelEditor setSelectedEnglishLevel={setSelectedEnglishLevel} />
                    </SuiBox>

                    <SuiBox my={2} display="flex" justifyContent="center" alignItems="center">
                        <SuiTypography variant="subtitle2" display={showRequiredQuestionsError ? "block" : "none"}>
                            Please fill all the required fields before sending the form
                        </SuiTypography>
                    </SuiBox>

                    <SuiBox mt={1} mx="auto" display="flex" flexWrap="wrap" justifyContent="space-around" alignItems="center" width={isMobile ? "auto" : "40%"}>
                        <SuiButton
                            color="info"
                            sx={{ width: 200, my: .5 }}
                            variant="outlined"
                            onClick={() => {
                                setFormIdToShow(null)
                                setIsEditingForm(false)
                                setModalContent('generalView')
                            }}
                        >
                            Cancel
                        </SuiButton>
                        
                        <SuiButton color="info" sx={{ width: 200, my: .5 }} variant="gradient" onClick={() => updateForm()}>Send form</SuiButton>
                    </SuiBox>
                </>
            }
        </>
    );
}

EditForm.defaultProps = {
    candidateScorecardData: null,
    application: null,
}

EditForm.propTypes = {
    setModalContent: PropTypes.func.isRequired,
    reFetchCandidateForms: PropTypes.func.isRequired,
    candidate: PropTypes.object.isRequired, // eslint-disable-line
    formIdToShow: PropTypes.number.isRequired,
    setFormIdToShow: PropTypes.func.isRequired,
    setIsEditingForm: PropTypes.func.isRequired,
    skillsHaveBeenEdited: PropTypes.bool.isRequired,
    setSkillsHaveBeenEdited: PropTypes.func.isRequired,
    candidateScorecardData: PropTypes.object, // eslint-disable-line
    application: PropTypes.object, // eslint-disable-line
}
