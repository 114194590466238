/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
import { useEffect, useState } from "react"
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import Swal from "sweetalert2";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
// @mui material components
import Grid from "@mui/material/Grid"
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery"
// import Avatar from "@mui/material/Avatar"
// Soft Modal
import Modal from '@mui/material/Modal';
import Checkbox from "@mui/material/Checkbox";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import RecruiterJobCard from "examples/Cards/RecruiterJobCard"
// Images
import placeholder from "assets/images/illustrations/recommended.png";
import mockCandidateAvatar from "assets/images/mockCandidateAvatar.png";
// Modals
import EnableAISourcingModal from 'layouts/dashboards/recruiter/enableAISourcingModal';
import DisableAISourcingModal from 'layouts/dashboards/recruiter/disableAISourcingModal';
import ScreeningTable from './screeningTable/screeningTable';

function RecruiterDashboard() {
  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")
  const user = ReactSession.get("user")
  const recruiter = ReactSession.get("recruiter")
  const notificationsCount = ReactSession.get("notificationsCount") ? ReactSession.get("notificationsCount") : 0;

  ReactSession.set("aiSourcingSelectAll", false)  

  useEffect(() => {
    document.title = `Dashboard | TECLA`;
  }, []);

  const canEnableAISourcing = jobCompanyId => {
    /* Admins can edit everything */
    if (user.type === "tecla_admin") return true
    /* Managers can only edit companies they're assigned to */
    if (user.type === "tecla_manager" && recruiter.companies.filter(comp => comp?.id === jobCompanyId).length) return true
    /* No one else can edit anything */
    return false
  }

  // AutoSourcing modals
  const [showEnableAISourcingModal, setShowEnableAISourcingModal] = useState(false)
  const [showDisableAISourcingModal, setShowDisableAISourcingModal] = useState(false)
  const [jobIdOpen, setJobIdOpen] = useState(null) // Hack to identify the jobId to open the modal
  const [jobManagersOpen, setJobManagersOpen] = useState(null) // Hack to identify the job Recruiters to open the modal

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentJob, setCurrentJob] = useState(0);
  const [managers, setManagers] = useState([]);

  const [jobs, setJobs] = useState([])
  const [updateCompany, setUpdateCompany] = useState(0);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const [loading, setLoading] = useState(true);
  
  function getDifferenceInSeconds(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / 1000;
  }

  function timeAgo(date) {
    const currentDate = new Date();
    const seconds = Math.floor(getDifferenceInSeconds(new Date(date), currentDate));
    const mins = Math.floor(seconds / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    let timePassed = "";
    if (months >= 1) {
      timePassed = `${months}M ago`;
    } else if (days >= 1) {
      timePassed = `${days}d ago`;
    } else if (hours >= 1) {
      timePassed = `${hours}h ago`;
    } else if (mins >= 1) {
      timePassed = `${mins}m ago`;
    } else if (seconds >= 1) {
      timePassed = `${seconds}s ago`;
    }

    return timePassed;
  }

  function dialogResponse(dataJobId, options, url, textConfirm, isClose, isActive, isOpen, declineApplications, createCopy, selectedValue) {
    const data = {};
    let validPost = false;
    if (textConfirm && textConfirm.length) {
      // eslint-disable-next-line
      if ($("input[name=confirm]").val().toLowerCase() === textConfirm.toLowerCase()) {
        validPost = true;
      }
      if ($("textarea:visible").val()) {
        data.closeReason = $("textarea:visible").val();
      }
    } else if (isActive || isClose || isOpen) {
      validPost = true;
    }

    if (declineApplications || declineApplications === 0) {
      data.declineApplications = declineApplications;
    }

    if (createCopy || createCopy === 0) {
      data.createCopy = createCopy;
    }
    
    if (selectedValue && selectedValue !== "no_reason") {
      data.reportCloseReason = selectedValue;
    }

    if (validPost) {
      const postToken = `Token ${token}`;
      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': postToken
        },
        body: JSON.stringify(data)
      };
      fetch(url, requestMetadata)
        .then(res => res.json())
        .then(response => {

          if (response.success) {
            setUpdateCompany(updateCompany + 1);
            if (textConfirm === "RE-OPEN") Swal.fire("This search has been re-opened.", "You can see it in your dashboard with IN-REVIEW status.", "success")
            if (isActive) Swal.fire("Job activated.", "", "success")
          }
        });
    }
  }

  const declineDialog = (dataJobId, options, url, textConfirm, isClose, isActive, isOpen) => Swal.fire(options).then((result) => {
    let declineApplications = null
    if (result.isConfirmed && isClose) {
      declineApplications = 1;
    } else if (result.isDenied && isClose) {
      declineApplications = 0;
    }

    let createCopy = null
    if (result.isConfirmed && isOpen) {
      createCopy = 1;
    } else if (result.isDenied && isOpen) {
      createCopy = 0;
    }

    if (result.isConfirmed || result.isDenied) {
      const selectedValue = $(".swal2-select").val();
      dialogResponse(dataJobId, options, url, textConfirm, isClose, isActive, isOpen, declineApplications, createCopy, selectedValue);
    }
  })

  useEffect(() => {
    if (user.type === "tecla_manager" || user.type === "tecla_admin") {
      const url = `${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiterRecruiters/`;
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      if (token) {
        headers.Authorization = `Token ${token}`;
      }
      fetch(url, { headers, })
        .then(async response => {
          const res = await response.json();
          validateToken(res) // eslint-disable-line
          if (res.success) {
            const newData = [];
            let fullName = "";
            // eslint-disable-next-line
            for (const manager of res.data) {
              fullName = `${manager.user.first_name} ${manager.user.last_name}`;
              newData.push({ value: manager.user.id, label: fullName, checked: false, recruiter: manager.user });
            }

            setManagers(newData);
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, [])

  const fetchRecruiterData = async () => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiter/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
      .then(async response => {
        const data = await response.json()

        if (data.success) {
          ReactSession.set("recruiter", data.data);
          const tempJobs = [...data.data.favoriteJobs];
          let jobSkills = {};
          let count = 0;
          // eslint-disable-next-line
          for (const job of tempJobs) {
            jobSkills = {};
            count = 0;
            // eslint-disable-next-line
            for (const requiredSkill of job.requiredSkills) {
              count += 1;
              jobSkills[`skill${count}`] = requiredSkill.skill.name;
            }
            job.requiredSkills = jobSkills;
          }
          setJobs(tempJobs);
        }
        setLoading(false);
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => { fetchRecruiterData() }, [])

  const [developerFirstMenu, setDeveloperFirstMenu] = useState(null);
  const openDeveloperFirstMenu = (event) => {
    setDeveloperFirstMenu(event.currentTarget);
    const targetId = ($(event.currentTarget).parents(".dashboardJob").attr("data-id"));
    $(".customMenu > .MuiPaper-root").css("display", "none");
    $(".customMenu").css("z-index", "");
    $(`.menu-${targetId} > .MuiPaper-root`).css("display", "block");
    $(`.menu-${targetId}`).css("z-index", "1311");
  }
  const closeDeveloperFirstMenu = () => setDeveloperFirstMenu(null);

  function buildMenu(state, close, status, reopened, jobId, jobCompanyId, jobRecruiters, hasAutoSourcingActive) {
    const menuItems = [];

    /* ONLY ADMINS AND MANGERS CAN SEE DROPDOWN */
    if (user && (user.type === "tecla_admin" || user.type === "tecla_manager")) {

      if (status === "in_review") {
        if (user.type === "tecla_admin" || (user.type === "tecla_manager" && recruiter.companies && recruiter.companies.filter(comp => Number(comp.id) === Number(jobCompanyId)).length)) {
          menuItems.push(<MenuItem className="menuActive" data-id={jobId} onClick={close}>Make active</MenuItem>);
        }
      }
      
      let recruiterString = "";
      if (jobRecruiters && jobRecruiters.length) {
        // eslint-disable-next-line
        for (const jobRecruiter of jobRecruiters) {
          recruiterString = `${recruiterString}${jobRecruiter.id},`;
        }
      }

      if (status !== "closed") {
        menuItems.push(<MenuItem className="menuClose" data-id={jobId} onClick={close}>Close</MenuItem>);
      }

      if (status === "closed" && !reopened) {
        menuItems.push(<MenuItem className="menuReopen" data-id={jobId} onClick={close}>Re-open</MenuItem>);
      }

      menuItems.push(<MenuItem className="menuEdit" data-id={jobId} onClick={close}>Edit</MenuItem>);
      menuItems.push(<MenuItem className="addSourcer" data-id={jobId} data-recruiters={recruiterString} onClick={close}>Assign Recruiters</MenuItem>);

      // AutoSourcing
      /* Admins can edit everything. Managers can only edit companies they're assigned to. */
      if (canEnableAISourcing(jobCompanyId) && status === "active") {
        if (hasAutoSourcingActive) {
          menuItems.push(
            <MenuItem className="disableAutoSourcing" onClick={() => {
              setShowDisableAISourcingModal(true)
              close()
            }}>Disable AI sourcing</MenuItem>
          )
        }
        else {
          menuItems.push(
            <MenuItem className="enableAutoSourcing" onClick={() => {
              setShowEnableAISourcingModal(true)
              close()
            }}>Enable AI sourcing</MenuItem>
          )
        }
      }
    }

    const menuName = `menu-${jobId} customMenu`;
    const renderMenu = (
      <Menu
        className={menuName}
        anchorEl={state}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(state)}
        onClose={close}
        keepMounted
      >
        {menuItems}
      </Menu>
    );

    return renderMenu;
  }

  // eslint-disable-next-line
  $(document).unbind().on("click", ".menuPause, .menuUnpause, .menuReopen, .menuEdit, .menuActive, .menuClose, .addSourcer", function () {
    const dataJobId = $(this).attr("data-id");
    let jobRecruitersString = $(this).attr("data-recruiters");
    if (jobRecruitersString) {
      jobRecruitersString = jobRecruitersString.split(",");
    }
    if ($(this).hasClass("addSourcer")) {
      const jobManagers = [];
      const managersCopy = [...managers];
      // eslint-disable-next-line
      for (const managerCopy of managersCopy) {
        managerCopy.checked = false;
        // eslint-disable-next-line
        for (const jobRecruiterStringId of jobRecruitersString) {
          const recruiterId = Number(jobRecruiterStringId);
          if (recruiterId && recruiterId === managerCopy.value) {
            managerCopy.checked = true;
          }
        }
        jobManagers.push(managerCopy);
      }
      setCurrentJob(Number(dataJobId));
      setManagers(jobManagers);
      handleOpen();
    } else {
      // eslint-disable-next-line
      if ($(this).hasClass("menuEdit")) {
        window.location.replace(`/r/newjob?id=${dataJobId}`);
      } else {
        let title = "Are you sure to pause this search?"
        let text = "Applicants will not be able to see it on their searches.";
        const html = "";
        let url = `${process.env.REACT_APP_API_ROUTE}/api/companies/${dataJobId}/companyPauseJob/`;
        let isClose = false;
        let isActive = false;
        let isOpen = false;
        const textConfirm = "";
        let showCancelButton = false;
        let showDenyButton = false;
        let confirmButtonText = "Confirm";
        let denyButtonText = "";
        if ($(this).hasClass("menuUnpause")) {
          title = "Are you sure to resume this search?"
          text = "Applicants will be able to see it again on their searches.";
        } else if ($(this).hasClass("menuReopen")) {
          isOpen = 1;
          url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${dataJobId}/teclaReopenJob/`;
          title = null
          text = "Do you want to create a NEW copy of this job (without any applications) or just reactivate this same job? (If reactivated the job will have “In review” status).";
          confirmButtonText = "CREATE COPY";
          denyButtonText = "REACTIVATE";
          showCancelButton = true;
          showDenyButton = true;
        } else if ($(this).hasClass("menuClose")) {
          isClose = 1;
          confirmButtonText = "YES, DECLINE";
          showCancelButton = true;
          showDenyButton = true;
          denyButtonText = "NO, KEEP THEM";
          url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${dataJobId}/teclaCloseJob/`;
          title = null
          text = "This job will be closed and won't be visible for candidates. Do you want to also decline all the existing applications for this position?";
        } else if ($(this).hasClass("menuActive")) {
          isActive = true;
          url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${dataJobId}/teclaActivateJob/`;
          title = "Do you want to activate this job?"
          text = "It will be made public and candidates will be able to apply inmediately.";
        }

        const options = {
          title: title || null,
          text: text,
          width: "650",
          height: "auto",
          showCloseButton: true,
          confirmButtonText: confirmButtonText,
          confirmButtonAriaLabel: confirmButtonText,
          focusConfirm: true,
          showCancelButton: showCancelButton,
          cancelButtonText: "Cancel",
          showDenyButton: showDenyButton,
          denyButtonText: denyButtonText,
          allowEscapeKey: "true",
          customClass: {
            confirmButton: "btn btn-custom-size",
            cancelButton: "btn btn-custom-size",
          }
        }

        if (html.length) {
          options.html = html;
        }

        if ($(this).hasClass("menuClose")) {
          options.html = text;
          options.input = "select";
          options.inputOptions = {
            "no_reason": "No close reason selected",
            "client_hired_elsewhere": "Client hired elsewhere",
            "client_closed_search": "Client closed the search",
            "client_unresponsive": "Client unresponsive",
            "client_hired_tecla": "Client hired TECLA candidate",
            "tecla_closed_search": "TECLA closed search due to poor fit with client",
          }
          options.didOpen =  () => {
            $(".swal2-select").css("margin-top", "5px");
            $(".swal2-html-container").html("<span>This job will be closed and won't be visible for candidates.</span></br><br/><span>Select a reason for closing this position</span>")
            $('<div class="swal2-html-container">Do you want to also decline all the existing applications for this position?</div>').insertAfter(".swal2-select");
          }
        }

        declineDialog(dataJobId, options, url, textConfirm, isClose, isActive, isOpen);
      }
    }
  });

  function setManagerCheck(managerCheckId) {
    const tempManagers = [...managers];
    const newManagers = [];
    // eslint-disable-next-line
    for (const manager of tempManagers) {
      if (Number(manager.value) === Number(managerCheckId)) {
        manager.checked = !manager.checked;
      }
      newManagers.push(manager);
    }

    setManagers(newManagers);
  }

  function saveManagers() {
    if (managers && managers.length && currentJob) {
      const recruiters = [];
      const newJobRecruiters = [];
      // eslint-disable-next-line
      for (const manager of managers) {
        if (manager.checked && !recruiters.includes(manager.value)) {
          recruiters.push(manager.value);
          newJobRecruiters.push(manager.recruiter)
        }
      }

      const postBody = {
        jobId: Number(currentJob),
        recruiters: recruiters
      };

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(postBody)
      };

      fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/recruiterJobRecruiters/`, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            const jobsCopy = [...jobs];
            // eslint-disable-next-line
            for (const jobCopy of jobsCopy) {
              if (jobCopy.id === currentJob) {
                jobCopy.recruiters = newJobRecruiters;
              }
            }
            setJobs(jobsCopy);
          }
        })
    }
    handleClose();
  }

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <RecruiterDashboardLayout>
      <SuiBox display="flex" flexWrap="wrap" width="100%" alignItems="center" justifyContent={{ sx: "start", xl: "space-between" }} my={2}>
          <SuiBox lineHeight={1}>
            <SuiTypography variant="h3" fontWeight="bold" className="managerBox">Recruiter Dashboard</SuiTypography>
          </SuiBox>

          <SuiBox lineHeight={1}>
            <SuiBox display="flex" justifyContent="center" alignItems="center">
              <SuiBox>
                <SuiTypography variant="h5" fontWeight="bold" className="managerBox" sx={{ textAlign: "right" }}>Hi {user.first_name}</SuiTypography>
                <SuiTypography variant="h6" className="managerBox">You have ({notificationsCount}) new notifications</SuiTypography>
              </SuiBox>

              <SuiBox
                sx={{
                  ml: 2,
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  backgroundImage: `url(${user.photo ?`${process.env.REACT_APP_API_ROUTE}${user.photo}` : mockCandidateAvatar})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50% 50%",
                }}
              />

            </SuiBox>
          </SuiBox>

        </SuiBox>
      <SuiBox>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <ScreeningTable />
          </Grid>
          
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3} display="flex" justifyContent="center">
              {jobs.length && !loading ? (
                <Grid container spacing={2} xs={12}>
                  {jobs.map(job => (
                    <Grid item xs={12} md={6} className="dashboardJob" data-id={job.id} key={job.id}>
                      <RecruiterJobCard
                        recruiter
                        job={job}
                        by={{ name: job.company.name, date: timeAgo(job.createdAt), logo: job.company.photo }}
                        badge={{ color: "success", label: job.status }}
                        title={job.seniorityName}
                        description={job.description}
                        skills={job.requiredSkills}
                        value={{ type: "$", from: job.minSalary, to: job.maxSalary, method: "Month" }}
                        action={{ type: "internal", route: `/job?id=${job.id}`, label: "Details" }}
                        category={job.category}
                        companyPhoto={job.company.photo}
                        subtitle={job.subtitle}
                        manage={{ type: "internal", route: `/r/manage?id=${job.id}`, label: "Applicants" }}
                        dateTime="16.01.22"
                        members={3}
                        companyId={job.company.id}
                        recruiters={job.recruiters}
                        managers={job.managers}
                        applicationsCounter={job.applicationsCounter}
                        dropdown={{
                          action: openDeveloperFirstMenu,
                          menu: buildMenu(developerFirstMenu, closeDeveloperFirstMenu, job.status, job.reopened, job.id, job.company.id, job.recruiters, job.autoSourcing, job.isHot),
                        }}
                        setJobIdOpen={setJobIdOpen}
                        setJobManagersOpen={setJobManagersOpen}
                        tags={job?.tags}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) :
                <Grid item>
                  {loading ? (
                    <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={20}>
                      <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                    </SuiBox>
                  ) : (
                    <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
                      <img src={placeholder} alt="Couple of documents" width={isMobile ? "100%" : "50%"} />
                      <SuiTypography my={2} variant="h2" textAlign="center" color="dark" fontWeight="bold">There are no jobs here, yet!</SuiTypography>
                      <SuiTypography variant="body2" textAlign="center">Browse new jobs and<br/>your favorites will be saved here.</SuiTypography>
                    </SuiBox>
                  )}
                </Grid>
              }
            </SuiBox>
          </Grid>
        </Grid>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <SuiBox sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: "100vw", md: "40vw", xl: "30vw" },
              height: { xs: "101vh", md: "fit-content" },
              maxHeight: "90%",
              bgcolor: 'background.paper',
              border: 'none',
              boxShadow: 24,
              borderRadius: { xs: 0, md: 4 },
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                <SuiBox display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                  <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Assign Sourcers</SuiTypography>
                </SuiBox>
                <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
              </SuiBox>
              <SuiBox id="modal-modal-description" borderRadius="16px" height="100%" my={2} p={2} sx={{ overflow: "auto" }}>
                {managers.map(manager => (
                  <SuiBox item xs={12} sm={12} style={{ margin: "5px 0", display: "block", width: "100%" }}>
                    <Checkbox data-id={manager.value} className="selectSkill" checked={manager.checked} onChange={() => setManagerCheck(manager.value)} />
                    <SuiTypography
                      variant="button"
                      fontWeight="regular"
                      sx={{ userSelect: "none" }}>
                      {manager.label}
                    </SuiTypography>
                  </SuiBox>
                ))}
              </SuiBox>
              <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
                <SuiButton color="info" onClick={saveManagers} >Save</SuiButton>
              </SuiBox>
            </SuiBox>
        </Modal>

      </SuiBox>

      {/* AutoSourcing modals */}
      {showEnableAISourcingModal && (
        <EnableAISourcingModal
          showEnableAISourcingModal={showEnableAISourcingModal}
          setShowEnableAISourcingModal={setShowEnableAISourcingModal}
          jobId={jobIdOpen}
          jobRecruiters={jobManagersOpen}
        />
      )}

      {showDisableAISourcingModal && (
        <DisableAISourcingModal
          showDisableAISourcingModal={showDisableAISourcingModal}
          setShowDisableAISourcingModal={setShowDisableAISourcingModal}
          jobId={jobIdOpen}
          jobRecruiters={jobManagersOpen}
          fetchRecruiterData={fetchRecruiterData}
        />
      )}

    </RecruiterDashboardLayout >
  )
}

export default RecruiterDashboard
