/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import mockCandidateAvatar from "assets/images/mockCandidateAvatar.png";

function NameAndPictureCell({ image, name }) {

  const fullImage = image ? `${process.env.REACT_APP_API_ROUTE}${image}` : mockCandidateAvatar

  return (
      <SuiBox display="flex" alignItems="center" pr={2}>
        <SuiBox mr={2} sx={{ display: { xs: 'none', md: 'block' } }}>
            <SuiAvatar src={fullImage} alt={name} variant="rounded" size="md" />
        </SuiBox>
        <SuiBox display="flex" flexDirection="column">
          <SuiTypography variant="button" fontWeight="medium">
            {name}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
  );
}

// Typechecking props for the NameAndPictureCell
NameAndPictureCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default NameAndPictureCell;
