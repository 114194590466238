/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from 'prop-types'
import { useState } from 'react'
// MUI components
import { Icon, Modal } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'
// SUI components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiAlert from 'components/SuiAlert'
import SuiEditor from 'components/SuiEditor'
import SuiButton from 'components/SuiButton'
import SuiAvatar from 'components/SuiAvatar'
// Assets
import mockCandidateAvatar from "assets/images/mockCandidateAvatar.png";
import EditNoteModal from './editNoteModal'

function CandidateProfileNotesModal({
                                        openNotesModal,
                                        setOpenNotesModal,
                                        openTagsModal,
                                        setOpenTagsModal,
                                        fullname,
                                        noteHtml,
                                        setNoteHtml,
                                        createNote,
                                        showNotesError,
                                        notes,
                                        setUpdateNotes,
                                        canDeleteNote,
                                        canEditNote,
                                        deleteNote,
                                        recruiters,
                                        setRecruiters, // eslint-disable-line
                                        setRecruiterCheck
                                    }) {

    const [openEditNoteModal, setOpenEditNoteModal] = useState(false)
    const [noteToEdit, setNoteToEdit] = useState(null)

    const editNote = fullNote => {
        setNoteToEdit(fullNote)
        setTimeout(() => setOpenEditNoteModal(true), 500)
    }

    return (
        <>
            <Modal
                open={openNotesModal}
                onClose={() => setOpenNotesModal(false)}
            >
                <SuiBox
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: "100vw", md: "80vw", xl: "60vw" },
                        height: "auto",
                        maxHeight: "80%",
                        overflowY: "auto",
                        bgcolor: 'background.paper',
                        border: 'none',
                        boxShadow: 24,
                        borderRadius: { xs: 2, md: 4 },
                        p: 3,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <SuiBox className="moonLoaderBox" py={20} display="flex" justifyContent="center" alignItems="center" >
                        <CircularProgress />
                    </SuiBox>
                    
                    <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                        <SuiBox display="flex" flexWrap="wrap">
                            <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Notes about&nbsp;</SuiTypography>
                            <SuiTypography variant="h6" fontWeight="bold">{fullname}</SuiTypography>
                        </SuiBox>
                        <Icon onClick={() => setOpenNotesModal(false)} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                    </SuiBox>

                    <SuiBox bgColor="#f8f9fa" borderRadius="16px" height="100%" my={2} sx={{ display: "table" }}>
                        
                        {showNotesError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                            <Table aria-label="simple table">
                                <TableBody style={{ display: "table", width: "100%" }}>
                                {notes?.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '&:last-child th': { width: "4rem" },
                                            '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" },
                                        }}
                                    >
                                    <TableCell component="th" scope="row" size="small">
                                        <SuiBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                            <SuiAvatar
                                                src={row.recruiterAvatar || mockCandidateAvatar}
                                                alt={row.name || "TECLA"}
                                                sx={{ borderRadius: "50%", mr: 1 }} 
                                                variant="rounded"
                                                size="sm"
                                                shadow="sm"
                                            />

                                            <SuiBox>
                                                <SuiTypography variant="body2" color="secondary" sx={{ width: "130px" }}>
                                                    {row.name || "TECLA"}
                                                </SuiTypography>

                                                <SuiTypography variant="body2" color="secondary" sx={{ width: "140px" }}>
                                                    {row.date}
                                                </SuiTypography>
                                            </SuiBox>
                                        </SuiBox>
                                    </TableCell>

                                    <TableCell align="left" size="small" className="tableEditCell" sx={{ paddingRight: "60px !important" }}>
                                        <SuiTypography variant="body2" color="secondary">
                                        <div className="noteContent" dangerouslySetInnerHTML={{ __html: row.message }} style={{ paddingTop: "5px" }} />
                                        </SuiTypography>
                                        { row.recruitersText ? (
                                            <SuiTypography sx={{ fontSize: "13px", color: "#8392ab" }} >tags: {row.recruitersText}</SuiTypography>
                                        ) : null }
                                        
                                        {canDeleteNote(row?.authorId) && (
                                            <DeleteIcon size="large" onClick={() => deleteNote(row.id)}/>
                                        )}

                                        {canEditNote(row?.authorId) && (
                                            <EditIcon size="large" onClick={() => editNote(row)} data-html={row.message} data-extra={row.job} data-id={row.id} sx={{ mr: 3 }} />
                                        )}
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </SuiBox>

                    <SuiBox display="flex" justifyContent="space-between" alignItems="flex-start" p={1}>
                        <SuiBox bgColor="#f8f9fa" borderRadius="16px" height="auto" my={2} sx={{ width: "950px", maxWidth: "calc(100% - 110px)" }}>
                            <SuiEditor height="100%" name="noteHtml" defaultValue={noteHtml} onChange={setNoteHtml} />
                        </SuiBox>
                        <SuiBox sx={{ width: "110px", paddingLeft: "10px" }}>
                            <SuiButton color="info" variant="outlined" sx={{ marginLeft: 1, margin: "25px 5px 15px 5px" }} onClick={() => setOpenTagsModal(true)}>Tags</SuiButton>
                            <SuiButton color="info" variant="gradient" sx={{ marginLeft: 1 }} onClick={createNote}>Send</SuiButton>
                        </SuiBox>
                    </SuiBox>

                </SuiBox>
            </Modal>
            
            {openEditNoteModal && (
                <EditNoteModal
                    openEditNoteModal={openEditNoteModal}
                    setOpenEditNoteModal={setOpenEditNoteModal}
                    noteToEdit={noteToEdit}
                    setNoteToEdit={setNoteToEdit}
                    setUpdateNotes={setUpdateNotes}
                />
            )}

            <Modal open={openTagsModal} onClose={() => setOpenTagsModal(false)}>
                <SuiBox
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: "100vw", md: "40vw", xl: "30vw" },
                        height: { xs: "101vh", md: "fit-content" },
                        maxHeight: "90%",
                        bgcolor: 'background.paper',
                        border: 'none',
                        boxShadow: 24,
                        borderRadius: { xs: 0, md: 4 },
                        p: 3,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                        <SuiBox display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                            <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Select Recruiters to tag</SuiTypography>
                        </SuiBox>
                        <Icon onClick={() => setOpenTagsModal(false)} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                    </SuiBox>

                    <SuiBox id="modal-modal-description" borderRadius="16px" height="100%" my={2} p={2} sx={{ overflow: "auto" }}>
                        {recruiters.map(rec => (
                            <SuiBox item xs={12} sm={12} style={{ margin: "5px 0", display: "block", width: "100%" }}>
                                <Checkbox data-id={rec.id} className="selectSkill" checked={rec.checked} onChange={() => setRecruiterCheck(rec.id)} />
                                <SuiTypography
                                    variant="button"
                                    fontWeight="regular"
                                    sx={{ userSelect: "none" }}
                                >
                                    {rec.display}
                                </SuiTypography>
                            </SuiBox>
                        ))}
                    </SuiBox>
                    <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
                        <SuiButton color="info" onClick={() => setOpenTagsModal(false)}>Save</SuiButton>
                    </SuiBox>
                </SuiBox>
            </Modal>
        </>
    )
}

CandidateProfileNotesModal.defaultProps = {
}

CandidateProfileNotesModal.propTypes = {
    openNotesModal: PropTypes.bool.isRequired,
    setOpenNotesModal: PropTypes.func.isRequired,
    openTagsModal: PropTypes.bool.isRequired,
    setOpenTagsModal: PropTypes.func.isRequired,
    fullname: PropTypes.func.isRequired,
    noteHtml: PropTypes.func.isRequired,
    setNoteHtml: PropTypes.func.isRequired,
    createNote: PropTypes.func.isRequired,
    showNotesError: PropTypes.bool.isRequired,
    notes: PropTypes.func.isRequired,
    setUpdateNotes: PropTypes.func.isRequired,
    canDeleteNote: PropTypes.func.isRequired,
    canEditNote: PropTypes.func.isRequired,
    deleteNote: PropTypes.func.isRequired,
    recruiters: PropTypes.func.isRequired,
    setRecruiters: PropTypes.func.isRequired,
    setRecruiterCheck: PropTypes.func.isRequired,
}

export default CandidateProfileNotesModal