/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
import { ReactSession } from 'react-client-session'
import { useEffect, useState } from 'react'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
// import SuiButton from 'components/SuiButton'
import CustomCourseCard from 'examples/Cards/BlogCards/CustomCourseCard/CustomCourse'
// MUI components
import useMediaQuery from '@mui/material/useMediaQuery'
// Assets
import courseDefault from 'assets/images/courseDefault.jpg'
import NotificationsModal from 'layouts/dashboards/default/levelUpView/notificationsModal/NotificationsModal'

function CoursesCardsContainer({ courses, setCourseSelected }) {

    ReactSession.setStoreType('localStorage')
    const token = ReactSession.get('token')

    const [notificationsToShow, setNotificationsToShow] = useState([])
    const [openNotificationsModal, setOpenNotificationsModal] = useState(false)

    const fetchNotifications = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/getAcademyNotifications/`, {
                headers: { 'Authorization': `Token ${token}` },
            })
            const data = await res.json()
            if (data?.data?.length > 0) setNotificationsToShow(data.data)
            
        } catch (error) {
            console.error('There was an error!', error)
        }
    }

    useEffect(() => { fetchNotifications() }, [])

    useEffect(() => { if (notificationsToShow?.length > 0) setOpenNotificationsModal(true) }, [notificationsToShow])

    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')

    function setCourseSelectedItem (itemSelected) {
        setCourseSelected(itemSelected)
        window.history.pushState({}, "", `/t/dashboard/courses/${itemSelected.pk}`);
        window.scrollTo(0, 0);
    }

    return (
        <>
            <NotificationsModal
                openNotificationsModal={openNotificationsModal}
                setOpenNotificationsModal={setOpenNotificationsModal}
                notifications={notificationsToShow}
            />

            <SuiBox
                sx={{
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : 'auto',
                    alignItems: 'normal',
                    flexWrap: 'wrap',
                    width: "100%"
                }}
            >
                {courses?.map(item => 
                    <SuiBox className="coueseBox" onClick={() => setCourseSelectedItem(item)}>
                        <CustomCourseCard
                            key={item?.pk}
                            image={item?.course?.image ? `${process.env.REACT_APP_API_ROUTE}${item?.course?.image}` : courseDefault}
                            title={item?.course?.title}
                            description={item?.course?.subtitle}
                            progress={item?.lessonsStats?.completedLessons}
                            total={item?.lessonsStats?.totalLessons}
                        />
                    </SuiBox>
                )}
            </SuiBox>
        </>
    )
}

// Typechecking props for the CoursesCardsContainer
CoursesCardsContainer.propTypes = {
    courses: PropTypes.array.isRequired, // eslint-disable-line
    setCourseSelected: PropTypes.func.isRequired
};

export default CoursesCardsContainer