/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react"
import { ReactSession } from "react-client-session"
// formik components and data
import { Formik, Form } from "formik"
import validations from "layouts/applications/recruiter-create-company/formSchemas/validations"
import form from "layouts/applications/recruiter-create-company/formSchemas/form"
import checkout from "layouts/applications/company-wizard/formSchemas/form"
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import SuiAlert from "components/SuiAlert"
import Swal from "sweetalert2"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
// Wizard page components
import Basic from "layouts/applications/recruiter-create-company/components/Basic"
import Interests from "layouts/applications/recruiter-create-company/components/Interests"
import HighLights from "layouts/applications/recruiter-create-company/components/Highlights/highlights"

const getSteps = () => ["Basic", "Highlights", "Industries"]

let setStep = true
function RecruiterCompanyWizard() {
  // Validation
  ReactSession.setStoreType("localStorage")
  // const user = ReactSession.get("user")
  const token = ReactSession.get("token")
  const postToken = `Token ${token}`

  const searchParams = new URLSearchParams(window.location.search)
  let urlStep = searchParams.get('step')

  /* State to store highlights wizard step information */
  const [selectedCountry, setSelectedCountry] = useState("")
  const [selectedCompanySize, setSelectedCompanySize] = useState("")
  const [highlights, setHighlights] = useState("")
  const [whyWorkHere, setWhyWorkHere] = useState("")
  const [culture, setCulture] = useState("")
  const [perks, setPerks] = useState("")
  const [bioValue, setBioValue] = useState("<br/><br/><br/><br/>");

  const [activeStep, setActiveStep] = useState(0)

  if (urlStep && setStep) {
    setStep = false
    urlStep = Number(urlStep)
    setActiveStep(urlStep)
  }

  const steps = getSteps()
  const { formId, formField } = form
  const currentValidation = validations[activeStep]
  // let validPost = true

  const isLastStep = activeStep === steps.length - 1

  const handleSubmit = (values, actions) => {
    if (isLastStep) console.log("submit")
    else actions.setTouched({})
  }
  // eslint-disable-next-line
  const handleNext = () => setActiveStep(activeStep + 1)

  function getTech(techString) {
    const techs = JSON.parse($(".techsBox").attr("data-techs"));
    let data = null;
    // eslint-disable-next-line
    $.each(techs, function (i, val) {
      if (techString === val.name) {
        data = { "id": val.id, "name": val.name };
      }
    });

    return data;
  }

  /* Function to show input validation error on each wizard step */
  function showError() {
    let timerSuccessid = "";
    $(".alertErrorBox").show();
    $('html, body').animate({
      scrollTop: $(".alertErrorBox").offset().top
    }, 100);
    if (timerSuccessid) {
      clearTimeout(timerSuccessid);
    }
    timerSuccessid = setTimeout(() => {
      $(".alertErrorBox").hide();
    }, 5000);
  }

  // eslint-disable-next-line
  function updateUser(formValues) {
    console.log('updateUser call')

    // if ($("form .MuiTypography-caption:visible").text().length === 0 && user && validPost) {
    // validPost = false;
    // eslint-disable-next-line
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/teclaCreateCompany/`;
    const postBody = {};

    if ($(".techsBox .react-tag-input__tag__content:visible").length) {
      const techArray = [];
      // eslint-disable-next-line
      $.each($(".techsBox .react-tag-input__tag__content:visible"), function (i, val) {
        const tech = getTech($(val).text());
        if (tech) {
          techArray.push(tech);
        }
        if (techArray.length) {
          postBody.industries = techArray;
        }
      });
    }

    postBody.company = formValues.companyName

    postBody.first_name = formValues.companyFirstName
    postBody.last_name = formValues.companyLastName
    postBody.email = formValues.companyMail
    postBody.password = formValues.companyPassword
    postBody.password_confirmation = formValues.companyPassword

    if (selectedCountry) postBody.country = selectedCountry
    if (selectedCompanySize) postBody.size = selectedCompanySize

    postBody.description = bioValue
    postBody.website = formValues.website
    postBody.youtube = formValues.youtube
    postBody.linkedin = formValues.linkedin
    postBody.twitter = formValues.twitter
    postBody.highlights = highlights
    postBody.whyWorkHere = whyWorkHere
    postBody.culture = culture
    postBody.perks = perks

    if (Object.keys(postBody).length > 0) {
      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': postToken
        },
        body: JSON.stringify(postBody)
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {

          // validPost = true
          if (response.success) {
            // eslint-disable-next-line
            if (activeStep === 0) {
              handleNext()
            } else {
              Swal.fire("Company created succesfully", "", "success");
              let timerFinishid = "";
              if (timerFinishid) {
                clearTimeout(timerFinishid);
              }
              timerFinishid = setTimeout(() => {
                window.location.replace(`/r/company/${response.data.company.id}`)
              }, 3000);
            }
          } else {
            Swal.fire("There was a problem creating the company", "", "error");
          }
        })
    } else if (activeStep === 0) {
      handleNext()
    } else {
      // window.location.replace(`/r/company/${response.data.id}`)
      console.log("error")
    }

    // }

  }

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <Basic
          formData={formData}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          showError={showError}
          bioValue={bioValue}
          setBioValue={setBioValue}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          selectedCompanySize={selectedCompanySize}
          setSelectedCompanySize={setSelectedCompanySize}
        />
      case 1:
        return <HighLights
          formData={formData}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          highlights={highlights}
          setHighlights={setHighlights}
          whyWorkHere={whyWorkHere}
          setWhyWorkHere={setWhyWorkHere}
          culture={culture}
          setCulture={setCulture}
          perks={perks}
          setPerks={setPerks}
        />
      case 2:
        return <Interests
          formData={formData}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          updateUser={updateUser}
        />
      default:
        return null
    }
  }

  // Forcing form initial values, Formik default way doesn't update correctly
  const {
    formField: {
      companyName,
      companyFirstName,
      companyLastName,
      companyMail,
      companyPassword,
      website,
      youtube,
      linkedin,
      twitter,
      bio,
      photo,
    },
  } = checkout

  return (
    <RecruiterDashboardLayout>
      <SuiBox pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SuiBox mt={6} mb={1} textAlign="center">
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">Edit company profile</SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="regular" color="secondary">This information will let your candidates know more about the company</SuiTypography>
            </SuiBox>

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <SuiBox className="alertErrorBox" display="none">
              <SuiAlert color="error" className="alertError">Please fill all the required fields in this section before continuing.</SuiAlert>
            </SuiBox>

            <Card>
              <SuiBox p={2}>
                <SuiBox>
                  <Formik
                    enableReinitialize
                    validationSchema={currentValidation}
                    onSubmit={handleSubmit}
                    initialValues={{
                      [companyName.name]: "",
                      [companyFirstName.name]: "",
                      [companyLastName.name]: "",
                      [companyMail.name]: "",
                      [companyPassword.name]: "",
                      [website.name]: "",
                      [youtube.name]: "",
                      [linkedin.name]: "",
                      [twitter.name]: "",
                      [bio.name]: "",
                      [photo.name]: "",
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">
                        <SuiBox>
                          {getStepContent(activeStep, { values, touched, formField, errors })}
                        </SuiBox>
                      </Form>
                    )}
                  </Formik>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>

    </RecruiterDashboardLayout>
  );
}

export default RecruiterCompanyWizard
