/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';

import checkout from "layouts/applications/recruiter-application-edit/formComponents/form";

ReactSession.setStoreType("localStorage");
const ApplicationForEdit = ReactSession.get("ApplicationForEdit");
let candidate = {};
if (ApplicationForEdit && ApplicationForEdit.user && ApplicationForEdit.user.candidate) {
  candidate = ApplicationForEdit.user.candidate;
}

const {
  formField: {
    firstName,
    lastName,
    email,
    country,
    phone,
    resume,
    linkedin,
    github,
    portfolio,
    website,
    twitter,
  },
} = checkout;

export default {
  [firstName.name]: ApplicationForEdit?.user?.first_name || "",
  [lastName.name]: ApplicationForEdit?.user?.last_name || "",
  [email.name]: ApplicationForEdit?.email || "",
  [country.name]: candidate?.country || "",
  [phone.name]: candidate?.phone || "",
  [resume.name]: "",
  [linkedin.name]: candidate?.linkedin || "",
  [github.name]: candidate?.github || "",
  [portfolio.name]: candidate?.portfolio || "",
  [website.name]: candidate?.website || "",
  [twitter.name]: candidate?.twitter || "",
};
