import { ReactSession } from 'react-client-session';

// eslint-disable-next-line
export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

// format date MM-DD-YYYY
export const formatDate = date => {
    const d = new Date(date)
    const month = `0${d.getMonth() + 1}`.slice(-2)
    const day = `0${d.getDate()}`.slice(-2)
    const year = d.getFullYear()
    return `${month}-${day}-${year}`
}

// Slice string bigger than given ammount of characters
export const sliceString = (str, ammount) => {
    if (str.length > ammount) {
        return `${str.slice(0, ammount)}...`
    }
    return str
}

// Strip HTML tags from string
export const stripHtml = text => {
    const stripContent = text.replace(/<\/?[^>]+(>|$)/g, "")
    return stripContent
}

// Strip HTML tags from string
export const validateToken = data => {
    let invalidToken = false
    if (data && typeof data === 'string' && data.includes("Invalid token")) invalidToken = true;
    if (data && data.detail &&  data.detail.includes("Invalid token")) invalidToken = true;
    if (invalidToken) {
        ReactSession.setStoreType("localStorage");
        ReactSession.set("user", null);
        ReactSession.set("candidate", null);
        ReactSession.set("company", null);
        ReactSession.set("recruiter", null);
        ReactSession.set("token", null);
        ReactSession.set("candidateDashboardActiveTab", null)
        if (ReactSession.get("student")) {
            ReactSession.set("student", null);
            window.location.replace("/login");
        } else {
            ReactSession.set("student", null);
            window.location.replace("/t/login");
        }

    }
}