/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useRef, useState } from "react"
import PropTypes from "prop-types"
import { ReactSession } from 'react-client-session'
// @mui material components
import TableRow from "@mui/material/TableRow"
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiSelect from "components/SuiSelect"
import SuiButton from "components/SuiButton"
import SuiBox from "components/SuiBox"
// Settings page components
import TableCell from "layouts/pages/account/settings/components/TableCell"

function UserTableRecord({ user, roleOptions, fetchCompanyUsers }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const getRoleName = roleName => {
    switch (roleName) {
      case "company_owner":
        return "Admin"
      case "limited_recruiter":
        return "Limited Recruiter"
      default:
        return "Recruiter"
    }
  }

  const [userNewRole, setUserNewRole] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)

  const roleRef = useRef()

  const changeUserRole = () => {
    setShowSpinner(true)

    const body = { type: userNewRole, id: user.id }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/companyUserEditType/`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}` },
        body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(response => {

      if (response.success) {

        setShowSpinner(false)
        setShowDoneIcon(true)
        
        setUserNewRole(null)
        roleRef.current.state.value = ""

        fetchCompanyUsers()

        setTimeout(() => setShowDoneIcon(false), 2000)
      }
    })
    .catch(error => console.error(error))
  }

  return (
    <TableRow key={user.name}>
      <TableCell padding={isMobile ? [1, .5, 1, .5] : [1, 0, 1, 0]} smallFont align="center">{isMobile ? `${user.name} / ${getRoleName(user.role)}` : user.name}</TableCell>
      {!isMobile && <TableCell padding={[1, 0, 1, 0]} smallFont align="center">{user.email}</TableCell>}
      {!isMobile && <TableCell padding={[1, 0, 1, 0]} smallFont align="center">{user.status}</TableCell>}
      {!isMobile && <TableCell padding={isMobile ? [1, .5, 1, .5] : [1, 0, 1, 0]} smallFont align="center">{getRoleName(user.role)}</TableCell>}
      <TableCell padding={isMobile ? [1, .5, 1, .5] : [1, 0, 1, 0]} width={200} smallFont align="center">
        <SuiSelect
          id="userRole"
          placeholder="New role"
          label="Role"
          options={roleOptions.filter(opt => opt.value !== user.role)}
          name="userRole"
          size="small"
          success={userNewRole}
          onChange={e => setUserNewRole(e.value)}
          ref={roleRef}
          smallFont
        />
      </TableCell>
      <TableCell padding={isMobile ? [1, .5, 1, .5] : [1, 0, 1, 0]} smallFont align="center">
        <>
          {showSpinner ?  // eslint-disable-line
            <SuiBox display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size={20} />
            </SuiBox >
          :
            showDoneIcon ?
            <SuiBox display="flex" justifyContent="center" alignItems="center">
              <DoneIcon fontSize="medium"/>
            </SuiBox >
            :
            <SuiButton
              variant="outlined"
              color="info"
              size="small"
              disabled={!userNewRole}
              onClick={() => changeUserRole()}
              style={isMobile ? {width: 30} : null}
            >Change role</SuiButton>
          }
        </>
      </TableCell>
    </TableRow>
  )
}

// typechecking props for UserSignUp
UserTableRecord.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  roleOptions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fetchCompanyUsers: PropTypes.isRequired,
}

export default UserTableRecord