/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
// import { useEffect } from "react";
import { ReactSession } from 'react-client-session'; // eslint-disable-line
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// Soft UI Dashboard PRO React components
import SuiAvatar from 'components/SuiAvatar';
import SuiBox from "components/SuiBox";
import SuiAlert from "components/SuiAlert"; // eslint-disable-line
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";

import BeatLoader from "react-spinners/BeatLoader";
import ClipLoader from "react-spinners/ClipLoader";

// Settings page components
import StudentDashboardLayout from "examples/LayoutContainers/StudentDashboardLayout";
import mockCompanyAvatar from  "assets/images/mockCompanyAvatar.png"
import ComputerIcon from '@mui/icons-material/Computer';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

function Settings() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const sesionStudent = ReactSession.get("student");
  const studentPhoto = sesionStudent?.studentCompany?.photo ? `${process.env.REACT_APP_API_ROUTE}${sesionStudent.studentCompany.photo}` : mockCompanyAvatar;
  const [rows, setRows] = useState([])
  const [showSpinner, setShowSpinner] = useState(true)
  const [inputDisabled, setInputDisabled] = useState(true)

  function movedialog() {
    setTimeout(() => { 
      $(".aiDialog").scrollTop($(".aiDialog")[0].scrollHeight); 
    }, "100");
  }

  function callAi(message, rowsToUse) {
    const postBody = message ? { question: message } : {};
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/academy/chatOpenAI/`;
    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': `Token ${token}`
        },
        body: new URLSearchParams(postBody)
    };

    fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
            if (response.success) {
              const tempRows = [...rowsToUse]
              tempRows.push({
                "isBot": true,
                "message": response.data,
                "url": null,
                "id": rows.length + 1
              })
              setRows(tempRows)
              setShowSpinner(false)
              setInputDisabled(false)
              movedialog()
            }
        });
  }

  useEffect(() => {
    document.title = `AI Tutor | Boost`;
    if (showSpinner) {
      callAi("", [])
    }
  }, []);

  function updateRowUrl(rowId, text) {
    let tempRows = []
    let rowEdited = null
    for (const tempRow of [...rows]) { // eslint-disable-line
      if (tempRow.id === rowId) {
        tempRow.url = text
        rowEdited = tempRow
      }
      tempRows.push(tempRow)
    }

    if (rowEdited) {
      setRows(tempRows)
      movedialog()
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/academy/textToSpeech/`;
        const requestMetadata = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': `Token ${token}`
            },
            body: new URLSearchParams({ "text": rowEdited.message })
        };

        fetch(recipeUrl, requestMetadata)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                  tempRows = []
                  for (const tempRow of [...rows]) { // eslint-disable-line
                    if (tempRow.id === rowEdited.id) {
                      tempRow.url = `${process.env.REACT_APP_API_ROUTE}${response.data.audio}`
                    }
                    tempRows.push(tempRow)
                    movedialog()
                  }
                  setRows(tempRows)
                }
            });
    }
  }

  function createMessage() {
    setInputDisabled(true);
    const tempRows = [...rows]
    tempRows.push({
      "isBot": false,
      "message": $("textarea[name=message]").val(),
      "url": null,
      "id": rows.length + 1
    })
    $("textarea[name=message]").blur()
    setRows(tempRows)
    setShowSpinner(true)
    movedialog()
    callAi($("textarea[name=message]").val(), tempRows)
    $("textarea[name=message]").val("")
  }

  return (
    <StudentDashboardLayout>
      <SuiBox mt={4}>
        <Card >
          <Grid container spacing={3} className="aiContainer">
            <Grid item xs={12} lg={12} className="aiBox">
              <SuiBox className="aiDialog">
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table aria-label="simple table">
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow className={row.isBot ? "aiRow bot" : "aiRow"}>
                          <TableCell align="left" size="small" className="aiCell">
                            { !row.isBot ?
                              (
                                <SuiAvatar
                                  src={studentPhoto}
                                  alt="avatar"
                                  variant="rounded"
                                  size="xs"
                                  shadow="sm"
                                  sx={{ display: "inline-block" }}
                                />
                              ) : (
                                <ComputerIcon fontSize="small" sx={{ display: "inline-block", marginTop: "2px" }}/>
                              )}
                            <SuiTypography variant="body2" color="secondary" sx={{ whiteSpace: "pre-line" }}>
                              {row.message}
                            </SuiTypography>
                            
                            { row.isBot ? (
                              <SuiBox sx={{ margin: "15px 0 0 25px", textAlign: "center" }}>
                                { !row.url ? (
                                  <SuiButton variant="outlined" color="info" size="medium" onClick={() => updateRowUrl(row.id, "update")}>
                                    Generate Audio 
                                    <VolumeUpIcon />
                                  </SuiButton>
                                ) : null }
                                { row.url === "update" ? (
                                  <SuiButton variant="outlined" color="info" size="medium" disabled>
                                    <span style={{ marginRight: "5px" }}>Generating Audio </span>
                                    <ClipLoader color="#36d7b7" size={10} sx={{ marginLeft: "3px" }} /> 
                                  </SuiButton>
                                ) : null }
                                { row.url && row.url !== "update" ? (
                                  <audio 
                                    controlsList="nodownload" 
                                    controls 
                                    src={row.url}
                                    >
                                      Your browser does not support the <code>audio</code> element.
                                  </audio>
                                ) : null }
                              </SuiBox >
                            ) : null }
                            
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                { showSpinner ? ( 
                  <SuiBox sx={{ padding: "15px 45px 20px 45px", background: "#fff" }}>
                    <ComputerIcon fontSize="small" sx={{ display: "inline-block", verticalAlign: "middle", margin: "0 12px 0 0" }}/>
                    <span className="botThinking"> BoostAI is thinking, please wait</span>
                    <BeatLoader color="#36d7b7" size={5} /> 
                  </SuiBox >
                ) : null }
              </SuiBox >
              <Grid item xs={12} lg={12} className="aiInput">
                <SuiInput 
                  type="text" 
                  placeholder="Send a Message..." 
                  name="message"
                  multiline 
                  rows={2} 
                  disabled={inputDisabled}
                  onKeyDown={(e) => {
                    if (e.code === "Enter" && e.target.value.length > 0) {
                      e.preventDefault();
                      createMessage();
                    }
                  }}
                  />
              </Grid>
            </Grid>
          </Grid >
        </Card>
      </SuiBox >
    </StudentDashboardLayout >
  );
}

export default Settings;
