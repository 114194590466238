/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
// Soft UI Dashboard PRO React layouts

import CompanyDashboard from "layouts/dashboards/company";
import ProfileCompany from "layouts/pages/profile/profile-company";

import Wizard from "layouts/applications/wizard";
import CompanyWizard from "layouts/applications/company-wizard";
import Support from "layouts/pages/account/support";

import Manage from "layouts/pages/applications-list";

import TotalApplications from "layouts/pages/total-applications";

import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignInCompany from "layouts/authentication/sign-in/company";
import Snooze from "layouts/authentication/snooze/illustration";
import Claim from "layouts/authentication/claim/illustration";
import Claimed from "layouts/authentication/claimed/illustration";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import SignUpCompany from "layouts/authentication/sign-up/company";

import ResetIllustration from "layouts/authentication/reset-password/illustration";
import NewIllustration from "layouts/authentication/new-password/illustration";
import NewerIllustration from "layouts/authentication/change-password/illustration";

import Activate from "layouts/authentication/error/activate";
import Validated from "layouts/authentication/error/validated";

import Apply from "layouts/pages/account/apply";
import JobApply from "layouts/applications/job-apply";
import NewJob from "layouts/applications/newjob";

import CompanySettings from "layouts/pages/account/settings-company";

import CompanyLogout from "layouts/pages/companyLogout/CompanyLogout";

// Soft UI Dashboard PRO React icons
import Office from "examples/Icons/Office";
import SettingsIcon from "examples/Icons/Settings";

import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";

import JobDetail from "layouts/pages/account/detailjob";
import ClientInvoices from "layouts/pages/clientInvoices";

// Icons
import LogoutIcon from '@mui/icons-material/Logout';


const routes = [
  {
    name: "Candidate Log In",
    route: "/t/login",
    component: SignInIllustration,
  },
  {
    name: "Company Log In",
    route: "/c/login",
    component: SignInCompany,
  },
  {
    name: "Candidate Sign Up",
    route: "/t/signup",
    component: SignUpIllustration,
  },
  {
    name: "Company Sign Up",
    route: "/c/signup",
    component: SignUpCompany,
  },
  {
    name: "Reset Password",
    route: "/reset-password",
    component: ResetIllustration,
  },
  {
    name: "New Password",
    route: "/new-password",
    component: NewIllustration,
  },
  {
    name: "Change Password",
    route: "/dashboard/t/change-password",
    component: NewerIllustration,
  },
  {
    name: "Snooze",
    route: "/snooze",
    component: Snooze,
  },
  {
    name: "Job Details",
    route: "/job/",
    component: JobDetail,
  },
  {
    name: "Activate Account",
    route: "/activate",
    component: Activate,
  },
  {
    name: "Account Validated",
    route: "/validated",
    component: Validated,
  },
  {
    name: "Claim Account",
    route: "/t/claim",
    component: Claim,
  },
  {
    name: "Claimed Account",
    route: "/t/claimed",
    component: Claimed,
  },
  {
    name: "Account Wizard",
    route: "/t/wizard",
    component: Wizard,
  },
  {
    name: "Company Account Wizard",
    route: "/c/wizard",
    component: CompanyWizard,
  },
  {
    name: "Job Apply Old",
    route: "/job/apply-old",
    component: Apply,
  },
  {
    name: "Job Apply",
    route: "/job/apply",
    component: JobApply,
  },
  // COMPANY
  {
    name: "Dashboard",
    route: "/c/dashboard/",
    component: CompanyDashboard,
  },
  {
    name: "New Job",
    route: "/dashboard/c/create-a-job",
    component: NewJob,
  },
  {
    name: "Applications",
    route: "/c/manage",
    component: Manage,
  },
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  {
    type: "collapse",
    name: "Dashboard",
    key: "/c/dashboard",
    route: "/c/dashboard",
    href: "/c/dashboard",
    component: CompanyDashboard,
    icon: <SpaceShip size="12px" />,
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Profile",
    key: "/c/profile",
    route: "/c/profile",
    href: "/c/profile",
    component: ProfileCompany,
    icon: <Office size="12px" />,
  },
  {
    type: "collapse",
    name: "Jobs",
    key: "/c/dashboardJobs",
    icon: <Document size="12px" />,
    noCollapse: false,
    collapse: [
      {
        name: "Active Jobs",
        key: "/c/active-jobs",
        route: "/c/active-jobs",
        component: CompanyDashboard,
      },
      {
        name: "Closed Jobs",
        key: "/c/closed-jobs",
        route: "/c/closed-jobs",
        component: CompanyDashboard,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applicants",
    key: "/c/review",
    route: "/c/review",
    href: "/c/review",
    component: TotalApplications,
    icon: <CustomerSupport size="12px" />,
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Invoices",
    key: "/c/invoices",
    route: "/c/invoices",
    href: "/c/invoices",
    component: ClientInvoices,
    icon: <CustomerSupport size="12px" />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "/c/settings",
    route: "/c/settings",
    href: "/c/settings",
    component: CompanySettings,
    icon: <SettingsIcon size="12px" />,
  },
  {
    type: "collapse",
    name: "Support",
    key: "/c/support",
    route: "/c/support",
    href: "/c/support",
    component: Support,
    icon: <CustomerSupport size="12px" />,
  },
  {
    type: "collapse",
    name: "Log out",
    title: "test title",
    key: "/c/logout",
    route: "/c/logout",
    href: "/c/logout",
    component: CompanyLogout,
    icon: <LogoutIcon fontSize="small" />,
  },
];

export default routes;
