/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
import NameAndPictureCell from "layouts/pages/conversionData/components/nameAndPictureCell";
import ActionCell from "layouts/pages/conversionData/components/actionCell";
import ApplicationsInDBCell from "layouts/pages/conversionData/components/applicationsInDBCell";

export default {
  columns: [
    {
      Header: "Recruiter",
      accessor: "recruiter",
      Cell: ({ value: [name, image] }) => <NameAndPictureCell name={name} image={image} />,
    },
    {
      Header: "Invitations", accessor: "action", disableSortBy: true,
      Cell: ({ value: data }) => <ActionCell recruiterId={data.recruiterId} invites={data.invites} />,
    },
    {
      Header: "Applications in DB", accessor: "applicationsInDb", disableSortBy: true,
      Cell: ({ value: data }) => <ApplicationsInDBCell recruiterId={data.recruiterId} applications={data.applications} />,
    },
  ],

  rows: [],
};
