/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import StudentDashboardLayout from "examples/LayoutContainers/StudentDashboardLayout"

import EventCard from "examples/Cards/EventCard";
// avatares
import LATAM from "assets/images/discount-logos/Latam.png";
import Geeks from "assets/images/discount-logos/4Geeks.png";
import Coder from "assets/images/discount-logos/CoderHouse.png";
import Payoneer from "assets/images/discount-logos/payonneer.png";
import Brilliant from "assets/images/discount-logos/Brilliant.png";
import Drops from "assets/images/discount-logos/drops.png";
import LaMaquinita from "assets/images/discount-logos/laMaquinita.jpg";
import Isdi from "assets/images/discount-logos/isdi.jpg";
import Artifact from "assets/images/discount-logos/artifact.png";
// banners
import LATAMBanner from "assets/images/discount-banners/latam.png";
import GeekBanner from "assets/images/discount-banners/4geek.png";
import CoderBanner from "assets/images/discount-banners/coderhouse.png";
import PayoneerBanner from "assets/images/discount-banners/payoneer.png";
import CodeableSymbol from "assets/images/discount-logos/Codeable.jpg";
import CodeableLogo from "assets/images/discount-banners/Codeable_2.png";
import OpenEnglishSymbol from "assets/images/discount-logos/OE.jpg";
import OpenEnglishLogo from "assets/images/discount-banners/OE.png";
import NextU from "assets/images/discount-logos/NextU.jpg";
import NextUBanner from "assets/images/discount-banners/NextU_2.png";
import BrilliantBanner from "assets/images/discount-banners/Brilliant.png";
import DropsBanner from "assets/images/discount-banners/drops.png";
import LaMaquinitaBanner from "assets/images/discount-banners/laMaquinita.png";
import ArtifactBanner from "assets/images/discount-banners/artifact.png";
import IsdiBanner from "assets/images/discount-banners/isdi.png";


function Settings() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token")
  const candidate = ReactSession.get("candidate")
  const student = ReactSession.get("student")

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  useEffect(() => {
    document.title = `Discounts | TECLA`;
    if (student) {
      document.title = `Perks | Boost`;
    }
  }, []);

  const setDiscountVisited = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/setDiscountVisited/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${token}`
        },
    })
      .then(async response => {
        const data = await response.json() // eslint-disable-line
      })
      .catch(error => {
          console.error('There was an error!', error)
      })
  }

  useEffect(() => { if (!candidate?.checkList?.discounts) setDiscountVisited() }, [])

  const setLayout = children => {
    if (student) return <StudentDashboardLayout>{children}</StudentDashboardLayout>
    return <BaseLayout>{children}</BaseLayout>
  }
  
  return (
    <>
      {setLayout(
        <>
          <SuiTypography variant="h3" fontWeight="bold" ml={1} mb={3}>
            { student ? "Perks" : "Discounts" }
          </SuiTypography>
          <SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} xl={12}>

                <SuiBox mb={3} display="flex" justifyContent="center">
                  <Grid container spacing={2} xs={12}>
                    <Grid item xs={12} xl={6} className="job-box">
                      <EventCard
                        id="Tecla4GA"
                        image={Geeks}
                        banner={GeekBanner}
                        size="100%"
                        title="4Geeks"
                        description="15% OFF for Chile Community"
                        description2='Go to the posted Link. In the section "Where do you want to study?" please select "Chile", and in the section "Invitation Code" please type Tecla4GA'
                        action={{ type: "external", route: "https://4geeksacademy.com/es/aplica", color: "success", label: "Redeem" }}
                      />
                    </Grid>
                    
                    <Grid item xs={12} xl={6} className="job-box">
                      <EventCard
                        id="TECLA10"
                        image={Coder}
                        banner={CoderBanner}
                        size="100%"
                        title="Coderhouse"
                        description="10% OFF on CoderBeca Plan"
                        description2="Requirements: 85% assistance and delivery of all tests on time. Use the code below to have 10% Additional of any current discounts."
                        action={{ type: "external", route: "https://www.coderhouse.com.mx/?utm_source=tecla&utm_medium=alianzas&utm_campaign= ", color: "success", label: "Redeem" }}
                      />
                    </Grid>

                    <Grid item xs={12} xl={6} className="job-box">
                      <EventCard
                        id="TECLA-OE"
                        image={OpenEnglishSymbol}
                        banner={OpenEnglishLogo}
                        size="100%"
                        title="Open English"
                        description="80% OFF on Open English Adult Courses"
                        description2="Write to jennifer.reyes@openenglish.com with TECLA-OE in the subject"
                      />
                    </Grid>

                    <Grid item xs={12} xl={6} className="job-box">
                      <EventCard
                        id=""
                        image={CodeableSymbol}
                        banner={CodeableLogo}
                        size="100%"
                        title="Codeable"
                        description="30% OFF on Precodeable Course and 25% OFF on Codeable Sign Up Fee"
                        description2='Write to hola@codeable.la with the subject "Tecla + PRE Codeable: 30% OFF" or "Tecla + Codeable: 25% OFF Enrollment Fee"'
                      />
                    </Grid>


                    <Grid item xs={12} xl={6} className="job-box">
                      <EventCard
                        id="TECLA-OE"
                        image={NextU}
                        banner={NextUBanner}
                        size="100%"
                        title="NextU"
                        description="75% OFF on Next U Prices"
                        description2="Write to jennifer.reyes@openenglish.com with TECLA-OE in the subject"
                      />
                    </Grid>

                    <Grid item xs={12} xl={6} className="job-box">
                      <EventCard
                        id="https://brilliant.org/Tecla/"
                        image={Brilliant}
                        banner={BrilliantBanner}
                        size="100%"
                        title="Brilliant"
                        description="20% OFF on Annual Subscription"
                        description2="Click on the link and you'll receive a 20% OFF in your annual suscription"
                        action={{ type: "external", route: "https://brilliant.org/Tecla/", color: "success", label: "Redeem" }}

                      />
                    </Grid>

                    <Grid item xs={12} xl={6} className="job-box">
                      <EventCard
                        id="TECLA2022"
                        image={Drops}
                        banner={DropsBanner}
                        size="100%"
                        title="Drops"
                        description="60% OFF on Annual Subscription"
                        description2="Once you create your Drops account, click “Add a coupon” and enter the promo code: TECLA2022."
                        action={{ type: "external", route: "https://app.languagedrops.com/#/purchase/iapPremiumYearly70", color: "success", label: "Redeem" }}
                      />
                    </Grid>

                    <Grid item xs={12} xl={6} className="job-box">
                      <EventCard
                        id=""
                        image={LaMaquinita}
                        banner={LaMaquinitaBanner}
                        size="100%"
                        title="LaMaquinita"
                        description="Special Discounts and Prices for LaMaquinita"
                        description2="Fill out the form in LaMaquinita webpage or contact one of LaMaquinita agents:
                        1 day trial (person)
                        3 days trial (companies and startups)
                        25% OFF in the first month in 3-month contracts
                        15% OFF in the first 3 months 6-month contracts
                        25% OFF in the first 3 months in 1 year+ contracts"
                      />
                    </Grid>

                    <Grid item xs={12} xl={6} className="job-box">
                      <EventCard
                        id="https://drive.google.com/file/d/18BojYN42V_AOpLDHt_IfbW7qNQnJ9Jff/view?usp=sharing"
                        image={Artifact}
                        banner={ArtifactBanner}
                        size={isMobile ? "100%" : null}
                        title="Artifact"
                        description="$10,000 ARG PESOS OFF on all categories"
                        description2="Except accessories. Send the flyer to ventas@artifactargentna.com or show it at a physical store."
                        action={{ type: "external", route: "https://www.artifactargentina.com/", color: "success", label: "Redeem" }}
                      />
                    </Grid>

                    <Grid item xs={12} xl={6} className="job-box">
                      <EventCard
                        id="IDSI-TECLA-2022"
                        image={Isdi}
                        banner={IsdiBanner}
                        size={isMobile ? "100%" : null}
                        title="ISDI Coders"
                        description="15% OFF on Bootcamp Price"
                        description2="Write to msoto@isdi.education with IDSI-TECLA-2022 in the subject."
                        action={{ type: "external", route: "https://isdicoders.com/es_mx/bootcamps/full-time-cdmx/", color: "success", label: "Redeem" }}
                      />
                    </Grid>

                  </Grid>
                </SuiBox>

                <SuiBox mb={3} display="flex" justifyContent="center">
                  <Grid container spacing={2} xs={12}>
                    <Grid item xs={12} xl={6} className="job-box">
                      <SuiTypography variant="h4" ml={1} mb={3}>
                        Career
                      </SuiTypography>
                      <EventCard
                        id="TECLA-IO"
                        image={LATAM}
                        banner={LATAMBanner}
                        size="100%"
                        title="Desafio LATAM"
                        description="50% OFF on Careers Prices"
                        description2="Careers: Full-Stack, Front-End, Data Science, UX/UI Design"
                        action={{ type: "external", route: "https://desafiolatam.com/", color: "success", label: "Redeem" }}
                      />
                    </Grid>

                    <Grid item xs={12} xl={6} className="job-box">
                      <SuiTypography variant="h4" ml={1} mb={3}>
                        Finance
                      </SuiTypography>
                      <EventCard
                        id="http://tracking.payoneer.com/SH3QH"
                        image={Payoneer}
                        banner={PayoneerBanner}
                        size="100%"
                        title="Payoneer"
                        description="Payoneer Premium (No Fees)"
                        description2="Payoneer Premium for TECLA's community through the following link https://register.payoneer.com/en/solution/tecla-talents/"
                        action={{ type: "external", route: "http://tracking.payoneer.com/SH3QH", color: "success", label: "Redeem" }}
                      />
                    </Grid>
                  </Grid>
                </SuiBox>

              </Grid>
            </Grid >
          </SuiBox >
        </ >
      )}
    </>
  );
}

export default Settings;
