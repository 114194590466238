/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react"
import { ReactSession } from 'react-client-session'
import $ from 'jquery'
// @mui material components
import Grid from "@mui/material/Grid"
import Icon from "@mui/material/Icon"
// prop-type is a library for typechecking of props
import PropTypes from "prop-types"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiAvatar from "components/SuiAvatar"
import SuiButton from "components/SuiButton"
import SuiEditor from "components/SuiEditor"
import SuiSelect from "components/SuiSelect"
// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField"
// Images
import mockCompanyAvatar from "assets/images/mockCompanyAvatar.png";

function Basic({ formData, setActiveStep, activeStep, bioValue, setBioValue, selectedCountry, setSelectedCountry, selectedCompanySize, setSelectedCompanySize }) {
  
  ReactSession.setStoreType("localStorage")
  const user = ReactSession.get("user")
  const companyForRecruiterEdit = ReactSession.get("companyForRecruiterEdit")
  const token = ReactSession.get("token")

  const { formField, values, errors, touched } = formData
  const { companyName, website, youtube, linkedin, twitter, photo, bio } = formField

  const {
    companyName: companyNameV,
    website: websiteV,
    youtube: youtubeV,
    linkedin: linkedinV,
    twitter: twitterV,
    photo: photoV,
    bio: bioV
  } = values

  let defaultAvatar = mockCompanyAvatar;
  if (companyForRecruiterEdit.photo) {
    defaultAvatar = `${process.env.REACT_APP_API_ROUTE}${companyForRecruiterEdit.photo}`;
  }
  const [profileImage, setProfileImage] = useState(defaultAvatar)

  // let timerid = ""

  // if (timerid) {
  //   clearTimeout(timerid)
  // }

  // timerid = setTimeout(() => {
  $("input[name=firstName]").attr("placeholder", companyForRecruiterEdit.name ? companyForRecruiterEdit.name : "Full Name");
  $("textarea[name=bio]").attr("placeholder", companyForRecruiterEdit.description ? companyForRecruiterEdit.description : "Say a few words about who you are or what you're working on");
  // if (companyForRecruiterEdit.photo) {
  //   $(".MuiAvatar-img").attr("src", `${process.env.REACT_APP_API_ROUTE}${companyForRecruiterEdit.photo}`)
  // }
  // }, 200);

  function editImageClick() {
    $("input[name=file]").click();
  }

  function editImage() {
    if (companyForRecruiterEdit) {
      // eslint-disable-next-line
      const imageField = $("input[name=file]").prop('files')[0];
      const formPostData = new FormData();

      // eslint-disable-next-line no-useless-concat
      const postToken = `Token ${token}`;

      // eslint-disable-next-line
      formPostData.append('file', imageField);
      formPostData.append('company', companyForRecruiterEdit.id);
      

      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/companies/uploadPhoto/`;

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Authorization': postToken
        },
        body: formPostData
      };


      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            setProfileImage(`${process.env.REACT_APP_API_ROUTE}${response.data.photo}`);
            user.photo = response.data.photo;
            ReactSession.set("user", user);
            // if (timerid) {
            //   clearTimeout(timerid);
            // }

            // timerid = setTimeout(() => {
            //   $(".MuiAvatar-img").attr("src", `${process.env.REACT_APP_API_ROUTE}${response.data.photo}`);
            // }, 200);

          }
        });
    }
  }

  const [countries, setCountries] = useState([]);

  const fetchCountries = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/getAllCountries/`)
    .then(async response => {
      const data = await response.json();

      const newData = [];
      // eslint-disable-next-line
      Object.keys(data.data).forEach(function (key) {
        newData.push({ value: key, label: data.data[key] });
      });
      setCountries(newData);
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
  }

  useEffect(() => { fetchCountries() }, []);

  function changeCountry(e) {
    $(".countryBox").attr("data-val", e.value);
    setSelectedCountry(e.value)
  }

  const companySizes = [
    {value : '1-10', label : '1 - 10'},
    {value : '11-50', label : '11 - 50'},
    {value : '51-100', label : '51 - 100'},
    {value : '101-500', label : '101 - 500'},
    {value : '501-1000', label : '501 - 1000'},
    {value : '1000+', label : '1000+'}
  ]

  const verifyBasicInfoInputs = () => {
    if (companyNameV.length > 0 &&
        !errors.companyName &&
        !errors.website &&
        !errors.twitter &&
        !errors.youtube &&
        !errors.linkedin &&
        !errors.bio &&
        selectedCompanySize
      ) {
        setActiveStep(activeStep+1)
      }
  }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={1} mb={4}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5" fontWeight="regular">Let&apos;s start with some basic information</SuiTypography>
        </SuiBox>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} container justifyContent="center">
            <SuiBox position="relative" height="max-content" mx="auto">
              <SuiAvatar src={profileImage} alt="profile picture" size="xxl" variant="rounded" />
              <SuiBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                <SuiButton variant="gradient" color="light" size="small" iconOnly className="editImage" onClick={editImageClick}>
                  <Icon>edit</Icon>
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={8}>

            <Grid display="none">
              <FormField
                type={photo.type}
                label={photo.label}
                name="file"
                value={photoV}
                placeholder={photo.placeholder}
                error={errors.image && touched.photo}
                success={photoV.length > 0 && !errors.photo}
                onChange={editImage}
              />
            </Grid>
            
            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Company name</SuiTypography>
              <FormField
                type={companyName.type}
                label={companyName.label}
                name={companyName.name}
                value={companyNameV}
                placeholder={companyName.placeholder}
                error={errors.companyName && touched.companyName}
                success={companyNameV.length > 0 && !errors.companyName}
              />
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Grid item xs={5.5} className="countryBox" mb={3}>
                <SuiTypography variant="button" ml={1} mb={1}>Company country</SuiTypography>
                <SuiSelect
                  placeholder="Select country (optional)"
                  options={countries}
                  value={selectedCountry ? countries.filter(e => (selectedCountry === companyForRecruiterEdit.country ? e.label : e.value) === selectedCountry) : null}
                  name="country"
                  onChange={changeCountry}
                />
              </Grid>

              <Grid item xs={5.5} className="sizeBox" mb={3}>
                <SuiTypography variant="button" ml={1} mb={1}>Company size</SuiTypography>
                <SuiSelect
                  placeholder="Select an option"
                  options={companySizes}
                  value={selectedCompanySize ?
                    companySizes?.filter(item => item?.value === companyForRecruiterEdit?.size)[0]
                    : null}
                  name="size"
                  onChange={e => setSelectedCompanySize(e.value)}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Website</SuiTypography>
              <FormField
                type={website.type}
                label={website.label}
                name={website.name}
                value={websiteV}
                placeholder={website.placeholder}
                error={errors.website && touched.website}
                success={websiteV.length > 0 && !errors.website}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>YouTube</SuiTypography>
              <FormField
                type={youtube.type}
                label={youtube.label}
                name={youtube.name}
                value={youtubeV}
                placeholder={youtube.placeholder}
                error={errors.youtube && touched.youtube}
                success={youtubeV.length > 0 && !errors.youtube}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>LinkedIn</SuiTypography>
              <FormField
                type={linkedin.type}
                label={linkedin.label}
                name={linkedin.name}
                value={linkedinV}
                placeholder={linkedin.placeholder}
                error={errors.linkedin && touched.linkedin}
                success={linkedinV.length > 0 && !errors.linkedin}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Twitter</SuiTypography>
              <FormField
                type={twitter.type}
                label={twitter.label}
                name={twitter.name}
                value={twitterV}
                placeholder={twitter.placeholder}
                error={errors.twitter && touched.twitter}
                success={twitterV.length > 0 && !errors.twitter}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>
                Company bio: Tell us a bit more about the company
              </SuiTypography>
              <SuiEditor
                maxLength={2000}
                defaultValue={bioValue}
                name={bio.name}
                onChange={setBioValue}
                placeholder={bio.placeholder}
                error={errors.bio && touched.bio}
                success={bioV.length > 0 && !errors.bio}
              />
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="flex-end">
          <SuiButton variant="gradient" color="info" onClick={() => verifyBasicInfoInputs()} isSubmitting className="changeStep nextStep" >Next</SuiButton>
      </SuiBox>
    </SuiBox>
  )
}

// typechecking props for UserSignUp
Basic.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.isRequired,
  activeStep: PropTypes.isRequired,
  bioValue: PropTypes.isRequired,
  setBioValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  selectedCountry: PropTypes.string.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  selectedCompanySize: PropTypes.string.isRequired,
  setSelectedCompanySize: PropTypes.func.isRequired,
}

export default Basic
