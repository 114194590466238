/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router components
import { Link } from "react-router-dom"
// prop-types is library for typechecking of props
import PropTypes from "prop-types"
// @mui material components
import Card from "@mui/material/Card"
import StarIcon from '@mui/icons-material/Star'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiAvatar from "components/SuiAvatar";
import folderIcon from "layouts/recruiterFolders/folderCard/folderIcon/folderIcon.png"
import GroupIcon from '@mui/icons-material/Group';


function FolderCard({ folderName, folderId, isFavorite, owner, isPublic, anyoneCanSource }) {

  return (
    <Link to={`folders/${folderId}`}>
      <Card sx={{ cursor: "pointer", height: "100%" }} position="relative">
        <SuiBox px={1.5} py={2} minHeight="10rem" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
          <SuiAvatar src={folderIcon} alt="Avatar" variant="rounded" size="xxl" />

          {isFavorite && <StarIcon fontSize="small" sx={{ position: 'absolute', top: 15, right: 15 }} />}
          <SuiBox mt={1} mb={2} lineHeight={0}>
            <SuiBox sx={{ textAlign: "center" }}>
              <SuiTypography variant="button" fontWeight="regular" color="text">{folderName}</SuiTypography>
            </SuiBox>
            { owner ? (
              <SuiBox sx={{ textAlign: "center" }}>
                <SuiTypography variant="button" fontWeight="regular" color="text" sx={{ opacity: "0.7" }}>{owner}</SuiTypography>
              </SuiBox>
            ) : null }
            { isPublic ? (
              <SuiBox sx={{ textAlign: "center" }}>
                <SuiTypography variant="button" fontWeight="regular" color="text" sx={{ opacity: "0.7" }}>Public folder</SuiTypography>
              </SuiBox>
            ) : null }
            { anyoneCanSource ? (
              <GroupIcon sx={{ position: "absolute", top: "10px", right: "10px", color: "#67748e" }} />
            ) : null }
          </SuiBox>
        </SuiBox>
      </Card >
    </Link>
  )
}


// Typechecking props for the FolderCard
FolderCard.propTypes = {
  folderName: PropTypes.string.isRequired,
  folderId: PropTypes.number.isRequired,
  isFavorite: PropTypes.bool,
  owner: PropTypes.string,
  isPublic: PropTypes.bool,
  anyoneCanSource: PropTypes.bool,
}

FolderCard.defaultProps = {
  isFavorite: false,
  owner: "",
  isPublic: false,
  anyoneCanSource: false,
}

export default FolderCard
