import PropTypes from 'prop-types'
import { ReactSession } from 'react-client-session'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import placeholder from 'assets/images/placeholder.jpg'
// @mui material components
import Slider from '@mui/material/Slider'
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiBadge from 'components/SuiBadge'

function EnglishLevelEditor({ setSelectedEnglishLevel }) {

    ReactSession.setStoreType('localStorage')
    const token = ReactSession.get('token')

    const { appId } = useParams()
    
    const [comment, setComment] = useState(null)

    const [englishsetListData, setEnglishsetListData] = useState([])
    const [englishLevel, setEnglishLevel] = useState(null)

    const updateComment = newVal => {
        if (newVal) {
            if (newVal === 10) setComment(`${newVal} - Excellent English, 95% - 100% proficiency`);
            else if (newVal === 9) setComment(`${newVal} - Excellent English, 95% - 100% proficiency`);
            else if (newVal === 8) setComment(`${newVal} - Minor mistakes, 85% - 95% proficiency.`);
            else if (newVal === 7) setComment(`${newVal} - Can hold a complete conversation but there are some mistakes, 75-85% proficiency.`);
            else if (newVal === 6) setComment(`${newVal} - Can speak but conversation is choppy with several mistakes, 75% or less proficiency.`);
            else if (newVal === 5) setComment(`${newVal} - Currently learning; not able to speak yet`);
            else setComment(`${newVal} - Not able to speak English at all`)
        }
        else {
            /* eslint-disable-next-line */
            if (englishLevel === 10) setComment(`${englishLevel} - Excellent English, 95% - 100% proficiency`);
            else if (englishLevel === 9) setComment(`${englishLevel} - Excellent English, 95% - 100% proficiency`);
            else if (englishLevel === 8) setComment(`${englishLevel} - Minor mistakes, 85% - 95% proficiency.`);
            else if (englishLevel === 7) setComment(`${englishLevel} - Can hold a complete conversation but there are some mistakes, 75-85% proficiency.`);
            else if (englishLevel === 6) setComment(`${englishLevel} - Can speak but conversation is choppy with several mistakes, 75% or less proficiency.`);
            else if (englishLevel === 5) setComment(`${englishLevel} - Currently learning; not able to speak yet`);
            else setComment(`${englishLevel} - Not able to speak English at all`)
        }
    }

    const getEnglishLevel = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/${appId}/companyApplicationDetail/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })
            const data = await res.json()

            const englishLev = data?.data?.user?.candidate?.englishLevel
            const englishValidator = data?.data?.user?.candidate?.englishValidator

            if (data.success && englishLev) {
                setEnglishLevel(englishLev)

                setEnglishsetListData([
                    {
                        image: placeholder,
                        name: "Spoken english",
                        percent: `${englishLev}0%`,
                        color: "info",
                        value: englishLev * 10,
                        englishValidator: englishValidator,
                    }
                ])
            }
            
        } catch (error) {
            console.error('There was an error!', error)
        }
    }

    useEffect(() => { getEnglishLevel() }, [])
    useEffect(() => { updateComment() }, [englishLevel])
    
    return (
        <>
            {englishsetListData?.length > 0 ? (
                <SuiBox
                    component="li"
                    display="flex"
                    alignItems="center"
                    sx={{ background: '#f8f9fa', p: 2, borderRadius: '.3rem' }}
                >
                    <SuiBox
                        component="ul"
                        display="flex"
                        flexDirection="column"
                        width="100%"
                        sx={{ listStyle: "none" }}
                    >
                        <SuiBox component="li" w="100%" py={0.25}>
                            <SuiBox display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
                                <SuiBox>
                                    <SuiBadge badgeContent="English level" color="info" variant="contained" circular size="sm" container sx={{ zIndex: '0 !important' }} />
                                </SuiBox>

                                <SuiTypography
                                    variant="button"
                                    fontWeight="medium"
                                    color="text"
                                    textTransform="capitalize"
                                >
                                    {comment}
                                </SuiTypography>
                            </SuiBox>

                            <Slider
                                defaultValue={englishLevel}
                                valueLabelDisplay="auto"
                                step={1}
                                min={1}
                                max={10}
                                sx={{ zIndex: '0 !important' }}
                                onChange={e => {
                                    setSelectedEnglishLevel(e?.target?.value)
                                    updateComment(e?.target?.value)
                                }}
                            />
                        </SuiBox>
                    </SuiBox>
                </SuiBox>
            )
            : (
                <SuiBox mx={5} my={10} display='flex' justifyContent='center' alignItems='center'><CircularProgress size={40} /></SuiBox>
            )
        }
        </>
    )
}

export default EnglishLevelEditor

EnglishLevelEditor.propTypes = {
    setSelectedEnglishLevel: PropTypes.func.isRequired
}