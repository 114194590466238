/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Job from "layouts/pages/account/detailjob/components/Job";
import CompanyDashboardLayout from "examples/LayoutContainers/CompanyDashboardLayout";
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout";
import JobCard from "examples/Cards/JobCard";
import SuccessReport from "layouts/pages/jobFeedback/components/SuccessReport";

function Settings() {
  ReactSession.setStoreType("localStorage");
  const recruiter = ReactSession.get("recruiter")
  const company = ReactSession.get("company")
  const user = ReactSession.get("user");
  const token = ReactSession.get("token");

  let isGuest = true;
  if (user) {
    isGuest = false;
  }
  const sesionCandidate = { ...ReactSession.get("candidate") };
  let timerid = "";
  const [candidate, setSandidate] = useState(sesionCandidate);
  const [job, setJob] = useState({});
  const [feedbackCount, setFeedbackCount] = useState(null);

  const [successReport, setSuccessReport] = useState(false);
  const [report, setReport] = useState({});
  const [loading, setLoading] = useState(true);
  const [reportNotes, setReportNotes] = useState("");

  const [horizontalBarChartData, setHorizontalBarChartData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);

  const [similarJobs, setSimilarJobs] = useState([]);
  const [notes, setNotes] = useState([]);
  let searchUrl = decodeURIComponent(window.location.search);
  if (searchUrl.indexOf("#") >= 0) {
    searchUrl = searchUrl.substr(0, searchUrl.indexOf("#"))
  }
  const searchParams = new URLSearchParams(searchUrl);
  const jobId = searchParams.get('id');
  const code = searchParams.get('code');

  const [updatesCount, setUpdatesCount] = useState(0)

   const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  function getStringDate(date) {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2,'0');
    const dd = String(date.getDate()).padStart(2,'0');
    const hour = String(date.getHours());
    const min = String(date.getMinutes());
    
    return `${mm}/${dd}/${yyyy} - ${hour}:${min}`;
  }

  const formatDeclineReason = declineCode => {
    switch (declineCode) {
      case "0":
        return "Compensation misalignment"
      case "1":
        return "Employment duration too short"
      case "2":
        return "English skills not up to par"
      case "3":
        return "Location"
      case "4":
        return "Offer declined"
      case "5":
        return "Overqualified"
      case "6":
        return "Motivations misalignment"
      case "7":
        return "Underqualified"
      case "8":
        return "Unresponsive"
      case "9":
        return "Withdrew"
      case "10":
        return "Position closed"
      case "11":
        return "Better for another role"
      case "12":
        return "Culture Fit"
      case "13":
        return "Keep for future opportunities"
      case "14":
        return "Timing"
      default:
        return ""
    }
  }

  const fetchJobNotes = () => {
    const notesUrl = `${process.env.REACT_APP_API_ROUTE}/api/notes/getJobNotes/?job=${Number(jobId)}`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) headers.Authorization = `Token ${token}`;
    
    fetch(`${notesUrl}`, { headers, })
      .then(async response => {
        const data = await response.json();
        if (data.success) {
          const tempDefaultNotes = [];
          let arrobaIndex = 0;
          let restText = "";
          let spaceIndex = 0;
          let taged = "";
          let textProcesed = "";
          let noteDate = "";
          if (data.data && data.data.length) {
            // eslint-disable-next-line
            for (const note of data.data) {

              let resultText = "";
              let arrobaCount = 0;
              let noteText = note.content;
              if (note.application) {
                noteText = `${noteText} ${note.application.job.company.name} - ${note.application.job.seniorityName}`
              }
              arrobaCount = (noteText.match(/@/g) || []).length;
              while (arrobaCount > 0) {
                arrobaIndex = noteText.indexOf("@");
                restText = noteText.substring(arrobaIndex, noteText.length);
                textProcesed = noteText.substring(0, arrobaIndex);
                spaceIndex = restText.indexOf(" ");
                if (spaceIndex  === -1) {
                  spaceIndex = restText.length;
                }
                taged = `<strong style="color: #0b4170;">${restText.substring(0, spaceIndex)}</strong>`;
                resultText = `${resultText} ${textProcesed} ${taged}`;
                noteText = `${restText.substring(spaceIndex, restText.length)}`;
                arrobaCount -= (taged.match(/@/g) || []).length;
              }
              resultText = `${resultText} ${noteText}`;
              noteDate = getStringDate(new Date(note.createdAt));
              tempDefaultNotes.push({
                name: `${note.author.first_name} ${note.author.last_name}`, 
                date: noteDate, 
                message: resultText, 
                text: note?.content,
                id: note?.id,
                authorId: note?.author?.id,
                companyId: note?.job?.company
              });
            }
          }

          if (tempDefaultNotes.length) {
            setNotes(tempDefaultNotes)
          }
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  const fetchSuccessReport = () => {
    const notesUrl = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${Number(jobId)}/getJobReport/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) headers.Authorization = `Token ${token}`;
    
    fetch(`${notesUrl}`, { headers, })
      .then(async response => {
        const data = await response.json();
        if (data.success) {
          const reportData = data.data;
          setLoading(false)
          setReport(reportData)
          setReportNotes(data.data.reportNotes)

          // decline reasons chart
          const labelsValues = {};
          const labels = [];
          const dataCounts = [];
          if (reportData.reportDeclines && reportData.reportDeclines.length) {
            // eslint-disable-next-line
            for (const reportDecline of reportData.reportDeclines) {
              labelsValues[reportDecline.declinedReason] = labelsValues[reportDecline.declinedReason] ? labelsValues[reportDecline.declinedReason] +  reportDecline.count : reportDecline.count;
            }

            // eslint-disable-next-line
            for (const [key, value] of Object.entries(labelsValues)) {
              labels.push(formatDeclineReason(key))
              dataCounts.push(value)
            }
          }

          const tempHorizontalBarChartData =  {
            labels: labels,
            datasets: [
              {
                label: "Decline reasons for this job",
                color: "dark",
                data: dataCounts,
              },
            ],
          };

          setHorizontalBarChartData(tempHorizontalBarChartData);

          // report status chart
          const reportLabelsValues = {};
          const reportLabels = [];
          const reportDataCounts = [];
          if (reportData.reportStatus && reportData.reportStatus.length) {
            // eslint-disable-next-line
            for (const reportStatus of reportData.reportStatus) {
              reportLabelsValues[reportStatus.status] = reportLabelsValues[reportStatus.status] ? reportLabelsValues[reportStatus.status] +  reportStatus.count : reportStatus.count;
            }

            // eslint-disable-next-line
            for (const [key, value] of Object.entries(reportLabelsValues)) {
              reportLabels.push(key)
              reportDataCounts.push(value)
            }
          }

          const tempPieChartData = {
            labels: reportLabels,
            datasets: {
              label: "Projects",
              backgroundColors: ["primary", "secondary", "info", "success", "warning", "error", "light", "dark"],
              data: reportDataCounts,
            },
          };
          setPieChartData(tempPieChartData);

        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => {
    if (jobId) {
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      let url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${jobId}/jobStandalone/`;
      if (token) {
        url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/jobDetail/?jobId=${jobId}`;
        headers.Authorization = `Token ${token}`;
      }
      fetch(url, { headers, })
        .then(async response => {
          const res = await response.json();
        validateToken(res) // eslint-disable-line
          if (res.success) {
            setJob(res.data.job);
            setFeedbackCount(res?.data?.feedbackCount)
            setSimilarJobs(res.similarJobs);
            document.title = `${res.data.job.seniorityName} | TECLA`;
            if (recruiter) {
              document.title = `${res.data.job.company.name} ${res.data.job.seniorityName} | TECLA`;
            }
          } else if (res.detail && res.detail.includes("token")) {
            window.location.replace(`/t/login`);
          } else {
            $(".alertErrorLoaderBox").show();
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });

      if (token)  {
        fetchJobNotes()
        fetchSuccessReport()
      }
    }
  }, [jobId]);

  function getDifferenceInSeconds(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / 1000;
  }

  function timeAgo(date) {
    const currentDate = new Date();
    const seconds = Math.floor(getDifferenceInSeconds(new Date(date), currentDate));
    const mins = Math.floor(seconds / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    let timePassed = "";
    if (months >= 1) {
      timePassed = `${months}M ago`;
    } else if (days >= 1) {
      timePassed = `${days}d ago`;
    } else if (hours >= 1) {
      timePassed = `${hours}h ago`;
    } else if (mins >= 1) {
      timePassed = `${mins}m ago`;
    } else if (seconds >= 1) {
      timePassed = `${seconds}s ago`;
    }

    return timePassed;
  }

  function hotLabel(isHot) {
    if (isHot) {
      return { color: "error", label: "HOT JOB" };
    }

    return {};
  }

  function showMessage(text) {
    $('.alertSuccessBox div div').text(text);
    window.scrollTo(0, 0);
    $('.alertSuccessBox').show();
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      $('.alertSuccessBox').hide();
    }, 3000);
  }

  // eslint-disable-next-line
  $(document).unbind().on("click", ".saveJob", function () {
    
    let recipeUrl = (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin") ? `${process.env.REACT_APP_API_ROUTE}/api/recruiters/recruiterAddFavoriteJob/` : `${process.env.REACT_APP_API_ROUTE}/api/candidates/candidateSaveJob/`;
    let saved = true;
    if ($(this).hasClass("saved")) {
      saved = false;
      recipeUrl = (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin") ? `${process.env.REACT_APP_API_ROUTE}/api/recruiters/recruiterRemoveFavoriteJob/` : `${process.env.REACT_APP_API_ROUTE}/api/candidates/candidateUnsavedJob/`;
    }

    const postBody = { 'jobId': job.id };
    const requestMetadata = {
      method: 'POST',
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Authorization": `Token ${token}`
      },
      body: new URLSearchParams(postBody)
    };

    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {


        if (response.success) {
          if (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin") {
            if (saved) showMessage("Job saved to favorites")
            else showMessage("Job removed from favorites")
            ReactSession.set("recruiter", response.data)
          }
          else {
            const updateCandidate = { ...candidate };
            if (!updateCandidate.favoriteJobs) {
              updateCandidate.favoriteJobs = [];
            }
            if (saved) {
              updateCandidate.favoriteJobs.push({ "id": job.id });
              showMessage("Job saved to favorites");
            } else {
              const newFavoriteJobs = [];
              // eslint-disable-next-line
              for (const favoriteJob of updateCandidate.favoriteJobs) {
                if (job.id !== favoriteJob.id) {
                  newFavoriteJobs.push(favoriteJob);
                }
              }
              updateCandidate.favoriteJobs = newFavoriteJobs;
              showMessage("Job removed from favorites");
            }
            ReactSession.set("candidate", updateCandidate);
            setSandidate(updateCandidate);
          }

          setUpdatesCount(prevState => prevState+1)
        }
      });
  });

  return (
    <>
      {// eslint-disable-next-line
      company ? (
          <CompanyDashboardLayout>
            <SuiBox className="alertSuccessBox" display="none">
              <SuiAlert color="info" display="none">Link copied to clipboard!</SuiAlert>
            </SuiBox>
            <SuiBox className="alertErrorLoaderBox" display="none">
              <SuiAlert color="error" display="none">Sorry this position is not currently active.</SuiAlert>
            </SuiBox>
            <SuiTypography variant="h3" fontWeight="bold" ml={2} mb={3}>Job Details</SuiTypography>
            { !job || !job.company ? (
              <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={10}>
                <MoonLoader color="#17c1e8" loading css={override} size={70} />
              </SuiBox>
            ) : (
              <SuiBox>
                <Job job={job} setJob={setJob} candidate={candidate} isGuest={isGuest} code={code} isCompany={true} />
              </SuiBox>
            )}
            { similarJobs && similarJobs.length ? (
              <Grid container spacing={3} mt={1} mb={2}>
                <Grid item xs={12} sm={12}>
                  <SuiTypography variant="h4" fontWeight="bold" ml={1}>SIMILAR JOBS 👀</SuiTypography>
                </Grid>
                {similarJobs.map(similarJob => (
                  <Grid item xs={12} sm={4}>
                    <JobCard
                      by={{ name: similarJob.company.name, date: timeAgo(similarJob.createdAt) }}
                      badge={hotLabel(similarJob.isHot)}
                      title={similarJob.seniorityName}
                      description={similarJob.description}
                      skills={similarJob.requiredSkills}
                      value={{ type: "$", from: similarJob.minSalary, to: similarJob.maxSalary, method: "Month" }}
                      action={{ type: "internal", route: `/job?id=${similarJob.id}`, label: "see more" }}
                      subtitle={similarJob.subtitle}
                      job={similarJob}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : ( null )}
          </CompanyDashboardLayout>
        )
      :
      (user && (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin")) ? (
          <RecruiterDashboardLayout>
            <SuiBox className="alertSuccessBox" display="none">
              <SuiAlert color="info" display="none">Link copied to clipboard!</SuiAlert>
            </SuiBox>
            <SuiBox className="alertErrorLoaderBox" display="none">
              <SuiAlert color="error" display="none">Sorry this position is not currently active.</SuiAlert>
            </SuiBox>
            <SuccessReport 
              successReport={successReport} 
              setSuccessReport={setSuccessReport} 
              job={job} 
              report={report}
              reportNotes={reportNotes}
              setReportNotes={setReportNotes}
              loading={loading} 
              setLoading={setLoading}
              horizontalBarChartData={horizontalBarChartData}
              pieChartData={pieChartData} />
            <SuiTypography variant="h3" fontWeight="bold" ml={2} mb={3}>Job Details</SuiTypography>
            { !job || !job.company ? (
              <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={10}>
                <MoonLoader color="#17c1e8" loading css={override} size={70} />
              </SuiBox>
            ) : (
              <SuiBox>
                <Job
                  job={job}
                  setJob={setJob}
                  candidate={candidate}
                  isGuest={isGuest}
                  code={code}
                  isCompany={false}
                  updatesCount={updatesCount}
                  notes={notes}
                  fetchJobNotes={fetchJobNotes}
                  setNotes={setNotes}
                  token={token}
                  feedbackCount={feedbackCount}
                  setSuccessReport={setSuccessReport}
                />
              </SuiBox>
            )}
            { similarJobs && similarJobs.length ? (
              <Grid container spacing={3} mt={1} mb={2}>
                <Grid item xs={12} sm={12}>
                  <SuiTypography variant="h4" fontWeight="bold" ml={1}>SIMILAR JOBS 👀</SuiTypography>
                </Grid>
                {similarJobs.map(similarJob => (
                  <Grid item xs={12} sm={4}>
                    <JobCard
                      by={{ name: similarJob.company.name, date: timeAgo(similarJob.createdAt) }}
                      badge={hotLabel(similarJob.isHot)}
                      title={similarJob.seniorityName}
                      description={similarJob.description}
                      skills={similarJob.requiredSkills}
                      value={{ type: "$", from: similarJob.minSalary, to: similarJob.maxSalary, method: "Month" }}
                      action={{ type: "internal", route: `/job?id=${similarJob.id}`, label: "see more" }}
                      subtitle={similarJob.subtitle}
                      job={similarJob}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : ( null )}
          </RecruiterDashboardLayout>
        )
      : (
        <BaseLayout>
          <SuiBox className="alertSuccessBox" display="none">
            <SuiAlert color="info" display="none">Link copied to clipboard!</SuiAlert>
          </SuiBox>
          <SuiBox className="alertErrorLoaderBox" display="none">
            <SuiAlert color="error" display="none">Sorry this position is not currently active.</SuiAlert>
          </SuiBox>
          <SuiTypography variant="h3" fontWeight="bold" ml={2} mb={3}>Job Details</SuiTypography>
          { !job || !job.company ? (
            <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={10}>
              <MoonLoader color="#17c1e8" loading css={override} size={70} />
            </SuiBox>
          ) : (
            <SuiBox>
              <Job job={job} setJob={setJob} candidate={candidate} isGuest={isGuest} code={code} isCompany={false}/>
            </SuiBox>
          )}
          { similarJobs && similarJobs.length ? (
            <Grid container spacing={3} mt={1} mb={2}>
              <Grid item xs={12} sm={12}>
                <SuiTypography variant="h4" fontWeight="bold" ml={1}>SIMILAR JOBS 👀</SuiTypography>
              </Grid>
              {similarJobs.map(similarJob => (
                <Grid item xs={12} sm={4}>
                  <JobCard
                    by={{ name: similarJob.company.name, date: timeAgo(similarJob.createdAt) }}
                    badge={hotLabel(similarJob.isHot)}
                    title={similarJob.seniorityName}
                    description={similarJob.description}
                    skills={similarJob.requiredSkills}
                    value={{ type: "$", from: similarJob.minSalary, to: similarJob.maxSalary, method: "Month" }}
                    action={{ type: "internal", route: `/job?id=${similarJob.id}`, label: "see more" }}
                    subtitle={similarJob.subtitle}
                    job={similarJob}
                  />
                </Grid>
              ))}
            </Grid>
          ) : ( null )}
        </BaseLayout>
      )
  }
  </>
  );
}

export default Settings;
