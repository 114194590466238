/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// MUI components
import PropTypes from "prop-types";
import { Icon, Modal } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress'
// SUI components
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import Tooltip from "@mui/material/Tooltip";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import EditIcon from '@mui/icons-material/Edit';
// Formik
import { Formik, Form } from "formik";
// NewUser layout schemas for form and form feilds
import EditCandidateForm from "layouts/pages/users/new-user/components/EditCandidate";
import validations from "examples/Modal/EditCandidate/schemas/validations";
import form from "examples/Modal/EditCandidate/schemas/form";
// import initialValues from "examples/Modal/EditCandidate/schemas/initialValues";

function getContent(formData, formCandidate, formSetCandidate) {
    return <EditCandidateForm formData={formData} candidate={formCandidate} setCandidate={formSetCandidate} />;
}

function EditCandidateModal({ candidate, setCandidate }) {
    ReactSession.setStoreType("localStorage");
    const token = ReactSession.get("token");
    const user = ReactSession.get("user")

    const [open, setOpen] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false)

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    // Validation
    const [activeStep, setActiveStep] = useState(0);
    // const steps = getSteps();
    const { formId, formField } = form;
    const currentValidation = validations[activeStep];
    const isLastStep = false;
    let timerid = "";

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const submitForm = async (values, actions) => {
        await sleep(1000);

        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values, null, 2));

        // actions.setSubmitting(false);
        actions.resetForm();

        setActiveStep(0);
    };

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            submitForm(values, actions);
        } else {
            // setActiveStep(activeStep + 1);
            actions.setTouched({});
            // actions.setSubmitting(false);
        }
    };

    const signUpClick = async () => {
        await sleep(100);
        if ($("#new-user-form .MuiTypography-caption:visible").text().length === 0) {
            const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidate.user.id}/teclaUpdateCandidate/`;
             const postBody = { 
                user: {
                    'email': $("input[name=email]").val(),
                    'first_name': $("input[name=firstName]").val(),
                    'last_name': $("input[name=lastName]").val(),
                },
                'title': $("input[name=title]").val(),
                'bio': $("textarea[name=bio]").val(),
            }

            const requestMetadata = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(postBody)
            };

            setShowSpinner(true);

            fetch(recipeUrl, requestMetadata)
                .then(res => res.json())
                .then(response => {
                    if (response.success) {
                        $(".alertSuccesModalBox div div").text("Candidate account Updated Successfully");
                        $(".alertSuccesModalBox").show();

                        if (timerid) {
                            clearTimeout(timerid);
                        }
                        const tempCandidate = {...candidate}
                        tempCandidate.user.first_name = $("input[name=firstName]").val()
                        tempCandidate.user.last_name = $("input[name=lastName]").val()
                        tempCandidate.user.email = $("input[name=email]").val()
                        tempCandidate.title = $("input[name=title]").val()
                        tempCandidate.bio = $("textarea[name=bio]").val()
                        setCandidate(tempCandidate)
                        timerid = setTimeout(() => {
                            $(".alertSuccesModalBox").hide();
                            handleClose()
                        }, 2000);
                    } else {
                        let errorMsg = "Error Editing Profile";
                        if (response.data && response.data.error) {
                            errorMsg = response.data.error;
                        }
                        // alertSuccesModalBox
                        $(".alertErrorBox div div").text(errorMsg);
                        $(".alertErrorBox").show();
                        if (timerid) {
                            clearTimeout(timerid);
                        }
                        timerid = setTimeout(() => {
                            $(".alertErrorBox").hide();
                        }, 3000);
                    }
                    setShowSpinner(false);
                });
        }
    }

    // Forcing form initial values, Formik default way doesn't update correctly
  const {
    formField: {
      firstName,
      lastName,
      title,
      email,
      bio,
      photo,
    },
  } = form

    return (
        <>
            { user && (user.type === "tecla_admin" || user.type === "tecla_recruiter" || user.type === "tecla_manager") ? (
                <Tooltip title="Edit user" placement="top" sx={{ verticalAlign: "middle", ml: 1, cursor: "pointer" }}>
                  <EditIcon onClick={handleOpen} />
                </Tooltip>
            ) : null }
            <Modal
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <SuiBox sx={{
                    display: "flex",
                    position: 'relative',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: "60vw", md: "42rem", lg: "43rem", xl: "49rem" },
                    height: { xs: "100vh", md: "auto" },
                    maxHeight: { md: "101vh" },
                    minHeight: { md: "30rem" },
                    background: "white",
                    border: 'none',
                    boxShadow: 16,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                }}>
                    <SuiBox>
                        <SuiBox className="alertErrorBox" display="none">
                          <SuiAlert color="error" className="alertError">Error.</SuiAlert>
                        </SuiBox>
                        <SuiBox className="alertSuccesModalBox" display="none">
                          <SuiAlert color="info" className="alertSuccesModal">Success.</SuiAlert>
                        </SuiBox>
                        <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                            <SuiTypography id="child-modal-title" variant="overline" opacity={0.75}></SuiTypography>
                            <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                        </SuiBox>
                    </SuiBox>

                    <SuiBox display="flex">
                        <SuiBox sx={{ width: { xs: "100%", md: "100%" } }} mr={2}>
                            <Formik
                                initialValues={{
                                  [firstName.name]: candidate?.user?.first_name ? candidate?.user?.first_name : "",
                                  [lastName.name]: candidate?.user?.last_name ? candidate?.user?.last_name : "",
                                  [title.name]: candidate?.title ? candidate?.title : "",
                                  [bio.name]: candidate?.bio ? candidate?.bio : "",
                                  [email.name]: candidate?.user?.email ? candidate?.user?.email : "",
                                  [photo.name]: "",
                                }}
                                validationSchema={currentValidation}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, issubmitting }) => (
                                    <Form id={formId} autoComplete="off" type="POST" actions="#">
                                        <SuiBox>
                                            {getContent({
                                                values,
                                                touched,
                                                formField,
                                                errors,
                                            }, candidate, setCandidate)}

                                            <SuiBox mt={4}>
                                                <SuiBox sx={{ textAlign: "right" }} justifyContent="space-evenly" alignItems="right" flexWrap="wrap" mt={2} mb={1}>
                                                    {showSpinner ? (
                                                      <SuiButton sx={{ background: "#fff !important", boxShadow: "none !important" }} size="large" color="white" variant="gradient" circular>
                                                        <CircularProgress />
                                                      </SuiButton>)
                                                    : (
                                                        <SuiButton size="large" type="submit" color="info" issubmitting={issubmitting} variant="gradient" onClick={signUpClick} circular>
                                                            save Changes
                                                        </SuiButton>
                                                    ) }
                                                </SuiBox>
                                            </SuiBox>

                                        </SuiBox>
                                    </Form>
                                )}
                            </Formik>
                        </SuiBox>
                    </SuiBox>
                </SuiBox>
            </Modal>
        </>
    );

}

// Typechecking props for the EditCandidateModal
EditCandidateModal.propTypes = {
  candidate: PropTypes.objectOf(PropTypes.any).isRequired,
  setCandidate: PropTypes.func.isRequired, 
};

export default EditCandidateModal;
