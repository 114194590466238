/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react"
// react-router-dom components
import { useLocation, } from "react-router-dom"
// formik components
import { Formik, Form } from "formik"
// @mui material components
import Checkbox from "@mui/material/Checkbox"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiAlert from "components/SuiAlert"
// NewUser layout schemas for form and form feilds
import SignUp from "layouts/pages/users/new-user/components/Join"
import validations from "layouts/pages/users/new-user/schemas/validations"
import form from "layouts/pages/users/new-user/schemas/formCompany"
import initialValues from "layouts/pages/users/new-user/schemas/initialValues"
// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout"
// Image
import companyAndRecruitersLogin from "assets/images/companyAndRecruitersLogin.jpg"



function getContent(formData) {
  return <SignUp formData={formData} />;
}

function Illustration() {

  const location = useLocation()
  const companyName = location ? location?.search.split("company=")[1].split("&")[0].replace("+", " ") : null
  const urlToken = location ? location?.search.split("token=")[1].split("&")[0] : null
  const urlId = location ? location?.search.split("id=")[1].split("&")[0] : null

  // Validation
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = false;
  let timerid = "";

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(values, null, 2));

    // actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      // setActiveStep(activeStep + 1);
      actions.setTouched({});
      // actions.setSubmitting(false);
    }
  };

  const signUpClick = async () => {
    await sleep(100);

    console.log($(".MuiTypography-caption:visible").text().length === 0, $("input[type='checkbox']").is(':checked'))
    if ($(".MuiTypography-caption:visible").text().length === 0 && $("input[type='checkbox']").is(':checked')) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/companies/companyConfirmUser/`;
      const postBody = {
        'first_name': $("input[name=firstName]").val(),
        'last_name': $("input[name=lastName]").val(),
        'password': $("input[name=password]").val(),
        'token': urlToken,
        'id': urlId,
      };

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: new URLSearchParams(postBody)
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          $(".suiAlertMsg").remove();

          if (response.success) {
            $(".suiAlertMsg").remove();
            const successAlert = $($('.alertSuccessBox .alertSuccess').parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(successAlert);

            if (timerid) {
              clearTimeout(timerid);
            }

            timerid = setTimeout(() => {
              window.location.replace('/c/login');
            }, 2000);
          } else {
            $(".suiAlertMsg").remove();

            const errorAlert = $($('.alertSuccessBox .alertError').text(response.data.error).parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(errorAlert);
          }
        });
    } else if (!$("input[type='checkbox']").is(':checked')) {
      $(".suiAlertMsg").remove();
      $(".suiAlertMsg").remove();

      const errorAlert = $($('.alertSuccessBox .alertError').text("Agreement with Terms of Service is required").parent().html()).addClass("suiAlertMsg");
      $(".suiAlertBox").parent().prepend(errorAlert);
    }
  }

  return (
    <IllustrationLayout
      color="primary"
      title={companyName ? `Join ${companyName} in TECLA` : "Join [companyName] in TECLA"}
      description="Please fill the fields below to sign up as a member of your company."
      illustration={{
        image: companyAndRecruitersLogin,
      }}
    >
      <SuiBox className="suiAlertBox">
        <SuiBox display="flex" alignItems="end">
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, issubmitting }) => (
              <Form id={formId} autoComplete="off" type="POST" actions="#">
                <SuiBox>
                  {getContent({ values, touched, formField, errors })}
                  <SuiBox>
                    <SuiBox>
                      <Checkbox />
                      <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        sx={{ userSelect: "none" }}
                        name="terms"
                      >
                        I Agree with Tecla’s <a target="_blank" rel="noreferrer" href="https://www.tecla.io/tos/"><u>Terms of Service</u></a> and <a target="_blank" rel="noreferrer" href="https://www.tecla.io/privacy/"><u>Privacy policy</u></a>.
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                  <SuiBox mt={4} mb={1}>
                    <SuiButton type="submit" variant="gradient" color="primary" size="large" issubmitting={issubmitting} onClick={signUpClick} fullWidth>
                      Sign up
                    </SuiButton>
                  </SuiBox>

                </SuiBox>
              </Form>
            )}
          </Formik>
        </SuiBox>
        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertSuccess">Welcome to TECLA!</SuiAlert>
        </SuiBox>
        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertError" color="error">EMAIL IS ALREADY IN USE</SuiAlert>
        </SuiBox>
      </SuiBox>
    </IllustrationLayout>
  )
}

export default Illustration
