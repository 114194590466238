/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Tooltip from "@mui/material/Tooltip";
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import AssignmentIcon from '@mui/icons-material/Assignment';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function ActionCell({ jobId, companyId, candidateId, managerId, isManaged, openManagerModal, companyJobs, company, setCompanyForSalesModal }) {

	let link = `/job?id=${jobId}`
	let title = "View job detail"
	let className = "";
	let target = "_blank";

	if (companyId !== 0) {
		link = `company/${companyId}`
		title = "View company detail"
	}
	if (companyJobs !== 0) {
		link = `/r/jobs/?company=${companyJobs}`
		title = "View company jobs"
	}
	if (candidateId !== 0) {
		link = `profile/${candidateId}`
		title = "View candidate detail"
	}
	if (managerId !== 0) {
		link = `#`;
		title = "Add Manager";
		className = "addManager";
		target = "";
	}

	return (
		<SuiBox display="flex" alignItems="center" justifyContent="center" flexDirection="column">
			{ managerId !== 0 ? (
				<SuiTypography onClick={() => { openManagerModal(managerId) }} data-id={managerId} variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} component="a" mr={1} href={link} target={target}>
					<Tooltip title={title} placement="top">
						{ // eslint-disable-next-line
						companyId !== 0 ?
							<ApartmentIcon />
							// eslint-disable-next-line
							: candidateId !== 0 ?
								<PersonIcon />
								: managerId !== 0 && !isManaged ?
									<AddIcon />
									:
									<WorkIcon sx={{ display: "none" }} />
						}
					</Tooltip>
				</SuiTypography>
			) : (
				<SuiTypography className={className} data-id={managerId} variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} component="a" mr={1} href={link} target={target}>
					<Tooltip title={title} placement="top">
						{ // eslint-disable-next-line
						companyId !== 0 ?
							<ApartmentIcon />
							// eslint-disable-next-line
							: companyJobs !== 0 ?
								<SendIcon />
								: managerId !== 0 && !isManaged ?
									<AddIcon />
									:
									<WorkIcon sx={{ display: "none" }} />
						}
					</Tooltip>
				</SuiTypography>
			)}

			{setCompanyForSalesModal && (
				<SuiTypography className={className} data-id={managerId} variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} onClick={() => {
					setCompanyForSalesModal(company)
				}}>
					<Tooltip title="Sales" placement="top">
						<AssignmentIcon />
					</Tooltip>
				</SuiTypography>
			)}
		</SuiBox>
	);
}

// Setting default value for the props of ActionCell
ActionCell.defaultProps = {
	jobId: 0,
	companyId: 0,
	candidateId: 0,
	managerId: 0,
	isManaged: false,
	openManagerModal: null,
	companyJobs: 0,
	company: undefined,
	setCompanyForSalesModal: undefined,
};

// Typechecking props for the ActionCell
ActionCell.propTypes = {
	jobId: PropTypes.number,
	companyId: PropTypes.number,
	candidateId: PropTypes.number,
	managerId: PropTypes.number,
	isManaged: PropTypes.bool,
	openManagerModal: PropTypes.func,
	companyJobs: PropTypes.number,
	company: PropTypes.object, // eslint-disable-line
	setCompanyForSalesModal: PropTypes.func,
};

export default ActionCell;
