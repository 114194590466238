/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiAlert from 'components/SuiAlert'
import SuiPagination from 'components/SuiPagination'
import SuiButton from 'components/SuiButton'
// MUI components
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import useMediaQuery from '@mui/material/useMediaQuery'
// NewslettersIssues page components
import StudentDashboardLayout from 'examples/LayoutContainers/StudentDashboardLayout'
import BaseLayout from 'layouts/pages/account/components/BaseLayout'
import NlItemCard from './nlItemCard'

function NewslettersIssues() {

    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')

    ReactSession.setStoreType('localStorage')
    const token = ReactSession.get('token')
    const student = ReactSession.get('student')
    
    const { nlType } = useParams()

    const [page, setPage] = useState(1)

    const [newslettersToShow, setNewslettersToShow] = useState([])
    const [showError, setShowError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubscribing, setIsSubscribing] = useState(false)
    const [showSubscribeSucces, setShowSubscribeSucces] = useState(false)

    const [pageOpts, setPageOpts] = useState([])

    const fetchNewsletters = () => {
        setIsLoading(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/getBoostNewsletter/?page=${page}&type=${nlType}`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            },
        })
        .then(async response => {
            const data = await response.json()
            setIsLoading(false)

            if (data.success) {
                setNewslettersToShow(data.data)
                
                /* PAGINATION */
                const pageOptsArr = []

                const totalPagesAmount = data?.data?.total_pages
                
                // totalPagesAmount is bigger than 5, so we only want to show 5 options
                if (totalPagesAmount > 5) {
                    // if the current page is 1, 2 or 3, we want to show the first 5 options
                    if (page <= 3) {
                        for (let i = 1; i <= 5; i++) { // eslint-disable-line
                            pageOptsArr.push(i)
                        }
                        pageOptsArr.push('...')
                    }
                    // if the current page is the last 3 pages, we want to show the last 5 options
                    else if (page >= totalPagesAmount - 2) {
                        pageOptsArr.push('...')
                        for (let i = totalPagesAmount - 4; i <= totalPagesAmount; i++) { // eslint-disable-line
                            pageOptsArr.push(i)
                        }
                    }
                    // if the current page is in the middle, we want to show the 5 options around the current page
                    else {
                        pageOptsArr.push('...')
                        for (let i = page - 2; i <= page + 2; i++) { // eslint-disable-line
                            pageOptsArr.push(i)
                        }
                        pageOptsArr.push('...')
                    }
                }
                else {
                    // populate array with values starting from 1 to the value of total_pages
                    for (let i = 1; i <= totalPagesAmount; i++) { // eslint-disable-line
                        pageOptsArr.push(i)
                    }
                }


                setPageOpts(pageOptsArr)
            }
            else {
                setShowError(true)
                setTimeout(() => { setShowError(false) }, (2000))
            }
        })
        .catch(error => {
            console.error('There was an error!', error)
            setShowError(true)
            setTimeout(() => { setShowError(false) }, (2000))
        })
    }

    useEffect(() => { fetchNewsletters() }, [])
    useEffect(() => { fetchNewsletters() }, [page])

    const subscribeToNewsletter = async () => {
        try {
            setIsSubscribing(true)

            const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/subscribeToBoostNewsletter/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({ type: nlType })
            })

            const data = await res.json()
            setIsSubscribing(false)

            if (data.success) {
                setShowSubscribeSucces(true)
                setTimeout(() => { setShowSubscribeSucces(false) }, (2000))
            }
            else {
                setShowError(true)
                setTimeout(() => { setShowError(false) }, (2000))
            }
            
        } catch (error) {
            console.error('There was an error!', error)
            setShowError(true)
            setTimeout(() => { setShowError(false) }, (2000))
        }
    }

    useEffect(() => { document.title = student ? 'Newsletters | Boost' : 'Newsletters | TECLA' }, [])

    const setLayout = children => {
        if (student) return <StudentDashboardLayout>{children}</StudentDashboardLayout>
        return <BaseLayout>{children}</BaseLayout>
    }

    return (
        <>
            {setLayout(
                <>
                    {showSubscribeSucces && <SuiAlert color='success'>Thank you  for subscribing to the newsletter.</SuiAlert>}

                    {showError && <SuiAlert color='error'>There was an error, please try again later.</SuiAlert>}
                    
                    <SuiBox
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: isMobile ? 'column' : 'row'
                        }}
                    >
                        <SuiTypography variant='h3' fontWeight='bold' ml={1} mt={isMobile ? 6 : 'auto'}>
                            {nlType === '1' ? 'English Tips & Tricks' : 'Lessons on Employability'}
                        </SuiTypography>

                        <SuiButton
                            variant='gradient'
                            fullWidth={isMobile}
                            color='info'
                            disabled={isSubscribing}
                            sx={{
                                my: isMobile ? 2 : 'auto',
                                mr: isMobile ? 'auto' : '2%'
                            }}
                            onClick={() => subscribeToNewsletter()}
                        >
                            {isSubscribing ? <CircularProgress size={20} /> : 'Subscribe'}
                        </SuiButton>
                    </SuiBox>

                    {isLoading && (
                        <SuiBox my={25} mx='auto' display='flex' justifyContent='center' alignItems='center'><CircularProgress size={40} /></SuiBox>
                    )}

                    <SuiBox display='flex' alignItems='center' flexWrap='wrap'>
                        {!isLoading && newslettersToShow?.data?.map(nl => (
                            <SuiBox
                                key={nl?.id}
                                sx={{
                                    width: isMobile ? '100%' : '31%',
                                    margin: isMobile ? '3% 1%' : '1%',
                                    minWidth: isMobile ? '100%': '29rem',
                                    cursor: 'pointer',
                                }}
                            >
                                <NlItemCard
                                    image={nl?.thumbnail_url}
                                    title={nl?.title}
                                    description={nl?.subtitle}
                                    link={student ? `/newsletters/${nlType}/${nl?.id}` : `/t/newsletters/${nlType}/${nl?.id}`}
                                />
                            </SuiBox >
                        ))}
                    </SuiBox >
                    
                    {!isLoading && newslettersToShow?.data && (
                        <SuiBox mb={9} style={{ margin: '20px auto 40px auto', widht: '100%', display: 'flex', justifyContent: 'center' }}>
                            <SuiPagination>
                                {newslettersToShow?.page !== 1 && (
                                    <SuiPagination item onClick={() => setPage(prev => prev-1)}>
                                        <Icon>keyboard_arrow_left</Icon>
                                    </SuiPagination>
                                )}

                                {pageOpts.map(pageOpt => (
                                    <SuiPagination
                                        item
                                        key={pageOpt}
                                        onClick={() => {if (pageOpt !== '...') setPage(pageOpt)}}
                                        active={page === pageOpt}
                                    >
                                        {pageOpt}
                                    </SuiPagination>
                                ))}
                                
                                {newslettersToShow?.page !== newslettersToShow?.total_pages && (                            
                                    <SuiPagination item onClick={() => setPage(prev => prev+1)}>
                                        <Icon>keyboard_arrow_right</Icon>
                                    </SuiPagination>
                                )}
                            </SuiPagination>
                        </SuiBox>
                    )}
                </>
            )}
        </>
    )
}

export default NewslettersIssues