/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import { useParams } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import SuiAlert from "components/SuiAlert";
// MUI components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';

function addToRecruiterSourcingModal({ showAddToRecruiterSourcing, setshowAddToRecruiterSourcing, host, candidateId }) {

    const token = ReactSession.get("token")
    
    const { userId } = useParams()

    const [desiredJob, setDesiredJob] = useState(null)
    const [jobOptions, setJobOptions] = useState([])
    
    const [showSpinner, setShowSpinner] = useState(false)
    const [showBigSpinner, setShowBigSpinner] = useState(true)
    const [showDoneIcon, setShowDoneIcon] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showRepeatedError, setShowRepeteatedError] = useState(false)

    const fetchActiveJobs = () => {
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/teclaGetAllActiveJobs/`, {
            headers: { "Authorization": `Token ${token}` }
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    const opts = []

                    data.data.forEach(job => opts.push({ value: job.id, label: `${job.company.name} - ${job.seniorityName}` }))
                    
                    setJobOptions(opts)
                    setShowBigSpinner(false)
                }
            })
            .catch(error => {
                console.error('There was an error!', error)
                setShowBigSpinner(false)
                setShowError(true)

                setTimeout(() => setShowError(false), 3000)
            })
    }

    useEffect(() => fetchActiveJobs(), [])

    // eslint-disable-next-line
    const saveCandidateToSourcing = () => {
        setShowSpinner(true)

        const requestBody = {
            job: desiredJob,
            candidate: host === "r/talent" ? candidateId : userId
        }

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/sourcings/createSourcing/`, {
            method: 'POST',
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "Authorization": `Token ${token}`
            },
            body: new URLSearchParams(requestBody)
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setShowSpinner(false)
                    setShowDoneIcon(true)

                    setTimeout(() => {
                        setshowAddToRecruiterSourcing(false)
                        setShowDoneIcon(false)
                        setDesiredJob(null)
                    }, 1500)
                }
                else if (data.data.error && data.data.error.includes("duplicate")) {
                    setShowRepeteatedError(true)
                    setShowSpinner(false)

                    setTimeout(() => setShowRepeteatedError(false), 3000)
                }
                else {
                    setShowSpinner(false)
                    setShowError(true)
    
                    setTimeout(() => setShowError(false), 3000)
                }
            })
            .catch(error => {
                console.error('There was an error!', error)
                setShowSpinner(false)
                setShowError(true)
                
                setTimeout(() => setShowError(false), 3000)
            })
    }
        
    return (
        <Modal open={showAddToRecruiterSourcing} onClose={() => setshowAddToRecruiterSourcing(false)}>
            <SuiBox sx={{
                width: "650px",
                height: "auto",
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: 'none',
                boxShadow: 24,
                borderRadius: { xs: 0, md: 4 },
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }} >
                {showBigSpinner ?
                    <SuiBox my={15} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={40} /></SuiBox>
                    :
                    <>
                        {showDoneIcon && <SuiAlert color="info">Candidate added to sourcing!</SuiAlert>}
                        {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}
                        {showRepeatedError && <SuiAlert color="error">This candidate was already sourced for this job</SuiAlert>}

                        <SuiTypography sx={{ my: 2.5 }} variant="h4">Candidate sourcing</SuiTypography>

                        <SuiTypography sx={{ my: 2.5 }} variant="button">Select a job to add this candidate:</SuiTypography>

                        <SuiBox my={5}>
                            <SuiSelect
                                label="Job"
                                name="job"
                                placeholder="Job"
                                options={jobOptions}
                                onChange={e => setDesiredJob(e.value)}
                            />
                        </SuiBox>

                        <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                            <SuiButton
                                sx={{ mx: 1 }}
                                fullWidth
                                color="info"
                                variant="outlined"
                                disabled={showSpinner || showDoneIcon}
                                onClick={() => {
                                    setDesiredJob(null)
                                    setshowAddToRecruiterSourcing(false)
                                }}>Cancel</SuiButton>

                            {/* eslint-disable-next-line */}
                            {showSpinner ?
                                <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                                :
                                showDoneIcon ?
                                    <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                                    :
                                    <SuiButton sx={{ mx: 1 }} disabled={!desiredJob} fullWidth color="info" variant="gradient" onClick={() => saveCandidateToSourcing()}>Source candidate</SuiButton>
                            }
                        </SuiBox>
                    </>
    
                }                
            </SuiBox>
        </Modal>
    )
}

export default addToRecruiterSourcingModal

// typechecking props
addToRecruiterSourcingModal.propTypes = {
    showAddToRecruiterSourcing: PropTypes.func.isRequired,
    setshowAddToRecruiterSourcing: PropTypes.func.isRequired,
    host: PropTypes.string,
    candidateId: PropTypes.number,
};

addToRecruiterSourcingModal.defaultProps = {
    host: undefined,
    candidateId: undefined
}