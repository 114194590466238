/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { useParams } from 'react-router-dom'
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiDatePicker from "components/SuiDatePicker";
import SuiSelect from "components/SuiSelect";
import SuiTypography from 'components/SuiTypography';
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

function Advanced({ formData, setActiveStep, selectedCountry, setSelectedCountry, setSelectedDateOfBirth }) {

  ReactSession.setStoreType("localStorage");
  const { candidateId } = useParams()
  let CandidateForEdit = ReactSession.get(`CandidateForEdit${candidateId}`)
  if (!CandidateForEdit) {
    CandidateForEdit = ReactSession.get("CandidateForEdit");
    ReactSession.set(`CandidateForEdit${candidateId}`, CandidateForEdit);
  }

  const [countries, setCountries] = useState([]);
  const [countryPlaceholder, setCountryPlaceholder] = useState("Select Country");

  const { formField, values, errors, touched } = formData;
  const { phone } = formField;

  const {
    phone: phoneV
  } = values;

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateCountries = async () => {
    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = [];

    interests?.data?.countries.forEach(country => {
      newData.push({ value: country.id, label: country.name })
      if (country.name === CandidateForEdit?.country?.name) setCountryPlaceholder(country.name)
    })

    setCountries(newData);
  }

  useEffect(() => { populateCountries() }, []);

   const [showEmptyFieldsMsg, setShowEmptyFieldsMsg] = useState(false)

  const verifyAdvancedInputs = () => {
    if (selectedCountry && !errors.phone) setActiveStep(prevStep => prevStep+1)
    else {
      setShowEmptyFieldsMsg(true)
      setTimeout(() => setShowEmptyFieldsMsg(false), 3000)
    }
  }

  // Formatting birthday like MM-DD
  const formatBirthday = () => {
    let value = $("input[name=dateOfBirth]:visible")[0].value // eslint-disable-line
    value = value.substring(5)
    $("input[name=dateOfBirth]").val(value)
  }

  return (
    <SuiBox>
      <SuiBox mt={2}>
        {showEmptyFieldsMsg && <SuiAlert color="secondary" className="alertError">Please fill all the required fields in this section before continuing.</SuiAlert>}
        <Grid container spacing={3} textAlign="left">
          <Grid item xs={12} sm={12}>

            <Grid item xs={12} sm={12} className="countryBox">
              <SuiTypography variant="button" ml={1}>Where are you located? (Required)</SuiTypography>
              <SuiSelect
                placeholder={countryPlaceholder}
                options={countries}
                name="country"
                onChange={e => (setSelectedCountry(e.value))}
                defaultValue={{ value: CandidateForEdit?.country?.name , label: CandidateForEdit?.country?.name }}
              />
              <br />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1}>Phone number (including code - optional)</SuiTypography>
              <FormField
                type={phone.type}
                label={phone.label}
                name={phone.name}
                value={phoneV}
                placeholder={phone.placeholder}
                error={errors.phone && touched.phone}
                success={phoneV.length > 0 && !errors.phone}
                icon={{
                  component: "add",
                  direction: "left",
                }}
                // event on paste taste
                onPaste={e => {
                  e.preventDefault();
                  // remove all characters except numbers
                  const paste = e.clipboardData.getData("text/plain").replace(/\D/g, "");
                  // insert pasted text
                  document.execCommand("insertText", false, paste);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1}>Date of birth</SuiTypography>
              <SuiDatePicker
                input={{ placeholder: "Date of Birth (optional)", name: "dateOfBirth" }}
                name="dateOfBirth"
                defaultValue={CandidateForEdit?.dateOfBirth?.substring(5)}
                onOpen={() => {
                  // hide year field
                  const instances = document.getElementsByClassName("cur-year");
                  for(let i= 0; i< instances.length; i+=1) instances[i].style.display="none"
                }}
                onChange={e => {
                  formatBirthday()
                  setSelectedDateOfBirth(new Date(e).toISOString().split('T')[0])
                }}  
              />
            </Grid>

          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" onClick={() => setActiveStep(prevStep => prevStep-1)} isSubmitting className="changeStep">Back</SuiButton>
        <SuiButton variant="gradient" color="info" onClick={() => verifyAdvancedInputs()} className="changeStep nextStep" isSubmitting>Next</SuiButton>
      </SuiBox>

    </SuiBox>
  );
}

// typechecking props for Advanced
Advanced.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  selectedCountry: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setSelectedDateOfBirth: PropTypes.func.isRequired,
};

export default Advanced;