/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import SuiAlert from "components/SuiAlert";

// Image
import trustedBy from "assets/images/clients-logos/trusted-by.png";

function Illustration() {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const id = searchParams.get('id');
  const email = searchParams.get('email');
  let timerid = "";

  function claimAccount() {
    if (token && id && $("input[name=password]").val() && $("input[name=password]").val() === $("input[name=password2]").val()) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/candidateClaimed/`;
      const data = {
        "password": $("input[name=password]").val(),
        "token": token,
        "id": id
      }
      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      };
      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            $(".alertSuccessBox").show();
            if (timerid) {
              clearTimeout(timerid);
            }
            timerid = setTimeout(() => {
              window.location.replace(`/t/login`);
            }, 3000);
          } else {
            $(".alertErrorBox div div").text(response.error);
            $(".alertErrorBox").show();
            if (timerid) {
              clearTimeout(timerid);
            }
            timerid = setTimeout(() => {
              $(".alertErrorBox").hide();
            }, 5000);
          }
        });
    } else if ($("input[name=password]").val() === $("input[name=password2]").val()) {
      $(".alertErrorBox div div").text("Wrong Password");
      $(".alertErrorBox").show();
      if (timerid) {
        clearTimeout(timerid);
      }
      timerid = setTimeout(() => {
        $(".alertErrorBox").hide();
      }, 5000);
    }
  }

  const description = `The account associated with the email ${email} has been succesfully claimed. ` +
      "Please add a new password for this account";

  return (
    <IllustrationLayout
      title="Set a new password"
      description={description}
      illustration={{
        image: trustedBy,
      }}
    >
      <SuiBox className="alertSuccessBox" display="none">
        <SuiAlert className="alertSuccess">New password set. Redirecting to login page...</SuiAlert>
      </SuiBox>
      <SuiBox className="alertErrorBox" display="none">
        <SuiAlert className="alertError" color="error">text.</SuiAlert>
      </SuiBox>
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiInput type="password" placeholder="New password" size="large" name="password" />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiInput type="password" placeholder="Confirm password" size="large" name="password2" />
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton variant="gradient" color="info" size="large" fullWidth onClick={claimAccount}>
            Change
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </IllustrationLayout>
  );
}

export default Illustration;
