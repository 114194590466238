/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { forwardRef } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// react-select components
import Select from "react-select";
// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
// Custom styles for SuiSelect
import styles from "components/SuiSelect/styles";
import smallFontStyles from "components/SuiSelect/smallFontStyles";

const SuiSelect = forwardRef(({ size, error, success, smallFont, noSelectPrompt, ...rest }, ref) => {
  const { light } = colors;

  return (
    <Select
      {...rest}
      ref={ref}
      styles={smallFont ? smallFontStyles(size, error, success, noSelectPrompt) : styles(size, error, success, noSelectPrompt)}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: light.main,
          primary: light.main,
        },
      })}
    />
  );
});

// Setting default values for the props of SuiSelect
SuiSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  smallFont: false,
  noSelectPrompt: false,
};

// Typechecking props for the SuiSelect
SuiSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  smallFont: PropTypes.bool,
  noSelectPrompt: PropTypes.bool,
};

export default SuiSelect;
