/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// import $ from 'jquery';

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// formik components
// import { Formik, Form } from "formik";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import Swal from "sweetalert2";


// import NewPassword from "layouts/pages/users/new-user/components/NewPassword";
// import SuiAlert from "components/SuiAlert";

import { ReactSession } from 'react-client-session';

// import validations from "layouts/pages/users/new-user/schemas/validations";
// import form from "layouts/pages/users/new-user/schemas/form";
// import initialValues from "layouts/pages/users/new-user/schemas/initialValues";

// Image
// import trustedBy from "assets/images/clients-logos/trusted-by.png";
import candidatesLogin from "assets/images/candidatesLogin.jpg"

// function getContent(formData) {
//   return <NewPassword formData={formData} />;
// }

function Illustration() {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');

  let id = searchParams.get('id');

  function snooze() {
    if (token && id) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/snooze/`;
      id = Number(id);
      const data = {
        "id": id,
        "token": token,
      }
      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      };
      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            Swal.fire('Snooze enabled. You will not receive customized job Invitations for 3 months.');
            const candidate = {...ReactSession.get("candidate")};
            candidate.noSendJobRecommendations = true;
            ReactSession.set("candidate", candidate);
          } else {
            Swal.fire('There was a problem with your request.');
          }
        });
    }
  }

  return (
    <IllustrationLayout
      title="Snooze"
      description="You will not receive new communications for 3 months."
      illustration={{
        image: candidatesLogin,
      }}
    >
      <SuiBox className="suiAlertBox">
        <SuiBox display="flex" alignItems="end">
          {/* <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, issubmitting }) => (
              <Form id={formId} autoComplete="off" type="POST" actions="#">
                <SuiBox>
                  {getContent({
                    values,
                    touched,
                    formField,
                    errors,
                  })}
                  <SuiBox mt={4} mb={1}>
                    <SuiButton variant="gradient" color="info" size="large" issubmitting={issubmitting} onClick={newPasswordClick} fullWidth>
                      snooze
                    </SuiButton>
                    </SuiBox>
                    </SuiBox>
                    </Form>
                    )}
                  </Formik> */}
          <SuiButton variant="outlined" color="info" size="large" fullWidth onClick={snooze} sx={{ marginTop: "25px" }}>
            snooze
          </SuiButton>
        </SuiBox>
        {/* <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertSuccess">Password changed!</SuiAlert>
        </SuiBox>
        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertError" color="error">The password is too weak</SuiAlert>
        </SuiBox> */}
      </SuiBox>
    </IllustrationLayout>
  );
}

export default Illustration;
