/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from "react-client-session"
import { useEffect, useState } from "react"
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiInput from "components/SuiInput"
import SuiButton from "components/SuiButton"
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import PlaceholderCard from "examples/Cards/PlaceholderCard"
import FolderCard from "layouts/clientFolders/folderCard/folderCard"
import placeholder from "assets/images/illustrations/saved.png"


function RecruiterFolders() {
  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")
  const recruiter = ReactSession.get("recruiter")

  useEffect(() => {
    // Only recruiters that can createClientFolders can access /r/client-folders
    if (!recruiter?.createClientFolders) window.location.replace("/r/dashboard")
  }, [])

  useEffect(() => { document.title = `Folders | TECLA` }, [])

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const [loading, setLoading] = useState(true);

  const [folders, setFolders] = useState([])
  const [showAddFolderMenu, setShowAddFolderMenu] = useState(false)
  const [newFolderName, setNewFolderName] = useState("")
  const [showSpinner, setShowSpinner] = useState(false)

  const fetchClientFolders = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/getFolderClients/`, {
      method: 'GET',
      headers: { "Authorization": `Token ${token}` }
    })
      .then(async response => {
        const data = await response.json()
        if (data.success) setFolders(data.data)
        setShowSpinner(false)
        setLoading(false)
        setNewFolderName("")
        setShowAddFolderMenu(false)
        ReactSession.set("clientFolders", data.data)
      })
      .catch(error => console.error('There was an error!', error))
  }

  const addNewFolder = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/createFolderClient/`, {
      method: 'POST',
      headers: {
        "content-type": "application/json",
        "Authorization": `Token ${token}`,
      },
      body: JSON.stringify({
        "name": newFolderName,
        "favorite": false,
        "candidates": []
      })
    })
      .then(async response => {
        const data = await response.json()
        if (data.success) {
          fetchClientFolders()
          setShowSpinner(true)
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  const cancel = () => {
    setNewFolderName("")
    setShowAddFolderMenu(false)
  }

  useEffect(() => { fetchClientFolders() }, [])

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <RecruiterDashboardLayout>
      <SuiBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiTypography variant="h3" fontWeight="bold">Client folders</SuiTypography>
            
            <SuiTypography variant="button" fontWeight="regular" color="text">
              Create folders that are visible for everyone, even outside of TECLA.
            </SuiTypography>
          </Grid>
        </Grid>
      </SuiBox>

      <Grid item xs={12} sx={{ cursor: "pointer" }} onClick={() => setShowAddFolderMenu(true)}>
        <PlaceholderCard title={{ variant: "h5", text: "CREATE A FOLDER" }} />
      </Grid>

      {showAddFolderMenu &&
        <Card sx={{ my: 2 }}>
          <Grid item xs={12} p={2} display="flex" bgColor="info">
            <SuiInput placeholder="Folder name..." onChange={e => setNewFolderName(e.target.value)} sx={{ maxWidth: "60%" }} />

            {showSpinner ?
              <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={20} />
              </SuiBox>
              :
              <SuiButton variant="gradient" color="info" disabled={newFolderName.length < 1} sx={{ marginLeft: 1, marginRight: 1 }} onClick={() => addNewFolder()}>Add folder</SuiButton>
            }

            <SuiButton variant="outlined" color="info" sx={{ marginLeft: 1, marginRight: 1 }} onClick={() => cancel()} disabled={showSpinner}>Cancel</SuiButton>

          </Grid>
        </Card>
      }

      <SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>

            <SuiBox my={3} display="flex" justifyContent="center">
              {folders.length > 0 ?
                <Grid container spacing={2} xs={12}>
                  {folders.map(folder =>
                    <Grid item xs={6} lg={2} className="folder-box">
                      <FolderCard folderName={folder.name} folderId={folder.code} isFavorite={folder.favorite} />
                    </Grid>
                  )}
                </Grid>
                :
                <Grid item>
                  {loading ?
                    <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={20}>
                      <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                    </SuiBox>
                  :
                    <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={5} width="100%" height="100%">
                      <img src={placeholder} alt="Couple of documents" height="auto" width={isMobile ? "100%" : "40%"} />

                      <SuiTypography my={2} variant="h2" textAlign="center" color="dark" fontWeight="bold">No folders created yet!</SuiTypography>
                      <SuiTypography mb={4} variant="body2" textAlign="center">Create new folders and<br/>they will be saved here.</SuiTypography>
                    </SuiBox>
                  }
                </Grid>
              }
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

    </RecruiterDashboardLayout >
  )
}

export default RecruiterFolders