/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
import { ReactSession } from 'react-client-session'
import { useEffect, useState } from 'react'
// @mui material components
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiSelect from 'components/SuiSelect'

function Scorecard({ candidateName, candidateId, scorecardData, setScorecardData, canEdit }) {

  ReactSession.setStoreType('localStorage')
  const token = ReactSession.get('token')

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const [isFetchingData, setIsFetchingData] = useState(false)
                         
  // Stores questions and answers
  const [questionsByCategory, setQuestionsByCategory] = useState({})

  const updateValue = (questionId, questionValue) => {
    const scorecardDataArr = scorecardData
    const quest = scorecardDataArr.filter(item => item.id === questionId)[0]

    quest.value = questionValue
    setScorecardData(scorecardDataArr)
  }

  const fetchCandidateScorecard = () => {
    setIsFetchingData(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateId}/getScoreboard/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      }
    })
    .then(async response => {
      const data = await response.json()

      if (data.success) {
        
        // Classify questions by category and build options array for each
        const questionsByCat = {}

        data?.data.forEach(question => {
          const options = []
          question?.item?.values?.forEach(val => options.push({ value: val, label: val }))
          question.options = options // eslint-disable-line

          if (questionsByCat[question?.item?.category]) questionsByCat[question?.item?.category].push(question)
          else questionsByCat[question?.item?.category] = [question]
        })

        setScorecardData(data?.data)
        setQuestionsByCategory(questionsByCat)
        setIsFetchingData(false)
      }
    })
    .catch(error => {
      console.error('There was an error!', error)
    })
  }

  useEffect(() => fetchCandidateScorecard(), [])

  return (
    <> 
        {isFetchingData ?
          <SuiBox my={20} mx='auto'><CircularProgress size={40}/></SuiBox>
        :
          <>
            <SuiTypography sx={{ mt: 2.5 }} variant='h4'>Scorecard for {candidateName}</SuiTypography>
            <SuiTypography sx={{ my: .5 }} variant='body2'>Rate the candidate from 1 to 5 on the following topics (Clients will see this info).</SuiTypography>

            {questionsByCategory && Object?.entries(questionsByCategory)?.map(cat => { // eslint-disable-line
              return (
                <SuiBox display='flex' flexDirection='column' alignItems='flex-start' key={cat[0]}>
                  <SuiTypography sx={{ mt: 2.5, mb: 1.5, fontSize:'1rem' }} variant='button'>{cat[0]}:</SuiTypography>

                  {cat[1]?.map(question => { // eslint-disable-line
                    return (
                      <SuiBox
                        display='flex'
                        flexDirection={isMobile ? 'column' : 'row'}
                        justifyContent='space-between'
                        alignItems={isMobile ? 'flex-start' : 'center'}
                        sx={{ backgroundColor: '#f8f9fa', borderRadius: '1%', width: '100%', p: 1, my: .5 }}
                        key={question?.id}
                      >
                        <SuiTypography sx={{ ml: 2.5 }} variant='button'>{question?.item?.name}</SuiTypography>
                        <SuiBox sx={{ width: isMobile ? '100%' : '65%' }}>
                          <SuiSelect
                            placeholder='Select one option (required)'
                            options={question?.options}
                            name='Score'
                            onChange={e => updateValue(question.id, e.value)}
                            isDisabled={!canEdit}
                            defaultValue={question?.value ?
                              scorecardData.filter(item => item.id === question.id)[0].options.filter(opt => opt.value === question.value)
                              :
                              null
                            }
                          />
                        </SuiBox>
                      </SuiBox>
                    )
                  })}
                </SuiBox>
              )
            })}

            <SuiBox
              display='flex'
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent='flex-end'
              alignItems='center'
              width='100%'
              mt={2}
            >
              <SuiTypography variant='body2' fontSize='.85rem' fontWeight='bold' color='text' mr={1}>Legend:</SuiTypography>
              <SuiTypography variant='body2' fontSize='.85rem' color='text'>
                1 = Poor - 2 = Fair - 3 = Average - 4 = Good - 5 = Excellent
              </SuiTypography>
            </SuiBox>
          </>
        }
    </>
  )
}

// Typechecking props for the Scorecard
Scorecard.propTypes = {
  candidateName: PropTypes.string.isRequired,
  candidateId: PropTypes.number.isRequired,
  scorecardData: PropTypes.array.isRequired, // eslint-disable-line
  setScorecardData: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};
  

export default Scorecard
