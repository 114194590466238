/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useState } from "react";
import { validateToken, capitalize } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Modal from '@mui/material/Modal'
import Checkbox from '@mui/material/Checkbox'
import useMediaQuery from '@mui/material/useMediaQuery'
import SendIcon from '@mui/icons-material/Send';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiAvatar from "components/SuiAvatar"
// Assets
import MockCandidateAvatar from  "assets/images/mockCandidateAvatar.png"
// Componentes
import UploadCVModal from "layouts/dashboards/default/uploadCVModal/UploadCVModal"


function ProfileCompletionModal({ openProfileCompletionModal, setOpenProfileCompletionModal, candidate }) {

    const isMobile = useMediaQuery('(max-width:500px)')

    const [openUploadCVModal, setOpenUploadCVModal] = useState(false)

    const completionPercentage = candidate?.checkList?.completedPercent >= 100 ? 100 : parseFloat(candidate?.checkList?.completedPercent).toFixed(2)

    return (
        <Modal open={openProfileCompletionModal} onClose={() => setOpenProfileCompletionModal(false)}>
            <SuiBox
                sx={{
                    width: isMobile ? '90%' : 'auto',
                    height: isMobile ? '90%' : 'auto',
                    overflowY: isMobile ? 'scroll' : 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    py: 5,
                    px: isMobile ? 3 : 10,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
            
                <SuiBox display="flex" alignItems="center" mb={2}>
                    <SuiAvatar
                        src={candidate?.user?.photo ? `${process.env.REACT_APP_API_ROUTE}${candidate?.user?.photo}` : MockCandidateAvatar}
                        alt="avatar"
                        size="lg"
                        variant="rounded"                      
                    />
                    <SuiTypography variant="h5" ml={2}>Hi {capitalize(candidate?.user?.first_name)}!</SuiTypography>
                </SuiBox>

                <SuiTypography variant="subtitle2" fontSize="1rem">
                    Your profile is {completionPercentage}% complete
                </SuiTypography>
                <SuiTypography variant="body2" fontSize=".95rem" mb={2}>
                    You can use the list below to easily spot what you are missing
                </SuiTypography>
                
                <SuiBox display="flex" alignItems="center" my={1}>
                    <Checkbox sx={{ cursor: "default" }} checked={candidate?.checkList?.cv} />
                    <SuiTypography variant="button" fontWeight="bold" mr={2}>Upload a CV / Resume</SuiTypography>
                    <SendIcon style={{ color: "gray", cursor: "pointer" }} onClick={() => setOpenUploadCVModal(true)} />

                    {openUploadCVModal && (
                        <UploadCVModal openUploadCVModal={openUploadCVModal} setOpenUploadCVModal={setOpenUploadCVModal} candidate={candidate} />
                    )}
                </SuiBox>
                
                <SuiBox display="flex" alignItems="center" my={1}>
                    <Checkbox sx={{ cursor: "default" }} checked={candidate?.checkList?.country} />
                    <SuiTypography variant="button" fontWeight="bold" mr={2}>Add a country of residence to your profile</SuiTypography>
                    <Link to="/t/wizard" target="_blank"><SendIcon style={{ color: "gray" }} /></Link>
                </SuiBox>

                <SuiBox display="flex" alignItems="center" my={1}>
                    <Checkbox sx={{ cursor: "default" }} checked={candidate?.checkList?.skills} />
                    <SuiTypography variant="button" fontWeight="bold" mr={2}>Set some skills on your profile</SuiTypography>
                    <Link to="/t/wizard" target="_blank"><SendIcon style={{ color: "gray" }} /></Link>
                </SuiBox>

                <SuiBox display="flex" alignItems="center" my={1}>
                    <Checkbox sx={{ cursor: "default" }} checked={candidate?.checkList?.commitment} />
                    <SuiTypography variant="button" fontWeight="bold" mr={2}>Set your commitment level</SuiTypography>
                    <Link to="/t/wizard" target="_blank"><SendIcon style={{ color: "gray" }} /></Link>
                </SuiBox>

                <SuiBox display="flex" alignItems="center" my={1}>
                    <Checkbox sx={{ cursor: "default" }} checked={candidate?.checkList?.social} />
                    <SuiTypography variant="button" fontWeight="bold" mr={2}>Add your social media links</SuiTypography>
                    <Link to="/t/wizard" target="_blank"><SendIcon style={{ color: "gray" }} /></Link>
                </SuiBox>

                <SuiBox display="flex" alignItems="center" my={1}>
                    <Checkbox sx={{ cursor: "default" }} checked={candidate?.checkList?.discounts} />
                    <SuiTypography variant="button" fontWeight="bold" mr={2}>Check out our resources page</SuiTypography>
                    <Link to="/t/discounts" target="_blank"><SendIcon style={{ color: "gray" }} /></Link>
                </SuiBox>

                <SuiBox display="flex" alignItems="center" my={1}>
                    <Checkbox sx={{ cursor: "default" }} checked={candidate?.checkList?.refer} />
                    <SuiTypography variant="button" fontWeight="bold" mr={2}>Check out the referrals page</SuiTypography>
                    <Link to="/t/refer" target="_blank"><SendIcon style={{ color: "gray" }} /></Link>
                </SuiBox>

            </SuiBox>
        </Modal>
    )
}

export default ProfileCompletionModal

// typechecking props
ProfileCompletionModal.propTypes = {
    openProfileCompletionModal: PropTypes.bool.isRequired,
    setOpenProfileCompletionModal: PropTypes.func.isRequired,
    candidate: PropTypes.object.isRequired, // eslint-disable-line
};