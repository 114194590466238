/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import Table from "@mui/material/Table";
// import TableRow from "@mui/material/TableRow";
// import TableBody from "@mui/material/TableBody";
// import Switch from "@mui/material/Switch";
import SuiTagInput from "components/SuiTagInput";
// import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import NewSkills from "layouts/pages/managersAddToTeam/modals/newSkills";

// import PropTypes from "prop-types";

// Setting pages components
// import TableCell from "layouts/pages/account/settings/components/TableCell";

function Notifications() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  // const postToken = `Token ${token}`;
  const [candidate, setCandidate] = useState({ ...ReactSession.get("candidate") });
  

  function getDefaultAlerts(alertCandidate) {
    let loop = 1;
    const defaultAlerts = { 
      "tagsString1": [],
      "removeAlertId1": 0,
      "tagsString2": [],
      "removeAlertId2": 0,
      "tagsString3": [],
      "removeAlertId3": 0,
    };
    // eslint-disable-next-line
    for (const alert in alertCandidate.alerts) {
      // eslint-disable-next-line
      for (const alertSkillIndex in alertCandidate.alerts[alert].skills) {
        const alertSkill = alertCandidate.alerts[alert].skills[alertSkillIndex];
        defaultAlerts[`tagsString${loop}`].push(`${alertSkill.skill.name} - ${alertSkill.minLevel} to ${alertSkill.level}`)
      }
      defaultAlerts[`removeAlertId${loop}`] = alertCandidate.alerts[alert].pk;
      loop += 1;
    }

    return defaultAlerts;
  }

  const [skills, setSkills] = useState([]);
  const [openSkills, setOpenSkills] = useState(false);
  const [skillLevels, setSkillLevels] = useState(getDefaultAlerts(candidate)); // eslint-disable-line

  useEffect(() => {
    setSkillLevels(getDefaultAlerts(candidate));
  }, [candidate]);

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateSkills = async () => {

    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = [];
    // eslint-disable-next-line
    for (let i = 0; i < interests?.data?.skills?.length; i++) {
      newData.push({ value: interests?.data?.skills[i]?.name, label: interests?.data?.skills[i]?.name, id: interests?.data?.skills[i]?.id });
    }

    $(".skillsBox").attr("data-skills", JSON.stringify(interests?.data?.skills));

    setSkills(newData);
  }

  useEffect(() => { populateSkills() }, []);

  function removeTag() {
    console.log("remove tag");
  }

  function clearAlert(alertId) {
    const postBody = {
        'alertId': alertId
    };
    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': `Token ${token}`
        },
        body: new URLSearchParams(postBody)
    };

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/removeJobAlert/`, requestMetadata)
        .then(res => res.json())
        .then(response => {
            if (response.success) {
              const sessionCandidate = { ...ReactSession.get("candidate") }
              sessionCandidate.alerts = response.data;
              setCandidate(sessionCandidate);
              ReactSession.set("candidate", sessionCandidate);
              // setSkillLevels(getDefaultAlerts(sessionCandidate));
            }
        });
  }

  return (
    <Card id="notifications">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5">Email Alerts</SuiTypography>
        </SuiBox>
        <SuiTypography variant="button" color="text" fontWeight="regular">
          Receive Email alerts when a new position with the skills you select is posted
        </SuiTypography>
      </SuiBox>

      <SuiBox pb={3} px={3} className="readOnly skillsBox">
        { skillLevels.tagsString1.length ? (
          <Grid container xs={12} md={12} spacing={1}>
            <Grid item xs={10}>
              <SuiTagInput
                placeholder=""
                tags={skillLevels.tagsString1}
                onChange={removeTag}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={2} >
              <SuiButton color="error" fullWidth onClick={() => clearAlert(skillLevels.removeAlertId1)}>CLEAR ALERT</SuiButton>
            </Grid>
          </Grid>
        ) : null }
        { skillLevels.tagsString2.length ? (
          <Grid container xs={12} md={12} spacing={1} mt={1}>
            <Grid item xs={10}>
              <SuiTagInput
                placeholder=""
                tags={skillLevels.tagsString2}
                onChange={removeTag}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={2} >
              <SuiButton color="error" fullWidth onClick={() => clearAlert(skillLevels.removeAlertId2)}>CLEAR ALERT</SuiButton>
            </Grid>
          </Grid>
        ) : null }
        { skillLevels.tagsString3.length ? (
          <Grid container xs={12} md={12} spacing={1} mt={1}>
            <Grid item xs={10}>
              <SuiTagInput
                placeholder=""
                tags={skillLevels.tagsString3}
                onChange={removeTag}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={2} >
              <SuiButton color="error" fullWidth onClick={() => clearAlert(skillLevels.removeAlertId3)}>CLEAR ALERT</SuiButton>
            </Grid>
          </Grid>
        ) : null }
        { !skillLevels.tagsString3.length ? (
          <Grid container xs={12} md={12} spacing={1} mt={1}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} >
              <SuiButton color="info" fullWidth onClick={() => setOpenSkills(true)}>NEW ALERT</SuiButton>
              <NewSkills openSkills={openSkills} setOpenSkills={setOpenSkills} skills={skills} setCandidate={setCandidate}/>
            </Grid>
          </Grid>
        ) : null }
      </SuiBox>
    </Card>
  );
}

export default Notifications;
