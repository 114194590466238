/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import { useParams } from 'react-router-dom'
import $ from 'jquery';
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";
// Images
import team2 from "assets/images/logos/Tecla_Logo_bg.png";

function Basic({ formData, setActiveStep }) {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const { candidateId } = useParams()
  let CandidateForEdit = ReactSession.get(`CandidateForEdit${candidateId}`)
  if (!CandidateForEdit) {
    CandidateForEdit = ReactSession.get("CandidateForEdit");
    ReactSession.set(`CandidateForEdit${candidateId}`, CandidateForEdit);
  }

  const { formField, values, errors, touched } = formData;
  const { email, firstName, lastName, title, photo, bio } = formField;

  const {
    email: emailV,
    firstName: firstNameV,
    lastName: lastNameV,
    title: titleV,
    photo: photoV,
    bio: bioV
  } = values;

  const [profileImage, setProfileImage] = useState(`${process.env.REACT_APP_API_ROUTE}${CandidateForEdit?.user?.photo}` || team2);
  const [editEmail, setEditEmail] = useState(false)
  let timerid = "";
  if (timerid)clearTimeout(timerid)

  function editImageClick() { $("input[name=file]").click() }

  function editImage() {

    const imageField = $("input[name=file]").prop('files')[0];
    const formPostData = new FormData();
    formPostData.append('file', imageField);
    formPostData.append('candidate', candidateId);

    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/teclaUploadPhoto/`;

    const requestMetadata = {
      method: 'POST',
      headers: {
        'Authorization': `Token ${token}`
      },
      body: formPostData
    };


    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {

        if (response.success) {
          setProfileImage(`${process.env.REACT_APP_API_ROUTE}${response.data.user.photo}`);

          if (timerid) {
            clearTimeout(timerid);
          }

          timerid = setTimeout(() => {
            $(".MuiAvatar-img").attr("src", `${process.env.REACT_APP_API_ROUTE}${response.data.user.photo}`);
          }, 200);
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  const [showEmptyFieldsMsg, setShowEmptyFieldsMsg] = useState(false)

  const verifyBasicInputs = () => {
    if (firstNameV.length > 0 && !errors.firstName && lastNameV.length > 0 && !errors.lastName) setActiveStep(prevStep => prevStep+1)
    else {
      setShowEmptyFieldsMsg(true)
      setTimeout(() => setShowEmptyFieldsMsg(false), 3000)
    }
  }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={1} mb={4}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5" fontWeight="regular">Let&apos;s start with the basic information</SuiTypography>
        </SuiBox>
      </SuiBox>

      <SuiBox mt={2}>
        {showEmptyFieldsMsg && <SuiAlert color="secondary" className="alertError">Please fill all the required fields in this section before continuing.</SuiAlert>}

        <Grid container spacing={3}>

          <Grid item xs={12} sm={4} container justifyContent="center">
            <SuiBox position="relative" height="max-content" mx="auto">
              <SuiAvatar src={profileImage} alt="profile picture" size="xxl" variant="rounded" />
              <SuiBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                <SuiButton variant="gradient" color="light" size="small" iconOnly className="editImage" onClick={editImageClick}>
                  <Icon>edit</Icon>
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Grid display="none">
              <FormField
                type={photo.type}
                label={photo.label}
                name="file"
                value={photoV}
                placeholder={photo.placeholder}
                error={errors.image && touched.photo}
                success={photoV.length > 0 && !errors.photo}
                onChange={editImage}
              />
            </Grid>

            <Grid container xs={12} md={12}>
              <Grid item xs={12} sm={12}>
                <SuiTypography variant="button" ml={1} mb={1}>Email</SuiTypography>
              </Grid>
              <Grid item xs={9} sm={9}>
                { editEmail ? (
                  <FormField
                    type={email.type}
                    label={email.label}
                    name={email.name}
                    value={emailV}
                    placeholder={email.placeholder}
                    error={errors.email && touched.email}
                    success={emailV.length > 0 && !errors.email}
                  />
                ) : (
                  <FormField
                    type={email.type}
                    label={email.label}
                    name={email.name}
                    value={emailV}
                    placeholder={email.placeholder}
                    error={errors.email && touched.email}
                    success={emailV.length > 0 && !errors.email}
                    disabled
                  />
                )}
              </Grid>
              <Grid item xs={3} sm={3} pl={2}>
                { editEmail ? (
                  <SuiButton variant="gradient" color="success" sx={{ width: "100%" }} onClick={() => setEditEmail(!editEmail)}>Done</SuiButton>
                ) : (
                  <SuiButton variant="gradient" color="info" sx={{ width: "100%" }} onClick={() => setEditEmail(!editEmail)}>Edit Mail</SuiButton>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>First name</SuiTypography>
              <FormField
                type={firstName.type}
                label={firstName.label}
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={firstNameV.length > 0 && !errors.firstName}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Last name</SuiTypography>
              <FormField
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                error={errors.lastName && touched.lastName}
                success={lastNameV.length > 0 && !errors.lastName}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Title (optional - 40 chars. max)</SuiTypography>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={titleV}
                placeholder={title.placeholder}
                error={errors.title && touched.title}
                success={titleV.length > 0 && !errors.title}
                inputProps={{ maxLength: 40 }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Tell us a bit more about you (optional - 500 chars. max)</SuiTypography>
              <FormField
                type={bio.type}
                label={bio.label}
                name={bio.name}
                value={bioV}
                placeholder={bio.placeholder}
                error={errors.bio && touched.bio}
                success={bioV.length > 0 && !errors.bio}
                multiline
                rows={3}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>

          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="flex-end">
        <SuiButton variant="gradient" color="info" onClick={() => verifyBasicInputs()} className="changeStep nextStep" isSubmitting>Next</SuiButton>
      </SuiBox>

    </SuiBox>
  );
}

// typechecking props for Basic
Basic.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.func.isRequired
};

export default Basic;
