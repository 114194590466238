/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Overview page components
// import ProductCell from "layouts/ecommerce/overview/components/ProductCell";
// import RefundsCell from "layouts/ecommerce/overview/components/RefundsCell";
// import DefaultCell from "layouts/ecommerce/overview/components/DefaultCell";

// Images
// import team1 from "assets/images/placeholder.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
// import team5 from "assets/images/team-5.jpg";

// import SuiBadge from "components/SuiBadge";

export default {
  columns: [
    { Header: "name", accessor: "user", width: "40%" },
    { Header: "date", accessor: "date", width: "40%" },
    { Header: "status", accessor: "status", align: "left" },
  ],

  rows: [],
};
