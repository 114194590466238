/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
import { useState, useEffect } from "react"
// @mui material components
import Card from "@mui/material/Card"
import Tooltip from '@mui/material/Tooltip';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
// Utils
import { formatDate, validateToken } from 'utils/commonFunctions';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DataTable from "examples/Tables/DataTable"
// Data
import dataTableData from "layouts/pages/profile/applications-list/data/dataTableData"
import dataTableDataSmall from "layouts/pages/profile/applications-list/data/dataTableDataSmall"
import ActionCell from "layouts/pages/profile/applications-list/components/ActionCell"

let applications = {};
let applicationsSmall = {};

function ProductsList() {
  ReactSession.setStoreType("localStorage");

  const token = ReactSession.get("token");
  const [applicationsData, setApplicationsData] = useState(dataTableData);
  const [applicationsDataSmall, setApplicationsDataSmall] = useState(dataTableDataSmall);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = `Applications | TECLA`;
  }, []);


  applications = applicationsData;
  applicationsSmall = applicationsDataSmall;

  const formatStatus = status => {
    switch (status) {
      case 'in-review':
        return 'Submitted'
      case 'active':
        return 'Submitted'
      case 'intro-requested':
        return 'Intro Requested'
      case 'interview':
        return 'Interview'
      case 'declined':
        return 'Declined'
      case 'withdrew':
        return 'Withdrawn'
      case 'hired':
        return 'Hired'  
      default:
        return status
    }
  }

  function removeOrWithdrawApplicationAjax(appId, type, withdrawReason) {
    let url = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/withdrew/`;
    if (type) {
      url = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/remove/`;
    }

    let requestBody = {}
    if (withdrawReason) requestBody = JSON.stringify({ withdrawReason })

    const requestMetadata = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: requestBody
    };
    fetch(url, requestMetadata)
      .then(res => res.json())
      .then(response => {

        if (response.success && response.data) {
          console.log("removed")
        }

        if (response.success) window.location.reload()
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function removeApplication(jobId) {
    const functionApp = { ...applications };
    const functionSmallApp = { ...applicationsSmall };
    functionApp.rows = [];
    functionSmallApp.rows = [];
    let appIdRemoved = 0;
    // eslint-disable-next-line
    for (const applicationRow of applications.rows) {
      if (applicationRow.jobId !== jobId) {
        functionApp.rows.push(applicationRow);
        functionSmallApp.rows.push(applicationRow);
      } else {
        appIdRemoved = applicationRow.appId;
      }
    }
    setApplicationsData(functionApp);
    setApplicationsDataSmall(functionSmallApp);
    removeOrWithdrawApplicationAjax(appIdRemoved, 1);
  }

  function withdrawApplication(jobId, withdrawReason) {
    const functionApp = { ...applications };
    const functionSmallApp = { ...applicationsSmall };
    functionApp.rows = [];
    functionSmallApp.rows = [];
    let appIdWithdraw = 0;
    // eslint-disable-next-line
    for (const applicationRow of applications.rows) {
      if (applicationRow.jobId !== jobId) {
        functionApp.rows.push(applicationRow);
        functionSmallApp.rows.push(applicationRow);
      } else {
        appIdWithdraw = applicationRow.appId;
        applicationRow.status = "withdrawn";
        applicationRow.action = <ActionCell jobId={applicationRow.jobId} status="withdrew" removeApplication={removeApplication} withdrawApplication={withdrawApplication} />;
        functionApp.rows.push(applicationRow);
        functionSmallApp.rows.push(applicationRow);
      }
    }
    setApplicationsData(functionApp);
    setApplicationsDataSmall(functionSmallApp);
    removeOrWithdrawApplicationAjax(appIdWithdraw, 0, withdrawReason);
  }

  useEffect(() => {
    setIsLoading(true)

    const url = `${process.env.REACT_APP_API_ROUTE}/api/applications/all/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success && res.data) {
          setIsLoading(false)
          const applicationsDataTemp = { ...applicationsData };
          const applicationsDataSmalltemp = { ...applicationsDataSmall };
          const restApplications = res.data;
          const applicationsArray = [];
          // eslint-disable-next-line
          for (const application of restApplications) {
            let hiringMethod = "Contract via TECLA";
            if (application.job.hiringMethod === "dh") {
              hiringMethod = "Direct Hire";
            } else if (application.job.hiringMethod === "dhp") {
              hiringMethod = "Direct Hire - Payroll";
            }
            
            let salaryToShow = application.salary;
            if (!salaryToShow) {
              salaryToShow = "";
            }
            applicationsArray.push({
              appId: application.id,
              jobId: application.job.id,
              position: [application.job.seniorityName, application.job.id],
              method: hiringMethod,
              category: application.job.category,
              date: formatDate(application.createdAt),
              salary: salaryToShow,
              status: formatStatus(application.status),
              feedbackSent: application.feedbackSent ?
                <Tooltip title="Feedback sent" placement="top"><LibraryAddCheckIcon sx={{ color: "#69bc6d", ml: 2 }} fontSize="medium" /></Tooltip> : null,
              action: <ActionCell jobId={application.job.id} status={application.status} removeApplication={removeApplication} withdrawApplication={withdrawApplication} />,
            })
          }
          applicationsDataTemp.rows = applicationsArray;
          applicationsDataSmalltemp.rows = applicationsArray;
          applications = applicationsDataTemp;
          applicationsSmall = applicationsDataSmalltemp;
          setApplicationsData(applicationsDataTemp);

          setApplicationsDataSmall(applicationsDataSmalltemp);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  return (
    <DashboardLayout>
      <SuiBox my={3}>
        <SuiBox mb={2}>
          <SuiTypography variant="h3" fontWeight="bold">Your applications</SuiTypography>

          <SuiTypography variant="button" fontWeight="regular" color="text">
            From here you can see a list of all your applications with their corresponding status.
          </SuiTypography>
        </SuiBox>
        
        <Card>
          {isLoading ?
            <SuiBox display="flex" justifyContent="center" alignItems="center" my={30} >
              <CircularProgress size={40} />
            </SuiBox>
          :
            <>
              <SuiBox sx={{ display: { xs: 'none', md: 'flex', minHeight: "60vh" } }}>
                <DataTable view="applications" table={applicationsData} canSearch />
              </SuiBox>
              <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
                <DataTable view="applications" table={applicationsDataSmall} />
              </SuiBox>
            </>
          }
        </Card>

        <SuiBox mt={2}>
          <SuiTypography variant="body" fontWeight="regular" color="text">Application status legend</SuiTypography>
          
          <SuiBox mt={.5} display="flex">
            <SuiTypography variant="button" fontWeight="bold" color="text">SUBMITTED:</SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text" ml={.5}>The hiring manager will review your application shortly.</SuiTypography>
          </SuiBox>

          <SuiBox mt={.5} display="flex">
            <SuiTypography variant="button" fontWeight="bold" color="text">DECLINED:</SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text" ml={.5}>The employer decided not to move forward with your candidacy. Please check our job board for more opportunities!</SuiTypography>
          </SuiBox>

          <SuiBox mt={.5} display="flex">
            <SuiTypography variant="button" fontWeight="bold" color="text">WITHDRAWN:</SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text" ml={.5}>As per your request, your application for this position has been withdrawn.</SuiTypography>
          </SuiBox>

          <SuiBox mt={.5} display="flex">
            <SuiTypography variant="button" fontWeight="bold" color="text">INTRO REQUESTED:</SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text" ml={.5}>Our client wants to meet you! Please check your email to schedule your first interview.</SuiTypography>
          </SuiBox>

          <SuiBox mt={.5} display="flex">
            <SuiTypography variant="button" fontWeight="bold" color="text">INTERVIEW:</SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text" ml={.5}>Good luck with your interviews! Feel free to contact us if you have any questions</SuiTypography>
          </SuiBox>
        </SuiBox>

      </SuiBox>
    </DashboardLayout>
  );
}

export default ProductsList;
