/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from 'prop-types'
import { ReactSession } from 'react-client-session'
import { useState } from 'react'
// MUI components
import { Icon, Modal } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
// SUI components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiEditor from 'components/SuiEditor'
import SuiButton from 'components/SuiButton'
import SuiAlert from 'components/SuiAlert'

function EditNoteModal({
                            openEditNoteModal,
                            setOpenEditNoteModal,
                            noteToEdit,
                            setNoteToEdit,
                            setUpdateNotes
                        }) {
    

    ReactSession.setStoreType('localStorage')
    const token = ReactSession.get('token')

    const [noteHTML, setNoteHTML] = useState(noteToEdit?.message)
    const [isProcessingRequest, setIsProcessingRequest] = useState(false)
    const [showNotesError, setShowNotesError] = useState(false)

    const editNote = async () => {
        try {
            setIsProcessingRequest(true)
            const recruitersId = []
            // eslint-disable-next-line
            for (const recruiterData of noteToEdit.recruitersData) {
                if (recruiterData?.user?.id) {
                    recruitersId.push(recruiterData?.user?.id)
                }
            }

            const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/notes/${noteToEdit?.id}/editNote/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`
                },
                body: JSON.stringify(
                    {
                        content: noteHTML,
                        recruitersId: recruitersId
                    }
                )
            })

            const data = await res.json()

            setIsProcessingRequest(false)

            if (data.success) {
                setUpdateNotes(prev => prev+1) // Refetch updated notes
                setNoteToEdit(null)
                setOpenEditNoteModal(false)
            }
            else {
                setShowNotesError(true)
                setTimeout(() => setShowNotesError(false), 2000)
            }
            
        } catch (error) {
            console.error('There was an error!', error)
            setShowNotesError(true)
            setTimeout(() => setShowNotesError(false), 2000)
        }
    }

    return (
        <Modal
            open={openEditNoteModal}
            onClose={() => setOpenEditNoteModal(false)}
        >
            <SuiBox
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90vw', md: '70vw', xl: '50vw' },
                    height: 'auto',
                    maxHeight: '80%',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 2, md: 4 },
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >                
                <SuiBox display='flex' justifyContent='space-between' alignItems='center'>
                    <SuiTypography id='modal-modal-title' variant='h6' ml={1.5} color='dark'>Edit Note</SuiTypography>

                    {showNotesError && <SuiAlert color='error'>There was an error, try again later</SuiAlert>}

                    <Icon onClick={() => setOpenEditNoteModal(false)} sx={{ cursor: 'pointer', borderRadius: 1, backgroundColor: { xs: '#21c0fd', md: '#fff' }, color: { xs: '#fff', md: '#344767' } }}>close</Icon>
                </SuiBox>

                <SuiBox display='flex' justifyContent='space-between' alignItems='flex-start' p={1}>
                    <SuiBox bgColor='#f8f9fa' borderRadius='16px' height='auto' my={2} sx={{ width: '950px', maxWidth: 'calc(100% - 110px)' }}>
                        <SuiEditor height='100%' name='noteHtml' defaultValue={noteHTML} onChange={setNoteHTML} />
                    </SuiBox>

                    <SuiBox sx={{ width: '110px', paddingLeft: '10px' }}>
                        <SuiButton
                            color='info'
                            variant='outlined'
                            sx={{ marginLeft: 1, margin: '25px 5px 15px 5px' }}
                            fullWidth
                            disabled={isProcessingRequest}
                            onClick={() => {
                                setNoteToEdit(null)
                                setOpenEditNoteModal(false)
                            }}
                        >
                            Cancel
                        </SuiButton>
                        
                        {isProcessingRequest ?
                            <SuiBox mx={5} display='flex' justifyContent='center' alignItems='center'><CircularProgress size={20} /></SuiBox>
                            :
                            <SuiButton
                                color='info'
                                variant='gradient'
                                sx={{ marginLeft: 1, margin: '10px 5px 15px 5px' }}
                                fullWidth
                                disabled={isProcessingRequest}
                                onClick={() => editNote()}
                            >
                                Save
                            </SuiButton>
                        }
                    </SuiBox>
                </SuiBox>

            </SuiBox>
        </Modal>
    )
}

EditNoteModal.propTypes = {
    openEditNoteModal: PropTypes.bool.isRequired,
    setOpenEditNoteModal: PropTypes.func.isRequired,
    noteToEdit: PropTypes.object.isRequired, // eslint-disable-line
    setNoteToEdit: PropTypes.func.isRequired,
    setUpdateNotes: PropTypes.func.isRequired,
}

export default EditNoteModal