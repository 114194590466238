/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// MUI components
import useMediaQuery from '@mui/material/useMediaQuery'
import Card from '@mui/material/Card'
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiBadge from 'components/SuiBadge'
import SuiInput from 'components/SuiInput'
import SuiAlert from 'components/SuiAlert'
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from 'examples/LayoutContainers/RecruiterDashboardLayout'
import CompanyDashboardLayout from 'examples/LayoutContainers/CompanyDashboardLayout'
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BaseLayout from 'layouts/pages/account/components/BaseLayout'
import DataTable from 'examples/Tables/DataTable'
import clientFolderTableData from 'layouts/clientFolders/folderContent/data/clientFolderTableData'
import clientFolderTableDataSmall from 'layouts/clientFolders/folderContent/data/clientFolderTableDataSmall'

function ClientFolderContent() {

    const isMobile = useMediaQuery('(max-width:500px)')

    ReactSession.setStoreType('localStorage')
    const token = ReactSession.get('token')
    const recruiter = ReactSession.get('recruiter') ? ReactSession.get('recruiter') : {};
    const company = ReactSession.get('company') ? ReactSession.get('company') : {};
    const candidate = ReactSession.get('candidate') ? ReactSession.get('candidate') : {};

    console.log('recruiter', recruiter.canManageClientFolders)

    const { id } = useParams()

    const [folderContent, setFolderContent] = useState(null)
    const [isFetchingFolderContent, setIsFetchingFolderContent] = useState(false)
    const [candidatesData, setCandidatesData] = useState(clientFolderTableData)
    const [candidatesDataSmall, setCandidatesDataSmall] = useState(clientFolderTableDataSmall)

    const [openEditModal, setOpenEditModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [newFolderName, setNewFolderName] = useState(false)

    const [showSpinner, setShowSpinner] = useState(false)
    const [showDoneIcon, setShowDoneIcon] = useState(false)

    const [showLinkSuccess, setShowLinkSuccess] = useState(false)

    const CustomWidthTooltip = styled(({ className, ...props }) => ( // eslint-disable-line
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 500,
        },
      });

    const fetchFolderContent = () => {
        setIsFetchingFolderContent(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/getFolderClient/?code=${id}`, {
            method: 'GET',
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setFolderContent(data.data)
                    setIsFetchingFolderContent(false)

                    const candidatesArray = []
                    
                    // Candidate skills
                    // eslint-disable-next-line
                    for (const candidate of data.data.candidates) {

                        let allSkills = "";
                        if (candidate.candidate.skills) {
                            // eslint-disable-next-line
                            for (const skill of candidate.candidate.skills) {
                                allSkills = `${allSkills} ${skill.skill.name}(${skill.level ? skill.level : 1}),`;
                            }
                            allSkills = allSkills.length ? allSkills.substr(0, allSkills.length - 1) : allSkills;
                        }

                        const candidateSkills = []
                        // eslint-disable-next-line
                        for (let i = 0; i < 5; i++) {
                            if (candidate?.candidate?.skills[i]?.skill?.name) {
                                candidateSkills.push(
                                        <span>
                                            <CustomWidthTooltip title={<div style={{ textAlign: "left" }}><b>Skills: </b>{allSkills}</div>}>
                                                <SuiBadge badgeContent={candidate?.candidate?.skills[i]?.skill?.name} color="info" variant="contained" size="lg" />
                                            </CustomWidthTooltip>
                                        </span>
                                    )
                            }
                            else break
                        }
                        
                        candidatesArray.push({
                            candidate: [`${candidate.candidate.user.first_name} ${candidate.candidate.user.last_name}`, candidate.candidate.user.photo, `/r/profile/${candidate.candidate.user.id}`],
                            country: candidate.candidate.country.name,
                            skills: candidateSkills.length > 0 ? candidateSkills : '',
                            action: [candidate, fetchFolderContent, data.data.id]
                        })

                    }

                    const candidatesDataTemp = { ...candidatesData }
                    candidatesDataTemp.rows = candidatesArray
                    setCandidatesData(candidatesDataTemp)

                    const candidatesDataSmallTemp = { ...candidatesDataSmall }
                    candidatesDataSmallTemp.rows = candidatesArray
                    setCandidatesDataSmall(candidatesDataSmallTemp)

                } else {
                    window.location.replace("/r/dashboard");
                }
            })
            .catch(error => console.error('There was an error!', error))
    }

    useEffect(() => { fetchFolderContent() }, [])

    const deleteFolder = () => {
        setShowSpinner(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${folderContent.id}/removeFolderClient/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setShowSpinner(false)
                    setShowDoneIcon(true)

                    setTimeout(() => {
                        setShowDoneIcon(false)
                        setOpenDeleteModal(false)
                        window.location.replace("/r/client-folders")
                    }, 1500)
                }
            })
            .catch(error => console.error('There was an error!', error))
    }

    const editFolder = () => {
        setShowSpinner(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${folderContent.id}/updateFolderClient/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ name: newFolderName })
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setShowSpinner(false)
                    setShowDoneIcon(true)

                    setTimeout(() => {
                        setOpenEditModal(false)
                        setShowDoneIcon(false)
                        window.location.reload()
                    }, 1500)
                }
            })
            .catch(error => console.error('There was an error!', error))
    }

    const copyLink = () => {
        navigator?.clipboard?.writeText(`${process.env.REACT_APP_PROD_ROUTE}folder/${id}`)
        setShowLinkSuccess(true)
        setTimeout(() => setShowLinkSuccess(false), 2000)
    }

    const renderDashboard = children => {
        if (Object.keys(recruiter).length) return <RecruiterDashboardLayout>{children}</RecruiterDashboardLayout>
        if (Object.keys(company).length) return <CompanyDashboardLayout>{children}</CompanyDashboardLayout>
        if (Object.keys(candidate).length) return <DashboardLayout>{children}</DashboardLayout>
        return <BaseLayout>{children}</BaseLayout>
    }

    return (
        renderDashboard(
            <>
                {folderContent && ( 
                    <>
                        {showLinkSuccess && (
                            <SuiAlert>Folder link copied!</SuiAlert>
                        )}

                        <SuiBox
                            py={2}
                            display="flex"
                            flexDirection={isMobile ? "column" : 'row'}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <SuiBox>
                                <SuiTypography variant="h3" fontWeight="bold">
                                    {folderContent.name}
                                </SuiTypography>
                                {recruiter.canManageClientFolders && (
                                    <SuiTypography variant="button" fontWeight="regular" color="text">
                                        This is a public folder visible for anyone, even outside of TECLA
                                    </SuiTypography>
                                )}
                            </SuiBox>

                            <SuiBox
                                display="flex"
                                flexDirection={isMobile ? "column" : "row"}
                                justifyContent={isMobile ? "auto" : "auto"}
                            >   
                                {recruiter.createClientFolders && (
                                    <>  
                                        <SuiButton
                                            variant="outlined"
                                            color="info"
                                            sx={{ marginLeft: 1, marginRight: 1 }}
                                            onClick={() => copyLink()}
                                        >
                                            Copy link
                                        </SuiButton>

                                        <SuiButton
                                            variant="outlined"
                                            color="info"
                                            sx={{ marginLeft: 1, marginRight: 1 }}
                                            onClick={() => setOpenEditModal(true)}
                                        >
                                            Edit folder
                                        </SuiButton>

                                        <SuiButton
                                            variant="outlined"
                                            color="error"
                                            sx={{ marginLeft: 1, marginRight: 1 }}
                                            onClick={() => setOpenDeleteModal(true)}
                                        >
                                            Delete folder
                                        </SuiButton>
                                    </>
                                )}
                            </SuiBox>


                        </SuiBox>

                        <Card>
                            <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <DataTable table={candidatesData} />
                            </SuiBox>
                            <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
                                <DataTable table={candidatesDataSmall} />
                            </SuiBox>
                        </Card>
                    </>
                )}
                
                {!folderContent && isFetchingFolderContent && (
                    <SuiBox mt="20rem" mx="auto" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={40} />
                    </SuiBox>
                )}

                {/* Edit folder modal */}
                <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
                    <SuiBox sx={{
                        width: { xs: "100vw", md: "650px" },
                        height: { xs: "101vh", md: "auto" },
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        border: 'none',
                        boxShadow: 24,
                        borderRadius: { xs: 0, md: 4 },
                        p: 3,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }} >

                        <SuiTypography variant="h4">
                            Provide a new name for the folder:
                        </SuiTypography>

                        <SuiBox my={2}>
                            <SuiInput
                                type="text"
                                label="New folder name"
                                name="New folder name"
                                placeholder="New folder name..."
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                onChange={e => setNewFolderName(e.target.value)}
                            />
                        </SuiBox>

                        <SuiBox width="80%" display="inherit" justifyContent="center" mx="auto">
                            <SuiButton
                                sx={{ width: "100%", marginRight: "1rem" }}
                                color="info"
                                variant="outlined"
                                disabled={showSpinner || showDoneIcon}
                                onClick={() => setOpenEditModal(false)}
                            >
                                Cancel
                            </SuiButton>

                            {/* eslint-disable-next-line */}
                            {showSpinner ?
                                <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                                :
                                showDoneIcon ?
                                    <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                                    :
                                    <SuiButton
                                        sx={{ width: "100%" }}
                                        color="info"
                                        variant="gradient"
                                        disabled={!newFolderName}
                                        onClick={() => editFolder()}
                                    >
                                        Confirm
                                    </SuiButton>
                            }
                        </SuiBox>
                    </SuiBox>
                </Modal>

                {/* Delete folder modal */}
                <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ width: isMobile ? '90%' : '50%', my: 'auto', mx: 'auto', p: '5%' }} >
                    <SuiBox sx={{ background: "white", p: 5 }}>
                        <SuiTypography sx={{ my: 2.5 }} variant="h6">Do you want to delete this folder?</SuiTypography>

                        <SuiBox mt={5} mb={5} width='100%' display="flex" justifyContent="center">
                            <SuiButton
                                sx={{ mx: 1, width: 200 }}
                                fullWidth
                                color="info"
                                variant="outlined"
                                disabled={showSpinner || showDoneIcon}
                                onClick={() => setOpenDeleteModal(false)}
                            >
                                Cancel
                            </SuiButton>

                            <SuiBox display="flex" justifyContent="center" alignItems="center">
                                {/* eslint-disable-next-line */}
                                {showSpinner ?
                                    <SuiBox mx={10} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                                    :
                                    showDoneIcon ?
                                        <SuiBox mx={10} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                                        :
                                        <SuiButton sx={{ mx: 1, width: 200 }} fullWidth color="info" variant="gradient" onClick={() => deleteFolder()}>Confirm</SuiButton>
                                }
                            </SuiBox>
                        </SuiBox>
                    </SuiBox>
                </Modal>
            </>
        )
    )
}

export default ClientFolderContent