/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types"
import { ReactSession } from 'react-client-session'
import { useParams } from 'react-router-dom'
import $ from 'jquery'
// @mui material components
import Grid from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiSelect from "components/SuiSelect"
import SuiInput from "components/SuiInput"
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"


function Skills({
                  formData, // eslint-disable-line
                  setActiveStep,
                  setCVwasUploaded,
                  setSelectedCV,
                  selectedInterviewInEnglish,
                  setSelectedInterviewInEnglish,
                  selectedCanWorkRemotely,
                  setSelectedCanWorkRemotely,
                  selectedAvailability,
                  setSelectedAvailability,
                  selectedSalaryToShow,
                  setSelectedSalaryToShow,
                  showError,
                  setUpdateCV
                }) {

  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("user");
  const { appId } = useParams()
  let ApplicationForEdit = ReactSession.get(`ApplicationForEdit${appId}`)
  if (!ApplicationForEdit) {
    ApplicationForEdit = ReactSession.get("ApplicationForEdit");
    ReactSession.set(`ApplicationForEdit${appId}`, ApplicationForEdit);
  }
  const candidate = ApplicationForEdit?.user?.candidate

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  let loadPdf = true;
  let timerid = "";
  if (timerid) clearTimeout(timerid)

  function resetLoad() {
    let resumeTimerid = "";
    if (resumeTimerid) {
      clearTimeout(resumeTimerid);
    }
    resumeTimerid = setTimeout(() => {
      loadPdf = true;
    }, 500);
  }

  timerid = setTimeout(() => {
    $("input[name=cv]").attr("accept", ".pdf");
    if (loadPdf && candidate && candidate.cv) {
      loadPdf = false;
      fetch(`${process.env.REACT_APP_API_ROUTE}${candidate.cv}`)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "resume.pdf");
          const container = new DataTransfer();
          container.items.add(file);
          $("input[name=cv]")[0].files = container.files;
          resetLoad();
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, 100);


  function uploadResume() {
    if (user) {
      const resumeField = $("input[name=cv]").prop('files')[0];
      const formPostData = new FormData();
      formPostData.append('cv', resumeField);

      setSelectedCV(formPostData)
      setCVwasUploaded(true)
    }

  }

  const interviewInEnglishArray = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
  ];
  const englishLevelArray = [
    { value: "1", label: "1 (Not able to speak English at all)" },
    { value: "2", label: "2 (Not able to speak English at all)" },
    { value: "3", label: "3 (Not able to speak English at all)" },
    { value: "4", label: "4 (Not able to speak English at all)" },
    { value: "5", label: "5 (Currently learning; not able to speak yet)" },
    { value: "6", label: "6 (I can speak but with lots of mistakes)" },
    { value: "7", label: "7 (I can hold a conversation with a few mistakes)" },
    { value: "8", label: "8 (I can hold a conversation easily; minor mistakes)" },
    { value: "9", label: "9 (Excellent English; no issues at all)" },
    { value: "10", label: "10 (English is like my first language)" },
  ];
  const availabilityArray = [
    { value: "1-week", label: "1 Week" },
    { value: "2-weeks", label: "2 Weeks" },
    { value: "3-weeks", label: "3 Weeks" },
    { value: "4-weeks", label: "4 Weeks" },
  ];

  const verifySkillInputs = () => {
    if ((!selectedInterviewInEnglish && selectedInterviewInEnglish !== false) || (!selectedCanWorkRemotely && selectedCanWorkRemotely !== false) || !selectedAvailability || !selectedSalaryToShow) showError()
    else setActiveStep(prevState => prevState + 1)
  }

  // eslint-disable-next-line
  let availability = ApplicationForEdit.availability;
  if (availability && availability === "2-week") {
    availability = "2-weeks";
  } else if (availability && availability === "3-week") {
    availability = "3-weeks";
  } else if (availability && availability === "4-week") {
    availability = "4-weeks";
  }

  return (
    <SuiBox>
      <SuiBox width="100%" textAlign="center" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">Submit more details about your skills.</SuiTypography>
      </SuiBox>

      <SuiBox
        mt={2}
        mb={4}
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="space-between"
      >
        <SuiBox width={isMobile ? "100%" : "48%"}>
          <SuiBox>
            <SuiTypography variant="button">Resume / CV</SuiTypography>
          </SuiBox>
          <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={uploadResume} />
          <SuiTypography variant="body2" fontWeight="regular" color="text" ml={1}>
            Upload the candidate CV in PDF format.
          </SuiTypography>
        </SuiBox>

        <SuiBox width={isMobile ? "100%" : "48%"}>
          <SuiBox>
            <SuiTypography variant="button">Upload the CV in the candidate profile too?</SuiTypography>
          </SuiBox>
          <SuiSelect
            name="updateCV"
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" }
            ]}
            defaultValue={{ value: false, label: "No" }}
            onChange={e => setUpdateCV(e.value)}
          />
        </SuiBox>
      </SuiBox>

      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={4}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">Are you able to hold an interview in English?</SuiTypography>
        </SuiBox>
        <SuiSelect
          name="interviewInEnglish"
          options={interviewInEnglishArray}
          defaultValue={ApplicationForEdit.interviewInEnglish ? { value: true, label: "Yes" } : { value: false, label: "No" }}
          onChange={e => setSelectedInterviewInEnglish(e.value)}
        />
      </SuiBox>

      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={4}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button"> On a scale of 1-10: what is your level of spoken English?</SuiTypography>
        </SuiBox>
        <SuiInput
          type="text"
          name="englishLevel"
          disabled
          defaultValue={ApplicationForEdit.user.candidate.englishLevel ?
            englishLevelArray.filter(item => item.value === ApplicationForEdit.user.candidate.englishLevel.toString())[0].label
            :
            null
          }
        />
      </SuiBox>

      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={4}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">
            Will you be able to work with your own equipment?
          </SuiTypography>
        </SuiBox>
        <SuiSelect
          name="canWorkRemotely"
          options={interviewInEnglishArray}
          defaultValue={ApplicationForEdit.user.candidate.canWorkRemotely ? { value: true, label: "Yes" } : { value: false, label: "No" }}
          onChange={e => setSelectedCanWorkRemotely(e.value)}
        />
      </SuiBox>

      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={5}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">When are you available to start a new position?</SuiTypography>
        </SuiBox>
        <SuiSelect
          name="availability"
          options={availabilityArray}
          defaultValue={availability ?
            {
              value: availabilityArray.filter(item => item.value === availability)[0].value,
              label: availabilityArray.filter(item => item.value === availability)[0].label
            }
            :
            null
          }
          onChange={e => setSelectedAvailability(e.value)}
        />
      </SuiBox>

      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={5}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography variant="button">Expected salary</SuiTypography>
            </SuiBox>
            <SuiInput
              type="number"
              placeholder="Expected salary"
              name="salary"
              defaultValue={ApplicationForEdit.salary}
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography variant="button">Salary shown to client</SuiTypography>
            </SuiBox>
            <SuiInput
              type="number"
              placeholder="Salary shown to client"
              name="salaryToShow"
              onChange={e => setSelectedSalaryToShow(e.target.value)}
              defaultValue={ApplicationForEdit.salaryToShow}
            />
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" onClick={() => setActiveStep(prevState => prevState - 1)} isSubmitting className="changeStep">Back</SuiButton>
        <SuiButton variant="gradient" color="info" onClick={() => verifySkillInputs()} className="changeStep nextStep" isSubmitting>Next</SuiButton>
      </SuiBox>

    </SuiBox>
  );
}

// typechecking props for Skills
Skills.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setCVwasUploaded: PropTypes.func.isRequired,
  setSelectedCV: PropTypes.func.isRequired,
  selectedInterviewInEnglish: PropTypes.bool.isRequired,
  setSelectedInterviewInEnglish: PropTypes.func.isRequired,
  selectedCanWorkRemotely: PropTypes.bool.isRequired,
  setSelectedCanWorkRemotely: PropTypes.func.isRequired,
  selectedAvailability: PropTypes.string.isRequired,
  setSelectedAvailability: PropTypes.func.isRequired,
  selectedSalaryToShow: PropTypes.number.isRequired,
  setSelectedSalaryToShow: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  setUpdateCV: PropTypes.func.isRequired,
};

export default Skills;