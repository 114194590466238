/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken, capitalize, formatDate } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiPagination from "components/SuiPagination";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from '@mui/material/Badge';
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React example components
import breakpoints from "assets/theme/base/breakpoints";
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import InvoicesTable from "layouts/pages/managerInvoices/table/invoicesTable";
// Data
// Pending approval - Approved by Tecla - Approved by client - In progress - Declined by Tecla - Declined by client - Paid
import pendingApprovalTableData from "layouts/pages/managerInvoices/data/pendingApprovalTableData";
import approvedByTeclaTableData from "layouts/pages/managerInvoices/data/approvedByTeclaTableData";
import approvedByClientTableData from "layouts/pages/managerInvoices/data/approvedByClientTableData";
import declinedByTeclaTableData from "layouts/pages/managerInvoices/data/declinedByTeclaTableData";
import declinedByClientTableData from "layouts/pages/managerInvoices/data/declinedByClientTableData";
import paidTableData from "layouts/pages/managerInvoices/data/paidTableData";

import pendingApprovalTableDataSmall from "layouts/pages/managerInvoices/data/pendingApprovalTableDataSmall";
import approvedByTeclaTableDataSmall from "layouts/pages/managerInvoices/data/approvedByTeclaTableDataSmall";
import approvedByClientTableDataSmall from "layouts/pages/managerInvoices/data/approvedByClientTableDataSmall";
import declinedByTeclaTableDataSmall from "layouts/pages/managerInvoices/data/declinedByTeclaTableDataSmall";
import declinedByClientTableDataSmall from "layouts/pages/managerInvoices/data/declinedByClientTableDataSmall";
import paidTableDataSmall from "layouts/pages/managerInvoices/data/paidTableDataSmall";

function MamagerInvoices() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const recruiter = ReactSession.get("recruiter");

  // Only recruiters that canManageTeams can access this page
  useEffect(() => {if(!recruiter.canManageTeams) window.location.replace("/r/dashboard")}, [])

  const [pagination, setPagination] = useState({ pages: [] })
  const [totalResults, setTotalResults] = useState(null)

  const [isFetchingData, setIsFetchingData] = useState(false)

// In Review - Active - Intro requested - Interview - Hired - Declined - withdrew

  const InvoiceStatesTables = {
    "pendingApproval": pendingApprovalTableData,
    "approvedByTecla": approvedByTeclaTableData,
    "approvedByClient": approvedByClientTableData,
    "declinedByTecla": declinedByTeclaTableData,
    "declinedByClient": declinedByClientTableData,
    "paid": paidTableData
  }

  const [invoiceStates, setInvoiceStates] = useState(InvoiceStatesTables)

  const smallInvoiceStatesTables = {
    "pendingApproval": pendingApprovalTableDataSmall,
    "approvedByTecla": approvedByTeclaTableDataSmall,
    "approvedByClient": approvedByClientTableDataSmall,
    "declinedByTecla": declinedByTeclaTableDataSmall,
    "declinedByClient": declinedByClientTableDataSmall,
    "paid": paidTableDataSmall
  }

  const [smallInvoiceStates, setSmallInvoiceStates] = useState(smallInvoiceStatesTables)

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const formatStatus = status => {
    switch (status) {
      case 'pending-approval':
        return 'Pending Approval'
      case 'approved-by-tecla':
        return 'Approved by TECLA'
      case 'approved-by-client':
        return 'Approved by Client'
      case 'in-progress':
        return 'In Progress'
      case 'declined-by-tecla':
        return 'Declined by TECLA'
      case 'declined-by-client':
        return 'Declined by Client'
      case 'paid':
        return 'Paid'
      default:
        return status
    }
  }

  const fetchInvoices = (page = 1) => {
    setIsFetchingData(true)

    function buildPagination(paginationData) {
      const funcPagination = {...paginationData};
      funcPagination.hasPrev = true;
      funcPagination.hasNext = true;
      if (funcPagination.current === 1 || funcPagination.countPages === 1) {
        funcPagination.hasPrev = false;
      }
  
      if (funcPagination.current === funcPagination.countPages) {
        funcPagination.hasNext = false;
      }
  
      if (funcPagination.countPages >= 5) {
        if (funcPagination.current === 1 || funcPagination.current === 2) {
          funcPagination.pages = [1, 2, 3, 4, 5];
        } else {
          funcPagination.pages = [funcPagination.current - 2, funcPagination.current - 1, funcPagination.current];
          if (funcPagination.countPages >= funcPagination.current + 1) {
            funcPagination.pages.push(funcPagination.current + 1);
          }
          if (funcPagination.countPages >= funcPagination.current + 2) {
            funcPagination.pages.push(funcPagination.current + 2);
          }
        }
      } else {
        funcPagination.pages = [];
        // eslint-disable-next-line
        for (let i = 0; i < funcPagination.countPages; i++) {
          funcPagination.pages.push(i + 1);
        }
      }
  
      funcPagination.pagesHtml = [];
      if (funcPagination.pages.length > 1) {
        // eslint-disable-next-line
        for (let i = 0; i < funcPagination.pages.length; i++) {
          if (funcPagination.pages[i] === funcPagination.current) {
            funcPagination.pagesHtml.push(<SuiPagination className="pageReload" item active> {funcPagination.pages[i]} </SuiPagination>)
          } else {
            funcPagination.pagesHtml.push(<SuiPagination className="pageReload" item onClick={() => fetchInvoices(funcPagination.pages[i])}> {funcPagination.pages[i]} </SuiPagination>)
          }
        }
      }
  
      return funcPagination;
    }

    const buildRows = invoices => {

      const rows = []

      if (invoices.length > 0) {
        invoices.forEach(invoice => {

          const invoiceObj = {
            candidate: [
              `${capitalize(invoice?.candidate?.user?.first_name)} ${capitalize(invoice?.candidate?.user?.last_name)}`,
              invoice?.candidate?.user?.photo ? `${process.env.REACT_APP_API_ROUTE}${invoice?.candidate?.user?.photo}` : null,
              invoice?.candidate?.user?.id
            ],
            date: formatDate(invoice.createdAt),
            status: formatStatus(invoice?.status),
            description: invoice?.description,
            paidOn: formatDate(invoice?.paidDate),
            notes: invoice?.notes,
            file: { file: invoice?.file },
            action: {
              invoiceId: invoice?.id,
              status: invoice?.status
            },
          }

          rows.push(invoiceObj)
        })
      }

      return rows
    }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/getInvoices/?page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
      .then(async response => {
        const res = await response.json()

        if (res.success) {
          
          /* PAGINATION */
          const resPagination = buildPagination({ ...res.data.pagination });
          setPagination(resPagination);

          /* INVOICE RECORDS */
          const tempApplicationStates = {...invoiceStates}

          tempApplicationStates.pendingApproval.rows = buildRows(res.data.invoices.filter(inv => inv.status === "pending-approval"))
          tempApplicationStates.approvedByTecla.rows = buildRows(res.data.invoices.filter(inv => inv.status === "approved-by-tecla"))
          tempApplicationStates.approvedByClient.rows = buildRows(res.data.invoices.filter(inv => inv.status === "approved-by-client"))
          tempApplicationStates.declinedByTecla.rows = buildRows(res.data.invoices.filter(inv => inv.status === "declined-by-tecla"))
          tempApplicationStates.declinedByClient.rows = buildRows(res.data.invoices.filter(inv => inv.status === "declined-by-client"))
          tempApplicationStates.paid.rows = buildRows(res.data.invoices.filter(inv => inv.status === "paid"))
          
          setInvoiceStates(tempApplicationStates)

          const tempSmallApplicationStates  = {...smallInvoiceStates}

          tempSmallApplicationStates.pendingApproval.rows = buildRows(res.data.invoices.filter(inv => inv.status === "pending-approval"))
          tempSmallApplicationStates.approvedByTecla.rows = buildRows(res.data.invoices.filter(inv => inv.status === "approved-by-tecla"))
          tempSmallApplicationStates.approvedByClient.rows = buildRows(res.data.invoices.filter(inv => inv.status === "approved-by-client"))
          tempSmallApplicationStates.declinedByTecla.rows = buildRows(res.data.invoices.filter(inv => inv.status === "declined-by-tecla"))
          tempSmallApplicationStates.declinedByClient.rows = buildRows(res.data.invoices.filter(inv => inv.status === "declined-by-client"))
          tempSmallApplicationStates.paid.rows = buildRows(res.data.invoices.filter(inv => inv.status === "paid"))

          setSmallInvoiceStates(tempSmallApplicationStates)

          if (res.data.pagination.count !== totalResults) setTotalResults(res.data.pagination.count)

          setIsFetchingData(false)
        }
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }

  useEffect(() => { fetchInvoices() }, [])

  const [tabsOrientation, setTabsOrientation] = useState("horizontal")
  const [invoicesTab, setInvoicesTab] = useState(0)

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal")
    }

    window.addEventListener("resize", handleTabsOrientation)

    handleTabsOrientation()

    return () => window.removeEventListener("resize", handleTabsOrientation)

  }, [tabsOrientation])

  const handleSetApplicationsTabs = (event, newTab) => setInvoicesTab(newTab)

  return (
    <RecruiterDashboardLayout>
      
      <SuiTypography variant="h3" fontWeight="bold">Invoices</SuiTypography>
      <SuiTypography variant="button" fontWeight="regular" color="text">
        From this page you can view and manage all the invoices.
      </SuiTypography>

      <Grid container spacing={3} mt={1}>
        <Grid item xs={12}>
          <Card sx={{ minHeight: "65vh" }}>
            {isFetchingData ?
                <SuiBox display="flex" justifyContent="center" alignItems="center" my={30} >
                  <CircularProgress size={40} />
                </SuiBox>
              :
              <>
                <SuiBox pt={3} px={3}>
                  <SuiBox display="flex" justifyContent="start" alignItems="center" width="100%">
                    <SuiBox width="100%">
                      <AppBar position="static">
                        <Tabs orientation={tabsOrientation} value={invoicesTab} onChange={handleSetApplicationsTabs}>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={invoiceStates?.pendingApproval?.rows?.length}
                              max={999}
                              onClick={() => setInvoicesTab(0)}
                            >
                              <Tab label="Pending approval" />
                            </Badge>
                          </SuiBox>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={invoiceStates?.approvedByTecla?.rows?.length}
                              max={999}
                              onClick={() => setInvoicesTab(1)}
                            >
                              <Tab label="Approved by TECLA" />
                            </Badge>
                          </SuiBox>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={invoiceStates?.approvedByClient?.rows?.length}
                              max={999}
                              onClick={() => setInvoicesTab(2)}
                            >
                              <Tab label="Approved by Client" />
                            </Badge>
                          </SuiBox>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={invoiceStates?.declinedByTecla?.rows?.length}
                              max={999}
                              onClick={() => setInvoicesTab(3)}
                            >
                              <Tab label="Declined by TECLA" />
                            </Badge>
                          </SuiBox>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={invoiceStates?.declinedByClient?.rows?.length}
                              max={999}
                              onClick={() => setInvoicesTab(4)}
                            >
                              <Tab label="Declined by client" />
                            </Badge>
                          </SuiBox>    

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={invoiceStates?.paid?.rows?.length}
                              max={999}
                              onClick={() => setInvoicesTab(5)}
                            >
                              <Tab label="Paid" />
                            </Badge>
                          </SuiBox>       

                        </Tabs>
                      </AppBar>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
                
                <SuiBox p={2} mt={1} width="100%" height="100%" minHeight="50vh">
                  <InvoicesTable title="Pending approval" value={invoicesTab} index={0} table={invoiceStates.pendingApproval} smallTable={smallInvoiceStates.pendingApproval} />
                  <InvoicesTable title="Approved by TECLA" value={invoicesTab} index={1} table={invoiceStates.approvedByTecla} smallTable={smallInvoiceStates.approvedByTecla} />
                  <InvoicesTable title="Approved by Client" value={invoicesTab} index={2} table={invoiceStates.approvedByClient} smallTable={smallInvoiceStates.approvedByClient} />
                  <InvoicesTable title="Declined by TECLA" value={invoicesTab} index={3} table={invoiceStates.declinedByTecla} smallTable={smallInvoiceStates.declinedByTecla} />
                  <InvoicesTable title="Declined by client" value={invoicesTab} index={4} table={invoiceStates.declinedByClient} smallTable={smallInvoiceStates.declinedByClient} />
                  <InvoicesTable title="Paid" value={invoicesTab} index={5} table={invoiceStates.paid} smallTable={smallInvoiceStates.paid} />
                </SuiBox>

                <SuiBox sx={{ mt: { xs: 'auto ', md: -8 } }} mb={5} mr={5} className="paginationBox" style={{ margin: "20px auto 40px auto" }}>
                  <SuiPagination>
                    {(pagination.count > 0 && pagination.hasPrev) && (
                      <SuiPagination item className="pageReloadPrev" onClick={() => fetchInvoices(pagination.prev)}>
                        <Icon>keyboard_arrow_left</Icon>
                      </SuiPagination>
                    )}

                    {(pagination.count > 0) && pagination.pagesHtml}

                    {(pagination.count > 0 && pagination.hasNext) && (
                      <SuiPagination item className="pageReloadNext" onClick={() => fetchInvoices(pagination.next)}>
                        <Icon>keyboard_arrow_right</Icon>
                      </SuiPagination>
                    )}
                  </SuiPagination>
                </SuiBox>
              </>
            }
          </Card >

        </Grid>
      </Grid>
    </RecruiterDashboardLayout>
  );
}

export default MamagerInvoices;
