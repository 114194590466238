/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiSelect from "components/SuiSelect";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiAlert from "components/SuiAlert";
import Icon from "@mui/material/Icon";

function Basic({ setActiveStep, selectedPosition, setSelectedPosition, allJobsAvailable, positionOptions }) {
  ReactSession.setStoreType("localStorage");
  const CandidateForEdit = ReactSession.get("CandidateForEdit")

  if (CandidateForEdit?.phone && CandidateForEdit?.phone.includes("+")) CandidateForEdit.phone = CandidateForEdit?.phone.slice(1)

  const [showEmptyFieldsMsg, setShowEmptyFieldsMsg] = useState(false)

  const verifyBasicInputs = () => {
    if (selectedPosition) setActiveStep(prevState => prevState + 1)
    else {
      setShowEmptyFieldsMsg(true)
      setTimeout(() => setShowEmptyFieldsMsg(false), 3000)
    }
  }

  const socialMediaList = [];
  const socialMediaInputsDefault = [];
  if (CandidateForEdit && CandidateForEdit?.linkedin) { 
    socialMediaInputsDefault.push({ label: "LinkedIn Profile", name: "linkedin", value: CandidateForEdit?.linkedin })
  } else {
    socialMediaList.push({ value: "linkedin", label: "LinkedIn Profile" })
  }
  if (CandidateForEdit && CandidateForEdit?.github) { 
    socialMediaInputsDefault.push({ label: "Github", name: "github", value: CandidateForEdit?.github })
  } else {
    socialMediaList.push({ value: "github", label: "Github" })
  }
  if (CandidateForEdit && CandidateForEdit?.portfolio) { 
    socialMediaInputsDefault.push({ label: "Portfolio", name: "portfolio", value: CandidateForEdit?.portfolio })
  } else {
    socialMediaList.push({ value: "portfolio", label: "Portfolio" })
  }
  if (CandidateForEdit && CandidateForEdit?.website) { 
    socialMediaInputsDefault.push({ label: "Website", name: "website", value: CandidateForEdit?.website })
  } else {
    socialMediaList.push({ value: "website", label: "Website" })
  }
  if (CandidateForEdit && CandidateForEdit?.twitter) { 
    socialMediaInputsDefault.push({ label: "Twitter", name: "twitter", value: CandidateForEdit?.twitter })
  } else {
    socialMediaList.push({ value: "twitter", label: "Twitter" })
  }
  const [socialMediaOptions, setSocialMediaOptions] = useState(socialMediaList);
  const [socialMediaInputs, setSocialMediaInputs] = useState(socialMediaInputsDefault);

  function addMediaInput() {
    const media = $("[name=media]").val();
    const tempMedia = [];
    const temsocialMediaInputs = [...socialMediaInputs]
    // eslint-disable-next-line
    for (const socialMedia of [...socialMediaOptions]) {
      if (media !== socialMedia.value) {
        tempMedia.push(socialMedia);
      } else {
        temsocialMediaInputs.push({ label: socialMedia.label, name: socialMedia.value, value: "" })
      }
    }
    console.log(temsocialMediaInputs, tempMedia)
    setSocialMediaInputs(temsocialMediaInputs);
    setSocialMediaOptions(tempMedia);
  }

  function closeMediaInput(mediaName, mediaLabel) {
    const temsocialMediaInputs = []
    const tempMedia = [...socialMediaOptions];
    // eslint-disable-next-line
    for (const socialMediaInput of [...socialMediaInputs]) {
      if (mediaName !== socialMediaInput.name) {
        temsocialMediaInputs.push(socialMediaInput);
      }
    }
    tempMedia.push({ value: mediaName, label: mediaLabel });

    setSocialMediaInputs(temsocialMediaInputs);
    setSocialMediaOptions(tempMedia);
  }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">Basic details and contact information</SuiTypography>
      </SuiBox>
      <SuiBox>

        {showEmptyFieldsMsg && <SuiAlert color="secondary" className="alertError">Please fill all the required fields in this section before continuing.</SuiAlert>}

        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <SuiTypography variant="button" ml={1} mb={1}>First name</SuiTypography>
                <SuiInput
                  type="text"
                  name="first_name"
                  disabled
                  defaultValue={CandidateForEdit?.user?.first_name}
                />
              </Grid>

              <Grid item xs={6}>
                <SuiTypography variant="button" ml={1} mb={1}>Last name</SuiTypography>
                <SuiInput
                  type="text"
                  name="last_name"
                  disabled
                  defaultValue={CandidateForEdit?.user?.last_name}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Email address</SuiTypography>
              <SuiInput
                type="text"
                name="email"
                disabled
                defaultValue={CandidateForEdit?.user?.email}
              />
            </Grid>

            <Grid item xs={12} sm={12} className="countryBox">
              <SuiTypography variant="button" ml={1}>Location</SuiTypography>
              <SuiInput
                type="text"
                name="country"
                disabled
                defaultValue={CandidateForEdit?.country?.name}
              />
            </Grid>

            <Grid item xs={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Phone number (including code - optional)</SuiTypography>
              <SuiInput
                type="text"
                name="phone"
                defaultValue={CandidateForEdit?.phone}
                // event on paste taste
                onPaste={e => {
                  e.preventDefault();
                  // remove all characters except numbers
                  const paste = e.clipboardData.getData("text/plain").replace(/\D/g, "");
                  // insert pasted text
                  document.execCommand("insertText", false, paste);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Position (required)</SuiTypography>
              <SuiSelect
                name="position"
                options={positionOptions}
                defaultValue={selectedPosition ?
                  {
                    value: allJobsAvailable?.filter(job => job?.id === selectedPosition?.id)[0]?.id,
                    label: allJobsAvailable?.filter(job => job?.id === selectedPosition?.id)[0]?.name
                  }
                  :
                  null
                }
                onChange={e => setSelectedPosition(allJobsAvailable.filter(job => job.id === e.value)[0])}
              />
            </Grid>

            <br/>
            <Grid item xs={12} mb={2}>
              <SuiBox mb={0.5}>
                <SuiTypography variant="button" ml={1}>
                  Social Media Links
                </SuiTypography>
              </SuiBox>

              {socialMediaInputs.map(socialMediaInput => { // eslint-disable-line
                if (socialMediaInput.name === "linkedin") return (
                  <Grid item xs={12} sx={{ position: "relative" }} mb={1}>
                    <SuiInput
                      type="text"
                      name={socialMediaInput.name}
                      label="Linkedin"
                      style={{ paddingRight: "40px" }}
                      defaultValue={socialMediaInput.value}
                      placeholder={socialMediaInput.label}
                    />
                  </Grid>
                )
              })}

              <Grid container xs={12} md={12} spacing={1} className="hiddenField">
                <Grid item xs={9} md={9}>
                  <SuiSelect
                    placeholder="Select social media"
                    options={socialMediaOptions}
                    name="media"
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <SuiButton color="info" fullWidth onClick={() => addMediaInput()}>Add</SuiButton>
                </Grid>
              </Grid>

            </Grid>

            {socialMediaInputs.map(socialMediaInput => { // eslint-disable-line
              if (socialMediaInput.name !== "linkedin") return (
                <Grid item xs={12} sx={{ position: "relative" }} mb={1}>
                  <SuiInput
                    type="text"
                    name={socialMediaInput.name}
                    label="Linkedin"
                    style={{ paddingRight: "40px" }}
                    defaultValue={socialMediaInput.value}
                    placeholder={socialMediaInput.label}
                  />
                  <Icon className="closeMedia" onClick={() => closeMediaInput(socialMediaInput.name, socialMediaInput.label)} >close</Icon>
                </Grid>
              )
            })}

          </Grid>
        </Grid>
      </SuiBox>

      { CandidateForEdit?.user.first_name && 
        CandidateForEdit?.user.last_name && 
        CandidateForEdit?.user.email && 
        CandidateForEdit?.linkedin && 
        CandidateForEdit?.country && 
        CandidateForEdit?.country.name ? (
        <SuiBox mt={3} width="100%" display="flex" justifyContent="flex-end">
          <SuiButton variant="gradient" color="info" onClick={() => verifyBasicInputs()} className="changeStep nextStep" isSubmitting>Next</SuiButton>
        </SuiBox>
      ) : (
        <SuiBox mt={3} width="100%" display="flex" justifyContent="flex-end">
          <SuiTypography sx={{ fontSize: "14px" }} fontWeight="regular" color="text" mt={1} mr={1}>This profile is missing required info, please complete the profile data before creating a new application</SuiTypography>
          <SuiButton variant="gradient" color="info" component={Link} to={`/r/editCandidateWizard/${CandidateForEdit?.user.id}`} isSubmitting>Edit</SuiButton>
        </SuiBox>
      )}

    </SuiBox >
  );
}

// typechecking props for Basic
Basic.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
  selectedPosition: PropTypes.object.isRequired, // eslint-disable-line
  setSelectedPosition: PropTypes.func.isRequired,
  allJobsAvailable: PropTypes.array.isRequired, // eslint-disable-line
  positionOptions: PropTypes.array.isRequired, // eslint-disable-line
};

export default Basic;
