import PropTypes from 'prop-types'
import { ReactSession } from 'react-client-session'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
// @mui material components
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiSelect from 'components/SuiSelect'
import SuiTagInput from 'components/SuiTagInput'

function SkillsPicker ({ setSelectedSkills, setSkillsHaveBeenEdited }) {

    ReactSession.setStoreType('localStorage')
    const { userId, appId } = useParams()

    const isProfilePage = window?.location?.pathname?.includes('profile')

    const token = ReactSession.get('token')

    const [isFetchingData, setIsFetchingData] = useState(false);

    const [candidateForEdit, setCandidateForEdit] = useState(null);
    const [skills, setSkills] = useState([]);
    const [skillLevels, setSkillLevels] = useState([]);

    const fetchCandidateSkills = () => {

        setIsFetchingData(true)
        // Get skills from different endpoint depending if we are in profile or application page
        const apiURL = isProfilePage ?
            `${process.env.REACT_APP_API_ROUTE}/api/candidates/${userId}/teclaGetCandidate/`
            :
            `${process.env.REACT_APP_API_ROUTE}/api/companies/${appId}/companyApplicationDetail/`

        fetch(apiURL, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        })
            .then(res => res.json())
            .then(response => {
                if (response?.success) {
                    if (isProfilePage) setCandidateForEdit(response?.data)
                    else setCandidateForEdit(response?.data?.user?.candidate)
                }
            })
            .catch(error => console.error('There was an error!', error))
    }

    useEffect(() => { fetchCandidateSkills() }, [])

    let skillLevelStrings = [];
    let skillLevelString = '';
    let skillString = '';
    let levelString = '';
    let tagtext = '';
    let timerid = '';
  
    const years = [
        { value: 1, label: "1 Year" },
        { value: 2, label: "2 Years" },
        { value: 3, label: "3 Years" },
        { value: 4, label: "4 Years" },
        { value: 5, label: "5 Years" },
        { value: 6, label: "6 Years" },
        { value: 7, label: "7 Years" },
        { value: 8, label: "8 Years" },
        { value: 9, label: "9 Years" },
        { value: 10, label: "10+ Years" },
    ];
    
    function changeskillPlaceholder(remove) {
        if (timerid) {
            clearTimeout(timerid);
        }
        timerid = setTimeout(() => {
            if (remove) {
                $(".react-tag-input__input").attr("placeholder", "");
                setIsFetchingData(false)
            } else {
                $(".react-tag-input__input").attr("placeholder", "Skills");
            }
        }, 100);
    }
    
    const fetchInterests = async () => { // eslint-disable-line
        try {
          const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
          const data = await res.json()
          const interestsObj = { date: new Date(), data: data.data }
          sessionStorage.setItem('interests', JSON.stringify(interestsObj))
          return interestsObj
      
        } catch (err) { console.error('fetchInterests error', err) }
    }

    const populateSkills = async () => {
        if (candidateForEdit && candidateForEdit?.skills) {
            const candidateSkill = [];
            // eslint-disable-next-line
            $.each(candidateForEdit.skills, function (i, val) {
                candidateSkill.push(`${val.skill.name} - ${val.level}`)
            });
      
            if (candidateSkill.length) {
                setSkillLevels(candidateSkill);
                changeskillPlaceholder(1);
            }
        }
        else setIsFetchingData(false)

        let interests = sessionStorage.getItem('interests')
        if (interests) interests = JSON.parse(interests)
        else interests = await fetchInterests()

        const newData = [];
        // eslint-disable-next-line
        for (let i = 0; i < interests?.data?.skills?.length; i++) {
            newData.push({ value: interests?.data?.skills[i]?.name, label: interests?.data?.skills[i]?.name });
        }

        $(".skillsBox").attr("data-skills", JSON.stringify(interests?.data?.skills));

        setSkills(newData);
    }

    useEffect(() => { populateSkills() }, [candidateForEdit]);
  
    function notInArray(skillStr) {
        // eslint-disable-next-line
        for (const skillLevel of skillLevels) {
            if (skillLevel.includes(`${skillStr} -`)) {
                return false;
            }
        }
    
        return true;
    }
  
    function getSkillObject(skillNameString) {
        const skillsStr = JSON.parse($(".skillsBox").attr("data-skills"));
        let data = null;
        // eslint-disable-next-line
        $.each(skillsStr, function (i, val) {
            if (skillNameString === val.name) {
            data = val;
            }
        });
    
        return data;
    }
  
    function getSkillAndLevel(skillStr) {
        const skillsStr = JSON.parse($(".skillsBox").attr("data-skills"));
        const skill = skillStr.substring(skillStr.indexOf("-") - 1, 0).trim();
        const level = Number(skillStr.substring(skillStr.length, skillStr.indexOf("-") + 1));
        let data = null;
        // eslint-disable-next-line
        $.each(skillsStr, function (i, val) {
            if (skill === val.name) {
            data = {
                "level": level,
                "minLevel": 1,
                "skill": { "id": val.id, "name": val.name }
            };
            }
        });
    
        return data;
    }
  
    function addSkill() {
        setSkillsHaveBeenEdited(true)

        skillString = $("input[name=skill]").val();
        levelString = $("input[name=level]").val();
        
        if (skillString && levelString && notInArray(skillString)) {
            skillLevelString = `${skillString} - ${levelString}`;
            skillLevelStrings = [...skillLevels];
            skillLevelStrings.push(skillLevelString);
            setSkillLevels(skillLevelStrings);
            changeskillPlaceholder(1);
    
            const skillArray = [];
            if ($(".skillsBox .react-tag-input__tag__content:visible").length) {
                // eslint-disable-next-line
                $.each($(".skillsBox .react-tag-input__tag__content:visible"), function (i, val) {
                    const skill = getSkillAndLevel($(val).text());
                    if (skill) {
                        skillArray.push(skill);
                    }
                });
            }
    
            const skillObject = getSkillObject(skillString);

            if (skillObject) {
                skillArray.push({
                    "level": Number(levelString),
                    "minLevel": 1,
                    "skill": { "id": skillObject.id, "name": skillObject.name }
                });
            }
            
            setSelectedSkills(skillArray)
        }

        // If skill is in array, replace it with the new one
        if (skillString && levelString && !notInArray(skillString)) {

            skillLevelString = `${skillString} - ${levelString}`;
            skillLevelStrings = [...skillLevels];
    
            // Find skillLevelString in skillLevelStrings that starts with same skillString before slash
            // Replace that skillLevelString with new skillLevelString
            // eslint-disable-next-line
            $.each(skillLevelStrings, function (i, val) {
            if (val.substring(0, val.indexOf("-") - 1).trim() === skillString) {
                skillLevelStrings[i] = skillLevelString;
            }
            });
    
            setSkillLevels(skillLevelStrings);
    
            changeskillPlaceholder(1);
    
            const skillArray = [];
            // eslint-disable-next-line
            $.each($(".skillsBox .react-tag-input__tag__content:visible"), function (i, val) {
            const skill = getSkillAndLevel($(val).text());
            if (skill) {
                skillArray.push(skill);
            }
            });
    
            const skillObject = getSkillObject(skillString);
    
            // find skill with same id in skillArray and replace it with skillObject
            // eslint-disable-next-line
            $.each(skillArray, function (i, val) {
            if (val.skill.id === skillObject.id) {
                skillArray[i] = {
                "level": Number(levelString),
                "minLevel": 1,
                "skill": { "id": skillObject.id, "name": skillObject.name }
                };
            }
            });
    
            if (skillArray.length) {
            setSelectedSkills(skillArray)
            }
        }
    }
  
    function removeTag() {
        setSkillsHaveBeenEdited(true)

        const inputLength = $(".skillsBox .react-tag-input__tag__content:visible").length

        const updateSkillArr = () => {
            if (inputLength) {
                const skillArray = [];
                // eslint-disable-next-line
                $.each($(".skillsBox .react-tag-input__tag__content:visible"), function (i, val) {
                const skill = getSkillAndLevel($(val).text());
                    if (skill) {
                        skillArray.push(skill);
                    }

                    if (skillArray.length > 0) setSelectedSkills(skillArray)
                });
            }
        }

        setTimeout(() => {
            if (inputLength === 1) setSelectedSkills([])
            else updateSkillArr()
        }, 500);
    }
    
    // eslint-disable-next-line
    $(document).unbind().on("click", ".skillsBox .react-tag-input__tag__remove", function () {
        tagtext = $(this).prev().text();
        skillLevelStrings = [...skillLevels];
        if (skillLevelStrings.length && skillLevelStrings.indexOf(tagtext) >= 0) {
            skillLevelStrings.splice(skillLevelStrings.indexOf(tagtext), 1);
            setSkillLevels(skillLevelStrings);
            if (!skillLevelStrings.length) {
            changeskillPlaceholder(0);
            }
        }
    });
    
    /* Mui hook to read screen size - We use it for small screen rendering */
    const isSmallScreen = useMediaQuery('(max-width: 1201px)')

    return (
        <SuiBox mt={2} sx={{ background: '#f8f9fa', p: 2, borderRadius: '.3rem' }}>
            <SuiBox ml={1} mb={0.5}>
                <SuiTypography variant="button">Skills and knowledge levels</SuiTypography>
            </SuiBox>

            {isFetchingData ?
                <SuiBox my={3} display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress size={20} />
                </SuiBox>
                :
                <Grid container xs={12} md={12} spacing={1}>
                    <Grid item xs={4}>
                        <SuiSelect
                            placeholder="Skill"
                            options={skills}
                            name="skill"
                            smallFont={isSmallScreen}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <SuiSelect
                            placeholder="Years"
                            options={years}
                            name="level"
                            smallFont={isSmallScreen}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SuiButton color="info" onClick={addSkill} fullWidth>
                            Add
                        </SuiButton>
                    </Grid>

                    <Grid item xs={12} md={12} className="skillsBox">
                        <SuiTagInput
                            placeholder="Skills."
                            tags={skillLevels}
                            onChange={removeTag}
                        />
                    </Grid>
                </Grid>
            }
        </SuiBox>
  )
}

export default SkillsPicker

SkillsPicker.propTypes = {
    selectedSkills: PropTypes.array.isRequired, // eslint-disable-line
    setSelectedSkills: PropTypes.func.isRequired,
    setSkillsHaveBeenEdited: PropTypes.func.isRequired,
}
