/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react"
import { ReactSession } from 'react-client-session'
import { useParams } from 'react-router-dom'
import Swal from "sweetalert2";
// formik components
import { Formik, Form } from "formik"
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
// Wizard page components
import Basic from "layouts/pages/profile/recruiter-EditCandidateWizard/components/Basic"
import Advanced from "layouts/pages/profile/recruiter-EditCandidateWizard/components/Advanced"
import Skills from "layouts/pages/profile/recruiter-EditCandidateWizard/components/Skills"
import Interests from "layouts/pages/profile/recruiter-EditCandidateWizard/components/Interests"
import Links from "layouts/pages/profile/recruiter-EditCandidateWizard/components/Links"
import validations from "layouts/pages/profile/recruiter-EditCandidateWizard/schemas/validations"
import form from "layouts/pages/profile/recruiter-EditCandidateWizard/schemas/form"


function Wizard() {
  const { candidateId } = useParams()
  ReactSession.setStoreType("localStorage")
  let CandidateForEdit = ReactSession.get(`CandidateForEdit${candidateId}`)
  if (!CandidateForEdit) {
    CandidateForEdit = ReactSession.get("CandidateForEdit");
    ReactSession.set(`CandidateForEdit${candidateId}`, CandidateForEdit);
  }
  const token = ReactSession.get("token")

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [englishLevelValidated, setEnglishLevelValidated] = useState(false);

  let candidatePhone = CandidateForEdit?.phone;
  if (candidatePhone && candidatePhone.includes("+")) {
    candidatePhone = candidatePhone.slice(1);
  }

  useEffect(() => {
    if (CandidateForEdit) {
      document.title = `Wizard - ${CandidateForEdit.user.first_name}  ${CandidateForEdit.user.last_name}  | TECLA`;
    }
  }, [CandidateForEdit]);

  /* State to store form inputs */
  // Advanced
  const [selectedCountry, setSelectedCountry] = useState(CandidateForEdit?.country?.id)
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(CandidateForEdit?.dateOfBirth)
  // Skills
  const [selectedSkills, setSelectedSkills] = useState(CandidateForEdit?.skills)
  const [selectedInterviewInEnglish, setSelectedInterviewInEnglish] = useState(CandidateForEdit?.interviewInEnglish)
  const [selectedEnglishLevel, setSelectedEnglishLevel] = useState(CandidateForEdit?.englishLevel)
  const [selectedCanWorkRemotely, setSelectedCanWorkRemotely] = useState(CandidateForEdit?.canWorkRemotely)
  // Interests
  const [selectedCommitments, setSelectedCommitments] = useState(CandidateForEdit?.commitment)
  const [selectedIndustries, setSelectedIndustries] = useState(CandidateForEdit?.industries)

  const [activeStep, setActiveStep] = useState(0)

  const steps = ["Basic", "Advanced", "Skills", "Interests", "Links"]

  const { formId, formField } = form;
  const currentValidation = validations[0];

  useEffect(() => {
    $("input[name=file]").attr("accept", ".jpg, .jpeg, .png");
  }, []);

  function updateUser(formValues) { // eslint-disable-line
    setIsSubmitting(true)
    const postBody = { user: { candidate: {} } }

    // Basic
    if (formValues.email !== CandidateForEdit?.user?.email) postBody.user.email = formValues.email
    if (formValues.firstName !== CandidateForEdit?.user?.first_name) postBody.user.first_name = formValues.firstName
    if (formValues.lastName !== CandidateForEdit?.user?.last_name) postBody.user.last_name = formValues.lastName
    if (formValues.title !== CandidateForEdit?.title) postBody.title = formValues.title
    if (formValues.bio !== CandidateForEdit?.bio) postBody.bio = formValues.bio
    // Advanced
    if (!selectedCountry || selectedCountry !== CandidateForEdit?.country?.id) postBody.country = { id: selectedCountry }
    if (formValues.phone && formValues.phone !== CandidateForEdit?.phone) postBody.phone = formValues.phone
    if (selectedDateOfBirth !== CandidateForEdit?.dateOfBirth) postBody.dateOfBirth = selectedDateOfBirth
    // Formatting birthday like MM-DD - Year is set to 2022 so backend accepts format
    if (selectedDateOfBirth !== CandidateForEdit?.dateOfBirth) postBody.dateOfBirth = `2022-${selectedDateOfBirth.substring(5)}`
    // Skills
    postBody.skills = selectedSkills
    if (selectedInterviewInEnglish !== CandidateForEdit?.interviewInEnglish) postBody.interviewInEnglish = selectedInterviewInEnglish
    if (selectedEnglishLevel !== CandidateForEdit?.englishLevel) postBody.englishLevel = selectedEnglishLevel
    if (selectedCanWorkRemotely !== CandidateForEdit?.canWorkRemotely) postBody.canWorkRemotely = selectedCanWorkRemotely
    if (englishLevelValidated) postBody.englishLevelValidated = englishLevelValidated
    // Interests
    postBody.commitment = selectedCommitments
    postBody.industries = selectedIndustries
    // Links
    if (formValues.youtube && formValues.youtube !== CandidateForEdit?.videoUrl) postBody.videoUrl = formValues.youtube
    if (formValues.github && formValues.github !== CandidateForEdit?.github) postBody.github = formValues.github
    if (formValues.gitlab && formValues.gitlab !== CandidateForEdit?.gitLab) postBody.gitLab = formValues.gitlab
    if (formValues.linkedin && formValues.linkedin !== CandidateForEdit?.linkedin) postBody.linkedin = formValues.linkedin
    if (formValues.stackoverflow && formValues.stackoverflow !== CandidateForEdit?.stackOverflow) postBody.stackOverflow = formValues.stackoverflow
    if (formValues.portfolio && formValues.portfolio !== CandidateForEdit?.portfolio) postBody.portfolio = formValues.portfolio
    if (formValues.website && formValues.website !== CandidateForEdit?.website) postBody.website = formValues.website
    if (formValues.twitter && formValues.twitter !== CandidateForEdit?.twitter) postBody.twitter = formValues.twitter
    if (formValues.facebook && formValues.facebook !== CandidateForEdit?.facebook) postBody.facebook = formValues.facebook
    if (formValues.instagram && formValues.instagram !== CandidateForEdit?.instagram) postBody.instagram = formValues.instagram

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${CandidateForEdit.user.id}/teclaUpdateCandidate/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(postBody)
    })
      .then(res => res.json())
      .then(response => {

        if (response.success) {
          ReactSession.set("CandidateForEdit", response.data)
          ReactSession.set(`CandidateForEdit${candidateId}`, null);
          setIsSubmitting(false)
          Swal.fire("Profile updated.", "", "success");
          let timerSuccessid = "";
          if (timerSuccessid) {
            clearTimeout(timerSuccessid);
          }
          timerSuccessid = setTimeout(() => {
            window.location.replace(`/r/profile/${candidateId}`)
          }, 3000);
        } else {
          let errorMsg = "There was an Error.";
          if ((response.error && response.error.includes("duplicate key")) || (response?.data?.error && response.data.error.includes("duplicate key"))) {
            errorMsg = "Error: Email already exists";
          }
          Swal.fire(errorMsg, "", "error");
        }
      }
      )
      .catch(error => console.error('There was an error!', error))
  }

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <Basic
          formData={formData}
          setActiveStep={setActiveStep}
        />
      case 1:
        return <Advanced
          formData={formData}
          setActiveStep={setActiveStep}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          setSelectedDateOfBirth={setSelectedDateOfBirth}
        />
      case 2:
        return <Skills
          formData={formData}
          setActiveStep={setActiveStep}
          setSelectedSkills={setSelectedSkills}
          setSelectedInterviewInEnglish={setSelectedInterviewInEnglish}
          setSelectedEnglishLevel={setSelectedEnglishLevel}
          setSelectedCanWorkRemotely={setSelectedCanWorkRemotely}
          englishLevelValidated={englishLevelValidated}
          setEnglishLevelValidated={setEnglishLevelValidated}
        />
      case 3:
        return <Interests
          formData={formData}
          setActiveStep={setActiveStep}
          setSelectedCommitments={setSelectedCommitments}
          setSelectedIndustries={setSelectedIndustries}
        />
      case 4:
        return <Links
          formData={formData}
          setActiveStep={setActiveStep}
          updateUser={updateUser}
          isSubmitting={isSubmitting}
        />
      default:
        return null
    }
  }

  // Forcing form initial values, Formik default way doesn't update correctly
  const {
    formField: {
      email,
      firstName,
      lastName,
      title,
      bio,
      country,
      phone,
      dateOfBirth,
      resume,
      youtube,
      github,
      gitlab,
      linkedin,
      stackoverflow,
      portfolio,
      website,
      twitter,
      facebook,
      instagram,
      photo,
    },
  } = form;

  return (
    <RecruiterDashboardLayout>
      <SuiBox pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>

            <SuiBox mt={6} mb={1} textAlign="center">
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">Edit candidate profile</SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="regular" color="secondary">This information will let us know more about you.</SuiTypography>
            </SuiBox>

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Card>
              <SuiBox p={2}>
                <SuiBox>
                  <Formik
                    validationSchema={currentValidation}
                    initialValues={{
                      [email.name]: CandidateForEdit?.user?.email || "",
                      [firstName.name]: CandidateForEdit?.user?.first_name || "",
                      [lastName.name]: CandidateForEdit?.user?.last_name || "",
                      [title.name]: CandidateForEdit?.title || "",
                      [bio.name]: CandidateForEdit?.bio || "",
                      [country.name]: CandidateForEdit?.country?.name || "",
                      [phone.name]: candidatePhone || "",
                      [dateOfBirth.name]: CandidateForEdit?.dateOfBirth || "",
                      [youtube.name]: CandidateForEdit?.videoUrl || "",
                      [github.name]: CandidateForEdit?.github || "",
                      [gitlab.name]: CandidateForEdit?.gitLab || "",
                      [linkedin.name]: CandidateForEdit?.linkedin || "",
                      [stackoverflow.name]: CandidateForEdit?.stackOverflow || "",
                      [portfolio.name]: CandidateForEdit?.portfolio || "",
                      [website.name]: CandidateForEdit?.website || "",
                      [twitter.name]: CandidateForEdit?.twitter || "",
                      [facebook.name]: CandidateForEdit?.facebook || "",
                      [instagram.name]: CandidateForEdit?.instagram || "",
                      [photo.name]: "",
                      [resume.name]: "",
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">
                        <SuiBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                          })}
                        </SuiBox>
                      </Form>
                    )}
                  </Formik>
                </SuiBox>
              </SuiBox>
            </Card>

          </Grid>
        </Grid>
      </SuiBox>
    </RecruiterDashboardLayout>
  );
}

export default Wizard;
