/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect } from "react";
import { ReactSession } from 'react-client-session'
import { capitalize } from 'utils/commonFunctions'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
// MUI components
import useMediaQuery from '@mui/material/useMediaQuery'
import Card from '@mui/material/Card'
// MyCoaches page components
import StudentDashboardLayout from 'examples/LayoutContainers/StudentDashboardLayout'

function MyCoaches() {

  useEffect(() => {
    document.title = `My coaches | Boost`;
  }, []);
  
  ReactSession.setStoreType('localStorage')
  const coaches = ReactSession.get('coaches')

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <StudentDashboardLayout>
      <SuiTypography variant='h3' fontWeight='bold' ml={1} mb={3}>
        My Coaches
      </SuiTypography>

      {coaches?.length === 0 ?
        <SuiTypography variant='h5' mx='auto' mt='20%' textAlign='center'>
          Looks like you haven&apos;t been assigned a Coach yet, but we&apos;re on it!<br/>
          If you don&apos;t see an update here in the next few days, feel free to reach out to your Program Lead for more information.
        </SuiTypography>
        :
        coaches?.map(coach => (
          <Card sx={{ p: 5, my: 2, maxWidth: '80rem' }} key={coach?.id}>      
            <SuiBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: isMobile ? 'center' : 'space-around',
                flexDirection: isMobile ? 'column' : 'row'
              }}
            >
              <SuiBox
                component='img'
                src={`${process.env.REACT_APP_API_ROUTE}${coach?.photo}`}
                alt={coach?.first_name}
                borderRadius='50%'
                boxShadow='xl'
                sx={{
                  width: '12rem',
                  height:'12rem',
                  'object-position': 'center',
                  'object-fit': 'cover',
                  mr: isMobile ? 0 : 2
                }}
              />

              <SuiBox sx={{ display: 'flex', flexDirection: 'column', maxWidth: isMobile ? '100%' :'70%' }}>
                <SuiTypography variant='h4' fontWeight='bold' mt={1}>
                  {capitalize(coach?.type)} Coach
                </SuiTypography>

                <SuiTypography variant='subtitle2' textColor='text' fontWeight='regular' sx={{ marginBottom: 2 }}>
                  {coach?.first_name} {coach?.last_name}
                </SuiTypography>

                <SuiTypography variant='button' textColor='text' fontWeight='regular'>
                  <div dangerouslySetInnerHTML={{ __html: coach?.bio }} />
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Card>
        ))
      }
    </ StudentDashboardLayout>
  )
}

export default MyCoaches