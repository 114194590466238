/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
import { useState, useEffect, useRef } from "react"
import $ from 'jquery'
// @mui material components
import Grid from "@mui/material/Grid"
import SuiSelect from "components/SuiSelect"
import SuiTagInput from "components/SuiTagInput"
import SuiButton from "components/SuiButton"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// prop-type is a library for typechecking of props
import PropTypes from "prop-types"

function Skills({ formData }) {

  /* We use these session variables to obtain information about the user. */
  ReactSession.setStoreType("localStorage")
  const candidate = ReactSession.get("candidate")

  /* Props:
    - formFields contain information about field in the form and are defined in layouts/cvBuilder/formSchemas/form
    - values, errors and touched are predefined by Formik
  */
  // eslint-disable-next-line
  const { formField, values, errors, touched, candidateSkillsIds, setCandidateSkillsIds, activeStep, setActiveStep, showError } = formData

  const [candidateSkills, setCandidateSkills] = useState([])

  let skillLevelStrings = []
  let skillLevelString = ""
  let skillString = ""
  let tagtext = ""
  let timerid = ""

  function changeskillPlaceholder(remove) {
    if (timerid) clearTimeout(timerid)

    timerid = setTimeout(() => {
      if (remove) {
        $(".react-tag-input__input").attr("placeholder", "")
      } else {
        $(".react-tag-input__input").attr("placeholder", "Skills (required)")
      }
    }, 100)
  }

  /* These state and function are used to diplay the skills input options. */
  const [skillOptions, setSkillOptions] = useState([])

  const skillInput = useRef()

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const fetchSkillOptions = async () => {

    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = []
    // eslint-disable-next-line
    for (let i = 0; i < interests?.data?.skills?.length; i++) {
      newData.push({ value: interests?.data?.skills[i]?.name, label: interests?.data?.skills[i]?.name, id: interests?.data?.skills[i]?.id })
    }

    $(".skillsBox").attr("data-skills", JSON.stringify(interests?.data?.skills))

    setSkillOptions(newData)
  }


  useEffect(() => {

    if (candidate && candidate.skills) {
      const candidateSkill = []
      const skillIds = []
      // eslint-disable-next-line
      $.each(candidate.skills, function (i, val) {
        candidateSkill.push(`${val.skill.name}`)
        skillIds.push(val.skill.id)
      })

      setCandidateSkillsIds(skillIds)

      if (candidateSkill.length) {
        setCandidateSkills(candidateSkill)
        changeskillPlaceholder(1)
      }
    }

    fetchSkillOptions()
  }, [])

  function notInArray(skillStr) {
    // eslint-disable-next-line
    for (const skillLevel of candidateSkills) {
      if (skillLevel.includes(skillStr)) return false
    }
    return true
  }

  function addSkill() {
    skillString = $("input[name=skill]").val()

    if (skillString && notInArray(skillString)) {
      skillLevelString = `${skillString}`
      skillLevelStrings = [...candidateSkills]
      skillLevelStrings.push(skillLevelString)
      setCandidateSkills(skillLevelStrings)
      changeskillPlaceholder(1)

      setCandidateSkillsIds([...candidateSkillsIds, skillInput.current.state.value.id])
    }
  }

  /* Removing skills */
  // eslint-disable-next-line
  $(document).unbind().on("click", ".skillsBox .react-tag-input__tag__remove", function () {
    tagtext = $(this).prev().text()
    skillLevelStrings = [...candidateSkills]
    if (skillLevelStrings.length && skillLevelStrings.indexOf(tagtext) >= 0) {
      skillLevelStrings.splice(skillLevelStrings.indexOf(tagtext), 1)
      setCandidateSkills(skillLevelStrings)

      const skillIdToRemove = skillOptions.filter(opt => opt.value === tagtext)[0].id
      setCandidateSkillsIds(candidateSkillsIds.filter(skill => skill !== skillIdToRemove))

      if (!skillLevelStrings.length) {
        changeskillPlaceholder(0)
      }
    }
  })

  const verifySkillsInputs = () => {
    if (candidateSkillsIds.length) setActiveStep(activeStep + 1)
    else showError()
  }

  return (
    <SuiBox>

      <SuiBox mt={2}>
        <SuiBox ml={1} mb={0.5}>
          <SuiTypography variant="button">Add your skills and experience level</SuiTypography>
        </SuiBox>
        <Grid container xs={12} md={12} spacing={1}>
          <Grid item xs={6}>
            <SuiSelect
              placeholder="Skill"
              options={skillOptions}
              name="skill"
              ref={skillInput}
            />
          </Grid>

          <Grid item xs={6} >
            <SuiButton color="info" onClick={addSkill} fullWidth>Add</SuiButton>
          </Grid>

          <Grid item xs={12} md={12} className="skillsBox">
            <SuiTagInput
              placeholder="Skills (required)"
              tags={candidateSkills}
              onChange={() => { }}
              error={"Add at least one skill." && candidateSkills.length < 1}
              success={candidateSkills.length > 0}
            />
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" onClick={() => setActiveStep(activeStep - 1)} isSubmitting className="changeStep">Back</SuiButton>
        <SuiButton variant="gradient" color="info" onClick={() => verifySkillsInputs()} className="changeStep nextStep" isSubmitting>Next</SuiButton>
      </SuiBox>

    </SuiBox>
  )
}

// typechecking props for Skills
Skills.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

export default Skills