/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from "react-client-session"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useMediaQuery from "@mui/material/useMediaQuery"
// MUI components
import Card from "@mui/material/Card"
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import SuiBadge from "components/SuiBadge"
import SuiInput from "components/SuiInput"
import SuiAlert from 'components/SuiAlert'
import Checkbox from "@mui/material/Checkbox";
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import DataTable from "examples/Tables/DataTable"
import recruitersFolderTableData from "layouts/recruiterFolders/folderContent/data/recruitersFolderTableData"
import recruitersFolderTableDataSmall from "layouts/recruiterFolders/folderContent/data/recruitersFolderTableDataSmall"
import ActionCell from "layouts/recruiterFolders/folderContent/ActionCell/ActionCell"
// SWAL
import Swal from 'sweetalert2'

function FolderContent() {

    const isMobile = useMediaQuery('(max-width:500px)')

    ReactSession.setStoreType("localStorage")
    const token = ReactSession.get("token")
    const user = ReactSession.get("user")

    const { id } = useParams()

    const [folderContent, setFolderContent] = useState(null)
    const [candidatesData, setCandidatesData] = useState(recruitersFolderTableData)
    const [candidatesDataSmall, setCandidatesDataSmall] = useState(recruitersFolderTableDataSmall)

    const [openEditModal, setOpenEditModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [newFolderName, setNewFolderName] = useState(false)

    const [showSpinner, setShowSpinner] = useState(false)
    const [showDoneIcon, setShowDoneIcon] = useState(false)
    const [showFavoriteSpinner, setShowFavoriteSpinner] = useState(false)
    const [showPublicSpinner, setShowPublicSpinner] = useState(false)
    const [showSourceSpinner, setShowSourceSpinner] = useState(false)

    const [showLinkSuccess, setShowLinkSuccess] = useState(false)

    const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 500,
        },
      });

    const fetchFolderContent = () => {
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${id}/getFolder/`, {
            method: 'GET',
            headers: { "Authorization": `Token ${token}` }
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setFolderContent(data.data)

                    const candidatesArray = []
                    // eslint-disable-next-line
                    for (const candidate of data.data.candidates) {

                        let allSkills = "";
                        if (candidate.skills) {
                            // eslint-disable-next-line
                            for (const skill of candidate.skills) {
                                allSkills = `${allSkills} ${skill.skill.name}(${skill.level ? skill.level : 1}),`;
                            }
                            allSkills = allSkills.length ? allSkills.substr(0, allSkills.length - 1) : allSkills;
                        }

                        const candidateSkills = []
                        // eslint-disable-next-line
                        for (let i = 0; i < 5; i++) {
                            if (candidate?.skills[i]?.skill?.name) candidateSkills.push(<span><CustomWidthTooltip title={<div style={{ textAlign: "left" }}><b>Skills: </b>{allSkills}</div>}><SuiBadge badgeContent={candidate.skills[i].skill.name} color="info" variant="contained" size="lg" /></CustomWidthTooltip></span>)
                            else break
                        }

                        const isOwner = user && data.data.user && data.data.user.id === user.id;

                        candidatesArray.push({
                            candidate: [`${candidate.user.first_name} ${candidate.user.last_name}`, candidate.user.photo, `/r/profile/${candidate.user.id}`, candidate.title],
                            country: candidate.country.name,
                            skills: candidateSkills.length > 0 ? candidateSkills : '',
                            action: <ActionCell candidate={candidate} fetchFolderContent={fetchFolderContent} folderContent={folderContent} isOwner={isOwner} />
                        })
                    }

                    const candidatesDataTemp = { ...candidatesData }
                    candidatesDataTemp.rows = candidatesArray
                    setCandidatesData(candidatesDataTemp)

                    const candidatesDataSmallTemp = { ...candidatesDataSmall }
                    candidatesDataSmallTemp.rows = candidatesArray
                    setCandidatesDataSmall(candidatesDataSmallTemp)

                } else {
                    window.location.replace("/r/dashboard");
                }
            })
            .catch(error => console.error('There was an error!', error))
    }

    useEffect(() => { fetchFolderContent() }, [])

    const deleteFolder = () => {
        setShowSpinner(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${id}/removeFolder/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setShowSpinner(false)
                    setShowDoneIcon(true)

                    setTimeout(() => {
                        openDeleteModal(false)
                        setShowDoneIcon(false)
                        window.location.replace("/r/folders")
                    }, 1500)
                }
            })
            .catch(error => console.error('There was an error!', error))
    }

    function deleteFolderNew() {
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${id}/removeFolder/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        })
            .then(async response => {
                const data = await response.json()

                Swal.fire({
                    title: 'Do you want to delete this folder?',
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Delete folder',
                    width: 650,
                }).then(() => {
                    if (!data.success) {
                        Swal.fire('Folder deleted!', '', 'success')
                        setTimeout(() => {
                            window.location.replace("/r/folders")
                        }, 1500)
                    }
                })
            })
            .catch(error => console.error('There was an error!', error))
    }

    const editFolder = () => {
        setShowSpinner(true)

        const candidatesArray = []
        // eslint-disable-next-line
        for (const candidate of folderContent.candidates) candidatesArray.push(candidate.user.id)

        const requestBod = {
            name: newFolderName,
            favorite: folderContent.favorite,
            candidates: candidatesArray
        }

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${id}/updateFolder/`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
            body: JSON.stringify(requestBod)
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setShowSpinner(false)
                    setShowDoneIcon(true)

                    setTimeout(() => {
                        setFolderContent(data.data)
                        setOpenEditModal(false)
                        setShowDoneIcon(false)
                    }, 1500)
                }
            })
            .catch(error => console.error('There was an error!', error))
    }

    const toggleFavorite = () => {
        setShowFavoriteSpinner(true)
        const candidatesArray = []
        // eslint-disable-next-line
        for (const candidate of folderContent.candidates) candidatesArray.push(candidate.user.id)

        const requestBody = {
            name: folderContent.name,
            favorite: !folderContent.favorite,
            candidates: candidatesArray
        }

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${id}/updateFolder/`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
            body: JSON.stringify(requestBody)
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setFolderContent(data.data)
                    setShowFavoriteSpinner(false)
                }
            })
            .catch(error => console.error('There was an error!', error))
    }

    const togglePublic = () => {
        setShowPublicSpinner(true)
        let newPublicBool = true;
        const requestBody = {
            public: 1
        }
        if (folderContent.public) {
            requestBody.public = 0
            newPublicBool = false
        }

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${id}/setPublicFolder/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
            body: JSON.stringify(requestBody)
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    const tempFolderContent = {...folderContent}
                    tempFolderContent.public = newPublicBool;
                    setFolderContent(tempFolderContent)
                }
                setShowPublicSpinner(false)
            })
            .catch(error => console.error('There was an error!', error))
    }

    function checkSourcersValue(target) {
        setShowSourceSpinner(true)
        const anyoneCanSource = $(target).parent().hasClass("Mui-checked") ? 0 : 1;
        const newAnyoneCanSource = $(target).parent().hasClass("Mui-checked") ? false : true; // eslint-disable-line
        const requestBody = {
            anyoneCanSource: anyoneCanSource
        }

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${id}/setFolderAnyoneCanSource/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
            body: JSON.stringify(requestBody)
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    const tempFolderContent = {...folderContent}
                    tempFolderContent.anyoneCanSource = newAnyoneCanSource;
                    setFolderContent(tempFolderContent)
                }
                setShowSourceSpinner(false)
            })
            .catch(error => console.error('There was an error!', error))
    }

    const copyLink = () => {
        navigator?.clipboard?.writeText(`${process.env.REACT_APP_PROD_ROUTE}r/folders/${id}`)
        setShowLinkSuccess(true)
        setTimeout(() => setShowLinkSuccess(false), 2000)
    }

    return (
        <RecruiterDashboardLayout>
            {showLinkSuccess && (
                <SuiAlert>Folder link copied!</SuiAlert>
            )}
            {folderContent && <>
                <SuiBox py={2}
                    display="flex"
                    flexDirection={isMobile ? "column" : 'row'}
                    justifyContent="space-between"
                    alignItems="center">
                    <SuiBox>
                        <SuiTypography variant="h3" fontWeight="bold">
                            {folderContent.name}
                        </SuiTypography>
                        <SuiTypography variant="button" fontWeight="regular" color="text">
                            Organize folders to quickly access your favorite candidates.
                        </SuiTypography>
                    </SuiBox>

                    <SuiBox
                        display="flex"
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent={isMobile ? "auto" : "auto"}
                    >
                        { folderContent && user && folderContent.user && folderContent.user.id === user.id ? (
                            <SuiBox mr={2} display="flex" justifyContent="center" alignItems="center">
                            
                                <SuiBox sx={{ float: "right", marginRight: "10px" }}>
                                    <SuiTypography
                                      variant="button"
                                      fontWeight="regular"
                                      sx={{ userSelect: "none", marginRight: "5px" }}>
                                      All sourcers can add candidates
                                    </SuiTypography>
                                    { showSourceSpinner ? (
                                        <CircularProgress size={20} sx={{ marginLeft: "6px" }} />
                                    ) : (
                                        <Checkbox onChange={e => checkSourcersValue(e.target)} className="checkDensity" defaultChecked={folderContent.anyoneCanSource} />
                                    )}
                                </SuiBox>
                                {/* eslint-disable-next-line */}
                                {showFavoriteSpinner ? <CircularProgress size={20} />
                                    :
                                    folderContent.favorite ?
                                        <Tooltip title="Remove from favorites" placement="top" >
                                            <StarIcon sx={{ cursor: 'pointer' }} fontSize='large' onClick={() => toggleFavorite()} />
                                        </Tooltip>
                                        :
                                        <Tooltip title="Add to favorites" placement="top" >
                                            <StarBorderIcon sx={{ cursor: 'pointer' }} fontSize='large' onClick={() => toggleFavorite()} />
                                        </Tooltip>
                                }
                            </SuiBox>
                        ) : null }
                        { folderContent && user && folderContent.user && folderContent.user.id === user.id ? (
                            <SuiBox>
                                <SuiButton
                                    variant="outlined"
                                    color="info"
                                    sx={{ marginLeft: 1, marginRight: 1 }}
                                    onClick={() => copyLink()}
                                >
                                    Copy link
                                </SuiButton>
                                { folderContent.public ? (
                                    <SuiButton variant="outlined" color="info" sx={{ marginLeft: 1, marginRight: 1, width: "135px" }} onClick={() => togglePublic()}>
                                        { showPublicSpinner ? (
                                            <CircularProgress size={20} />
                                        ) : ("Make Private") }
                                    </SuiButton>    
                                ) : (
                                    <SuiButton variant="outlined" color="info" sx={{ marginLeft: 1, marginRight: 1, width: "135px" }} onClick={() => togglePublic()}>
                                        { showPublicSpinner ? (
                                            <CircularProgress size={20} />
                                        ) : ("Make Public") }
                                    </SuiButton>   
                                )}
                                <SuiButton variant="outlined" color="info" sx={{ marginLeft: 1, marginRight: 1 }} onClick={() => setOpenEditModal(true)}>Edit folder</SuiButton>
                                {/* DESCOMENTAME LUEGO <SuiButton variant="outlined" color="info" sx={{ marginLeft: 1, marginRight: 1 }} onClick={editFoldernew}>Edit folder</SuiButton> */}
                                {/* <SuiButton variant="outlined" color="error" sx={{ marginLeft: 1, marginRight: 1 }} onClick={() => setOpenDeleteModal(true)}>Delete folder</SuiButton> */}
                                <SuiButton variant="outlined" color="error" sx={{ marginLeft: 1, marginRight: 1 }} onClick={deleteFolderNew}>Delete folder</SuiButton>
                            </SuiBox>
                        ) : (
                            <SuiBox>
                                <SuiButton
                                    variant="outlined"
                                    color="info"
                                    sx={{ marginLeft: 1, marginRight: 1 }}
                                    onClick={() => copyLink()}
                                >
                                    Copy link
                                </SuiButton>
                            </SuiBox>
                        ) }

                    </SuiBox>


                </SuiBox>

                <Card>
                    <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <DataTable table={candidatesData} />
                    </SuiBox>
                    <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
                        <DataTable table={candidatesDataSmall} />
                    </SuiBox>
                </Card></>}

            {/* Edit folder modal */}
            <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
                <SuiBox sx={{
                    width: { xs: "100vw", md: "650px" },
                    height: { xs: "101vh", md: "auto" },
                    // width: "650px",
                    // height: "auto",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }} >

                    <SuiTypography variant="h4">
                        Provide a new name for the folder:
                    </SuiTypography>

                    <SuiBox my={2}>
                        <SuiInput
                            type="text"
                            label="New folder name"
                            name="New folder name"
                            placeholder="New folder name..."
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            onChange={e => setNewFolderName(e.target.value)}
                        />
                    </SuiBox>

                    <SuiBox width="80%" display="inherit" justifyContent="center" mx="auto">
                        <SuiButton
                            sx={{ width: "100%", marginRight: "1rem" }}
                            color="info"
                            variant="outlined"
                            disabled={showSpinner || showDoneIcon}
                            onClick={() => setOpenEditModal(false)}>
                            Cancel
                        </SuiButton>
                        <SuiButton
                            sx={{ width: "100%" }}
                            color="info"
                            variant="gradient"
                            disabled={!newFolderName}
                            onClick={() => editFolder()}>
                            Confirm
                        </SuiButton>
                    </SuiBox>
                </SuiBox>
            </Modal>

            {/* Delete folder modal */}
            <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ width: isMobile ? '90%' : '50%', my: 'auto', mx: 'auto', p: '5%' }} >
                <SuiBox sx={{ background: "white", p: 5 }}>
                    <SuiTypography sx={{ my: 2.5 }} variant="h6">Do you want to delete this folder?</SuiTypography>

                    <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                        <SuiButton
                            sx={{ mx: 1, width: 200 }}
                            fullWidth
                            color="info"
                            variant="outlined"
                            disabled={showSpinner || showDoneIcon}
                            onClick={() => setOpenDeleteModal(false)}
                        >Cancel</SuiButton>

                        <SuiBox display="flex" justifyContent="center" alignItems="center">
                            {/* eslint-disable-next-line */}
                            {showSpinner ?
                                <SuiBox mx={10} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                                :
                                showDoneIcon ?
                                    <SuiBox mx={10} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                                    :
                                    <SuiButton sx={{ mx: 1, width: 200 }} fullWidth color="info" variant="gradient" onClick={() => deleteFolder()}>Confirm</SuiButton>
                            }
                        </SuiBox>
                    </SuiBox>
                </SuiBox>
            </Modal>

        </RecruiterDashboardLayout >
    )
}

export default FolderContent