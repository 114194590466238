/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react"
import { ReactSession } from "react-client-session"
// formik components and data
import { Formik, Form } from "formik"
import validations from "layouts/applications/recruiter-company-wizard/formSchemas/validations"
import form from "layouts/applications/recruiter-company-wizard/formSchemas/form"
import checkout from "layouts/applications/company-wizard/formSchemas/form"
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
// Wizard page components
import Basic from "layouts/applications/recruiter-company-wizard/components/Basic"
import Interests from "layouts/applications/recruiter-company-wizard/components/Interests"
import HighLights from "layouts/applications/recruiter-company-wizard/components/Highlights/highlights"

const getSteps = () => ["Basic", "Highlights", "Industries"]

let setStep = true
function RecruiterCompanyWizard() {
  // Validation
  ReactSession.setStoreType("localStorage")
  const companyForRecruiterEdit = ReactSession.get("companyForRecruiterEdit")
  const token = ReactSession.get("token")
  const postToken = `Token ${token}`

  const searchParams = new URLSearchParams(window.location.search)
  let urlStep = searchParams.get('step')

  useEffect(() => {
    if (companyForRecruiterEdit) {
      document.title = `Wizard - ${companyForRecruiterEdit.name} | TECLA`;
    }
  }, [companyForRecruiterEdit]);

  /* State to store highlights wizard step information */
  const [selectedCountry, setSelectedCountry] = useState(companyForRecruiterEdit.country)
  const [selectedCompanySize, setSelectedCompanySize] = useState(companyForRecruiterEdit?.size)
  const [highlights, setHighlights] = useState(companyForRecruiterEdit.highlights)
  const [whyWorkHere, setWhyWorkHere] = useState(companyForRecruiterEdit.whyWorkHere)
  const [culture, setCulture] = useState(companyForRecruiterEdit.culture)
  const [perks, setPerks] = useState(companyForRecruiterEdit.perks)
  const biovalue = companyForRecruiterEdit.description ? companyForRecruiterEdit.description : "<br/><br/><br/><br/>";
  const [bioValue, setBioValue] = useState(biovalue);

  function getCompanyData() {
    const headers = { "Content-Type": "application/json" }
    if (token) headers.Authorization = `Token ${token}`

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/${companyForRecruiterEdit.id}/teclaCompanyDetail/`, { headers })
      .then(async response => {
        const data = await response.json()
        if (data.success) {
          ReactSession.set("companyForRecruiterEdit", data.data)

          if (data.data.highlights) setHighlights(data.data.highlights)
          if (data.data.whyWorkHere) setWhyWorkHere(data.data.whyWorkHere)
          if (data.data.culture) setCulture(data.data.culture)
          if (data.data.perks) setPerks(data.data.perks)
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  const [activeStep, setActiveStep] = useState(0)

  if (urlStep && setStep) {
    setStep = false
    urlStep = Number(urlStep)
    setActiveStep(urlStep)
  }

  const steps = getSteps()
  const { formId, formField } = form
  const currentValidation = validations[activeStep]
  // let validPost = true

  const isLastStep = activeStep === steps.length - 1

  const handleSubmit = (values, actions) => {
    if (isLastStep) console.log("submit")
    else actions.setTouched({})
  }
  // eslint-disable-next-line
  const handleNext = () => setActiveStep(activeStep + 1)

  function getTech(techString) {
    const techs = JSON.parse($(".techsBox").attr("data-techs"));
    let data = null;
    // eslint-disable-next-line
    $.each(techs, function (i, val) {
      if (techString === val.name) {
        data = { "id": val.id, "name": val.name };
      }
    });

    return data;
  }

  // eslint-disable-next-line
  function updateUser(formValues) {
    console.log('updateUser call')

    // if ($("form .MuiTypography-caption:visible").text().length === 0 && user && validPost) {
    // validPost = false;
    // eslint-disable-next-line
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/companies/${companyForRecruiterEdit.id}/teclaUpdateCompany/`;
    const postBody = {};

    if ($(".techsBox .react-tag-input__tag__content:visible").length) {
      const techArray = [];
      // eslint-disable-next-line
      $.each($(".techsBox .react-tag-input__tag__content:visible"), function (i, val) {
        const tech = getTech($(val).text());
        if (tech) {
          techArray.push(tech);
        }
        if (techArray.length) {
          postBody.industries = techArray;
        }
      });
    }

    postBody.name = formValues.companyName
    if (selectedCountry && selectedCountry !== companyForRecruiterEdit.country) postBody.country = selectedCountry
    if (selectedCompanySize && selectedCompanySize !== companyForRecruiterEdit.size) postBody.size = selectedCompanySize
    postBody.description = bioValue
    postBody.website = formValues.website
    postBody.youtube = formValues.youtube
    postBody.linkedin = formValues.linkedin
    postBody.twitter = formValues.twitter
    postBody.highlights = highlights
    postBody.whyWorkHere = whyWorkHere
    postBody.culture = culture
    postBody.perks = perks

    if (Object.keys(postBody).length > 0) {
      const requestMetadata = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': postToken
        },
        body: JSON.stringify(postBody)
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {

          // validPost = true
          if (response.success) {
            // eslint-disable-next-line
            Object.keys(postBody).forEach(function (key) {
              if (key !== "user") {
                companyForRecruiterEdit[key] = postBody[key];
              }
            })
            ReactSession.set("companyForRecruiterEdit", companyForRecruiterEdit)

            if (activeStep === 0) {
              handleNext()
            } else {
              window.location.replace(`/r/company/${companyForRecruiterEdit.id}`)
            }
          }
        })
    } else if (activeStep === 0) {
      handleNext()
    } else {
      window.location.replace(`/r/company/${companyForRecruiterEdit.id}`)
    }

    // }

  }

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <Basic
          formData={formData}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          bioValue={bioValue}
          setBioValue={setBioValue}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          selectedCompanySize={selectedCompanySize}
          setSelectedCompanySize={setSelectedCompanySize}
        />
      case 1:
        return <HighLights
          formData={formData}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          highlights={highlights}
          setHighlights={setHighlights}
          whyWorkHere={whyWorkHere}
          setWhyWorkHere={setWhyWorkHere}
          culture={culture}
          setCulture={setCulture}
          perks={perks}
          setPerks={setPerks}
        />
      case 2:
        return <Interests
          formData={formData}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          updateUser={updateUser}
        />
      default:
        return null
    }
  }

  useEffect(() => { getCompanyData() }, [])

  // Forcing form initial values, Formik default way doesn't update correctly
  const {
    formField: {
      companyName,
      website,
      youtube,
      linkedin,
      twitter,
      bio,
      photo,
    },
  } = checkout

  return (
    <RecruiterDashboardLayout>
      <SuiBox pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SuiBox mt={6} mb={1} textAlign="center">
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">Edit company profile</SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="regular" color="secondary">This information will let your candidates know more about the company</SuiTypography>
            </SuiBox>

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Card>
              <SuiBox p={2}>
                <SuiBox>
                  <Formik
                    enableReinitialize
                    validationSchema={currentValidation}
                    onSubmit={handleSubmit}
                    initialValues={{
                      [companyName.name]: companyForRecruiterEdit && companyForRecruiterEdit.name ? companyForRecruiterEdit.name : "",
                      [website.name]: companyForRecruiterEdit && companyForRecruiterEdit.website ? companyForRecruiterEdit.website : "",
                      [youtube.name]: companyForRecruiterEdit && companyForRecruiterEdit.youtube ? companyForRecruiterEdit.youtube : "",
                      [linkedin.name]: companyForRecruiterEdit && companyForRecruiterEdit.linkedin ? companyForRecruiterEdit.linkedin : "",
                      [twitter.name]: companyForRecruiterEdit && companyForRecruiterEdit.twitter ? companyForRecruiterEdit.twitter : "",
                      [bio.name]: companyForRecruiterEdit && companyForRecruiterEdit.description ? companyForRecruiterEdit.description : "",
                      [photo.name]: "",
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">
                        <SuiBox>
                          {getStepContent(activeStep, { values, touched, formField, errors })}
                        </SuiBox>
                      </Form>
                    )}
                  </Formik>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>

    </RecruiterDashboardLayout>
  );
}

export default RecruiterCompanyWizard
