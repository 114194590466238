/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// import ProductCell from "layouts/pages/profile/applications-list/components/ProductCell";
import ProductCell from "layouts/ecommerce/overview/components/ProductCell";


export default {
  columns: [
    {
      Header: "Name",
      accessor: "product",
      width: "40%",
      Cell: ({ value: [name, data, url, isNew] }) => (
        <ProductCell image={data.image} name={name} url={url} isNew={isNew} />
      ),
    },
    { Header: "Country", accessor: "country" },
    { Header: "Availability", accessor: "availability" },
    { Header: "Salary", accessor: "salary" },
    { Header: "Application", accessor: "application" },
    { Header: "Action", accessor: "action" },
  ],

  rows: [],
};
