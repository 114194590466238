/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import { useState } from 'react'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import FullFeedbackModal from 'layouts/pages/jobFeedback/components/FullFeedbackModal/fullFeedbackModal'

function FeedbackReasonCell({ reason, feedback }) {

    const [showFullFeedbackModal, setShowFullFeedbackModal] = useState(false)

    return (
        <>
            <SuiBox sx={{ cursor: 'pointer' }} onClick={() => setShowFullFeedbackModal(true)}>
                <SuiTypography variant='button' fontWeight='light'>
                    {reason}
                </SuiTypography>
            </SuiBox>
            
            {showFullFeedbackModal && (
                <FullFeedbackModal
                    showFullFeedbackModal={showFullFeedbackModal}
                    setShowFullFeedbackModal={setShowFullFeedbackModal}
                    feedback={feedback}
                />
            )}
        </>
    )
}

// Typechecking props for the FeedbackReasonCell
FeedbackReasonCell.propTypes = {
    reason: PropTypes.string.isRequired,
    feedback: PropTypes.object.isRequired, // eslint-disable-line
}

export default FeedbackReasonCell