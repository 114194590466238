/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import $ from 'jquery';
// @mui material components
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import Tooltip from "@mui/material/Tooltip";
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import CheckBoxIcon from '@mui/icons-material/CheckBox';

function Skills() {

  /* Mui hook to read screen size - We use it for small screen rendering */
  const isSmallScreen = useMediaQuery('(max-width: 1201px)')

  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("user");
  const token = ReactSession.get("token");
  let candidate = { ...ReactSession.get("candidate") };

  const [skills, setSkills] = useState([]);
  const [skillLevels, setSkillLevels] = useState([]);
  const [levelPlaceHolder, setLevelPlaceHolder] = useState("Select a English Level");
  const [interviewPlaceHolder, setInterviewPlaceHolder] = useState("Select...");
  const [canWorkPlaceHolder, setCanWorkPlaceHolder] = useState("Select...");

  let skillLevelStrings = [];
  let skillLevelString = "";
  let skillString = "";
  let levelString = "";
  let tagtext = "";
  let timerid = "";

  const years = [
    { value: 1, label: "1 Year" },
    { value: 2, label: "2 Years" },
    { value: 3, label: "3 Years" },
    { value: 4, label: "4 Years" },
    { value: 5, label: "5 Years" },
    { value: 6, label: "6 Years" },
    { value: 7, label: "7 Years" },
    { value: 8, label: "8 Years" },
    { value: 9, label: "9 Years" },
    { value: 10, label: "10+ Years" },
  ];
  const levels = [
    { value: 1, label: "1 (Not able to speak English at all)" },
    { value: 2, label: "2 (Not able to speak English at all)" },
    { value: 3, label: "3 (Not able to speak English at all)" },
    { value: 4, label: "4 (Not able to speak English at all)" },
    { value: 5, label: "5 (Currently learning; not able to speak yet)" },
    { value: 6, label: "6 (I can speak but with lots of mistakes)" },
    { value: 7, label: "7 (I can hold a conversation with a few mistakes)" },
    { value: 8, label: "8 (I can hold a conversation easily; minor mistakes)" },
    { value: 9, label: "9 (Excellent English; no issues at all)" },
    { value: 10, label: "10 (English is like my first language)" },
  ];
  const defaultOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  function changeskillPlaceholder(remove) {
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      if (remove) {
        $(".react-tag-input__input").attr("placeholder", "");
      } else {
        $(".react-tag-input__input").attr("placeholder", "Skills");
      }
    }, 100);
  }

  function getStringBool(value) {
    if (value === true) {
      return "Yes";
    }
    if (value === false) {
      return "No";
    }

    return "Select...";
  }

  useEffect(() => {
    $("input[name=cv]").attr("accept", ".pdf");
    if (candidate.englishLevel) {
      setLevelPlaceHolder(levels.filter(item => (Number(item.value) === Number(candidate.englishLevel)))[0].label);
    }
    if (candidate.interviewInEnglish === true || candidate.interviewInEnglish === false) {
      setInterviewPlaceHolder(getStringBool(candidate.interviewInEnglish));
    }
    if (candidate.canWorkRemotely === true || candidate.canWorkRemotely === false) {
      setCanWorkPlaceHolder(getStringBool(candidate.canWorkRemotely));
    }

    if (candidate.skills) {
      const candidateSkill = [];
      // eslint-disable-next-line
      $.each(candidate.skills, function (i, val) {
        candidateSkill.push(`${val.skill.name} - ${val.level}`)
      });

      if (candidateSkill.length) {
        setSkillLevels(candidateSkill);
        changeskillPlaceholder(1);
      }
    }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      .then(async response => {
        const data = await response.json();
        // console.log(data.data.countries)

        const newData = [];
        // eslint-disable-next-line
        for (let i = 0; i < data.data.skills.length; i++) {
          newData.push({ value: data.data.skills[i].name, label: data.data.skills[i].name });
        }

        $(".skillsBox").attr("data-skills", JSON.stringify(data.data.skills));

        setSkills(newData);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  function notInArray(skillStr) {
    // eslint-disable-next-line
    for (const skillLevel of skillLevels) {
      if (skillLevel.includes(`${skillStr} -`)) {
        return false;
      }
    }

    return true;
  }

  function addSkill() {
    skillString = $("input[name=skill]").val();
    levelString = $("input[name=years]").val();
    if (skillString && levelString && notInArray(skillString)) {
      skillLevelString = `${skillString} - ${levelString}`;
      skillLevelStrings = [...skillLevels];
      skillLevelStrings.push(skillLevelString);
      setSkillLevels(skillLevelStrings);
      changeskillPlaceholder(1);
    }

    // If skill is in array, replace it with the new one
    if (skillString && levelString && !notInArray(skillString, skillLevels)) {
      skillLevelString = `${skillString} - ${levelString}`;
      skillLevelStrings = [...skillLevels];
      // eslint-disable-next-line
      for (let i = 0; i < skillLevelStrings.length; i++) {
        if (skillLevelStrings[i].includes(`${skillString} -`)) {
          skillLevelStrings[i] = skillLevelString;
          break;
        }
      }
      setSkillLevels(skillLevelStrings);
    }
  }

  function removeTag() {
    console.log("remove tag");
  }

  function uploadResume() {
    if (user) {
      const resumeField = $("input[name=cv]").prop('files')[0];
      let validCv = true;
      if (resumeField && resumeField.name) {
        const extension = resumeField.name.substring(resumeField.name.length - 4, resumeField.name.length).toLowerCase();
        if (!extension.includes("pdf")) {
          validCv = false;
        }
      }

      if (validCv) {
        const formPostData = new FormData();
        // eslint-disable-next-line no-useless-concat
        const postToken = `Token ${token}`;

        formPostData.append('cv', resumeField);

        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/uploadCVCandidate/`;
        const normalizedName = "cv.pdf"

        const requestMetadata = {
          method: 'PATCH',
          headers: {
            "Content-Disposition": `attachment; filename=${normalizedName}`,
            "Authorization": postToken,
            "content-type": "multipart/form-data;"
          },
          body: formPostData
        };

        fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
            if (response.success && response.data && response.data.cv) {
              candidate = { ...ReactSession.get("candidate") };
              candidate.cv = response.data.cv;
              ReactSession.set("candidate", candidate);
            }
          });
      } else {
        $("input[name=cv]").val("");
        let timerRedirectid = "";
        $(".alertErrorBox").show();
        if (timerRedirectid) {
          clearTimeout(timerRedirectid);
        }
        timerRedirectid = setTimeout(() => {
          $(".alertErrorBox").hide();
        }, 3000);
      }
    }
  }

  // eslint-disable-next-line
  $(document).unbind().on("click", ".skillsBox .react-tag-input__tag__remove", function () {
    tagtext = $(this).prev().text();
    skillLevelStrings = [...skillLevels];
    if (skillLevelStrings.length && skillLevelStrings.indexOf(tagtext) >= 0) {
      skillLevelStrings.splice(skillLevelStrings.indexOf(tagtext), 1);
      setSkillLevels(skillLevelStrings);
      if (!skillLevelStrings.length) {
        changeskillPlaceholder(0);
      }
    }
  });

  return (
    <SuiBox>
      <SuiBox width="100%" textAlign="center" mb={4}>
        <SuiTypography variant="h5" fontWeight="regular">
          Tell us a bit more about your skills
        </SuiTypography>
      </SuiBox>

      <SuiBox mt={2}>
        <SuiBox ml={1} mb={0.5}>
          <SuiTypography variant="button">
            Upload your Resume / CV
          </SuiTypography>
        </SuiBox>
        <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={uploadResume} />
      </SuiBox>

      <SuiBox mt={2}>
        <SuiBox ml={1} mb={0.5}>
          <SuiTypography variant="button">
            Add your skill levels
          </SuiTypography>
        </SuiBox>
        
        <Grid container xs={12} md={12} spacing={1}>
          <Grid item xs={4}>
            <SuiSelect
              placeholder={skills.length < 1 ? "Loading..." : "Skill"}
              disabled={skills.length < 1}
              options={skills}
              smallFont={isSmallScreen}
              name="skill"
            />
          </Grid>
          <Grid item xs={4}>
            <SuiSelect
              placeholder="Years"
              options={years}
              smallFont={isSmallScreen}
              name="years"
            />
          </Grid>
          <Grid item xs={4} >
            <SuiButton color="info" onClick={addSkill} fullWidth>Add</SuiButton>
          </Grid>
          <Grid item xs={12} md={12} className="skillsBox">
            <SuiTagInput
              placeholder="Skills."
              tags={skillLevels}
              onChange={removeTag}
            />
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox>
        <SuiBox mt={2} className="interviewInEnglishBox">
          <SuiBox ml={1} mb={0.5}>
            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>
              Are you able to hold an interview in English?
            </SuiTypography>
          </SuiBox>
          <SuiSelect
            placeholder={interviewPlaceHolder}
            options={defaultOptions}
            name="interviewInEnglish"
            isSearchable={false}
          />
        </SuiBox>
      </SuiBox>

      <SuiBox>
        <SuiBox mt={2} className="englishLevelBox">
          <SuiBox ml={1} mb={0.5}>
            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>
              On a scale of 1 to 10: What is your level of spoken English?
              { candidate.englishLevelValidated ? (
                <Tooltip title="Validated by our team. To change this please request an interview" placement="top">
                  <CheckBoxIcon fontSize="small" color="success" sx={{ verticalAlign: "middle" }} />
                </Tooltip>
              ) : null }
            </SuiTypography>
          </SuiBox>
          { candidate.englishLevelValidated ? (
            <SuiInput name="englishLevel" type="number" value={candidate.englishLevel} disabled />
          ) : (
            <SuiSelect
              placeholder={levelPlaceHolder}
              options={levels}
              name="englishLevel"
              isSearchable={false}
            />
          )}
        </SuiBox>
      </SuiBox>

      <SuiBox>
        <SuiBox mt={2} className="canWorkRemotelyBox">
          <SuiBox ml={1} mb={0.5}>
            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>
              Will you be able to work with your own equipment?
            </SuiTypography>
          </SuiBox>
          <SuiSelect
            placeholder={canWorkPlaceHolder}
            options={defaultOptions}
            name="canWorkRemotely"
            isSearchable={false}
          />
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default Skills;
