/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
// Soft UI Dashboard PRO React components
import { useState, useEffect } from "react"
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Card from "@mui/material/Card"
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React example components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiAlert from "components/SuiAlert"
import SuiPagination from "components/SuiPagination";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import DashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import DataTable from "examples/Tables/DataTable"
// Data
import dataTableData from "layouts/pages/searchCompany/data/dataTableData";
import dataTableDataSmall from "layouts/pages/searchCompany/data/dataTableDataSmall";
import SuiButton from "components/SuiButton";
import CreateCompany from "examples/Modal/CreateCompany";
// Modal
import SalesModal from "layouts/pages/profile/components/CompanyHeader/SalesModal/SalesModal"


function ProductsList() {
  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("user");
  const recruiter = ReactSession.get("recruiter");
  const token = ReactSession.get("token");

  useEffect(() => {
    document.title = `Companies | TECLA`;
  }, []);

  const [applicationsData, setApplicationsData] = useState(dataTableData);
  const [applicationsDataSmall, setApplicationsDataSmall] = useState(dataTableDataSmall);

  const [pagination, setPagination] = useState({ pages: [] });
  const [page, setPage] = useState(1)
  const [totalResults, setTotalResults] = useState(null)
  const [recruiterManagers, setRecruiterManagers] = useState([{ value: -1, label: "All" }]);
  const [selectedRecruiter, setSelectedRecruiter] = useState(-1);

  const [withOpenJobs, setWithOpenJobs] = useState(true)
  const [name, setName] = useState("")
  const [managers, setManagers] = useState([]);
  const [updateManagers, setUpdateManagers] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);

  const [isFetchingData, setIsFetchingData] = useState(false)

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formatContractType = contractType => {
    switch (contractType) {
      case 'staff_augmentation':
        return 'Staff Augmentation'
      case 'direct_hire':
        return 'Direct Hire'
      case 'sourcing_service':
        return 'Sourcing as a service'
      case 'staff_augmentation-conversion':
        return 'Staff Augmentation (conversion)'
      default:
        return contractType
    }
  }

  // Sales modal
	const [openSales, setOpenSales] = useState(false);
	const [companyForSalesModal, setCompanyForSalesModal] = useState(null);

	const [companyUpdated, setCompanyUpdated] = useState(0);
	const [companySalesData, setCompanySalesData] = useState({});
	const [salesNotes, setSalesNotes] = useState("<br/><br/><br/><br/><br/><br/><br/><br/>");
	const [loading, setLoading] = useState(false);

	function saveSales() {
		setLoading(true);
	
		const formPostData = new FormData();
		formPostData.append('contractType', $("input[name=contract]").val());
		formPostData.append('notes', salesNotes);
		
		fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/${companyForSalesModal?.id}/updateCompanySale/`, {
			method: 'POST',
			headers: {
			  "Content-Disposition": `attachment; filename=file.pdf`,
			  'Authorization': `Token ${token}`
			},
			body: formPostData
		})
		  .then(res => res.json())
		  .then(response => {
        if (response.success) {
          setLoading(false)
          window.location.reload()
        }
		  })
	}

  const fetchCompanySalesData = () => {
    if (companyForSalesModal?.id) {
      const headers = { "Content-Type": "application/json" }
      if (token) headers.Authorization = `Token ${token}`
    
      fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/getCompanySale/?company=${companyForSalesModal?.id}`, { headers })
          .then(async response => {
          const data = await response.json()

          if (data.success && data.data) {
            setCompanySalesData(data.data);
            if (data.data.notes !== "") setSalesNotes(data.data.notes);
            setLoading(false);
          }
          })
    } 
  }

  useEffect(() => {
    if (companyForSalesModal) {
      fetchCompanySalesData()
      setTimeout(() => setOpenSales(true), 1000)
    }
  }, [companyForSalesModal])

	useEffect(() => { fetchCompanySalesData() }, [companyUpdated])

  function openManagerModal(managerId) {
    const newData = [];
    const tempManagersJson = JSON.parse($(".managerBox").attr("data-managers"));
    $(".managerBox").attr("data-manager", managerId);
    let fullName = "";
    let filterResult = [];
    let isManaged = false;

    // eslint-disable-next-line
    for (const manager of tempManagersJson) {
      isManaged = false;
      fullName = `${manager.user.first_name} ${manager.user.last_name}`;
      filterResult = manager.companies.filter(x => Number(x.id) === Number(managerId));
      if (filterResult && filterResult.length) {
        isManaged = true;
      }
      newData.push({ value: manager.user.id, label: fullName, checked: isManaged });
    }
    setManagers(newData);
    handleOpen();
  }

  const fetchCompaniesData = (showSpinner) => {
    if (showSpinner) setIsFetchingData(true)

    let withOpenJobsInt = 1;
    if (!withOpenJobs) {
      withOpenJobsInt = 0;
    }
    const url = `${process.env.REACT_APP_API_ROUTE}/api/companies/teclaCompaniesFind/?name=${name}&withOpenJobs=${withOpenJobsInt}&page=${page}&recruiterId=${selectedRecruiter}`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line

        if (res.success && res.data) {
          const applicationsDataTemp = { ...applicationsData };
          const applicationsDataSmalltemp = { ...applicationsDataSmall };
          const restCompanies = res.data.companies;

          const applicationsArray = [];
          let isNotManaged = true;
          if (user.type === "tecla_admin") {
            isNotManaged = false;
          }

          // eslint-disable-next-line
          for (const company of restCompanies) {
            const companyUrl = `/r/company/${company.id}`;
            const managersArray = []
            company.managers.forEach(item => managersArray.push(
              {
                first_name: item.user.first_name,
                last_name: item.user.last_name,
                photo: item.user.photo
              }
            ))

            applicationsArray.push({
              company: [company.name, company.photo, companyUrl],
              managers: managersArray,
              openjobs: company.openJobs,
              pausedjobs: company.pausedJobs,
              closedjobs: company.closedJobs,
              contractType: formatContractType(company?.sale?.contractType),
              action: {
                companyId: company.id,
                isManaged: isNotManaged,
                openManagerModal: openManagerModal,
                company: company,
                setCompanyForSalesModal: setCompanyForSalesModal
              }
            })
          }
          applicationsDataTemp.rows = applicationsArray;
          applicationsDataSmalltemp.rows = applicationsArray;
          // applications = applicationsDataTemp;
          // applicationsSmall = applicationsDataSmalltemp;
          setApplicationsData(applicationsDataTemp);
          setApplicationsDataSmall(applicationsDataSmalltemp);

          const resPagination = { ...res.data.pagination };
          resPagination.hasPrev = true;
          resPagination.hasNext = true;
          if (resPagination.current === 1 || resPagination.countPages === 1) {
            resPagination.hasPrev = false;
          }

          if (resPagination.current === resPagination.countPages) {
            resPagination.hasNext = false;
          }

          if (resPagination.countPages >= 5) {
            if (resPagination.current === 1 || resPagination.current === 2) {
              resPagination.pages = [1, 2, 3, 4, 5];
            } else {
              resPagination.pages = [resPagination.current - 2, resPagination.current - 1, resPagination.current];
              if (resPagination.countPages >= resPagination.current + 1) {
                resPagination.pages.push(resPagination.current + 1);
              }
              if (resPagination.countPages >= resPagination.current + 2) {
                resPagination.pages.push(resPagination.current + 2);
              }
            }
          } else {
            resPagination.pages = [];
            // eslint-disable-next-line
            for (let i = 0; i < resPagination.countPages; i++) {
              resPagination.pages.push(i + 1);
            }
          }

          resPagination.pagesHtml = [];
          if (resPagination.pages.length > 1) {
            // eslint-disable-next-line
            for (let i = 0; i < resPagination.pages.length; i++) {
              if (resPagination.pages[i] === resPagination.current) {
                resPagination.pagesHtml.push(<SuiPagination className="pageReload" item active> {resPagination.pages[i]} </SuiPagination>)
              } else {
                resPagination.pagesHtml.push(<SuiPagination onClick={() => setPage(resPagination.pages[i])} item> {resPagination.pages[i]} </SuiPagination>)
              }
            }
          }

          setPagination(resPagination);
          if (res.data.pagination.count !== totalResults) setTotalResults(res.data.pagination.count)
          if (showSpinner) setIsFetchingData(false)
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => { fetchCompaniesData(true) }, [page, withOpenJobs, selectedRecruiter]);

  useEffect(() => { 
    if (!firstLoad) {
      fetchCompaniesData(false) 
    } else {
      setFirstLoad(false);
    }
  }, [name]);

  useEffect(() => {
    if (user.type === "tecla_admin") {
      const url = `${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiterManagers/`;
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      if (token) {
        headers.Authorization = `Token ${token}`;
      }
      fetch(url, { headers, })
        .then(async response => {
          const res = await response.json();
        validateToken(res) // eslint-disable-line

          if (res.success) {
            const previousManagers = [...managers];
            let prevManagersFilter = [];
            const newData = [];
            const optionsData = [{ value: -1, label: "All" }];
            let fullName = "";
            let checked = false;
            // eslint-disable-next-line
            for (const manager of res.data) {
              prevManagersFilter = [];
              checked = false;
              fullName = `${manager.user.first_name} ${manager.user.last_name}`;
              if (updateManagers >= 1) {
                prevManagersFilter = previousManagers.filter(x => Number(x.value) === Number(manager.user.id));
                if (prevManagersFilter && prevManagersFilter.length) {
                  checked = prevManagersFilter[0].checked;
                }
              }
              newData.push({ value: manager.user.id, label: fullName, checked: checked });
              optionsData.push({ value: manager.user.id, label: fullName });
            }

            $(".managerBox").attr("data-managers", JSON.stringify(res.data));
            setManagers(newData);
            setRecruiterManagers(optionsData);
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, [updateManagers]);

  function showSuccess() {
    let timerSuccessid = "";
    $(".alertSuccessBox").show();
    if (timerSuccessid) clearTimeout(timerSuccessid)

    timerSuccessid = setTimeout(() => {
      $(".alertSuccessBox").hide();
    }, 3000);
  }

  function setManagerCheck(managerCheckId) {
    const tempManagers = [...managers];
    const newManagers = [];
    // eslint-disable-next-line
    for (const manager of tempManagers) {
      if (Number(manager.value) === Number(managerCheckId)) {
        manager.checked = !manager.checked;
      }
      newManagers.push(manager);
    }

    setManagers(newManagers);
  }

  function saveManagers() {
    const tempManagers = [...managers];
    const recruiters = [];
    // eslint-disable-next-line
    for (const manager of tempManagers) {
      if (manager.checked) {
        recruiters.push(manager.value);
      }
    }
    const companyId = Number($(".managerBox").attr("data-manager"));

    const postBody = {
      companyId: companyId,
      recruiters: recruiters
    };

    const requestMetadata = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(postBody)
    };

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/recruiterCompanyManager/`, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          setUpdateManagers(updateManagers + 1);
          showSuccess();
          fetchCompaniesData(true)
          handleClose();
        }
      })
  }

  return (
    <DashboardLayout>
      <SuiBox my={3}>
        <SuiBox className="alertSuccessBox" display="none">
          <SuiAlert color="info" className="alertSuccess">Managers Updated.</SuiAlert>
        </SuiBox>

        <SuiBox display="flex" flexWrap="wrap" width="100%" alignItems="center" justifyContent={{ sx: "start", xl: "space-between" }} my={2}>
          <SuiBox lineHeight={1}>
            <SuiTypography variant="h3" fontWeight="bold" className="managerBox">Active Companies</SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">Search and filter through all companies currently active.</SuiTypography>
          </SuiBox>

          <SuiBox>
            <SuiBox sx={{ display: "inline-block", width: "300px" }}>
              <SuiTypography variant="h6" fontWeight="bold">Sort by RM</SuiTypography>
              <Grid>
                <SuiSelect
                  placeholder="All"
                  options={recruiterManagers}
                  name="managers"
                  onChange={e => setSelectedRecruiter(e.value)}
                />
              </Grid>
            </SuiBox>
            { recruiter && recruiter.user && recruiter.user.type === "tecla_admin" ? (
              <CreateCompany text="test" />
             ) : null }
          </SuiBox>
        </SuiBox>

        <Card>
          {isFetchingData ?
            <SuiBox display="flex" justifyContent="center" alignItems="center" my={30} >
              <CircularProgress size={40} />
            </SuiBox>
          :
            <>
              <SuiBox display="flex" justifyContent="end" alignItems="center" p={3}>
                <SuiBox display="inherit" alignItems="inherit">
                  <SuiBox display="inherit" alignItems="inherit" mx={2}>
                    <Checkbox checked={withOpenJobs} onChange={() => {
                        setPage(1)
                        setWithOpenJobs(!withOpenJobs)
                      }}
                    />
                    <SuiTypography variant="overline">
                      With jobs only
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox width="12rem" ml="auto">
                    <SuiInput
                      placeholder="Search..."
                      value={name}
                      onChange={e => {
                        setPage(1)
                        setName(e.target.value)
                      }}
                    />
                  </SuiBox>
                </SuiBox>
              </SuiBox>

              <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
                <DataTable table={applicationsData} totalResults={totalResults} view="companies" paginationData={pagination} />
              </SuiBox>

              <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
                <DataTable table={applicationsDataSmall} totalResults={totalResults} view="companies" paginationData={pagination} />
              </SuiBox>

              <br />

              <SuiBox mb={9} className="paginationBox" style={{ margin: "20px auto 40px auto" }}>
                <SuiPagination>
                  {(pagination.count > 0 && pagination.hasPrev) && (
                    <SuiPagination item onClick={() => setPage(page - 1)}>
                      <Icon>keyboard_arrow_left</Icon>
                    </SuiPagination>
                  )}

                  {(pagination.count > 0) && pagination.pagesHtml}
                  
                  {(pagination.count > 0 && pagination.hasNext) && (
                    <SuiPagination item onClick={() => setPage(page + 1)}>
                      <Icon>keyboard_arrow_right</Icon>
                    </SuiPagination>
                  )}
                </SuiPagination>
              </SuiBox>
            </>
          }
          
        </Card>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SuiBox sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: "100vw", md: "40vw", xl: "40vw" },
            height: "auto",
            maxHeight: "90%",
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            borderRadius: { xs: 2, md: 4 },
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center">
              <SuiBox display="block" flexWrap="wrap">
                <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Assign Recruiting Managers</SuiTypography>
              </SuiBox>
              <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
            </SuiBox>

            <SuiBox id="modal-modal-description" bgColor="#f8f9fa" borderRadius="16px" height="auto" my={2} p={2} sx={{ overflow: "auto" }}>
              <Grid container xs={12} md={12} spacing={1}>
                {managers.map(manager => (
                  <SuiBox item xs={12} sm={12} style={{ margin: "5px", display: "block", width: "100%" }}>
                    <Checkbox data-id={manager.value} className="selectSkill" checked={manager.checked} onChange={() => setManagerCheck(manager.value)} />
                    <SuiTypography
                      variant="button"
                      fontWeight="regular"
                      sx={{ userSelect: "none" }}>
                      {manager.label}
                    </SuiTypography>
                  </SuiBox>
                ))}
              </Grid>
            </SuiBox>
            <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
              <SuiButton color="info" onClick={saveManagers} >Save</SuiButton>
            </SuiBox>
          </SuiBox>
        </Modal>
      </SuiBox>

      {/* Sales modal */}
      {openSales &&  (
				<SalesModal
					openSales={openSales}
					setOpenSales={setOpenSales}
					company={companyForSalesModal}
					companySalesData={companySalesData}
					companyUpdated={companyUpdated}
					setCompanyUpdated={setCompanyUpdated}
					salesNotes={salesNotes}
					setSalesNotes={setSalesNotes}
					saveSales={saveSales}
					loading={loading}
					setLoading={setLoading}
				/>
			)}

    </DashboardLayout>
  );
}

export default ProductsList;
