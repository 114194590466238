/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session'
import { useState } from "react";
// @mui material components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";

function DeleteFormConfirmationModal({ showDeleteFormConfirmationModal, setShowDeleteFormConfirmationModal, formId }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  const [showError, setShowError] = useState(false)

  const deleteForm = () => {
    setShowSpinner(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/deleteForm/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({ formInstanceId: formId })
    })
    .then(async response => {
        const data = await response.json()

        if (data.success) {
            setShowSpinner(false)
            setShowDoneIcon(true)

            setTimeout(() => {
                setShowDeleteFormConfirmationModal(false)
                setShowDoneIcon(false)
                window.location.reload()
            }, 2000)
        }
        else {
            setShowError(true)
            setShowSpinner(false)
            setTimeout(() => setShowError(false), 2000)
        }
    })
    .catch(error => {
        setShowError(true)
        setShowSpinner(false)
        setTimeout(() => setShowError(false), 2000)
        console.error('There was an error!', error)
    })
  }

    return (
        <Modal
            open={showDeleteFormConfirmationModal}
            onClose={() => setShowDeleteFormConfirmationModal(false)}
        >
            <SuiBox
                sx={{
                    width: "650px",
                    height: "auto",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >

                    {showDoneIcon && <SuiAlert color="info">Form deleted!</SuiAlert>}
                    {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                    <SuiTypography sx={{ my: 2.5 }} variant="h4">Delete form</SuiTypography>

                    <SuiTypography sx={{ my: 2.5 }} variant="body2">Are you sure you want to delete this form? This can&apos;t be undone.</SuiTypography>
                
                    <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                        <SuiButton
                            sx={{ mx: 1 }}
                            fullWidth
                            color="info"
                            variant="outlined"
                            disabled={showSpinner || showDoneIcon}
                            onClick={() => setShowDeleteFormConfirmationModal(false)}
                        >
                            Cancel
                        </SuiButton>

                        {/* eslint-disable-next-line */}
                        {showSpinner ?
                            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                            :
                            showDoneIcon ?
                                <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                                :
                                <SuiButton
                                    sx={{ mx: 1 }}
                                    fullWidth
                                    color="info"
                                    variant="gradient"
                                    disabled={showSpinner || showDoneIcon}
                                    onClick={() => deleteForm()}
                                >
                                    Delete
                                </SuiButton>
                        }
                    </SuiBox>
            </SuiBox>
        </Modal>
    )
}

// Typechecking props for the ProfilesList
DeleteFormConfirmationModal.propTypes = {
    showDeleteFormConfirmationModal: PropTypes.bool.isRequired,
    setShowDeleteFormConfirmationModal: PropTypes.func.isRequired,
    formId: PropTypes.number.isRequired
};
  

export default DeleteFormConfirmationModal