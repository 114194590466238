/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import Swal from "sweetalert2";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React example components
import CompanyDashboardLayout from "examples/LayoutContainers/CompanyDashboardLayout";
import JobCard from "examples/Cards/CompanyJobCard";
// Images
import placeholder from "assets/images/illustrations/recommended.png";


function Default() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const sesionCompany = { ...ReactSession.get("company") };

  useEffect(() => {
    document.title = `Paused Jobs | TECLA`;
  }, []);

  let pausedJobs = [];
  // eslint-disable-next-line
  for (const job of sesionCompany.jobs) {
    if (job.status === "pause") {
      pausedJobs.push(job);
    }
  }
  sesionCompany.jobs = pausedJobs;
  sesionCompany.favoriteJobs = [];
  const [company, setCompany] = useState(sesionCompany);

  const [developerFirstMenu, setDeveloperFirstMenu] = useState(null);
  const [updateCompany, setUpdateCompany] = useState(null);

  const openDeveloperFirstMenu = (event) => {
    setDeveloperFirstMenu(event.currentTarget);
    const targetId = ($(event.currentTarget).parents(".dashboardJob").attr("data-id"));
    $(".customMenu > .MuiPaper-root").css("display", "none");
    $(".customMenu").css("z-index", "");
    $(`.menu-${targetId} > .MuiPaper-root`).css("display", "block");
    $(`.menu-${targetId}`).css("z-index", "1311");
  }
  const closeDeveloperFirstMenu = () => setDeveloperFirstMenu(null);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_ROUTE}/api/companies/company/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };

    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success) {
          const companyData = { ...res.data };
          pausedJobs = [];
          // eslint-disable-next-line
          for (const job of companyData.jobs) {
            if (job.status === "pause") {
              pausedJobs.push(job);
            }
          }
          companyData.jobs = pausedJobs;
          setCompany(companyData);
          ReactSession.set("company", res.data);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, [updateCompany]);

  function getDifferenceInSeconds(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / 1000;
  }

  function timeAgo(date) {
    const currentDate = new Date();
    const seconds = Math.floor(getDifferenceInSeconds(new Date(date), currentDate));
    const mins = Math.floor(seconds / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    let timePassed = "";
    if (months >= 1) {
      timePassed = `${months}M ago`;
    } else if (days >= 1) {
      timePassed = `${days}d ago`;
    } else if (hours >= 1) {
      timePassed = `${hours}h ago`;
    } else if (mins >= 1) {
      timePassed = `${mins}m ago`;
    } else if (seconds >= 1) {
      timePassed = `${seconds}s ago`;
    }

    return timePassed;
  }

  function buildMenu(state, close, status, jobId) {
    const menuItems = [];
    if (status === "active") {
      menuItems.push(<MenuItem className="menuPause" data-id={jobId} onClick={close}>Pause</MenuItem>);
    }
    if (status === "pause") {
      menuItems.push(<MenuItem className="menuUnpause" data-id={jobId} onClick={close}>Unpause</MenuItem>);
    }
    if (status === "in_review") {
      menuItems.push(<MenuItem className="menuEdit" data-id={jobId} onClick={close}>Edit</MenuItem>);
    }
    if (status !== "closed") {
      menuItems.push(<MenuItem className="menuClose" data-id={jobId} onClick={close}>Close</MenuItem>);
    }

    const menuName = `menu-${jobId} customMenu`;
    const renderMenu = (
      <Menu
        className={menuName}
        anchorEl={state}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(state)}
        onClose={close}
        keepMounted
      >
        {menuItems}
      </Menu>
    );

    return renderMenu;
  }

  const declineDialog = (dataJobId, options, url, textConfirm, isPause, action) => Swal.fire(options).then((result) => {
    if (result.isConfirmed) {
      const data = {};
      let validPost = false;
      
      if (textConfirm && textConfirm.length) {
        // eslint-disable-next-line
        if ($("input[name=confirm]").val().toLowerCase() === textConfirm.toLowerCase())  validPost = true;

        if ($("textarea:visible").val()) data.closeReason = $("textarea:visible").val();
      }
      else if (action === 'close' || action === 'edit' || action === 'pause') validPost = true;
      else validPost = true;

      if (action === 'close') data.closeReason = $("textarea:visible").val();
      if (action === 'edit') data.editReason = $("textarea:visible").val();
      if (action === 'pause') {
        data.pause = isPause;
        data.reason = $("textarea:visible").val();
      }

      if (validPost) {

        const postToken = `Token ${token}`;
        const requestMetadata = {
          method: action === 'edit' ? 'PATCH' : 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': postToken
          },
          body: JSON.stringify(data)
        };
        fetch(url, requestMetadata)
          .then(res => res.json())
          .then(response => {
            
            if (response.success) {
              setUpdateCompany(updateCompany + 1);
              if (textConfirm === "RE-OPEN") Swal.fire("This search has been re-opened.", "You can see it in your dashboard with IN-REVIEW status.", "success")
              else if (action === 'close' || action === 'edit' || action === 'pause') Swal.fire("Thanks for submitting your request", "Our team will review your request as soon as possible. We will notify you if there is any problem.", "success")
            }
          });
      }
    }
  })

  // eslint-disable-next-line
  $(document).unbind().on("click", ".menuPause, .menuUnpause, .menuReopen, .menuEdit, .menuClose", function () {
    const dataJobId = $(this).attr("data-id");

    let title = "Are you sure to pause this search?"
    let text = "Applicants will not be able to see it on their searches.";
    let html = '<b>Reason for pausing search:</b><br/><textarea id="swal-input1" class="swal2-input" rows="4" style="height: 100px; width: 400px;" placeholder=""></textarea>' +
    '<br/><br/>';
    let url = `${process.env.REACT_APP_API_ROUTE}/api/companies/${dataJobId}/companyPauseJob/`;
    let isPause = 1;
    let textConfirm = "";
    let action = "pause";
    
      if ($(this).hasClass("menuEdit")) {
        url = `${process.env.REACT_APP_API_ROUTE}/api/companies/${dataJobId}/companyUpdateJob/`;
        title = null
        text = null;
        html = '<div class="swal2-html-container" id="swal2-html-container" style="display: block;">Please describe what you would like to edit on this search with as much detail as possible:</div>' +
        '<br/><textarea id="swal-input1" class="swal2-input" rows="4" style="height: 100px; width: 400px;" placeholder=""></textarea>';
        action = 'edit'
      
      } else if ($(this).hasClass("menuUnpause")) {
        isPause = 0;
        title = "Are you sure to resume this search?"
        text = "Applicants will be able to see it again on their searches.";
      
      } else if ($(this).hasClass("menuReopen")) {
        textConfirm = "RE-OPEN";
        url = `${process.env.REACT_APP_API_ROUTE}/api/companies/${dataJobId}/companyReopenJob/`;
        title = null
        text = "Are you sure to re-open this search? this will create a new copy of it with no applications.";
        html = '<div class="swal2-html-container" id="swal2-html-container" style="display: block;">Are you sure to re-open this search? this will create a new copy of it with no applications.</div>' +
          '<br/><b>To confirm please type "RE-OPEN" below</b><br/><input id="openInput" name="confirm" class="swal2-input" style="text-transform: uppercase;">';
      
        } else if ($(this).hasClass("menuClose")) {
        url = `${process.env.REACT_APP_API_ROUTE}/api/companies/${dataJobId}/companyCloseJob/`;
        title = null
        text = "Are you sure to close this search? All the current aplicants will be rejected.";
        html = '<b>Reason for closing search:</b><br/><textarea id="swal-input1" class="swal2-input" rows="4" style="height: 100px; width: 400px;" placeholder=""></textarea>' +
          '<br/><br/>';
        action = 'close'
      }

      const options = {
        title: title || null,
        text: text,
        width: "600",
        height: "auto",
        showCloseButton: true,
        confirmButtonText: 'Confirm',
        confirmButtonAriaLabel: 'Confirm',
        focusConfirm: true,
        showCancelButton: false,
        allowEscapeKey: "true",
      }

      if (html.length) {
        options.html = html;
      }

      declineDialog(dataJobId, options, url, textConfirm, isPause, action);
    
  });

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <CompanyDashboardLayout>
      <SuiTypography variant="h3" fontWeight="bold" ml={1} mb={3}>
        Paused Jobs
      </SuiTypography>
      <SuiBox mb={3} display="flex" justifyContent="center" alignItems="center">
        {company.jobs && company.jobs.length ? (
          <Grid container spacing={3}>
            {company.jobs.map(hotJob => (
              <Grid item xs={12} md={6} lg={6} className="dashboardJob" data-id={hotJob.id}>
                <JobCard
                  job={hotJob}
                  by={{ name: hotJob.seniorityName, date: timeAgo(hotJob.createdAt) }}
                  badge={{ color: "success", label: hotJob.status }}
                  title={hotJob.seniorityName}
                  subtitle={hotJob.subtitle}
                  description={hotJob.description}
                  skills={hotJob.requiredSkills}
                  value={{ type: "$", from: hotJob.minSalary, to: hotJob.maxSalary , method: "Month" }}
                  sourcing={{ type: "internal", route: `/c/sourcing/${hotJob.id}`, label: "Sourcing", valid: company.sourcing }}
                  action={{ type: "internal", route: `/job?id=${hotJob.id}`, label: "see more" }}
                  manage={{ type: "internal", route: `/c/manage?id=${hotJob.id}`, label: "Manage" }}
                  dateTime="16.01.22"
                  members={3}
                  companyId={hotJob.company.id} 
                  applicationsCounter={hotJob.applicationsCounter}
                  dropdown={{
                    action: openDeveloperFirstMenu,
                    menu: buildMenu(developerFirstMenu, closeDeveloperFirstMenu, hotJob.status, hotJob.id),
                  }}
                />
              </Grid>
            ))}

          </Grid>
        ) :
          <Grid item container xs={12}>
            <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end" mt={isMobile ? 1 : 10} width="100%">
              <img src={placeholder} alt="Couple of documents" width={isMobile ? "100%" : "25%"} />
              <SuiTypography mb={1} variant="h2" textAlign="center" color="dark" fontWeight="bold">
                You don&apos;t have any paused jobs now
              </SuiTypography>
              <SuiTypography mb={4} variant="body2" textAlign="center" color="dark">
                When you pause a job it will be displayed here
              </SuiTypography>
            </SuiBox>
          </Grid>
        }
      </SuiBox>
    </CompanyDashboardLayout >
  );
}

export default Default;
