/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session'
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Modal from '@mui/material/Modal'
import useMediaQuery from '@mui/material/useMediaQuery'
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiAlert from "components/SuiAlert"
import SuiTypography from "components/SuiTypography"

function UploadCVModal({ openUploadCVModal, setOpenUploadCVModal, candidate }) {

    ReactSession.setStoreType("localStorage")
    const token = ReactSession.get("token")

    const [showSpinner, setShowSpinner] = useState(false)
    const [showDoneIcon, setShowDoneIcon] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showPDFError, setShowPDFError] = useState(false)

    const [uploadedCV, setUploadedCV] = useState(false)
  
    const uploadCV = () => { // eslint-disable-line
        setShowSpinner(true)

        const extension = uploadedCV.name.substring(uploadedCV.name.length - 4, uploadedCV.name.length).toLowerCase()
        
        if (!extension.includes("pdf")) {
            setShowPDFError(true)
            setShowSpinner(false)
            return setTimeout(() => setShowPDFError(false), 2000)
        }

        const normalizedName = "cv.pdf"
        
        const formPostData = new FormData()
        formPostData.append('cv', uploadedCV)
    
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/uploadCVCandidate/`, {
            method: 'PATCH',
            headers: {
                "Content-Disposition": `attachment; filename=${normalizedName}`,
                "content-type": "multipart/form-data;",
                'Authorization': `Token ${token}`
            },
            body: formPostData
        })
        .then(async response => {
            const data = await response.json()
  
            if (data.success) {

                if (response.data && response.data.cv) {
                    const sessionCandidate = { ...candidate }
                    sessionCandidate.cv = response.data.cv
                    ReactSession.set("candidate", sessionCandidate)
                }

                setShowSpinner(false)
                setShowDoneIcon(true)
        
                setTimeout(() => {
                    setOpenUploadCVModal(false)
                    setShowDoneIcon(false)
                }, 2000)
            }
            else {
                setShowError(true)
                setShowSpinner(false)
                
                setTimeout(() => setShowError(false), 2000)
            }
        })
        .catch(error => {
            setShowError(true)
            console.error('There was an error!', error)
        })
    }

    const isMobile = useMediaQuery('(max-width:500px)')

    return (
        <Modal open={openUploadCVModal} onClose={() => setOpenUploadCVModal(false)}>
            <SuiBox
                sx={{
                    width: isMobile ? "95%%" : "650px",
                    height: "auto",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                {showDoneIcon && <SuiAlert color="info">CV uploaded!</SuiAlert>}
                {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}
                {showPDFError && <SuiAlert color="error">Only PDF format is allowed for CVs.</SuiAlert>}

                <SuiTypography sx={{ my: 2.5 }} variant="h4">Upload CV</SuiTypography>

                <Grid item xs={12} sm={12} display="flex" flexDirection="column" mb={2}>
                    <TextField
                        id="cv"
                        type="file"
                        inputProps={{ accept: "application/pdf" }}
                        disabled={showSpinner}
                        onChange={() => {
                            const file = document?.getElementById('cv')?.files[0]
                            setUploadedCV(file)
                        }}
                    />
                    <SuiTypography variant="button" fontWeight="regular" color="text" mt={1}>Upload your English CV / Resume in PDF format</SuiTypography>
                </Grid>

                <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                    <SuiButton
                      sx={{ mx: 1 }}
                      fullWidth
                      color="info"
                      variant="outlined"
                      disabled={showSpinner || showDoneIcon}
                      onClick={() => setOpenUploadCVModal(false)}
                    >
                      Cancel
                    </SuiButton>

                    {/* eslint-disable-next-line */}
                    {showSpinner ?
                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                        :
                        showDoneIcon ?
                            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                            :
                            <SuiButton
                              sx={{ mx: 1 }}
                              fullWidth
                              disabled={!uploadedCV || showSpinner || showDoneIcon}
                              color="info"
                              variant="gradient"
                              onClick={() => uploadCV()}
                            >
                              Confirm
                            </SuiButton>
                    }
                </SuiBox>
            </SuiBox>
        </Modal>
    )
}

export default UploadCVModal

// typechecking props
UploadCVModal.propTypes = {
    openUploadCVModal: PropTypes.bool.isRequired,
    setOpenUploadCVModal: PropTypes.func.isRequired,
    candidate: PropTypes.object.isRequired, // eslint-disable-line
};