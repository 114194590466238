/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "new-user-form",
  formField: {
    firstName: {
      name: "firstName",
      type: "text",
      placeholder: "First Name",
      errorMsg: "First name is required.",
      tooShort: "Your first name should be more than 1 character.",
    },
    lastName: {
      name: "lastName",
      type: "text",
      placeholder: "Last Name",
      errorMsg: "Last name is required.",
      tooShort: "Your last name should be more than 1 character.",
    },
    title: {
      name: "title",
      type: "text",
      placeholder: "Title",
      errorMsg: "Title is required.",
      tooShort: "Your title should be more than 1 character.",
    },
    email: {
      name: "email",
      type: "email",
      placeholder: "Email Address",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid.",
      invalidDomainMsg: "Use a valid business email domain.",
    },
    bio: {
      name: "bio",
      label: "bio",
      type: "textarea",
      placeholder: "Say a few words about who you are or what you're working on",
      tooBig: "The company description cannot have more than 500 characters.",
    },
    photo: {
      name: "photo",
      type: "file",
      label: "bio",
      placeholder: "image"
    },
  },
};
