/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from 'prop-types'
import { useState } from 'react'
import { ReactSession } from 'react-client-session'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiInput from 'components/SuiInput'
// MUI components
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'

function ChangeCVModal({ openChangeCVModal, setOpenChangeCVModal, candidate, fetchFolderContent }) {

    const token = ReactSession.get("token")

    const [showSpinner, setShowSpinner] = useState(false)
	const [showDoneIcon, setShowDoneIcon] = useState(false)
	const [CVHasBeenUploaded, setCVHasBeenUploaded] = useState(false)
    
	const deleteCandidate = () => {
		setShowSpinner(true)

        const resumeField = $("input[name=cv]").prop('files')[0]
        const formPostData = new FormData();  
        formPostData.append('cv', resumeField);

		fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${candidate.pk}/uploadCVFolderClient/`, {
			method: 'PATCH',
			headers: {
				"Content-Disposition": "attachment; filename=cv.pdf",
                "content-type": "multipart/form-data;",
				"Authorization": `Token ${token}`
			},
			body: formPostData
		})
			.then(async response => {
				const data = await response.json()

				if (data.success) {
					setShowSpinner(false)
					setShowDoneIcon(true)
					fetchFolderContent()

					setTimeout(() => {
						setOpenChangeCVModal(false)
						setShowDoneIcon(false)
					}, 1500)
				}
			})
			.catch(error => console.error('There was an error!', error))
	}

    return (
        <Modal open={openChangeCVModal} onClose={() => setOpenChangeCVModal(false)}>
            <SuiBox
                sx={{
                    width: { xs: "100vw", md: "650px" },
                    height: { xs: "101vh", md: "auto" },
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <SuiTypography variant="h5" textAlign="center">
                    Change CV for {candidate?.candidate?.user?.first_name} {candidate?.candidate?.user?.last_name}
                </SuiTypography>

                <SuiBox mt={2}>
                    <SuiBox ml={1} mb={0.5}>
                        <SuiTypography variant="button">Upload Resume / CV</SuiTypography>
                    </SuiBox>
                    <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={() => setCVHasBeenUploaded(true)} />
                </SuiBox>

                <SuiBox width="80%" display="inherit" justifyContent="center" mx="auto" mt={2}>
                    <SuiButton
                        sx={{ width: "100%", marginRight: "1rem" }}
                        color="info"
                        variant="outlined"
                        disabled={showSpinner || showDoneIcon}
                        onClick={() => setOpenChangeCVModal(false)}
                    >
                        Cancel
                    </SuiButton>

                    {/* eslint-disable-next-line */}
                    {showSpinner ?
                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                        :
                        showDoneIcon ?
                            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                            :
                            <SuiButton
                                sx={{ width: "100%" }}
                                color="info"
                                variant="gradient"
                                onClick={() => deleteCandidate()}
                                disabled={!CVHasBeenUploaded}
                            >
                                Change CV
                            </SuiButton>
                    }
                </SuiBox>
            </SuiBox>
        </Modal>
    )
}

ChangeCVModal.propTypes = {
    openChangeCVModal: PropTypes.bool.isRequired,
    setOpenChangeCVModal: PropTypes.func.isRequired,
    candidate: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchFolderContent: PropTypes.func.isRequired,
}

export default ChangeCVModal