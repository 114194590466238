/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { ReactSession } from 'react-client-session';
import { Link } from "react-router-dom";
import { formatDate,capitalize, validateToken } from "utils/commonFunctions"; // eslint-disable-line
// SWAL
import Swal from 'sweetalert2'
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
// import Modal from '@mui/material/Modal';
// import Icon from '@mui/material/Icon';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
import Checkbox from "@mui/material/Checkbox";
// import Paper from '@mui/material/Paper';
// import TableRow from '@mui/material/TableRow';
// import Badge from '@mui/material/Badge';
// import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard React components
import SuiAlert from "components/SuiAlert";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
// import SuiEditor from "components/SuiEditor";
// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import headerBackground from "assets/images/headerBackground.jpg";
import mockCandidateAvatar from "assets/images/mockCandidateAvatar.png";
// Others
import RecruiterFormsModal from "layouts/pages/profile/components/Header/recruiterFormsModal/RecruiterFormsModal"
import CandidateActivityModal from "examples/Modal/CandidateActivity";
import CandidateNotesModal from "examples/Modal/CandidateProfileNotes";
import MarkHiredModal from "./markHiredModal/MarkHiredModal";
import ContractFilesModal from "./contractFilesModal/ContractFilesModal";
import ProfileHeaderMenuForRecruiters from "./ProfileHeaderMenuForRecruiters"
import CandidateScorecardModal from "./candidateScorecardModal/CandidateScorecardModal";
import CandidateCVsModal from "./candidateCVsModal/CandidateCVsModal";
// DESCOMENTAME
// DESCOMENTAME
// import RecruiterForms from 'layouts/pages/profile/components/RecruiterForms/index';
// DESCOMENTAME
// DESCOMENTAME

function Header({
  candidate,
  canEditApplication,
  canCreateApplication,
  canEdit,
  canShare,
  canNote,
  canForms,
  canSource,
  reFetchCandidateForms,
  isFetchingForms,
  isCandidate,
  magicLink,
  setshowAddToRecruiterFolderModal,
  setShowSoftBan,
  setshowAddToRecruiterSourcing,
  appId,
  token,
  recruiters,
  setRecruiters,
  notes,
  updateNotes,
  setUpdateNotes,
  application,
  setApplication,
  showToClient,
  setShowToClient,
  activities,
  setShowCompanyCandidateScorecardModal
}) {

  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("user");
  const company = ReactSession.get("company");
  const recruiter = ReactSession.get("recruiter");

  const isApplicationView = window.location.pathname.includes('/application')

  const CandidateSalaryExpectation = application && application.salary ? application.salary : ReactSession.get("CandidateSalaryExpectation");
  const isRecruiter = (user && (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin"));
  const isManager = (user && (user.type === "tecla_manager" || user.type === "tecla_admin"));
  const isCompany = user && user.type === "company_owner";

  // let addTimerId = "";

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [noteHtml, setNoteHtml] = useState("<br><br><br>");
  // const [recruitersSelected, setRecruitersSelected] = useState([]);

  const [showMarkHiredModal, setShowMarkHiredModal] = useState(false);
  const [showContractFilesModal, setShowContractFilesModal] = useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const [openTags, setOpenTags] = useState(false);
  // const handleOpenTags = () => {
  //   setOpenTags(true);
  // };
  // const handleCloseTags = () => setOpenTags(false);

  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);
  // const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const fullname = candidate.user ? `${candidate.user.first_name} ${candidate.user.last_name}` : "";
  const imgProfile = candidate.user && candidate.user.photo ? `${process.env.REACT_APP_API_ROUTE}${candidate.user.photo}` : mockCandidateAvatar;
  const title = candidate.title ? candidate.title : "";

  function copyToClipboard(text) {
    try {
        navigator.clipboard.writeText(text);
     } 
     catch (err) {
        console.log("fail copy")
     }
  }

  function copyTextClick(e) {
    e.preventDefault();
    const copyText = `${process.env.REACT_APP_PROD_ROUTE}t/profile/${candidate.user.id}`;
    copyToClipboard(copyText);
    // navigator.clipboard.writeText(copyText)
  }

  function copyMagicLink(e) {
    e.preventDefault();
    const copyText = candidate?.user?.magicLink;
    copyToClipboard(copyText);
    // navigator.clipboard.writeText(copyText)
  }

  // eslint-disable-next-line
  $(document).on("click", ".prevent", function (e) {
    e.preventDefault();
    $('.alertSuccessBox').show();
    let timerid = "";
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      $('.alertSuccessBox').hide();
    }, 2000);
  });

  // eslint-disable-next-line
  $(document).on("click", ".magic", function (e) {
    e.preventDefault();
    $('.magicSuccessBox').show();
    let timerid = "";
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      $('.magicSuccessBox').hide();
    }, 2000);
  });

  function setRecruiterCheck(recruiterCheckId) {
    const tempRecruiters = [...recruiters];
    const newRecruiters = [];
    // eslint-disable-next-line
    for (const recruiter of tempRecruiters) {
      if (Number(recruiter.id) === Number(recruiterCheckId)) {
        recruiter.checked = !recruiter.checked;
      }
      newRecruiters.push(recruiter);
    }

    setRecruiters(newRecruiters);
  }

  const [showNotesError, setShowNotesError] = useState(false)

  function deleteNote(noteId) {
    $(".moonLoaderBox").addClass("active");
    const requestMetadata = {
      method: 'POST',
      headers: {
        "content-type": "application/json",
        "Authorization": `Token ${token}`
      },
      body: JSON.stringify({})
    };
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/notes/${noteId}/removeNote/`;
    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          setUpdateNotes(updateNotes + 1);
        }
        else {
          setShowNotesError(true)          
          setTimeout(() => setShowNotesError(false), 2000)
        }
      })
      .catch(error => {
        setShowNotesError(true)
        console.error('There was an error!', error)
      })
  }

  function createNote() {
    $(".moonLoaderBox").addClass("active");
    let postBody = {};
    const tempRecruiters = [...recruiters];
    const newRecruiters = [];
    const recruitersId = [];
    let recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/notes/createNote/`;
    const editId = $(".tableEditCell.editing svg:first").attr("data-id");
    if (editId) {
      recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/notes/${editId}/editNote/`;
    }
    // eslint-disable-next-line
    for (const recruiter of tempRecruiters) {
      if (recruiter.checked) {
        recruitersId.push(recruiter.id)
      }
      recruiter.checked = false;
      newRecruiters.push(recruiter);
    }
    if (isCandidate) {
      postBody = {
        'candidate': Number(candidate.user.id),
        'content': noteHtml,
        'recruitersId': recruitersId
      };
    } else {
      postBody = {
        'candidate': Number(candidate.user.id),
        'application': Number(appId),
        'content': noteHtml,
        'recruitersId': recruitersId
      };
    }
    const requestMetadata = {
      method: 'POST',
      headers: {
        "content-type": "application/json",
        "Authorization": `Token ${token}`
      },
      body: JSON.stringify(postBody)
    };
    
    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          $(".tableEditCell.editing").removeClass("editing");
          setUpdateNotes(updateNotes + 1);
          $(".ql-editor").html("<p></p><p></p><p></p>");
          setNoteHtml("<br><br><br>");

          setRecruiters([]);
          setRecruiters(newRecruiters);
        }
      });
  }

  let salaryValue = 0;
  if (application && application.salary && application && application.job && application.job.hiringMethod === "dh") {
    salaryValue = application.salary; // eslint-disable-line
  }

  function checkShowApp(value) {
    const showToClientNumber = value ? 1 : 0;
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/${application.id}/setShowToClient/`;
    const postBody = {
        'showToClient': showToClientNumber,
    };
    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': `Token ${token}`,
        },
        body: new URLSearchParams(postBody)
    };

    fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
            console.log(response)
            setShowToClient(value);
        });
  }

  function validateDialog() {

    const baseText = "To validate this application and make it visible for the client first enter the salary the client will see. <br><br>";
    let warning = "";
    let button = "";
    // withSaleNotes
    if (application?.job?.hiringMethod === "sa" && application?.withSaleNotes) {
      warning = "<div><b style='color: #FF0000'>Warning:</b> This is a Staff Augmentation position, please check the sales profile before activating. <br><br></div>";
      button = `<a href="/r/company/${application.company.id}?sales=1" target="_blank" class="alertButton" tabindex="0" type="button">Sales Profile</a><br>`;
    } 
    else if (application?.job?.hiringMethod === "sa" && !application?.withSaleNotes) {
      warning = "<div><b style='color: #FF0000'>Warning:</b> This is a Staff Augmentation position and it doesn't have a sales profile yet, therefore applications can't be validated. Please contact the sales manager to fix this. <br><br></div>";
    }
    else if (application?.job?.hiringMethod === "dh" && !application?.withSaleNotes) {
      warning = "<div><b style='color: #FF0000'>Warning:</b> This position doesn't have a sales profile yet, therefore applications can't be validated. Please contact the sales manager to fix this. <br><br></div>";
    }

    const isFreelanceJob = application?.job?.commitment?.name === "Freelance";
    let html = ''

    if (isFreelanceJob) {
      html = `<div style="text-align: left; display: flex; flex-direction: column; justify-content: center; align-items: center">
        ${baseText}${warning}${button}
        <div style='text-align: center; font-weight: bold'>Expected hourly rate: $ ${CandidateSalaryExpectation}</div>
        <input type="number" id="swal-input1" class="swal2-input" placeholder="Salary" style="margin-right: auto; margin-left: auto;" />
      </div>`
    }
    else {
      html = `<div style="text-align: left; display: flex; flex-direction: column; justify-content: center; align-items: center">
        ${baseText}${warning}${button}
        <div style='text-align: center; font-weight: bold'>Expected salary: $ ${CandidateSalaryExpectation}</div>
        <input type="number" id="swal-input1" class="swal2-input" placeholder="Salary" style="margin-right: auto; margin-left: auto;" />
      </div>`
    }

    Swal.fire({
      icon: "info",
      title: 'Action required',
      html: html,
      // input: 'number',
      // inputPlaceholder: 'Salary',
      confirmButtonText: "Validate",
      // inputValue: salaryValue,
      didOpen: function (){ // eslint-disable-line
        if ((application.job.hiringMethod === "sa" && !application.withSaleNotes) || (application.job.hiringMethod === "dh" && !application.withSaleNotes)) {
          $(".swal2-confirm").attr("disabled", "disabled");
        }
      }
    }).then((result) => {
      if (result.isConfirmed && $('#swal-input1').val() && application.status === "in-review") {

        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/teclaValidateApplication/`;
        const salaryToShow = Number($('#swal-input1').val());
        const postData = { salaryToShow: Number($('#swal-input1').val()) };
        const requestMetadata = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify(postData)
        };

        fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
            if (response.success) {
              const tempApplication = { ...application };
              tempApplication.status = "active";
              tempApplication.salaryToShow = salaryToShow;
              setApplication(tempApplication);
            }
          });
      }
    })
  }

  function declineDialog() {
    Swal.fire({
      icon: "error",
      title: '',
      text: 'Are you sure to decline this application?',
      width: "400",
      height: "auto",
      input: "select",
      inputOptions: {
        0: "Select decline reason",
        1: "Compensation misalignment",
        2: "Employment duration too short",
        3: "English skills not up to par",
        4: "Location",
        5: "Offer declined",
        6: "Overqualified",
        7: "Motivations misalignment",
        8: "Underqualified",
        9: "Unresponsive",
        10: "Withdrew",
        11: "Position closed",
        12: "Better for another role",
      },
      inputPlaceholder: 'Click here to list of reasons',
      showCloseButton: true,
      confirmButtonText: 'Yes, decline',
      focusConfirm: true,
      showCancelButton: true,
      cancelButtonText: 'No, cancel',
      allowEscapeKey: "true",
      didOpen: () => {
        $(".swal2-select").val(0);
        $(".swal2-select option").first().remove();
      },
      preConfirm: () => {
        if (Number($(".swal2-container select").val()) === 0) {
          Swal.showValidationMessage('Select a valid option')   
        }
      }
    }).then((areaResult) => {
      const selectValue = Number(areaResult.value) - 1;
      if (areaResult.isConfirmed && application.status === "in-review" && selectValue >= 0) {
        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/setStatus/`;
        const postBody = {
          "status": "declined",
          "declinedReason": String(selectValue),
          "declinedComment": ""
        };
        const postToken = `Token ${token}`;
        const requestMetadata = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': postToken
          },
          body: new URLSearchParams(postBody)
        };

        fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
            if (response.success) {
              const tempApplication = { ...application };
              tempApplication.status = "declined";
              tempApplication.declinedReason = String(selectValue);
              setApplication(tempApplication);
            }
          });
      }
    })
  }

  // Notes can be deleted by admins and note authors
  const canDeleteNote = (noteAuthorId,) => {
    if (user.type === "tecla_admin") return true
    if (noteAuthorId === user.id) return true
    return false
  }

  // Notes can be edited by admins, managers with assigned company and note authors
  const canEditNote = (noteAuthorId,) => {
    // if (user.type === "tecla_admin" && application && application?.id) return true
    // if (user?.type === "tecla_manager" && recruiter?.companies?.filter(comp => comp?.id === application?.company?.id)?.length) return true
    if (noteAuthorId === user.id) return true
    return false
  }

  const [openActivityModal, setOpenActivityModal] = useState(false)

  const [openNotesModal, setOpenNotesModal] = useState(false)
  const [openTagsModal, setOpenTagsModal] = useState(false)

  const [showCandidateScorecardModal, setShowCandidateScorecardModal] = useState(false)

  const [candidateScorecardData, setCandidateScorecardData] = useState(null)

  const fetchCandidateScorecard = () => {    
      if (candidate?.user?.id) {
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidate?.user?.id}/getScoreboard/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(async response => {
            const data = await response.json()

            if (data.success) setCandidateScorecardData(data?.data)
        })
        .catch(error => console.error('There was an error!', error))
      }
  }

  useEffect(() => { if (!candidateScorecardData) fetchCandidateScorecard() }, [])

  const scorecardIsEmpty = candidateScorecardData && candidateScorecardData[0].value === ''

  const canEditScorecard = () => {
    // [Soy admin] o 
    if (user?.type === "tecla_admin") return true
    // [Soy manager con la compañía asignada] o 
    if (user?.type === "tecla_manager" && recruiter?.companies?.filter(comp => comp?.id === application?.company?.id)?.length) return true
    // [No soy manager o admin pero fui el recruiter que ha creado el Scoreboard] o 
    if (candidateScorecardData && candidateScorecardData.length &&  candidateScorecardData[0]?.recruiter?.user?.id === user?.id) return true
    // [No soy nada de eso y lo ha creado otro pero está vacío también puedo llenarlo]
    return scorecardIsEmpty
  }
    
  const [showCandidateCVsModal, setShowCandidateCVsModal] = useState(false)

  
  return (
    <SuiBox position="relative" className="theHeader" sx={{ backdropFilter: "none" }}>
      <SuiBox className="alertSuccessBox" display="none">
        <SuiAlert color="info" display="none">Link copied to clipboard!</SuiAlert>
      </SuiBox>
      <SuiBox className="magicSuccessBox" display="none">
        <SuiAlert color="info" display="none">Magic link copied to clipboard!</SuiAlert>
      </SuiBox>
      <SuiBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="10rem"
        borderRadius="xl"
        sx={{
          backgroundImage: `url(${headerBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: {sx: 'none', md: 'saturate(200%) blur(30px)'},
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        {/* CANDIDATE INFORMATION BELOW */}
        {/* CANDIDATE INFORMATION BELOW */}
        {/* CANDIDATE INFORMATION BELOW */}
        <Grid container spacing={1} alignItems="center">

          <Grid item>
            <SuiAvatar
              src={imgProfile}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <SuiBox height="90%" mt={0.5} lineHeight={1}>
              {isRecruiter && application?.candidate?.user?.id ? (
                <Link to={`/r/profile/${application?.candidate?.user?.id}`} target="_blank">
                  <SuiTypography variant="h5" fontWeight="medium">
                    {fullname}
                  </SuiTypography>
                </Link>
                )
              :
                (
                <SuiTypography variant="h5" fontWeight="medium">
                  {fullname}
                </SuiTypography>
              )}

              <SuiTypography variant="button" color="text" fontWeight="medium">
                {title}
              </SuiTypography>
            </SuiBox>
          </Grid>


          <Grid item xs={12} md={9} sx={{ ml: "auto", zIndex: 1000 }}>
            <AppBar position="static">
              <SuiBox display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="end" position="relative">

                {!isRecruiter && !company && canEdit && candidate && (
                  <SuiBox component={Link} to="/t/wizard" color="white" sx={{ width: { xs: "100%", sm: "auto" } }}>
                    <SuiButton color="info" sx={{ width: { xs: "100%", sm: "auto" }, marginBottom: 1, marginRight: { xs: 0, sm: 1 } }}>
                      Edit Profile
                    </SuiButton>
                  </SuiBox>
                )}
                
                {isRecruiter && (
                  <>
                    <SuiBox display="flex" flexDirection="column" justifyContent="start" alignItems="center">
                      <SuiBox display="flex">
                        <SuiButton variant="text" style={{ color: "#30385c" }} disabled={user.type === "tecla_recruiter"} onClick={() => setShowMarkHiredModal(true)} >
                          Hired?
                          <Checkbox style={{ marginLeft: "5px", border: "#30385c solid 1px" }} checked={candidate.hired ? true : false} /> {/* eslint-disable-line */}
                        </SuiButton>
                      </SuiBox>

                      {candidate.hired && (
                        <SuiTypography variant="button" color="#30385c" fontWeight="light">
                          {formatDate(candidate.hiredDate)}
                        </SuiTypography>
                      )}

                      {showMarkHiredModal && (
                        <MarkHiredModal showMarkHiredModal={showMarkHiredModal} setShowMarkHiredModal={setShowMarkHiredModal} candidateId={candidate.user.id} isHired={candidate.hired} candidateName={`${candidate.user.first_name} ${candidate.user.last_name}`} />
                      )}
                    </SuiBox>

                    {canEditApplication && application && (
                      <SuiBox display="flex">
                        <SuiButton variant="text" style={{ color: "#30385c" }} >
                          SHOW TO CLIENT
                          <Checkbox style={{ marginLeft: "5px", border: "#30385c solid 1px" }} checked={showToClient} onChange={() => checkShowApp(!showToClient)} />
                        </SuiButton>
                      </SuiBox>
                    )}

                    {canForms && (
                      <Badge
                        color="secondary"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        sx={{ width: { xs: "100%", sm: "auto" }, mx: 1, height: "3rem" }}
                        badgeContent={isRecruiter ? candidate?.formsCount : null}
                        showZero
                        max={999}
                      >
                        <SuiButton
                          color="info"
                          py={25}
                          onClick={() => handleOpenForm()}
                          sx={{ width: { xs: "100%", md: "auto" } }}
                        >
                          Forms
                        </SuiButton>
                      </Badge>
                    )}

                    {openForm && (
                      <RecruiterFormsModal
                        open={openForm}
                        onClose={handleCloseForm}
                        candidate={candidate}
                        fullname={fullname}
                        reFetchCandidateForms={reFetchCandidateForms}
                        isFetchingForms={isFetchingForms}
                        application={application}
                      />
                    )}

                    {canNote && (
                      <Badge
                        color="secondary"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        sx={{ width: { xs: "100%", sm: "auto" }, mx: 2, height: "3rem" }}
                        badgeContent={isRecruiter ? notes?.length : null}
                        showZero
                        max={999}
                      >
                        <SuiButton
                          color="info"
                          py={25}
                          onClick={() => setOpenNotesModal(true)}
                          sx={{ width: { xs: "100%", md: "auto" } }}
                        >
                          Notes
                        </SuiButton>
                      </Badge>
                    )}

                    {canEditApplication && application.status === "in-review" && (
                      <SuiButton
                        color="success"
                        variant="gradient"
                        onClick={validateDialog}
                        sx={{ width: { xs: "100%", sm: "auto" }, marginBottom: { xs: 1, md: 0 }, marginRight: 1, height: "3rem" }}
                      >
                        ACTIVATE
                      </SuiButton>
                    )}

                    {canEditApplication && application.status === "in-review" && (
                      <SuiButton
                        color="error"
                        variant="gradient"
                        onClick={declineDialog}
                        sx={{ width: { xs: "100%", sm: "auto" }, marginBottom: { xs: 1, md: 0 }, marginRight: 1, height: "3rem" }}
                      >
                        DECLINE
                      </SuiButton>
                    )}
                      
                    <ProfileHeaderMenuForRecruiters
                      candidate={candidate}
                      setShowContractFilesModal={setShowContractFilesModal}
                      isRecruiter={isRecruiter}
                      isManager={isManager}
                      magicLink={magicLink}
                      copyMagicLink={copyMagicLink}
                      canShare={canShare}
                      copyTextClick={copyTextClick}
                      canEditApplication={canEditApplication}
                      appId={appId}
                      canCreateApplication={canCreateApplication}
                      setshowAddToRecruiterFolderModal={setshowAddToRecruiterFolderModal}
                      setShowSoftBan={setShowSoftBan}
                      canSource={canSource}
                      setshowAddToRecruiterSourcing={setshowAddToRecruiterSourcing}
                      canForms={canForms}
                      handleOpenForm={handleOpenForm}
                      canNote={canNote}
                      setOpenNotesModal={setOpenNotesModal}
                      application={application}
                      validateDialog={validateDialog}
                      declineDialog={declineDialog}
                      setOpenActivityModal={setOpenActivityModal}
                      setShowCandidateScorecardModal={setShowCandidateScorecardModal}
                      canEditScorecard={canEditScorecard}
                      setShowCandidateCVsModal={setShowCandidateCVsModal}
                    />

                    {showCandidateCVsModal && (
                      <CandidateCVsModal
                        showCandidateCVsModal={showCandidateCVsModal}
                        setShowCandidateCVsModal={setShowCandidateCVsModal}
                        candidateId={candidate?.user?.id}
                      />
                    )}

                    {showContractFilesModal && (
                      <ContractFilesModal
                        showContractFilesModal={showContractFilesModal}
                        setShowContractFilesModal={setShowContractFilesModal}
                        candidateId={candidate?.user?.id}
                        contract={candidate?.contract}
                      />
                    )}
                    
                    {/* Notes modal */}
                    {(openNotesModal || openTagsModal) && (
                      <CandidateNotesModal
                        openNotesModal={openNotesModal}
                        setOpenNotesModal={setOpenNotesModal}
                        openTagsModal={openTagsModal}
                        setOpenTagsModal={setOpenTagsModal}
                        fullname={fullname}
                        noteHtml={noteHtml}
                        setNoteHtml={setNoteHtml}
                        createNote={createNote}
                        showNotesError={showNotesError}
                        notes={notes}
                        setUpdateNotes={setUpdateNotes}
                        canDeleteNote={canDeleteNote}
                        canEditNote={canEditNote}
                        deleteNote={deleteNote}
                        recruiters={recruiters}
                        setRecruiters={setRecruiters}
                        setRecruiterCheck={setRecruiterCheck}
                      />
                    )}

                    {isRecruiter && candidate && openActivityModal && (
                      <CandidateActivityModal
                        activities={activities}
                        fullname={fullname}
                        openActivityModal={openActivityModal}
                        setOpenActivityModal={setOpenActivityModal}
                      />
                    )}

                    {showCandidateScorecardModal && (
                      <CandidateScorecardModal
                        showCandidateScorecardModal={showCandidateScorecardModal}
                        setShowCandidateScorecardModal={setShowCandidateScorecardModal}
                        candidateName={`${capitalize(candidate?.user?.first_name)} ${capitalize(candidate?.user?.last_name)}`}
                        candidateId={candidate?.user?.id}
                        application={application}
                        cantEditRecords={!isApplicationView && canEditScorecard()} // Can only be edited if in /application page
                      />
                    )}

                  </>
                )}

                {isCompany &&
                  application?.scoreboard?.length > 0 &&
                  application?.scoreboard[0]?.value !== "" &&
                  application?.showScoreboard && (
                    <SuiBox color="white" sx={{ width: { xs: "100%", sm: "auto" } }} onClick={() => setShowCompanyCandidateScorecardModal(true)}>
                      <SuiButton color="info" sx={{ width: { xs: "100%", sm: "auto" }, marginBottom: 1, marginRight: { xs: 0, sm: 1 } }}>
                        Show scorecard
                      </SuiButton>
                    </SuiBox>
                )}

              </SuiBox>
            </AppBar>
          </Grid>

        </Grid>
      </Card >
    </SuiBox >
  );
}

Header.defaultProps = {
  canEditApplication: false,
  canCreateApplication: false,
  canEdit: false,
  canShare: false,
  canNote: false,
  canForms: false,
  canSource: false,
  reFetchCandidateForms: null,
  isFetchingForms: false,
  isCandidate: false,
  magicLink: false,
  setshowAddToRecruiterFolderModal: undefined,
  setShowSoftBan: undefined,
  setshowAddToRecruiterSourcing: undefined,
  appId: 0,
  token: "",
  recruiters: [],
  setRecruiters: undefined, 
  notes: [],
  updateNotes: 0,
  setUpdateNotes: undefined,
  application: {},
  setApplication: undefined,
  showToClient: false,
  setShowToClient: undefined,
  activities: [],
  setShowCompanyCandidateScorecardModal: undefined,
}

// typechecking props
Header.propTypes = {
  candidate: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  canEditApplication: PropTypes.bool,
  canCreateApplication: PropTypes.bool,
  canEdit: PropTypes.bool,
  canShare: PropTypes.bool,
  canNote: PropTypes.bool,
  canForms: PropTypes.bool,
  canSource: PropTypes.bool,
  reFetchCandidateForms: PropTypes.func,
  isFetchingForms: PropTypes.bool,
  isCandidate: PropTypes.bool,
  magicLink: PropTypes.bool,
  setshowAddToRecruiterFolderModal: PropTypes.func,
  setShowSoftBan: PropTypes.func,
  setshowAddToRecruiterSourcing: PropTypes.func,
  appId: PropTypes.number,
  token: PropTypes.string,
  recruiters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setRecruiters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  notes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  updateNotes: PropTypes.number,
  setUpdateNotes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  application: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setApplication: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showToClient: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setShowToClient: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  activities: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setShowCompanyCandidateScorecardModal: PropTypes.func,
};

export default Header;
