/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { ReactSession } from 'react-client-session';
import { useParams } from 'react-router-dom';
// formik components
import { Formik, Form } from "formik";
import validations from "layouts/applications/recruiter-application-edit/formComponents/validations";
import form from "layouts/applications/recruiter-application-edit/formComponents/form";
import initialValues from "layouts/applications/recruiter-application-edit/formComponents/initialValues";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CircularProgress from '@mui/material/CircularProgress';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
// Wizard page components
import Basic from "layouts/applications/recruiter-application-edit/components/Basic";
import Skills from "layouts/applications/recruiter-application-edit/components/Skills";
import Questions from "layouts/applications/recruiter-application-edit/components/Questions";
import LoadApplication from "examples/Modal/LoadApplication";

function RecruiterEditApplicationWizard() {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")
  const { appId } = useParams()
  let ApplicationForEdit = ReactSession.get(`ApplicationForEdit${appId}`)
  if (!ApplicationForEdit) {
    ApplicationForEdit = ReactSession.get("ApplicationForEdit");
    ReactSession.set(`ApplicationForEdit${appId}`, ApplicationForEdit);
  }

  const candidate = ApplicationForEdit?.user?.candidate

  useEffect(() => {
    document.title = `Edit Application | TECLA`;
  }, []);

  const [activeStep, setActiveStep] = useState(0)
  const steps = ["Basic", "Skills", "Questions"]

  const { formId, formField } = form
  const currentValidation = validations[activeStep]

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [isFetchingData, setIsFetchingData] = useState(false)

  const [appLoading, setAppLoading] = useState(false)
  const [appError, setAppError] = useState("")
  const [cvError, setCvError] = useState("")

  function showErrorModal() {
    setAppLoading(false);
  }

  function applicationsAnswer (question, appForEdit) {
    // eslint-disable-next-line
    for (const appQuestion of appForEdit.questions) {
      if (Number(appQuestion.question.id) === Number(question.id)) {
        return appQuestion.answer;
      }
    }

    return "";
  }

  // Questions
  const selectedQuest = []
  if (ApplicationForEdit.job.questions && ApplicationForEdit.job.questions.length) {
    ApplicationForEdit.job.questions.forEach(question => selectedQuest.push({ id: question.id, answer: applicationsAnswer(question, ApplicationForEdit) }));
  }
  const [selectedQuestions, setSelectedQuestions] = useState(selectedQuest)
  const [selectedCanWorkRemotely, setSelectedCanWorkRemotely] = useState(candidate?.canWorkRemotely)

  const fetchApplicationToEditData = () => {
    setIsFetchingData(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/${appId}/companyApplicationDetail/`, {
      headers: { "Authorization": `Token ${token}` }
    })
    .then(async response => {
      const data = await response.json();
      if (data.success) {
        const fetchAppData = {...data.data};
        if((!fetchAppData.questions || (fetchAppData.questions && !fetchAppData.questions.length)) && fetchAppData.job.questions && fetchAppData.job.questions.length) {
          const selectedJobQuest = []
          fetchAppData.job.questions.forEach(question => selectedJobQuest.push({ id: question.id, answer: applicationsAnswer(question, fetchAppData) }));
          setSelectedQuestions(selectedJobQuest)
        }
        // ReactSession.set("ApplicationForEdit", fetchAppData);
        setSelectedCanWorkRemotely(fetchAppData.user.candidate.canWorkRemotely)
        ReactSession.set(`ApplicationForEdit${appId}`, fetchAppData);
        setIsFetchingData(false)
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    })
  }

  useEffect(() => { fetchApplicationToEditData() }, [])

  /* State to store form inputs */
  // Skills
  const [CVwasUploaded, setCVwasUploaded] = useState(false)
  const [updateCV, setUpdateCV] = useState(false)
  const [selectedCV, setSelectedCV] = useState(ApplicationForEdit?.cv)
  const [selectedInterviewInEnglish, setSelectedInterviewInEnglish] = useState(ApplicationForEdit?.interviewInEnglish)
  const [selectedAvailability, setSelectedAvailability] = useState(ApplicationForEdit?.availability)
  const [selectedSalaryToShow, setSelectedSalaryToShow] = useState(ApplicationForEdit?.salaryToShow)

  function showError(text) {
    let textError = text;
    if (!textError) textError = "Please fill all the fields in this section before continuing.";

    let timerSuccessid = "";
    $(".alertErrorBox div div").text(textError);
    $(".alertErrorBox").show();

    $('html, body').animate({ scrollTop: $(".alertErrorBox").offset().top }, 100);
    if (timerSuccessid) clearTimeout(timerSuccessid);

    timerSuccessid = setTimeout(() => { $(".alertErrorBox").hide() }, 5000);
  }

  // eslint-disable-next-line
  function showSuccess() {
    $('.loadingBox span:visible:contains("close")').click()
    let timerSuccessid = "";
    $(".alertSuccessBox").show();
    $('html, body').animate({ scrollTop: $(".alertSuccessBox").offset().top }, 100);

    if (timerSuccessid) clearTimeout(timerSuccessid)

    timerSuccessid = setTimeout(() => {
      setIsRedirecting(false)
      window.location.replace(`/r/application/${appId}`)
    }, 2000);
  }

  const teclaUpdateApplication = async () => {
    setIsSubmitting(true)

    const requestBody = { user: { candidate: {} } }
    // Skills
    if (CVwasUploaded) requestBody.cv = selectedCV
    if (selectedInterviewInEnglish !== ApplicationForEdit.interviewInEnglish) requestBody.interviewInEnglish = selectedInterviewInEnglish
    if (selectedCanWorkRemotely !== candidate?.canWorkRemotely) requestBody.user.candidate.canWorkRemotely = selectedCanWorkRemotely
    if (selectedAvailability !== ApplicationForEdit.availability) requestBody.availability = selectedAvailability
    if (parseInt(selectedSalaryToShow, 10) !== ApplicationForEdit.salaryToShow) requestBody.salaryToShow = parseInt(selectedSalaryToShow, 10)

    // Questions
    if (selectedQuestions !== selectedQuest) requestBody.questions = selectedQuestions

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/applications/${ApplicationForEdit.id}/teclaUpdateApplication/`, {
      method: 'POST',
      headers: {
        "Authorization": `Token ${token}`,
        "content-type": "application/json"
      },
      body: JSON.stringify(requestBody)
    })
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          ReactSession.set(`ApplicationForEdit${ApplicationForEdit.id}`, null);
          showSuccess()
          setIsRedirecting(true)
          setIsSubmitting(false)
        } else if (response.data && response.data.error) {
          showErrorModal();
          setAppError(response.data.error)
        } else {
          showErrorModal();
          setAppError("Unexpected problem uploading your Application CV")
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  const uploadCV = async () => {
    setAppLoading(true);
    $(".loadApplicationBox button").click();
    const resumeField = selectedCV.get('cv');
    const normalizedName = resumeField.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    setIsSubmitting(true)

    const updateCVvalue = updateCV ? 1 : 0

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/uploadCVApplication/?updateProfileCV=${updateCVvalue}`, {
      method: 'PATCH',
      headers: {
        "Content-Disposition": `attachment; filename=${normalizedName}`,
        "Authorization": `Token ${token}`,
        "content-type": "multipart/form-data;"
      },
      body: selectedCV
    })
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          teclaUpdateApplication()
        } else if (response.data && response.data.error) {
          showErrorModal();
          setCvError(response.data.error)
        } else {
          showErrorModal();
          setCvError("Unexpected problem uploading your Application CV")
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  const updateApplication = async formValues => {
    if (CVwasUploaded) uploadCV(formValues)
    else teclaUpdateApplication(formValues)
  }

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <Basic
          setActiveStep={setActiveStep}
          showError={showError}
        />
      case 1:
        return <Skills
          formData={formData}
          setActiveStep={setActiveStep}
          setCVwasUploaded={setCVwasUploaded}
          setSelectedCV={setSelectedCV}
          selectedInterviewInEnglish={selectedInterviewInEnglish}
          setSelectedInterviewInEnglish={setSelectedInterviewInEnglish}
          selectedCanWorkRemotely={selectedCanWorkRemotely}
          setSelectedCanWorkRemotely={setSelectedCanWorkRemotely}
          selectedAvailability={selectedAvailability}
          setSelectedAvailability={setSelectedAvailability}
          selectedSalaryToShow={selectedSalaryToShow}
          setSelectedSalaryToShow={setSelectedSalaryToShow}
          showError={showError}
          setUpdateCV={setUpdateCV}
        />
      case 2:
        return <Questions
          formData={formData}
          setActiveStep={setActiveStep}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          updateApplication={updateApplication}
          showError={showError}
          isSubmitting={isSubmitting}
          isRedirecting={isRedirecting}
        />
      default:
        return <Basic setActiveStep={setActiveStep} />
    }
  }

  return (
    <RecruiterDashboardLayout>
      <SuiBox pb={8}>
        <SuiBox className="loadApplicationBox" display="none">
          <LoadApplication text="Load Application" appError={appError} cvError={cvError} appLoading={appLoading}/>
        </SuiBox>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>

            {isFetchingData ?
              <SuiBox mt={3} display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress size={40} />
              </SuiBox>
            :
              <>
                <SuiBox mt={6} mb={1} textAlign="center">
                  <SuiBox mb={1}>
                    <SuiTypography variant="h3" fontWeight="bold">Edit application to {`${ApplicationForEdit.job.seniorityName}`}</SuiTypography>
                  </SuiBox>
                  <SuiTypography variant="h5" fontWeight="regular" color="secondary">Fill the required details to apply for this position</SuiTypography>
                </SuiBox>

                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <SuiBox className="alertErrorBox" display="none">
                  <SuiAlert color="error" className="alertError">Please fill all the fields in this section before continuing.</SuiAlert>
                </SuiBox>
                <SuiBox className="alertUrlErrorBox" display="none">
                  <SuiAlert color="error" className="alertUrlError">Invalid Url.</SuiAlert>
                </SuiBox>
                <SuiBox className="alertSuccessBox" display="none">
                  <SuiAlert color="info" className="alertSuccess">Application submitted!</SuiAlert>
                </SuiBox>
                <Card>
                  <SuiBox p={2}>
                    <SuiBox>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                      >
                        {({ values, errors, touched }) => (
                          <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">
                            <SuiBox>
                              {getStepContent(activeStep, {
                                values,
                                touched,
                                formField,
                                errors,
                              })}

                            </SuiBox>
                          </Form>
                        )}
                      </Formik>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </>
            }
          </Grid>
        </Grid>
      </SuiBox>
    </RecruiterDashboardLayout>
  );
}

export default RecruiterEditApplicationWizard;
