/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session'
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";


function JobFeedbackModal({ showJobFeedbackModal, setShowJobFeedbackModal, jobId }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  const [showError, setShowError] = useState(false)

  const feedbackOptions = [
    { value: 'non-competitive-salary', label: 'Non competitive salary' },
    { value: 'missmatching-skills', label: 'Mismatching skills' },
    { value: 'responsibilities-not-challenging', label: 'Job responsibilities not challenging enough' },
    { value: 'company-description-interests', label: 'Company description doesn’t match my interests' },
    { value: 'company-beneficts-compelling', label: 'Company benefits are not compelling' },
    { value: 'other', label: 'Other' }
  ]

  const [selectedFeedbackOption, setSelectedFeedbackOption] = useState(null)
  const [feedbackText, setFeedbackText] = useState(null)

  const sendFeedback = () => {
    setShowSpinner(true)

    const headers = { 'Content-Type': 'application/json' }
    if (token) headers.Authorization = `Token ${token}`

    const body = { reason: selectedFeedbackOption }
    if (feedbackText) body.text = feedbackText

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/${jobId}/setJobFeedback/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    })
    .then(async response => {
      const data = await response.json()

      if (data.success) {
        setShowSpinner(false)
        setShowDoneIcon(true)

        setTimeout(() => {
          setShowJobFeedbackModal(false)
          setShowDoneIcon(false)
          window.location.reload()
        }, 2000)
      }
      else {
        setShowError(true)
        setShowSpinner(false)
        
        setTimeout(() => setShowError(false), 2000)
      }
    })
    .catch(error => {
      setShowError(true)
      console.error('There was an error!', error)
    })
  }

  return (
    <Modal open={showJobFeedbackModal} onClose={() => setShowJobFeedbackModal(false)}>
        <SuiBox
            sx={{
                width: { xs: "90%", md: "650px" },
                height: "auto",
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: 'none',
                boxShadow: 24,
                borderRadius: { xs: 0, md: 4 },
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
                {showDoneIcon && <SuiAlert color="info">Feedback sent!</SuiAlert>}
                {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                <SuiTypography sx={{ my: 2.5 }} variant="h4">Send us your feedback for this job</SuiTypography>

                <SuiTypography sx={{ mt: 2.5 }} variant="h6">Feedback</SuiTypography>
                <SuiSelect
                    placeholder="Select an option"
                    options={feedbackOptions}
                    onChange={e => setSelectedFeedbackOption(e.value)}
                />

                <SuiTypography sx={{ mt: 2.5 }} variant="h6">Tell us your thoughts about this job (optional - 500 chars. max)</SuiTypography>
                <SuiInput
                    placeholder="Type here..."
                    multiline
                    rows={5}
                    inputProps={{ maxLength: 500 }}
                    onChange={e => setFeedbackText(e.target.value)}
                />

                <SuiBox mt={4} mb={5} width='80%' mx='auto' display="flex">
                    <SuiButton
                      sx={{ mx: 1 }}
                      fullWidth
                      color="info"
                      variant="outlined"
                      disabled={showSpinner || showDoneIcon}
                      onClick={() => setShowJobFeedbackModal(false)}
                    >
                      Cancel
                    </SuiButton>

                    {/* eslint-disable-next-line */}
                    {showSpinner ?
                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                        :
                        showDoneIcon ?
                            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                            :
                            <SuiButton
                              sx={{ mx: 1 }}
                              fullWidth
                              disabled={showSpinner || showDoneIcon || !selectedFeedbackOption}
                              color="info"
                              variant="gradient"
                              onClick={() => sendFeedback()}
                            >
                              Send feedback
                            </SuiButton>
                    }
                </SuiBox>
        </SuiBox>
    </Modal>
  )
}

// Typechecking props for the ProfilesList
JobFeedbackModal.propTypes = {
  showJobFeedbackModal: PropTypes.bool.isRequired,
  setShowJobFeedbackModal: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired,
};
  

export default JobFeedbackModal