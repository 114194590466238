/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Grid from "@mui/material/Grid";
// import AppBar from "@mui/material/AppBar";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React base styles
// import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CompanyDashboardLayout from "examples/LayoutContainers/CompanyDashboardLayout";
import PageLayout from "examples/LayoutContainers/PageLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

function BaseLayout({ children, isGuest, isCompany }) {
  // const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [tabValue, setTabValue] = useState(0);

  // useEffect(() => {
  //   // A function that sets the orientation state of the tabs.
  //   function handleTabsOrientation() {
  //     return window.innerWidth < breakpoints.values.sm
  //       ? setTabsOrientation("vertical")
  //       : setTabsOrientation("horizontal");
  //   }

  //   /** 
  //    The event listener that's calling the handleTabsOrientation function when resizing the window.
  //   */
  //   window.addEventListener("resize", handleTabsOrientation);

  //   // Call the handleTabsOrientation function to set the state with the initial value.
  //   handleTabsOrientation();

  //   // Remove event listener on cleanup
  //   return () => window.removeEventListener("resize", handleTabsOrientation);
  // }, [tabsOrientation]);

  // const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <>
      {isGuest ? (
        <PageLayout>
          <SuiBox p={5}>
            {children}
          </SuiBox>
        </PageLayout>
      ) : null}
      {isCompany ? (
        <CompanyDashboardLayout>
          <SuiBox mt={1}>
            {children}
          </SuiBox>
        </CompanyDashboardLayout>
      ) : (
        <DashboardLayout>
          <SuiBox mt={1}>
            {children}
          </SuiBox>
        </DashboardLayout>

      )}

    </>
  );
}

BaseLayout.defaultProps = {
  isGuest: false,
  isCompany: false,
}
// Setting default values for the props of BaseLayout
// BaseLayout.defaultProps = {
//   stickyNavbar: false,
// };

// Typechecking props for BaseLayout
BaseLayout.propTypes = {
  // stickyNavbar: PropTypes.bool,
  isGuest: PropTypes.bool,
  isCompany: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
