/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from 'react';
import { validateToken, capitalize } from "utils/commonFunctions"; // eslint-disable-line
import { useParams } from 'react-router-dom';
// @mui material components
import Grid from '@mui/material/Grid';
// Soft UI Dashboard PRO React components
import SuiBadge from 'components/SuiBadge';
import SuiPagination from 'components/SuiPagination';
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from 'examples/LayoutContainers/RecruiterDashboardLayout'
import DashboardNavbar from 'layouts/pages/similarCandidates/FilterNavbar';
// Data
import Listas from 'layouts/pages/similarCandidates/components/ApplicationLists';
import dataTableData from 'layouts/pages/similarCandidates/data/dataTableData';
import dataTableDataSmall from 'layouts/pages/similarCandidates/data/sm/dataTableDataSmall';
import ActionCell from 'layouts/pages/similarCandidates/components/ActionCell';
import ProductCell from 'layouts/ecommerce/overview/components/ProductCell';
import mockCandidateAvatar from 'assets/images/mockCandidateAvatar.png';

function SimilarCandidates() {
  ReactSession.setStoreType('localStorage');
  const token = ReactSession.get('token');
  const recruiter = ReactSession.get('recruiter');
  
  const recruiterFolders = ReactSession.get('recruiterFolders');
  let candidatesViewedList = ReactSession.get('candidatesViewed');
  if (!candidatesViewedList) {
    candidatesViewedList = [];
  }

  useEffect(() => {
    document.title = `Similar Talent | TECLA`;
  }, []);

  const { cId } = useParams();

  const [applicationsData, setApplicationsData] = useState(dataTableData);
  const [applicationsDataSmall, setApplicationsDataSmall] = useState(dataTableDataSmall);

  const [originalCandidateName, setOriginalCandidateName] = useState('');

  const apiUrl = process.env.REACT_APP_API_ROUTE;

  const [pagination, setPagination] = useState({ pages: [] });
  const [totalResults, setTotalResults] = useState(null);

  const [isFetchingData, setIsFetchingData] = useState(false)

  const [method, setMethod] = useState(1); // eslint-disable-line
  const [seniority, setSeniority] = useState(1); // eslint-disable-line

  const [skills, setSkills] = useState([]); // eslint-disable-line 
  const [countries, setCountries] = useState([]); // eslint-disable-line
  const [search, setSearch] = useState(0);
  const [candidatesViewed, setCandidatesViewed] = useState(candidatesViewedList);
  const [filters, setFilters] = useState({
    firstName: "",
    email: "",
    skills: [],
    skillsInCV: [],
    countries: [],
    withCV: false,
    withVideo: false,
    active: false,
    excludes: "",
    page: 1,
    searchKeyWords: "",
    keywordsSearch: false
  });

  function setPage(value) {
    const filtersCopy = {...filters};
    filtersCopy.page = value;
    setFilters(filtersCopy);
    setSearch(search + 1);
  }

  function buildPagination(paginationData) {
    const funcPagination = {...paginationData};
    funcPagination.hasPrev = true;
    funcPagination.hasNext = true;
    if (funcPagination.current === 1 || funcPagination.countPages === 1) {
      funcPagination.hasPrev = false;
    }

    if (funcPagination.current === funcPagination.countPages) {
      funcPagination.hasNext = false;
    }

    if (funcPagination.countPages >= 5) {
      if (funcPagination.current === 1 || funcPagination.current === 2) {
        funcPagination.pages = [1, 2, 3, 4, 5];
      } else {
        funcPagination.pages = [funcPagination.current - 2, funcPagination.current - 1, funcPagination.current];
        if (funcPagination.countPages >= funcPagination.current + 1) {
          funcPagination.pages.push(funcPagination.current + 1);
        }
        if (funcPagination.countPages >= funcPagination.current + 2) {
          funcPagination.pages.push(funcPagination.current + 2);
        }
      }
    } else {
      funcPagination.pages = [];
      // eslint-disable-next-line
      for (let i = 0; i < funcPagination.countPages; i++) {
        funcPagination.pages.push(i + 1);
      }
    }

    funcPagination.pagesHtml = [];
    if (funcPagination.pages.length > 1) {
      // eslint-disable-next-line
      for (let i = 0; i < funcPagination.pages.length; i++) {
        if (funcPagination.pages[i] === funcPagination.current) {
          funcPagination.pagesHtml.push(<SuiPagination className="pageReload" item active> {funcPagination.pages[i]} </SuiPagination>)
        } else {
          funcPagination.pagesHtml.push(<SuiPagination className="pageReload" item onClick={() => setPage(funcPagination.pages[i])}> {funcPagination.pages[i]} </SuiPagination>)
        }
      }
    }

    return funcPagination;
  }

  function candidateViewed(candidateId) {
    let candidatesViewedListCopy = ReactSession.get("candidatesViewed");
    if (!candidatesViewedListCopy) {
      candidatesViewedListCopy = [];
    }
    if (!candidatesViewedListCopy.includes(Number(candidateId))) {
      candidatesViewedListCopy.push(Number(candidateId));
      ReactSession.set("candidatesViewed", candidatesViewedListCopy);
      setCandidatesViewed(candidatesViewedListCopy);
    }
  }

  function reloadCandidates() {
    setIsFetchingData(true)

    let url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${cId}/teclaGetSimilarCandidates/`
    const headers = { "Content-Type": "application/x-www-form-urlencoded" }
    if (token) headers.Authorization = `Token ${token}`

    const reqFilters = { page: filters.page };
    let skillsFilter = ``;
    let countriesFilter = ``;
    if (filters.keywordsSearch) {
      url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/teclaCandidatesFindInCV/`
      reqFilters.words = $("input[name=searchInCv]").val();
      reqFilters.density = method - 1;
      reqFilters.seniority = seniority - 1;
    } else {
      if (filters.firstName) reqFilters.name = filters.firstName
      if (filters.email) reqFilters.email = filters.email
      if (filters.excludes) reqFilters.excludes = filters.excludes
      if (filters.skills.length) {
        // eslint-disable-next-line
        for (const skill of filters.skills) {
          skillsFilter = `${skillsFilter}&skills=${skill}`
        }
      }
      if (filters.skillsInCV.length) {
        // eslint-disable-next-line
        for (const skill of filters.skillsInCV) {
          skillsFilter = `${skillsFilter}&skillsInCV=${skill}`
        }
      }
      if (filters.countries.length) {
        // eslint-disable-next-line
        for (const country of filters.countries) {
          countriesFilter = `${countriesFilter}&countries=${country}`
        }
      }
      reqFilters.withCV = 0;
      if (filters.withCV) reqFilters.withCV = 1

      reqFilters.withVideo = 0;
      if (filters.withVideo) reqFilters.withVideo = 1

      reqFilters.active = 0;
      if (filters.active) reqFilters.active = 1
    }

    const data = new URLSearchParams(reqFilters);
    fetch(`${url}?${data}${skillsFilter}${countriesFilter}`, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line

        if (res.success && res.data && res.data.candidates) {
          
          const name = `${capitalize(res?.data?.originalCandidate?.first_name)} ${capitalize(res?.data?.originalCandidate?.last_name)}`
          setOriginalCandidateName(name)

          const applicationsDataTemp = { ...applicationsData };
          const applicationsDataSmalltemp = { ...applicationsDataSmall };
          const candidatesArray = [];
          // eslint-disable-next-line
          for (const candidate of res.data.candidates) {
            const fullName = candidate.user ? `${candidate.user.first_name} ${candidate.user.last_name}` : "";
            let referalImg = mockCandidateAvatar
            if (candidate.user.photo) {
              referalImg = `${apiUrl}${candidate.user.photo}`;
            }
            const country = candidate.country ? candidate.country.name : "";
            const candidateSkills = [];
            let count = 0;
            // eslint-disable-next-line
            for (const skill of candidate.skillsToShow) {
              if (count < 5) {
                candidateSkills.push(<SuiBadge badgeContent={skill} color="info" variant="contained" size="lg" />);
              }
              count += 1;
            }
            const candidateUrl = `/r/profile/${candidate.user.id}`;
            let isViewed = false;
            if (candidatesViewed.includes(candidate.user.id)) {
              isViewed = true;
            }

            candidatesArray.push({
              hired: [candidate.user.id, candidate.hired, fullName],
              candidate: <ProductCell image={referalImg} name={fullName} url={candidateUrl} candidateId={candidate.user.id} candidateViewed={candidateViewed} isViewed={isViewed} title={candidate.title} />,
              country: country,
              skills: candidateSkills,
              action: <ActionCell candidate={candidate} />,
              sourced: [candidate.sourced, candidate.sourcingsCount, candidate.user.id, fullName],
              candidateData: candidate,
              referalImg: referalImg,
              fullName: fullName,
              candidateUrl: candidateUrl,
              candidateId: candidate.user.id,
              title: candidate.title,
            });
          }
          
          applicationsDataTemp.rows = candidatesArray;
          applicationsDataSmalltemp.rows = candidatesArray;
          setApplicationsData(applicationsDataTemp);
          setApplicationsDataSmall(applicationsDataSmalltemp);

          // Pagination
          const resPagination = buildPagination({ ...res.data.pagination });
          setPagination(resPagination);
          if (res.data.pagination.count !== totalResults) setTotalResults(res.data.pagination.count)

          setIsFetchingData(false)
          const filtersCopy = { ...filters };
          filtersCopy.keywordsSearch = false;
          setFilters(filtersCopy);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => { 
    const applicationsDataCopy = {...applicationsData};
    if (applicationsDataCopy && applicationsDataCopy.rows && applicationsDataCopy.rows.length) {
      let candidatesViewedListCopy = ReactSession.get("candidatesViewed");
      if (!candidatesViewedListCopy) {
        candidatesViewedListCopy = [];
      }
      const newApplicationsDataRows = [];
      // eslint-disable-next-line
      for (const candidatesRow of applicationsDataCopy.rows) {
        const isViewed = candidatesViewedListCopy.includes(Number(candidatesRow.candidateId));
        const candidate = candidatesRow.candidateData;
        newApplicationsDataRows.push({
          hired: [candidate.user.id, candidate.hired, candidatesRow.fullName],
          candidate: <ProductCell image={candidatesRow.referalImg} name={candidatesRow.fullName} url={candidatesRow.candidateUrl} candidateId={candidatesRow.candidateId} candidateViewed={candidateViewed} isViewed={isViewed} title={candidatesRow.title} />,
          country: candidatesRow.country,
          skills: candidatesRow.skills,
          action: <ActionCell candidate={candidatesRow.candidateData} />,
          sourced: [candidate.sourced, candidate.sourcingsCount, candidate.user.id, candidatesRow.fullName],
          candidateData: candidatesRow.candidateData,
          referalImg: candidatesRow.referalImg,
          fullName: candidatesRow.fullName,
          candidateUrl: candidatesRow.candidateUrl,
          candidateId: candidatesRow.candidateId,
          title: candidatesRow.title,
        });
      }
      applicationsDataCopy.rows = newApplicationsDataRows;
      setApplicationsData(applicationsDataCopy);
      setApplicationsDataSmall(applicationsDataCopy);
    }
  }, [candidatesViewed]);

  useEffect(() => { reloadCandidates() }, [search]);

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateSkills = async () => {
    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = [];
    // eslint-disable-next-line
    for (let i = 0; i < interests?.data?.skills?.length; i++) {
      newData.push({ value: interests?.data?.skills[i]?.id, label: interests?.data?.skills[i]?.name });
    }
    setSkills(newData);
  }

  useEffect(() => {
    populateSkills()

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/jobCountries/`)
      .then(async response => {
        const data = await response.json();
        const newData = [];
        // const countryTags = [];
        // eslint-disable-next-line
        Object.keys(data.data.countries).forEach(function (key) {
          newData.push({ value: data.data.countries[key].id, label: data.data.countries[key].name });
          // countryTags.push(data.data.countries[key].name);
        });
        $(".countryBox").attr("data-countries", JSON.stringify(newData));
        setCountries(newData);
        // if (setTags) {
        //   setSkillLevels(countryTags);
        // }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  const fetchFolders = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/getFoldersName/`, {
      method: 'GET',
      headers: { "Authorization": `Token ${token}` }
    })
    .then(async response => {
      const data = await response.json()
      if (data.success && (!recruiterFolders || !Array.isArray(recruiterFolders))) ReactSession.set("recruiterFolders", data.data)
    })
    .catch(error => console.error('There was an error!', error))
  }

  const fetchClientFolders = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/getFolderClients/`, {
      method: 'GET',
      headers: { "Authorization": `Token ${token}` }
    })
      .then(async response => {
        const data = await response.json()
        if (data.success) ReactSession.set("clientFolders", data.data)
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => {
    fetchFolders()
    if (recruiter?.canManageClientFolders) fetchClientFolders()
  }, [])

  return (
    <RecruiterDashboardLayout>
      <DashboardNavbar title='Talent' />
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Listas
            applicationsData={applicationsData}
            applicationsDataSmall={applicationsDataSmall}
            pagination={pagination}
            setPage={setPage}
            filters={filters}
            totalResults={totalResults}
            isFetchingData={isFetchingData}
            originalCandidateName={originalCandidateName}
          />
        </Grid>
      </Grid>
    </RecruiterDashboardLayout>
  );
}

export default SimilarCandidates;