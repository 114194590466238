/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import { useParams } from 'react-router-dom';
// React Player
import ReactPlayer from "react-player";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import useMediaQuery from "@mui/material/useMediaQuery"
// Icons
import GitlabIcon from "assets/images/socialIcons/Gitlab.png";
import PortfolioIcon from "assets/images/socialIcons/Portfolio.png";
import stackOverflowIcon from "assets/images/socialIcons/StackO.png";
import WebsiteIcon from "assets/images/socialIcons/Website.png";
import leverIcon from "assets/images/LeverIcon.png"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAvatar from "components/SuiAvatar";
import SuiInput from "components/SuiInput";
import SuiAlert from "components/SuiAlert";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfileSetInfoCard from "examples/Cards/InfoCards/ProfileSetInfoCard";
import SkillsetList from "examples/Lists/SkillsetList";
import SkillsList from "examples/Lists/SkillsList";
import SkillsUpdateList from "examples/Lists/SkillsUpdateList";
import InterestList from "examples/Lists/InterestList";
import Appications from "examples/Lists/Appications";
// Overview page components
import SourcingsList from "layouts/pages/profile/profile-overview/sourcingsList/sourcingsList";
import Header from "layouts/pages/profile/components/Header";
import emptyCVImg from "assets/images/illustrations/recommended.png";
import placeholder from "assets/images/placeholder.jpg";
// PDF
import PdfViewer from "layouts/pdfViewer/PdfViewer";
import AddToRecruiterSourcingModal from "layouts/pages/profile/profile-overview/addToRecruiterSourcingModal/addToRecruiterSourcingModal";
import AddToRecruiterFolderModal from "layouts/pages/profile/profile-overview//addToRecruiterFolderModal/addToRecruiterFolderModal";
import SoftBan from "layouts/pages/profile/profile-overview/softBan";

import mockCompanyAvatar from "assets/images/mockCompanyAvatar.png";


// DESCOMENTAME
// import ApplicationDetails from "examples/Lists/ApplicationList";
// DESCOMENTAME

function Overview() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const user = ReactSession.get("user");
  const recruiter = ReactSession.get("recruiter");

  const isRecruiter = (user && (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin"));

  const [candidate, setCandidate] = useState({});
  const [recruiters, setRecruiters] = useState([]);
  const [notes, setNotes] = useState([]);
  const [updateNotes, setUpdateNotes] = useState(0);
  const [activities, setActivities] = useState([]);

  const [isLoadingData, setIsLoadingData] = useState(false);

  const { userId } = useParams();

  function createData(id, name, date, message, recruitersText, recruiterAvatar, authorId, recruitersData) {
    if (recruiterAvatar) return { id, name, date, message, recruitersText, recruiterAvatar: `${process.env.REACT_APP_API_ROUTE}${recruiterAvatar}`, authorId, recruitersData }
    return { id, name, date, message, recruitersText, mockCompanyAvatar, authorId, recruitersData };
  }

  function getRecruiters() {
    const headers = { "Content-Type": "application/json" };
    const recruiterUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/getRecruiters/`;
    headers.Authorization = `Token ${token}`;
    fetch(recruiterUrl, { headers, })
      .then(async response => {
        const data = await response.json();
        if (data.success) {
          const users = [];
          // eslint-disable-next-line
          for (const user of data.data) {
            users.push({
              id: user.id,
              display: user.uname,
              uname: `@${user.uname}`,
              checked: false
            });
          }

          setRecruiters(users);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => {
    if (userId) {
      const headers = { "Content-Type": "application/json" };
      const recruiterUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${userId}/getCandidateActivity/`;
      headers.Authorization = `Token ${token}`;
      fetch(recruiterUrl, { headers, })
        .then(async response => {
          const data = await response.json();
          if (data.success) {
            setActivities(data.data);
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, []);

  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);

  function getStringDate(date) {
    return `${
      padL(date.getMonth()+1)}/${
      padL(date.getDate())}/${
      date.getFullYear()} - ${
      padL(date.getHours())}:${
      padL(date.getMinutes())
    }`
  }

  function getNotes(candidateId) {
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const url = `${process.env.REACT_APP_API_ROUTE}/api/notes/getNotes/?candidate=${Number(candidateId)}`;
    headers.Authorization = `Token ${token}`;
    fetch(`${url}`, { headers, })
      .then(async response => {
        const data = await response.json();

        if (data.success) {
          setNotes([])
          const tempDefaultNotes = [];
          let noteDate = "";
          let recruitersText = "";
          if (data.data && data.data.length) {
            // eslint-disable-next-line
            for (const note of data.data) {
              recruitersText = "";
              // eslint-disable-next-line
              for (const recruiter of note.recruiters) {
                recruitersText = `${recruitersText}${recruiter.user.first_name} ${recruiter.user.last_name}, `
              }
              if (recruitersText.length) {
                recruitersText = recruitersText.substring(0, recruitersText.length - 2);
              }

              noteDate = getStringDate(new Date(note.createdAt));

              tempDefaultNotes.push(
                createData(
                  note?.id,
                  `${note?.author?.first_name} ${note?.author?.last_name}`,
                  noteDate,
                  note?.content,
                  recruitersText,
                  note?.author?.photo,
                  note?.author?.id,
                  note?.recruiters
                )
              );
            }
          }
          else if (data.data && !data.data.length) setNotes([])

          $(".moonLoaderBox").removeClass("active");

          if (tempDefaultNotes.length) {
            setNotes(tempDefaultNotes);
            const searchParams = new URLSearchParams(window.location.search);
            const urlNotes = searchParams.get('notes');
            if (urlNotes && Number(urlNotes) === 1) {
              let timerid = "";
              if (timerid) {
                clearTimeout(timerid);
              }
              timerid = setTimeout(() => {
                $("button:contains('Notes')").click();
              }, 500);
            }
          }
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => {
    if (userId || token) {
      setIsLoadingData(true)

      let url = "";
      const headers = { "Content-Type": "application/json" };

      if (isRecruiter && userId) {
        url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${userId}/teclaGetCandidate/`;
        headers.Authorization = `Token ${token}`;
      }
      else if (userId && token) {
        url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${userId}/getCandidate/`;
        headers.Authorization = `Token ${token}`;
      }
      else if (token) {
        url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/candidate/`;
        headers.Authorization = `Token ${token}`;
      }

      fetch(url, { headers, })
        .then(async response => {
          const data = await response.json();
          if (data.success) {
            const newCandidate = { ...data.data };

            if (userId) { newCandidate.isParameter = true }
            setCandidate(newCandidate);

            ReactSession.set("CandidateForEdit", newCandidate)
            setIsLoadingData(false)

            const cfirstName = newCandidate.user ? newCandidate.user.first_name : "";
            const clastName = newCandidate.user ? newCandidate.user.last_name : "";
            document.title = `Profile - ${cfirstName} ${clastName} | TECLA`;
          } else {
            window.location.replace("/");
          }

        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    } else {
      setCandidate(ReactSession.get("candidate"));
    }

    if (userId) {
      getRecruiters();
    }

    let candidatesViewedListCopy = ReactSession.get("candidatesViewed");
    if (!candidatesViewedListCopy) {
      candidatesViewedListCopy = [];
    }
    if (!candidatesViewedListCopy.includes(Number(userId))) {
      candidatesViewedListCopy.push(Number(userId));
      ReactSession.set("candidatesViewed", candidatesViewedListCopy);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      getNotes(userId);
    }
  }, [updateNotes]);

  const firstName = candidate.user ? candidate.user.first_name : "";
  const lastName = candidate.user ? candidate.user.last_name : "";
  const country = candidate.country ? candidate.country.name : "";
  let dateOfBirth = candidate.dateOfBirth ? candidate.dateOfBirth : "";
  if (dateOfBirth) {
    dateOfBirth = new Date(`${dateOfBirth}T00:00:00`);
    let month = dateOfBirth.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = dateOfBirth.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    dateOfBirth = `${month}/${day}`;
  }

  const profileUser = {
    fullName: `${firstName} ${lastName}`,
    location: country,
    birthday: dateOfBirth,
    mobile: candidate.phone,
    email: candidate?.user?.email
  }

  const githubLink = candidate.github ? candidate.github : false;
  const gitLabLink = candidate.gitLab ? candidate.gitLab : false;
  const websiteLink = candidate.website ? candidate.website : false;
  const stackOverflowLink = candidate.stackOverflow ? candidate.stackOverflow : false;
  const portfolioLink = candidate.portfolio ? candidate.portfolio : false;
  const facebookLink = candidate.facebook ? candidate.facebook : false;
  const twitterLink = candidate.twitter ? candidate.twitter : false;
  const linkedinLink = candidate.linkedin ? candidate.linkedin : false;
  const instagramLink = candidate.instagram ? candidate.instagram : false;
  const videoLink = candidate.videoUrl ? candidate.videoUrl : false;
  const leverLink = candidate.leverUrl ? candidate.leverUrl : false;

  let cv = "";
  if (candidate.cv) {
    cv = `${process.env.REACT_APP_API_ROUTE}${candidate.cv}`;
  }

  const englishsetListData = [];
  let comment = "Not able to speak English at all";
  if (candidate.englishLevel) {
    englishsetListData.push({
      image: placeholder,
      name: "Spoken english",
      percent: `${candidate.englishLevel}0%`,
      color: "info",
      value: candidate.englishLevel * 10,
      englishValidator: candidate.englishValidator,
    });
    if (candidate.englishLevel === 10) {
      comment = "English is like my first language";
    } else if (candidate.englishLevel === 9) {
      comment = "Excellent English; no issues at all";
    } else if (candidate.englishLevel === 8) {
      comment = "I can hold a conversation easily; minor mistakes";
    } else if (candidate.englishLevel === 7) {
      comment = "I can hold a conversation with a few mistakes";
    } else if (candidate.englishLevel === 6) {
      comment = "I can speak but with lots of mistakes";
    } else if (candidate.englishLevel === 5) {
      comment = "Currently learning; not able to speak yet";
    }
  }

  function returAsYears(years) {
    if (years >= 10) {
      return `${years}+ Years`
    }

    if (years >= 2) {
      return `${years} Years`
    }

    if (years === 0) {
      return ""
    }

    return `${years} Year`;
  }

  function getSkillValue(skillValue) {
    if (skillValue >= 5) {
      return 100;
    }

    return skillValue * 20;
  }

  const skillsetListData = [];
  if (candidate.skills) {
    // eslint-disable-next-line
    for (let i = 0; i < candidate.skills.length; i++) {
      skillsetListData.push({
        image: placeholder,
        name: candidate.skills[i].skill.name,
        percent: returAsYears(candidate.skills[i].level),
        color: "info",
        value: getSkillValue(candidate.skills[i].level)
      });
    }
  }

  const interestListData = [];
  if (candidate.industries) {
    // eslint-disable-next-line
    for (let i = 0; i < candidate.industries.length; i++) {
      interestListData.push({
        image: placeholder,
        name: candidate.industries[i].name,
      });
    }
  }

  const appsListData = [];
  if (candidate.applications) {
    let appName = "";
    let job = {};

    // eslint-disable-next-line
    for (let i = 0; i < candidate.applications.length; i++) {
      job = candidate.applications[i].job;
      appName = `${job.company.name} - ${job.seniorityName}`;
      appsListData.push({
        name: appName,
        status: candidate.applications[i].status,
        declinedReason: candidate.applications[i].declinedReason,
        appId: candidate.applications[i].id,
        date: candidate.applications[i].createdAt
      });
    }
  }

  // DESCOMENTAME
  // const appDetails = [
  //   {
  //     id: 1, salary: "3500 to 4000", availability: "As soon as possible",
  //     question1: "is this a question?is this a question?", answer1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five cent",
  //     question2: "is this a question?is this a question?", answer2: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five cent",
  //     question3: "is this a question?is this a question?", answer3: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five cent",
  //   },
  // ]
  // DESCOMENTAME

  const candidateLinks = [];
  if (githubLink) {
    candidateLinks.push({
      link: githubLink,
      icon: <GitHubIcon />,
      color: "github",
    });
  }
  if (gitLabLink) {
    candidateLinks.push({
      link: gitLabLink,
      icon: <img src={GitlabIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "pinterest",
    });
  }
  if (websiteLink) {
    candidateLinks.push({
      link: websiteLink,
      icon: <img src={WebsiteIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "vimeo",
    });
  }
  if (stackOverflowLink) {
    candidateLinks.push({
      link: stackOverflowLink,
      icon: <img src={stackOverflowIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "dribbble",
    });
  }
  if (portfolioLink) {
    candidateLinks.push({
      link: portfolioLink,
      icon: <img src={PortfolioIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "youtube",
    });
  }
  if (leverLink && (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin")) {
    candidateLinks.push({
      link: leverLink,
      icon: <SuiAvatar src={leverIcon} alt="Lever icon" size="xs" sx={{ height: 18 }} />,
      color: "twitter",
    });
  }

  const candidateSocials = [];
  if (facebookLink) {
    candidateSocials.push({
      link: facebookLink,
      icon: <FacebookIcon />,
      color: "facebook",
    });
  }
  if (twitterLink) {
    candidateSocials.push({
      link: twitterLink,
      icon: <TwitterIcon />,
      color: "twitter",
    });
  }
  if (instagramLink) {
    candidateSocials.push({
      link: instagramLink,
      icon: <InstagramIcon />,
      color: "instagram",
    });
  }
  if (linkedinLink) {
    candidateSocials.push({
      link: linkedinLink,
      icon: <LinkedInIcon />,
      color: "linkedin",
    });
  }

  const fetchFolders = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/getFoldersName/`, {
      method: 'GET',
      headers: { "Authorization": `Token ${token}` }
    })
      .then(async response => {
        const data = await response.json()
        if (data.success) ReactSession.set("recruiterFolders", data.data)
      })
      .catch(error => console.error('There was an error!', error))
  }

  const fetchClientFolders = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/getFolderClients/`, {
      method: 'GET',
      headers: { "Authorization": `Token ${token}` }
    })
      .then(async response => {
        const data = await response.json()
        if (data.success) ReactSession.set("clientFolders", data.data)
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => {
    if (isRecruiter) {
      fetchFolders()
      if (recruiter?.canManageClientFolders) fetchClientFolders()
    }
  }, []);

  const [showAddToRecruiterFolderModal, setshowAddToRecruiterFolderModal] = useState(false)

  const [showSoftBan, setShowSoftBan] = useState(false)

  const [showAddToRecruiterSourcing, setshowAddToRecruiterSourcing] = useState(false)

  const [isFetchingForms, setIsFetchingForms] = useState(false)

  // We use this function to refetch candidate info in RecruiterFormsModal
  // It refreshes candidate information once a new form has been submited
  const reFetchCandidateForms = () => {
    setIsFetchingForms(true)

    if (isRecruiter && userId) {
      const headers = { "Content-Type": "application/json" };
      const url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${userId}/teclaGetCandidate/`;
      headers.Authorization = `Token ${token}`;

      fetch(url, { headers, })
        .then(async response => {
          const data = await response.json();

          if (data.success) {
            const newCandidate = { ...data.data };

            if (userId) { newCandidate.isParameter = true }
            setCandidate(newCandidate);

            ReactSession.set("CandidateForEdit", newCandidate)

            setIsFetchingForms(false)
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }

  function uploadResume() {
    if (user) {
      const resumeField = $("input[name=cv]").prop('files')[0];
      let validCv = true;
      if (resumeField && resumeField.name) {
        const extension = resumeField.name.substring(resumeField.name.length - 4, resumeField.name.length).toLowerCase();
        if (!extension.includes("pdf")) {
          validCv = false;
        }
      }

      if (validCv) {
        const formPostData = new FormData();
        // eslint-disable-next-line no-useless-concat
        const postToken = `Token ${token}`;

        formPostData.append('cv', resumeField);

        let recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/uploadCVCandidate/`;
        const candidateId = candidate.id ? candidate.id : userId;
        if (isRecruiter && candidateId) {
          recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateId}/teclaUploadCVCandidate/`;
        }
        const normalizedName = resumeField.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        const requestMetadata = {
          method: 'PATCH',
          headers: {
            "Content-Disposition": `attachment; filename=${normalizedName}`,
            "Authorization": postToken,
            "content-type": "multipart/form-data;"
          },
          body: formPostData
        };

        fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
            if (response.success && response.data && response.data.cv) {
              const sessionCandidate = { ...candidate };
              sessionCandidate.cv = response.data.cv;
              if (!isRecruiter) {
                ReactSession.set("candidate", sessionCandidate);
              }
              setCandidate(sessionCandidate);
            }
          });
      } else {
        $("input[name=cv]").val("");
        let timerRedirectid = "";
        $(".alertErrorBox").show();
        if (timerRedirectid) {
          clearTimeout(timerRedirectid);
        }
        timerRedirectid = setTimeout(() => {
          $(".alertErrorBox").hide();
        }, 3000);
      }
    }
  }

  function openLoadResume() {
    $("[name=cv]").click();
  }

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <>
      {isLoadingData ?
        <SuiBox display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress size={40} />
        </SuiBox>
      :
        <>
          {isRecruiter ?
            <RecruiterDashboardLayout>
              <Header
                candidate={candidate}
                canNote
                canForms
                reFetchCandidateForms={reFetchCandidateForms}
                isFetchingForms={isFetchingForms}
                canSource
                isCandidate
                token={token}
                setshowAddToRecruiterFolderModal={setshowAddToRecruiterFolderModal}
                setShowSoftBan={setShowSoftBan}
                setshowAddToRecruiterSourcing={setshowAddToRecruiterSourcing}
                canEdit={user.type === "tecla_manager" || user.type === "tecla_admin"}
                magicLink={user.type === "tecla_manager" || user.type === "tecla_admin"}
                canCreateApplication={user.type === "tecla_manager" || user.type === "tecla_admin"}
                recruiters={recruiters}
                setRecruiters={setRecruiters}
                notes={notes}
                updateNotes={updateNotes}
                setUpdateNotes={setUpdateNotes}
                activities={activities}
              />

              {showAddToRecruiterFolderModal && <AddToRecruiterFolderModal showAddToRecruiterFolderModal={showAddToRecruiterFolderModal} setshowAddToRecruiterFolderModal={setshowAddToRecruiterFolderModal} />}
              
              {showSoftBan && <SoftBan showSoftBan={showSoftBan} setShowSoftBan={setShowSoftBan} />}

              {showAddToRecruiterSourcing && <AddToRecruiterSourcingModal showAddToRecruiterSourcing={showAddToRecruiterSourcing} setshowAddToRecruiterSourcing={setshowAddToRecruiterSourcing} />}

              <SuiBox display="flex" mt={5} mb={3}>
                <Grid container spacing={3}>
                  {/* LEFT */}
                  <Grid item xs={12} lg={4}>

                    {videoLink ? (
                      <Grid item xs={12} mb={3}>
                        <Card>
                          <SuiBox pt={2} px={2}>
                            <SuiBox mb={0.5}>
                              <SuiTypography variant="h6" fontWeight="medium">
                                Intro Video
                              </SuiTypography>
                            </SuiBox>
                            <SuiBox mb={2}>
                              <ReactPlayer url={videoLink} width="100%" />
                            </SuiBox>
                          </SuiBox>
                        </Card>
                      </Grid>
                    ) : ("")}
                
                    <Grid item xs={12}>
                      <SuiBox mb={3}>
                        <ProfileSetInfoCard
                          title="profile"
                          description={candidate.bio}
                          info={profileUser}
                          links={candidateLinks}
                          social={candidateSocials}
                          action={{ route: "", tooltip: "Edit Profile" }}
                          isActiveUser={candidate?.user?.inUse}
                          candidate={candidate}
                          setCandidate={setCandidate}
                        />
                      </SuiBox>
                    </Grid>

                    <Grid item xs={12}>
                      <SuiBox mb={3}>
                        <SkillsList title="Skills" profiles={skillsetListData} />
                      </SuiBox>
                    </Grid>
                    
                    {englishsetListData.length ? (
                      <Grid item xs={12}>
                        <SuiBox mb={3}>
                          <SkillsUpdateList title="English level" candidate={candidate} setCandidate={setCandidate} profiles={englishsetListData} comment={comment} />
                        </SuiBox>
                      </Grid>
                    ) : (null)}

                    {interestListData.length ? (
                      <Grid item xs={12}>
                        <SuiBox mb={2}>
                          <InterestList title="Interests" profiles={interestListData} />
                        </SuiBox>
                      </Grid>
                    ) : (null)}

                  </Grid>
                  {/* RIGHT */}
                  <Grid item xs={12} lg={8}>
                    <Card>
                      <Grid item xs={15} lg={15}>
                        {candidate.user && <Card sx={{ overflowY: "auto", backgroundColor: cv ? '#525758' : '#fff' }}>
                          {cv ?
                            <PdfViewer cvUrl={cv} />
                            :
                            <SuiBox sx={{ minHeight: 400, py: 4 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                              <SuiBox className="alertErrorBox" display="none" sx={{ width: "80%" }}>
                                <SuiAlert color="error" className="alertError">Please upload a valid CV.</SuiAlert>
                              </SuiBox>
                              <SuiTypography variant="h6" fontWeight="medium">The candidate hasn&apos;t uploaded a CV file</SuiTypography>
                              
                              <img src={emptyCVImg} alt="Couple of documents" width={isMobile ? "100%" : "35%"} />

                              <SuiButton
                                sx={{ backgroundColor: "#feec1e" }}
                                size="large"
                                onClick={() => openLoadResume()}
                                >
                                <SuiTypography color="dark" fontWeight="bold">Add CV</SuiTypography>
                              </SuiButton>
                              <SuiInput name="cv" type="file" inputProps={{accept: "application/pdf"}} label="Resume" placeholder="Resume" onChange={uploadResume} sx={{ display: "none !important" }} />
                            </SuiBox>
                          }
                        </Card>}

                        {cv && (
                          <SuiBox mx="auto" my={3} display="flex" justifyContent="center" alignItems="center">
                            <SuiButton color="info" variant="gradient" href={cv} target="_blank">Download</SuiButton>
                          </SuiBox>
                        )}
                      </Grid>
                    </Card>

                    {appsListData.length ? (
                      <Grid item xs={12} mt={3}>
                        <SuiBox mb={2}>
                          <Appications title="Applications" apps={appsListData} />
                        </SuiBox>
                      </Grid>
                    ) : (null)}

                    {candidate?.sourcing?.length > 0 && (
                      <Grid item xs={12} mt={3}>
                        <SuiBox mb={2}>
                          <SourcingsList candidate={candidate} sourcings={candidate?.sourcing} />
                        </SuiBox>
                      </Grid>
                    )}
                  </Grid>

                </Grid>
              </SuiBox>
            </RecruiterDashboardLayout>
            :
            <DashboardLayout>
              {/* <DashboardNavbar /> */}
              <Header candidate={candidate} canEdit canShare />
              <SuiBox display="flex" mt={5} mb={3}>
                <Grid container spacing={3}>
                  {/* LEFT */}
                  <Grid item xs={12} lg={4}>

                    {videoLink ? (
                      <Grid item xs={12} mb={3}>
                        <Card>
                          <SuiBox pt={2} px={2}>
                            <SuiBox mb={0.5}>
                              <SuiTypography variant="h6" fontWeight="medium">
                                Intro Video
                              </SuiTypography>
                            </SuiBox>
                            <SuiBox mb={2}>
                              <ReactPlayer url={videoLink} width="100%" />
                            </SuiBox>
                          </SuiBox>
                        </Card>
                      </Grid>
                    ) : ("")}

                    <Grid item xs={12}>
                      <SuiBox mb={3}>
                        <ProfileInfoCard
                          title="profile"
                          description={candidate.bio}
                          info={profileUser}
                          links={candidateLinks}
                          social={candidateSocials}
                          action={{ route: "", tooltip: "Edit Profile" }}
                        />
                      </SuiBox>
                    </Grid>

                    {skillsetListData.length ? (
                      <Grid item xs={12}>
                        <SuiBox mb={3}>
                          <SkillsetList title="Skills" profiles={skillsetListData} />
                        </SuiBox>
                      </Grid>
                    ) : (null)}

                    {englishsetListData.length ? (
                      <Grid item xs={12}>
                        <SuiBox mb={3}>
                          <SkillsetList title="English level" profiles={englishsetListData} comment={comment} />
                        </SuiBox>
                      </Grid>
                    ) : (null)}

                    {interestListData.length ? (
                      <Grid item xs={12}>
                        <SuiBox mb={2}>
                          <InterestList title="Interests" profiles={interestListData} />
                        </SuiBox>
                      </Grid>
                    ) : (null)}

                  </Grid>
                  {/* RIGHT */}
                  <Grid item xs={12} lg={8}>
                    <Card>
                      <Grid item xs={15} lg={15}>
                        {candidate.user && <Card sx={{ overflowY: "auto", backgroundColor: cv ? '#525758' : '#fff' }}>
                          {cv ?
                            <PdfViewer cvUrl={cv} />
                            :
                            <SuiBox sx={{ minHeight: 400, py: 4 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                              <SuiBox className="alertErrorBox" display="none" sx={{ width: "80%" }}>
                                <SuiAlert color="error" className="alertError">Please upload a valid CV.</SuiAlert>
                              </SuiBox>
                              <SuiTypography variant="h6" fontWeight="medium">Please add a CV file to display it here</SuiTypography>
                              
                              <img src={emptyCVImg} alt="Couple of documents" width={isMobile ? "100%" : "35%"} />
                              
                              <SuiButton
                                sx={{ backgroundColor: "#feec1e" }}
                                size="large"
                                onClick={() => openLoadResume()}
                                >
                                <SuiTypography color="dark" fontWeight="bold">Add CV</SuiTypography>
                              </SuiButton>
                              <SuiInput name="cv" type="file" inputProps={{accept: "application/pdf"}} label="Resume" placeholder="Resume" onChange={uploadResume} sx={{ display: "none !important" }} />

                            </SuiBox>
                          }
                        </Card>}

                        {cv && (
                          <SuiBox mx="auto" my={3} display="flex" justifyContent="center" alignItems="center">
                            <SuiButton color="info" variant="gradient" href={cv} target="_blank">Download</SuiButton>
                          </SuiBox>
                        )}
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </SuiBox>
            </DashboardLayout>
          }
        </>
      }
    </>
  );
}

export default Overview;
