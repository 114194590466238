/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types"
import { useState } from "react"
import { ReactSession } from 'react-client-session'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
// MUI components
import Checkbox from '@mui/material/Checkbox'
import MarkHiredModal from "layouts/pages/profile/components/Header/markHiredModal/MarkHiredModal"

function HiredMarkCell({ candidateId, isHired, candidateName }) {

	ReactSession.setStoreType("localStorage")
	const user = ReactSession.get("user")

	const [showMarkHiredModal, setShowMarkHiredModal] = useState(false)

	// We use this to avoid calling API everytime the checkbox is clicked
	// If API response is successful, we set frontHiredMark to the opposite value and show that
	const [hiredMarkHasBeenChanged, setHiredMarkHasBeenChanged] = useState(false)
	const [frontHiredMark, setFrontHiredMark] = useState(isHired)

	return (
		<SuiBox display="flex" alignItems="center" >

			<SuiBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
					<SuiButton variant="text" style={{ color: "#30385c" }} disabled={user.type === "tecla_recruiter"} onClick={() => setShowMarkHiredModal(true)} >
						<Checkbox style={{ marginLeft: "5px", border: "#30385c solid 1px" }} checked={hiredMarkHasBeenChanged ? frontHiredMark : isHired} />
					</SuiButton>

					{showMarkHiredModal && (
						<MarkHiredModal
							showMarkHiredModal={showMarkHiredModal}
							setShowMarkHiredModal={setShowMarkHiredModal}
							candidateId={candidateId}
							isHired={isHired} candidateName={candidateName}
							hiredMarkHasBeenChanged={hiredMarkHasBeenChanged}
							setHiredMarkHasBeenChanged={setHiredMarkHasBeenChanged}
							frontHiredMark={frontHiredMark}
							setFrontHiredMark={setFrontHiredMark}
						/>
					)}
			</SuiBox>
		</SuiBox>
	)
}

HiredMarkCell.propTypes = {
	candidateId: PropTypes.number.isRequired,
	isHired: PropTypes.bool.isRequired,
	candidateName: PropTypes.string.isRequired
}

export default HiredMarkCell
