/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Slider from '@mui/material/Slider';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";


function SkillSlider({ name, percent, color, skillsListToShow, setSkillsListToshow, skillsListToPost, setSkillsListToPost, returAsYears, setSkillUpdated }) {

    const handleChange = newVal => {
        const newListToPost = [...skillsListToPost]
        const skillToPost = newListToPost?.filter(skl => skl?.skill?.name === name)[0]
        skillToPost.level = newVal
        setSkillsListToPost(newListToPost)

        const newListToShow = [...skillsListToShow]
        const skillToShow = newListToShow?.filter(skl => skl?.name === name)[0]
        skillToShow.percent = returAsYears(newVal)
        setSkillsListToshow(newListToShow)

        if (setSkillUpdated) {
            setSkillUpdated(true)
        }
    }

    return (
        <SuiBox key={name} component="li" display="flex" alignItems="center">
            <SuiBox
                component="ul"
                display="flex"
                flexDirection="column"
                width="100%"
                sx={{ listStyle: "none" }}
            >
                <SuiBox component="li" w="100%" py={0.25}>
                    <SuiBox display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
                        <SuiBox>
                            <SuiBadge badgeContent={name} color={color} variant="contained" circular size="sm" container />
                        </SuiBox>

                        <SuiTypography
                            variant="button"
                            fontWeight="medium"
                            color="text"
                            textTransform="capitalize"
                        >
                            {percent}
                        </SuiTypography>
                    </SuiBox>

                    <Slider
                        defaultValue={percent.substring(0,2).replaceAll(" ", "")}
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={10}
                        onChange={e => handleChange(e.target.value)}
                    />
                </SuiBox>
            </SuiBox>
        </SuiBox>
    );
}

// Typechecking props for the SkillSlider
SkillSlider.propTypes = {
    name: PropTypes.string.isRequired,
    percent: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    skillsListToShow: PropTypes.array.isRequired, // eslint-disable-line
    setSkillsListToshow: PropTypes.func.isRequired,
    skillsListToPost: PropTypes.array.isRequired, // eslint-disable-line
    setSkillsListToPost: PropTypes.func.isRequired,
    returAsYears: PropTypes.func.isRequired,
    setSkillUpdated: PropTypes.func.isRequired, // eslint-disable-line
};

export default SkillSlider;
