/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken, formatDate } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiPagination from "components/SuiPagination";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from '@mui/material/Badge';
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React example components
import breakpoints from "assets/theme/base/breakpoints";
import CompanyDashboardLayout from "examples/LayoutContainers/CompanyDashboardLayout"
import InvoicesTable from "layouts/pages/clientInvoices/table/invoicesTable";
// Data
import pendingApprovalTableData from "layouts/pages/clientInvoices/data/pendingApprovalTableData";
import approvedTableData from "layouts/pages/clientInvoices/data/approvedTableData";
import inProgressTableData from "layouts/pages/clientInvoices/data/inProgressTableData";
import declinedTableData from "layouts/pages/clientInvoices/data/declinedTableData";
import paidTableData from "layouts/pages/clientInvoices/data/paidTableData";
// In Review - Active - Intro requested - Interview - Hired - Declined - withdrew
import pendingApprovalTableDataSmall from "layouts/pages/clientInvoices/data/pendingApprovalTableDataSmall";
import approvedTableDataSmall from "layouts/pages/clientInvoices/data/approvedTableDataSmall";
import inProgressTableDataSmall from "layouts/pages/clientInvoices/data/inProgressTableDataSmall";
import declinedTableDataSmall from "layouts/pages/clientInvoices/data/declinedTableDataSmall";
import paidTableDataSmall from "layouts/pages/clientInvoices/data/paidTableDataSmall";

function MamagerInvoices() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");

  const [pagination, setPagination] = useState({ pages: [] })
  const [totalResults, setTotalResults] = useState(null)

  const [isFetchingData, setIsFetchingData] = useState(false)
  const [updateInvoices, setUpdateInvoices] = useState(0)

  const formatStatus = code => {
    switch (code) {
      case "pending-approval":
        return "Pending Approval"
      case "approved-by-tecla":
        return "Approved by TECLA"
      case "approved-by-client":
        return "Approved by Client"
      case "in-progress":
        return "In Progress"
      case "declined-by-tecla":
        return "Offer declined"
      case "declined-by-client":
        return "Offer declined"
      case "paid":
        return "Paid"
      default:
        return ""
    }
  }

  const InvoiceStatesTables = {
    "pendingApproval": pendingApprovalTableData,
    "approved": approvedTableData,
    "inProgress": inProgressTableData,
    "declined": declinedTableData,
    "paid": paidTableData
  }

  const [invoiceStates, setInvoiceStates] = useState(InvoiceStatesTables)

  const smallInvoiceStatesTables = {
    "pendingApproval": pendingApprovalTableDataSmall,
    "approved": approvedTableDataSmall,
    "inProgress": inProgressTableDataSmall,
    "declined": declinedTableDataSmall,
    "paid": paidTableDataSmall
  }

  const [smallInvoiceStates, setSmallInvoiceStates] = useState(smallInvoiceStatesTables)

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const fetchInvoices = (page = 1) => {
    setIsFetchingData(true)

    function buildPagination(paginationData) {
      const funcPagination = {...paginationData};
      funcPagination.hasPrev = true;
      funcPagination.hasNext = true;
      if (funcPagination.current === 1 || funcPagination.countPages === 1) {
        funcPagination.hasPrev = false;
      }
  
      if (funcPagination.current === funcPagination.countPages) {
        funcPagination.hasNext = false;
      }
  
      if (funcPagination.countPages >= 5) {
        if (funcPagination.current === 1 || funcPagination.current === 2) {
          funcPagination.pages = [1, 2, 3, 4, 5];
        } else {
          funcPagination.pages = [funcPagination.current - 2, funcPagination.current - 1, funcPagination.current];
          if (funcPagination.countPages >= funcPagination.current + 1) {
            funcPagination.pages.push(funcPagination.current + 1);
          }
          if (funcPagination.countPages >= funcPagination.current + 2) {
            funcPagination.pages.push(funcPagination.current + 2);
          }
        }
      } else {
        funcPagination.pages = [];
        // eslint-disable-next-line
        for (let i = 0; i < funcPagination.countPages; i++) {
          funcPagination.pages.push(i + 1);
        }
      }
  
      funcPagination.pagesHtml = [];
      if (funcPagination.pages.length > 1) {
        // eslint-disable-next-line
        for (let i = 0; i < funcPagination.pages.length; i++) {
          if (funcPagination.pages[i] === funcPagination.current) {
            funcPagination.pagesHtml.push(<SuiPagination className="pageReload" item active> {funcPagination.pages[i]} </SuiPagination>)
          } else {
            funcPagination.pagesHtml.push(<SuiPagination className="pageReload" item onClick={() => fetchInvoices(funcPagination.pages[i])}> {funcPagination.pages[i]} </SuiPagination>)
          }
        }
      }
  
      return funcPagination;
    }

    const buildRows = invoices => {

      const rows = []

      if (invoices.length > 0) {
        invoices.forEach(invoice => {

          const invoiceObj = {
            appId: invoice?.id,
            jobId: invoice?.job?.id,
            date: formatDate(invoice.createdAt),
            status: formatStatus(invoice?.status),
            description: invoice?.description,
            paidOn: invoice?.paidOn,
            notes: invoice?.notes,
            salary: invoice?.contract?.salary && invoice?.contract?.showSalaryToClient ? invoice.contract.salary : "-",
            action: {
              invoiceId: invoice?.id,
              invoiceUrl: invoice?.file,
              updateInvoices: updateInvoices,
              setUpdateInvoices: setUpdateInvoices,
              status: invoice.status,
            },
          }

          rows.push(invoiceObj)
        })
      }

      return rows
    }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/getInvoicesCompany/?page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
      .then(async response => {
        const res = await response.json()

        if (res.success) {
          
          /* PAGINATION */
          const resPagination = buildPagination({ ...res.data.pagination });
          setPagination(resPagination);


          const invoicesData = {
            'pending-approval': [],
            'approved-by-client': [],
            'in-progress': [],
            'declined': [],
            'paid': [],
          }

          // eslint-disable-next-line
          for (const invoice of res.data.invoices) {
            // eslint-disable-next-line
            if (invoice.status === "declined-by-tecla" || invoice.status === "declined-by-client") {
              invoicesData.declined.push(invoice);
            } else if (invoice.status === "approved-by-tecla") {
              invoicesData["pending-approval"].push(invoice);
            } else {
              invoicesData[invoice.status].push(invoice);
            }
          }

          /* APPLICATION RECORDS */
          const tempApplicationStates = {...invoiceStates}

          tempApplicationStates.pendingApproval.rows = buildRows(invoicesData['pending-approval'])
          tempApplicationStates.approved.rows = buildRows(invoicesData['approved-by-client'])
          tempApplicationStates.inProgress.rows = buildRows(invoicesData['in-progress'])
          tempApplicationStates.declined.rows = buildRows(invoicesData.declined)
          tempApplicationStates.paid.rows = buildRows(invoicesData.paid)
          
          setInvoiceStates(tempApplicationStates)

          const tempSmallApplicationStates  = {...smallInvoiceStates}

          tempSmallApplicationStates.pendingApproval.rows = buildRows(invoicesData['pending-approval'])
          tempSmallApplicationStates.approved.rows = buildRows(invoicesData['approved-by-client'])
          tempSmallApplicationStates.inProgress.rows = buildRows(invoicesData['in-progress'])
          tempSmallApplicationStates.declined.rows = buildRows(invoicesData.declined)
          tempSmallApplicationStates.paid.rows = buildRows(invoicesData.paid)

          setSmallInvoiceStates(tempSmallApplicationStates)

          if (res.data.pagination.count !== totalResults) setTotalResults(res.data.pagination.count)

        }
        setIsFetchingData(false)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }

  useEffect(() => { fetchInvoices() }, [updateInvoices])

  const [tabsOrientation, setTabsOrientation] = useState("horizontal")
  const [invoicesTab, setInvoicesTab] = useState(0)

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal")
    }

    window.addEventListener("resize", handleTabsOrientation)

    handleTabsOrientation()

    return () => window.removeEventListener("resize", handleTabsOrientation)

  }, [tabsOrientation])

  const handleSetApplicationsTabs = (event, newTab) => setInvoicesTab(newTab)

  return (
    <CompanyDashboardLayout>
      
      <SuiTypography variant="h4" fontWeight="bold" ml={5} mb={2}>Invoices</SuiTypography>
      <SuiTypography variant="button" fontWeight="regular" color="text">
        From this page you can view and manage all your team invoices.
      </SuiTypography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ minHeight: "65vh" }}>
            {isFetchingData ?
                <SuiBox display="flex" justifyContent="center" alignItems="center" my={30} >
                  <CircularProgress size={40} />
                </SuiBox>
              :
              <>
                <SuiBox pt={3} px={3}>
                  <SuiBox display="flex" justifyContent="start" alignItems="center" width="100%">
                    <SuiBox width="100%">
                      <AppBar position="static">
                        <Tabs orientation={tabsOrientation} value={invoicesTab} onChange={handleSetApplicationsTabs}>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={invoiceStates?.pendingApproval?.rows?.length}
                              max={999}
                              onClick={() => setInvoicesTab(0)}
                            >
                              <Tab label="Pending approval" />
                            </Badge>
                          </SuiBox>

                          
                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={invoiceStates?.approved?.rows?.length}
                              max={999}
                              onClick={() => setInvoicesTab(1)}
                            >
                              <Tab label="Approved" />
                            </Badge>
                          </SuiBox>

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={invoiceStates?.declined?.rows?.length}
                              max={999}
                              onClick={() => setInvoicesTab(2)}
                            >
                              <Tab label="Declined" />
                            </Badge>
                          </SuiBox>    

                          <SuiBox height="50px" width={isMobile ? "200px" : "15%"} mx={isMobile ? "auto" : "3%"} display="flex" justifyContent="center" alignItems="center" >
                            <Badge
                              color="secondary"
                              badgeContent={invoiceStates?.paid?.rows?.length}
                              max={999}
                              onClick={() => setInvoicesTab(3)}
                            >
                              <Tab label="Paid" />
                            </Badge>
                          </SuiBox>       

                        </Tabs>
                      </AppBar>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
                
                <SuiBox p={2} mt={1} width="100%" height="100%" minHeight="50vh">
                  <InvoicesTable title="Pending approval" value={invoicesTab} index={0} table={invoiceStates.pendingApproval} smallTable={smallInvoiceStates.pendingApproval} />
                  <InvoicesTable title="Approved" value={invoicesTab} index={1} table={invoiceStates.approved} smallTable={smallInvoiceStates.approved} />
                  <InvoicesTable title="Declined" value={invoicesTab} index={2} table={invoiceStates.declined} smallTable={smallInvoiceStates.declined} />
                  <InvoicesTable title="Paid" value={invoicesTab} index={3} table={invoiceStates.paid} smallTable={smallInvoiceStates.paid} />
                </SuiBox>

                <SuiBox sx={{ mt: { xs: 'auto ', md: -8 } }} mb={5} mr={5} className="paginationBox" style={{ margin: "20px auto 40px auto" }}>
                  <SuiPagination>
                    {(pagination.count > 0 && pagination.hasPrev) && (
                      <SuiPagination item className="pageReloadPrev" onClick={() => fetchInvoices(pagination.prev)}>
                        <Icon>keyboard_arrow_left</Icon>
                      </SuiPagination>
                    )}

                    {(pagination.count > 0) && pagination.pagesHtml}

                    {(pagination.count > 0 && pagination.hasNext) && (
                      <SuiPagination item className="pageReloadNext" onClick={() => fetchInvoices(pagination.next)}>
                        <Icon>keyboard_arrow_right</Icon>
                      </SuiPagination>
                    )}
                  </SuiPagination>
                </SuiBox>
              </>
            }
          </Card >

        </Grid>
      </Grid>
    </CompanyDashboardLayout>
  );
}

export default MamagerInvoices;
