/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import NameAndPictureCell from './nameAndPictureCell'

export default {
  columns: [
    {
      Header: 'Candidate',
      accessor: 'candidate',
      Cell: ({ value: [name, data, candidateId] }) => <NameAndPictureCell image={data} name={name} candidateId={candidateId} />,
    },
    {
        Header: 'Position',
        accessor: 'position',
        Cell: ({ value: [name, appId] }) => <NameAndPictureCell name={name} appId={appId} />,
    },
    {
      Header: 'Company',
      accessor: 'company',
      Cell: ({ value: [name, companyId] }) => <NameAndPictureCell name={name} companyId={companyId} />,
    },
    { Header: 'Interview status', accessor: 'interviewStatus' },
    { Header: 'Interview date', accessor: 'interviewDate' },
    { Header: 'CV status', accessor: 'cvStatus' },
  ],

  rows: [],
}
