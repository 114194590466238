/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// MUI components
import { Icon, Modal } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress'
// SUI components
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import SuiSelect from "components/SuiSelect";

function ValidateEnglishLevel({ candidateEnglishLevel, setCandidateEnglishLevel, setUpdateWithSameValue }) {

    const [open, setOpen] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false)
    const [englishLevel, setEnglishLevel] = useState(candidateEnglishLevel)

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        console.log(englishLevel)
        if (Number(candidateEnglishLevel) === Number(englishLevel)) {
            setUpdateWithSameValue(true);
        } else {
            setCandidateEnglishLevel(englishLevel);
        }
        setShowSpinner(true);
        setTimeout(() => {
            setShowSpinner(false);
            setOpen(false);
        }, "500")
    };

    const levels = [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: 10, label: 10 },
      ];

    return (
        <>
            <SuiButton
                onClick={handleOpen}
                ariant="gradient" 
                color="info" 
                sx={{ width: "100%" }}
                >
                VALIDATE ENGLISH LEVEL
            </SuiButton>
            <Modal
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <SuiBox sx={{
                    display: "flex",
                    position: 'relative',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: "50vw", md: "42rem", lg: "43rem", xl: "49rem" },
                    height: { xs: "30vh", md: "auto" },
                    maxHeight: { md: "101vh" },
                    minHeight: { md: "16rem" },
                    background: "white",
                    border: 'none',
                    boxShadow: 16,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                }}>
                    <SuiBox>
                        <SuiBox className="alertErrorBox" display="none">
                          <SuiAlert color="error" className="alertError">Please fill all the fields in this section before continuing.</SuiAlert>
                        </SuiBox>
                        <SuiBox className="alertSuccesModalBox" display="none">
                          <SuiAlert color="info" className="alertSuccesModal">Please fill all the fields in this section before continuing.</SuiAlert>
                        </SuiBox>
                        <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                            <SuiTypography id="child-modal-title" variant="overline" opacity={0.75}></SuiTypography>
                            <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                        </SuiBox>
                        <SuiBox display="flex">
                            <SuiTypography id="modal-modal-description" variant="h6" fontWeight="bold">
                                Validate the candidate&apos;s english level from 1 to 10 (They won&apos;t be able to modify it)
                            </SuiTypography>
                        </SuiBox>
                    </SuiBox>

                    <SuiBox>
                        <SuiBox sx={{ width: "100%", display: "block" }}>
                            <SuiSelect
                              placeholder="English Level"
                              options={levels}
                              name="englishLevel"
                              onChange={e => (setEnglishLevel(e.value))}
                              defaultValue={candidateEnglishLevel ?
                                levels.filter(item => item.value === candidateEnglishLevel)[0]
                                :
                                null
                              }
                            />
                        </SuiBox>
                        
                        <SuiBox sx={{ textAlign: "right" }} justifyContent="space-evenly" alignItems="right" flexWrap="wrap" mt={2} mb={1}>
                            <SuiButton size="large" color="info" onClick={handleClose} variant="gradient" circular sx={{ marginRight: "10px" }}>
                                Cancel
                            </SuiButton>
                            {showSpinner ? (
                              <SuiButton sx={{ background: "#fff !important", boxShadow: "none !important" }} size="large" color="white" variant="gradient" circular>
                                <CircularProgress />
                              </SuiButton>)
                            : (
                                <SuiButton size="large"color="info" variant="gradient" onClick={handleSave} circular>
                                    Save
                                </SuiButton>
                            ) }
                        </SuiBox>
                    </SuiBox>
                </SuiBox>
            </Modal>
        </>
    );

}

// typechecking props for Skills
ValidateEnglishLevel.propTypes = {
    setUpdateWithSameValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    setCandidateEnglishLevel: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    candidateEnglishLevel: PropTypes.number.isRequired,
};

export default ValidateEnglishLevel;
