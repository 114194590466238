/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import ProductCell from "layouts/ecommerce/overview/components/ProductCell";

export default {
  columns: [
    {
      Header: "Name",
      accessor: "product",
      width: "40%",
      Cell: ({ value: [name, data, applicationId, isNew] }) => (
        <ProductCell image={data.image} name={name} url={`/application/${applicationId}`} isNew={isNew} />
      ),
    },
    { Header: "Country", accessor: "country" },
    { Header: "Availability", accessor: "availability" },
    { Header: "Salary", accessor: "salary" },
    { Header: "Job", accessor: "job" },
    { Header: "Application", accessor: "app" },
    { Header: "Action", accessor: "action" },
  ],

  rows: [],
};
