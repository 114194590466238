/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session'
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";


function ApproveInvoiceModal({ showApproveInvoiceModal, setShowApproveInvoiceModal, invoiceId }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  const [showError, setShowError] = useState(false)

  const approveInvoice = () => {
    setShowSpinner(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${invoiceId}/setInvoiceStatus/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify({ status: 'approved-by-tecla' })
    })
    .then(async response => {
      const data = await response.json()

      if (data.success) {
        setShowSpinner(false)
        setShowDoneIcon(true)

        setTimeout(() => {
          setShowApproveInvoiceModal(false)
          setShowDoneIcon(false)
          window.location.reload()
        }, 2000)
      }
      else {
        setShowError(true)
        setShowSpinner(false)
        
        setTimeout(() => setShowError(false), 2000)
      }
    })
    .catch(error => {
      setShowError(true)
      console.error('There was an error!', error)
    })
  }

  return (
    <Modal open={showApproveInvoiceModal} onClose={() => setShowApproveInvoiceModal(false)}>
        <SuiBox sx={{
            width: "650px",
            height: "auto",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            borderRadius: { xs: 0, md: 4 },
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        }} >

                {showDoneIcon && <SuiAlert color="info">Invoice sent to client!</SuiAlert>}
                {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                <SuiTypography sx={{ my: 2.5 }} variant="h4">Approve invoice?</SuiTypography>

                <SuiTypography sx={{ my: 2.5 }} variant="body2">Are you sure to approve this invoice? This action can’t be undone. The Invoice will be sent to the clinet for review and they will receive a notification.</SuiTypography>
               
                <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                    <SuiButton
                      sx={{ mx: 1 }}
                      fullWidth
                      color="info"
                      variant="outlined"
                      disabled={showSpinner || showDoneIcon}
                      onClick={() => setShowApproveInvoiceModal(false)}
                    >
                      Cancel
                    </SuiButton>

                    {/* eslint-disable-next-line */}
                    {showSpinner ?
                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                        :
                        showDoneIcon ?
                            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                            :
                            <SuiButton
                              sx={{ mx: 1 }}
                              fullWidth
                              disabled={showSpinner || showDoneIcon}
                              color="info"
                              variant="gradient"
                              onClick={() => approveInvoice()}
                            >
                              Send to client
                            </SuiButton>
                    }
                </SuiBox>
        </SuiBox>
    </Modal>
  )
}

// Typechecking props for the ProfilesList
ApproveInvoiceModal.propTypes = {
  showApproveInvoiceModal: PropTypes.bool.isRequired,
  setShowApproveInvoiceModal: PropTypes.func.isRequired,
  invoiceId: PropTypes.number.isRequired,
};
  

export default ApproveInvoiceModal