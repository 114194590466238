import PropTypes from "prop-types";
import { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
// @mui material components
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiInput from 'components/SuiInput';
import SuiButton from 'components/SuiButton';
import SuiSelect from 'components/SuiSelect';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    "&.MuiAccordion-root::before": {
        display: "none",
    },
    borderRadius: "0.5rem",
    marginBottom: "0.5rem"
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    borderRadius: "0.5rem",
    backgroundColor: '#f8f9fa',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
}));

export default function ViewForm({ setModalContent, candidate, formIdToShow, setFormIdToShow }) { 
    const user = ReactSession.get("user");
    const isRecruiter = (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin")

    const formToShow = candidate.forms.filter(item => item.id === formIdToShow)[0]

    const [expanded, setExpanded] = useState([])
    const [rerenderHack, setRerenderHack] = useState(0) // eslint-disable-line

    useEffect(() => {
        const questionIdsArr = []
        formToShow?.questions.forEach(item => questionIdsArr.push(item.id))
        setExpanded(questionIdsArr)
    }, [])

    const handleExpand = itemId => {
        const newExpandedArr = expanded
        if (expanded.includes(itemId)) setExpanded(newExpandedArr.filter(item => item !== itemId))
        else {
            newExpandedArr.push(itemId)
            setExpanded(newExpandedArr)
        }
        setRerenderHack(prev => prev+1)
    };

    const renderAnswerType = question => {
        switch (question?.question?.type) {
            case "choice":
                return <SuiSelect isDisabled defaultInputValue={question.answer} />
            case "multiline":
                return <SuiInput multiline rows={5} readOnly inputProps={{ maxLength: 2000 }} value={question.answer} />
            case "line":
                return <SuiInput readOnly inputProps={{ maxLength: 2000 }} value={question.answer} />
            default:
                return null
        }
    }

    return (
        <>  
            {isRecruiter && 
            formToShow?.application?.job?.name && 
            formToShow?.application?.job?.company?.name && (
                <SuiBox display="flex" sx={{ marginBottom: 1.5 }}>
                    <SuiTypography
                        variant="subtitle2"
                        sx={{ cursor: 'pointer', marginRight: .5 }}
                        component="a"
                        href={`/job?id=${formToShow?.application?.job?.id}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {formToShow?.application?.job?.name}
                    </SuiTypography>
                    
                    <SuiTypography
                        variant="subtitle2"
                        sx={{ cursor: 'pointer' }}
                        component="a"
                        href={`/r/company/${formToShow?.application?.job?.company?.id}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        - {formToShow?.application?.job?.company?.name}
                    </SuiTypography>
                </SuiBox>
            )}

            {formToShow?.questions?.map(item => (
                <Accordion
                    expanded={expanded.includes(item?.id)}
                    onChange={() => handleExpand(item?.id)}
                    sx={{ backgroundColor: "#f8f9fa" }}
                    key={item.id}
                >
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <SuiTypography variant="subtitle2">{item.question.title}</SuiTypography>
                        {item.question.required && <SuiTypography variant="overline" ml={2}> *required</SuiTypography>}
                    </AccordionSummary>
                    
                    <AccordionDetails>
                        <SuiBox display="flex" flexWrap="wrap" width="100%" mb={2} flexDirection="column">
                            <SuiBox bgColor="#f8f9fa" borderRadius="16px" width="100%" p={1} mr={2} >
                                <SuiTypography variant="body2">{item.question.content}</SuiTypography>
                            </SuiBox>

                            <SuiBox display="inherit" flexWrap="inherit" width="100%">
                                <SuiBox width="100%" mt={1} mr={1}>
                                    {renderAnswerType(item)}
                                </SuiBox>
                            </SuiBox>
                        </SuiBox>
                    </AccordionDetails>
                </Accordion>
            ))}

            <SuiBox mt={2} display="flex" justifyContent="space-beweeen" alignItems="center">
                <SuiButton
                    color="info"
                    variant="outlined"
                    onClick={() => {
                        setFormIdToShow(null)
                        setModalContent('generalView')
                    }}
                >
                    Close
                </SuiButton>
            </SuiBox>
        </>
    );
}

ViewForm.propTypes = {
    setModalContent: PropTypes.func.isRequired,
    candidate: PropTypes.object.isRequired, // eslint-disable-line
    formIdToShow: PropTypes.number.isRequired,
    setFormIdToShow: PropTypes.func.isRequired,
}
