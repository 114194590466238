/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import * as React from 'react';
import Swal from "sweetalert2";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
// MUI
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from '@mui/material/Fade';

function ActionCell({ token, validated, phase, status, moveTo, appId, hiringMethod, company, monthlySalary, salary, updatedApp, setUpdatedApp }) { // eslint-disable-line

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	let selectValue = 0;
	let textAreaValue = "";

	function validateDialog() {
	    const baseText = "To change the state of this application it must be validated first, please enter the salary the client will see.<br>This will validate the application too.<br><br>";
	    let warning = "";
	    let button = "";
	    // withSaleNotes
	    if (hiringMethod === "sa" && company.withSaleNotes) {
	      warning = "<div><b style='color: #FF0000'>Warning:</b> This is a Staff Augmentation position, please check the sales profile before activating. <br><br></div>";
	      button = `<a href="/r/company/${company.id}?sales=1" target="_blank" class="alertButton" tabindex="0" type="button">Sales Profile</a><br>`;
	    } else if (hiringMethod === "sa" && !company.withSaleNotes) {
	      warning = "<div><b style='color: #FF0000'>Warning:</b> This is a Staff Augmentation position and it doesn't have a sales profile yet, therefore applications can't be validated. Please contact the sales manager to fix this. <br><br></div>";
	    }
	    Swal.fire({
	      icon: "info",
	      title: 'Action required',
	      html: `<div style="text-align: left; display: flex; flex-direction: column; justify-content: center; align-items: center">
	                ${baseText}${warning}${button}
	                <div style='text-align: center; font-weight: bold'>Expected salary: $ ${salary}</div>
	                <input type="number" id="swal-input1" class="swal2-input" placeholder="Salary" style="margin-right: auto; margin-left: auto;" />
	              </div>`,
	      // input: 'number',
	      // inputPlaceholder: 'Salary',
	      confirmButtonText: "Validate",
	      // inputValue: salaryValue,
	      didOpen: function (){ // eslint-disable-line
	        if (hiringMethod === "sa" && !company.withSaleNotes) {
	          $(".swal2-confirm").attr("disabled", "disabled");
	        }
	      }
	    }).then((result) => {
	    console.log(result.isConfirmed, $('#swal-input1').val(), status)
	      if (result.isConfirmed && $('#swal-input1').val()) {
	        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/teclaValidateApplication/`;
	        const salaryToShow = Number($('#swal-input1').val());
	        const postData = { salaryToShow: salaryToShow };
	        const requestMetadata = {
	          method: 'POST',
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Token ${token}`
	          },
	          body: JSON.stringify(postData)
	        };

	        fetch(recipeUrl, requestMetadata)
	          .then(res => res.json())
	          .then(response => {
	            if (response.success) {
	            	setUpdatedApp(updatedApp + 1);
	            }
	          });
	      }
	    })
	  }
	
	const declineDialog = () => Swal.fire({
		icon: "error",
		title: 'Decline Applicant',
		text: 'Select a reason to decline this candidate',
		width: "400",
		height: "auto",
		input: "select",
		inputOptions: {
			0: "Select decline reason",
			1: "Compensation misalignment",
			2: "Employment duration too short",
			3: "English skills not up to par",
			4: "Location",
			5: "Offer declined",
			6: "Overqualified",
			7: "Motivations misalignment",
			8: "Underqualified",
			9: "Unresponsive",
			10: "Withdrew",
			11: "Position closed",
			12: "Better for another role",
			13: "Culture Fit",
			14: "Keep for future opportunities",
			15: "Timing",
		},
		inputPlaceholder: 'Click here to list of reasons',
		showCloseButton: true,
		confirmButtonText: 'Confirm',
		confirmButtonAriaLabel: 'Confirm',
		focusConfirm: true,
		showCancelButton: false,
		allowEscapeKey: "true",
		didOpen: () => {
			$(".swal2-select").val(0);
			$(".swal2-select option").first().remove();
		},
		preConfirm: () => {
	      if (Number($(".swal2-container select").val()) === 0) {
	        Swal.showValidationMessage('Select a valid option')   
	      }
	    }
	}).then((result) => {
		selectValue = Number(result.value) - 1;
		if (result.isConfirmed && selectValue >= 0) {
			Swal.fire({
				icon: 'success',
				title: 'Candidate declined',
				text: 'Add some comments',
				inputPlaceholder: 'Type here...',
				focusConfirm: true,
				width: "400",
				height: "auto",
				input: "textarea",
			}).then((areaResult) => {
				if (areaResult.isConfirmed) {
					textAreaValue = $(".swal2-container textarea").val();
					moveTo(appId, phase, status, "declined", selectValue, textAreaValue);
				}
			})
		}
	})

	const declineAction = () => {
		handleClose();
		declineDialog();
	}

	const moveToAction = (newStatus) =>{
	    handleClose();
	    moveTo(appId, phase, status, newStatus, 0, "");
	}

	const hireAction = () =>{
	    handleClose();
	    moveTo(appId, phase, status, "hired", 0, "");
	}

	return (
		<SuiBox display="flex" alignItems="center" >

			<SuiTypography
				color="secondary"
				onClick={handleClick}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					mr: "0.75rem",
					alignSelf: "flex-start",
					py: 1.25,
				}}
			>
				{ !validated ? (
					<SuiButton color="info" variant="outlined" onClick={() => validateDialog()}>
						Action
					</SuiButton>
				) : (
					<SuiButton color="info" variant="outlined">
						Action
					</SuiButton>
				) }
			</SuiTypography>
			{ !validated ? null : (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
					keepMounted
				>
					<MenuItem onClick={() => moveToAction("review")}>
						Move to Applicants
					</MenuItem>

					{status !== "prospects" ? (
						<MenuItem onClick={() => moveToAction("prospects")}>
							Move to Submitted
						</MenuItem>
					) : null}

					{status !== "intro" ? (
						<MenuItem onClick={() => moveToAction("intro")}>
							Move to Intro Requested
						</MenuItem>
					) : null}

					{status !== "interviewing" ? (
						<MenuItem onClick={() => moveToAction("interviewing")}>
							Move to Interviewing
						</MenuItem>
					) : null}

					{status !== "hired" ? (
						<MenuItem onClick={hireAction}>Hire</MenuItem>
					) : null}

					{status !== "declined" ? (
						<MenuItem onClick={declineAction}><SuiTypography variant="body" color="error">Decline</SuiTypography></MenuItem>
					) : null}
				</Menu>
			)}
		</SuiBox>
	);
}

ActionCell.defaultProps = {
	token: "",
	validated: true,
	phase: "next phase",
	status: "prospects",
	moveTo: null,
	appId: 0,
	hiringMethod: "sa",
	company: {},
	monthlySalary: 0,
	salary: 0,
	updatedApp: 0,
	setUpdatedApp: null
}

ActionCell.propTypes = {
	token: PropTypes.string,
	validated: PropTypes.bool,
	phase: PropTypes.string,
	status: PropTypes.string,
	moveTo: PropTypes.func,
	appId: PropTypes.number,
	hiringMethod: PropTypes.string,
	company: PropTypes.objectOf(PropTypes.any),
	monthlySalary: PropTypes.number, 
	salary: PropTypes.number,
	updatedApp: PropTypes.number,
	setUpdatedApp: PropTypes.objectOf(PropTypes.any),
};

export default ActionCell;
