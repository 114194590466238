/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
// sweetalert2 components
import Swal from "sweetalert2";
import PropTypes from "prop-types";

function WithAttachedFunction({jobId, removeApplication}) {
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-error removeApplication",
        cancelButton: "button button-success",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Remove application",
        text: "This will remove this job from your list of applications, are you sure?",
        showCancelButton: true,
        confirmButtonText: `<span data-id=${jobId}>Remove</span>`,
        cancelButtonText: "Cancel",
        reverseButtons: false,
      })
    .then((result) => {
      if (result.value) {
        removeApplication(jobId);
        newSwal.fire("Deleted!", "Your application has been deleted.", "success");
      }
    });
  };

  return (
    <SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={showAlert}>
      <Tooltip title="Remove" placement="left">
        <Icon>delete</Icon>
      </Tooltip>
    </SuiTypography>
  );
}

WithAttachedFunction.defaultProps = {
  jobId: 0,
  removeApplication: null
}

WithAttachedFunction.propTypes = {
  jobId: PropTypes.number,
  removeApplication: PropTypes.func,
};

export default WithAttachedFunction;
