/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
// import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function NewPassword({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { password, passwordConfirm } = formField;
  const {
    passwordConfirm: passwordConfirmV,
    password: passwordV,
  } = values;

  return (
    <SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordV}
              placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              type={passwordConfirm.type}
              label={passwordConfirm.label}
              name={passwordConfirm.name}
              value={passwordConfirmV}
              placeholder="Confirm password"
              error={errors.passwordConfirm && touched.passwordConfirm}
              success={passwordConfirmV.length > 0 && !errors.passwordConfirm}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for NewPassword
NewPassword.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default NewPassword;
