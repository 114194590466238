/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { useParams } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiEditor from "components/SuiEditor";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import FolderCard from "layouts/recruiterFolders/folderFile/folderFile";
import DeleteFileModal from "layouts/pages/managersAddToTeam/modals/deleteFileModal";

function ManagersAddToTeam() {
    ReactSession.setStoreType("localStorage")
    const token = ReactSession.get("token")
    const recruiter = ReactSession.get("recruiter")
    const CandidateForEdit = ReactSession.get("CandidateForEdit")

    // Only recruiters that canManageTeams can access this page and with candidates that aren't inTeam
    useEffect(() => { if(!recruiter?.canManageTeams || CandidateForEdit.inTeam) window.location.replace("/r/dashboard") }, [])

    const { candidateId } = useParams()

    const [isFetchingData, setIsFetchingData] = useState(false)
    const [showError, setShowError] = useState(false) 
    const [showSuccess, setShowSuccess] = useState(false) 
    const [showPDFError, setShowPDFError] = useState(false)
    
    const [showDeleteFileModal, setShowDeleteFileModal] = useState(false)

    const [companyOptions, setCompanyOptions] = useState([])

    const [candidateCompany, setCandidateCompany] = useState(null)
    const [candidateRole, setCandidateRole] = useState(null)
    const [salary, setSalary] = useState(null)
    const [showSalaryToClient, setShowSalaryToClient] = useState(false)
    const [internalNotes, setInternalNotes] = useState(null)
    const [contractFile, setContractFile] = useState(null)

    const fetchCompanyOptions = () => {
        setIsFetchingData(true)

        const headers = { "Content-Type": "application/json" }
        if (token) headers.Authorization = `Token ${token}`

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiterCompanies/`, { headers })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    const optionsArr = []
                    // eslint-disable-next-line
                    for (let i = 0; i < data.data.length; i++) {
                        optionsArr.push({ value: data.data[i].id, label: data.data[i].name })
                    }
    
                    setCompanyOptions(optionsArr)
                    setIsFetchingData(false)
                }
                else console.error('There was an error!')
            })
            .catch(error => console.error('There was an error!', error))
    }
    
    useEffect(() => { fetchCompanyOptions() }, [])

    const addToTeam = () => { // eslint-disable-line
        setIsFetchingData(true)
        const formPostData = new FormData()

        // Check if contract file is PDF
        if (contractFile) {
            const extension = contractFile?.name?.substring(contractFile?.name?.length - 4, contractFile?.name?.length)?.toLowerCase()
        
            if (!extension?.includes("pdf")) {
                setShowPDFError(true)
                return setTimeout(() => setShowPDFError(false), 2500)
            }

            formPostData.append('file', contractFile)
        }

        const normalizedName = contractFile?.name?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")

        formPostData.append('company', candidateCompany)
        formPostData.append('rol', candidateRole)
        formPostData.append('salary', salary)
        formPostData.append('showSalaryToClient', showSalaryToClient ? 1 : 0)
        if (internalNotes) formPostData.append('notes', internalNotes)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateId}/candidateContract/`, {
            method: 'POST',
            headers: {
                'Content-Disposition': `attachment; filename=${normalizedName}`,
                'Authorization': `Token ${token}`
            },
            body: formPostData
          })
            .then(async response => {
                const data = await response.json()
                setIsFetchingData(false)

                if (data.success) {
                    setShowSuccess(true)

                    setCandidateCompany(null)
                    setCandidateRole(null)
                    setSalary(null)
                    setShowSalaryToClient(true)
                    setInternalNotes(null)
                    setContractFile(null)

                    setTimeout(() => setShowSuccess(false), 2500)
                }
                else {
                    setShowError(true)
                    setTimeout(() => setShowError(false), 2500)
                }
            })
            .catch(error => {
                console.error('There was an error!', error)
                setIsFetchingData(false)
                setShowError(true)
                setTimeout(() => setShowError(false), 2500)
            })
    }

    const getFileName = fileName => fileName.substring(fileName.lastIndexOf("/") + 1, fileName.length - 4)
    
    return (
      <RecruiterDashboardLayout>
        <Grid container spacing={3} mt={3}>
            <SuiTypography variant="h4" fontWeight="bold" ml={5}>Add candidate to team</SuiTypography>
 
            <Grid item xs={12}>
                <Card sx={{ minHeight: "75vh", py: 3 }} >

                {showSuccess && <SuiAlert width="90%" mx="auto" color="success">Candidate added to team!</SuiAlert>}
                {showError && <SuiAlert width="90%" mx="auto" color="error">An error ocurred, please try again later.</SuiAlert>}
                {showPDFError && <SuiAlert width="90%" mx="auto" color="error">Only PDF format is allowed for contracts.</SuiAlert>}

                {isFetchingData ?
                    <SuiBox display="flex" justifyContent="center" alignItems="center" my={30} >
                        <CircularProgress size={40} />
                    </SuiBox>
                    :
                    <>
                        <SuiBox>
                            <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
                                <SuiTypography variant="h5" fontWeight="regular">Add candidate to a company team</SuiTypography>
                            </SuiBox>

                            <Grid container justifyContent="center">
                                <Grid item xs={11} mb={2}>
                                    <SuiTypography variant="button" ml={1}>Company</SuiTypography>
                                    <SuiTypography variant="overline" color="secondary" ml={1}>*What company will this candidate work for?</SuiTypography>

                                    <SuiSelect
                                        placeholder="companies"
                                        options={companyOptions}
                                        name="company"
                                        onChange={e => setCandidateCompany(e.value)}
                                    />
                                </Grid>

                                <Grid item xs={11} mb={2}>
                                    <SuiBox width={{sm: "100%", md: "50%"}}>
                                        <SuiTypography variant="button" ml={1}>Candidate role</SuiTypography>
                                        <SuiInput
                                            placeholder="i.e. Backend Developer (max 40 characters)"
                                            name="name"
                                            inputProps={{ maxLength: 40 }}
                                            onChange={e => setCandidateRole(e.target.value)}
                                        />
                                    </SuiBox>
                                </Grid>

                                <Grid item xs={11} mb={2}>
                                    <SuiBox display="flex" alignItems="flex-end">

                                        <SuiBox width="50%" mr="5%">
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography variant="button">Salary</SuiTypography>
                                            </SuiBox>
                                            <SuiInput
                                                type="number"
                                                name="salary"
                                                onChange={e => setSalary(e.target.value)}
                                            />
                                        </SuiBox>

                                        <SuiBox display="flex" mb={.5}>
                                            <SuiButton variant="text" style={{ color: "#30385c" }} onClick={() => setShowSalaryToClient(!showSalaryToClient)}>
                                                <Checkbox
                                                    style={{ marginLeft: "5px", border: "#30385c solid 1px" }}
                                                    onChange={() => setShowSalaryToClient(!showSalaryToClient)}
                                                    checked={showSalaryToClient}
                                                />
                                                Show salary to client?
                                            </SuiButton>
                                        </SuiBox>
                                    </SuiBox>
                                </Grid>


                                <Grid item xs={11} mb={2}>
                                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                        <SuiTypography variant="button">Internal notes (Optional)</SuiTypography>
                                        <SuiTypography variant="overline" color="secondary" ml={1}>*These will only be visible to the TECLA team</SuiTypography>
                                    </SuiBox>

                                    <SuiEditor
                                        maxLength={5000}
                                        value={internalNotes}
                                        onChange={e => setInternalNotes(e)}
                                    />

                                    <SuiTypography
                                        variant="overline"
                                        color={internalNotes?.length > 5000 ? "error" : "secondary"}
                                        textAlign="right"
                                    >
                                        {internalNotes?.length}/5000 chars.
                                    </SuiTypography>
                                </Grid>

                                <Grid item xs={11} mb={2}>
                                    <SuiBox width={{sm: "100%", md: "50%"}} mr="5%">
                                        <SuiBox mb={1} ml={0.5} lineHeight={0}>
                                            <SuiTypography variant="button">Contract file in PDF (Optional)</SuiTypography>
                                        </SuiBox>

                                        <TextField
                                            id="contract"
                                            type="file"
                                            inputProps={{accept: "application/pdf"}}
                                            sx={{ width: "100%" }}
                                            onChange={() => {
                                                const file = document?.getElementById('contract')?.files[0]
                                                setContractFile(file)
                                            }}
                                        />
                                    </SuiBox>
                                </Grid>

                                {CandidateForEdit?.contract?.files?.length > 0 && (
                                    <Grid item xs={11} mb={2}>
                                        <SuiBox width="100%" mr="5%">
                                            <SuiBox mb={1} ml={0.5} lineHeight={0}>
                                                <SuiTypography variant="button">Candidate contract files</SuiTypography>
                                            </SuiBox>

                                            <SuiBox display="flex" flexWrap="wrap">
                                                {CandidateForEdit?.contract?.files?.map(file => (
                                                    <Grid item xs={6} lg={2} className="folder-box" my={1} mr={1} sx={{ maxWidth: "175px !important" }}>
                                                        <FolderCard
                                                        folderName={getFileName(file.file)}
                                                        folderId={file.id}
                                                        folderUrl={file.file}
                                                        closeFile={() => setShowDeleteFileModal(true)}
                                                        />
                                                    
                                                        {showDeleteFileModal && (
                                                            <DeleteFileModal showDeleteFileModal={showDeleteFileModal} setShowDeleteFileModal={setShowDeleteFileModal} file={file} />
                                                        )}
                                                    </Grid>
                                                ))}
                                            </SuiBox>
                                        </SuiBox>
                                    </Grid>
                                )}

                                <Grid item xs={11} mb={2} display="flex" justifyContent="flex-end">
                                    <SuiButton
                                        variant="gradient"
                                        color="info"
                                        disabled={!candidateCompany || !candidateRole || !salary}
                                        onClick={() => addToTeam()}
                                    >
                                        Save
                                    </SuiButton>
                                </Grid>

                            </Grid>

                        </SuiBox>
                    
                    </>
                }
                </Card >
    
            </Grid>
        </Grid>
      </RecruiterDashboardLayout>
  )
}

export default ManagersAddToTeam