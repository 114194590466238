/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "new-company-form",
  formField: {
    firstName: {
      name: "firstName",
      type: "text",
      placeholder: "First Name",
      errorMsg: "First name is required.",
      tooShort: "Your first name should be more than 1 character.",
    },
    lastName: {
      name: "lastName",
      type: "text",
      placeholder: "Last Name",
      errorMsg: "Last name is required.",
      tooShort: "Your last name should be more than 1 character.",
    },
    title: {
      name: "title",
      type: "text",
      placeholder: "Title",
      errorMsg: "Title is required.",
      tooShort: "Your title should be more than 1 character.",
    },
    company: {
      name: "company",
      label: "company",
      type: "text",
      errorMsg: "Company is required.",
      placeholder: "Company Name",
    },
    email: {
      name: "email",
      type: "email",
      placeholder: "Work Email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid.",
      invalidDomainMsg: "Use a valid business email domain.",
    },
    password: {
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be at least 8 characters long.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "repeat password",
      type: "password",
      placeholder: "******",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
    passwordConfirm: {
      name: "passwordConfirm",
      label: "confirm password",
      type: "password",
      placeholder: "******",
      errorMsg: "Confirm Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
    address1: {
      name: "address1",
      label: "address 1",
      type: "text",
      placeholder: "eg. Street 111",
      errorMsg: "Address is required.",
    },
    address2: {
      name: "address2",
      label: "address 2",
      type: "text",
      placeholder: "eg. Street 221",
    },
    city: {
      name: "city",
      label: "city",
      type: "text",
      placeholder: "eg. Tokyo",
      errorMsg: "City is required.",
    },
    country: {
      name: "country",
      label: "country",
      type: "text",
      placeholder: "eg. Japan",
      errorMsg: "Country is required.",
    },
    phone: {
      name: "phone",
      label: "Phone Number",
      type: "tel",
      placeholder: "Phone Number",
      errorMsg: "Phone Number is required.",
      invalidMsg: "Phone number is not valid.",
    },
    dateOfBirth: {
      name: "dateOfBirth",
      label: "Date Of Birth",
      type: "text",
      placeholder: "Date Of Birth",
      errorMsg: "Date Of Birth is required.",
    },
    resume: {
      name: "resume",
      label: "Resume or CV",
      type: "file",
      placeholder: "Resume or CV",
      errorMsg: "Resume or CV is required.",
    },
    zip: {
      name: "zip",
      label: "zip",
      type: "number",
      placeholder: "7 letters",
      errorMsg: "Zip is required.",
      invalidMsg: "Zipcode is not valie (e.g. 70000).",
    },
    publicEmail: {
      name: "publicEmail",
      label: "public email",
      type: "email",
      placeholder: "Use an address you don't use frequently",
    },
    bio: {
      name: "bio",
      label: "bio",
      type: "textarea",
      placeholder: "Say a few words about who you are or what you're working on",
      tooBig: "The company description cannot have more than 500 characters.",
    },
    photo: {
      name: "photo",
      type: "file",
      label: "bio",
      placeholder: "image"
    },
    youtube: {
      name: "youtube",
      label: "youTube handle",
      type: "text",
      placeholder: "Youtube Intro video (optional)",
      errorMsg: "Video is required.",
    },
    github: {
      name: "github",
      label: "github handle",
      type: "text",
      placeholder: "Github profile (optional)",
      errorMsg: "Profile is required.",
    },
    gitlab: {
      name: "gitlab",
      label: "gitlab handle",
      type: "text",
      placeholder: "Gitlab profile (optional)",
      errorMsg: "Profile is required.",
    },
    linkedin: {
      name: "linkedin",
      label: "linkedin handle",
      type: "text",
      placeholder: "Linkedin profile (required)",
      errorMsg: "Profile is required.",
    },
    stackoverflow: {
      name: "stackoverflow",
      label: "stackoverflow handle",
      type: "text",
      placeholder: "Stackoverflow profile (optional)",
      errorMsg: "Profile is required.",
    },
    portfolio: {
      name: "portfolio",
      label: "portfolio handle",
      type: "text",
      placeholder: "Portfolio (optional)",
      errorMsg: "Profile is required.",
    },
    website: {
      name: "website",
      label: "website handle",
      type: "text",
      placeholder: "Website (optional)",
      errorMsg: "Profile is required.",
    },
    twitter: {
      name: "twitter",
      label: "twitter handle",
      type: "text",
      placeholder: "Twitter profile (optional)",
      errorMsg: "Twitter profile is required.",
    },
    facebook: {
      name: "facebook",
      label: "facebook handle",
      type: "text",
      placeholder: "Facebook profile (optional)",
      errorMsg: "facebook profile is required.",
    },
    instagram: {
      name: "instagram",
      label: "instagram handle",
      type: "text",
      placeholder: "Instagram profile (optional)",
      errorMsg: "instagram profile is required.",
    },
  },
};
