/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react"
import { ReactSession } from 'react-client-session'
import Swal from "sweetalert2";
// formik components
import { Formik, Form } from "formik"
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
// Wizard page components
import Basic from "layouts/applications/recruiter-wizard/components/Basic"
import Links from "layouts/pages/profile/recruiter-EditCandidateWizard/components/Links"
import validations from "layouts/applications/recruiter-wizard/formSchemas/validations"
import form from "layouts/applications/recruiter-wizard/formSchemas/form"


function Wizard() {
  ReactSession.setStoreType("localStorage")
  const recruiter = ReactSession.get("recruiter")
  const token = ReactSession.get("token")

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (recruiter) {
      document.title = `Wizard - ${recruiter.user.first_name}  ${recruiter.user.last_name}  | TECLA`;
    }
  }, [recruiter]);

  /* State to store form inputs */
  // Advanced
  const [selectedCountry, setSelectedCountry] = useState(recruiter?.country)
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(recruiter?.dateOfBirth)

  const [activeStep, setActiveStep] = useState(0)

  const { formId, formField } = form;
  const currentValidation = validations[0];

  useEffect(() => {
    $("input[name=file]").attr("accept", ".jpg, .jpeg, .png");
  }, []);

  function updateUser(formValues) { // eslint-disable-line
    setIsSubmitting(true)
    const postBody = { user: {} }

    // Basic
    postBody.user.first_name = formValues.firstName
    postBody.user.last_name = formValues.lastName
    if (formValues.rol !== recruiter?.role) postBody.role = formValues.rol
    if (selectedCountry && selectedCountry !== recruiter?.country) postBody.country = selectedCountry;
    if (selectedDateOfBirth !== recruiter?.dateOfBirth) postBody.dateOfBirth = selectedDateOfBirth;
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/updateRecruiter/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(postBody)
    })
      .then(res => res.json())
      .then(response => {

        if (response.success) {
          if (response.data) ReactSession.set("recruiter", response.data)
          if (response.data.user) ReactSession.set("user", response.data.user)
          
          setIsSubmitting(false)
          Swal.fire("Profile updated.", "", "success");
          let timerSuccessid = "";
          if (timerSuccessid) {
            clearTimeout(timerSuccessid);
          }
          timerSuccessid = setTimeout(() => {
            window.location.replace(`/r/recruiter`)
          }, 3000);
        } else {
          Swal.fire("There was an Error.", "", "error");
        }
      }
      )
      .catch(error => console.error('There was an error!', error))
  }

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <Basic
          formData={formData}
          setActiveStep={setActiveStep}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          setSelectedDateOfBirth={setSelectedDateOfBirth}
          updateUser={updateUser}
        />
      case 4:
        return <Links
          formData={formData}
          setActiveStep={setActiveStep}
          isSubmitting={isSubmitting}
        />
      default:
        return null
    }
  }

  // Forcing form initial values, Formik default way doesn't update correctly
  const {
    formField: {
      firstName,
      lastName,
      rol,
      country,
      dateOfBirth,
      photo,
    },
  } = form;

  return (
    <RecruiterDashboardLayout>
      <SuiBox pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>

            <SuiBox mt={6} mb={1} textAlign="center">
              <SuiBox mb={2}>
                <SuiTypography variant="h3" fontWeight="bold">Edit Recruiter profile</SuiTypography>
              </SuiBox>
            </SuiBox>

            <Card>
              <SuiBox p={2}>
                <SuiBox>
                  <Formik
                    validationSchema={currentValidation}
                    initialValues={{
                      [firstName.name]: recruiter?.user?.first_name || "",
                      [lastName.name]: recruiter?.user?.last_name || "",
                      [rol.name]: recruiter?.role || "",
                      [country.name]: recruiter?.country?.name || "",
                      [dateOfBirth.name]: recruiter?.dateOfBirth || "",
                      [photo.name]: "",
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">
                        <SuiBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                          })}
                        </SuiBox>
                      </Form>
                    )}
                  </Formik>
                </SuiBox>
              </SuiBox>
            </Card>

          </Grid>
        </Grid>
      </SuiBox>
    </RecruiterDashboardLayout>
  );
}

export default Wizard;
