/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// import $ from 'jquery';

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// formik components
// import { Formik, Form } from "formik";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// import NewPassword from "layouts/pages/users/new-user/components/NewPassword";
// import SuiAlert from "components/SuiAlert";

import { ReactSession } from 'react-client-session';

// import validations from "layouts/pages/users/new-user/schemas/validations";
// import form from "layouts/pages/users/new-user/schemas/form";
// import initialValues from "layouts/pages/users/new-user/schemas/initialValues";

// Image
import trustedBy from "assets/images/clients-logos/trusted-by.png";
import SuiAlert from "components/SuiAlert";
// import SuiTypography from "components/SuiTypography";

// function getContent(formData) {
//   return <NewPassword formData={formData} />;
// }

function Illustration() {
  const lastEmail = ReactSession.get("lastEmail");

  function claimAccount() {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/candidateClaim/`;
    const data = {
      "email": lastEmail
    }
    const requestMetadata = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };
    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        let timerid = "";
        if (response.success) {
          $(".alertSuccessBox").show();
          if (timerid) {
            clearTimeout(timerid);
          }
          timerid = setTimeout(() => {
            $(".alertSuccessBox").hide();
          }, 5000);
        } else {
          $(".alertErrorBox").show();
          if (timerid) {
            clearTimeout(timerid);
          }
          timerid = setTimeout(() => {
            $(".alertErrorBox").hide();
          }, 5000);
        }
      });
  }

  const description = `The email ${lastEmail} is already registered in our platform. ` +
      "If this is your email address use the button below to request a claiming link";

  return (
    <IllustrationLayout
      title="Mail already registered."
      description={description}
      illustration={{
        image: trustedBy,
      }}
    >
      <SuiBox className="alertSuccessBox" display="none">
        <SuiAlert className="alertSuccess">Claiming link sent to mail.</SuiAlert>
      </SuiBox>
      <SuiBox className="alertErrorBox" display="none">
        <SuiAlert className="alertError" color="error">Please wait one minute before asking for a new link.</SuiAlert>
      </SuiBox>
      <SuiBox className="suiAlertBox">
        <SuiBox display="flex" alignItems="end">
          <SuiButton variant="outlined" color="info" size="large" fullWidth onClick={claimAccount}>
            Claim this account
          </SuiButton>

        </SuiBox>
        {/* <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertSuccess">Password changed!</SuiAlert>
        </SuiBox>
        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertError" color="error">The password is too weak</SuiAlert>
        </SuiBox> */}
      </SuiBox>
    </IllustrationLayout>
  );
}

export default Illustration;
