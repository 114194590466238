/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

export default styled(LinearProgress)(({ theme, ownerState }) => {

  const { palette } = theme;
  const { color, value, variant } = ownerState;

  const { text } = palette;

  const getEndColor = (val) => {

    if (val === 100) return "#2152ff"
    if (val === 75) return "#217ffa"
    if (val === 50) return "#26b1fa"
    return "#21d4fd"
  }

  const endColor = getEndColor(ownerState?.value)

  // background value
  let backgroundValue;

  if (variant === "gradient") {

    backgroundValue = `linear-gradient(45deg, #21d4fd, ${endColor})`

  } else {
    backgroundValue = palette[color] ? palette[color].main : palette.info.main;
  }


  return {
    "& .MuiLinearProgress-bar": {
      background: backgroundValue,
      width: `${value}%`,
      color: text.main,
    },
  };
});
