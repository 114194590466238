/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { ReactSession } from 'react-client-session'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiAlert from 'components/SuiAlert'
import SuiInput from 'components/SuiInput'
import SuiSelect from 'components/SuiSelect'
// MUI components
import Modal from '@mui/material/Modal'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'
import useMediaQuery from "@mui/material/useMediaQuery"

import ModalTableRow from 'layouts/pages/profile/profile-company/components/magicLinkModal/modalTableRow/ModalTableRow'

function MagicLinkModal({ showMagicLinkModal, setshowMagicLinkModal, company, isFetchingCompanyData, getCompanyDataForRecruiterUser }) {

    const companyForRecruiterEdit = ReactSession.get("companyForRecruiterEdit")
    const token = ReactSession.get("token")

    const [showDoneIcon, setShowDoneIcon] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const [newUserFirstName, setNewUserFirstName] = useState(null)
    const [newUserLastName, setNewUserLastName] = useState(null)
    const [newUserEmail, setNewUserEmail] = useState(null)
    const [newUserRole, setNewUserRole] = useState(null)

    const [showSpinner, setShowSpinner] = useState(false)
	const [showDoneIconBtn, setShowDoneIconBtn] = useState(false)

	const [showNewRoleSpinner, setShowNewRoleSpinner] = useState(false)
	const [showNewRoleDoneIcon, setShowNewRoleDoneIcon] = useState(false)
	const [userBeingProcessed, setUserBeingProcessed] = useState(null)

    useEffect(() => {
        if (errorMsg) {
            setShowError(true)
            setTimeout(() => {
                setShowError(false)
                setErrorMsg('')
            }, 2000)
        }
    }, [errorMsg])
  
	const addNewUser = () => {
		setShowSpinner(true)

		fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/${company.id}/teclaCompanyAddUser/`, {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Token ${token}`
			},
			body: JSON.stringify({ 
                email: newUserEmail,
                type: newUserRole,
                first_name: newUserFirstName,
                last_name: newUserLastName,
             })
		})
			.then(async response => {
				const data = await response.json()

				if (data.success) {
					setShowSpinner(false)
					setShowDoneIconBtn(true)
                    getCompanyDataForRecruiterUser()

                    setTimeout(() => setShowDoneIconBtn(false), 1500)
				}
                else {
                    setShowSpinner(false)

                    if (data.data.error) setErrorMsg(data.data.error)
                    else {
                        setShowError(true)
                        setTimeout(() => {
                            setShowError(false)
                            setErrorMsg('')
                        }, 2000)
                    }
                }
			})
			.catch(error => {
                console.error('There was an error!', error)
                setShowSpinner(false)
                setShowError(true)
                setTimeout(() => {
                    setShowError(false)
                    setErrorMsg('')
                }, 2000)
            })
	}

    const changeUserRole = (user, newRole) => {
		setShowNewRoleSpinner(true)
        setUserBeingProcessed(user.email)

		fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/${company.id}/teclaCompanyUpdateUser/`, {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Token ${token}`
			},
			body: JSON.stringify({ 
                id: user.id,
                type: newRole,
             })
		})
			.then(async response => {
				const data = await response.json()

				if (data.success) {
					setShowNewRoleSpinner(false)
                    setUserBeingProcessed(null)
                    setShowNewRoleDoneIcon(true)
                    getCompanyDataForRecruiterUser()

                    setTimeout(() => setShowNewRoleDoneIcon(false), 1500)
				}
                else {
                    setUserBeingProcessed(null)
                    setShowNewRoleSpinner(false)

                    if (data.data.error) setErrorMsg(data.data.error)
                    else {
                        setShowError(true)
                        setTimeout(() => {
                            setShowError(false)
                            setErrorMsg('')
                        }, 2000)
                    }
                }
			})
			.catch(error => {
                console.error('There was an error!', error)
                setShowNewRoleSpinner(false)
                setUserBeingProcessed(null)
                setShowError(true)
                setTimeout(() => {
                    setShowError(false)
                    setErrorMsg('')
                }, 2000)
            })
	}

    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')

    return (
        <Modal open={showMagicLinkModal} onClose={() => setshowMagicLinkModal(false)}>
            <SuiBox
                sx={{
                    width: { xs: "100vw", md: "80vw" },
                    height: { xs: "100vh", md: "90vh" },
                    maxHeight: { xs: "100vh", md: "90vh" },
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    overflowY: 'auto'
                }}
            >
                {isFetchingCompanyData ?
                    <SuiBox my={20} display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={40} />
                    </SuiBox>
                    :
                    <>
                        {showError && (
                            <SuiAlert color="error">
                                {errorMsg || "There was an error, please try again later."}
                            </SuiAlert>
                        )}
                        
                        {showDoneIcon && <SuiAlert color="info">Magic link copied to clipboard!</SuiAlert>}
                        
                        <SuiTypography variant="h4" fontWeight="bold" textAlign="center">
                            {company?.name} users
                        </SuiTypography>
                        
                        <SuiTypography variant="body2" mt={1}>
                            From here you can add new users to the company, edit their role or copy their magic links
                        </SuiTypography>
                        
                        <SuiBox width="90%">
                            <SuiTypography variant="body1" fontWeight="bold" mt={1}>Company roles</SuiTypography>
                            
                            <SuiTypography variant="body2">
                                <span style={{ fontWeight: "bold" }}>- Admin:</span> Able to manage everything on the account
                            </SuiTypography>

                            <SuiTypography variant="body2">
                                <span style={{ fontWeight: "bold" }}>- Recruiter:</span> Create jobs and view salary information
                            </SuiTypography>

                            <SuiTypography variant="body2">
                                <span style={{ fontWeight: "bold" }}>- Limited recruiter:</span> No salary information and can&apos;t create jobs
                            </SuiTypography>
                        </SuiBox>

                        <SuiBox width="100%" display="flex" flexDirection="column" alignItems="center" my={5}>
                            <SuiTypography variant="body1" fontWeight="bold" mt={1}>Add new user</SuiTypography>
                            
                            <SuiBox display="flex" flexDirection={isMobile ? "column" : "row"} alignItems="center" justifyContent="center">
                                <SuiInput
                                    type="text"
                                    placeholder="First name"
                                    sx={{ my: 1, maxWidth: "11rem" }}
                                    onChange={e => setNewUserFirstName(e.target.value)}
                                />

                                <SuiInput
                                    type="text"
                                    placeholder="Last name"
                                    sx={{ ml: isMobile ? 0 : 3, my: 1, maxWidth: "11rem" }}
                                    onChange={e => setNewUserLastName(e.target.value)}
                                />

                                <SuiInput
                                    type="text"
                                    placeholder="Work email address"
                                    sx={{ mx: isMobile ? 0 : 3, my: 1, maxWidth: "11rem" }}
                                    onChange={e => setNewUserEmail(e.target.value)}
                                />

                                <SuiBox sx={{ minWidth: "15rem", my: 1 }}>
                                    <SuiSelect
                                        placeholder="Company role"
                                        noSelectPrompt
                                        options={[
                                            { value: 'company_owner', label: 'Admin'},
                                            { value: 'recruiter', label: 'Recruiter'},
                                            { value: 'limited_recruiter', label: 'Limited Recruiter'},
                                        ]}
                                        onChange={e => setNewUserRole(e.value)}
                                    />
                                </SuiBox>

                                {/* eslint-disable-next-line */}
                                {showSpinner ?
                                    <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center">
                                        <CircularProgress size={20} />
                                    </SuiBox>
                                    :
                                    showDoneIconBtn ?
                                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                                        :
                                        <SuiButton
                                            sx={{ maxWidth:"15rem", ml: 3, my: 1 }}
                                            color="info"
                                            variant="outlined"
                                            disabled={(!newUserFirstName || !newUserLastName || !newUserEmail || !newUserRole)}
                                            onClick={() => addNewUser()}
                                        >
                                            Add user
                                        </SuiButton>
                                }
                            </SuiBox>
                        </SuiBox>


                        <Table sx={{ mt: 2, mb: 1 }}>
                            <SuiBox component="thead">
                                <TableRow>
                                    <SuiBox component="th" width="400px" align="center">Username</SuiBox>
                                    <SuiBox component="th" width="400px" display={{ xs: "none", sm: "contents" }}>
                                        <SuiBox component="th" width="400px" align="center">Email</SuiBox>
                                    </SuiBox>
                                    <SuiBox component="th" width="400px" align="center">Status</SuiBox>
                                    <SuiBox component="th" width="400px" align="center">Current role</SuiBox>
                                    <SuiBox component="th" width="400px" align="center">New role</SuiBox>
                                    <SuiBox component="th" width="400px" align="center">Confirm</SuiBox>
                                    <SuiBox component="th" width="400px" align="center">Magic Link</SuiBox>
                                </TableRow>
                            </SuiBox>

                            <TableBody>
                                {companyForRecruiterEdit?.users?.map(
                                    user => <ModalTableRow
                                                key={user.email}
                                                user={user}
                                                showDoneIcon={showDoneIcon}
                                                setShowDoneIcon={setShowDoneIcon}
                                                changeUserRole={changeUserRole}
                                                showNewRoleSpinner={showNewRoleSpinner}
                                                showNewRoleDoneIcon={showNewRoleDoneIcon}
                                                userBeingProcessed={userBeingProcessed}
                                            />
                                )}
                            </TableBody>
                        </Table>
                    </>
                }
                
            </SuiBox >
        </Modal >
    )
}

export default MagicLinkModal

// typechecking props
MagicLinkModal.propTypes = {
    showMagicLinkModal: PropTypes.func.isRequired,
    setshowMagicLinkModal: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired, // eslint-disable-line
    isFetchingCompanyData: PropTypes.bool.isRequired,
    getCompanyDataForRecruiterUser: PropTypes.func.isRequired,
};