import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactSession } from 'react-client-session'
// @mui material components
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import SuiSelect from 'components/SuiSelect'
import SuiAlert from 'components/SuiAlert'
import SkillsPicker from './SkillsPicker'
import EnglishLevelEditor from './EnglishLevelEditor'
import Scorecard from './Scorecard'


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    "&.MuiAccordion-root::before": {
        display: "none",
    },
    borderRadius: "0.5rem",
    marginBottom: "0.5rem"
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    borderRadius: "0.5rem",
    backgroundColor: '#f8f9fa',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
}));

export default function AddNewForm({
                                        setModalContent,
                                        candidate,
                                        reFetchCandidateForms,
                                        setNewFormWasCreated,
                                        skillsHaveBeenEdited,
                                        setSkillsHaveBeenEdited,
                                        candidateScorecardData,
                                        application // eslint-disable-line
                                    }) {
    
    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')

    const { userId, appId } = useParams()

    ReactSession.setStoreType("localStorage")
    const token = ReactSession.get("token")
    // const user = ReactSession.get("user")
    // const recruiter = ReactSession.get("recruiter")

    const [expanded, setExpanded] = useState([])
    const [rerenderHack, setRerenderHack] = useState(0) // eslint-disable-line

    const handleExpand = itemId => {
        const newExpandedArr = expanded
        if (expanded.includes(itemId)) setExpanded(newExpandedArr.filter(item => item !== itemId))
        else {
            newExpandedArr.push(itemId)
            setExpanded(newExpandedArr)
        }
        setRerenderHack(prev => prev+1)
    };

    const [formFields, setFormFields] = useState(null)
    const [formResponses, setFormResponses] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showError, setShowError] = useState(false)

    const [showRequiredQuestionsError, setShowRequiredQuestionsError] = useState(false)
        
    const fetchFormFields = () => {
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/forms/getForm/`, {
            headers: {
              "Authorization": `Token ${token}`,
            }})
            .then(res => res.json())
            .then(response => {
                if (response.success) setFormFields(response.data)
            })
            .catch(error =>console.error('There was an error!', error))
    }

    useEffect(() => { fetchFormFields() }, [])

    useEffect(() => {
        if (formFields?.questions) {
            const questionsFormat = []
            const questionsIdsArr = []

            formFields?.questions.forEach(item => {
                questionsFormat.push({ id: item?.id, answer: null, required: item?.required })
                questionsIdsArr.push(item?.id)
            })

            setFormResponses({
                candidate: userId || candidate?.user?.id,
                form: formFields?.id,
                questions: questionsFormat
            })

            /* All question inputs start expanded by default */
            setExpanded(questionsIdsArr)
        }
    }, [formFields])
    
    // Hack to force re-render when text is entered
    // Necessary to show characters count update
    const [updatesCount, setUpdatesCount] = useState(0)

    const updateResponse = (id, value) => {
        const updatedResponses = formResponses
        updatedResponses.questions.filter(item => item?.id === id)[0].answer = value
        setFormResponses(updatedResponses)
        setUpdatesCount(updatesCount+1)
    }

    const renderAnswerType = question => {
        const diffOptions = question?.choice.split('|')
        const optionsArr = []        
        diffOptions.forEach(item => optionsArr.push({ value: item, label: item }))

        switch (question?.type) {
            case "choice":
                return <SuiSelect
                            placeholder={question.required ? "Select one option (required)" : "Select one option"}
                            options={optionsArr}
                            onChange={e => updateResponse(question.id, e.value)}
                        />
            case "multiline":
                return (
                    <SuiBox>
                        <SuiInput
                            placeholder={question.required ? "Type here... (required)" : "Type here..."}
                            multiline
                            rows={5}
                            inputProps={{ maxLength: 2000 }}
                            onChange={e => updateResponse(question.id, e.target.value)}
                        />
                        <SuiTypography
                            mt={1}
                            variant="subtitle2"
                            color={formResponses?.questions?.filter(item => item?.id === question?.id)[0]?.answer?.length === 2000 ? "error" : "auto"}
                            fontSize=".9rem"
                            align="right"
                        >
                            {formResponses?.questions?.filter(item => item?.id === question?.id)[0]?.answer?.length || "0"}/2000 chars.
                        </SuiTypography>
                    </SuiBox>
                )
            case "line":
                return (
                    <SuiBox>
                        <SuiInput
                            placeholder={question.required ? "Type here... (required)" : "Type here..."}
                            inputProps={{ maxLength: 2000 }}
                            onChange={e => updateResponse(question.id, e.target.value)}
                        />
                        <SuiTypography
                            mt={1}
                            variant="subtitle2"
                            color={formResponses?.questions?.filter(item => item?.id === question?.id)[0]?.answer?.length === 2000 ? "error" : "auto"}
                            fontSize=".9rem"
                            align="right"
                        >
                            {formResponses?.questions?.filter(item => item?.id === question?.id)[0]?.answer?.length || "0"}/2000 chars.
                        </SuiTypography>
                    </SuiBox>
                )
            default:
                return null
        }
    }

    const requiredQuestionsAreCompleted = () => {
        // If the question is required and doesnt have an answer, return false
        if (formResponses.questions.filter(item => item?.required && !item?.answer).length) return false
        return true
    }

    /* =========== SKILLS ===========  */
    const [selectedSkills, setSelectedSkills] = useState([])
    /* =========== ENGLISH LEVEL ===========  */
    const [selectedEnglishLevel, setSelectedEnglishLevel] = useState(null)
    /* =========== SCORECARD ===========  */
    const [scorecardData, setScorecardData] = useState([])

    const canSubmitScorecard = () => {
        let canSubmitBool = true
    
        scorecardData?.forEach(item => {if (!item?.value) canSubmitBool = false })
    
        return canSubmitBool
    }

    const submitScorecard = () => {

        const requestBody = []
        scorecardData.forEach(item => {
          requestBody.push(
            { id: item.id, value: item.value }
          )
        })
    
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidate?.user?.id}/setScoreboard/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify(requestBody)
        })
        .then(async response => {
          const data = await response.json()
    
          if (data.success) {
            setIsSubmitting(false)
            reFetchCandidateForms()
            setNewFormWasCreated(true)
          }
          else {
            setShowError(true)
            setIsSubmitting(false)
            setTimeout(() => setShowError(false), 2000)
          }
        })
        .catch(error => {
            setShowError(true)
            setIsSubmitting(false)
            setTimeout(() => setShowError(false), 2000)
            console.error('There was an error!', error)
        })
    }

    const sendForm = () => {
        setIsSubmitting(true)

        if (!requiredQuestionsAreCompleted() || !canSubmitScorecard()) {
            setIsSubmitting(false)
            setShowRequiredQuestionsError(true)
            setTimeout(() => setShowRequiredQuestionsError(false), 3000)
        }
        else {
            // If the question wasn't answered we send (answer: "")
            const postBody = formResponses
            const answeredQuestionsArr = []
            formResponses.questions.forEach(item => answeredQuestionsArr.push({ id: item?.id, answer: item?.answer || "" }))
            postBody.questions = answeredQuestionsArr
            
            // Skills
            if (skillsHaveBeenEdited) postBody.skills = selectedSkills
            // English level
            if (selectedEnglishLevel) postBody.englishLevel = selectedEnglishLevel
            // Application id
            if (appId) postBody.application = appId
            

            fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/addFormToCandidate/`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`,
                },
                body: JSON.stringify(postBody)
                })
                .then(res => res.json())
                .then(response => {
                    if (response.success) submitScorecard()
                })
                .catch(error => console.error('There was an error!', error))
        }
    }

    const scorecardIsEmpty = candidateScorecardData && candidateScorecardData[0].value === '' /* eslint-disable-line */

    const canEditScorecard = () => { /* eslint-disable-line */
        /* // [Soy admin] o 
        if (user?.type === "tecla_admin") return true
        // [Soy manager con la compañía asignada] o 
        if (user?.type === "tecla_manager" && recruiter?.companies?.filter(comp => comp?.id === application?.company?.id)?.length) return true
        // [No soy manager o admin pero fui el recruiter que ha creado el Scoreboard] o 
        if (candidateScorecardData && candidateScorecardData.length &&  candidateScorecardData[0]?.recruiter?.user?.id === user?.id) return true
        // [No soy nada de eso y lo ha creado otro pero está vacío también puedo llenarlo]
        return scorecardIsEmpty */
        return true
    }

    return (
        <>
            {(!formFields || isSubmitting )?
                <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center" sx={{ height: "90vh" }}>
                    <CircularProgress size={40} />
                </SuiBox>
                :
                <>  
                    {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                    {/* SKILLS */}
                    <SuiBox mb={1}>
                        <SkillsPicker selectedSkills={selectedSkills} setSelectedSkills={setSelectedSkills} setSkillsHaveBeenEdited={setSkillsHaveBeenEdited}/>
                    </SuiBox>

                    {/* QUESTIONS */}
                    {formFields?.questions.map(item => (
                        <Accordion
                            key={item?.id}
                            expanded={expanded.includes(item?.id)}
                            onChange={() => handleExpand(item?.id)}
                            sx={{ backgroundColor: "#f8f9fa" }}
                        >
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <SuiTypography variant="subtitle2">{item?.title}</SuiTypography>
                                {item?.required && <SuiTypography variant="overline" ml={2}> *required</SuiTypography>}
                            </AccordionSummary>
                            
                            <AccordionDetails>
                                <SuiBox display="flex" flexWrap="wrap" width="100%" mb={2} flexDirection="column">
                                    <SuiBox bgColor="#f8f9fa" borderRadius="16px" width="100%" p={1} mr={2} >
                                        <SuiTypography variant="body2">{item?.content}</SuiTypography>
                                    </SuiBox>

                                    <SuiBox display="inherit" flexWrap="inherit" width="100%">
                                        <SuiBox width="100%" mt={1} mr={1}>
                                            {renderAnswerType(item)}
                                        </SuiBox>
                                    </SuiBox>
                                </SuiBox>
                            </AccordionDetails>
                        </Accordion>
                    ))}

                    {/* ENGLISH */}
                    <SuiBox mt={1}>
                        <EnglishLevelEditor setSelectedEnglishLevel={setSelectedEnglishLevel} />
                    </SuiBox>

                    {/* SCORECARD */}
                    <SuiBox mt={1}>
                        <Scorecard
                            candidateName={`${candidate?.user?.first_name} ${candidate?.user?.last_name}`}
                            candidateId={candidate?.user?.id}
                            scorecardData={scorecardData}
                            setScorecardData={setScorecardData}
                            canEdit={canEditScorecard()}
                        />
                    </SuiBox>

                    <SuiBox my={2} display="flex" justifyContent="center" alignItems="center">
                        <SuiTypography variant="subtitle2" display={showRequiredQuestionsError ? "block" : "none"}>
                            Please fill all the required fields before sending the form
                        </SuiTypography>
                    </SuiBox>

                    <SuiBox mt={1} mx="auto" display="flex" flexWrap="wrap" justifyContent="space-around" alignItems="center" width={isMobile ? "auto" : "40%"}>
                        <SuiButton color="info" sx={{ width: 200, my: .5 }} variant="outlined" onClick={() => setModalContent('generalView')}>Cancel</SuiButton>
                        <SuiButton color="info" sx={{ width: 200, my: .5 }} variant="gradient" onClick={() => sendForm()}>Send form</SuiButton>
                    </SuiBox>
                </>
            }
        </>
    );
}

AddNewForm.defaultProps = {
    candidateScorecardData: null,
    application: null,
}

AddNewForm.propTypes = {
    setModalContent: PropTypes.func.isRequired,
    reFetchCandidateForms: PropTypes.func.isRequired,
    candidate: PropTypes.object.isRequired, // eslint-disable-line
    setNewFormWasCreated: PropTypes.func.isRequired,
    skillsHaveBeenEdited: PropTypes.bool.isRequired,
    setSkillsHaveBeenEdited: PropTypes.func.isRequired,
    candidateScorecardData: PropTypes.object, // eslint-disable-line
    application: PropTypes.object, // eslint-disable-line
}
