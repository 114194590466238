/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

import { ReactSession } from 'react-client-session';

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// import PropTypes from "prop-types";

// Setting pages components
import TableCell from "layouts/pages/account/settings/components/TableCell";

function Notifications() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const postToken = `Token ${token}`;
  let candidate = { ...ReactSession.get("candidate") };

  function updateCandidate(e) {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/updateCandidate/`;
    const postBody = {};
    const targetName = e.target.name;
    const targetValue = e.target.checked;
    postBody[targetName] = targetValue;
    const requestMetadata = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': postToken
      },
      body: JSON.stringify(postBody)
    };
    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          candidate = { ...ReactSession.get("candidate") };
          candidate[targetName] = targetValue;
          ReactSession.set("candidate", candidate);
        }
      });
  }

  return (
    <Card id="notifications">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5">Email Notifications</SuiTypography>
        </SuiBox>
        <SuiTypography variant="button" color="text" fontWeight="regular">
          Choose which notifications you&apos;d like to receive.
        </SuiTypography>
      </SuiBox>

      <SuiBox pb={3} px={3}>
        <SuiBox minWidth="auto" sx={{ overflow: "none" }}>
          <Table sx={{ minWidth: "36rem" }}>
            <SuiBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Activity
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                  Off/On
                </TableCell>
              </TableRow>
            </SuiBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SuiBox lineHeight={1.4}>
                    <SuiTypography display="block" variant="button" fontWeight="regular">
                      Snooze job Invitations customized to my profile for 3 months.
                    </SuiTypography>
                  </SuiBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  {candidate.snoozeJobInvitations ? (
                    <Switch sx={{ "& .Mui-checked": { top: "50% !important" } }} name="snoozeJobInvitations" onChange={updateCandidate} defaultChecked />
                  ) : (
                    <Switch sx={{ "& .Mui-checked": { top: "50% !important" } }} name="snoozeJobInvitations" onChange={updateCandidate} />
                  )}
                </TableCell>
              </TableRow>



              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SuiBox lineHeight={1.4}>
                    <SuiTypography display="block" variant="button" fontWeight="regular">
                      Send me TECLA notifications, reminders, and other announcements.
                    </SuiTypography>
                  </SuiBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  {candidate.sendMailCommunications ? (
                    <Switch sx={{ "& .Mui-checked": { top: "50% !important" } }} name="sendMailCommunications" onChange={updateCandidate} defaultChecked />
                  ) : (
                    <Switch sx={{ "& .Mui-checked": { top: "50% !important" } }} name="sendMailCommunications" onChange={updateCandidate} />
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SuiBox lineHeight={1.4}>
                    <SuiTypography display="block" variant="button" fontWeight="regular">
                      Send me notifications when I apply for a new job.
                    </SuiTypography>
                  </SuiBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  {candidate.sendJobConfirmationWhenApplying ? (
                    <Switch sx={{ "& .Mui-checked": { top: "50% !important" } }} name="sendJobConfirmationWhenApplying" onChange={updateCandidate} defaultChecked />
                  ) : (
                    <Switch sx={{ "& .Mui-checked": { top: "50% !important" } }} name="sendJobConfirmationWhenApplying" onChange={updateCandidate} />
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SuiBox lineHeight={1.4}>
                    <SuiTypography display="block" variant="button" fontWeight="regular">
                      Send me notifications when one of my applications is declined.
                    </SuiTypography>
                  </SuiBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  {candidate.sendWhenApplicationIsDeclined ? (
                    <Switch sx={{ "& .Mui-checked": { top: "50% !important" } }} name="sendWhenApplicationIsDeclined" onChange={updateCandidate} defaultChecked />
                  ) : (
                    <Switch sx={{ "& .Mui-checked": { top: "50% !important" } }} name="sendWhenApplicationIsDeclined" onChange={updateCandidate} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Notifications;
