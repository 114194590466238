/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react'
import { ReactSession } from 'react-client-session'
import { useParams, Link } from 'react-router-dom'
import { validateToken } from 'utils/commonFunctions'
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
// @mui icons
import WebsiteIcon from "assets/images/socialIcons/Website.png"
import TwitterIcon from "@mui/icons-material/Twitter"
import YouTubeIcon from "@mui/icons-material/YouTube"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiButton from 'components/SuiButton'
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import CompanyDashboardLayout from "examples/LayoutContainers/CompanyDashboardLayout"
import InterestList from "examples/Lists/InterestList"
import CompanyProfileInfoCard from "layouts/pages/profile/profile-company/CompanyProfileInfoCards/companyProfileInfoCard"
import CompanyHighlightsCards from "layouts/pages/profile/profile-company/CompanyProfileInfoCards/companyHighlightsCards"
// Overview page components
import Header from "layouts/pages/profile/components/CompanyHeader"
import placeholder from "assets/images/illustrations/recommended.png"
import CompanyProfileManagersCard from './CompanyProfileInfoCards/companyProfileManagersCard'



function Overview() {

  ReactSession.setStoreType("localStorage")
  const user = ReactSession.get("user")
  const recruiter = ReactSession.get("recruiter")
  const company = ReactSession.get("company")
  const token = ReactSession.get("token")
  const { companyId } = useParams();
  let magicLink = false;
  let editManagers = false;

  if (user.type === "tecla_admin") {
    magicLink = true;
    editManagers = true;
  } else if (companyId && recruiter && recruiter.companies) {
    // eslint-disable-next-line
    const companies = recruiter.companies.filter(function (el) { return Number(el.id) === Number(companyId) });
    if (companies && companies.length) {
      magicLink = true;
    }
  }

  const [companyProfileForRecruiterUsers, setCompanyProfileForRecruiterUsers] = useState(null)
  const [companyLinksForRecruiterUsers, setCompanyLinksForRecruiterUsers] = useState([])
  const [companyInterestsForRecruiterUsers, setCompanyInterestsForRecruiterUsers] = useState([])
  const [managers, setManagers] = useState([]);
  const [updateManagers, setUpdateManagers] = useState(0);
  const [companySalesData, setCompanySalesData] = useState({});
  const [companyUpdated, setCompanyUpdated] = useState(0);
  const [salesNotes, setSalesNotes] = useState("<br/><br/><br/><br/><br/><br/><br/><br/>");
  const [loading, setLoading] = useState(false);


  function getCompanyDataForCompanyUser() {
    const headers = { "Content-Type": "application/json" }
    if (token) headers.Authorization = `Token ${token}`

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/company/`, { headers })
      .then(async response => {
        const data = await response.json()
        ReactSession.set("company", data.data)
        if (data.success) {
          document.title = `Profile - ${data.data.name} | TECLA`;
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  /* Populate company intests and links for company users */
  const interestListData = []
  if (user.type !== "tecla_recruiter" && user.type !== "tecla_manager" && user.type !== "tecla_admin" && company.industries) {
    // eslint-disable-next-line
    for (let i = 0; i < company.industries.length; i++) {
      interestListData.push({
        image: placeholder,
        name: company.industries[i].name,
      })
    }
  }

  const companyLinks = []

  if (user.type !== "tecla_recruiter" && user.type !== "tecla_manager" && user.type !== "tecla_admin" && company.website) {
    companyLinks.push({
      link: company.website,
      icon: <img src={WebsiteIcon} alt="website" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "vimeo"
    })
  }
  if (user.type !== "tecla_recruiter" && user.type !== "tecla_manager" && user.type !== "tecla_admin" && company.youtube) {
    companyLinks.push({
      link: company.youtube,
      icon: <YouTubeIcon />,
      color: "youtube"
    })
  }
  if (user.type !== "tecla_recruiter" && user.type !== "tecla_manager" && user.type !== "tecla_admin" && company.linkedin) {
    companyLinks.push({
      link: company.linkedin,
      icon: <LinkedInIcon />,
      color: "linkedin"
    })
  }
  if (user.type !== "tecla_recruiter" && user.type !== "tecla_manager" && user.type !== "tecla_admin" && company.twitter) {
    companyLinks.push({
      link: company.twitter,
      icon: <TwitterIcon />,
      color: "twitter"
    })
  }

  /* Populate company intests and links for recruiter users */
  // eslint-disable-next-line
  const populateCompanyLinks = () => {
    const linksArr = []

    if (companyProfileForRecruiterUsers?.website) {
      linksArr.push({
        link: companyProfileForRecruiterUsers?.website,
        icon: <img src={WebsiteIcon} alt="website" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
        color: "vimeo"
      })
    }
    if (companyProfileForRecruiterUsers?.youtube) {
      linksArr.push({
        link: companyProfileForRecruiterUsers?.youtube,
        icon: <YouTubeIcon />,
        color: "youtube"
      })
    }
    if (companyProfileForRecruiterUsers?.linkedin) {
      linksArr.push({
        link: companyProfileForRecruiterUsers?.linkedin,
        icon: <LinkedInIcon />,
        color: "linkedin"
      })
    }
    if (companyProfileForRecruiterUsers?.twitter) {
      linksArr.push({
        link: companyProfileForRecruiterUsers?.twitter,
        icon: <TwitterIcon />,
        color: "twitter"
      })
    }

    setCompanyLinksForRecruiterUsers(linksArr)

    const interestsArr = []

    if (companyProfileForRecruiterUsers?.industries) {
      // eslint-disable-next-line
      for (let i = 0; i < companyProfileForRecruiterUsers?.industries.length; i++) {
        interestsArr.push({
          image: placeholder,
          name: companyProfileForRecruiterUsers?.industries[i].name,
        })
      }
    }

    setCompanyInterestsForRecruiterUsers(interestsArr)
  }

  const [isFetchingCompanyData, setIsFetchingCompanyData] = useState(false)

  function getCompanyDataForRecruiterUser() {
    setIsFetchingCompanyData(true)
    
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/${companyId}/teclaCompanyDetail/`, {
      headers: { "Authorization": `Token ${token}` }
    })
      .then(async response => {
        const data = await response.json()
        if (data.success) {
          setCompanyProfileForRecruiterUsers(data.data)

          ReactSession.set("companyForRecruiterEdit", data.data)
          document.title = `Profile - ${data.data.name} | TECLA`;
          setIsFetchingCompanyData(false)
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => {
    const headers = { "Content-Type": "application/json" }
    if (token) headers.Authorization = `Token ${token}`

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/getCompanySale/?company=${companyId}`, { headers })
      .then(async response => {
        const data = await response.json()
        if (data.data) {
          setCompanySalesData(data.data);
          if (data.data.notes !== "") {
            setSalesNotes(data.data.notes);
          }
          setLoading(false);
        }
      })
  }, [companyUpdated])

  useEffect(() => {
    if (managers && managers.length) {
      const recruiters = [];
      // eslint-disable-next-line
      for (const manager of managers) {
        if (manager.checked && !recruiters.includes(manager.value)) {
          recruiters.push(manager.value);
        }
      }

      const postBody = {
        companyId: Number(companyId),
        recruiters: recruiters
      };

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(postBody)
      };

      fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/recruiterCompanyManager/`, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            console.log("updated")
          }
        })
    }
  }, [updateManagers])

  useEffect(() => {
    if (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin") getCompanyDataForRecruiterUser()
    else getCompanyDataForCompanyUser()

    if (editManagers && companyId) {
      const url = `${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiterManagers/`;
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      if (token) {
        headers.Authorization = `Token ${token}`;
      }
      fetch(url, { headers, })
        .then(async response => {
          const res = await response.json();
        validateToken(res) // eslint-disable-line
          if (res.success) {
            const newData = [];
            let fullName = "";
            let filterResult = [];
            let isManaged = false;
            // eslint-disable-next-line
            for (const manager of res.data) {
              isManaged = false;
              fullName = `${manager.user.first_name} ${manager.user.last_name}`;
              filterResult = manager.companies.filter(x => Number(x.id) === Number(companyId));
              if (filterResult && filterResult.length) {
                isManaged = true;
              }
              
              newData.push(
                { 
                  value: manager?.user?.id,
                  label: fullName,
                  checked: isManaged,
                  first_name: manager?.user?.first_name,
                  last_name: manager?.user?.last_name,
                  photo: manager?.user?.photo
                }
              );
            }

            setManagers(newData);
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, [])

  useEffect(() => { if (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin") populateCompanyLinks() }, [companyProfileForRecruiterUsers])

  function saveSales() {
    setLoading(true);
    // const postBody = {
    //   contractType: $("input[name=contract]").val(),
    //   notes: salesNotes
    // };

    const formPostData = new FormData();
    formPostData.append('contractType', $("input[name=contract]").val());
    formPostData.append('notes', salesNotes);

    const requestMetadata = {
      method: 'POST',
      headers: {
        "Content-Disposition": `attachment; filename=file.pdf`,
        'Authorization': `Token ${token}`
      },
      body: formPostData
    };

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/${companyId}/updateCompanySale/`, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          setLoading(false);
        }
      })
  }

  const canEdit = () => {
    /* Admins can edit everything */
    if (user.type === "tecla_admin") return true
    /* Managers can only edit companies they're assigned to */
    if (user.type === "tecla_manager" && recruiter.companies.filter(comp => comp.id === companyProfileForRecruiterUsers.id).length) return true
    /* No one else can edit anything */
    return false
  }

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <>
      {/* eslint-disable-next-line */}
      {((user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin") && companyProfileForRecruiterUsers) ?
        /* Company profile for recruiter users */
        <RecruiterDashboardLayout>

         <Header
            company={companyProfileForRecruiterUsers}
            canEdit={canEdit()}
            magicLink={magicLink}
            setUpdateManagers={setUpdateManagers}
            companySalesData={companySalesData}
            companyUpdated={companyUpdated}
            setCompanyUpdated={setCompanyUpdated}
            salesNotes={salesNotes}
            setSalesNotes={setSalesNotes}
            saveSales={saveSales}
            loading={loading}
            setLoading={setLoading}
            isFetchingCompanyData={isFetchingCompanyData}
            getCompanyDataForRecruiterUser={getCompanyDataForRecruiterUser}
          />

          <SuiBox display="flex" mt={5} mb={3}>
            {!companyProfileForRecruiterUsers.description.length ? (
              <>
                <Grid container>
                  {/* LEFT */}
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={3}>
                      <CompanyProfileManagersCard
                        company={companyProfileForRecruiterUsers}
                        managers={managers}
                        editManagers={editManagers}
                        updateManagers={updateManagers}
                        setUpdateManagers={setUpdateManagers}
                        setManagers={setManagers}
                      />
                    </SuiBox>
                  </Grid>

                  {/* RIGHT */}
                  <Grid item xs={12} md={8}>
                    <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
                      <SuiBox component="img" src={placeholder} alt="Couple of documents" height="auto" width={isMobile ? "100%" : "35%"} />
                      <SuiTypography mb={1} variant="h5" textAlign="center" color="dark">
                        Company has nothing to display here yet.
                      </SuiTypography>
                      <SuiTypography mb={4} variant="body2" textAlign="center" color="dark">
                        This company will be personalizing their profile soon!
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container spacing={3}>
                {/* LEFT */}
                <Grid item xs={12} md={4}>

                  {(companyProfileForRecruiterUsers.website || companyProfileForRecruiterUsers.youtube || companyProfileForRecruiterUsers.linkedin || companyProfileForRecruiterUsers.twitter) && companyLinksForRecruiterUsers && (
                    <SuiBox mb={3}>
                      <CompanyProfileInfoCard links={companyLinksForRecruiterUsers} />
                    </SuiBox>
                  )}

                  <SuiBox mb={3}>
                    <CompanyProfileManagersCard
                      company={companyProfileForRecruiterUsers}
                      managers={managers}
                      editManagers={editManagers}
                      updateManagers={updateManagers}
                      setUpdateManagers={setUpdateManagers}
                      setManagers={setManagers}
                    />
                  </SuiBox>
                  
                  {companyInterestsForRecruiterUsers.length > 0 && (
                    <Grid item xs={12}>
                      <SuiBox mb={2}>
                        <InterestList title="Tech segments" profiles={companyInterestsForRecruiterUsers} />
                      </SuiBox>
                    </Grid>
                  )}

                </Grid>

                {/* RIGHT */}
                <Grid item xs={12} lg={8}>
                  {companyProfileForRecruiterUsers.description.length ? (
                    <Grid item xs={12}>
                      <SuiBox mb={3}>
                        <Card sx={{ height: "auto", minHeight: "13vh" }}>
                          <SuiBox p={2}>
                            <SuiTypography variant="h6" mb={2}>Company Bio</SuiTypography>
                            <SuiTypography variant="body2" p={3}><div dangerouslySetInnerHTML={{ __html: companyProfileForRecruiterUsers.description }} /></SuiTypography>
                          </SuiBox>
                        </Card>
                      </SuiBox>
                    </Grid>
                  ) : null}

                  {(companyProfileForRecruiterUsers.highLights || companyProfileForRecruiterUsers.whyWorkHere || companyProfileForRecruiterUsers.culture || companyProfileForRecruiterUsers.perks) && (
                    <CompanyHighlightsCards
                      companyHighlights={companyProfileForRecruiterUsers.highlights}
                      companyWhyWorkHere={companyProfileForRecruiterUsers.whyWorkHere}
                      companyCulture={companyProfileForRecruiterUsers.culture}
                      companyPerks={companyProfileForRecruiterUsers.perks}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </SuiBox>
        </RecruiterDashboardLayout>
        :
        /* Company profile for company users */
        company ?
          <CompanyDashboardLayout >
            <Header company={company} canEdit />
            <SuiBox display="flex" mt={2} mb={2}>
            {!company.description.length && !interestListData.length ? (
                <>
                  <Grid container xs={12}>
                    <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
                      <SuiBox component="img" src={placeholder} alt="Couple of documents" height="auto" width={isMobile ? "50%" : "20%"} />
                      <SuiTypography mb={1} variant="h5" textAlign="center" color="dark">Your profile is incomplete</SuiTypography>
                      <SuiTypography mb={4} variant="body2" textAlign="center" color="dark">
                        Add your company&apos;s details to attract more applicants.
                      </SuiTypography>
                      <SuiBox component={Link} to="/c/wizard" color="white">
                        <SuiButton color="warning" variant="contained" sx={{ backgroundColor: "#feec1e" }} size="large">
                          <SuiTypography color="dark" fontWeight="bold">
                            EDIT PROFILE
                          </SuiTypography>
                        </SuiButton>
                      </SuiBox>
                    </SuiBox>
                  </Grid>
                </>
              ) : (
                <Grid container spacing={3}>
                  {/* LEFT */}
                  <Grid item xs={12} md={4}>

                    {(company.website || company.youtube || company.twitter) && (
                      <SuiBox mb={3}>
                        <CompanyProfileInfoCard links={companyLinks} />
                      </SuiBox>
                    )}

                    {interestListData.length > 0 && (
                      <Grid item xs={12}>
                        <SuiBox mb={2}>
                          <InterestList title="Tech segments" profiles={interestListData} />
                        </SuiBox>
                      </Grid>
                    )}

                  </Grid>

                  {/* RIGHT */}
                  <Grid item xs={12} lg={8}>
                    {company.description.length ? (
                      <Grid item xs={12}>
                        <SuiBox mb={3}>
                          <Card sx={{ height: "auto", minHeight: "13vh" }}>
                            <SuiBox p={2}>
                              <SuiTypography variant="h6" mb={2}>Company Bio</SuiTypography>
                              <SuiTypography variant="body2" p={3}><div dangerouslySetInnerHTML={{ __html: company.description }} /></SuiTypography>
                            </SuiBox>
                          </Card>
                        </SuiBox>
                      </Grid>
                    ) : null}

                    {(company.highLights || company.whyWorkHere || company.culture || company.perks) && (
                      <CompanyHighlightsCards
                        companyHighlights={company.highlights}
                        companyWhyWorkHere={company.whyWorkHere}
                        companyCulture={company.culture}
                        companyPerks={company.perks}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </SuiBox>
          </CompanyDashboardLayout>
          :
          null
      }
    </>
  )
}

export default Overview