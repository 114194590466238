/* eslint-disable no-useless-escape */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
// import SuiTypography from 'components/SuiTypography'
import SuiAlert from 'components/SuiAlert'
// MUI components
import CircularProgress from '@mui/material/CircularProgress'
// NewslettersIssues page components
import StudentDashboardLayout from 'examples/LayoutContainers/StudentDashboardLayout'
import BaseLayout from 'layouts/pages/account/components/BaseLayout'

function NewsletterIssueDetail() {
    
    ReactSession.setStoreType('localStorage')
    const token = ReactSession.get('token')
    const student = ReactSession.get("student")
    
    const { nlType, nlId } = useParams()

    const [contentToShow, setContentToShow] = useState([])
    const [showError, setShowError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const fetchNLdetail = () => {
        setIsLoading(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/academy/getBoostNewsletterDetail/?postId=${nlId}&type=${nlType}`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            },
        })
        .then(async response => {
            const data = await response.text()
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, 'text/html');
            
            setIsLoading(false)

            if (doc.body.textContent.includes('"success":true')) {
                const htmlToString = html => {
                    const div = document?.createElement('div');
                    div?.appendChild(html);
                    return div?.innerHTML;
                }
    
                const post = doc?.querySelector('.rendered-post');

                // FIX LINKS FORMATTING

                // remove '\' character from string
                const removeBackslash = str => str?.replace(/\\/g, '')

                const fixFormat = str => {
                    let formattedStr = removeBackslash(str)
                    if (formattedStr?.endsWith('"/')) formattedStr = formattedStr?.slice(0, -2)
                    if (formattedStr?.startsWith('"')) formattedStr = formattedStr?.slice(1)
                    return formattedStr
                }

                const images = doc?.querySelectorAll('.rendered-post img')
                // loop through images and remove first two characters and last three characters from src if the image doesn't have the alt 'Author'
                images?.forEach(image => {
                    const src = image?.getAttribute('src')
                    image?.setAttribute('src', fixFormat(src))

                    // set max-width to images
                    image?.setAttribute('style', window?.innerWidth < 500 ? 'max-width: 18rem' : 'max-width: 40rem')
                })

                const audios = doc?.querySelectorAll('.rendered-post audio')
                // loop through audios and remove first two and last three characters from src
                audios?.forEach(audio => {
                    const src = audio?.getAttribute('src')
                    audio?.setAttribute('src', fixFormat(src))
                })

                const links = doc?.querySelectorAll('.rendered-post a')
                // loop through links and remove first two and last three characters from href
                links?.forEach(link => {
                    const href = link?.getAttribute('href')
                    link?.setAttribute('href', fixFormat(href))
                })

                // Format social networks div
                const socialNetworkDiv = doc?.querySelector('.space-x-4')
                socialNetworkDiv?.setAttribute('style', 'display: flex')
                // loop through children and add 1px margin
                socialNetworkDiv?.childNodes?.forEach(child => {
                    child?.setAttribute('style', 'margin: 3px')
                })

                // Get iframe element and delete it
                const iframe = doc?.querySelector('.rendered-post iframe')
                // check if src attribute contains word 'embeds'
                if (iframe?.getAttribute('src')?.includes('embeds')) {
                    iframe?.remove()
                }
    
                setContentToShow(htmlToString(post))
            }
            else {
                console.error('There was an error!')
                setShowError(true)
                setTimeout(() => { setShowError(false) }, (2000))
            }
        })
        .catch(error => {
            console.error('There was an error!', error)
            setShowError(true)
            setTimeout(() => { setShowError(false) }, (2000))
        })
    }

    useEffect(() => { fetchNLdetail() }, [])

    useEffect(() => { document.title = student ? 'Newsletters | Boost' : 'Newsletters | TECLA' }, [])

    const setLayout = children => {
        if (student) return <StudentDashboardLayout>{children}</StudentDashboardLayout>
        return <BaseLayout>{children}</BaseLayout>
    }

    return (
        <>
            {setLayout(
                <>
                    {showError && <SuiAlert color='error'>There was an error, please try again later.</SuiAlert>}
        
                    {isLoading && (
                        <SuiBox my={25} mx='auto' display='flex' justifyContent='center' alignItems='center'><CircularProgress size={40} /></SuiBox>
                    )}
        
                    {!isLoading && contentToShow && (
                        <SuiBox
                            my={2}
                            // sx={{ maxWidth: isMobile ? 'auto' : '80%' }}
                        >
                                <div dangerouslySetInnerHTML={{ __html: contentToShow }} />
                        </SuiBox>
                    )}
                
                </>
            )}
        </>
    )
}

export default NewsletterIssueDetail