/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import Tooltip from "@mui/material/Tooltip"
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"

function RecruiterItem({ userItem, isRecruiter }) {

  let user = userItem
  if (userItem && userItem.user) user = userItem.user

  let classNames = "user-globe"
  if (isRecruiter || user.photo) classNames = `${classNames} isRecruiter`

  function teamMemberName(jobUser) {
    if (jobUser && jobUser.first_name && jobUser.last_name) {
      const memberName = `${jobUser.first_name.substring(0, 1)}${jobUser.last_name.substring(0, 1)}`.toUpperCase();
      return memberName
    }

    return "";
  }

  return (
    <Tooltip title={`${user.first_name} ${user.last_name}`} placement="top">
      <SuiBox className={classNames}>
        { user && user.photo ? (
          <img alt={`${user.first_name} ${user.last_name}`} src={`${process.env.REACT_APP_API_ROUTE}${user.photo}`} />
        ) : (
          <SuiTypography mb={1} variant="h6" textAlign="center" color="dark">{teamMemberName(user)}</SuiTypography>
        ) }
      </SuiBox >
    </Tooltip>
  );
}

// Setting default values for the props of Basket
RecruiterItem.defaultProps = {
  isRecruiter: false
};

// Typechecking props for the Basket
RecruiterItem.propTypes = {
  userItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isRecruiter: PropTypes.bool,
};

export default RecruiterItem;
