/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import CompanyDashboardLayout from "examples/LayoutContainers/CompanyDashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
// import SalesTable from "examples/Tables/SalesTable";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
// import Globe from "examples/Globe";

// 
// import TeamProfileCard from "examples/Cards/TeamCards/TeamProfileCard";
// import JobCard from "examples/Cards/CompanyJobCard";
// import JobCard from "examples/Cards/JobCard";
// import TeamComplexCard from "examples/Cards/ComplexTeamCard"

// import PlaceholderCard from "examples/Cards/PlaceholderCard";
// import ComplexProjectCard from "examples/Cards/ProjectCards/ComplexProjectCard";

// Soft UI Dashboard PRO React base styles
// import typography from "assets/theme/base/typography";
// import breakpoints from "assets/theme/base/breakpoints";

// Data
// import salesTableData from "layouts/dashboards/default/data/salesTableData";
// import reportsBarChartData from "layouts/dashboards/default/data/reportsBarChartData";
// import gradientLineChartData from "layouts/dashboards/default/data/gradientLineChartData";

// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
// import placeholder from "assets/images/placeholder.jpg";

import placeholder from "assets/images/empty.png";

import { ReactSession } from 'react-client-session';
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line


function Default() {
  ReactSession.setStoreType("localStorage");
  // const user = ReactSession.get("user");
  const token = ReactSession.get("token");
  const sesionCandidate = { ...ReactSession.get("candidate") };
  sesionCandidate.favoriteJobs = [];
  const [candidate, setSandidate] = useState(sesionCandidate);

  // const [developerFirstMenu, setDeveloperFirstMenu] = useState(null);

  // const openDeveloperFirstMenu = (event) => setDeveloperFirstMenu(event.currentTarget);
  // const closeDeveloperFirstMenu = () => setDeveloperFirstMenu(null);

  if (!$(".dashboardJob").length) {
    const url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/candidate/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };

    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success) {
          setSandidate(res.data);
          ReactSession.set("candidate", res.data);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  // function getDifferenceInSeconds(date1, date2) {
  //   const diffInMs = Math.abs(date2 - date1);
  //   return diffInMs / 1000;
  // }

  // function timeAgo(date) {
  //   const currentDate = new Date();
  //   const seconds = Math.floor(getDifferenceInSeconds(new Date(date), currentDate));
  //   const mins = Math.floor(seconds / 60);
  //   const hours = Math.floor(mins / 60);
  //   const days = Math.floor(hours / 24);
  //   const months = Math.floor(days / 30);

  //   let timePassed = "";
  //   if (months >= 1) {
  //     timePassed = `${months}M ago`;
  //   } else if (days >= 1) {
  //     timePassed = `${days}d ago`;
  //   } else if (hours >= 1) {
  //     timePassed = `${hours}h ago`;
  //   } else if (mins >= 1) {
  //     timePassed = `${mins}m ago`;
  //   } else if (seconds >= 1) {
  //     timePassed = `${seconds}s ago`;
  //   }

  //   return timePassed;
  // }

  // Dropdown menu template for the ComplexProjectCard
  // const renderMenu = (state, close) => (
  //   <Menu
  //     anchorEl={state}
  //     anchorOrigin={{ vertical: "top", horizontal: "left" }}
  //     transformOrigin={{ vertical: "top", horizontal: "right" }}
  //     open={Boolean(state)}
  //     onClose={close}
  //     keepMounted
  //   >
  //     <MenuItem onClick={close}>Edit</MenuItem>
  //     <MenuItem onClick={close}>Pause</MenuItem>
  //     <MenuItem onClick={close}>Cancel Job</MenuItem>
  //   </Menu>
  // );

  return (
    <CompanyDashboardLayout>
      <SuiBox py={3}>
        {candidate.hotJobs && candidate.hotJobs.length ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <SuiTypography variant="h4" fontWeight="bold" ml={1}>
                Company Dashboard
              </SuiTypography>
            </Grid>


            {/*  Hola Andres,  este trocito de abajo es lo único que se debe mostrar en el empty state */}
            <Grid item>
              <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={10}>
                <img src={placeholder} alt="Couple of documents" height="auto" width="75%" />
                <SuiTypography mb={2} variant="h4">
                  You don&apos;t have any jobs yet
                </SuiTypography>
                <SuiButton circular="true" variant="gradient" color="info" size="large">+ Create job</SuiButton>
              </SuiBox>
            </Grid>

          </Grid>
        ) : null}
      </SuiBox>
    </CompanyDashboardLayout >
  );
}

export default Default;
