/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import { useParams } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// MUI components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// import PublicIcon from '@mui/icons-material/Public';
// import LockIcon from '@mui/icons-material/Lock';

function softBanModal({ showSoftBan, setShowSoftBan }) {

    const token = ReactSession.get("token")

    const { userId } = useParams()

    const [showSpinner, setShowSpinner] = useState(false)
    const [showDoneIcon, setShowDoneIcon] = useState(false)
    const [showError, setShowError] = useState(false)

    const banCandidate = () => {
        setShowSpinner(true)
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${userId}/setAutodeclined/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
            body: JSON.stringify({})
        })
            .then(async response => {
                const data = await response.json()

                if (data.success) {
                    setShowSpinner(false)
                    setShowDoneIcon(true)

                    setTimeout(() => {
                        setShowSoftBan(false)
                        setShowDoneIcon(false)
                    }, 3000)
                }
            })
            .catch(error => {
                console.error('There was an error!', error)
                setShowSpinner(false)
                setShowError(true)

                setTimeout(() => setShowError(false), 3000)
            })
    }

    return (
        <Modal open={showSoftBan} onClose={() => setShowSoftBan(false)}>
            <SuiBox
                sx={{
                    width: "650px",
                    height: "auto",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                {showDoneIcon && <SuiAlert color="info">Candidate banned!</SuiAlert>}
                {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                <SuiTypography sx={{ my: 1 }} variant="h6">Apply Soft Ban?</SuiTypography>
                <SuiTypography sx={{ my: 1 }} variant="button">Applying this action will result in a soft ban for the candidate, leading to the following consequences:</SuiTypography>

                <SuiTypography sx={{ ml: 2 }} variant="button">1. Their applications will not be displayed to recruiters.</SuiTypography>
                <SuiTypography sx={{ ml: 2 }} variant="button">2. The candidate will be excluded from talent searches.</SuiTypography>
                <SuiTypography sx={{ ml: 2 }} variant="button">3. The candidate will not be considered when searching for similar profiles.</SuiTypography>
                <SuiTypography sx={{ ml: 2 }} variant="button">4. The candidate will not be included in AI sourcing.</SuiTypography>
                <SuiTypography sx={{ ml: 2 }} variant="button">5. Their applications will be automatically declined after 3 days of applying.</SuiTypography>

                <SuiTypography sx={{ my: 2 }} variant="button">This action can only be reversed from the database. Are you sure you want to proceed with banning this candidate?</SuiTypography>

                <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                    <SuiButton
                        sx={{ mx: 1 }}
                        fullWidth
                        color="info"
                        variant="outlined"
                        disabled={showSpinner || showDoneIcon}
                        onClick={() => {
                            setShowSoftBan(false)
                        }}
                    >
                            CANCEL
                    </SuiButton>

                    {/* eslint-disable-next-line */}
                    {showSpinner ?
                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                        :
                        showDoneIcon ?
                            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                            :
                            <SuiButton sx={{ mx: 1 }} fullWidth color="info" variant="gradient" onClick={() => banCandidate()}>BAN USER</SuiButton>
                    }
                </SuiBox>
            </SuiBox>
        </Modal>
    )
}

export default softBanModal

// typechecking props
softBanModal.propTypes = {
    showSoftBan: PropTypes.func.isRequired,
    setShowSoftBan: PropTypes.func.isRequired
};