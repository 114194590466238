/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router-dom components
import { Link } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// MUI
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    color: "white",
    fontWeight: "bold"
  },
}));

function ProductCell({ image, name, url, isNew, candidateId, candidateViewed, isViewed, title }) {
  const styles = isViewed ? { color: "#344767", fontWeight : "400" } : { color: "#344767" };

  return (
    <SuiBox display="flex" alignItems="center" pr={2}>
      { !url ? (
        <SuiBox mr={2}>
          <SuiAvatar src={image} alt={name} variant="rounded" />
        </SuiBox>
      ) : (
        <SuiBox mr={2}>
          { candidateViewed ? (
            <Link to={url} target="_blank"><SuiAvatar src={image} alt={name} variant="rounded" onClick={() => candidateViewed(candidateId)} /></Link>
          ) : (
            <Link to={url} target="_blank"><SuiAvatar src={image} alt={name} variant="rounded" /></Link>
          )}
        </SuiBox>
      )}

      { !url ? (
        <SuiBox display="flex" flexDirection="column">
          {isNew ? (
            <Tooltip title="New application" placement="top">
              <StyledBadge color="success" badgeContent="!">
                <SuiTypography variant="button" fontWeight="medium">{name}</SuiTypography>
              </StyledBadge>
            </Tooltip>)
          :
            <SuiTypography variant="button" fontWeight="medium">{name}</SuiTypography>
          }
        </SuiBox>
      ) : (
        <SuiBox display="flex" flexDirection="column">
          {isNew ? (
            <Tooltip title="New application" placement="top">
              <StyledBadge color="success" badgeContent="!">
                <SuiTypography variant="button" fontWeight="medium" mr={1.5}>
                  <Link to={url} target="_blank" style={{ color: "#344767" }} className="candidateSelected" data-id={candidateId}>{name}</Link>
                </SuiTypography>

              </StyledBadge>
            </Tooltip>)
          : (
            <SuiTypography variant="button" fontWeight="medium">
              { candidateViewed ? (
                <Link to={url} target="_blank" style={styles} className="candidateSelected" onClick={() => candidateViewed(candidateId)}>{name}</Link>
              ) : (
                <Link to={url} target="_blank" style={{ color: "#344767" }} className="candidateSelected" data-id={candidateId}>{name}</Link>
              )}
            </SuiTypography>
          )}
          { title ? (
            <SuiTypography variant="h6" style={{ fontSize: "12px", fontWeight: "400" }}>{title}</SuiTypography>
          ) : null }
        </SuiBox>
      )}
    </SuiBox>
  );
}

ProductCell.defaultProps = {
  url: "",
  isNew: false,
  candidateId: 0,
  candidateViewed: null,
  isViewed: false,
  title: ""
};

// Typechecking props for the ProductCell
ProductCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  isNew: PropTypes.bool,
  candidateId: PropTypes.number,
  candidateViewed: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isViewed: PropTypes.bool,
  title: PropTypes.string,
};

export default ProductCell;
