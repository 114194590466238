/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// formik components
import { Formik, Form } from "formik";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayoutStudent";
import NewPassword from "layouts/pages/users/new-user/components/NewPassword";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
// Image
import companyAndRecruitersLogin from "assets/images/Login-B2B-alumnos-graph.jpg";

function getContent(formData) {
  return <NewPassword formData={formData} />;
}

function Illustration() {

  useEffect(() => {
    document.title = `New password | Boost`;
  }, []);

  // Validation
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;

  const currentValidation = validations[activeStep];
  const isLastStep = false;
  let timerid = "";

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(values, null, 2));

    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      actions.setTouched({});
    }
  };

  const newPasswordClick = async () => {
    await sleep(100);
    if ($(".MuiTypography-caption:visible").text().length === 0 && token) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/password_reset/confirm/`;
      const postBody = {
        'password': $("input[name=password]").val(),
        'token': token
      };

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: new URLSearchParams(postBody)
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          $(".suiAlertMsg").remove();

          if (response.success) {
            $(".suiAlertMsg").remove();
            const successAlert = $($('.alertSuccessBox .alertSuccess').parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(successAlert);

            if (timerid) {
              clearTimeout(timerid);
            }

            timerid = setTimeout(() => {
              window.location.replace("/login");
            }, 2000);
          } else {
            $(".suiAlertMsg").remove();

            const errorAlert = $($('.alertSuccessBox .alertError').parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(errorAlert);
          }
        });
    }
  }


  return (
    <IllustrationLayout
      title="New Password"
      description="Please enter a new password for your account."
      illustration={{
        image: companyAndRecruitersLogin,
      }}
    >
      <SuiBox className="suiAlertBox">
        <SuiBox display="flex" alignItems="end">
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, issubmitting }) => (
              <Form id={formId} autoComplete="off" type="POST" actions="#">
                <SuiBox>
                  {getContent({
                    values,
                    touched,
                    formField,
                    errors,
                  })}
                  <SuiBox mt={4} mb={1}>
                    <SuiButton variant="gradient" color="info" size="large" issubmitting={issubmitting} onClick={newPasswordClick} fullWidth>
                      send
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </Form>
            )}
          </Formik>
        </SuiBox>
        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertSuccess">Password changed!</SuiAlert>
        </SuiBox>
        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertError" color="error">The password is not strong enough</SuiAlert>
        </SuiBox>
      </SuiBox>
    </IllustrationLayout>
  );
}

export default Illustration;
