/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
// @mui material components
import Modal from '@mui/material/Modal'
import { capitalize } from "utils/commonFunctions"
import TeclaLogo from 'assets/images/logos/Tecla_Logo_bg.png'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiAvatar from 'components/SuiAvatar'

function ApplicationNoteModal({ showApplicationNoteModal, setShowApplicationNoteModal, application, setShowCompanyCandidateScorecardModal }) {

    return (
        <Modal
            open={showApplicationNoteModal}
            onClose={() => {
            setShowApplicationNoteModal(false)
                if (application?.scoreboard?.length > 0 && application?.scoreboard[0]?.value !== "" && application?.showScoreboard) {
                    setShowCompanyCandidateScorecardModal(true)
                }
            }}
        >
            <SuiBox
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: "100vw", md: "80vw", xl: "60vw" },
                    height: "auto",
                    maxHeight: "80%",
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 2, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <SuiTypography variant="h6" ml={1.5} color="dark">
                    {`Note for ${capitalize(application?.candidate?.user?.first_name)} ${capitalize(application?.candidate?.user?.last_name)}'s application`}
                </SuiTypography>

                <SuiBox sx={{ display: "flex", alignItems: "center", paddingLeft: "20px", my: 1 }}>
                    <SuiAvatar
                        src={application?.notesRecruiter?.user?.photo ? `${process.env.REACT_APP_API_ROUTE}${application?.notesRecruiter?.user?.photo}` : TeclaLogo}
                        alt={application?.notesRecruiter?.user?.first_name ? `${capitalize(application?.notesRecruiter?.user?.first_name)} ${capitalize(application?.notesRecruiter?.user?.last_name)}` : "TECLA"}
                        sx={{ borderRadius: "50%", mr: 1 }} 
                        variant="rounded"
                        size="sm"
                        shadow="sm"
                    />

                    <SuiTypography variant="body2" color="secondary" sx={{ width: "130px" }}>
                        {application?.notesRecruiter?.user?.first_name ? `${capitalize(application?.notesRecruiter?.user?.first_name)} ${capitalize(application?.notesRecruiter?.user?.last_name)}` : "TECLA"}
                    </SuiTypography>
                </SuiBox>

                <SuiTypography variant="body2" color="secondary">
                    <div dangerouslySetInnerHTML={{ __html: application?.notes }} style={{ paddingTop: "5px", paddingLeft: "20px" }} />
                </SuiTypography>

                <SuiBox mt={2} mb={5} width={{ xs: '50%', sm: '20%'}} mx='auto' display="flex">
                    <SuiButton
                        sx={{ mx: 1 }}
                        fullWidth
                        color="info"
                        variant="outlined"
                        onClick={() => {
                            setShowApplicationNoteModal(false)
                            if (application?.scoreboard?.length > 0 && application?.scoreboard[0]?.value !== "" && application?.showScoreboard) {
                                setShowCompanyCandidateScorecardModal(true)
                            }
                        }}
                    >
                        Close
                    </SuiButton>
                </SuiBox>

            </SuiBox>
        </Modal>
    )
}

// Typechecking props for the ApplicationNoteModal
ApplicationNoteModal.propTypes = {
    showApplicationNoteModal: PropTypes.bool.isRequired,
    setShowApplicationNoteModal: PropTypes.func.isRequired,
    application: PropTypes.object.isRequired, // eslint-disable-line
    setShowCompanyCandidateScorecardModal: PropTypes.func.isRequired,
}

export default ApplicationNoteModal