/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// formik components
import { Formik, Form } from "formik";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SuiAlert from "components/SuiAlert";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Wizard page components
import Basic from "layouts/applications/wizard/components/Basic";
import Advanced from "layouts/applications/wizard/components/Advanced";
import Skills from "layouts/applications/wizard/components/Skills";
import Interests from "layouts/applications/wizard/components/Interests";
import Links from "layouts/applications/wizard/components/Links";


function Wizard() {

  const [showLinkedinLinkError, setShowLinkedinLinkError] = useState(false)
  const [showWrongLinkFormatMsg, setShowWrongLinkFormatMsg] = useState(false)
  const [showRequiredError, setShowRequiredError] = useState(false)

  function getSteps() {
    return ["Details", "Contact", "Skills", "Interests", "Links"];
  }
  
  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <Basic formData={formData} />;
      case 1:
        return <Advanced formData={formData} showRequiredError={showRequiredError} />;
      case 2:
        return <Skills formData={formData} />;
      case 3:
        return <Interests formData={formData} />;
      case 4:
        return <Links formData={formData} showLinkedinLinkError={showLinkedinLinkError} showWrongLinkFormatMsg={showWrongLinkFormatMsg} />;
      default:
        return null;
    }
  }
  
  let setStep = true;

  // Validation
  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("user");
  const candidate = ReactSession.get("candidate");
  const token = ReactSession.get("token");
  const postToken = `Token ${token}`;

  const searchParams = new URLSearchParams(window.location.search);
  let urlStep = searchParams.get('step');
  const [activeStep, setActiveStep] = useState(0);
  if (urlStep && setStep) {
    setStep = false;
    urlStep = Number(urlStep);
    setActiveStep(urlStep);
  }

  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[0];
  let validPost = true;

  const isLastStep = activeStep === steps.length - 1;

  useEffect(() => {
    document.title = `Wizard | TECLA`;
  }, []);
  
  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      console.log("submit")
      // submitForm(values, actions);
    } else {
      // setActiveStep(activeStep + 1);
      actions.setTouched({});
      // actions.setSubmitting(false);
    }
  };

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  function getSkillAndLevel(skillString) {
    const skills = JSON.parse($(".skillsBox").attr("data-skills"));

    const skill = skillString.substring(skillString.indexOf("-") - 1, 0).trim();
    const level = Number(skillString.substring(skillString.length, skillString.indexOf("-") + 1));
    let data = null;
    // eslint-disable-next-line
    $.each(skills, function (i, val) {
      if (skill === val.name) {
        data = { "level": level, "skill": { "id": val.id, "name": val.name } };
      }
    });

    return data;
  }

  function getCommitment(commitmentString) {
    const commitments = JSON.parse($(".commitmentsBox").attr("data-commitment"));
    let data = null;
    // eslint-disable-next-line
    $.each(commitments, function (i, val) {
      if (commitmentString === val.name) {
        data = { "id": val.id, "name": val.name };
      }
    });

    return data;
  }

  function getTech(techString) {
    const techs = JSON.parse($(".techsBox").attr("data-techs"));
    let data = null;
    // eslint-disable-next-line
    $.each(techs, function (i, val) {
      if (techString === val.name) {
        data = { "id": val.id, "name": val.name };
      }
    });

    return data;
  }

  function getBoolValue(value) {
    if (value === "true" || value === "True" || value === "Yes" || value === "yes" || value === true) {
      return true;
    }

    return false;
  }

  function updateUser(values, errors) {

    if (
      errors.youtube ||
      errors.github ||
      errors.gitlab ||
      errors.stackoverflow ||
      errors.portfolio ||
      errors.website ||
      errors.twitter ||
      errors.facebook ||
      errors.instagram
    ) {
        setShowWrongLinkFormatMsg(true)
        window.scrollTo({ top: 0, behavior: "smooth" });
        setTimeout(() => setShowWrongLinkFormatMsg(false), 5000)
        return
    }
    
    if ($("form .MuiTypography-caption:visible").text().length === 0 && user && validPost) {
      validPost = false;
      let validData = true;
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/candidates/updateCandidate/`;
      const postBody = {};

      if (($("input[name=firstName]:visible").val() && $("input[name=firstName]:visible").val().length) ||
        $("input[name=lastName]:visible").val() && $("input[name=lastName]:visible").val().length) {
        postBody.user = {};
        if ($("input[name=firstName]:visible").val() && $("input[name=firstName]:visible").val().length) {
          postBody.user.first_name = $("input[name=firstName]").val();
        }
        if ($("input[name=lastName]:visible").val() && $("input[name=lastName]:visible").val().length) {
          postBody.user.last_name = $("input[name=lastName]").val();
        }
      }
      if ($("textarea[name=bio]:visible").val() && $("textarea[name=bio]:visible").val().length) {
        postBody.bio = $("textarea[name=bio]:visible").val();
      }
      if ($("input[name=title]:visible").val() && $("input[name=title]:visible").val().length) {
        postBody.title = $("input[name=title]:visible").val();
      }
      if ($(".countryBox:visible").length) {
        // eslint-disable-next-line
        if (($(".countryBox:visible").attr("data-val") || $(".countryBox:visible").attr("data-val") === 0) && $(".countryBox:visible").attr("data-val").length) {
          postBody.country = { "id": Number($(".countryBox:visible").attr("data-val")) };
        } else if (!candidate || (candidate && !candidate.country)) {
          validData = false;
        }
      }
      if ($("input[name=phone]:visible").val() && $("input[name=phone]:visible").val().length) {
        postBody.phone = $("input[name=phone]").val();
        if (postBody.phone && !postBody.phone.includes("+")) {
          postBody.phone = `+${postBody.phone}`;
        }
      }
      if ($("input[name=dateOfBirth]:visible").val() && $("input[name=dateOfBirth]:visible").val().length) {
        const currentYear = new Date().getFullYear().toString();
        // Formatting birthday like MM-DD - Year is set to 2022 so backend accepts format
        postBody.dateOfBirth = `${currentYear}-${$("input[name=dateOfBirth]").val()}`;
      }
      if ($(".englishLevelBox:visible").length && $("input[name=englishLevel]").val().length) {
        if ($(".englishLevelBox:visible input:eq(0)").val()) {
          postBody.englishLevel = $(".englishLevelBox:visible input:eq(0)").val();
        } else if ($(".englishLevelBox:visible input:eq(1)").val()) {
          postBody.englishLevel = $(".englishLevelBox:visible input:eq(1)").val();
        }
      }
      if ($(".interviewInEnglishBox:visible").length && $("input[name=interviewInEnglish]").val().length) {
        if ($(".interviewInEnglishBox:visible input:eq(0)").val()) {
          postBody.interviewInEnglish = $(".interviewInEnglishBox:visible input:eq(0)").val();
        } else if ($(".interviewInEnglishBox:visible input:eq(1)").val()) {
          postBody.interviewInEnglish = $(".interviewInEnglishBox:visible input:eq(1)").val();
        }
        postBody.interviewInEnglish = getBoolValue(postBody.interviewInEnglish);
      }
      if ($(".canWorkRemotelyBox:visible").length && $("input[name=canWorkRemotely]").val().length) {
        if ($(".canWorkRemotelyBox:visible input:eq(0)").val()) {
          postBody.canWorkRemotely = $(".canWorkRemotelyBox:visible input:eq(0)").val();
        } else if ($(".canWorkRemotelyBox:visible input:eq(1)").val()) {
          postBody.canWorkRemotely = $(".canWorkRemotelyBox:visible input:eq(1)").val();
        }
        postBody.canWorkRemotely = getBoolValue(postBody.canWorkRemotely);
      }

      if ($(".webLinks:visible").length) {
        // Linkedin error message
        if (values.linkedin.length < 1 || errors?.linkedin) {
          setShowLinkedinLinkError(true)
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => setShowLinkedinLinkError(false), 5000)
          return
        }
        else { // eslint-disable-line
          setShowLinkedinLinkError(false)
          // eslint-disable-next-line
          $.each($(".webLinks:visible input"), function (i, val) {
            const inputName = $(val).attr("name");
            postBody[inputName] = $(val).val();
          });
        }
      }


      if ($(".skillsBox .react-tag-input__tag__content:visible").length) {
        const skillArray = [];
        // eslint-disable-next-line
        $.each($(".skillsBox .react-tag-input__tag__content:visible"), function (i, val) {
          const skill = getSkillAndLevel($(val).text());
          if (skill) {
            skillArray.push(skill);
          }
          if (skillArray.length) {
            postBody.skills = skillArray;
          }
        });
      }
      if ($(".commitmentsBox .react-tag-input__tag__content:visible").length) {
        const commitmentArray = [];
        // eslint-disable-next-line
        $.each($(".commitmentsBox .react-tag-input__tag__content:visible"), function (i, val) {
          const commitment = getCommitment($(val).text());
          if (commitment) {
            commitmentArray.push(commitment);
          }
          if (commitmentArray.length) {
            postBody.commitment = commitmentArray;
          }
        });

        const techArray = [];
        // eslint-disable-next-line
        $.each($(".techsBox .react-tag-input__tag__content:visible"), function (i, val) {
          const tech = getTech($(val).text());
          if (tech) {
            techArray.push(tech);
          }
          if (techArray.length) {
            postBody.industries = techArray;
          }
        });
      }

      if (Object.keys(postBody).length > 0 && validData) {
        const requestMetadata = {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': postToken
          },
          body: JSON.stringify(postBody)
        };

        fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
            validPost = true;
            if (response.success) {
              // update data in sesion
              if (postBody.user) {
                if (postBody.user.first_name) {
                  user.first_name = postBody.user.first_name;
                }
                if (postBody.user.last_name) {
                  user.last_name = postBody.user.last_name;
                }
                ReactSession.set("user", user);
              }
              // eslint-disable-next-line
              Object.keys(postBody).forEach(function (key) {
                if (key !== "user") {
                  candidate[key] = postBody[key];
                }
              });
              ReactSession.set("candidate", candidate);

              if (!isLastStep) {
                handleNext();
              } else {
                window.location.replace("/t/profile");
              }
            }
          });
      } else if (!validData) {
        setShowRequiredError(true);
      } else if (activeStep === 4) {
        window.location.replace("/t/profile");
      } else {
        handleNext();
      }

    }
  }

  return (
    <DashboardLayout>
      <SuiBox pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SuiBox mt={6} mb={1} textAlign="center">
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Build Your Profile
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="regular" color="secondary">
                We&apos;ll use this to match you with the right remote jobs
              </SuiTypography>
            </SuiBox>

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <SuiBox className="alertErrorBox" display="none">
              <SuiAlert color="error" className="alertError">Please upload a valid CV.</SuiAlert>
            </SuiBox>
            <Card>
              <SuiBox p={2}>
                <SuiBox>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={currentValidation}
                    onSubmit={handleSubmit}
                  >
                    {({ values, errors, touched, issubmitting }) => (
                      <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">
                        <SuiBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                          })}
                          <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                            {activeStep === 0 ? (
                              <SuiBox />
                            ) : (
                              <SuiButton variant="outlined" color="info" onClick={handleBack} issubmitting={issubmitting} className="changeStep">
                                back
                              </SuiButton>
                            )}
                            <SuiButton
                              variant="gradient"
                              color="info"
                              onClick={() => updateUser(values, errors)}
                              className="changeStep"
                            >
                              {isLastStep ? "done" : "next"}
                            </SuiButton>
                          </SuiBox>
                        </SuiBox>
                      </Form>
                    )}
                  </Formik>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout >
  );
}

export default Wizard;
