/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session'
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";


function DisableAISourcingModal({ showDisableAISourcingModal, setShowDisableAISourcingModal, jobId, fetchRecruiterData, fetchJobsData }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  const [showError, setShowError] = useState(false)

  const disableAISourcing = () => {
    setShowSpinner(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/${jobId}/setAutoSourcing/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify({ autoSourcing: 0 })
    })
    .then(async response => {
      const data = await response.json()
      if (data.success) {
        setShowSpinner(false)
        setShowDoneIcon(true)

        setTimeout(() => {
          setShowDisableAISourcingModal(false)
          setShowDoneIcon(false)
          if (fetchRecruiterData) fetchRecruiterData()
          else if (fetchJobsData) fetchJobsData()
        }, 2000)
      }
      else setShowError(true)
    })
    .catch(error => {
      setShowError(true)
      console.error('There was an error!', error)
    })
  }

  return (
    <Modal
        open={showDisableAISourcingModal}
        onClose={() => setShowDisableAISourcingModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SuiBox sx={{
            width: "650px",
            height: "auto",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            borderRadius: { xs: 0, md: 4 },
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        }} >

                {showDoneIcon && <SuiAlert color="info">AI sourcing disabled!</SuiAlert>}
                {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                <SuiTypography sx={{ my: 2.5 }} variant="h4">Disable AI sourcing for this job?</SuiTypography>

                <SuiTypography sx={{ my: 2.5 }} variant="body2">Warning: If disabled, the AI will not conduct any follow-ups on the candidates already invited. Disable?</SuiTypography>
                <SuiTypography sx={{ my: 2.5 }} variant="body2">Note: Follow-up emails are currently disabled for AI sourcing.</SuiTypography>
                
                <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                    <SuiButton
                      sx={{ mx: 1 }}
                      fullWidth
                      color="info"
                      variant="outlined"
                      disabled={showSpinner || showDoneIcon}
                      onClick={() => setShowDisableAISourcingModal(false)}
                    >
                      Cancel
                    </SuiButton>

                    {/* eslint-disable-next-line */}
                    {showSpinner ?
                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                        :
                        showDoneIcon ?
                            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                            :
                            <SuiButton
                              sx={{ mx: 1 }}
                              fullWidth
                              color="info"
                              variant="gradient"
                              disabled={showSpinner || showDoneIcon}
                              onClick={() => disableAISourcing()}
                            >
                              Disable
                            </SuiButton>
                    }
                </SuiBox>
        </SuiBox>
      </Modal>
  )
}

// default props
DisableAISourcingModal.defaultProps = {
  fetchRecruiterData: null,
  fetchJobsData: null,
}

// Typechecking props for the ProfilesList
DisableAISourcingModal.propTypes = {
  showDisableAISourcingModal: PropTypes.bool.isRequired,
  setShowDisableAISourcingModal: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired,
  fetchRecruiterData: PropTypes.func,
  fetchJobsData: PropTypes.func,
};
  

export default DisableAISourcingModal