/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
// react-router-dom components
import { Link } from 'react-router-dom'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiAvatar from 'components/SuiAvatar'
import mockCompanyAvatar from 'assets/images/mockCompanyAvatar.png'
import mockCandidateAvatar from 'assets/images/mockCandidateAvatar.png'

function NameAndPictureCell({ image, name, candidateId, companyId, companyAvatar, appId }) {

  let link = ''

  if (candidateId) link = `profile/${candidateId}`
  if (companyId)  link = `company/${companyId}`
  if (appId) link = `application/${appId}`

  // eslint-disable-next-line
  const fullImage = image ? `${process.env.REACT_APP_API_ROUTE}${image}` :
                    (candidateId || appId) ? mockCandidateAvatar : mockCompanyAvatar

  return (
    <Link to={link} target='_blank' rel='noopener noreferrer'>
      <SuiBox display='flex' alignItems='center' pr={2}>
        {!appId && !companyId && (
          <SuiBox mr={2} sx={{ display: { xs: 'none', md: 'block' } }}>
              <SuiAvatar src={fullImage} alt={name} variant='rounded' size={companyAvatar ? 'sm' : 'md'} />
          </SuiBox>
        )}

        <SuiBox display='flex' flexDirection='column'>
          <SuiTypography variant='button' fontWeight='medium'>
            {name}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </Link>
  )
}

// Setting default values for the props of SuiAlert
NameAndPictureCell.defaultProps = {
  candidateId: null,
  companyId: null,
  companyAvatar: false,
  appId: null
}

// Typechecking props for the NameAndPictureCell
NameAndPictureCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  candidateId: PropTypes.number,
  companyId: PropTypes.number,
  companyAvatar: PropTypes.bool,
  appId: PropTypes.number,
}

export default NameAndPictureCell
