/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
// Soft UI Dashboard React components
import SuiAlert from "components/SuiAlert";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React base styles
import headerBackground from "assets/images/headerBackground.jpg";

function Header({ user, recruiter, imgProfile }) {
  const isRecruiter = (user && (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin")); // eslint-disable-line 
  const fullname = `${recruiter?.user?.first_name} ${recruiter?.user?.last_name}`;

  return (
    <SuiBox position="relative">
      <SuiBox className="alertSuccessManagerBox" display="none">
        <SuiAlert color="info" display="none">Managers Updated!</SuiAlert>
      </SuiBox>
      <SuiBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="10rem"
        borderRadius="xl"
        sx={{
          backgroundImage: `url(${headerBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        {/* CANDIDATE INFORMATION BELOW */}
        {/* CANDIDATE INFORMATION BELOW */}
        {/* CANDIDATE INFORMATION BELOW */}
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SuiAvatar
              src={imgProfile}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <SuiBox height="90%" mt={0.5} lineHeight={1}>
              <SuiTypography variant="h5" fontWeight="medium">
                {fullname}
              </SuiTypography>
            </SuiBox>
          </Grid>

          <Grid item xs={12} md={7} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <SuiBox display="flex" justifyContent="end" flexWrap="wrap">
                <SuiButton component={Link} to="/r/recruiter/wizard/" color="info" className="magic" sx={{ width: { xs: "100%", sm: "auto" } }}>
                  Edit Profile
                </SuiButton>
              </SuiBox>
            </AppBar>
          </Grid>

        </Grid>
      </Card>
    </SuiBox >
  );
}

Header.defaultProps = {
  user: {},
  recruiter: {},
}

// typechecking props
Header.propTypes = {
  recruiter: PropTypes.oneOfType([PropTypes.object]),
  user: PropTypes.oneOfType([PropTypes.object]),
  imgProfile: PropTypes.string.isRequired,
};

export default Header;
