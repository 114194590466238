/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// ProductsList page components
import NameAndPictureCell from "layouts/recruiterSourcingContent/NameAndPictureCell/nameAndPictureCell"

export default {
  columns: [
    {
      Header: "Candidate",
      accessor: "candidate",
      Cell: ({ value: [name, image, candidateId, title] }) => <NameAndPictureCell name={name} image={image} candidateId={candidateId} title={title} />,
    },
    { Header: "Recruiter", accessor: "recruiter" },
    { Header: "Source Date", accessor: "sourceDate" },
    { Header: "Country", accessor: "country" },
    { Header: "Skills", accessor: "skills" },
    { Header: "Lever Status", accessor: "status" },
    { Header: "Action", accessor: "action" },
  ],

  rows: [
    {
      candidate: "",
      recruiter: "",
      sourceDate: "",
      country: "",
      skills: "",
      action: ""
    },
  ],
}
