/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

function ProfileInfoCard({ title, description, info, social, links, isActiveUser }) {
  ReactSession.setStoreType("localStorage")
  const user = ReactSession.get("user");

  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <SuiBox key={label} display="flex" py={1} pr={2}>
      <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </SuiTypography>
      <SuiTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </SuiTypography>
    </SuiBox>
  ));

  // Render the card social media icons
  const renderSocial = social.map(({ link, icon, color }) => (
    <SuiBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.xl}
      color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </SuiBox>
  ));
  // Render the card external link icons
  const renderLinks = links.map(({ link, icon, color }) => (
    <SuiBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.xl}
      // color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </SuiBox>
  ));

  const isRecruiter = (user && (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin"))

  return (
    <Card sx={{ height: "auto" }}>
      <SuiBox display="flex" flexDirection="column" pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SuiTypography>

        {isRecruiter && isActiveUser && (
          <Tooltip title="Active user" placement="top" sx={{ mb: 1, ml: .5 }}>
            <CheckBoxIcon color="success" />
          </Tooltip>
        )}
      </SuiBox>

      <SuiBox p={2}>
        {description && (
          <>
            <SuiBox mb={2} lineHeight={1}>
              <SuiTypography variant="button" color="text" fontWeight="regular">
                {description}
              </SuiTypography>
            </SuiBox>

            <SuiBox opacity={0.3}>
              <Divider />
            </SuiBox>
          </>
        )}

        <SuiBox>
          {renderItems}
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Links: &nbsp;
            </SuiTypography>
            {renderLinks}
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Social: &nbsp;
            </SuiTypography>
            {renderSocial}
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  info: PropTypes.objectOf(PropTypes.any).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  isActiveUser: PropTypes.bool,
};

ProfileInfoCard.defaultProps = {
  description: '',
  isActiveUser: false,
};

export default ProfileInfoCard;
