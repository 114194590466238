/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

import { ReactSession } from 'react-client-session';

// Settings page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function ChangePassword({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { password, repeatPassword } = formField;
  const {
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;

  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const candidate = ReactSession.get("candidate");
  const postToken = `Token ${token}`;

  function showSuccess() {
    let timerSuccessId = "";
    $(".alertSuccessBox div div").text("Password Changed.");
    $(".alertSuccessBox").show();
    $('html, body').animate({
      scrollTop: $(".alertSuccessBox").offset().top
    }, 100);
    if (timerSuccessId) {
      clearTimeout(timerSuccessId);
    }
    timerSuccessId = setTimeout(() => {
      $(".alertSuccessBox").hide();
    }, 5000);
  }

  function showError(text) {
    let timerErrorId = "";
    $(".alertErrorBox div div").text(text);
    $(".alertErrorBox").show();
    $('html, body').animate({
      scrollTop: $(".alertErrorBox").offset().top
    }, 100);
    if (timerErrorId) {
      clearTimeout(timerErrorId);
    }
    timerErrorId = setTimeout(() => {
      $(".alertErrorBox").hide();
    }, 5000);
  }

  function updatePassword() {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/${candidate.user.id}/changePassword/`;
    const oldInputPassword = $("input[name=password]").val();
    const newInputPassword = $("input[name=repeatPassword]").val();
    const postBody = {
      "old_password": oldInputPassword,
      "new_password": newInputPassword,
    };
    if (oldInputPassword && newInputPassword && $("form .MuiTypography-caption:visible").text().length === 0) {
      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': postToken
        },
        body: JSON.stringify(postBody)
      };
      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            showSuccess();
          } else if (response.data && response.data.error) {
            showError(response.data.error)
          } else {
            showError("Wrong password");
          }
        });
    }
  }

  return (
    <Card id="change-password">
      <SuiBox p={3}>
        <SuiTypography variant="h5">Change Password</SuiTypography>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordV}
              placeholder="Old Password"
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={repeatPassword.type}
              label={repeatPassword.label}
              name={repeatPassword.name}
              value={repeatPasswordV}
              placeholder="New Password"
              error={errors.repeatPassword && touched.repeatPassword}
              success={repeatPasswordV.length > 0 && !errors.repeatPassword}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <SuiBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <SuiBox ml="auto" mt={4}>
            <SuiButton variant="outlined" color="info" size="small" onClick={updatePassword}>
              update password
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// typechecking props for UserSignUp
ChangePassword.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChangePassword;
