/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Fade from "@mui/material/Fade";
import DataTable from "examples/Tables/DataTable";
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function invoicesTable({ value, index, title, table, smallTable }) {
  const isMobile = useMediaQuery('(max-width:750px)')

  return (
    value === index && (
      <Fade in timeout={550}>
        <SuiBox my={3}>
          <SuiTypography variant="h4" ml={1}>{title}</SuiTypography>
          { !isMobile ? (
            <SuiBox sx={{ display: { xs: 'flex', md: 'flex' } }}>
              <DataTable entriesPerPage={{defaultValue: 100}} table={table} view="invoices" />
            </SuiBox>
          ) : (
            <SuiBox sx={{ display: { xs: 'flex ', md: 'flex' } }}>
              <DataTable entriesPerPage={{defaultValue: 100}} table={smallTable} view="invoices" />
            </SuiBox>
          )}

        </SuiBox>
      </Fade>
    )
  );
}

// Typechecking props for the invoicesTable
invoicesTable.propTypes = {
  title: PropTypes.string.isRequired,
  table: PropTypes.string,
  smallTable: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

// defaultprops
invoicesTable.defaultProps = {
  table: null,
  smallTable: null,
}

export default invoicesTable;