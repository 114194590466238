/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
// import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// import { ReactSession } from 'react-client-session';
// Soft UI Dashboard PRO React components
// import { Icon } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// import SuiButton from "components/SuiButton";
import SuiAvatar from "components/SuiAvatar";
// MUI components
import Tooltip from "@mui/material/Tooltip";
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';

// Icons
import leverIcon from "assets/images/LeverIcon.png"

function ActionCell({ candidate }) {

	const apiUrl = process.env.REACT_APP_API_ROUTE;
	let candidateUrl = "";
	let cvUrl = "";
	let leverUrl = "";
	if (candidate && candidate.user) {
		candidateUrl = `/r/profile/${candidate.user.id}`;
		cvUrl = candidate.cv ? `${apiUrl}${candidate.cv}` : "";
		leverUrl = candidate.leverUrl;
	}

	return (
		<SuiBox display="flex" alignItems="center" >

			{cvUrl && (
				<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={cvUrl} target="_blank">
						<Tooltip title="View CV" placement="top">
							<AssignmentIcon />
						</Tooltip>
					</SuiTypography>
				</SuiBox>
			)}

			<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={candidateUrl} target="_blank">
					<Tooltip title="View profile" placement="top">
						<PersonIcon />
					</Tooltip>
				</SuiTypography>
			</SuiBox>

			{leverUrl && (
				<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={leverUrl} target="_blank">
						<Tooltip title="Lever Profile" placement="top">
							<SuiAvatar src={leverIcon} alt="Lever icon" size="xs" />
						</Tooltip>
					</SuiTypography>
				</SuiBox>
			)}
		</SuiBox>
	)
}

ActionCell.defaultProps = {
	candidate: {}
}

ActionCell.propTypes = {
	candidate: PropTypes.objectOf(PropTypes.any),
}

export default ActionCell
