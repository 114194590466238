/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
// import SuiBox from "components/SuiBox";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function ResetPassword({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { email } = formField;
  const {
    email: emailV,
  } = values;

  return (
      <Grid container spacing={3} mt={.2}>
        <Grid item xs={12} sm={12}>
          <FormField
            type={email.type}
            label={email.label}
            name={email.name}
            value={emailV}
            placeholder={email.placeholder}
            error={errors.email && touched.email}
            success={emailV.length > 0 && !errors.email}
          />
        </Grid>
      </Grid>
  );
}

// typechecking props for ResetPassword
ResetPassword.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ResetPassword;
