/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import { useEffect, useState } from 'react'
import { ReactSession } from 'react-client-session'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiDatePicker from 'components/SuiDatePicker'
// MUI components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
// Charts
import HorizontalBarChart from 'examples/Charts/BarCharts/HorizontalBarChart'
import PieChart from 'examples/Charts/PieChart'
import SuiButton from "components/SuiButton"

function RecruiterSuccessRate() {

    const token = ReactSession.get("token")
    const recruiter = ReactSession.get("recruiter")

    // Only recruiters that are admin can access /r/successRate
    if (!recruiter?.user?.type === "tecla_admin") window.location.replace("/r/dashboard")

    const [isFetchingData, setIsFetchingData] = useState(false)
    const [companyData, setCompanyData] = useState(null)
    
    const [startDateFilter, setStartDateFilter] = useState(null)
    const [endDateFilter, setEndDateFilter] = useState(null)
    
	const fetchCompanyStats = (cleanFilters) => {
		setIsFetchingData(true)

        let url = `${process.env.REACT_APP_API_ROUTE}/api/companies/getTeclaReport/?`
        if (!cleanFilters) {
            if (startDateFilter) url = `${url}startDate=${startDateFilter}&`
            if (endDateFilter) url = `${url}endDate=${endDateFilter}`
        }

		fetch(url, {
			method: 'GET',
			headers: { "Authorization": `Token ${token}` },
		})
			.then(async response => {
				const data = await response.json()

				if (data.success) {
					setIsFetchingData(false)
                    setCompanyData(data.data)
				}
			})
			.catch(error => console.error('There was an error!', error))
	}

    useEffect(() => fetchCompanyStats(), [])
  
    return (
        <RecruiterDashboardLayout>
            <SuiTypography variant="h3" fontWeight="bold" ml={1} mb={3}>Tecla success report</SuiTypography>
            
            <Grid container p={2} spacing={2}>
                <Grid item xs={12} sm={3}>
                    <SuiTypography variant="button" ml={1}>Start date</SuiTypography>
                    <SuiDatePicker
                        input={{ placeholder: "Start date" }}
                        value={startDateFilter}
                        onChange={e => setStartDateFilter(new Date(e).toISOString().split('T')[0])}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <SuiTypography variant="button" ml={1}>End date</SuiTypography>
                    <SuiDatePicker
                        input={{ placeholder: "End date" }}
                        value={endDateFilter}
                        onChange={e => setEndDateFilter(new Date(e).toISOString().split('T')[0])}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <SuiButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        sx={{ ml: 1, mt: { xs: 0, sm: 3.8 } }}
                        disabled={(!startDateFilter || !endDateFilter) || isFetchingData}
                        onClick={() => fetchCompanyStats()}
                    >
                        Search
                    </SuiButton>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <SuiButton
                        variant="outlined"
                        color="info"
                        fullWidth
                        sx={{ ml: 1, mt: { xs: 0, sm: 3.8 } }}
                        disabled={(!startDateFilter && !endDateFilter) || isFetchingData}
                        onClick={() => {
                            setStartDateFilter(null)
                            setEndDateFilter(null)
                            fetchCompanyStats(true)
                        }}
                    >
                        Clean filters
                    </SuiButton>
                </Grid>
            </Grid>

            {isFetchingData ? 
                <SuiBox mx="auto" my={30} display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress size={40} />
                </SuiBox>
                :
                <Grid container p={2} spacing={2}>

                    {companyData && (
                        <>
                            <Grid item md={6}>
                                <SuiTypography variant="button" sx={{ ml: 1, mb: 0.5 }}>
                                    Number of days with open jobs (average)
                                </SuiTypography>
                                <SuiInput value={companyData?.daysOpenAverage} disabled />
                            </Grid>

                            <Grid item md={6}>
                                <SuiTypography variant="button" sx={{ ml: 1, mb: 0.5 }}>
                                    Success rate for company jobs
                                </SuiTypography>
                                <SuiInput value={`${companyData?.hiredAverage}% success rate (Jobs with at least one hired candidate are successful)`} disabled />
                            </Grid>

                            <Grid item md={6}>
                                {
                                    (companyData?.client_closed_search === 0 &&
                                    companyData?.client_hired_elsewhere === 0 &&
                                    companyData?.client_hired_tecla === 0 &&
                                    companyData?.client_unresponsive === 0 &&
                                    companyData?.tecla_closed_search === 0)
                                ?
                                    <Card
                                        sx={{
                                            height: "24rem",
                                            display: "flex",
                                            justifyContet: "center",
                                            alignItems: "center",
                                            position: "relative"
                                        }}
                                    >
                                        <SuiTypography
                                            sx={{ position: "absolute", top: 12, left: 12 }}
                                            variant="h6"
                                        >
                                            Reasons for closing jobs
                                        </SuiTypography>

                                        <SuiTypography sx={{ my: "auto" }}>No data</SuiTypography>
                                    </Card>
                                :
                                    <HorizontalBarChart
                                        title="Reasons for closing jobs"
                                        chart={{
                                            labels: [
                                                "Client closed the search",
                                                "Client hired elsewhere",
                                                "Client hired TECLA candidate",
                                                "Client unresponsive",
                                                "TECLA closed search due to poor fit with client"
                                            ],
                                            datasets: [{
                                                label: "Closed jobs by reason",
                                                color: "dark",
                                                data: [
                                                    companyData?.client_closed_search,
                                                    companyData?.client_hired_elsewhere,
                                                    companyData?.client_hired_tecla,
                                                    companyData?.client_unresponsive,
                                                    companyData?.tecla_closed_search
                                                ],
                                            }],
                                        }}
                                    />
                                }
                            </Grid>

                            <Grid item md={6}>
                                {companyData?.activeJobs === 0 && companyData?.closedJobs === 0 ?
                                    <Card
                                        sx={{
                                            height: "24rem",
                                            display: "flex",
                                            justifyContet: "center",
                                            alignItems: "center",
                                            position: "relative"
                                        }}
                                    >
                                        <SuiTypography
                                            sx={{ position: "absolute", top: 12, left: 12 }}
                                            variant="h6"
                                        >
                                            Current jobs per status
                                        </SuiTypography>

                                        <SuiTypography sx={{ my: "auto" }}>No data</SuiTypography>
                                    </Card>
                                :
                                    <PieChart
                                        title="Current jobs per status"
                                        chart={{
                                            labels: companyData?.activeJobs >= companyData?.closedJobs ? ["Active", "Closed"] : ["Closed", "Active"],
                                            datasets: {
                                                label: "Jobs by status",
                                                backgroundColors: companyData?.activeJobs >= companyData?.closedJobs ? ["success", "error"] : ["error", "success"],
                                                data: companyData?.activeJobs >= companyData?.closedJobs ? [
                                                    companyData?.activeJobs,
                                                    companyData?.closedJobs
                                                ] : [
                                                    companyData?.closedJobs,
                                                    companyData?.activeJobs
                                                ],
                                            },
                                        }}
                                    />
                                }
                            </Grid>

                            <Grid item md={6}>
                                {Object.values(companyData?.declineReasons).reduce((partialSum, a) => partialSum + a, 0) === 0 ?
                                    <Card
                                        sx={{
                                            height: "24rem",
                                            display: "flex",
                                            justifyContet: "center",
                                            alignItems: "center",
                                            position: "relative"
                                        }}
                                    >
                                        <SuiTypography
                                            sx={{ position: "absolute", top: 12, left: 12 }}
                                            variant="h6"
                                        >
                                            Decline reasons for candidates applying
                                        </SuiTypography>

                                        <SuiTypography sx={{ my: "auto" }}>No data</SuiTypography>
                                    </Card>
                                :
                                    <HorizontalBarChart
                                        title="Decline reasons for candidates applying"
                                        height="30rem"
                                        chart={{
                                            labels: [
                                                "Compensation misalignment",
                                                "Employment duration too short",
                                                "English skills not up to par",
                                                "Location",
                                                "Offer declined",
                                                "Overqualified",
                                                "Motivations misalignment",
                                                "Underqualified",
                                                "Unresponsive",
                                                "Withdrew",
                                                "Position closed",
                                                "Better for another role",
                                                "Culture Fit",
                                                "Keep for future opportunities",
                                                "Timing",
                                            ],
                                            datasets: [{
                                                label: "Candidates by decline reasons",
                                                color: "dark",
                                                data: [
                                                    companyData?.declineReasons[0],
                                                    companyData?.declineReasons[1],
                                                    companyData?.declineReasons[2],
                                                    companyData?.declineReasons[3],
                                                    companyData?.declineReasons[4],
                                                    companyData?.declineReasons[5],
                                                    companyData?.declineReasons[6],
                                                    companyData?.declineReasons[7],
                                                    companyData?.declineReasons[8],
                                                    companyData?.declineReasons[9],
                                                    companyData?.declineReasons[10],
                                                    companyData?.declineReasons[11],
                                                    companyData?.declineReasons[12],
                                                    companyData?.declineReasons[13],
                                                    companyData?.declineReasons[14]
                                                ],
                                            }],
                                        }}
                                    />
                                }
                            </Grid>

                            <Grid item md={6}>
                                {Object.values(companyData?.candidateStatus).reduce((partialSum, a) => partialSum + a, 0) === 0 ?
                                    <Card
                                        sx={{
                                            height: "24rem",
                                            display: "flex",
                                            justifyContet: "center",
                                            alignItems: "center",
                                            position: "relative"
                                        }}
                                    >
                                        <SuiTypography
                                            sx={{ position: "absolute", top: 12, left: 12 }}
                                            variant="h6"
                                        >
                                            Candidates status before job closing
                                        </SuiTypography>

                                        <SuiTypography sx={{ my: "auto" }}>No data</SuiTypography>
                                    </Card>
                                :
                                    <Card
                                        sx={{
                                            height: "34.5rem",
                                            position: "relative"
                                        }}
                                    >
                                        <SuiTypography
                                            sx={{ position: "absolute", top: 12, left: 12 }}
                                            variant="h6"
                                        >
                                            Candidates status before job closing
                                        </SuiTypography>
                                        
                                        <SuiBox sx={{ my: "auto" }}>
                                            <PieChart
                                                title=""
                                                height="21rem"
                                                chart={{
                                                    labels: [
                                                        "Active",
                                                        "Declined",
                                                        "Hired",
                                                        "In-review",
                                                        "Interview",
                                                        "Intro-requested",
                                                        "Withdrew",
                                                    ],
                                                    datasets: {
                                                        label: "Projects",
                                                        backgroundColors: ["info", "primary", "dark", "secondary", "success", "warning" , "error"],
                                                        data: [
                                                            companyData?.candidateStatus?.active,
                                                            companyData?.candidateStatus?.declined,
                                                            companyData?.candidateStatus?.hired,
                                                            companyData?.candidateStatus?.['in-review'],
                                                            companyData?.candidateStatus?.interview,
                                                            companyData?.candidateStatus?.['intro-requested'],
                                                            companyData?.candidateStatus?.withdrew
                                                        ],
                                                    },
                                                }}
                                            />
                                        </SuiBox>
                                    </Card>
                                }
                            </Grid>
                        </>
                    )}
                </Grid>
            }

        </RecruiterDashboardLayout>
    )
}

export default RecruiterSuccessRate