/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from 'prop-types'
import { formatDate } from 'utils/commonFunctions'
// @mui material components
import Modal from '@mui/material/Modal'
import useMediaQuery from '@mui/material/useMediaQuery'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import SuiTypography from 'components/SuiTypography'
// MUI components
import Divider from '@mui/material/Divider'

function NotificationsModal({ openNotificationsModal, setOpenNotificationsModal, notifications }) { // eslint-disable-line

    const isMobile = useMediaQuery('(max-width:500px)')

    return (
        <Modal open={openNotificationsModal} onClose={() => setOpenNotificationsModal(false)}>
            <SuiBox
                sx={{
                    width: isMobile ? '95%' : '650px',
                    height: 'auto',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: 0,
                    p: 3
                }}
            >
                {notifications?.map((item, i) => 
                    <SuiBox key={item?.pk} sx={{ marginTop: i !== 0 ? 7 : 'auto' }}>
                        <Divider/>
                        <SuiTypography variant='h5' fontWeight='bold'>{item?.notification?.name}</SuiTypography>
                        <SuiTypography variant='h6'>{formatDate(item?.notification?.createdAt)}</SuiTypography>
                        <Divider/>
                        <SuiTypography variant='body2'>
                            <div dangerouslySetInnerHTML={{ __html: item?.notification?.text }} />
                        </SuiTypography>
                    </SuiBox>
                )}

                <SuiBox my={5} width='80%' mx='auto' display='flex'>
                    <SuiButton
                      sx={{ mx: 1 }}
                      fullWidth
                      color='info'
                      variant='outlined'
                      onClick={() => setOpenNotificationsModal(false)}
                    >
                      Close
                    </SuiButton>
                </SuiBox>
            </SuiBox>
        </Modal>
    )
}

export default NotificationsModal

// typechecking props
NotificationsModal.propTypes = {
    openNotificationsModal: PropTypes.bool.isRequired,
    setOpenNotificationsModal: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired, // eslint-disable-line
};