/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Divider from "@mui/material/Divider";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

function Separator() {
  return (
    <SuiBox position="relative" py={0.25}>
      <Divider sx={{ height: 2, marginTop: 5, marginBottom: 5 }}/>
      <SuiBox
        bgColor="white"
        position="absolute"
        top="50%"
        left="50%"
        px={1.5}
        lineHeight={1}
        sx={{ transform: "translate(-50%, -60%)" }}
      />
    </SuiBox>
  );
}

export default Separator;