/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { useLocation } from 'react-router-dom';
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function UserSignUp({ formData, setSelectedCountry, setShowSelectedCountryError, setShowUploadedCVError, setUploadedCV, isSigninupWithLinkedin, linkedinSignupData, isCreatingProfile }) {
  const { formField, values, errors, touched } = formData;
  const { firstName, lastName, email, password } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    password: passwordV,
  } = values;

  const [countryOptions, setCountryOptions] = useState([])
  const [isFetchingCountryOptions, setIsFetchingCountryOptions] = useState(false)  // eslint-disable-line

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const fetchCountryOptions = async () => {
    setIsFetchingCountryOptions(true)

    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = [];
    // eslint-disable-next-line
    Object.keys(interests?.data?.countries).forEach(key => {
      newData.push({ value: interests?.data?.countries[key]?.id, label: interests?.data?.countries[key]?.name });
    });
    setCountryOptions(newData);

    setIsFetchingCountryOptions(false)
  }

  const location = useLocation()
  const isCandidateSignup = () => location?.pathname === '/t/signup'

  useEffect(() => { if (isCandidateSignup() && !isFetchingCountryOptions && !countryOptions.length) fetchCountryOptions() }, []);

  return (
    <SuiBox>
      <SuiBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={isSigninupWithLinkedin ? linkedinSignupData?.first_name : firstNameV}
              disabled={isSigninupWithLinkedin || isCreatingProfile}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={isSigninupWithLinkedin || (firstNameV.length > 0 && !errors.firstName)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={isSigninupWithLinkedin ? linkedinSignupData?.last_name : lastNameV}
              disabled={isSigninupWithLinkedin || isCreatingProfile}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={isSigninupWithLinkedin || (lastNameV.length > 0 && !errors.lastName)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={isSigninupWithLinkedin ? linkedinSignupData?.email : emailV}
              disabled={isSigninupWithLinkedin || isCreatingProfile}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={isSigninupWithLinkedin || (emailV.length > 0 && !errors.email)}
            />
          </Grid>

          {!isSigninupWithLinkedin && (
            <Grid item xs={12} sm={12}>
              <FormField
                type={password.type}
                label={password.label}
                name={password.name}
                value={passwordV}
                disabled={isCreatingProfile}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV.length > 0 && !errors.password}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
          )}

          {isCandidateSignup() && (
            <Grid item xs={12} sm={12}>
              <SuiSelect
                placeholder={isFetchingCountryOptions ? <CircularProgress size={20} /> : "Country of residence"}
                options={countryOptions}
                disabled={isCreatingProfile}
                name="country"
                onChange={e => {
                  setSelectedCountry(e.value)
                  setShowSelectedCountryError(false)
                }}
              />
            </Grid>
          )}

          {isCandidateSignup() && (
            <Grid item xs={12} sm={12} display="flex" flexDirection="column" mb={2}>
              <TextField
                id="cv"
                type="file"
                inputProps={{accept: "application/pdf"}}
                disabled={isCreatingProfile}
                onChange={() => {
                  setShowUploadedCVError(false)
                  const file = document?.getElementById('cv')?.files[0]
                  setUploadedCV(file)
                }}
              />
              <SuiTypography variant="button" fontWeight="regular" color="text" mt={1}>
                Upload your English CV / Resume in PDF format
              </SuiTypography>
            </Grid>
          )}

        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for UserSignUp
UserSignUp.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setShowSelectedCountryError: PropTypes.func.isRequired,
  setShowUploadedCVError: PropTypes.func.isRequired,
  setUploadedCV: PropTypes.func.isRequired,  
  isSigninupWithLinkedin: PropTypes.bool.isRequired,
  linkedinSignupData: PropTypes.object.isRequired, // eslint-disable-line
  isCreatingProfile: PropTypes.bool.isRequired,
};

export default UserSignUp;
