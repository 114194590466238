/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// @mui material components
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'
import useMediaQuery from '@mui/material/useMediaQuery'
import Checkbox from '@mui/material/Checkbox'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiAlert from 'components/SuiAlert'
import SuiSelect from 'components/SuiSelect'

function CandidateScorecardModal({
                          showCandidateScorecardModal,
                          setShowCandidateScorecardModal,
                          candidateName,
                          candidateId,
                          application,
                          cantEditRecords
                        }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")
  const user = ReactSession.get("user")
  const recruiter = ReactSession.get("recruiter")

  const { appId } = useParams()

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const [isFetchingData, setIsFetchingData] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  const [showError, setShowError] = useState(false)

  const [showScorecardToClient, setShowScorecardToClient] = useState(application?.showScoreboard)
  const [isUpdatingToggle, setIsUpdatingToggle] = useState(false)
  const [showToggleError, setShowToggleError] = useState(false)
                         
  // Stores questions and answers
  const [scorecardData, setScorecardData] = useState(null)
  const [questionsByCategory, setQuestionsByCategory] = useState([])

  const updateValue = (questionId, questionValue) => {
    const scorecardDataArr = scorecardData
    const quest = scorecardDataArr.filter(item => item.id === questionId)[0]

    quest.value = questionValue
    setScorecardData(scorecardDataArr)
  }

  const canSubmit = () => {
    let canSubmitBool = true
    scorecardData?.forEach(item => { if (!item?.value) canSubmitBool = false })

    return canSubmitBool
  }

  const fetchCandidateScorecard = () => {
    setIsFetchingData(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateId}/getScoreboard/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      }
    })
    .then(async response => {
      const data = await response.json()

      if (data.success) {
        
        // Classify questions by category and build options array for each
        const questionsByCat = {}

        data?.data.forEach(question => {
          const options = []
          question?.item?.values?.forEach(val => options.push({ value: val, label: val }))
          question.options = options // eslint-disable-line

          if (questionsByCat[question?.item?.category]) questionsByCat[question?.item?.category].push(question)
          else questionsByCat[question?.item?.category] = [question]
        })

        setScorecardData(data?.data)
        setQuestionsByCategory(questionsByCat)
        setIsFetchingData(false)
      }
      else {
        setIsFetchingData(false)
        setShowSpinner(false)
        
        setTimeout(() => setShowError(false), 2000)
      }
    })
    .catch(error => {
      setShowError(true)
      setTimeout(() => setShowError(false), 2000)
      console.error('There was an error!', error)
    })
  }

  const [showRequiredQuestionsError, setShowRequiredQuestionsError] = useState(false)

  const submitScorecard = () => {

    if (!canSubmit()) {
      setShowRequiredQuestionsError(true)
      setTimeout(() => setShowRequiredQuestionsError(false), 3000)
    }
    else {
      setShowSpinner(true)
  
      const requestBody = []
      scorecardData.forEach(item => {
        requestBody.push(
          { id: item.id, value: item.value }
        )
      })
  
      fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateId}/setScoreboard/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(requestBody)
      })
      .then(async response => {
        const data = await response.json()
  
        if (data.success) {
          setShowSpinner(false)
          setShowDoneIcon(true)
  
          setTimeout(() => setShowCandidateScorecardModal(false), 2000)
        }
        else {
          setShowError(true)
          setShowSpinner(false)
          
          setTimeout(() => setShowError(false), 2000)
        }
      })
      .catch(error => {
        setShowError(true)
        setTimeout(() => setShowError(false), 2000)
        console.error('There was an error!', error)
      })
    }
  }

  useEffect(() => fetchCandidateScorecard(), [])

  const isEditingInApplication = window.location.pathname.includes('/application')

  const canHideAndShowScorecard = applicationCompanyId => {
    /* Admins can edit everything */
    if (user?.type === "tecla_admin") return true
    /* Managers can only edit companies they're assigned to */
    if (user?.type === "tecla_manager" && recruiter?.companies?.filter(comp => comp?.id === applicationCompanyId)?.length) return true
    /* No one else can edit anything */
    return false
  }

  const toggleShowScorecard = async (showValue) => {
    setIsUpdatingToggle(true)

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/teclaShowScoreboard/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({ show: showValue })
      })

      const data = await res.json()

      setIsUpdatingToggle(false)

      if (data?.success) setShowScorecardToClient(showValue)

      else {
        setShowToggleError(true)
        setTimeout(() => setShowToggleError(false), 2000)
      }      
    } catch (error) {
        console.error('There was an error!', error)
        setIsUpdatingToggle(false)
        setShowToggleError(true)
        setTimeout(() => setShowToggleError(false), 2000)
    }
  }

  return (
    <Modal open={showCandidateScorecardModal} onClose={() => setShowCandidateScorecardModal(false)} >
      <SuiBox
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '100vw', md: '60vw' },
          height: { xs: '100vh', md: 'auto' },
          maxHeight: { md: '90vh' },
          bgcolor: 'background.paper',
          border: 'none',
          boxShadow: 24,
          borderRadius: { xs: 0, md: 4 },
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto'
        }}
      >
        {isFetchingData ?
          <SuiBox my={20} mx="auto"><CircularProgress size={40}/></SuiBox>
        :
          <>
            {showDoneIcon && <SuiAlert color="info">Scorecard saved!</SuiAlert>}
            {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

            <SuiTypography sx={{ mt: 2.5 }} variant="h4">Scorecard for {candidateName}</SuiTypography>
            <SuiTypography sx={{ my: .5 }} variant="body2">Rate the candidate from 1 to 5 on the following topics (Clients will see this info).</SuiTypography>

            {questionsByCategory && Object?.entries(questionsByCategory)?.map(cat => { // eslint-disable-line
              return (
                <SuiBox display="flex" flexDirection="column" alignItems="flex-start" key={cat[0]}>
                  <SuiTypography sx={{ mt: 2.5, mb: 1.5, fontSize:"1rem" }} variant="button">{cat[0]}:</SuiTypography>

                  {cat[1]?.map(question => { // eslint-disable-line
                    return (
                      <SuiBox
                        display="flex"
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems={isMobile ? "flex-start" : "center"}
                        sx={{ backgroundColor: "#f8f9fa", borderRadius: "1%", width: "100%", p: 1, my: .5 }}
                        key={question?.id}
                      >
                        <SuiTypography sx={{ ml: 2.5 }} variant="button">{question?.item?.name}</SuiTypography>
                        <SuiBox sx={{ width: isMobile ? "100%" : "65%" }}>
                          <SuiSelect
                            placeholder="Select one option (required)"
                            options={question?.options}
                            name="Score"
                            onChange={e => updateValue(question.id, e.value)}
                            isDisabled={cantEditRecords}
                            defaultValue={question?.value ?
                              scorecardData.filter(item => item.id === question.id)[0].options.filter(opt => opt.value === question.value)
                              :
                              null
                            }
                          />
                        </SuiBox>
                      </SuiBox>
                    )
                  })}
                </SuiBox>
              )
            })}

            <SuiBox
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="flex-end"
              alignItems="center"
              width="100%"
              mt={2}
            >
              <SuiTypography variant="body2" fontSize=".85rem" fontWeight="bold" color="text" mr={1}>Legend:</SuiTypography>
              <SuiTypography variant="body2" fontSize=".85rem" color="text">
                1 = Poor - 2 = Fair - 3 = Average - 4 = Good - 5 = Excellent
              </SuiTypography>
            </SuiBox>

            {/* Only show if editing in r/application and if user is recruiter admin or manager */}
            {isEditingInApplication && canHideAndShowScorecard(application?.company?.id) && (
              <SuiBox
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
                mt={2}
              >
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{ mr: 1.5 }}
                >
                  Show scorecard to client for this application?
                </SuiTypography>

                {isUpdatingToggle ?
                  <CircularProgress size={20} />
                  :
                  <Checkbox
                    sx={{ mb: .25 }}
                    checked={showScorecardToClient}
                    disabled={isUpdatingToggle}
                    onChange={() => toggleShowScorecard(!showScorecardToClient)}
                  />
                }
              </SuiBox>
            )}

            {showToggleError && (
              <SuiBox
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
                mt={1}
              >
                <SuiTypography
                  variant="button"
                  color="error"
                  fontWeight="regular"
                >
                  An error ocurred, please try again later.
                </SuiTypography>
              </SuiBox>
            )}

            <SuiBox my={2} display="flex" justifyContent="center" alignItems="center">
                <SuiTypography variant="subtitle2" display={showRequiredQuestionsError ? "block" : "none"}>
                    Please fill all the required fields before sending the form
                </SuiTypography>
            </SuiBox>

            {cantEditRecords ? 
              <SuiBox mt={5} mb={5} width='80%' mx='auto' display="flex">
                <SuiButton
                  sx={{ mx: 1 }}
                  fullWidth
                  color="info"
                  variant="outlined"
                  disabled={showSpinner || showDoneIcon || isUpdatingToggle}
                  onClick={() => setShowCandidateScorecardModal(false)}
                >
                  Close
                </SuiButton>
              </SuiBox>
              :
              <SuiBox mt={5} mb={5} width='80%' mx='auto' display="flex">
                  <SuiButton
                    sx={{ mx: 1 }}
                    fullWidth
                    color="info"
                    variant="outlined"
                    disabled={showSpinner || showDoneIcon || isUpdatingToggle}
                    onClick={() => setShowCandidateScorecardModal(false)}
                  >
                    Cancel
                  </SuiButton>

                  {/* eslint-disable-next-line */}
                  {showSpinner ?
                      <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                      :
                      showDoneIcon ?
                          <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                          :
                          <SuiButton
                            sx={{ mx: 1 }}
                            fullWidth
                            disabled={showSpinner || showDoneIcon || isUpdatingToggle}
                            color="info"
                            variant="gradient"
                            onClick={() => submitScorecard()}
                          >
                            Confirm
                          </SuiButton>
                  }
              </SuiBox>
            }
          </>
        }
      </SuiBox>
    </Modal>
  )
}

CandidateScorecardModal.defaultProps = {
  cantEditRecords: false
}


// Typechecking props for the CandidateScorecardModal
CandidateScorecardModal.propTypes = {
  showCandidateScorecardModal: PropTypes.bool.isRequired,
  setShowCandidateScorecardModal: PropTypes.func.isRequired,
  candidateName: PropTypes.string.isRequired,
  candidateId: PropTypes.number.isRequired,
  application: PropTypes.object.isRequired, // eslint-disable-line
  cantEditRecords: PropTypes.bool
};
  

export default CandidateScorecardModal