/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
// @mui material components
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import JobCard from "examples/Cards/JobCard";
import placeholder from "assets/images/illustrations/recommended.png";

function Settings() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const [loading, setLoading] = useState(true);

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    document.title = `Jobs | TECLA`;
  }, []);

  function updateJobs() {
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/recommendedJobs/`, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success && res.data) {
          const resJobs = [...res.data];
          let jobSkills = {};
          let count = 0;
          // eslint-disable-next-line
          for (const job of resJobs) {
            jobSkills = {};
            count = 0;
            // eslint-disable-next-line
            for (const requiredSkill of job.requiredSkills) {
              count += 1;
              jobSkills[`skill${count}`] = requiredSkill.skill.name;
            }
            job.requiredSkills = jobSkills;
          }
          setJobs(resJobs);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => {
    updateJobs();
  }, []);

  function getDifferenceInSeconds(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / 1000;
  }

  function timeAgo(date) {
    const currentDate = new Date();
    const seconds = Math.floor(getDifferenceInSeconds(new Date(date), currentDate));
    const mins = Math.floor(seconds / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    let timePassed = "";
    if (months >= 1) {
      timePassed = `${months}M ago`;
    } else if (days >= 1) {
      timePassed = `${days}d ago`;
    } else if (hours >= 1) {
      timePassed = `${hours}h ago`;
    } else if (mins >= 1) {
      timePassed = `${mins}m ago`;
    } else if (seconds >= 1) {
      timePassed = `${seconds}s ago`;
    }

    return timePassed;
  }

  function hotLabel(isHot) {
    if (isHot) {
      return { color: "error", label: "HOT JOB" };
    }

    return {};
  }

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <BaseLayout>
      <SuiTypography variant="h3" fontWeight="bold" ml={1} mb={3}>Invitations 💌</SuiTypography>
      
      <SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3} display="flex" justifyContent="center">
              {jobs.length ? (
                <Grid container spacing={2} xs={12}>
                  {jobs.map(job => (
                    <Grid item xs={12} lg={6} className="job-box">
                      <JobCard
                        by={{ name: job.company.name, date: timeAgo(job.createdAt) }}
                        badge={hotLabel(job.isHot)}
                        title={job.seniorityName}
                        description={job.description}
                        skills={job.requiredSkills}
                        value={{ type: "$", from: job.minSalary, to: job.maxSalary, method: "Month" }}
                        action={{ type: "internal", route: `/job?id=${job.id}`, label: "see more" }}
                        category={job.category}
                        companyPhoto={job.company.photo}
                        salaryBasedOnExperience={job?.salaryBasedOnExperience}
                        job={job}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) :
                <Grid item>
                  { loading ? (
                    <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={20}>
                      <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                    </SuiBox> 
                  ) : (
                    <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={5} width="100%" height="100%">
                      <img src={placeholder} alt="Couple of documents" width={isMobile ? "100%" : "35%"} />

                      <SuiTypography mb={2} variant="h2" textAlign="center" color="dark" fontWeight="bold">No invitations, yet!</SuiTypography>
                      
                      <SuiTypography mb={4} variant="body2" textAlign="center">
                        From time to time we will send you invitations to apply to certain jobs. Check again later.
                      </SuiTypography>
                    </SuiBox>
                  )}
                </Grid>
              }
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout >
  );
}

export default Settings;
