/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "layouts/pages/profile/recruiter-EditCandidateWizard/schemas/form";

const {
  formField: {
    email,
    firstName,
    lastName,
    phone,
    bio,
    linkedin,
    twitter,
    youtube,
    github,
    gitlab,
    stackoverflow,
    portfolio,
    website,
    facebook,
    instagram
  },
} = checkout;

export default [
  Yup.object().shape({
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [firstName.name]: Yup.string().required(firstName.errorMsg).matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Only alphabets are allowed for this field ").min(1, firstName.tooShort),
    [lastName.name]: Yup.string().required(lastName.errorMsg).matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Only alphabets are allowed for this field ").min(1, lastName.tooShort),
    [phone.name]: Yup.string().min(8, phone.invalidMsg).max(15, phone.invalidMsg).matches(/^[0-9]*$/g, "Only numbers without spaces are allowed for this field."),
    [bio.name]: Yup.string().max(500, bio.tooBig),
    [linkedin.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.linkedin.com/"'), // eslint-disable-line
    [twitter.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.twitter.com/"'), // eslint-disable-line
    [youtube.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.youtube.com/"'), // eslint-disable-line
    [github.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.github.com/"'), // eslint-disable-line
    [gitlab.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.gitlab.com/"'), // eslint-disable-line
    [stackoverflow.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.stackoverflow.com/"'), // eslint-disable-line
    [portfolio.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.portfolio.com/"'), // eslint-disable-line
    [website.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.website.com/"'), // eslint-disable-line
    [facebook.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.facebook.com/"'), // eslint-disable-line
    [instagram.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.instagram.com/"'), // eslint-disable-line
  })
];
