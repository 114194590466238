/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import PropTypes from "prop-types";
import { useState, useEffect } from "react"
import { ReactSession } from 'react-client-session'
import { useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card"
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import DataTable from "examples/Tables/DataTable"
// Data
import dataTableData from "layouts/pages/profile/candidate-applications/data/dataTableData"
import dataTableDataSmall from "layouts/pages/profile/candidate-applications/data/dataTableDataSmall"
import ActionCell from "layouts/pages/profile/candidate-applications/components/ActionCell"

let applications = {};
let applicationsSmall = {};

// { candidate }
function ProductsList() {
  ReactSession.setStoreType("localStorage");

  const token = ReactSession.get("token");
  const [applicationsData, setApplicationsData] = useState(dataTableData);
  const [applicationsDataSmall, setApplicationsDataSmall] = useState(dataTableDataSmall);
  const [fullName, setFullName] = useState("");

  applications = applicationsData;
  applicationsSmall = applicationsDataSmall;

  const { cId } = useParams();

  function removeOrWithdrawApplicationAjax(appId, type) {
    let url = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/withdrew/`;
    if (type) {
      url = `${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/remove/`;
    }
    const requestMetadata = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Token ${token}`
      },
      body: {}
    };
    fetch(url, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success && response.data) {
          console.log("removed")
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function removeApplication(jobId) {
    const functionApp = { ...applications };
    const functionSmallApp = { ...applicationsSmall };
    functionApp.rows = [];
    functionSmallApp.rows = [];
    let appIdRemoved = 0;
    // eslint-disable-next-line
    for (const applicationRow of applications.rows) {
      if (applicationRow.jobId !== jobId) {
        functionApp.rows.push(applicationRow);
        functionSmallApp.rows.push(applicationRow);
      } else {
        appIdRemoved = applicationRow.appId;
      }
    }
    setApplicationsData(functionApp);
    setApplicationsDataSmall(functionSmallApp);
    removeOrWithdrawApplicationAjax(appIdRemoved, 1);
  }

  function withdrawApplication(jobId) {
    const functionApp = { ...applications };
    const functionSmallApp = { ...applicationsSmall };
    functionApp.rows = [];
    functionSmallApp.rows = [];
    let appIdWithdraw = 0;
    // eslint-disable-next-line
    for (const applicationRow of applications.rows) {
      if (applicationRow.jobId !== jobId) {
        functionApp.rows.push(applicationRow);
        functionSmallApp.rows.push(applicationRow);
      } else {
        appIdWithdraw = applicationRow.appId;
        applicationRow.status = "withdrew";
        applicationRow.action = <ActionCell jobId={applicationRow.jobId} appId={applicationRow.id} status="withdrew" removeApplication={removeApplication} withdrawApplication={withdrawApplication} />;
        functionApp.rows.push(applicationRow);
        functionSmallApp.rows.push(applicationRow);
      }
    }
    setApplicationsData(functionApp);
    setApplicationsDataSmall(functionSmallApp);
    removeOrWithdrawApplicationAjax(appIdWithdraw, 0);
  }

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_ROUTE}/api/applications/${cId}/teclaCandidateApplications/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success && res.data) {
          const applicationsDataTemp = { ...applicationsData };
          const applicationsDataSmalltemp = { ...applicationsDataSmall };
          const restApplications = res.data.applications;
          setFullName(res.data.name);
          const applicationsArray = [];
          // eslint-disable-next-line
          for (const application of restApplications) {
            let hiringMethod = "TECLA";
            if (application.job.hiringMethod === "dh") {
              hiringMethod = "Direct Hire";
            } else if (application.job.hiringMethod === "dhp") {
              hiringMethod = "Direct Hire - Payroll";
            }
            const date = new Date(application.createdAt);
            const dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split("T")[0];

            applicationsArray.push({
              appId: application.id,
              jobId: application.job.id,
              position: application.job.seniorityName,
              method: hiringMethod,
              category: application.job.category,
              date: dateString,
              salary: application.salary,
              status: application.status,
              feedbackSent: application.feedbackSent ? <LibraryAddCheckIcon sx={{ color: "#69bc6d", ml: 2 }} fontSize="medium" /> : null,
              action: <ActionCell jobId={application.job.id} appId={application.id} status={application.status} application={application} removeApplication={removeApplication} withdrawApplication={withdrawApplication} />,
            })
          }
          applicationsDataTemp.rows = applicationsArray;
          applicationsDataSmalltemp.rows = applicationsArray;
          applications = applicationsDataTemp;
          applicationsSmall = applicationsDataSmalltemp;
          setApplicationsData(applicationsDataTemp);

          setApplicationsDataSmall(applicationsDataSmalltemp);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  useEffect(() => {
    if (fullName) {
      document.title = `applications - ${fullName} | TECLA`;
    }
  }, [fullName]);
  
  return (
    <RecruiterDashboardLayout>
      <SuiBox my={3}>

        <SuiBox mb={2}>
          {fullName && <SuiTypography variant="h3" fontWeight="bold">
            {fullName}&apos;s Applications
          </SuiTypography>}
          <SuiTypography variant="button" fontWeight="regular" color="text">
            From here you can see a list of all the applications for this candidate with their corresponding status.
          </SuiTypography>
        </SuiBox>

        <Card>
          <SuiBox sx={{ display: { xs: 'none', md: 'flex' } }}>
            <DataTable
              table={applicationsData}
              view="applications"
              canSearch
              entriesPerPage={{ defaultValue: 50 }}
            />
          </SuiBox>
          <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
            <DataTable
              table={applicationsDataSmall}
              view="applications"
              entriesPerPage={{ defaultValue: 50 }}
            />
          </SuiBox>
        </Card>
      </SuiBox>
    </RecruiterDashboardLayout>
  );
}

export default ProductsList;
