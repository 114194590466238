/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/// @mui material components
import Grid from "@mui/material/Grid"
// prop-type is a library for typechecking of props
import PropTypes from "prop-types"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiEditor from "components/SuiEditor"
import SuiButton from "components/SuiButton"


function Highlights({ formData, setActiveStep, activeStep, highlights, setHighlights, whyWorkHere, setWhyWorkHere, culture, setCulture, perks, setPerks }) {

  /* eslint-disable-next-line */
  const { formField, values, errors, touched } = formData

  const verifyHighlightsInputs = () => {
    /* if (firstNameV.length > 0 && !errors.firstName &&
        lastNameV.length > 0 && !errors.lastName &&
        emailV.length > 0 && !errors.email &&
        phoneV.length > 0 && !errors.phone &&
        bioV.length > 0 && !errors.bio) {
          setActiveStep(activeStep+1)
        }
    else showError() */
    setActiveStep(activeStep+1)
  }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={1} mb={4}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5" fontWeight="regular">This info is used to attract talent to your jobs</SuiTypography>
        </SuiBox>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container spacing={3}>

            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography variant="button">Company Highlights</SuiTypography>
              </SuiBox>
              <SuiEditor
                maxLength={500}
                defaultValue={highlights}
                name="highlights"
                onChange={setHighlights}
                placeholder="Share highlights, achievements, or milestones (i.e. raised a funding round, featured by the press, etc.) (optional - Max 500 Characters)"
                error={errors.highlights && touched.highlights}
                success={highlights.length > 0 && highlights.length <= 500 && !errors.highlights}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography variant="button">Why Work Here</SuiTypography>
              </SuiBox>
              <SuiEditor
                maxLength={500}
                defaultValue={whyWorkHere}
                name="whyWorkHere"
                onChange={setWhyWorkHere}
                placeholder="Why is your company a great place to work at? (optional - Max 500 Characters)"
                error={errors.whyWorkHere && touched.whyWorkHere}
                success={whyWorkHere.length > 0 && whyWorkHere.length <= 500 && !errors.whyWorkHere}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography variant="button">Culture</SuiTypography>
              </SuiBox>
              <SuiEditor
                maxLength={500}
                defaultValue={culture}
                name="culture"
                onChange={setCulture}
                placeholder="In a few sentences, please describe the culture of the company (optional - Max 500 Characters)"
                error={errors.culture && touched.culture}
                success={culture.length > 0  && culture.length <= 500 && !errors.culture}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography variant="button">Perks</SuiTypography>
              </SuiBox>
              <SuiEditor
                maxLength={500}
                defaultValue={perks}
                name="perks"
                onChange={setPerks}
                placeholder="In a few sentences, please describe any and all perks offered (optional - Max 500 Characters)"
                error={errors.perks && touched.perks}
                success={perks.length > 0 && perks.length <= 500 && !errors.perks}
              />
            </Grid>

        </Grid>
      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" onClick={() => setActiveStep(activeStep-1)} isSubmitting className="changeStep">Back</SuiButton>
        <SuiButton variant="gradient" color="info" onClick={() => verifyHighlightsInputs()} className="changeStep nextStep" isSubmitting>Next</SuiButton>
      </SuiBox>

    </SuiBox>
  )
}

// typechecking props for UserSignUp
Highlights.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setActiveStep: PropTypes.isRequired,
  activeStep: PropTypes.isRequired,
  highlights: PropTypes.isRequired,
  setHighlights: PropTypes.isRequired,
  whyWorkHere: PropTypes.isRequired,
  setWhyWorkHere: PropTypes.isRequired,
  culture: PropTypes.isRequired,
  setCulture: PropTypes.isRequired,
  perks: PropTypes.isRequired,
  setPerks: PropTypes.isRequired
}

export default Highlights
