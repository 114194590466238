/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types"
import { useRef, useState } from "react"
// MUI components
import TableRow from "@mui/material/TableRow"
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
import SuiBox from "components/SuiBox"
import SuiSelect from 'components/SuiSelect'
import TableCell from "layouts/pages/account/settings/components/TableCell"
import { capitalize } from "utils/commonFunctions"

function ModalTableRow({ user, showDoneIcon, setShowDoneIcon, changeUserRole, showNewRoleSpinner, showNewRoleDoneIcon, userBeingProcessed }) {

  const [showSpinner, setShowSpinner] = useState(false)
  const [newRole, setNewRole] = useState(null)

  const textAreaRef = useRef(null);

  const copyToClipboard = () => {
    setShowSpinner(true)

    textAreaRef.current.select()
    document.execCommand('copy')

    setTimeout(() => {
      setShowSpinner(false)
      setShowDoneIcon(true)
    }, 1000)

    setTimeout(() => setShowDoneIcon(false), 3000)
  }

  const formatUserType = type => {
    switch (type) {
      case 'company_owner':
        return 'Admin'
      case 'recruiter':
        return 'Recruiter'
      case 'limited_recruiter':
        return 'Limited Recruiter'
      case 'candidate':
        return 'Candidate'
      case 'tecla_recruiter':
        return 'TECLA Recruiter'
      case 'tecla_manager':
        return 'TECLA Manager'
      case 'tecla_admin':
        return 'TECLA Admin'
      default:
        return 'Recruiter'
    }
  }

  return (
    <TableRow key={user.email}>
      <TableCell smallFont align="center">{user?.first_name} {user?.last_name}</TableCell>

      <SuiBox display={{ xs: "none", sm: "contents" }}>
        <TableCell padding={[2, 0, 1, 0]} smallFont align="center">{user?.email}</TableCell>
      </SuiBox>

      <TableCell smallFont align="center">{capitalize(user?.status)}</TableCell>

      <TableCell smallFont align="center">{formatUserType(user.type)}</TableCell>

      <TableCell smallFont align="center">
        <SuiSelect
          placeholder="Company role"
          smallFont
          options={[
              { value: 'company_owner', label: 'Admin'},
              { value: 'recruiter', label: 'Recruiter'},
              { value: 'limited_recruiter', label: 'Limited Recruiter'}
          ]}
          onChange={e => setNewRole(e.value)}
        />
      </TableCell>

      <TableCell padding={[2, 0, 1, 0]} smallFont align="center">
        {/* eslint-disable-next-line */}
        {(userBeingProcessed === user.email && showNewRoleSpinner) ?
          <SuiBox display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={20} />
          </SuiBox>
          :
          (userBeingProcessed === user.email && showNewRoleDoneIcon) ?
            <SuiBox>
              <SuiTypography variant="caption">Role changed!</SuiTypography>
            </SuiBox>
            :
            <SuiButton
              color="info"
              variant="outlined"
              disabled={!newRole}
              onClick={() => changeUserRole(user, newRole)}
            >
              Change role
            </SuiButton>
        }
      </TableCell>

      <TableCell padding={[2, 0, 1, 0]} smallFont align="center">
        {/* eslint-disable-next-line */}
        {showSpinner ?
          <SuiBox display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
          :
          showDoneIcon ?
            <SuiBox>
              <SuiTypography variant="caption">Copied!</SuiTypography>
            </SuiBox>
            :
            <SuiButton color="info" variant="outlined" onClick={() => copyToClipboard()}>Copy</SuiButton>
        }
      </TableCell>
      
      {/* We use this hidden field to store the magic link and copy it */}
      <SuiBox position="absolute" top="-1000%">
        <textarea ref={textAreaRef} value={user.magic_link} readOnly />
      </SuiBox>
      
    </TableRow >
  )
}

export default ModalTableRow

// typechecking props
ModalTableRow.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line
  showDoneIcon: PropTypes.bool.isRequired,
  setShowDoneIcon: PropTypes.func.isRequired,
  changeUserRole: PropTypes.func.isRequired,
  showNewRoleSpinner: PropTypes.bool.isRequired,
  showNewRoleDoneIcon: PropTypes.bool.isRequired,
  userBeingProcessed: PropTypes.string.isRequired,
};