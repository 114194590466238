/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session'
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";


function DeleteFileModal({ showDeleteFileModal, setShowDeleteFileModal, file }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")
  const CandidateForEdit = ReactSession.get("CandidateForEdit")

  const getFileName = fileName => fileName.substring(fileName.lastIndexOf("/") + 1, fileName.length - 12)

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  const [showError, setShowError] = useState(false)

  const deleteFile = () => {
    setShowSpinner(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${file.id}/deleteCandidateContractFile/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
    .then(async response => {
      const data = await response.json()

      if (data.success) {
        setShowSpinner(false)
        setShowDoneIcon(true)

        const newCandidateForEdit = CandidateForEdit
        newCandidateForEdit.contract.files = data.data
        ReactSession.set("CandidateForEdit", newCandidateForEdit);

        setTimeout(() => {
          setShowDeleteFileModal(false)
          setShowDoneIcon(false)
          setShowDeleteFileModal(false)
        }, 2000)
      }
      else {
        setShowError(true)
        setShowSpinner(false)
        
        setTimeout(() => setShowError(false), 2000)
      }
    })
    .catch(error => {
      setShowError(true)
      console.error('There was an error!', error)
    })
  }

  return (
    <Modal open={showDeleteFileModal} onClose={() => setShowDeleteFileModal(false)}>
        <SuiBox sx={{
            width: "650px",
            height: "auto",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            borderRadius: { xs: 0, md: 4 },
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        }} >

            {showDoneIcon && <SuiAlert color="info">File deleted!</SuiAlert>}
            {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

            <SuiTypography sx={{ my: 2.5 }} variant="h4">Delete {getFileName(file.file)} file?</SuiTypography>

            <SuiTypography sx={{ my: 2.5 }} variant="body2">Are you sure to delete this file? This action can’t be undone.</SuiTypography>
            
            <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                <SuiButton
                    sx={{ mx: 1 }}
                    fullWidth
                    color="info"
                    variant="outlined"
                    disabled={showSpinner || showDoneIcon}
                    onClick={() => setShowDeleteFileModal(false)}
                >
                    Cancel
                </SuiButton>

                {/* eslint-disable-next-line */}
                {showSpinner ?
                    <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                    :
                    showDoneIcon ?
                        <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                        :
                        <SuiButton
                            sx={{ mx: 1 }}
                            fullWidth
                            disabled={showSpinner || showDoneIcon}
                            color="info"
                            variant="gradient"
                            onClick={() => deleteFile()}
                        >
                            Delete
                        </SuiButton>
                }
            </SuiBox>
        </SuiBox>
    </Modal>
  )
}

// Typechecking props for the ProfilesList
DeleteFileModal.propTypes = {
  showDeleteFileModal: PropTypes.bool.isRequired,
  setShowDeleteFileModal: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired, // eslint-disable-line
};

export default DeleteFileModal