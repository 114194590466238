/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import SendIcon from '@mui/icons-material/Send';
import Tooltip from "@mui/material/Tooltip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// utils
import { formatDate } from 'utils/commonFunctions';

function SourcingsList({ candidate, sourcings }) {

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">{candidate?.user?.first_name} {candidate?.user?.last_name}&apos;s Sourcings</SuiTypography>
      </SuiBox>

      <SuiBox p={2}>
        <SuiBox display="flex" flexDirection="row" flexWrap="wrap" p={0} mb={3}>
          <TableContainer sx={{ boxShadow: "none" }}>
            <Table aria-label="simple table">
              <TableBody>
                {sourcings.map(item => (
                  <TableRow
                    key={item.jobId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" } }}
                  >
                    <TableCell component="th" scope="row" size="small">
                      <SuiTypography variant="body2"  color="dark" width="20rem" overflowX="scroll">
                        <a href={`/r/sourcing/${item.jobId}`} style={{ color: "#8392ab" }} target="_blank" >{item.companyName} - {item.jobName}</a>
                      </SuiTypography>
                    </TableCell>

                    <TableCell align="center" size="small">
                      <SuiTypography variant="body2" color="secondary">
                        {formatDate(item.sourcingDate)}
                      </SuiTypography>
                    </TableCell>

                    <TableCell align="center" size="small">
                      <SuiTypography variant="body2" fontWeight="medium" color="secondary">
                        {item.leverStatus}
                      </SuiTypography>
                    </TableCell>

                    <TableCell align="center" size="small">
                      <SuiTypography variant="body2" fontWeight="medium" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} component="a" mr={1} href={`/job?id=${item.jobId}`} target="_blank">
                        <Tooltip title="View job" placement="top">
                          <SendIcon/>
                        </Tooltip>
                      </SuiTypography>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </SuiBox>
      </SuiBox>
    </Card >
  );
}

// Typechecking props for the SourcingsList
SourcingsList.propTypes = {
  candidate: PropTypes.object.isRequired, // eslint-disable-line
  sourcings: PropTypes.array.isRequired, // eslint-disable-line
};

export default SourcingsList;
