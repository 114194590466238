/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types"
// MUI components
import { Icon, Modal } from "@mui/material"
import CircularProgressWithSize from 'components/circularProgressWithSize/CircularProgressWithSize'
// SUI components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"

function ScoreModal({ score, openScoreModal, setOpenScoreModal }) {
    return (
        <Modal
            hideBackdrop
            open={openScoreModal}
            onClose={() => setOpenScoreModal(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <SuiBox
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: "100vw", md: "80vw", xl: "60vw" },
                    height: { xs: "100vh", md: "auto" },
                    maxHeight: "80%",
                    overflowY: "scroll",
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <div></div>
                    <Icon onClick={() => setOpenScoreModal(false)} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                </SuiBox>

                <SuiBox borderRadius="16px" height="100%" my={2} sx={{ display: "block", maxHeight: "500px", overflow: "auto", textAlign: "center" }}>
                    <SuiBox mb={2}>
                        <SuiTypography variant="h5" ml={1.5} color="dark" sx={{ margin: "0 auto" }}>Test Score</SuiTypography> 
                    </SuiBox>
                    <CircularProgressWithSize value={score} />
                </SuiBox>

            </SuiBox>
        </Modal>
    )
}

ScoreModal.propTypes = {
  score: PropTypes.number.isRequired,
  openScoreModal: PropTypes.bool.isRequired,
  setOpenScoreModal: PropTypes.func.isRequired,
}

export default ScoreModal