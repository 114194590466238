/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
import { useState, useEffect } from "react"
// @mui material components
import Card from "@mui/material/Card"
// import Tooltip from '@mui/material/Tooltip';
// Utils
import { formatDate } from 'utils/commonFunctions';
// Soft UI Dashboard PRO React components
import { Icon } from "@mui/material";
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import CircularProgress from '@mui/material/CircularProgress'
import SuiBadge from "components/SuiBadge";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DataTable from "examples/Tables/DataTable"
// Data
import dataTableData from "layouts/pages/profile/invoices/data/dataTableData"
import dataTableDataSmall from "layouts/pages/profile/invoices/data/dataTableDataSmall"
import ActionCell from "layouts/pages/profile/invoices/components/ActionCell"

import NewInvoice from "layouts/pages/managersAddToTeam/modals/newInvoice";

function ProductsList() {
  ReactSession.setStoreType("localStorage");

  const token = ReactSession.get("token");
  const [invoicesData, setApplicationsData] = useState(dataTableData);
  const [invoicesDataSmall, setApplicationsDataSmall] = useState(dataTableDataSmall);
  const [loadInvoices, setLoadInvoices] = useState(0);

  const [openInvoice, setOpenInvoice] = useState(false);
  // const [files, setFiles] = useState([]);
  const handleOpenInvoice = () => setOpenInvoice(true);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = `Invoices | TECLA`;
  }, []);

  const formatStatus = code => {
    switch (code) {
      case "pending-approval":
        return "Pending Approval"
      case "approved-by-tecla":
        return "Pending Approval"
      case "approved-by-client":
        return "Approved"
      case "in-progress":
        return "In Progress"
      case "declined-by-tecla":
        return "Declined"
      case "declined-by-client":
        return "Declined"
      case "paid":
        return "Paid"
      default:
        return ""
    }
  }

  const formatStatusColor = code => {
    switch (code) {
      case "pending-approval":
        return "secondary"
      case "approved-by-tecla":
        return "secondary"
      case "approved-by-client":
        return "info"
      case "in-progress":
        return "secondary"
      case "declined-by-tecla":
        return "error"
      case "declined-by-client":
        return "error"
      case "paid":
        return "success"
      default:
        return "secondary"
    }
  }

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/getInvoicesCandidate/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success && res.data && res.data.invoices) {
          setIsLoading(false)
          const invoicesDataTemp = { ...invoicesData };
          const invoicesDataSmalltemp = { ...invoicesDataSmall };
          const invoicesArray = [];
          // const filesArray = [];
          // eslint-disable-next-line
          for (const invoice of res.data.invoices) {
            console.log(formatStatusColor(invoice.status))
            invoicesArray.push({
              date: formatDate(invoice.createdAt),
              description: invoice.description,
              paymentDate: invoice.paidDate ? formatDate(invoice.paidDate) : "Not yet paid",
              status: <SuiBadge color={formatStatusColor(invoice.status)} badgeContent={formatStatus(invoice.status)} size="lg" variant="contained" container />,
              notes: invoice.declineNote,
              action: <ActionCell cvUrl={`${process.env.REACT_APP_API_ROUTE}${invoice.file}`} />,
            })
            // filesArray.push(`${process.env.REACT_APP_API_ROUTE}${invoice.file}`);
          }
          // if (filesArray.length) {
          //   setFiles(filesArray)
          // }
          invoicesDataTemp.rows = invoicesArray;
          invoicesDataSmalltemp.rows = invoicesArray;
          setApplicationsData(invoicesDataTemp);
          setApplicationsDataSmall(invoicesDataSmalltemp);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, [loadInvoices]);

  return (
    <DashboardLayout>
      <SuiBox my={3}>
        <SuiBox display="flex" flexWrap="wrap" width="100%" alignItems="center" justifyContent={{ sx: "start", xl: "space-between" }} my={2}>
          <SuiBox lineHeight={1}>
            <SuiTypography variant="h3" fontWeight="bold">Invoices</SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              From this page you can view your past invoices or create new ones.
            </SuiTypography>
          </SuiBox>
          <SuiButton size="medium" color="info" variant="outlined" circular className="magic" onClick={handleOpenInvoice} sx={{ width: { xs: "100%", sm: "auto" }, marginRight: { xs: 0, sm: 1 } }}>
            <Icon>
              add
            </Icon>
            &nbsp; New Inoice
          </SuiButton>
          <NewInvoice openInvoice={openInvoice} setOpenInvoice={setOpenInvoice} loadInvoices={loadInvoices} setLoadInvoices={setLoadInvoices} />
        </SuiBox>
        
        <Card>
          {isLoading ?
            <SuiBox display="flex" justifyContent="center" alignItems="center" my={30} >
              <CircularProgress size={40} />
            </SuiBox>
          :
            <>
              <SuiBox sx={{ display: { xs: 'none', md: 'flex', minHeight: "60vh" } }}>
                <DataTable view="invoices" table={invoicesData} />
              </SuiBox>
              <SuiBox sx={{ display: { xs: 'flex ', md: 'none' } }}>
                <DataTable view="invoices" table={invoicesDataSmall} />
              </SuiBox>
            </>
          }
        </Card>

      </SuiBox>
    </DashboardLayout>
  );
}

export default ProductsList;
