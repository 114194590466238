/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import UserGlobe from "examples/RecruiterGlobe";

function ManagersCell({ managers }) {

  return (
    <SuiBox>
      <SuiBox display="flex" alignItems="center" pr={2}>
        {managers?.map(manager => (
          <UserGlobe userItem={manager} />
        ))}
      </SuiBox>
    </SuiBox>
  );
}

ManagersCell.defaultProps = {
  managers: [],
};

// Typechecking props for the ManagersCell
ManagersCell.propTypes = {
  managers: PropTypes.array, // eslint-disable-line
};

export default ManagersCell;
