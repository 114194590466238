/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: "cv-builder-form",
  formField: {
    firstName: {
      name: "firstName",
      type: "text",
      placeholder: "First name (required)",
      errorMsg: "First name is required.",
      tooShort: "Your first name should be more than 1 character.",
    },
    lastName: {
      name: "lastName",
      type: "text",
      placeholder: "Last name (required)",
      errorMsg: "Last name is required.",
      tooShort: "Your last name should be more than 1 character.",
    },
    email: {
      name: "email",
      type: "email",
      placeholder: "Email address (required)",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    phone: {
      name: "phone",
      label: "Phone Number",
      type: "tel",
      placeholder: "Phone Number (required)",
      errorMsg: "Phone Number is required.",
      invalidMsg: "Phone number is not valid.",
    },
    linkedin: {
      name: "linkedin",
      type: "text",
      label: "Linkedin Profile",
      placeholder: "LinkedIn Profile (optional)"
    },
    bio: {
      name: "bio",
      label: "bio",
      type: "textarea",
      placeholder: "Say a few words about who you are or what you're working on (required).",
      tooSmall: "Your bio cannot have less than 200 characters.",
      tooBig: "Your bio cannot have more than 500 characters.",
    },
  },
};
