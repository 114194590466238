/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import Tooltip from "@mui/material/Tooltip";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiProgress from "components/SuiProgress";
import SuiBadge from "components/SuiBadge";

import CheckBoxIcon from '@mui/icons-material/CheckBox';

function ProfilesList({ title, profiles, comment }) {

  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("user");
  const isRecruiter = (user && (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin"));

  const convertPercentage = percentage => {
    const newNum = `${parseInt(percentage, 10)}`
    if (newNum === "0") return newNum
    if (newNum === "100") return "10"
    return newNum.replace("0", "")
  }

  const renderProfiles = profiles.map(({ name, percent, color, value, englishValidator }) => (
    <SuiBox key={name} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <SuiBox
        component="ul"
        display="flex"
        flexDirection="column"
        width="100%"
        p={0}
        m={0}
        sx={{ listStyle: "none" }}
      >
        <SuiBox component="li" w="100%" py={0.25}>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
            <SuiBox>
              <SuiBadge badgeContent={name} color={color} variant="contained" circular size="sm" container />
              { englishValidator && isRecruiter ? (
                <Tooltip title={`Validated by ${englishValidator.user.first_name} ${englishValidator.user.last_name}`} placement="top">
                  <CheckBoxIcon fontSize="small" color="success" sx={{ verticalAlign: "middle", marginLeft: "5px" }} />
                </Tooltip>
              ) : null }
              { englishValidator && !isRecruiter ? (
                <Tooltip title="Validated by our team. To change this please request an interview" placement="top">
                  <CheckBoxIcon fontSize="small" color="success" sx={{ verticalAlign: "middle", marginLeft: "5px" }} />
                </Tooltip>
              ) : null }
            </SuiBox>
            <SuiTypography
              variant="button"
              fontWeight="medium"
              color="text"
              textTransform="capitalize"
            >
              {name === "Spoken english" ? `${convertPercentage(percent)}/10` : percent}
            </SuiTypography>
          </SuiBox>
          <SuiProgress variant="gradient" color="info" value={value} />
          <SuiTypography variant="caption" fontWeight="regular" color="text" ml={0}>
            {comment}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  ));

  return (
    <Card sx={{ maxHeight: "44vh", overflow: "auto" }}>
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2} max >
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

ProfilesList.defaultProps = {
  comment: ""
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  comment: PropTypes.string
};

export default ProfilesList;
