/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AssignmentIcon from '@mui/icons-material/Assignment';
import IconButton from '@mui/material/IconButton';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import ApproveInvoicePaymentModal from "layouts/pages/managersAddToTeam/modals/approveInvoicePaymentModal";
import DeclineInvoiceModal from "layouts/pages/managersAddToTeam/modals/declineInvoiceModal";


function actionCell({ invoiceId, invoiceUrl, updateInvoices, setUpdateInvoices, status }) {

	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

    const [showApprovePaymentModal, setShowApprovePaymentModal] = useState(false)
	const [showDeclineInvoiceModal, setShowDeclineInvoiceModal] = useState(false)

	const handleClick = event => setAnchorEl(event.currentTarget)
	const handleApprovePayment = () => {
		setShowApprovePaymentModal(true)
        setAnchorEl(null)
    };

    const handleDeclineInvoice = () => {
		setShowDeclineInvoiceModal(true)
        setAnchorEl(null)
    };

    const handleClose = () => setAnchorEl(null)


	return (
		<SuiBox display="flex" alignItems="center">
			<SuiBox display="flex" alignItems="center" justifyContent="center" flexDirection="column">
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} component="a" mr={1} href={`${process.env.REACT_APP_API_ROUTE}${invoiceUrl}`} target="_blank">
					<Tooltip title="View invoice" placement="top">
						<AssignmentIcon/>
					</Tooltip>
				</SuiTypography>
			</SuiBox>
			{ status !== "declined-by-tecla" && status !== "declined-by-client" && status !== "approved-by-client" ? (
				<SuiBox display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<IconButton
						aria-label="more"
						id="long-button"
						aria-controls={open ? 'long-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-haspopup="true"
						onClick={handleClick}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-menu"
						MenuListProps={{
							'aria-labelledby': 'long-button',
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
					>
						<MenuItem className="menuActive" onClick={handleApprovePayment}>Approve for payment</MenuItem>
						<MenuItem className="menuActive" onClick={handleDeclineInvoice}>Decline</MenuItem>
					</Menu>
				</SuiBox>
			) : null }
			{/* Modals */}
			<>
				{showApprovePaymentModal && (
					<ApproveInvoicePaymentModal showApproveInvoicePaymentModal={showApprovePaymentModal} setShowApproveInvoicePaymentModal={setShowApprovePaymentModal} invoiceId={invoiceId} updateInvoices={updateInvoices} setUpdateInvoices={setUpdateInvoices} />
				)}

				{showDeclineInvoiceModal && (
					<DeclineInvoiceModal showDeclineInvoiceModal={showDeclineInvoiceModal} setShowDeclineInvoiceModal={setShowDeclineInvoiceModal} invoiceId={invoiceId} updateInvoices={updateInvoices} setUpdateInvoices={setUpdateInvoices} />
				)}
			</>
		</SuiBox>
	);
}

// Setting default values
actionCell.defaultProps = {
  invoiceId: 0,
  invoiceUrl: "",
  status: "",
  updateInvoices: 0,
  setUpdateInvoices: null
};

// Typechecking props for the actionCell
actionCell.propTypes = {
	invoiceId: PropTypes.number,
	invoiceUrl: PropTypes.string,
	updateInvoices: PropTypes.string,
	setUpdateInvoices: PropTypes.objectOf(PropTypes.any),
	status: PropTypes.string,
};

export default actionCell;
