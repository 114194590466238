/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { validateToken, formatDate } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
// MUI components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from "@mui/material"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import SendIcon from '@mui/icons-material/Send';
import Tooltip from "@mui/material/Tooltip";

function SourcingsModal({ openSourcingsModal, setOpenSourcingsModal, candidateId, candidateName }) {

    const token = ReactSession.get("token")
        
    const [showSpinner, setShowSpinner] = useState(false)
    const [showError, setShowError] = useState(false)

    const [sourcings, setSourcings] = useState([])

    const fetchSourcings = () => {
        setShowSpinner(true)

        fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateId}/teclaGetCandidateSourcings/`, {
            headers: { "Authorization": `Token ${token}` }
        })
            .then(async response => {
                const data = await response.json()

                console.log('data', data)

                if (data.success) {
                    setSourcings(data.data)
                    setShowSpinner(false)
                }
            })
            .catch(error => {
                console.error('There was an error!', error)
                setShowSpinner(false)
                setShowError(true)

                setTimeout(() => setShowError(false), 3000)
            })
    }

    useEffect(() => fetchSourcings(), [])

    const isMobile = useMediaQuery('(max-width:500px)')
            
    return (
        <Modal open={openSourcingsModal} onClose={() => setOpenSourcingsModal(false)}>
            <SuiBox sx={{
                width: isMobile ? "90%" : "750px",
                height: isMobile ? "90%" : "auto",
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: 'none',
                boxShadow: 24,
                borderRadius: { xs: 0, md: 4 },
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }} >
                {/* eslint-disable-next-line */}
                {showSpinner ?
                    <SuiBox my={15} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={40} /></SuiBox>
                    :
                    showError ? <SuiAlert color="error">There was an error, try again later</SuiAlert>
                    :
                    <>
                        <SuiBox pt={2} px={2}>
                            <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">{candidateName}&apos;s sourcings</SuiTypography>
                        </SuiBox>

                        <SuiBox p={2}>
                            <SuiBox display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap" p={0} mb={3}>
                                <TableContainer sx={{ boxShadow: "none" }} >
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            {sourcings.length > 0 && sourcings.map(item => (
                                                <TableRow
                                                    key={item.jobId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" } }}
                                                >
                                                    <TableCell component="th" scope="row" size="small">
                                                        <SuiTypography variant="body2"  color="dark" width="20rem" overflowX="scroll">
                                                            <a href={`/r/sourcing/${item.jobId}`} style={{ color: "#8392ab" }} target="_blank" >
                                                                {item.companyName} - {item.jobName}
                                                            </a>
                                                        </SuiTypography>
                                                    </TableCell>

                                                    <TableCell align="center" size="small">
                                                        <SuiTypography variant="body2" color="secondary" minWidth="6rem">
                                                            {formatDate(item.sourcingDate)}
                                                        </SuiTypography>
                                                    </TableCell>

                                                    <TableCell align="center" size="small">
                                                        <SuiTypography variant="body2" fontWeight="medium" color="secondary" minWidth="6rem">
                                                            {item.leverStatus}
                                                        </SuiTypography>
                                                    </TableCell>

                                                    <TableCell align="center" size="small">
                                                        <SuiTypography variant="body2" fontWeight="medium" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} component="a" mr={1} href={`/job?id=${item.jobId}`} target="_blank">
                                                            <Tooltip title="View job" placement="top">
                                                                <SendIcon/>
                                                            </Tooltip>
                                                        </SuiTypography>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </SuiBox>
                        </SuiBox>
                    </>
                }                
            </SuiBox>
        </Modal>
    )
}

export default SourcingsModal

// typechecking props
SourcingsModal.propTypes = {
    openSourcingsModal: PropTypes.func.isRequired,
    setOpenSourcingsModal: PropTypes.func.isRequired,
    candidateId: PropTypes.number.isRequired,
    candidateName: PropTypes.string.isRequired,
};