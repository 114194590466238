/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useRef, useState, useEffect } from "react"
import Swal from "sweetalert2"
import { ReactSession } from "react-client-session"
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiInput from "components/SuiInput"

import SuiButton from "components/SuiButton"
import SuiSelect from "components/SuiSelect"
// Soft UI Dashboard PRO React example components
import CompanyDashboardLayout from "examples/LayoutContainers/CompanyDashboardLayout"


function companySupport() {

  const token = ReactSession.get("token")

  useEffect(() => {
    document.title = `Support | TECLA`;
  }, []);

  const subjectOptions = [
    { value: "general", label: "General" },
    { value: "scheduling", label: "Scheduling" },
    { value: "technical-support", label: "Technical Support" },
    { value: "bug-report", label: "Bug Report" }
  ]

  const showAlert = () => Swal.fire("Support request sent.", "We will contact you back as soon as possible.", "success")

  const [subject, setSubject] = useState("")
  const [description, setDescription] = useState("")

  const subjectRef = useRef()
  const descriptionRef = useRef()

  const sendSupportRequest = async () => {

    const reqHeaders = { 'Authorization': `Token ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' }
    const reqBody = { subject, description }

    await fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/supportRequest/`, {
      method: 'POST',
      headers: reqHeaders,
      body: new URLSearchParams(reqBody)
    })
      .then(async response => {
        const data = await response.json()
        if (data.success) {
          showAlert()
          $("input[name=subject]")[0].value = ""
          $("textarea[name=description]")[0].value = ""
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  return (
    <CompanyDashboardLayout>
      <SuiBox mt={3} mb={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <Card sx={{ overflow: "visible" }}>
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h4" fontWeight="medium">Support</SuiTypography>

                <Divider />
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">Subject</SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    placeholder="Please select an option"
                    label="subject"
                    options={subjectOptions}
                    name="subject"
                    onChange={e => setSubject(e.value)}
                    ref={subjectRef}
                  />
                </SuiBox>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">Issue Description</SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="regular" color="text" >Please describe the issue you are having in as much detail as possible.</SuiTypography>
                  </SuiBox>
                  <SuiInput
                    placeholder="Please describe your issue..."
                    label="description"
                    name="description"
                    ref={descriptionRef}
                    multiline
                    rows={5}
                    onChange={e => setDescription(e.target.value)}
                    inputProps={{ minLength: 10, maxLength: 500 }}
                  />
                </SuiBox>

                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiButton variant="gradient" color="info" disabled={!subject || description.length < 10} onClick={() => sendSupportRequest()}>Send support request</SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </CompanyDashboardLayout>
  )
}

export default companySupport
