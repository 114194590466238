/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
import NameAndPictureCell from "layouts/pages/jobFeedback/components/NameAndPictureCell/nameAndPictureCell"
import FeedbackReasonCell from "layouts/pages/jobFeedback/components/FeedbackReasonCell/feedbackReasonCell"

export default {
  columns: [
    {
      Header: "Candidate",
      accessor: "candidate",
      Cell: ({ value: [name, data, feedbackId] }) => <NameAndPictureCell image={data} name={name} feedbackId={feedbackId} />,
    },
    {
      Header: "Feedback",
      accessor: "feedback",
      Cell: ({ value: [reason, feedback] }) => <FeedbackReasonCell reason={reason} feedback={feedback} />,
    },
  ],

  rows: [],
};
