/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { useParams } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiBadge from "components/SuiBadge";
import SuiPagination from "components/SuiPagination";
import SuiTypography from "components/SuiTypography";
// MUI components
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SendIcon from '@mui/icons-material/Send';
// import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
// import DashboardNavbar from "examples/Navbars/FilterNavbar";
import TalentFinder from 'examples/TalentFinder';
// Data
import Listas from "layouts/pages/suggested/components/ApplicationLists";
import dataTableData from "layouts/pages/suggested/data/dataTableData";
import dataTableDataSmall from "layouts/pages/suggested/data/sm/dataTableDataSmall";
// import ActionCell from "layouts/pages/suggested/components/ActionCell";
import ProductCell from "layouts/ecommerce/overview/components/ProductCell";
import mockCandidateAvatar from "assets/images/mockCandidateAvatar.png";
import Swal from "sweetalert2";

function Suggested() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const recruiter = ReactSession.get("recruiter");

  const recruiterFolders = ReactSession.get("recruiterFolders");
  let candidatesViewedList = ReactSession.get("candidatesViewed");
  if (!candidatesViewedList) {
    candidatesViewedList = [];
  }

  useEffect(() => {
    document.title = `Suggested Candidates | TECLA`;
  }, []);

  const { jId } = useParams();

  const [applicationsData, setApplicationsData] = useState(dataTableData);
  const [applicationsDataSmall, setApplicationsDataSmall] = useState(dataTableDataSmall);

  const apiUrl = process.env.REACT_APP_API_ROUTE;

  const [pagination, setPagination] = useState({ pages: [] });
  const [totalResults, setTotalResults] = useState(null);

  const [isFetchingData, setIsFetchingData] = useState(false)

  const [job, setJob] = useState(ReactSession.get("sourcingJob")) // eslint-disable-line

  const [skills, setSkills] = useState([]);
  const [countries, setCountries] = useState([]);
  const [search, setSearch] = useState(0);
  const [candidateChecked, setCandidateChecked] = useState(0);
  const [filters, setFilters] = useState({
    firstName: "",
    email: "",
    skills: [],
    skillsInCV: [],
    countries: [],
    withCV: false,
    withVideo: false,
    active: false,
    excludes: "",
    page: 1,
    searchKeyWords: "",
    keywordsSearch: false
  });

  function setPage(value) {
    const filtersCopy = {...filters};
    filtersCopy.page = value;
    setFilters(filtersCopy);
    setSearch(search + 1);
  }

  function setSourcered(candidateId) {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/sourcings/createSourcing/`;
    const postBody = {
        'job': Number(jId),
        'candidate': Number(candidateId),
    };

    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': `Token ${token}`
        },
        body: new URLSearchParams(postBody)
    };

    fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
            if (response.success) {
                Swal.fire("Candidate sourced succesfully.", "", "success");
                $(`.cancidateCheck${candidateId}`).parent().html($(".exampleBoxIcon").html())
            } else {
              Swal.fire("This candidate was already sourced for this job.", "", "error");
            }
        });
  }

  function setRated(candidateId, rated) {
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${job?.id}/setSuggestedRating/`;
    const postBody = {
        'candidate': Number(candidateId),
        'rating': rated ? 1 : 0,
        'text': "",
    };

    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': `Token ${token}`
        },
        body: new URLSearchParams(postBody)
    };

    fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
            if (response.success) {
                $(`.rateBox${candidateId}`).removeClass("downRated upRated").addClass(rated ? "upRated" : "downRated");
            }
        });
  }

  function buildPagination(paginationData) {
    const funcPagination = {...paginationData};
    funcPagination.hasPrev = true;
    funcPagination.hasNext = true;
    if (funcPagination.current === 1 || funcPagination.countPages === 1) {
      funcPagination.hasPrev = false;
    }

    if (funcPagination.current === funcPagination.countPages) {
      funcPagination.hasNext = false;
    }

    if (funcPagination.countPages >= 5) {
      if (funcPagination.current === 1 || funcPagination.current === 2) {
        funcPagination.pages = [1, 2, 3, 4, 5];
      } else {
        funcPagination.pages = [funcPagination.current - 2, funcPagination.current - 1, funcPagination.current];
        if (funcPagination.countPages >= funcPagination.current + 1) {
          funcPagination.pages.push(funcPagination.current + 1);
        }
        if (funcPagination.countPages >= funcPagination.current + 2) {
          funcPagination.pages.push(funcPagination.current + 2);
        }
      }
    } else {
      funcPagination.pages = [];
      // eslint-disable-next-line
      for (let i = 0; i < funcPagination.countPages; i++) {
        funcPagination.pages.push(i + 1);
      }
    }

    funcPagination.pagesHtml = [];
    if (funcPagination.pages.length > 1) {
      // eslint-disable-next-line
      for (let i = 0; i < funcPagination.pages.length; i++) {
        if (funcPagination.pages[i] === funcPagination.current) {
          funcPagination.pagesHtml.push(<SuiPagination className="pageReload" item active> {funcPagination.pages[i]} </SuiPagination>)
        } else {
          funcPagination.pagesHtml.push(<SuiPagination className="pageReload" item onClick={() => setPage(funcPagination.pages[i])}> {funcPagination.pages[i]} </SuiPagination>)
        }
      }
    }

    return funcPagination;
  }

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  function buildCandidatesTable(responseData) {
    const applicationsDataTemp = { ...applicationsData };
    const applicationsDataSmalltemp = { ...applicationsDataSmall };
    const candidatesArray = [];
    // eslint-disable-next-line
    for (const candidate of responseData.candidates) {
      const fullName = candidate.user ? `${candidate.user.first_name} ${candidate.user.last_name}` : "";
      let referalImg = mockCandidateAvatar
      if (candidate.user.photo) {
        referalImg = `${apiUrl}${candidate.user.photo}`;
      }
      const country = candidate.country ? candidate.country.name : "";
      const candidateSkills = [];
      let count = 0;
      let allSkills = "";
      // eslint-disable-next-line
      for (const skill of candidate.skills) {
        allSkills = `${allSkills} ${skill.skill.name}(${skill.level ? skill.level : 1}),`;
      }
      allSkills = allSkills.length ? allSkills.substr(0, allSkills.length - 1) : allSkills;
      let allSkillsInCv = "";
      // eslint-disable-next-line
      for (const skill of candidate.skillsCountInCV) {
        allSkillsInCv = `${allSkillsInCv} ${skill.skillCount},`;
      }
      allSkillsInCv = allSkillsInCv.length ? allSkillsInCv.substr(0, allSkillsInCv.length - 1) : allSkillsInCv;

      // eslint-disable-next-line
      for (const skill of candidate.skillsToShow) {
        if (count < 5) {
          candidateSkills.push(<span><CustomWidthTooltip title={<div style={{ textAlign: "left" }}><b>Skills in profile: </b>{allSkills} <br/><br/><b>Skills in CV: </b>{allSkillsInCv}</div>} placement="bottom"><SuiBadge badgeContent={skill} color="info" variant="contained" size="lg" /></CustomWidthTooltip></span>);
        }
        count += 1;
      }
      const candidateUrl = `/r/profile/${candidate.user.id}`;
      let isViewed = false;
      if (candidatesViewedList.includes(candidate.user.id)) {
        isViewed = true;
      }
      const candidateAction = <SuiBox><Tooltip title="Source" placement="top"><SendIcon onClick={() => setSourcered(candidate.user.id)} style={{ width: "30px", height: "30px", cursor: "pointer" }} /></Tooltip></SuiBox>;
      let candidateSourcedHtml = <CheckBoxOutlineBlankIcon className={`cancidateCheck${candidate.user.id}`} style={{ width: "30px", height: "30px" }}/>
      if (candidate.sourced) {
        candidateSourcedHtml = <CheckBoxIcon style={{ width: "30px", height: "30px" }}/>
      }
      let rated = "";
      if (candidate.rating) {
        rated = candidate.rating.rating ? "upRated" : "downRated";
      }
      candidatesArray.push({
        sourced: candidateSourcedHtml,
        candidate: <ProductCell image={referalImg} name={fullName} url={candidateUrl} candidateId={candidate.user.id} isViewed={isViewed} title={candidate.title} />,
        country: country,
        skills: candidateSkills,
        action: candidateAction,
        candidateData: candidate,
        referalImg: referalImg,
        fullName: fullName,
        candidateUrl: candidateUrl,
        candidateId: candidate.user.id,
        rate: { "candidateId": candidate.user.id, "setRated": setRated, "rated": rated },
        title: candidate.title,
      });
    }
    
    applicationsDataTemp.rows = candidatesArray;
    applicationsDataSmalltemp.rows = candidatesArray;
    setApplicationsData(applicationsDataTemp);
    setApplicationsDataSmall(applicationsDataSmalltemp);

    // Pagination
    const resPagination = buildPagination({ ...responseData.pagination });
    setPagination(resPagination);
    if (responseData.pagination.count !== totalResults) setTotalResults(responseData.pagination.count)

    if (job) {
      setIsFetchingData(false)
    }
    const filtersCopy = { ...filters };
    filtersCopy.keywordsSearch = false;
    setFilters(filtersCopy);
  }

  function reloadCandidates() {
    setIsFetchingData(true)

    let url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${jId}/getSuggestedCandidates/`
    const headers = { "Content-Type": "application/x-www-form-urlencoded" }
    if (token) headers.Authorization = `Token ${token}`

    const reqFilters = { page: filters.page };
    let skillsFilter = ``;
    let countriesFilter = ``;
    if (filters.keywordsSearch) {
      url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/teclaCandidatesFindInCV/`
      reqFilters.words = $("input[name=searchInCv]").val();
    } else {
      if (filters.firstName) reqFilters.name = filters.firstName
      if (filters.email) reqFilters.email = filters.email
      if (filters.excludes) reqFilters.excludes = filters.excludes
      if (filters.skills.length) {
        // eslint-disable-next-line
        for (const skill of filters.skills) {
          skillsFilter = `${skillsFilter}&skills=${skill}`
        }
      }
      if (filters.skillsInCV.length) {
        // eslint-disable-next-line
        for (const skill of filters.skillsInCV) {
          skillsFilter = `${skillsFilter}&skillsInCV=${skill}`
        }
      }
      if (filters.countries.length) {
        // eslint-disable-next-line
        for (const country of filters.countries) {
          countriesFilter = `${countriesFilter}&countries=${country}`
        }
      }
    }

    const data = new URLSearchParams(reqFilters);
    fetch(`${url}?${data}`, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line

        if (res.success && res.data && res.data.candidates) {
          const responseData = {...res.data};
          buildCandidatesTable(responseData);
          if (responseData.job) {
            setJob(responseData.job);
            ReactSession.set("sourcingJob", responseData.job)
          }
          setTimeout(() => { setCandidateChecked(candidateChecked + 1) }, "3000")
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => { reloadCandidates() }, [search]);

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateSkills = async () => {
    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = [];
    // eslint-disable-next-line
    for (let i = 0; i < interests.data?.skills?.length; i++) {
      newData.push({ value: interests.data?.skills[i]?.id, label: interests.data?.skills[i]?.name });
    }
    setSkills(newData);
  }

  useEffect(() => {
    populateSkills()

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/jobCountries/`)
      .then(async response => {
        const data = await response.json();
        const newData = [];
        // const countryTags = [];
        // eslint-disable-next-line
        Object.keys(data.data.countries).forEach(function (key) {
          newData.push({ value: data.data.countries[key].id, label: data.data.countries[key].name });
          // countryTags.push(data.data.countries[key].name);
        });
        $(".countryBox").attr("data-countries", JSON.stringify(newData));
        setCountries(newData);
        // if (setTags) {
        //   setSkillLevels(countryTags);
        // }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  const fetchFolders = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/getFoldersName/`, {
      method: 'GET',
      headers: { "Authorization": `Token ${token}` }
    })
    .then(async response => {
      const data = await response.json()
      if (data.success && (!recruiterFolders || !Array.isArray(recruiterFolders))) ReactSession.set("recruiterFolders", data.data)
    })
    .catch(error => console.error('There was an error!', error))
  }

  const fetchClientFolders = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/getFolderClients/`, {
      method: 'GET',
      headers: { "Authorization": `Token ${token}` }
    })
      .then(async response => {
        const data = await response.json()
        if (data.success) ReactSession.set("clientFolders", data.data)
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => {
    fetchFolders()
    if (recruiter?.canManageClientFolders) fetchClientFolders()
  }, [])
  
  return (
    <RecruiterDashboardLayout>
      <SuiTypography variant="h3" fontWeight="bold" mr={5}>
        Suggested candidates for {job?.company?.name} {job?.name}
      </SuiTypography>
      <SuiBox className="exampleBoxIcon" sx={{ display: "none" }}>
        <CheckBoxIcon style={{ width: "30px", height: "30px" }}/>
      </SuiBox>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TalentFinder 
            skills={skills} 
            skillFilterText="" 
            countries={countries} 
            setCountries={setCountries} 
            filters={filters} 
            setFilters={setFilters} 
            search={search} 
            setSearch={setSearch}
            setPage={setPage}  
          />
        </Grid>

        <Grid item xs={12}>
          <Listas
            applicationsData={applicationsData}
            applicationsDataSmall={applicationsDataSmall}
            pagination={pagination}
            setPage={setPage}
            filters={filters}
            totalResults={totalResults}
            isFetchingData={isFetchingData}
          />
        </Grid>
      </Grid>
    </RecruiterDashboardLayout>
  );
}

export default Suggested;
