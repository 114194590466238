/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types' // eslint-disable-line
import { useEffect } from 'react'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'

function StripeCancel() {

    useEffect(() => {
        setTimeout(() => { window.location.href = '/t/dashboard/levelUp' }, 2500)
    }, [])

    return (
        <SuiBox display='flex' justifyContent='center' alignItems='center'>
            <SuiTypography mt='20%'>Payment processing failed, please, try again later.</SuiTypography>
        </SuiBox>
    )
}

// Typechecking props for the StripeCancel
StripeCancel.propTypes = {
}
  
export default StripeCancel