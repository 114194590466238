/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useRef } from "react"
// react-quill components
import ReactQuill from "react-quill"
// react-quill styles
import "react-quill/dist/quill.snow.css"
// Custom styles for the SuiEditor
import SuiEditorRoot from "components/SuiEditor/SuiEditorRoot"

function SuiEditor(props) {
  const quilRef = useRef()
  // eslint-disable-next-line 
  const checkCharacterCount = event => { if (quilRef?.current?.props?.defaultValue?.length > props?.maxLength) event?.preventDefault() }

  return (
    <SuiEditorRoot>
      <ReactQuill theme="snow" {...props} onKeyDown={e => {
        // eslint-disable-next-line
        if (props.maxLength) checkCharacterCount(e)
      }} ref={quilRef} />
    </SuiEditorRoot>
  )
}

export default SuiEditor
