/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// react-router-dom components
import { useParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
// Soft UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";


function Validated() {
  const { code } = useParams();
  const [responseMsg, setResponseMsg] = useState(1);
  ReactSession.setStoreType("localStorage");

  function getNotificationCount(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/users/getNotificationsCount/`, { headers, })
      .then(async response => {
        const data = await response.json();
        // console.log(data.data.countries)
        if (data.success) {
          ReactSession.set("notificationsCount", data.data);
        }
        window.location.replace("/r/dashboard");
      })
      .catch(error => {
        console.error('There was an error!', error);
        window.location.replace("/r/dashboard");
      });
  }

  const getInvoicesNotificationsCount = async (token) => {
    if (token) {
      const headers = { "Content-Type": "application/json" };
      headers.Authorization = `Token ${token}`;
      fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/getInvoicesNotification/`, { headers, })
        .then(async response => {
          const data = await response.json();
          if (data.success) ReactSession.set("invoicesNotificationsCount", data.data);
        })
        .catch(error => console.error('There was an error!', error));
    }
  }

  function getRecruiter(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiter/`, { headers, })
      .then(async response => {
        const data = await response.json();
        // console.log(data.data.countries)
        if (data.success) {
          ReactSession.set("candidate", null);
          ReactSession.set("company", null);
          ReactSession.set("student", null);
          ReactSession.set("recruiter", data.data);
          getNotificationCount(token);
          getInvoicesNotificationsCount(token)
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function getCompany(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/company/`, { headers, })
      .then(async response => {
        const data = await response.json();

        ReactSession.set("company", data.data);
        ReactSession.set("candidate", null);
        ReactSession.set("recruiter", null);
        ReactSession.set("student", null);
        window.location.replace("/c/dashboard");
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function getCandidate(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/candidate/`, { headers, })
      .then(async response => {
        const data = await response.json();

        ReactSession.set("candidate", data.data);
        ReactSession.set("company", null);
        ReactSession.set("recruiter", null);
        ReactSession.set("student", null);
        if (!data.data.cv) {
          window.location.replace("/t/wizard");
        } else {
          window.location.replace("/t/dashboard");
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function getStudent(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/users/getStudent/`, { headers, })
      .then(async response => {
        const data = await response.json();

        ReactSession.set("student", data.data);
        ReactSession.set("coaches", data?.data?.coachs);
        ReactSession.set("candidate", null);
        ReactSession.set("company", null);
        ReactSession.set("recruiter", null);
        window.location.replace("/dashboard");
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => {
    if (code) {
      const postBody = {
        'code': code
      };
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/magicLogin/`;
      const requestMetadata = {
        method: 'POST',
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams(postBody)
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            ReactSession.set("user", response.data.user);
            ReactSession.set("token", response.data.access_token);
            if (response.data && response.data.user && response.data.user.type === "company_student") {
              getStudent(response.data.access_token);
            } else if (response.data && response.data.user && response.data.user.type === "candidate") {
              getCandidate(response.data.access_token);
            } else if (response.data && response.data.user && (response.data.user.type === "company_owner" || response.data.user.type === "recruiter" || response.data.user.type === "limited_recruiter")) {
              getCompany(response.data.access_token);
            } else {
              getRecruiter(response.data.access_token);
            }
          } else {
            setResponseMsg(2);
          }
        });
    }
  }, []);

  return (
    <PageLayout white>
      <DefaultNavbar
        routes={pageRoutes}
        transparent
        action={{
          type: "external",
          route: "/",
          label: "home",
          color: "info",
        }}
      />
      <SuiBox my={24} height="calc(100vh - 24rem)">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={8} ml={2}>
              <SuiBox className="alertSuccessBox" display="none">
                <SuiAlert color="info" className="alertSuccess">Mail sent.</SuiAlert>
              </SuiBox>
              <SuiBox className="alertErrorBox" display="none">
                <SuiAlert color="secondary" className="alertError">Please wait at least one minute before requesting another link.</SuiAlert>
              </SuiBox>
            </Grid>
            {responseMsg === 1 ? (
              <Grid item xs={12} lg={8} ml={2}>
                <SuiBox fontSize="3.5rem" lineHeight={1.2}>
                  <SuiTypography variant="inherit" color="info" textGradient fontWeight="bold">
                    Logging into your account...
                  </SuiTypography>
                </SuiBox>
                <SuiBox mt={1} mb={2}>
                  <SuiTypography variant="body1" color="text">
                    Please wait a moment while we validate your request.
                  </SuiTypography>
                </SuiBox>
              </Grid>
            ) : null}
            {responseMsg === 2 ? (
              <Grid item xs={12} lg={8} ml={2}>
                <SuiBox fontSize="3.5rem" lineHeight={1.2}>
                  <SuiTypography variant="inherit" color="error" textGradient fontWeight="bold">
                    We could not sign you in
                  </SuiTypography>
                </SuiBox>
                <SuiBox mt={1} mb={2}>
                  <SuiTypography variant="body1" color="text">
                    There was a problem with your request, please try again in a moment.
                  </SuiTypography>
                </SuiBox>
              </Grid>
            ) : null}

          </Grid>
        </Grid>
      </SuiBox>
    </PageLayout>
  );
}

export default Validated;
