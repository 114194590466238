/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid";
// sui components
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function Interests({ formData }) {
  ReactSession.setStoreType("localStorage");
  const candidate = ReactSession.get("candidate");
 
  const { formField, values, errors, touched } = formData;
  const { resume } = formField;
  const {
    resume: resumeV
  } = values;

  let commitmentsString = "";
  let techsString = "";
  let commitmentsSelectedStrings = [];
  let techsSelectedStrings = [];
  let commitmentStrings = [];
  let techStrings = [];
  let tagtext = "";
  let timerid = "";
  let timerid2 = "";
 
  const [commitments, setCommitments] = useState([]);
  const [commitmentsSelected, setCommitmentsSelected] = useState([]);

  const [techs, settechs] = useState([]);
  const [techsSelected, setTechsSelected] = useState([]);

  function changeCommitPlaceholder(remove, box) {
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      if (remove) {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "");
      } else if (box === "commitmentsBox") {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "Commitments.");
      } else {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "Techs.");
      }
    }, 100);
  }

  function changeTechPlaceholder(remove, box) {
    if (timerid2) {
      clearTimeout(timerid2);
    }
    timerid2 = setTimeout(() => {
      if (remove) {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "");
      } else if (box === "commitmentsBox") {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "Commitments.");
      } else {
        $(`.${box} .react-tag-input__input`).attr("placeholder", "Techs.");
      }
    }, 100);
  }

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateData = async () => {
    if (candidate.commitment) {
      const candidateCommitmets = [];
      // eslint-disable-next-line
      $.each(candidate.commitment, function (i, val) {
        candidateCommitmets.push(val.name)
      });

      if (candidateCommitmets.length) {
        setCommitmentsSelected(candidateCommitmets);
        changeCommitPlaceholder(1, "commitmentsBox");
      }
    }

    if (candidate.industries) {
      const candidateTechs = [];
      // eslint-disable-next-line
      $.each(candidate.industries, function (i, val) {
        candidateTechs.push(val.name)
      });

      if (candidateTechs.length) {
        setTechsSelected(candidateTechs);
        changeTechPlaceholder(1, "techsBox");
      }
    }

    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newCommitmentData = [];
    // eslint-disable-next-line
    for (let i = 0; i < interests?.data?.commitment?.length; i++) {
      newCommitmentData.push({ value: interests?.data?.commitment[i]?.name, label: interests?.data?.commitment[i]?.name });
    }

    const newTechData = [];
    // eslint-disable-next-line
    for (let i = 0; i < interests?.data?.industries?.length; i++) {
      newTechData.push({ value: interests?.data?.industries[i]?.name, label: interests?.data?.industries[i]?.name });
    }

    $(".commitmentsBox").attr("data-commitment", JSON.stringify(interests?.data?.commitment));
    $(".techsBox").attr("data-techs", JSON.stringify(interests?.data?.industries));

    setCommitments(newCommitmentData);
    settechs(newTechData);
  }

  useEffect(() => { populateData() }, []);

  function notInArray(selectStr, isTech) {
    let tempArray = commitmentsSelected;
    if (isTech) {
      tempArray = techsSelected;
    }
    // eslint-disable-next-line
    for (const tempItem of tempArray) {
      if (tempItem.includes(selectStr)) {
        return false;
      }
    }

    return true;
  }

  function addCommitment() {
    commitmentsString = $("input[name=commitments]").val();
    if (commitmentsString && notInArray(commitmentsString, false)) {
      commitmentsSelectedStrings = [...commitmentsSelected];
      commitmentsSelectedStrings.push(commitmentsString);
      setCommitmentsSelected(commitmentsSelectedStrings);
      changeCommitPlaceholder(1, "commitmentsBox");
    }
  }

  function addtech() {
    techsString = $("input[name=techs]").val();
    if (techsString && notInArray(techsString, true)) {
      techsSelectedStrings = [...techsSelected];
      techsSelectedStrings.push(techsString);
      setTechsSelected(techsSelectedStrings);
      changeTechPlaceholder(1, "techsBox");
    }
  }

  function removeTag() {
    console.log("remove tag");
    console.log(resume, resumeV, errors, touched)
  }

  // eslint-disable-next-line
  $(document).on("click", ".commitmentsBox .react-tag-input__tag__remove, .techsBox .react-tag-input__tag__remove", function () {
    tagtext = $(this).prev(".react-tag-input__tag__content");
    if ($(this).parents(".commitmentsBox").length && tagtext) {
      tagtext = tagtext.text();
      commitmentStrings = [...commitmentsSelected];
      if (commitmentStrings.indexOf(tagtext) >= 0) {
        commitmentStrings.splice(commitmentStrings.indexOf(tagtext), 1);
        setCommitmentsSelected(commitmentStrings);
        if (!commitmentStrings.length) {
          changeCommitPlaceholder(0, "commitmentsBox");
        }
      }
    } else if (tagtext) {
      tagtext = tagtext.text();
      techStrings = [...techsSelected];
      if (techStrings.indexOf(tagtext) >= 0) {
        techStrings.splice(techStrings.indexOf(tagtext), 1);
        setTechsSelected(techStrings);
        if (!techStrings.length) {
          changeTechPlaceholder(0, "techsBox");
        }
      }
    }
  });

  return (
    <SuiBox>

      <SuiBox width="100%" textAlign="center" mb={4}>
        <SuiTypography variant="h5" fontWeight="regular">
          Select your levels of commitment
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={2}>

        <Grid container xs={12} md={12} spacing={1}>
          <Grid item xs={8} mb={2}>
            <SuiSelect
              placeholder={commitments.length < 1 ? "Loading..." : "Select Commitment level"}
              options={commitments}
              name="commitments"
              disabled={commitments.length < 1}
              isSearchable={false}
            />
          </Grid>
          <Grid item xs={4} >
            <SuiButton color="info" onClick={addCommitment} fullWidth>Add</SuiButton>
          </Grid>
        </Grid>
        
        <Grid item xs={12} md={12} className="commitmentsBox">
          <SuiTagInput
            placeholder="Your commitments"
            tags={commitmentsSelected}
            onChange={removeTag}
          />
        </Grid>
      </SuiBox>

      <SuiBox width="100%" textAlign="center" mb={4} mt={4}>
        <SuiTypography variant="h5" fontWeight="regular">
          Select some tech segments you are interested in
        </SuiTypography>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container xs={12} md={12} spacing={1}>
          <Grid item xs={8} mb={2}>
            <SuiSelect
              placeholder={techs.length < 1 ? "Loading..." : "Select tech segment"}
              disabled={techs.length < 1}
              options={techs}
              name="techs"
            />
          </Grid>
          <Grid item xs={4}>
            <SuiButton color="info" onClick={addtech} fullWidth>Add</SuiButton>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} className="techsBox">
          <SuiTagInput
            placeholder="Your tech segments"
            tags={techsSelected}
            onChange={removeTag}
          />
        </Grid>
      </SuiBox>

    </SuiBox>
  );
}

Interests.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Interests;
