/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session';
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import Icon from "@mui/material/Icon";

function Basic({ setActiveStep, showError }) {
  const { appId } = useParams()
  let ApplicationForEdit = ReactSession.get(`ApplicationForEdit${appId}`)
  if (!ApplicationForEdit) {
    ApplicationForEdit = ReactSession.get("ApplicationForEdit");
    ReactSession.set(`ApplicationForEdit${appId}`, ApplicationForEdit);
  }
  const candidate = ApplicationForEdit.user.candidate // eslint-disable-line

  if (candidate?.phone && candidate?.phone.includes("+")) candidate.phone = candidate?.phone.slice(1)

  const verifyBasicInputs = () => {
    if (!ApplicationForEdit?.user?.first_name ||
        !ApplicationForEdit?.user?.last_name ||
        !ApplicationForEdit?.email ||
        !candidate?.country?.name
        ) showError()
    else setActiveStep(prevState => prevState + 1)
  }


  const socialMediaList = [];
  const socialMediaInputsDefault = [];
  if (candidate && candidate?.linkedin) { 
    socialMediaInputsDefault.push({ label: "LinkedIn Profile", name: "linkedin", value: candidate?.linkedin })
  } else {
    socialMediaList.push({ value: "linkedin", label: "LinkedIn Profile" })
  }
  if (candidate && candidate?.github) { 
    socialMediaInputsDefault.push({ label: "Github", name: "github", value: candidate?.github })
  } else {
    socialMediaList.push({ value: "github", label: "Github" })
  }
  if (candidate && candidate?.portfolio) { 
    socialMediaInputsDefault.push({ label: "Portfolio", name: "portfolio", value: candidate?.portfolio })
  } else {
    socialMediaList.push({ value: "portfolio", label: "Portfolio" })
  }
  if (candidate && candidate?.website) { 
    socialMediaInputsDefault.push({ label: "Website", name: "website", value: candidate?.website })
  } else {
    socialMediaList.push({ value: "website", label: "Website" })
  }
  if (candidate && candidate?.twitter) { 
    socialMediaInputsDefault.push({ label: "Twitter", name: "twitter", value: candidate?.twitter })
  } else {
    socialMediaList.push({ value: "twitter", label: "Twitter" })
  }
  const [socialMediaOptions, setSocialMediaOptions] = useState(socialMediaList);
  const [socialMediaInputs, setSocialMediaInputs] = useState(socialMediaInputsDefault);

  function addMediaInput() {
    const media = $("[name=media]").val();
    const tempMedia = [];
    const temsocialMediaInputs = [...socialMediaInputs]
    // eslint-disable-next-line
    for (const socialMedia of [...socialMediaOptions]) {
      if (media !== socialMedia.value) {
        tempMedia.push(socialMedia);
      } else {
        temsocialMediaInputs.push({ label: socialMedia.label, name: socialMedia.value, value: "" })
      }
    }
    console.log(temsocialMediaInputs, tempMedia)
    setSocialMediaInputs(temsocialMediaInputs);
    setSocialMediaOptions(tempMedia);
  }

  function closeMediaInput(mediaName, mediaLabel) {
    const temsocialMediaInputs = []
    const tempMedia = [...socialMediaOptions];
    // eslint-disable-next-line
    for (const socialMediaInput of [...socialMediaInputs]) {
      if (mediaName !== socialMediaInput.name) {
        temsocialMediaInputs.push(socialMediaInput);
      }
    }
    tempMedia.push({ value: mediaName, label: mediaLabel });

    setSocialMediaInputs(temsocialMediaInputs);
    setSocialMediaOptions(tempMedia);
  }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">Basic details and contact information</SuiTypography>
      </SuiBox>
      <SuiBox>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <SuiTypography variant="button" ml={1} mb={1}>First name</SuiTypography>
                <SuiInput
                  type="text"
                  name="first_name"
                  disabled
                  defaultValue={ApplicationForEdit?.user?.first_name}
                />
              </Grid>

              <Grid item xs={6}>
                <SuiTypography variant="button" ml={1} mb={1}>Last name</SuiTypography>
                <SuiInput
                  type="text"
                  name="last_name"
                  disabled
                  defaultValue={ApplicationForEdit?.user?.last_name}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Email address</SuiTypography>
              <SuiInput
                type="text"
                name="email"
                disabled
                defaultValue={ApplicationForEdit?.email}
              />
            </Grid>

            <Grid item xs={12} sm={12} className="countryBox">
              <SuiTypography variant="button" ml={1}>Location</SuiTypography>
              <SuiInput
                type="text"
                name="country"
                disabled
                defaultValue={candidate?.country?.name}
              />
            </Grid>

            <Grid item xs={12}>
              <SuiTypography variant="button" ml={1} mb={1}>Phone number (optional)</SuiTypography>
              <SuiInput
                type="text"
                name="phone"
                disabled
                defaultValue={candidate?.phone}
                // event on paste taste
                onPaste={e => {
                  e.preventDefault();
                  // remove all characters except numbers
                  const paste = e.clipboardData.getData("text/plain").replace(/\D/g, "");
                  // insert pasted text
                  document.execCommand("insertText", false, paste);
                }}
              />
            </Grid>

          </Grid>
        </Grid>
        <br/>
        { socialMediaOptions && socialMediaOptions.length ? (
          <Grid item xs={12} mb={2}>
            <SuiBox mb={0.5}>
              <SuiTypography variant="button" ml={1}>
                Social Media Links
              </SuiTypography>
            </SuiBox>

            {socialMediaInputs.map(socialMediaInput => { // eslint-disable-line
                if (socialMediaInput.name === "linkedin") return (
                  <Grid item xs={12} sx={{ position: "relative" }} mb={1}>
                    <SuiInput
                      type="text"
                      name={socialMediaInput.name}
                      label="Linkedin"
                      style={{ paddingRight: "40px" }}
                      defaultValue={socialMediaInput.value}
                      placeholder={socialMediaInput.label}
                    />
                  </Grid>
                )
              })}

            <Grid container xs={12} md={12} spacing={1} className="hiddenField">
              <Grid item xs={9} md={9}>
                <SuiSelect
                  placeholder="Select social media"
                  options={socialMediaOptions}
                  name="media"
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <SuiButton color="info" fullWidth onClick={() => addMediaInput()}>Add</SuiButton>
              </Grid>
            </Grid>
          </Grid>
        ) : null }

        {socialMediaInputs.map(socialMediaInput => { // eslint-disable-line
          if (socialMediaInput.name !== "linkedin") return (
            <Grid item xs={12} sx={{ position: "relative" }} mb={1}>
              <SuiInput
                type="text"
                name={socialMediaInput.name}
                label="Linkedin"
                style={{ paddingRight: "40px" }}
                defaultValue={socialMediaInput.value}
                placeholder={socialMediaInput.label}
              />
              <Icon className="closeMedia" onClick={() => closeMediaInput(socialMediaInput.name, socialMediaInput.label)} >close</Icon>
            </Grid>
          )
        })}

      </SuiBox>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <Link to={`/r/editCandidateWizard/${ApplicationForEdit?.user?.id}`} target="_blank">
          <SuiButton variant="outlined" color="info" isSubmitting>Edit candidate</SuiButton>
        </Link>
        <SuiButton variant="gradient" color="info" onClick={() => verifyBasicInputs()} className="changeStep nextStep" isSubmitting>Next</SuiButton>
      </SuiBox>

    </SuiBox >
  );
}

// typechecking props for Basic
Basic.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};

export default Basic;
