/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import checkout from "layouts/applications/company-wizard/formSchemas/form"
import { ReactSession } from 'react-client-session'

ReactSession.setStoreType("localStorage")
const company = ReactSession.get("company")

const {
  formField: {
    companyName,
    companyFirstName,
    companyLastName,
    companyMail,
    companyPassword,
    website,
    youtube,
    linkedin,
    twitter,
    bio,
    photo,
  },
} = checkout

export default {
  [companyName.name]: company && company.name ? company.name : "",
  [companyFirstName.name]: "",
  [companyLastName.name]: "",
  [companyMail.name]: "",
  [companyPassword.name]: "",
  [website.name]: company && company.website ? company.website : "",
  [youtube.name]: company && company.youtube ? company.youtube : "",
  [linkedin.name]: company && company.linkedin ? company.linkedin : "",
  [twitter.name]: company && company.twitter ? company.twitter : "",
  [bio.name]: company && company.description ? company.description : "",
  [photo.name]: "",
}
