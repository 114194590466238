/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// formik components
import { Formik, Form } from "formik";
// MUI components
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
import SuiTypography from "components/SuiTypography"
// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import ResetPassword from "layouts/pages/users/new-user/components/ResetPassword";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
// Image
import companyAndRecruitersLogin from "assets/images/companyAndRecruitersLogin.jpg"

function getContent(formData) {
  return <ResetPassword formData={formData} />;
}

function Illustration() {
  // Validation
  const [activeStep, setActiveStep] = useState(0);

  /* Mui hook to read screen size - We use it for small screen rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = false;

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(values, null, 2));

    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      // setActiveStep(activeStep + 1);
      actions.setTouched({});
    }
  };

  const resetPasswordClick = async () => {
    await sleep(100);
    if ($(".MuiTypography-caption:visible").text().length === 0) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/password_reset/`;
      const postBody = {
        'email': $("input[name=email]").val()
      };

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: new URLSearchParams(postBody)
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          $(".suiAlertMsg").remove();

          if (response.success) {
            $(".suiAlertMsg").remove();
            const successAlert = $($('.alertSuccessBox .alertSuccess').parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(successAlert);
          } else {
            $(".suiAlertMsg").remove();

            const errorAlert = $($('.alertSuccessBox .alertError').parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(errorAlert);
          }
        });
    }
  }

  return (
    <IllustrationLayout
      title="Reset Your Password"
      description="Enter the email associated with your account to receive instructions on changing your password."
      illustration={{
        image: companyAndRecruitersLogin,
      }}
    >
      <SuiBox className="suiAlertBox">
        <SuiBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, issubmitting }) => (
              <Form id={formId} autoComplete="off" type="POST" actions="#" >
                <SuiBox width={isMobile ? "300px" : "400px"}>
                  {getContent({
                    values,
                    touched,
                    formField,
                    errors,
                  })}
                  <SuiBox mt={4} mb={1}>
                    <SuiButton
                      type="submit"
                      variant="contained"
                      color="warning"
                      size="large"
                      issubmitting={issubmitting}
                      onClick={resetPasswordClick}
                      fullWidth
                      sx={{ backgroundColor: "#feec1e" }}
                    >
                      <SuiTypography fontWeight="bold">Send</SuiTypography>
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </Form>
            )}
          </Formik>
        </SuiBox>

        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertSuccess">Mail sent</SuiAlert>
        </SuiBox>

        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertError" color="error">Email not found</SuiAlert>
        </SuiBox>
      </SuiBox>
    </IllustrationLayout>
  );
}

export default Illustration;
