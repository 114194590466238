/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
// react-router-dom components
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
// formik components
import { Formik, Form } from "formik";
// @mui material components
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from '@mui/material/CircularProgress'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
// NewUser layout schemas for form and form feilds
import SignUp from "layouts/pages/users/new-user/components/SignUp";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
// Authentication layout components
import IllustrationLayoutCandidate from "layouts/authentication/components/IllustrationLayoutCandidate";
// Images
import candidatesLogin from "assets/images/time_to_upgrade.webp";
import candidatesLoginAnim from  "assets/images/time_to_upgrade.webm";
import MockCandidateAvatar from  "assets/images/mockCandidateAvatar.png"


function Illustration() {
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');

  ReactSession.setStoreType("localStorage");

  // Validation
  const [activeStep, setActiveStep] = useState(0);
  // const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = false;
  let timerid = "";

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(values, null, 2));

    // actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      // setActiveStep(activeStep + 1);
      actions.setTouched({});
      // actions.setSubmitting(false);
    }
  };

  function getCandidate(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/candidate/`, { headers, })
      .then(async response => {
        const data = await response.json();

        ReactSession.set("candidate", data.data);
        ReactSession.set("company", null);
        ReactSession.set("recruiter", null);
        if (!data.data.cv) {
          window.location.replace("/t/wizard");
        } else {
          window.location.replace("/t/dashboard");
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function manageLogin(response) {
    $(".suiAlertMsg")?.remove();
    if (response.success) {
      ReactSession.set("user", response.data.user);
      ReactSession.set("token", response.data.access_token);
      // window.location.replace("/t/dashboard");
      getCandidate(response.data.access_token);
    } else {
      $(".suiAlertMsg")?.remove();

      const errorAlert = $($('.alertSuccessBox .alertError').text(response.data.error).parent().html()).addClass("suiAlertMsg");
      $(".suiAlertBox").parent().prepend(errorAlert);

      if (response.data.id) {
        if (timerid) {
          clearTimeout(timerid);
        }
        timerid = setTimeout(() => {
          window.location.replace(`/activate?id=${response.data.id}`);
        }, 3000);
      }
    }
  }

  const [isCreatingProfile, setIsCreatingProfile] = useState(false)

  const [isSigninupWithLinkedin, setIsSigninupWithLinkedin] = useState(false)
  const [linkedinSignupData, setLinkedinSignupData] = useState(null)

  const { linkedInLogin } = useLinkedIn({
    clientId: '86x3397xbajc1d',
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "r_emailaddress,r_liteprofile",

    onSuccess: (linkedinCode) => {
      setIsSigninupWithLinkedin(true)

      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/signupLinkedin/`;
      const postBody = {
        'code': linkedinCode
      };
      const requestMetadata = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        body: new URLSearchParams(postBody)
      };
      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          setLinkedinSignupData(response?.data)
          // Hack to remove warnings from name and last name inputs
          document.querySelector("#new-user-form > div > div:nth-child(1) > div > div > div:nth-child(1) > div > div.MuiBox-root.css-opqdhz > div")?.remove()
          document.querySelector("#new-user-form > div > div:nth-child(1) > div > div > div:nth-child(2) > div > div.MuiBox-root.css-opqdhz > div")?.remove()
        });
    },
    onError: (error) => { console.log(error) },
  });

  const [selectedCountry, setSelectedCountry] = useState(null)
  const [showSelectedCountryError, setShowSelectedCountryError] = useState(false)
  
  const [uploadedCV, setUploadedCV] = useState(null)
  const [showUploadedCVError, setShowUploadedCVError] = useState(false)
  const [showPDFError, setShowPDFError] = useState(false)

  

  const getContent = formData => { // eslint-disable-line
    return <SignUp
      formData={formData}
      setSelectedCountry={setSelectedCountry}
      setShowSelectedCountryError={setShowSelectedCountryError}
      setUploadedCV={setUploadedCV}
      setShowUploadedCVError={setShowUploadedCVError}
      isSigninupWithLinkedin={isSigninupWithLinkedin}
      linkedinSignupData={linkedinSignupData}
      isCreatingProfile={isCreatingProfile}
    />
  }

  const signUpClick = async (values) => { // eslint-disable-line

    if (!selectedCountry) return setShowSelectedCountryError(true)
    if (!uploadedCV) return setShowUploadedCVError(true)

    const extension = uploadedCV.name.substring(uploadedCV.name.length - 4, uploadedCV.name.length).toLowerCase()
        
    if (!extension.includes("pdf")) {
        setShowPDFError(true)
        return setTimeout(() => setShowPDFError(false), 2000)
    }
    const normalizedName = "cv.pdf"

    await sleep(100);
    if ($(".MuiTypography-caption:visible").text().length === 0 && $("input[type='checkbox']").is(':checked')) {
      setIsCreatingProfile(true)

      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/signupCandidateWithCV/`;
      const lastEmail = $("input[name=email]").val();
      ReactSession.set("lastEmail", lastEmail);

      const formCvPostData = new FormData();
      
      formCvPostData.append('email', values.email);
      formCvPostData.append('first_name', values.firstName);
      formCvPostData.append('last_name', values.lastName);
      formCvPostData.append('password', values.password);
      formCvPostData.append('password_confirmation', values.password);
      formCvPostData.append('file', uploadedCV);
      formCvPostData.append('country', selectedCountry);

      const utm = ReactSession.get("utm");
      if (utm) formCvPostData.append('utm', utm);
      
      if (code) formCvPostData.append('code', code);
      const requestMetadata = {
        method: 'POST',
        headers: {
          "Content-Disposition": `attachment; filename=${normalizedName}`,
        },
        body: formCvPostData
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {

          setIsCreatingProfile(false)

          $(".suiAlertMsg")?.remove();
          if (response.success) {
            $(".suiAlertMsg")?.remove();
            const successAlert = $($('.alertSuccessBox .alertSuccess').parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(successAlert);

            if (timerid) {
              clearTimeout(timerid);
            }

            ReactGA.initialize(process.env.REACT_APP_DEFAULT_GA_KEY);
            ReactGA.event({
              category: "candidate_registered",
              action: "candidate_registered",
            });

            timerid = setTimeout(() => {
              window.location.replace(`/validated?id=${response.data.id}`);
            }, 2000);


          } else {
            $(".suiAlertMsg")?.remove();

            let errorMsg = response.data.error;
            if (errorMsg && errorMsg.includes("duplicate key")) {
              errorMsg = "This email address is already in use. Please enter a different email address or login to your existing account."
            }

            const errorAlert = $($('.alertSuccessBox .alertError').text(errorMsg).parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(errorAlert);

            if (timerid) {
              clearTimeout(timerid);
            }

            if (response.data.confirmed) {
              timerid = setTimeout(() => {
                window.location.replace(`/t/login`);
              }, 2000);
            }

            if (response.data.candidateExists && !response.data.confirmed && response.data.fromLever) {
              timerid = setTimeout(() => {
                window.location.replace(`/t/claim`);
              }, 2000);
            }
          }
        });
    } else if (!$("input[type='checkbox']").is(':checked')) {

      $(".suiAlertMsg")?.remove();
      $(".suiAlertMsg")?.remove();

      const errorAlert = $($('.alertSuccessBox .alertError').text("Agreement with Terms of Service is required").parent().html()).addClass("suiAlertMsg");
      $(".suiAlertBox").parent().prepend(errorAlert);
    }
  }
  
  const completeLinkedinSignup = async () => { // eslint-disable-line

    // Hack to remove warnings from name and last name inputs
    document.querySelector("#new-user-form > div > div:nth-child(1) > div > div > div:nth-child(1) > div > div.MuiBox-root.css-opqdhz > div")?.remove()
    document.querySelector("#new-user-form > div > div:nth-child(1) > div > div > div:nth-child(2) > div > div.MuiBox-root.css-opqdhz > div")?.remove()

    if (!selectedCountry) return setShowSelectedCountryError(true)
    if (!uploadedCV) return setShowUploadedCVError(true)

    const extension = uploadedCV.name.substring(uploadedCV.name.length - 4, uploadedCV.name.length).toLowerCase()
        
    if (!extension.includes("pdf")) {
        setShowPDFError(true)
        return setTimeout(() => setShowPDFError(false), 2000)
    }
    const normalizedName = "cv.pdf"

    if (!$("input[type='checkbox']").is(':checked')) {
      $(".suiAlertMsg")?.remove();
      $(".suiAlertMsg")?.remove();

      const errorAlert = $($('.alertSuccessBox .alertError').text("Agreement with Terms of Service is required").parent().html()).addClass("suiAlertMsg");
      return $(".suiAlertBox").parent().prepend(errorAlert);
    }

    setIsCreatingProfile(true)

    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/signupLinkedinWithCV/`;
    const lastEmail = $("input[name=email]").val();
    ReactSession.set("lastEmail", lastEmail);

    const formCvPostData = new FormData();

    formCvPostData.append('email', linkedinSignupData?.email);
    formCvPostData.append('first_name', linkedinSignupData?.first_name);
    formCvPostData.append('last_name', linkedinSignupData?.last_name);
    formCvPostData.append('file', uploadedCV);
    formCvPostData.append('country', selectedCountry);
    formCvPostData.append('image_url', linkedinSignupData.image_url || MockCandidateAvatar);

    const utm = ReactSession.get("utm");
    if (utm) formCvPostData.append('utm', utm);
    
    if (code) formCvPostData.append('code', code);

    const requestMetadata = {
      method: 'POST',
      headers: {
        "Content-Disposition": `attachment; filename=${normalizedName}`,
      },
      body: formCvPostData
    };

    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        setIsCreatingProfile(false)

        $(".suiAlertMsg")?.remove();
        if (response.success) {
          $(".suiAlertMsg")?.remove();
          const successAlert = $($('.alertSuccessBox .alertSuccess').parent().html()).addClass("suiAlertMsg");
          $(".suiAlertBox").parent().prepend(successAlert);

          if (timerid) {
            clearTimeout(timerid);
          }

          manageLogin(response)
        } else {
          $(".suiAlertMsg")?.remove();

          const errorAlert = $($('.alertSuccessBox .alertError').text(response.data.error).parent().html()).addClass("suiAlertMsg");
          $(".suiAlertBox").parent().prepend(errorAlert);

          if (timerid) {
            clearTimeout(timerid);
          }

          if (response.data.confirmed) {
            timerid = setTimeout(() => {
              window.location.replace(`/t/login`);
            }, 2000);
          }

          if (response.data.candidateExists && !response.data.confirmed && response.data.fromLever) {
            timerid = setTimeout(() => {
              window.location.replace(`/t/claim`);
            }, 2000);
          }
        }
      });
    
  }

  return (
    <IllustrationLayoutCandidate
      color="info"
      illustration={{
        image: candidatesLogin,
        video: candidatesLoginAnim,
      }}
    > 
      {showUploadedCVError && <SuiAlert color="error">Please include your CV/Resume to continue.</SuiAlert>}
      {showPDFError && <SuiAlert color="error">Only PDF format is allowed for CVs.</SuiAlert>}
      {showSelectedCountryError && <SuiAlert color="error">Please select your country of residence.</SuiAlert>}

      {isSigninupWithLinkedin && (
        <SuiTypography variant="body2" color="text" mt={1} textAlign="center">
          Please add your CV and country of residence to finish.
        </SuiTypography>
      )}

      <SuiBox className="suiAlertBox">
        <SuiBox display="flex" alignItems="center">
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, issubmitting }) => (
              <Form id={formId} autoComplete="off" type="POST" actions="#">
                <SuiBox>
                  {getContent({
                    values,
                    touched,
                    formField,
                    errors,
                  })}
                  <SuiBox>
                    <SuiBox>
                      <Checkbox disabled={isCreatingProfile} />
                      <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        sx={{ userSelect: "none" }}
                        name="terms"
                      >
                        I Agree with Tecla’s <a target="_blank" rel="noreferrer" href="https://www.tecla.io/tos/"><u>Terms of Service</u></a> and <a target="_blank" rel="noreferrer" href="https://www.tecla.io/privacy/"><u>Privacy policy</u></a>.
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>

                  {isCreatingProfile ? // eslint-disable-line
                    <SuiBox mt={4} mb={1} display="flex" justifyContent="center" alignItems="center" >
                      <CircularProgress size={30} mr={5} />
                      <SuiTypography color="dark" fontWeight="bold" ml={3}>Creating your profile, please wait...</SuiTypography>
                    </SuiBox>
                  :
                    !isSigninupWithLinkedin ? (
                      <>
                        <SuiBox mt={4} mb={1}>
                          <SuiButton type="submit" variant="contained" color="warning" size="large" issubmitting={issubmitting} onClick={() => signUpClick(values)} fullWidth sx={{ backgroundColor: "#feec1e" }}>
                            <SuiTypography color="dark" fontWeight="bold">Sign Up</SuiTypography>
                          </SuiButton>
                        </SuiBox>
                        
                        <SuiBox mt={2} mb={1}>
                          <SuiButton variant="contained" color="dark" size="large" fullWidth sx={{ backgroundColor: "#0177b5" }}>
                            <SuiTypography color="white" fontWeight="bold" onClick={linkedInLogin}>Sign up Using LinkedIn</SuiTypography>
                          </SuiButton>
                        </SuiBox>
                      </>
                      )
                    :
                      <SuiBox mt={4} mb={1}>
                        <SuiButton type="submit" variant="contained" color="warning" size="large" issubmitting={issubmitting} onClick={() => completeLinkedinSignup()} fullWidth sx={{ backgroundColor: "#feec1e" }}>
                          <SuiTypography color="dark" fontWeight="bold">Complete Sign Up</SuiTypography>
                        </SuiButton>
                      </SuiBox>
                  }

                  {!isCreatingProfile && (
                    <SuiBox mt={3} textAlign="center">
                      <SuiTypography variant="button" color="text" fontWeight="regular">
                        Already have an account?&nbsp;
                        <SuiTypography
                          component={Link}
                          to="/t/login"
                          variant="button"
                          sx={{ color: "#097df7", fontSize: "18px" }}
                          fontWeight="bold"
                        >
                          Log in
                        </SuiTypography>
                      </SuiTypography>
                    </SuiBox>
                  )}
                </SuiBox>
              </Form>
            )}
          </Formik>
        </SuiBox>

        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertSuccess">Welcome to TECLA!</SuiAlert>
        </SuiBox>

        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertError" color="error">EMAIL IS ALREADY IN USE</SuiAlert>
        </SuiBox>
      </SuiBox>
    </IllustrationLayoutCandidate>
  );
}

export default Illustration;
