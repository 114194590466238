/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as Yup from "yup"
import checkout from "layouts/applications/company-wizard/formSchemas/form"

const {
  formField: { companyName, companyFirstName, companyLastName, companyMail, companyPassword, website, youtube, linkedin, twitter, bio }, } = checkout

export default [
  Yup.object().shape({
    [companyName.name]: Yup.string().required(companyName.errorMsg).matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Only alphabets are allowed for this field ").min(1, companyName.tooShort),
    [companyFirstName.name]: Yup.string().required(companyFirstName.errorMsg),
    [companyLastName.name]: Yup.string().required(companyLastName.errorMsg),
    [companyMail.name]: Yup.string().required(companyMail.errorMsg),
    [companyPassword.name]: Yup.string().required(companyPassword.errorMsg),
    [website.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.google.com/"'), // eslint-disable-line
    [youtube.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.google.com/"'), // eslint-disable-line
    [linkedin.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.google.com/"'), // eslint-disable-line
    [twitter.name]: Yup.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, 'Enter a valid URL format. ie: "https://www.google.com/"'), // eslint-disable-line
    [bio.name]: Yup.string().max(2000, bio.tooBig),
  }),
]
