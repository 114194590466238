/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import FormField from "layouts/applications/wizard/components/FormField";

function Links({ formData, showLinkedinLinkError, showWrongLinkFormatMsg }) {

  const { formField, values, errors, touched } = formData;
  const { youtube, github, gitlab, linkedin, stackoverflow, portfolio, website, twitter, facebook, instagram } = formField;

  const {
    youtube: youtubeV,
    github: githubV,
    gitlab: gitlabV,
    linkedin: linkedinV,
    stackoverflow: stackoverflowV,
    portfolio: portfolioV,
    website: websiteV,
    twitter: twitterV,
    facebook: facebookV,
    instagram: instagramV,
  } = values;

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SuiBox width="100%" textAlign="center" mb={4}>
          <SuiTypography variant="h5" fontWeight="regular">
            Make yourself known by including links to your social media
          </SuiTypography>
        </SuiBox>
      </SuiBox>

      <SuiBox mt={2}>
        <SuiBox mt={2}>
        {showLinkedinLinkError && <SuiAlert color="secondary" className="alertError">Please add a LinkedIn profile link to continue.</SuiAlert>}
        {showWrongLinkFormatMsg && <SuiAlert color="secondary" className="alertError">Please enter correct link format.</SuiAlert>}

          <Grid container spacing={2} className="webLinks">
            <Grid item xs={12} md={12}>
              <FormField
                type={youtube.type}
                label={youtube.label}
                name={youtube.name}
                value={youtubeV}
                placeholder={youtube.placeholder}
                error={errors.youtube && touched.youtube}
                success={youtubeV.length > 0 && !errors.youtube}
              />
              <SuiTypography variant="body2" fontWeight="regular" color="text" ml={1}>
                *Tip: A short video presentation will greatly increase your odds of being hired.
              </SuiTypography>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormField
                type={github.type}
                label={github.label}
                name={github.name}
                value={githubV}
                placeholder={github.placeholder}
                error={errors.github && touched.github}
                success={githubV.length > 0 && !errors.github}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormField
                type={gitlab.type}
                label={gitlab.label}
                name={gitlab.name}
                value={gitlabV}
                placeholder={gitlab.placeholder}
                error={errors.gitlab && touched.gitlab}
                success={gitlabV.length > 0 && !errors.gitlab}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormField
                type={linkedin.type}
                label={linkedin.label}
                name={linkedin.name}
                value={linkedinV}
                placeholder={linkedin.placeholder}
                error={errors.linkedin && touched.linkedin}
                success={linkedinV.length > 0 && !errors.linkedin}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormField
                type={stackoverflow.type}
                label={stackoverflow.label}
                name={stackoverflow.name}
                value={stackoverflowV}
                placeholder={stackoverflow.placeholder}
                error={errors.stackoverflow && touched.stackoverflow}
                success={stackoverflowV.length > 0 && !errors.stackoverflow}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormField
                type={portfolio.type}
                label={portfolio.label}
                name={portfolio.name}
                value={portfolioV}
                placeholder={portfolio.placeholder}
                error={errors.portfolio && touched.portfolio}
                success={portfolioV.length > 0 && !errors.portfolio}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormField
                type={website.type}
                label={website.label}
                name={website.name}
                value={websiteV}
                placeholder={website.placeholder}
                error={errors.website && touched.website}
                success={websiteV.length > 0 && !errors.website}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormField
                type={twitter.type}
                label={twitter.label}
                name={twitter.name}
                value={twitterV}
                placeholder={twitter.placeholder}
                error={errors.twitter && touched.twitter}
                success={twitterV.length > 0 && !errors.twitter}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormField
                type={facebook.type}
                label={facebook.label}
                name={facebook.name}
                value={facebookV}
                placeholder={facebook.placeholder}
                error={errors.facebook && touched.facebook}
                success={facebookV.length > 0 && !errors.facebook}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormField
                type={instagram.type}
                label={instagram.label}
                name={instagram.name}
                value={instagramV}
                placeholder={instagram.placeholder}
                error={errors.instagram && touched.instagram}
                success={instagramV.length > 0 && !errors.instagram}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

// typechecking props for Links
Links.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  showLinkedinLinkError: PropTypes.bool.isRequired,
  showWrongLinkFormatMsg: PropTypes.bool.isRequired,
};


export default Links;
