// prop-type is a library for typechecking of props
import PropTypes from 'prop-types'
// @mui material components
import Modal from '@mui/material/Modal'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import SuiTypography from 'components/SuiTypography'

function CloseConfirmationModal({ showCloseConfirmationModal, setShowCloseConfirmationModal, onClose, newFormWasCreated }) {
    
    const confirmClose = () => {
        onClose()
        setShowCloseConfirmationModal(false)
        if (newFormWasCreated) window.location.reload()
    }

    return (
        <Modal
            open={showCloseConfirmationModal}
            onClose={() => { setShowCloseConfirmationModal(false)}}
        >
            <SuiBox
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '100vw', md: '60vw' },
                    height: { xs: '100vh', md: 'auto' },
                    maxHeight: { md: '90vh' },
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY:'auto'
                }}
            >
                <SuiBox sx={{ my: 3, mx: 'auto' }}>
                    <SuiTypography variant="h4" color="dark">Are you sure you want to close this modal?</SuiTypography>
                    <SuiTypography variant="body" color="text">Your changes will not be saved.</SuiTypography>
                </SuiBox>

                <SuiBox mt={2} mb={5} width='80%' mx='auto' display='flex'>
                    <SuiButton
                        sx={{ mx: 1 }}
                        fullWidth
                        color='info'
                        variant='outlined'
                        onClick={() => setShowCloseConfirmationModal(false)}
                    >
                        Cancel
                    </SuiButton>


                    <SuiButton
                        sx={{ mx: 1 }}
                        fullWidth
                        color='info'
                        variant='gradient'
                        onClick={() => confirmClose()}
                    >
                        Confirm
                    </SuiButton>
                </SuiBox>
            </SuiBox>
        </Modal>
    )
}

export default CloseConfirmationModal

// typechecking props
CloseConfirmationModal.propTypes = {
    showCloseConfirmationModal: PropTypes.bool.isRequired,
    setShowCloseConfirmationModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    newFormWasCreated: PropTypes.bool.isRequired,
};