/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// react-router-dom components
import { Link } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import mockCandidateAvatar from "assets/images/mockCandidateAvatar.png";

function NameAndPictureCell({ image, name, candidateId }) {

  return (
    <Link to={`profile/${candidateId}`} target="_blank" rel="noopener noreferrer">
      <SuiBox display="flex" alignItems="center" pr={2}>
        <SuiBox mr={2} sx={{ display: { xs: 'none', md: 'block' } }}>
            <SuiAvatar src={image || mockCandidateAvatar} alt={name} variant="rounded" size="sm" />
        </SuiBox>
        <SuiBox display="flex" flexDirection="column">
          <SuiTypography variant="button" fontWeight="medium">
            {name}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </Link>
  );
}

// Setting default values for the props of SuiAlert
NameAndPictureCell.defaultProps = {
  candidateId: null,
};

// Typechecking props for the NameAndPictureCell
NameAndPictureCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  candidateId: PropTypes.number,
};

export default NameAndPictureCell;
