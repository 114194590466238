/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session';
import { useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import $ from 'jquery';
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";


let loadPdf = true;

// eslint-disable-next-line react/prop-types
function Skills({ isFreelanceJob }) {

  ReactSession.setStoreType("localStorage");
  // const user = ReactSession.get("user");
  const candidate = { ...ReactSession.get("candidate") };
  const lastApply = { ...ReactSession.get("lastApply") };

  if (!lastApply.interviewInEnglish && lastApply.interviewInEnglish !== false && (candidate.interviewInEnglish || candidate.interviewInEnglish === false)) {
    lastApply.interviewInEnglish = candidate.interviewInEnglish;
  }
  if (lastApply.interviewInEnglish === true) {
    lastApply.interviewInEnglishExtra = "Yes";
  } else if (lastApply.interviewInEnglish === false) {
    lastApply.interviewInEnglishExtra = "No";
  }

  if (!lastApply.canWorkRemotely && lastApply.canWorkRemotely !== false && (candidate.canWorkRemotely || candidate.canWorkRemotely === false)) {
    lastApply.canWorkRemotely = candidate.canWorkRemotely;
  }
  if (lastApply.canWorkRemotely === true) {
    lastApply.canWorkRemotelyExtra = "Yes";
  } else if (lastApply.canWorkRemotely === false) {
    lastApply.canWorkRemotelyExtra = "No";
  }

  if (!lastApply.englishLevel && candidate.englishLevel && !candidate.firstApplication) {
    lastApply.englishLevel = String(candidate.englishLevel);
  }

  let defaultSalary = 0;
  if (lastApply.salary) {
    defaultSalary = lastApply.salary;
  }

  // eslint-disable-next-line
  $(document).unbind().on("keypress", "input[name=salary]", function (e) {
    if (e.keyCode === 46) {
        e.preventDefault();
    }
});

  let timerid = "";

  if (timerid) {
    clearTimeout(timerid);
  }

  function resetLoad() {
    let resumeTimerid = "";
    if (resumeTimerid) {
      clearTimeout(resumeTimerid);
    }
    resumeTimerid = setTimeout(() => {
      loadPdf = true;
    }, 500);
  }

  useEffect(() => {
    timerid = setTimeout(() => {
      $("input[name=cv]").attr("accept", ".pdf");
      if (loadPdf && candidate && candidate.cv) {
        loadPdf = false;
        fetch(`${process.env.REACT_APP_API_ROUTE}${candidate.cv}`)
          .then((response) => response.blob())
          .then((blob) => {
            let cvName = "resume.pdf";
            if (lastApply && lastApply.cvName) {
              cvName = lastApply.cvName;
            }
            const file = new File([blob], cvName);
            const container = new DataTransfer();
            container.items.add(file);
            $("input[name=cv]")[0].files = container.files;
            resetLoad();
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
      }
    }, 100);
  }, []);

  function showError(text) {
    let textError = text;
    if (!textError) {
      textError = "There was an unknown error uploading the file.";
    }
    let timerSuccessid = "";
    $(".alertErrorBox div div").text(textError);
    $(".alertErrorBox").show();
    $('html, body').animate({
      scrollTop: $(".alertErrorBox").offset().top
    }, 100);
    if (timerSuccessid) {
      clearTimeout(timerSuccessid);
    }
    timerSuccessid = setTimeout(() => {
      $(".alertErrorBox").hide();
    }, 5000);
  }

  function uploadResume() {
    const resumeField = $("input[name=cv]").prop('files')[0];
    const formCvPostData = new FormData();
    formCvPostData.append('cv', resumeField);
    const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/applications/uploadCVApply/`;
    const normalizedName = "cv.pdf"
    const requestMetadata = {
      method: 'PATCH',
      headers: {
        "Content-Disposition": `attachment; filename=${normalizedName}`
      },
      body: formCvPostData
    };

    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          lastApply.tempCV = response.data.tempCV;
          ReactSession.set("lastApply", lastApply);
        } else if (response.data.error) {
          $("input[name=cv]").val("");

          showError(response.data.error);
        }
      })
      .catch(error => showError(error))
  }

  const interviewInEnglishArray = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
  ];
  const englishLevelArray = [
    { value: "1", label: "1 (Not able to speak English at all)" },
    { value: "2", label: "2 (Not able to speak English at all)" },
    { value: "3", label: "3 (Not able to speak English at all)" },
    { value: "4", label: "4 (Not able to speak English at all)" },
    { value: "5", label: "5 (Currently learning; not able to speak yet)" },
    { value: "6", label: "6 (I can speak but with lots of mistakes)" },
    { value: "7", label: "7 (I can hold a conversation with a few mistakes)" },
    { value: "8", label: "8 (I can hold a conversation easily; minor mistakes)" },
    { value: "9", label: "9 (Excellent English; no issues at all)" },
    { value: "10", label: "10 (English is like my first language)" },
  ];
  const availabilityArray = [
    { value: "1-week", label: "1 Week" },
    { value: "2-weeks", label: "2 Weeks" },
    { value: "3-weeks", label: "3 Weeks" },
    { value: "4-weeks", label: "4 Weeks" },
  ];

  return (
    <SuiBox>
      <SuiBox width="100%" textAlign="center" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">
          Application Questions
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={2} mb={4}>
        <SuiBox ml={1}>
          <SuiTypography variant="button">
            Resume / CV
          </SuiTypography>
        </SuiBox>
        <SuiInput name="cv" type="file" label="Resume" placeholder="Resume" onChange={uploadResume} />
        <SuiTypography variant="body2" fontWeight="regular" color="text" ml={1}>
          Please upload your CV here in PDF format. If you already have an account you can apply using the information saved in your profile
        </SuiTypography>
      </SuiBox>
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={4}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">
            Are you able to hold an interview in English?
          </SuiTypography>
        </SuiBox>
        {lastApply.interviewInEnglishExtra ? (
          <SuiSelect
            name="interviewInEnglish"
            options={interviewInEnglishArray}
            defaultValue={interviewInEnglishArray.find(({ value }) => value === lastApply.interviewInEnglishExtra)}
            isSearchable={false}
          />
        ) : (
          <SuiSelect
            name="interviewInEnglish"
            options={interviewInEnglishArray}
            isSearchable={false}
          />
        )}
      </SuiBox>
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={4}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">
            On a scale of 1-10: what is your level of spoken English?
          </SuiTypography>
        </SuiBox>
        {lastApply.englishLevel ? (
          <SuiSelect
            name="englishLevel"
            options={englishLevelArray}
            defaultValue={englishLevelArray.find(({ value }) => value === lastApply.englishLevel)}
            isSearchable={false}
          />
        ) : (
          <SuiSelect
            name="englishLevel"
            options={englishLevelArray}
            isSearchable={false}
          />
        )}
      </SuiBox>
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={4}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">
            Will you be able to work with your own equipment?
          </SuiTypography>
        </SuiBox>
        {lastApply.canWorkRemotelyExtra ? (
          <SuiSelect
            name="canWorkRemotely"
            options={interviewInEnglishArray}
            defaultValue={interviewInEnglishArray.find(({ value }) => value === lastApply.canWorkRemotelyExtra)}
            isSearchable={false}
          />
        ) : (
          <SuiSelect
            name="canWorkRemotely"
            options={interviewInEnglishArray}
            isSearchable={false}
          />
        )}
      </SuiBox>
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={5}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">
            When are you available to start a new position?
          </SuiTypography>
        </SuiBox>
        {lastApply.availability ? (
          <SuiSelect
            name="availability"
            options={availabilityArray}
            defaultValue={availabilityArray.find(({ value }) => value === lastApply.availability)}
            isSearchable={false}
          />
        ) : (
          <SuiSelect
            name="availability"
            options={availabilityArray}
            isSearchable={false}
          />
        )}
      </SuiBox>
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={5}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            {isFreelanceJob ?
              <SuiTypography variant="button">What is your hourly rate expectation in USD?</SuiTypography>
              :
              <SuiTypography variant="button">What is your monthly salary expectation in USD?</SuiTypography>
            }
          </SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiInput type="number" placeholder="Expected salary" name="salary" defaultValue={defaultSalary} />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Skills;

