/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// Soft UI Dashboard PRO React components
// ProductsList page components
import ProductCell from "layouts/pages/profile/applications-list/components/ProductCell";
// import JobCell from "layouts/pages/total-applications/components/JobCell"
// import ActionCell from "layouts/pages/profile/applications-list/components/ActionCell";
// Images
// import adidasHoodie from "assets/images/ecommerce/adidas-hoodie.jpeg";
// import macBookPro from "assets/images/ecommerce/macbook-pro.jpeg";
// import metroChair from "assets/images/ecommerce/metro-chair.jpeg";
// import alchimiaChair from "assets/images/ecommerce/alchimia-chair.jpeg";
// import fendiCoat from "assets/images/ecommerce/fendi-coat.jpeg";
// import offWhiteJacket from "assets/images/ecommerce/off-white-jacket.jpeg";
// import yohjiYamamoto from "assets/images/ecommerce/yohji-yamamoto.jpeg";
// import mcqueenShirt from "assets/images/ecommerce/mcqueen-shirt.jpeg";
// import yellowChair from "assets/images/ecommerce/yellow-chair.jpeg";
// import heronTshirt from "assets/images/ecommerce/heron-tshirt.jpeg";
// import livingChair from "assets/images/ecommerce/living-chair.jpeg";
// import orangeSofa from "assets/images/ecommerce/orange-sofa.jpeg";
// import burberry from "assets/images/ecommerce/burberry.jpeg";
// import dgSkirt from "assets/images/ecommerce/d&g-skirt.jpeg";
// import undercover from "assets/images/ecommerce/undercover.jpeg";

export default {
  columns: [
    {
      Header: "Candidate",
      accessor: "candidate",
      width: "40%",
      Cell: ({ value: name }) => (
        <ProductCell name={name} />
      ),
    },
    { Header: "Action", accessor: "action" },
  ],

  rows: [],
};
