/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// MUI
// import HelpIcon from '@mui/icons-material/Help';
// import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React components
// import SuiBadge from "components/SuiBadge";
// import SuiBox from "components/SuiBox";
// ProductsList page components
// import ProductCell from "layouts/pages/profile/applications-list/components/ProductCell";
// import ActionCell from "layouts/pages/profile/applications-list/components/ActionCell";

export default {
  columns: [
    { Header: "Date", accessor: "date" },
    { Header: "Description", accessor: "description" },
    { Header: "Status", accessor: "status" },
    { Header: "Payment Date", accessor: "paymentDate" },
    { Header: "Notes", accessor: "notes" },
    { Header: "Action", accessor: "action" },
  ],

  rows: [],
};
