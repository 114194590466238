/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import SuiAvatar from "components/SuiAvatar";
import SuiAlert from "components/SuiAlert";
// MUI components
import Modal from '@mui/material/Modal';
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
// Icons
import leverIcon from "assets/images/LeverIcon.png"
import AddToRecruiterSourcingModal from "layouts/pages/profile/profile-overview/addToRecruiterSourcingModal/addToRecruiterSourcingModal";
import ApplicationsModal from "layouts/pages/searchRecruiter/components/ActionCell/ApplicationsModal/applicationsModal";

function ActionCell({ candidate }) {

	const apiUrl = process.env.REACT_APP_API_ROUTE;
	let candidateUrl = "";
	let cvUrl = "";
	let leverUrl = "";
	let similarCandidatesUrl = "";
	if (candidate && candidate.user) {
		candidateUrl = `/r/profile/${candidate.user.id}`;
		cvUrl = candidate.cv ? `${apiUrl}${candidate.cv}` : "";
		leverUrl = candidate.leverUrl;
		similarCandidatesUrl = `/r/talent/${candidate.user.id}/similar`;
	}

	const recruiterFolders = ReactSession.get("recruiterFolders")
	const clientFolders = ReactSession.get("clientFolders")
	const token = ReactSession.get("token")

	const folderOptions = []
	if (Array.isArray(recruiterFolders)) {
		recruiterFolders?.forEach(folder => folderOptions.push({ value: folder.id, label: folder.name, public: folder.public }))
	}
	if (Array.isArray(clientFolders)) {
        clientFolders.forEach(folder => folderOptions.push({ value: `clientCode-${folder.id}`, label: `${folder.name} (Client folder)` }))
    }

	// Folders
	const [openFoldersModal, setOpenFoldersModal] = useState(false)
	const [desiredFolder, setDesiredFolder] = useState(null)
	const [showSpinner, setShowSpinner] = useState(false)
	const [showDoneIcon, setShowDoneIcon] = useState(false)
    const [showError, setShowError] = useState(false)

	const saveCandidateToFolder = (folder, candidatesArray) => {

		const requestBody = {
			name: folder.name,
			favorite: folder.favorite,
			candidates: candidatesArray,
		}
		requestBody.candidates.push(candidate.user.id)

		fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${desiredFolder}/updateFolder/`, {
			method: 'PATCH',
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Token ${token}`
			},
			body: JSON.stringify(requestBody)
		})
			.then(async response => {
				const data = await response.json()
				
				if (data.success) {
					setShowSpinner(false)
					setShowDoneIcon(true)

					setTimeout(() => {
						setOpenFoldersModal(false)
						setShowDoneIcon(false)
						setDesiredFolder(null)
					}, 1500)
				}
			})
			.catch(error => {
				console.error('There was an error!', error)
				setShowSpinner(false)
                setShowError(true)

                setTimeout(() => setShowError(false), 3000)
			})
	}

	const fetchFolderContent = () => {
		setShowSpinner(true)

		// For client folders
		if (String(desiredFolder).includes('clientCode')) {
			const clientFolderCode = desiredFolder.slice(desiredFolder.indexOf('-')+1)
			
			fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${clientFolderCode}/addCandidateToFolderClient/`, {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Token ${token}`
				},
				body: JSON.stringify({ candidateId: candidate.user.id })

			})
				.then(async response => {
					const data = await response.json()
					
					if (data.success) {
						setShowSpinner(false)
						setShowDoneIcon(true)
	
						setTimeout(() => {
							setOpenFoldersModal(false)
							setShowDoneIcon(false)
							setDesiredFolder(null)
						}, 1500)
					}
				})
				.catch(error => console.error('There was an error!', error))
		}
		// For recruiter folders
		else {
			fetch(`${process.env.REACT_APP_API_ROUTE}/api/folders/${desiredFolder}/getFolder/`, {
				method: 'GET',
				headers: { "Authorization": `Token ${token}` }
			})
				.then(async response => {
					const data = await response.json()
	
					if (data.success) {
						const candidatesArray = []
						// eslint-disable-next-line
						for (const candidate of data.data.candidates) candidatesArray.push(candidate.user.id)
						saveCandidateToFolder(data.data, candidatesArray)
					}
				})
				.catch(error => {
					console.error('There was an error!', error)
					setShowSpinner(false)
					setShowError(true)
	
					setTimeout(() => setShowError(false), 3000)
				})
		}

		
	}

	const saveCandidate = () => fetchFolderContent()

	const [openSourceModal, setOpenSourceModal] = useState(false)

	const [openApplicationsModal, setOpenApplicationsModal] = useState(false)

	return (
		<SuiBox display="flex" alignItems="center" >

			{cvUrl && (
				<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={cvUrl} target="_blank">
						<Tooltip title="View CV" placement="top">
							<AssignmentIcon />
						</Tooltip>
					</SuiTypography>
				</SuiBox>
			)}

			<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={candidateUrl} target="_blank">
					<Tooltip title="View profile" placement="top">
						<PersonIcon />
					</Tooltip>
				</SuiTypography>
			</SuiBox>

			<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column" onClick={() => setOpenApplicationsModal(true)}>
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1}>
					<Tooltip title="Applications" placement="top">
						<SendIcon />
					</Tooltip>
				</SuiTypography>
			</SuiBox>

			<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} onClick={() => setOpenSourceModal(true)}>
					<Tooltip title="Source" placement="top">
						<AddCircleIcon />
					</Tooltip>
				</SuiTypography>
			</SuiBox>

			<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} onClick={() => setOpenFoldersModal(true)}>
					<Tooltip title="Add to folder" placement="top">
						<CreateNewFolderIcon />
					</Tooltip>
				</SuiTypography>
			</SuiBox>

			{leverUrl && (
				<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={leverUrl} target="_blank">
						<Tooltip title="Lever Profile" placement="top">
							<SuiAvatar src={leverIcon} alt="Lever icon" size="xs" />
						</Tooltip>
					</SuiTypography>
				</SuiBox>
			)}

			<SuiBox mx={.5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
				<SuiTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} mr={1} component="a" href={similarCandidatesUrl} target="_blank">
					<Tooltip title="Search Similar profiles" placement="top">
						<GroupsIcon />
					</Tooltip>
				</SuiTypography>
			</SuiBox>
			
			{/* Folder modal */}
			<Modal open={openFoldersModal} onClose={() => setOpenFoldersModal(false)}>
				<SuiBox sx={{
					width: { xs: "100vw", md: "650px" },
					height: { xs: "101vh", md: "auto" },
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					bgcolor: 'background.paper',
					border: 'none',
					boxShadow: 24,
					borderRadius: { xs: 0, md: 4 },
					p: 3,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}} >

				{showDoneIcon && <SuiAlert color="info">Candidate added to folder!</SuiAlert>}
                {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

					<SuiTypography variant="h4">Add candidate to folder</SuiTypography>
					<SuiTypography variant="button">Please select the folder you&apos;d like to save the candidate to:</SuiTypography>

					<SuiBox my={2}>
						<SuiSelect
							label="Folder"
							name="folder"
							placeholder="Folder"
							options={folderOptions}
							onChange={e => setDesiredFolder(e.value)}
							getOptionLabel={e => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ marginLeft: 5 }}>{e.label}</span>
                              { e.public === true ? (<PublicIcon sx={{ marginLeft: "5px" }} />) : null}
                              { e.public === false ? (<LockIcon sx={{ marginLeft: "5px" }} />) : null}
                            </div>
                          )}
						/>
					</SuiBox>

					<SuiBox width="80%" display="inherit" justifyContent="center" mx="auto">
						<SuiButton
							sx={{ width: "80%", marginRight: "1rem" }}
							color="info"
							variant="outlined"
							disabled={showSpinner || showDoneIcon}
							onClick={() => {
								setDesiredFolder(null)
								setOpenFoldersModal(false)
							}}>
							Cancel
						</SuiButton>
						{/* eslint-disable-next-line */}
						{showSpinner ?
							<SuiBox sx={{ width: "80%", marginLeft: "1rem" }} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
							:
							showDoneIcon ?
								<SuiBox sx={{ width: "80%", marginLeft: "1rem" }} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
								:
								<SuiButton sx={{ width: "80%", marginLeft: "1rem" }} fullWidth color="info" variant="gradient" onClick={() => saveCandidate()}>Confirm</SuiButton>
						}
					</SuiBox>

				</SuiBox>
			</Modal>

			{/* Source modal */}
			{openSourceModal && (<AddToRecruiterSourcingModal
				showAddToRecruiterSourcing={openSourceModal}
				setshowAddToRecruiterSourcing={setOpenSourceModal}
				host="r/talent"
				candidateId={candidate.user.id}
			/>)}

			{/* Applications modal */}
			{openApplicationsModal && (<ApplicationsModal
				openApplicationsModal={openApplicationsModal}
				setOpenApplicationsModal={setOpenApplicationsModal}
				candidateId={candidate.user.id}
				candidateName={candidate.user ? `${candidate.user.first_name} ${candidate.user.last_name}` : ""}
			/>)}
		</SuiBox>
	)
}

ActionCell.defaultProps = {
	candidate: {}
}

ActionCell.propTypes = {
	candidate: PropTypes.objectOf(PropTypes.any)
}

export default ActionCell
