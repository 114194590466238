/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// react-router-dom components
import { Link } from "react-router-dom";
// formik components
import { Formik, Form } from "formik";
// @mui material components
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
// Authentication layout components
import SignIn from "layouts/pages/users/new-user/components/Validate";
import IllustrationLayoutCandidate from "layouts/authentication/components/IllustrationLayoutCandidate";
// Image
import candidatesLogin from "assets/images/time_to_upgrade.webp";
import candidatesLoginAnim from  "assets/images/time_to_upgrade.webm";
import CircularProgress from '@mui/material/CircularProgress'

function getContent(formData) {
  return <SignIn formData={formData} />;
}

function Illustration() {
  // Validation
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  // const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = false;
  let timerid = "";
  ReactSession.setStoreType("localStorage");
  ReactSession.set("lastCompanyLogin", false);

  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get('id');

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(values, null, 2));

    // actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      // setActiveStep(activeStep + 1);
      actions.setTouched({});
      // actions.setSubmitting(false);
    }
  };

  function getCandidate(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/candidate/`, { headers, })
      .then(async response => {
        const data = await response.json();
        ReactSession.set("candidate", data.data);
        ReactSession.set("company", null);
        ReactSession.set("recruiter", null);
        if (!data.data.cv) {
          window.location.replace("/t/wizard");
        } else {
          window.location.replace("/t/dashboard");
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function getCompany(token) {
    const headers = { "Content-Type": "application/json" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/companies/company/`, { headers, })
      .then(async response => {
        const data = await response.json();

        ReactSession.set("company", data.data);
        ReactSession.set("candidate", null);
        ReactSession.set("recruiter", null);
        window.location.replace("/c/dashboard");
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  const verifiAccount = async () => {
    await sleep(100);
    if ($(".MuiTypography-caption:visible").text().length === 0 && userId && !loading) {
      setLoading(true);
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/activateAccount/`;
      const postBody = {
        'code': String($("input[name=code]").val()),
        'id': Number(userId)
      };

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: new URLSearchParams(postBody)
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            ReactSession.set("user", response.data.user);
            ReactSession.set("token", response.data.access_token);

            if (response.data && response.data.user && response.data.user.type === "candidate") {
              getCandidate(response.data.access_token);
            } else if (response.data && response.data.user && (response.data.user.type === "company_owner" || response.data.user.type === "recruiter" || response.data.user.type === "limited_recruiter")) {
              getCompany(response.data.access_token);
            }

          } else {
            $(".alertErrorBox div div").text(response.data.error);
            $(".alertErrorBox").show();
            if (timerid) {
              clearTimeout(timerid);
            }
            timerid = setTimeout(() => {
              $(".alertErrorBox").hide();
            }, 10000);
          }

          setLoading(false);
        });
    }
  }

  return (
    <IllustrationLayoutCandidate
      color="info"
      illustration={{
        image: candidatesLogin,
        video: candidatesLoginAnim,
      }}
    >
      <SuiBox className="suiAlertBox">
        <SuiBox display="flex" alignItems="end">
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, issubmitting }) => (
              <Form id={formId} autoComplete="off" type="POST" actions="#">
                <SuiBox mb={3} px={1}>
                  <SuiTypography variant="h2" fontWeight="bold" sx={{ textAlign: "center" }}>
                    Account Verification
                  </SuiTypography>
                </SuiBox>
                <SuiBox mb={5} px={1}>
                  <SuiTypography variant="h6" sx={{ textAlign: "center", color: "#b7b3b3" }}>
                    To validate your account, please paste the code you received in the verification mail on the field below.
                  </SuiTypography>
                </SuiBox>
                <SuiBox className="alertErrorBox" display="none">
                  <SuiAlert className="alertError" color="error">EMAIL IS ALREADY IN USE</SuiAlert>
                </SuiBox>
                <SuiBox>
                  {getContent({
                    values,
                    touched,
                    formField,
                    errors,
                  })}
                  <SuiBox mt={5}>
                    { !loading ? (
                      <SuiButton type="submit" variant="contained" color="warning" size="large" issubmitting={issubmitting} onClick={verifiAccount} fullWidth sx={{ backgroundColor: "#feec1e" }}>
                        <SuiTypography fontWeight="bold">Verify account</SuiTypography>
                      </SuiButton>
                    ) : (
                      <SuiButton type="submit" variant="contained" color="warning" size="large" issubmitting={issubmitting} onClick={verifiAccount} fullWidth sx={{ backgroundColor: "#feec1e" }}>
                        <CircularProgress />
                      </SuiButton>
                    )}


                  </SuiBox>
                  <SuiBox opacity={0.25} my={3}>
                    <hr />
                  </SuiBox>
                  <SuiBox mt={3} textAlign="center">
                    <SuiTypography variant="button" color="text" fontWeight="regular" >
                      Already have a verified account? &nbsp;
                      <SuiTypography
                        component={Link}
                        to="/t/login"
                        variant="button"
                        fontWeight="bold"
                        sx={{ color: "#097df7" }}
                      >
                        Log In
                      </SuiTypography>

                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              </Form>
            )}
          </Formik>
        </SuiBox>
      </SuiBox>
    </IllustrationLayoutCandidate >
  );
}

export default Illustration;
